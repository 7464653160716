import { Table, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../../services/redux";
import { getAmountFormatWithSymbol } from "../../../../../helpers/functions";
import PrintHeader from "../../../common/print_header";

const OmSakthiReportList = ({ to_date, from_date }) => {
  const { t } = useTranslation();

  const { items: om_sakthi_report_list } = useDynamicSelector(
    "get_om_sakthi_summary"
  );

  // const data = om_sakthi_report_list ? [om_sakthi_report_list] : [];

  // const columns = [
  //   {
  //     title: "Normal Ticket Devotees",
  //     key: "base_total_devotees",
  //     render: (data) => (data ? data[0]?.base_total_devotees : "-"),
  //   },
  //   {
  //     title: "Normal Ticket Received Amount",
  //     key: "base_total_amount",
  //     render: (data) => getAmountFormatWithSymbol(data[0]?.base_total_amount),
  //   },
  //   {
  //     title: "VIP Ticket Devotees",
  //     key: "vip_total_devotees",
  //     render: (data) => data[0]?.vip_total_devotees,
  //   },
  //   {
  //     title: "VIP Ticket Received Amount",
  //     key: "vip_total_amount",
  //     render: (data) => getAmountFormatWithSymbol(data[0]?.vip_total_amount),
  //   },
  //   {
  //     title: "Total Devotees",
  //     key: "total_devotees",
  //     render: (data) => data[0]?.total_devotees,
  //   },
  //   {
  //     title: "Total Amount",
  //     key: "amount",
  //     align: "flex-end",
  //     render: (data) => {
  //       return (
  //         <span style={{ alignItems: "flex-end" }}>
  //           {getAmountFormatWithSymbol(data[0]?.total_amount)}
  //         </span>
  //       );
  //     },
  //   },
  // ];

  return (
    <div>
      {om_sakthi_report_list && (
        <>
          <div className="print-header">
            <PrintHeader title={"OM SAKTHI REPORT"} />
            <h4
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              OM SAKTHI REPORT
            </h4>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                {from_date?.selected_from_date}
              </h5>
              <h5>to</h5>
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "10px",
                }}
              >
                {to_date?.selected_to_date}
              </h5>
            </div>
          </div>
          <Typography style={{ fontWeight: "bold" }}>Ticket Summary</Typography>

          {/* <Table
            data-name-table="district-wise-report"
            className="om_sakthi_report_details screen-table"
            columns={columns}
            dataSource={data}
            pagination={false}
          /> */}
          <div className="om_sakthi_report_details screen-table">
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="header-style">Normal Ticket Devotees</th>
                  <th className="header-style">
                    Normal Ticket Received Amount
                  </th>
                  <th className="header-style">VIP Ticket Devotees</th>
                  <th className="header-style">VIP Ticket Received Amount</th>
                  <th className="header-style">Total Devotees</th>
                  <th className="header-style">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="body-style">
                    {om_sakthi_report_list
                      ? om_sakthi_report_list[0]?.base_total_devotees
                      : 0}
                  </td>{" "}
                  <td className="body-style">
                    {getAmountFormatWithSymbol(
                      om_sakthi_report_list
                        ? om_sakthi_report_list[0]?.base_total_amount
                        : 0
                    )}
                  </td>
                  <td className="body-style">
                    {om_sakthi_report_list
                      ? om_sakthi_report_list[0]?.vip_total_devotees
                      : 0}
                  </td>
                  <td className="body-style">
                    {getAmountFormatWithSymbol(
                      om_sakthi_report_list
                        ? om_sakthi_report_list[0]?.vip_total_amount
                        : 0
                    )}
                  </td>
                  <td className="body-style">
                    {om_sakthi_report_list
                      ? om_sakthi_report_list[0]?.total_devotees
                      : 0}
                  </td>
                  <td className="body-style">
                    {om_sakthi_report_list
                      ? getAmountFormatWithSymbol(
                          om_sakthi_report_list[0]?.total_amount
                        )
                      : 0}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <table className="table table-bordered print-table">
              <thead>
                <tr>
                  <th className="header-style">Normal Ticket Devotees</th>
                  <th className="header-style">
                    Normal Ticket Received Amount
                  </th>
                  <th className="header-style">VIP Ticket Devotees</th>
                  <th className="header-style">VIP Ticket Received Amount</th>
                  <th className="header-style">Total Devotees</th>
                  <th className="header-style">Total Amount</th>
                  {/* {columns.map((column) => (
                    <th className="header-style" key={column.key}>
                      {column.title}
                    </th>
                  ))} */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="body-style">
                    {om_sakthi_report_list[0].base_total_devotees}
                  </td>
                  <td className="body-style">
                    {getAmountFormatWithSymbol(
                      om_sakthi_report_list
                        ? om_sakthi_report_list[0]?.base_total_amount
                        : 0
                    )}
                  </td>
                  <td className="body-style">
                    {om_sakthi_report_list[0].vip_total_devotees}
                  </td>
                  <td className="body-style">
                    {getAmountFormatWithSymbol(
                      om_sakthi_report_list
                        ? om_sakthi_report_list[0]?.vip_total_amount
                        : 0
                    )}
                  </td>
                  <td className="body-style">
                    {om_sakthi_report_list[0].total_devotees}
                  </td>
                  <td className="body-style">
                    {om_sakthi_report_list
                      ? getAmountFormatWithSymbol(
                          om_sakthi_report_list[0]?.total_amount
                        )
                      : 0}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <style>
            {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-header {
        display: block !important;
      }

      @media print {
        @page {
          size: A4;
          margin: 1cm;
        }
  
        body::before {
          content: counter(page);
          top: 0;
          right: 1cm;
        }
      }
    }
  `}
          </style>
        </>
      )}
    </div>
  );
};

export default OmSakthiReportList;
