import { Row } from "antd";
import React, { useState } from "react";
import ReceiptImageContent from "./receipt_image_content";
import ContentHeader from "./content_header";
import CustomButton from "../../common/custom_button";
import { useTranslation } from "react-i18next";
import { AiFillPrinter } from "react-icons/ai";
import { print_function } from "../../../../helpers/functions";

const ContentHeaderbar = () => {
  const { t } = useTranslation();

  const [show_print_button, set_show_print_button] = useState(true);

  const print = () => {
    print_function("print-content", "print-button", set_show_print_button);
  };

  return (
    <Row
      style={{
        justifyContent: "space-between",
        margin: "20px 12px",
        height: "40px",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <Row style={{ width: "32%" }}>
        <ReceiptImageContent />
      </Row>
      <Row style={{ width: "32%" }}>
        <ContentHeader />
      </Row>

      <Row
        style={{
          width: "32%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-end",
        }}
      >
        {show_print_button && (
          <Row style={{ alignItems: "flex-start" }}>
            <CustomButton
              id="print-button"
              button_name={t("print")}
              onclick_button={print}
              icon={<AiFillPrinter />}
            />
          </Row>
        )}
      </Row>
    </Row>
  );
};

export default ContentHeaderbar;
