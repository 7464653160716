import { Form, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const QuotaFormItem = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name="confirmed_quota"
        label={t("confirmed_quota")}
        style={{ width: "400px" }}
        rules={[
          {
            required: true,
            message: "Confirmed Quota is required",
          },
        ]}
      >
        <InputNumber placeholder={t("confirmed_quota")} />
      </Form.Item>
    </div>
  );
};

export default QuotaFormItem;
