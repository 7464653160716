import React from "react";
import { Form, InputNumber } from "antd";

const ArrivedCount = (props) => {
  const { set_updated_arrived_count } = props;
  const on_change = (value) => {
    console.log("hhhhh", value);
    set_updated_arrived_count(value);
  };
  return (
    <Form.Item
      label="Arrived Count"
      name="arrived_count"
      rules={[
        {
          required: true,
          message: "Please enter arrived count",
        },
        {
          type: "number",
          message: "Please enter a valid number",
        },
      ]}
    >
      <InputNumber
        style={{ width: "100%" }}
        placeholder="Arrived count"
        onChange={on_change}
      />
    </Form.Item>
  );
};

export default ArrivedCount;
