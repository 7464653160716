import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import AddQuestion from "./feedback_manage_add_question_table";
import AddQuestionEditModal from "./add_question_modal";
import FeedbackManageDeleteModal from "../feedback_manage_delete_modal/feedback_manage_delete_modal";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../common/custom_button";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { navigateBack } from "../../../../../helpers/functions";
import {
  create_feedback_question__mutation,
  delete_feedback_question__mutation,
  get_feedback_question_list,
} from "../../../../../services/redux/slices/graphql/graphql_feedback_question";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../../services/redux";
import { toast } from "react-toastify";
import CustomDeleteModal from "../../../common/custom_delete_modal";

const FeedbackManageAddQuestion = (props) => {
  const { id, is_active, handle_switch } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const history = useHistory();

  const [is_edit_model_visible, set_edit_modal_visible] = useState(false);
  const [header_type, set_header_type] = useState("");
  const [edit_values, set_edit_values] = useState({});
  const [delete_id, set_delete_id] = useState("");
  const [is_delete_modal_visible, set_is_delete_modal_visible] =
    useState(false);
  const [isExpanded, setIsExpanded] = useState({});

  const { items: feedback_question_item } = useDynamicSelector(
    "get_feedback_question_list"
  );
  const {
    status: delete_feedback_question_status,
    error: delete_feedback_question_error,
  } = useDynamicSelector("delete_feedback_question");

  useEffect(() => {
    get_all_feedback_question_query();
  }, []);

  useEffect(() => {
    if (delete_feedback_question_status === "success") {
      toast.success("Question deleted successfully");
      set_is_delete_modal_visible(false);
      get_all_feedback_question_query();
      dispatch(dynamicClear("delete_feedback_question"));
    } else if (delete_feedback_question_error?.message) {
      toast.error(delete_feedback_question_error.message);
      get_all_feedback_question_query();
      dispatch(dynamicClear("delete_feedback_question"));
    }
  });

  const get_all_feedback_question_query = () => {
    let key = [{ key: "get_feedback_question_list", loading: true }];
    let query = get_feedback_question_list;
    let variables = {
      filter: {
        feedback_id: id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const delete_feedback_question = (id) => {
    let key = [{ key: "delete_feedback_question", loading: true }];
    let query = delete_feedback_question__mutation;
    let variable = {
      id: delete_id?.id,
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const handle_add = () => {
    set_edit_values({});
    set_header_type("add");
    set_edit_modal_visible(true);
  };
  const handle_edit = (record) => {
    if (isExpanded[record.id]) {
      // Close the panel
      setIsExpanded({ ...isExpanded, [record.id]: false });
    }
    set_header_type("edit");
    set_edit_values(record);
    set_edit_modal_visible(true);
  };

  const show_delete_model = (record) => {
    set_delete_id(record);
    set_is_delete_modal_visible(true);
  };

  const handle_delete_cancel = () => {
    set_is_delete_modal_visible(false);
  };

  const handle_delete_confirm = (record) => {
    delete_feedback_question(delete_id);
    set_is_delete_modal_visible(false);
  };
  const handle_back = () => {
    navigateBack();
  };

  return (
    <div>
      <AddQuestion
        handle_add={handle_add}
        handle_edit={handle_edit}
        show_delete_model={show_delete_model}
        handle_back={handle_back}
        get_all_feedback_question_query={get_all_feedback_question_query}
      />

      <AddQuestionEditModal
        header_type={header_type}
        edit_values={edit_values}
        is_edit_model_visible={is_edit_model_visible}
        set_edit_modal_visible={set_edit_modal_visible}
        id={id}
        get_all_feedback_question_query={get_all_feedback_question_query}
        handle_switch={handle_switch}
      />
      <FeedbackManageDeleteModal
        is_delete_modal_visible={is_delete_modal_visible}
        handle_delete_confirm={handle_delete_confirm}
        handle_delete_cancel={handle_delete_cancel}
        show_delete_model={show_delete_model}
      />

      {/* <CustomDeleteModal
        selector_name={"delete_feedback_question"}
        get_all={get_all_feedback_question_query}
        delete_api={handle_delete_confirm}
        record={delete_id}
        text={"Question"}
        delete_modal_visible={is_delete_modal_visible}
        set_delete_modal_visible={set_is_delete_modal_visible}
      /> */}
    </div>
  );
};

export default FeedbackManageAddQuestion;
