import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const BookingActionGetDetailButton = () => {
  const { t } = useTranslation();
  return (
    <Button
      htmlType="submit"
      style={{
        backgroundColor: "#033E3E",
        color: "#ffff",
        borderRadius: "6px",
        marginTop: "6px",
      }}
    >
      {t("get_details")}
    </Button>
  );
};

export default BookingActionGetDetailButton;
