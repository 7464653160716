import React from "react";
import EnrolmentSessionList from "../components/enrolment_session/enrolment_session_list/enrolment_session_list";

const EnrolmentSession = () => {
  return (
    <div>
      <EnrolmentSessionList />
    </div>
  );
};

export default EnrolmentSession;
