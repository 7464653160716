import { Box } from "native-base";
import React from "react";
import LoginHeaderBar from "../components/headerbar/login_header";
import Footer from "../components/footer/footer";
import BackgroundLogo from "../../assets/icons/background_image.png";
import BookingAndIrumudiPortal from "../components/login/booking_and_irumudi_portal/booking_and_irumudi_portal";

const LoginPage = () => {
  return (
    <div>
      <LoginHeaderBar />

      <Box
        bgImage={`url(${BackgroundLogo})`}
        style={{ height: "100vh", backgroundSize: "cover",width:"100%" }}
      >
        <BookingAndIrumudiPortal />
      </Box>
    </div>
  );
};

export default LoginPage;
