import { Button, Modal } from "antd";
import React from "react";
import FeedbackManageMainForm from "../feedback_manage_form/feedback_manage_main_form";
import { useDynamicSelector } from "../../../../../services/redux";
import { IoMdClose } from "react-icons/io";

const FeedbackManageEditModal = (props) => {
  const {
    set_edit_modal_visible,
    is_edit_model_visible,
    edit_values,
    header_type,
    get_feedback_list_query,
    is_active,
    is_active_record,
  } = props;
  const { loading, status } = useDynamicSelector("create_feedback");
  const { loading: create_feedback_loading } =
    useDynamicSelector("create_feedback");

  const { loading: update_feedback_loading } =
    useDynamicSelector("update_feedback");

  return (
    <div>
      <Modal
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        title={header_type === "edit" ? `Edit ` : `Add`}
        open={is_edit_model_visible}
        loading={loading}
        destroyOnClose={true}
        onCancel={() => set_edit_modal_visible(false)}
        footer={[
          <Button key="cancel" onClick={() => set_edit_modal_visible(false)}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            htmlType="submit"
            form="feedback_form"
            loading={create_feedback_loading || update_feedback_loading}
          >
            Save
          </Button>,
        ]}
      >
        <FeedbackManageMainForm
          header_type={header_type}
          set_edit_modal_visible={set_edit_modal_visible}
          edit_values={edit_values}
          get_feedback_list_query={get_feedback_list_query}
          is_active={is_active}
          is_active_record={is_active_record}
        />
      </Modal>
    </div>
  );
};

export default FeedbackManageEditModal;
