import { Form, Input } from "antd";
import React from "react";

const InputDistrictAdminName = () => {
  return (
    <>
      <Form.Item
        name={["district_admin", "name"]}
        label="Name"
        rules={[
          {
            required: true,
            message: "Please enter name",
          },
        ]}
      >
        <Input placeholder="Name" allowClear={true} />
      </Form.Item>
    </>
  );
};

export default InputDistrictAdminName;
