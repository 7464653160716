import React from "react";
import ToolTipContent from "./tooltip_content";
import ProgressBar from "./progress_bar";
import { Popover } from "antd";

const DateAndMonth = ({ date_index, date_array }) => {
  return (
    <Popover
      placement="right"
      content={<ToolTipContent data={date_array[date_index]} />}
      style={{ marginBottom: "-5px" }}
      height={20}
    >
      {date_array?.length > 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h6
            className="date"
            style={{
              fontWeight: "bold",
              color: "white",
              marginTop: "8px",
            }}
          >
            {date_array[date_index].date === "blank"
              ? ""
              : date_array[date_index].date_string}
          </h6>
          <div style={{ width: "98%", marginTop: "-10px" }}>
            {date_array[date_index]?.year ? (
              <ProgressBar
                calendar_data={date_array[date_index]}
                index={date_index}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </Popover>
  );
};

export default DateAndMonth;
