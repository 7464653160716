import { Form, Select } from "antd";
import React from "react";

const AccountsReportDate = () => {
  const dates = ["22-10-2023", "23-10-2023", "23-10-2023"];
  return (
    <div>
      <Form.Item name="date" label="Select Date">
        <Select placeholder="Select Date">
          {dates.map((date, index) => (
            <Select.Option key={index} value={date}>
              {date}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default AccountsReportDate;
