import { gql } from "@apollo/client";

export const get_feedback_list = gql`
  query get_feedback_list(
    $filter: get_feedback_list_filter_input
    $sort: sort_input
    $pagination: pagination_input
  ) {
    get_feedback_list(filter: $filter, sort: $sort, pagination: $pagination) {
      items {
        id
        title
        is_active
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_feedback = gql`
  query get_feedback($id: String!) {
    get_feedback(id: $id) {
      id
      title
      is_active
    }
  }
`;

export const create_feedback_mutation = gql`
  mutation create_feedback($data: create_feedback_input!) {
    create_feedback(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_feedback_mutation = gql`
  mutation update_feedback($id: String!, $data: update_feedback_input!) {
    update_feedback(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const update_feedback_action_mutation = gql`
  mutation update_feedback($id: String!, $data: update_feedback_input!) {
    update_feedback_action: update_feedback(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_feedback_mutation = gql`
  mutation delete_feedback($id: String!) {
    delete_feedback(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
