import { Button, Modal, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { useTranslation } from "react-i18next";
import { dynamicClear } from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../../../services/redux/selector";
import { IoMdClose } from "react-icons/io";
import LoaderSpinner from "../../common/custom_spinner";

const SpotBookingReceiptModal = ({
  receipt_modal_open,
  set_receipt_modal_open,
  form,
  set_selected_id_proof,
  mobile,
  get_leader_mobile_no,
}) => {
  const items = useDynamicSelector("get_booking_user_details");
  const { booking_reference, loading: booking_detail_loading } =
    useDynamicSelector("get_booking_detail");
  console.log("booking_detail_loading", booking_detail_loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  console.log("get_leader_mobile_no", get_leader_mobile_no);
  const on_finish = (values) => {
    // set_selected_id_proof("Aadhaar Card");
    const url = `/print-receipt/${booking_reference}/${get_leader_mobile_no}`;
    const new_tab = window.open(url, "_blank", "width=850, height=900");
    if (new_tab) {
      new_tab.focus();
    }

    set_receipt_modal_open(false);
    dispatch(dynamicClear("get_booking_user_details"));
    // form.resetFields();
  };
  const on_close = () => {
    set_receipt_modal_open(false);
    form.resetFields();
  };

  const on_cancel = () => {
    set_receipt_modal_open(false);
    form.resetFields();
  };
  return (
    <div>
      <Modal
        title={t("success")}
        open={receipt_modal_open}
        onCancel={on_cancel}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          booking_reference && (
            <Button
              style={{
                backgroundColor: "#168f92",
                color: "white",
                border: "transparent",
              }}
              onClick={on_close}
            >
              {t("no")}
            </Button>
          ),
          booking_reference && (
            <Button
              style={{
                backgroundColor: "#f96451",
                color: "white",
                border: "transparent",
              }}
              htmlType="submit"
              onClick={on_finish}
            >
              {t("yes")}
            </Button>
          ),
        ]}
      >
        {booking_detail_loading === true ? (
          <LoaderSpinner message={"please Wait..."} />
        ) : (
          <>
            <Typography>{t("success_notification")}</Typography>
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              {booking_reference}
            </Typography>
          </>
        )}
      </Modal>
    </div>
  );
};

export default SpotBookingReceiptModal;
