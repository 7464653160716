import { gql } from "@apollo/client";
export const get_season_list_query = gql`
  query get_season_list(
    $filter: get_season_list_filter_input
    $sort: sort_input
    $pagination: pagination_input
  ) {
    get_season_list(filter: $filter, sort: $sort, pagination: $pagination) {
      items {
        day_start_time_in_minutes
        id
        title
        is_active
        ticket_price
        day_end_time_in_minutes
        slot_time_in_minutes
        end_date
        start_date
        vip_ticket_price
        date_slots {
          id
          season_id
          date
          start_time_in_minutes
          end_time_in_minutes
          confirmed_quota
          waiting_count
          time_slots {
            id
            start_time_in_minutes
            end_time_in_minutes
          }
        }
      }
      error {
        status_code
        message
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;

export const get_season_query = gql`
  query get_season($id: String!) {
    get_season(id: $id) {
      date_slots {
        id
        date_string
        season_id
        date
        start_time_in_minutes
        end_time_in_minutes
        confirmed_count
        waiting_count
        confirmed_quota
        spot_booking_count
        waiting_quota
        time_slots {
          id
          start_time_in_minutes
          end_time_in_minutes
        }
      }
    }
  }
`;

export const create_season_mutation = gql`
  mutation create_season($data: create_season_input!) {
    create_season(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_season_mutation = gql`
  mutation delete_season($id: String!) {
    delete_season(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_season_date_slots_mutation = gql`
  mutation update_season_date_slot(
    $id: String!
    $data: update_season_date_slot_input
  ) {
    update_season_date_slot(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const change_season_active_status_button = gql`
  mutation change_season_active_status(
    $id: String!
    $custom: change_season_active_status_input
  ) {
    change_season_active_status(id: $id, custom: $custom) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const enrollment_active_season_query = gql`
  query get_active_season {
    get_active_season {
      date_slots {
        id
        season_id
        date
      }
      id
      title
      is_active
      ticket_price
      end_date
      start_date
    }
  }
`;

export const active_season_date_slots_query = gql`
  query get_active_season {
    active_season_date_slot: get_active_season {
      date_slots {
        id
        date_string
        date
        confirmed_count
        spot_booking_count
        waiting_count
        waiting_quota
        confirmed_quota
      }
    }
  }
`;
