import { Form, Input } from "antd";
import React from "react";

const PageName = () => {
  return (
    <>
      <Form.Item
        name={"name"}
        label={"Name"}
        rules={[
          {
            required: true,
            message: "Please enter name",
          },
        ]}
      >
        <Input placeholder={"Name"} type="text" />
      </Form.Item>
    </>
  );
};
export default PageName;
