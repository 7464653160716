import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SystemUserPasswordInput = (props) => {
  const { modal_title } = props;
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t("password")}
      name="password"
      rules={
        modal_title === "add"
          ? [
              {
                required: true,
                message: "Please enter password",
              },
            ]
          : []
      }
    >
      <Input.Password />
    </Form.Item>
  );
};
export default SystemUserPasswordInput;
