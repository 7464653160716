import { Form, Select } from "antd";
import React, { useEffect } from "react";
import {
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../../services/redux";
import _orderBy from "lodash/orderBy";
import moment from "moment";
import { useDispatch } from "react-redux";

const DailyTransactionReportToDate = (props) => {
  const { set_date, from_date, set_selected_dates } = props;

  const { items: date_slot_items } = useDynamicSelector(
    "get_season_date_slot_list"
  );
  const dispatch = useDispatch();
  const date_list = () => {
    let keys = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variable = {
      filter: {
        from_date,
        to_date,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    date_list();
  }, []);

  const onchange_get_selected_dates = (values, option) => {
    set_selected_dates({ to_date: option?.label });
  };

  return (
    <div>
      <Form.Item
        name="to_date"
        label="To Date"
        rules={[{ required: true, message: "Please select To Date" }]}
        allowClear={true}
      >
        <Select
          placeholder="Select To Date"
          allowClear={true}
          onChange={onchange_get_selected_dates}
        >
          {date_slot_items?.map((date, index) => (
            <Select.Option
              key={index}
              value={date?.id}
              label={date?.date_string}
            >
              {date.date_string}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default DailyTransactionReportToDate;
