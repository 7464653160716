import { Card, Typography } from "antd";
import React from "react";
import { PAYMENT_SUCCESS } from "../../../../../helpers/image_constants";

const PaymentSuccessCard = () => {
  return (
    <Card
      title={"Payment Status"}
      headStyle={{
        backgroundColor: "#275670",
        color: "white",
        textAlign: "center",
        fontSize: "17px",
        height: "10px",
      }}
      style={{
        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.4)",
        width: "100%",
        height: "auto",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", marginLeft: "30%" }}>
        <img src={PAYMENT_SUCCESS} style={{ width: "7%" }} />
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            color: "green",
            marginLeft: "12px",
          }}
        >
          The payment has already succeeded
        </Typography>
      </div>
    </Card>
  );
};

export default PaymentSuccessCard;
