import { gql } from "@apollo/client";

export const introspection_query = gql`
  query IntrospectionQuery {
    schema: __schema {
      types {
        name
        fields {
          name
        }
      }
    }
  }
`;

export const get_system_ui_module_list_query = gql`
  query get_system_ui_module_list(
    $sort: sort_input
    $pagination: pagination_input
  ) {
    get_system_ui_module_list(sort: $sort, pagination: $pagination) {
      items {
        id
        name
        is_deleted
        ui_module_components {
          id
          name
          api_modules {
            id
            name
          }
          roles {
            id
            name
          }
        }
        roles {
          id
          name
        }
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const create_system_ui_module_page = gql`
  mutation create_system_ui_module($data: system_ui_module_input!) {
    create_system_ui_module(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_system_ui_module_page = gql`
  mutation update_system_ui_module(
    $id: String!
    $data: system_ui_module_input!
  ) {
    update_system_ui_module(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_system_ui_module_page = gql`
  mutation delete_system_ui_module(
    $id: String!
    $data: system_ui_module_input!
  ) {
    delete_system_ui_module(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const create_system_ui_module_component_mutation = gql`
  mutation create_system_ui_module_component(
    $data: system_ui_module_component_input!
  ) {
    create_system_ui_module_component(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_system_ui_module_component_mutation = gql`
  mutation update_system_ui_module_component(
    $id: String!
    $data: system_ui_module_component_update_input!
  ) {
    update_system_ui_module_component(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const update_link_end_point_system_ui_module_component_mutation = gql`
  mutation update_system_ui_module_component(
    $id: String!
    $data: system_ui_module_component_update_input!
  ) {
    update_link_end_point: update_system_ui_module_component(
      id: $id
      data: $data
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_system_ui_module_component_mutation = gql`
  mutation delete_system_ui_module_component(
    $id: String!
    $data: system_ui_module_component_input!
  ) {
    delete_system_ui_module_component(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_system_role_list_query = gql`
  query get_system_role_list($sort: sort_input, $pagination: pagination_input) {
    get_system_role_list(sort: $sort, pagination: $pagination) {
      items {
        id
        name
        priority
        is_deleted
        home_ui_module_id
        home_ui_module {
          id
          name
          roles {
            id
            name
          }
        }
        ui_modules {
          id
          name
          roles {
            id
            name
          }
        }
        ui_module_components {
          id
          name
          roles {
            id
            name
          }
        }
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const system_ui_module_role_access = gql`
  mutation set_system_ui_module_role_access(
    $custom: set_system_ui_module_role_access_input!
  ) {
    set_system_ui_module_role_access(custom: $custom) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const system_ui_module_component_role_access = gql`
  mutation set_system_ui_module_component_role_access(
    $custom: set_system_ui_module_component_role_access_input!
  ) {
    set_system_ui_module_component_role_access(custom: $custom) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_set_system_module_list_query = gql`
  query get_system_api_module_list(
    $sort: sort_input
    $pagination: pagination_input
  ) {
    get_system_api_module_list(sort: $sort, pagination: $pagination) {
      items {
        id
        name
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const sync_system_api_modules_mutation = gql`
  mutation sync_system_api_modules($custom: sync_system_api_module_input!) {
    sync_system_api_modules(custom: $custom) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
