import { Select } from "antd";
import Title from "antd/lib/skeleton/Title";
import React from "react";
import { useTranslation } from "react-i18next";

const SearchDistrict = (props) => {
  const { set_selected_district_id, district_items } = props;
  const { t } = useTranslation();

  const on_change = (values) => {
    set_selected_district_id(values);
  };

  const custom_filter_option = (input, option) => {
    const input_value = input.toLowerCase();
    const option_label = option.props.children.toLowerCase();
    return option_label.indexOf(input_value) === 0;
  };

  return (
 
      <Select
        style={{
          width: "200px",
          height: "30px",
          fontSize: "16px",
          marginRight: "10px",
        }}
        allowClear
        showSearch
        filterOption={custom_filter_option}
        onChange={on_change}
      >
        {district_items?.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
  );
};

export default SearchDistrict;
