import { Card, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import DistrictWiseReportForm from "../components/district_wise_report/district_wise_report_form/district_wise_report_form";
import DistrictWiseReportList from "../components/district_wise_report/district_wise_report_list/district_wise_report_list";
import DistrictWiseReportDetails from "../components/district_wise_report/district_wise_report_details/district_wise_report_details";
import {
  district_wise_report_query,
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import LoaderSpinner from "../components/common/custom_spinner";

const DistrictWiseReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected_date, set_selected_state] = useState("");
  const [selected_language, set_selected_language] = useState("Tamil");
  const [yesterday_date, set_yesterday_date] = useState();
  console.log("yesterday_date", yesterday_date);

  const {
    items: district_wise_count_item,
    loading: district_wise_count_item_loading,
  } = useDynamicSelector("get_district_wise_count");

  useEffect(() => {
    if (selected_language) {
      district_wise_count();
    }
  }, [selected_language]);

  useEffect(() => {
    dispatch(dynamicClear("get_district_wise_count"));
  }, []);

  const district_wise_count = (values) => {
    let key = [{ key: "get_district_wise_count", loading: true }];
    let query = district_wise_report_query;
    let variables = {
      filter: {
        date: selected_date,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <>
      <Row style={{ marginLeft: "10px" }}>
        <CustomPageTitle title={t("district_wise_report_title")} />
      </Row>
      <div style={{ marginTop: "20px" }}>
        <DistrictWiseReportForm
          set_selected_state={set_selected_state}
          district_wise_count={district_wise_count}
          set_yesterday_date={set_yesterday_date}
          selected_date={selected_date}
        />
      </div>

      {district_wise_count_item_loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <LoaderSpinner />
        </div>
      )}

      <div style={{ marginTop: "20px" }}>
        <DistrictWiseReportDetails
          district_wise_count={district_wise_count}
          set_selected_language={set_selected_language}
          selected_language={selected_language}
          selected_date={selected_date}
          yesterday_date={yesterday_date}
        />
      </div>
    </>
  );
};
export default DistrictWiseReport;
