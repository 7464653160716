import { Button, Col, Form, Row, Typography } from "antd";
import React from "react";
import EnrollActionBookingNoFormItem from "./enroll_action_form_item/enroll_action_booking_no_form_item";
import EnrollActionReceiptNoFormItem from "./enroll_action_form_item/enroll_action_receipt_no";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  query_enroll_action,
  useDynamicSelector,
} from "../../../../services/redux";

const EnrollActionMainForm = (props) => {
  const { enroll_action, set_enroll_number } = props;
  const { t } = useTranslation();
  const {} = useDynamicSelector("get_enroll_action");

  // const dispatch = useDispatch();
  // const { booking_reference } = useDynamicSelector("get_enroll_action");

  // // useEffect(() => {}, []);

  // const enroll_action = (values) => {
  //   console.log("valuesvaluesvalues", values);
  //   let key = [{ key: "get_enroll_action", loading: true }];
  //   let query = query_enroll_action;
  //   let variables = {
  //     filter: {
  //       booking_reference: values.booking_reference,
  //       receipt_number: values.receipt_number,
  //     },
  //   };
  //   dispatch(dynamicRequest(key, query, variables));
  // };
  const handle_finish = (values) => {
    console.log("values56", values);
    // set_enroll_number(values);
    enroll_action(values);
  };
  return (
    <div style={{ width: "100%" }}>
      <Form
        style={{ width: "100%" }}
        layout="vertical"
        onFinish={handle_finish}
      >
        <Row gutter={24}>
          <Col span={8}>
            <EnrollActionBookingNoFormItem />
          </Col>
          <Typography style={{ marginTop: "35px", fontWeight: "bold" }}>
            (OR)
          </Typography>

          <Col span={8}>
            <EnrollActionReceiptNoFormItem />
          </Col>
          <Col span={3} style={{ marginTop: "30px", marginLeft: "7px" }}>
            <Button
              htmlType="submit"
              style={{
                backgroundColor: "#025a80",
                color: "white",
                borderRadius: "5px",
              }}
            >
              {t("get_details_tile")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EnrollActionMainForm;
