import { gql } from "@apollo/client";

export const get_user_groups_list = gql`
  query get_user_group_user_list($filter: get_user_group_user_filter_input) {
    get_user_group_user_list(filter: $filter) {
      items {
        password
        name
        mobile
        email
        system_user {
          username
          id
        }
      }
    }
  }
`;
