import React, { useEffect, useState } from "react";
import { Row } from "antd";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";
import GroupWiseReportForm from "../components/system_report/group_wise_report/group_wise_report_form/group_wise_report_main_form";
import GroupWiseReportDetails from "../components/system_report/group_wise_report/group_wise_report_details/group_wise_report_details";
import {
  dynamicClear,
  dynamicRequest,
  get_date_slots,
  query_group_wise_report,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import LoaderSpinner from "../components/common/custom_spinner";

const GroupWiseReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [report_date, set_report_date] = useState({});
  const {
    items: group_wise_report_list,
    loading: group_wise_report_list_loading,
  } = useDynamicSelector("get_district_group_wise_count");
  const [yesterday_date, set_yesterday_date] = useState();

  console.log("report_date", report_date);

  const get_date_slot_list = () => {
    let key = [{ key: "counter_user_wise_report_date_slots", loading: true }];
    let query = get_date_slots;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_all_group_wise_list = () => {
    let key = [{ key: "get_district_group_wise_count", loading: true }];
    let query = query_group_wise_report;
    let variable = {
      filter: {
        date_slot_id: report_date.id,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  useEffect(() => {
    get_date_slot_list();
    // dispatch(dynamicClear("get_district_group_wise_count"));
  }, []);

  return (
    <>
      <Row style={{ marginLeft: "10px" }}>
        <CustomPageTitle title={t("group_wise_report_title")} />
      </Row>
      <div style={{ marginTop: "20px" }}>
        <GroupWiseReportForm
          get_all_group_wise_list={get_all_group_wise_list}
          set_report_date={set_report_date}
          set_yesterday_date={set_yesterday_date}
        />
      </div>
      {group_wise_report_list_loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <LoaderSpinner />
        </div>
      )}
      <div style={{ marginTop: "20px" }}>
        <GroupWiseReportDetails
          report_date={report_date}
          yesterday_date={yesterday_date}
          get_all_group_wise_list={get_all_group_wise_list}
        />
      </div>
      {/* ) : null} */}

      {/* <div style={{ marginTop: "20px" }}>
        <GroupWiseReportDetails
          report_date={report_date}
          get_all_group_wise_list={get_all_group_wise_list}
        />
      </div> */}
    </>
  );
};

export default GroupWiseReport;
