import React, { useEffect, useState } from "react";
import { Button, Drawer, Layout, Space } from "antd";
import Sidebar from "../components/system/sidebar/sidebar";
import { Box, HStack, Text, VStack } from "native-base";
import { ROUTES } from "../routes/my_routes";
import {
  MdCircleNotifications,
  MdOutlineDashboard,
  MdOutlineDashboardCustomize,
  MdOutlineDateRange,
  MdOutlineFeedback,
  MdOutlineManageAccounts,
  MdOutlineManageSearch,
  MdOutlineNotificationsActive,
  MdOutlinePendingActions,
  MdOutlinePlace,
  MdSecurity,
  MdSecurityUpdateGood,
  MdSpeakerNotes,
  MdToday,
} from "react-icons/md";
import { HiCurrencyRupee } from "react-icons/hi2";
import { GrSend, GrTransaction } from "react-icons/gr";
import { IoIosHelpBuoy, IoMdSettings } from "react-icons/io";
import {
  BiReceipt,
  BiSend,
  BiSolidBookAdd,
  BiSolidBookAlt,
  BiSolidBookContent,
  BiSolidGroup,
  BiSolidReport,
  BiSolidUser,
  BiSolidUserDetail,
} from "react-icons/bi";
import {
  BsBank,
  BsCoin,
  BsFillCalendar2DateFill,
  BsFillCalendarDateFill,
  BsJournalBookmark,
  BsPencilFill,
  BsPeopleFill,
  BsPersonVideo2,
} from "react-icons/bs";
import {
  AiFillBank,
  AiFillCrown,
  AiOutlineReconciliation,
  AiOutlineUnorderedList,
  AiOutlineUser,
  AiOutlineUserAdd,
  AiOutlineUserSwitch,
  AiTwotoneBank,
  AiTwotoneReconciliation,
} from "react-icons/ai";
import {
  MdCalendarToday,
  MdGroups2,
  MdManageAccounts,
  MdOutlineAccessTime,
  MdOutlinePayment,
} from "react-icons/md";
// import HeaderBar from "@views/components/user/header_bar/header_bar";
import HeaderBar from "../components/user/header_bar/header_bar";
import { useCheckLogin } from "@helpers/hooks";
import { navigate } from "@helpers/functions";
import { useTranslation } from "react-i18next";
import {
  TbAddressBook,
  TbBrandBooking,
  TbDeviceIpadMinus,
  TbFileReport,
  TbLayoutDashboard,
  TbReport,
  TbReportAnalytics,
  TbReportMoney,
} from "react-icons/tb";
import { ScheduleFilled } from "@ant-design/icons";
import {
  dynamicRequest,
  query_get_system_role_list,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../helpers/functions";
const { Content } = Layout;

const CustomLayout = ({ children }) => {
  const { t } = useTranslation();
  const is_logged_in = useCheckLogin();
  const [open, setOpen] = useState(false);

  const role = retrieveItem("role");
  // // const dispatch = useDispatch();
  // const { items: roles_items } = useDynamicSelector("get_roles");

  // const get_all_system_role_list = () => {
  //   let key = [{ key: "get_roles", loading: true }];
  //   let query = query_get_system_role_list;
  //   dispatch(dynamicRequest(key, query));
  // };
  // useEffect(() => {
  //   get_all_system_role_list();
  // }, []);

  // const system_user_sub_menu = roles_items?.map((role) => ({
  //   key: role?.name,
  //   icon: <BiSolidUserDetail />,
  //   label: role?.name,
  //   url: `${ROUTES.SYSTEM_USERS}/${role?.name}`,
  // }));

  // {
  //   key: "Dashboard User Dashboard",
  //   url: ROUTES.DASHBOARD,
  //   accessKey: "Dashboard User Dashboard",
  // },

  useEffect(() => {
    if (is_logged_in === false) {
      navigate(ROUTES.LOGIN);
    }
  }, [is_logged_in]);
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Layout>
      <HeaderBar setOpen={setOpen} />
      <HStack flex={1}>
        <VStack flex={1}>
          <Content style={{ overflowY: "auto", backgroundColor: "white" }}>
            {children}
          </Content>
        </VStack>
      </HStack>
      <Drawer
        // className="ant-drawer-title ant-drawer-close"
        title="Notifications"
        placement="right"
        onClose={onClose}
        open={open}
        closable
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
          </Space>
        }
      >
        <Box
          style={{
            padding: 10,
            alignItems: "center",
          }}
        >
          {/* <NotificationLists /> */}
        </Box>
      </Drawer>
    </Layout>
  );
};
export default CustomLayout;
