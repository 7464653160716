import { Button, Modal } from "antd";
import React from "react";
import { IoMdClose } from "react-icons/io";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useDynamicSelector } from "../../../../../services/redux";
import { useTranslation } from "react-i18next";
import CounterPaymentPdfRender from "../counter_payment_pdf_render/counter_payment_pdf_render";

const CounterPaymentReportModal = (props) => {
  const { visible, set_visible, count, counter, total_amount } = props;
  const { t } = useTranslation();
  const { items: counter_list } = useDynamicSelector("get_all_counter_list");
  //   const {
  //     items: daily_transaction_items,
  //     loading: daily_transaction_items_loading,
  //   } = useDynamicSelector("get_daily_transaction_summary");

  return (
    <Modal
      title={t("counter_payment_report")}
      open={visible}
      width={800}
      destroyOnClose={true}
      onCancel={() => set_visible(false)}
      footer={[
        <Button key="cancel" onClick={() => set_visible(false)}>
          Cancel
        </Button>,
        <PDFDownloadLink
          style={{ textDecoration: "none", marginLeft: "10px" }}
          document={
            //   <DailyTransactionPdfRender
            //     daily_transaction_items={daily_transaction_items}
            //   />
            <CounterPaymentPdfRender
              count={count}
              counter={counter}
              counter_list={counter_list}
              // selected_date={selected_date}
              total_amount={total_amount}
            />
          }
          fileName={`Daily-Transaction-Report.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              "Loading document..."
            ) : (
              <Button variant={"feedback_configer_button"}>Download</Button>
            )
          }
        </PDFDownloadLink>,
      ]}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PDFViewer width={"770px"} height={"400px"}>
          {/* <DailyTransactionPdfRender
            daily_transaction_items={daily_transaction_items}
          /> */}

          <CounterPaymentPdfRender
            count={count}
            counter={counter}
            counter_list={counter_list}
            // selected_date={selected_date}
            total_amount={total_amount}
          />
        </PDFViewer>
      </div>
    </Modal>
  );
};

export default CounterPaymentReportModal;
