import { Card, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const DistrictAdministrationCard = (props) => {
  const { handle_navigate } = props;
  const district_names = [
    "MADURAI",
    "SALEM",
    "CHENNAI",
    "THIRUVARUR",
    "NAGAPPATINAM",
    "CANADA",
    "MADURAI",
    "SALEM",
    "CHENNAI",
    "THIRUVARUR",
    "NAGAPPATINAM",
    "CANADA",
    "MADURAI",
    "SALEM",
    "CHENNAI",
    "THIRUVARUR",
    "NAGAPPATINAM",
    "CANADA",
    "MADURAI",
    "SALEM",
    "CHENNAI",
    "THIRUVARUR",
    "NAGAPPATINAM",
    "CANADA",
    // Add more district names here
  ];

  // Function to generate a row of cards for a district
  const generateDistrictRow = (district) => (
    <Col span={8} key={district}>
      <Link to={`/district/${district}`}>
        {" "}
        {/* Use Link to navigate */}
        <div>
          <Card
            style={{
              height: "30px",
              borderRadius: "10px",
              backgroundColor: "#eecc50",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            {district}
          </Card>
        </div>
      </Link>
    </Col>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        borderRadius: "20px",
        marginTop: "10px",
      }}
    >
      <Card
        style={{ width: "95%", borderRadius: "10px" }}
        onClick={handle_navigate}
      >
        <Row gutter={[16, 16]}>
          {district_names.map((district) => generateDistrictRow(district))}
        </Row>
      </Card>
    </div>
  );
};

export default DistrictAdministrationCard;
