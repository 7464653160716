import { Checkbox, Form, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useDynamicSelector } from "../../../services/redux";

const DistrictUserCheckbox = (props) => {
  const { option, index, handle_on_change } = props;
  const [is_checked, set_is_checked] = useState(option.is_active);

  const handle_checkbox_change = (e) => {
    const new_checked_value = e.target.checked;
    set_is_checked(new_checked_value);
    handle_on_change(e, index, option.id);
  };
  useEffect(() => {
    set_is_checked(option.is_active);
  }, [option.is_active]);

  return (
    <>
      <Form.Item
        key={option.id}
        name={`mandram-${option.id}`}
        valuePropName="checked"
      >
        <Checkbox onChange={handle_checkbox_change} defaultChecked={is_checked}>
          {option.code}/{option.name}
        </Checkbox>
      </Form.Item>
    </>
  );
};
export default DistrictUserCheckbox;
