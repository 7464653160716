import { Button, Row, Typography } from "antd";
import React, { useState } from "react";
import MandramUserList from "./mandram_user_list";
import AddMandramUserModal from "./add_mandram_user/add_mandram_user_modal";
const { Title } = Typography;

const ExpandableMandramForm = (props) => {
  const { key, record_id, mandram_items, expanded_row_key } = props;

  const [mandram_user, set_add_mandram_user] = useState("");
  const [header_type, set_header_type] = useState("");

  const add_mandram_user = () => {
    set_header_type("Add Mandram User");
    set_add_mandram_user(true);
  };

  return (
    <Row
      key={key}
      style={{
        display: "flex",
        justifyContent: "flex-end",
        width: "90%",
        borderRadius: "10px",
      }}
    >
      {/* <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          margin: "5px",
          padding: "5px",
        }}
      >
        <Title level={5} style={{ color: "white" }}>
          MANDRAM(VSG) USER
        </Title>
        <Button
          style={{
            backgroundColor: "#168f92",
            color: "white",
            border: "transparent",
          }}
          onClick={add_mandram_user}
        >
          Add Mandram User
        </Button>
      </Row> */}
      <MandramUserList
        key={key}
        record_id={record_id}
        mandram_items={mandram_items}
        expanded_row_key={expanded_row_key}
      />
      <AddMandramUserModal
        set_add_mandram_user={set_add_mandram_user}
        mandram_user={mandram_user}
        header_type={header_type}
        set_header_type={set_header_type}
      />
    </Row>
  );
};

export default ExpandableMandramForm;
