import { Form, Input, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDynamicSelector } from "../../../../services/redux";

const VipBookingUserCountAmtSection = () => {
  const { t } = useTranslation();
  const { items: season_date_slot_list_items } = useDynamicSelector(
    "get_season_date_slot_list"
  );

  const vip_ticket_price =
    season_date_slot_list_items?.[0]?.season?.vip_ticket_price || 0;

  // const [ticket_count, set_ticket_count] = useState(0);
  const [total_ticket_amount, set_total_ticket_amount] = useState(0);

  const onCountChange = (e) => {
    console.log("val", e.target.value);

    set_total_ticket_amount(parseInt(e.target.value) * vip_ticket_price);
  };
  console.log("total_ticket_amount", total_ticket_amount);

  return (
    <div className="vip_booking_form_item_double_container">
      <div className="vip_booking_form_item_double_wrapper">
        <Form.Item
          name="count"
          label={t("Count")}
          rules={[{ required: true, message: t("Please enter the count") }]}
        >
          <Input
            placeholder={t("Enter count")}
            // value={ticket_count}
            onChange={onCountChange}
          />
        </Form.Item>
      </div>
      <div className="vip_booking_form_item_double_wrapper">
        <Typography style={{ marginBottom: "2%", marginTop: "4px" }}>
          Amount
        </Typography>
        <Input type="number" prefix="₹" value={(total_ticket_amount)} readOnly />
      </div>
    </div>
  );
};

export default VipBookingUserCountAmtSection;
