import React, { useEffect, useState } from "react";
import { Button, Drawer, Layout, Space } from "antd";
import Sidebar from "../components/system/sidebar/sidebar";
import { Box, HStack, VStack } from "native-base";
import { ROUTES } from "../routes/my_routes";
import {
  MdCircleNotifications,
  MdGroup,
  MdOutlineCallToAction,
  MdOutlineDashboard,
  MdOutlineDashboardCustomize,
  MdOutlineDateRange,
  MdOutlineFeedback,
  MdOutlineManageAccounts,
  MdOutlineManageSearch,
  MdOutlinePendingActions,
  MdOutlinePlace,
  MdSecurityUpdateGood,
  MdSpeakerNotes,
  MdToday,
} from "react-icons/md";
import { PiMapPinAreaFill } from "react-icons/pi";
import { HiCurrencyRupee } from "react-icons/hi2";
import { IoMdSettings } from "react-icons/io";
import {
  BiReceipt,
  BiSolidBookAlt,
  BiSolidBookContent,
  BiSolidGroup,
  BiSolidReport,
  BiSolidUser,
  BiSolidUserDetail,
} from "react-icons/bi";
import {
  BsBank,
  BsFillCalendarDateFill,
  BsPeopleFill,
  BsPersonVideo2,
} from "react-icons/bs";
import {
  AiFillAppstore,
  AiOutlineReconciliation,
  AiOutlineUnorderedList,
  AiOutlineUser,
  AiOutlineUserAdd,
  AiTwotoneBank,
} from "react-icons/ai";
import { RiVipCrownLine } from "react-icons/ri";
import {
  MdGroups2,
  MdOutlineAccessTime,
  MdOutlinePayment,
} from "react-icons/md";
// import HeaderBar from "@views/components/user/header_bar/header_bar";
import HeaderBar from "../components/user/header_bar/header_bar";
import { useCheckLogin } from "@helpers/hooks";
import { navigate } from "@helpers/functions";
import { useTranslation } from "react-i18next";
import {
  TbAddressBook,
  TbBrandBooking,
  TbLayoutDashboard,
  TbReport,
  TbReportAnalytics,
  TbReportSearch,
} from "react-icons/tb";
import { ScheduleFilled } from "@ant-design/icons";
import { retrieveItem } from "../../helpers/functions";
const { Content } = Layout;

const UserLayout = ({ children }) => {
  const { t } = useTranslation();
  const is_logged_in = useCheckLogin();
  const [open, setOpen] = useState(false);

  const role = retrieveItem("role");
  // // const dispatch = useDispatch();
  // const { items: roles_items } = useDynamicSelector("get_roles");

  // const get_all_system_role_list = () => {
  //   let key = [{ key: "get_roles", loading: true }];
  //   let query = query_get_system_role_list;
  //   dispatch(dynamicRequest(key, query));
  // };
  // useEffect(() => {
  //   get_all_system_role_list();
  // }, []);

  // const system_user_sub_menu = roles_items?.map((role) => ({
  //   key: role?.name,
  //   icon: <BiSolidUserDetail />,
  //   label: role?.name,
  //   url: `${ROUTES.SYSTEM_USERS}/${role?.name}`,
  // }));

  const menu_list = [
    {
      key: "Irumudi Calendar",
      icon: <BsFillCalendarDateFill />,
      label: t("ir-calendar"),
      url: ROUTES.IRUMUDI_CALENDAR,
      accessKey: "Irumudi Calendar",
    },
    {
      key: "Booking",
      icon: <BiSolidBookContent />,
      label: t("booking"),
      url: ROUTES.BOOKING,
      accessKey: "Booking",
    },
    {
      key: "VIP Booking",
      icon: <BiSolidBookContent />,
      label: t("vip_booking"),
      url: ROUTES.VIP_BOOKING,
      accessKey: "VIP Booking",
    },
    {
      key: "VIP Booking Users",
      icon: <RiVipCrownLine />,
      label: t("vip_booking_users"),
      url: ROUTES.VIP_BOOKING_USERS,
      accessKey: "VIP Booking Users",
    },

    {
      key: "VIP Booking History",
      icon: <RiVipCrownLine />,
      label: t("vip_booking_history"),
      url: ROUTES.VIP_BOOKING_HISTORY,
      accessKey: "VIP Booking History",
    },
    {
      key: "VIP Enrollment List",
      icon: <BiSolidBookContent />,
      label: t("vip_enrollment_list"),
      url: ROUTES.VIP_ENROLLMENT_LIST,
      accessKey: "VIP Enrollment List",
    },
    {
      key: "Overall Dashboard",
      icon: <TbLayoutDashboard />,
      label: t("overall_dashboard_menu"),
      url: ROUTES.OVERALL_DASHBOARD,
      accessKey: "Overall Dashboard",
    },
    {
      key: "Today Dashboard",
      icon: <MdOutlineDashboardCustomize />,
      label: t("today_dashboard"),
      url: ROUTES.TODAY_DASHBOARD,
      accessKey: "Today Dashboard",
    },
    {
      key: "SMS Dashboard",
      icon: <TbLayoutDashboard />,
      label: t("sms_dashboard"),
      url: ROUTES.SMS_DASHBOARD,
      accessKey: "SMS Dashboard",
    },
    {
      key: "District Dashboard",
      icon: <MdOutlineDashboardCustomize />,
      label: t("district_dashboard"),
      url: ROUTES.DISTRICT_DASHBOARD,
      accessKey: "District Dashboard",
    },
    {
      key: "Sub District Dashboard",
      icon: <MdOutlineDashboardCustomize />,
      label: t("sub_district_dashboard"),
      url: ROUTES.SUB_DISTRICT_DASHBOARD,
      accessKey: "Sub District Dashboard",
    },

    {
      key: "Enrolment Session",
      icon: <AiFillAppstore />,
      label: t("enrolment_session"),
      url: ROUTES.ENROLMENT_SESSION,
      accessKey: "Enrolment Session",
    },

    {
      key: "Active Season Date Slots",
      icon: <MdSecurityUpdateGood />,
      label: t("active_season_date_slot"),
      url: ROUTES.ACTIVE_DATE_SLOTS,
      accessKey: "Active Season Date Slots",
    },
    {
      key: "District",
      icon: <MdOutlinePlace />,
      label: t("district"),
      url: ROUTES.DISTRICT,
      accessKey: "Manage District  ",
    },
    {
      key: "Sub District",
      icon: <PiMapPinAreaFill />,
      label: t("sub_district"),
      url: ROUTES.SUBDISTRICT,
      accessKey: "Sub District",
    },
    {
      key: "Mandram",
      icon: <BiSolidGroup />,
      label: t("mandram"),
      url: ROUTES.MANDRAM,
      accessKey: "Manage Mandram",
    },
    {
      key: "MandramWise Count",
      icon: <MdOutlineManageAccounts />,
      label: t("mandram_wise_count"),
      url: ROUTES.MANDRAM_WISE_COUNT,
      accessKey: "Mandramwise Count",
    },
    {
      key: "Enroll Action",
      icon: <MdOutlineCallToAction />,
      label: t("enroll_action"),
      url: ROUTES.ENROLL_ACTION,
      accessKey: "Enroll Action",
    },
    {
      key: "Mandram Renewal",
      icon: <MdOutlineCallToAction />,
      label: t("mandram_renewal"),
      url: ROUTES.MANDRAM_RENEWAL,
      accessKey: "Mandram Renewal",
    },

    // {
    //   key: "district_administration",
    //   icon: <TbDeviceIpadMinus />,
    //   label: t("district_administration_label"),
    //   url: ROUTES.DISTRICT_ADMINISTRATION,
    //   accessKey: "district_administration",
    // },

    {
      key: "System Reports",
      label: t("system_reports"),
      icon: <TbReportAnalytics />,
      accessKey: "System Reports",
      children: [
        // {
        //   key: "Accounts Reports",
        //   icon: <MdManageAccounts />,
        //   label: t("accounts_report"),
        //   url: ROUTES.ACCOUNTS_REPORT,
        //   accessKey: "System Reports",
        // },
        {
          key: "Om Sakthi Report",
          icon: <BiSolidReport />,
          label: t("om_sakthi_report"),
          url: ROUTES.OM_SAKTHI_REPORT,
          accessKey: "System Reports",
        },
        {
          key: "GroupWise Reports",
          icon: <MdGroups2 />,
          label: t("group_wise_reports"),
          url: ROUTES.GROUP_WISE_REPORT,
          accessKey: "System Reports",
        },
        {
          key: "Counter UserWise Reports",
          icon: <MdGroups2 />,
          label: t("counter_user_wise_report"),
          url: ROUTES.COUNTER_USER_WISE_REPORT,
          accessKey: "System Reports",
        },

        {
          key: "DistrictWise Reports",
          icon: <AiOutlineReconciliation />,
          label: t("district_wise_report"),
          url: ROUTES.DISTRICT_WISE_REPORT,
          accessKey: "System Reports",
        },
        {
          key: "User Counter Wise Reports",
          icon: <MdGroups2 />,
          label: t("user_counter_wise_report"),
          url: ROUTES.USER_COUNTER_WISE_REPORT,
          accessKey: "System Reports",
        },
        {
          key: "Counter GroupWise Reports",
          icon: <MdGroups2 />,
          label: t("counter_group_wise_report"),
          url: ROUTES.COUNTER_GROUP_WISE_REPORT,
          accessKey: "System Reports",
        },
        {
          key: "User GroupWise Reports",
          icon: <MdGroups2 />,
          label: t("user_group_wise_report"),
          url: ROUTES.USER_GROUP_WISE_REPORT,
          accessKey: "System Reports",
        },
        // {
        //   key: "DistrictWise Reports",
        //   icon: <AiOutlineReconciliation />,
        //   label: t("district_wise_report"),
        //   url: ROUTES.DISTRICT_WISE_REPORT,
        //   accessKey: "System Reports",
        // },
      ],
    },
    {
      key: "Other Reports",
      label: t("other_reports"),
      icon: <TbReportSearch />,
      accessKey: "System Reports",
      children: [
        {
          key: "SakthiPeedam Reports",
          icon: <TbReport />,
          label: t("sakthi_peedam_report"),
          url: ROUTES.SAKTHI_PEEDAM_REPORTS,
          accessKey: "System Reports",
        },

        {
          key: "Payment Mode Reports",
          icon: <BiSolidReport />,
          label: t("payment_mode_reports"),
          url: ROUTES.PAYMENT_MODE_REPORT,
          accessKey: "System Reports",
        },
        {
          key: "Devotee payment Reports",
          icon: <MdOutlinePayment />,
          label: t("devotee_payment_report"),
          url: ROUTES.DEVOTEE_PAYMENT_REPORT,
          accessKey: "System Reports",
        },
        // {
        //   key: "Accounts Reports",
        //   icon: <MdManageAccounts />,
        //   label: t("accounts_report"),
        //   url: ROUTES.ACCOUNTS_REPORT,
        //   accessKey: "System Reports",
        // },
        {
          key: "Counter Payment Reports",
          icon: <MdGroups2 />,
          label: t("counter_payment_report"),
          url: ROUTES.COUNTER_PAYMENT_REPORT,
          accessKey: "System Reports",
        },
        // {
        //   key: "GroupWise Reports",
        //   icon: <MdGroups2 />,
        //   label: t("group_wise_reports"),
        //   url: ROUTES.GROUP_WISE_REPORT,
        //   accessKey: "System Reports",
        // },
        // {
        //   key: "Counter UserWise Reports",
        //   icon: <MdGroups2 />,
        //   label: t("counter_user_wise_report"),
        //   url: ROUTES.COUNTER_USER_WISE_REPORT,
        //   accessKey: "System Reports",
        // },
        // {
        //   key: "Counter Payment Reports",
        //   icon: <MdGroups2 />,
        //   label: t("counter_payment_report"),
        //   url: ROUTES.COUNTER_PAYMENT_REPORT,
        //   accessKey: "System Reports",
        // },
        {
          key: "Date Wise Report",
          icon: <MdOutlineDateRange />,
          label: t("date_wise_report"),
          url: ROUTES.DATE_WISE_REPORT,
          accessKey: "System Reports",
        },
        {
          key: "Daily Transaction Report",
          icon: <HiCurrencyRupee />,
          label: t("daily_transaction_report"),
          url: ROUTES.TRANSACTION_REPORT,
          accessKey: "System Reports",
        },
        {
          key: "Day Wise Report",
          icon: <MdToday />,
          label: t("day_wse_rpt"),
          url: ROUTES.DAY_WISE_REPORT,
          accessKey: "System Reports",
        },
        {
          key: "Address Wise Report",
          icon: <TbAddressBook />,
          label: t("address_wise_report_title"),
          url: ROUTES.ADDRESS_WISE_REPORT,
          accessKey: "System Reports",
        },
        {
          key: "Date Wise Booking Report",
          icon: <TbAddressBook />,
          label: t("date_wise_booking_report"),
          url: ROUTES.DATE_WISE_BOOKING_REPORT,
          accessKey: "System Reports",
        },
      ],
    },
    {
      key: "Booking Report",
      icon: <TbAddressBook />,
      label: t("Booking_report_title"),
      url: ROUTES.BOOKING_REPORT,
      accessKey: "Booking Report",
    },
    {
      key: "Settings",
      icon: <IoMdSettings />,
      label: t("settings"),
      accessKey: "Settings",
      children: [
        // {
        //   key: "time_manage",
        //   icon: <MdOutlineAccessTime />,
        //   label: t("time_manage"),
        //   url: ROUTES.TIME_MANAGE,
        //   accessKey: "time_manage",
        // },
        {
          key: "season",
          icon: <TbBrandBooking />,
          label: t("season_label"),
          url: ROUTES.SEASON,
          accessKey: "Settings",
        },
        // {
        //   key: "payment_mode",
        //   icon: <BsCoin />,
        //   label: t("payment_mode"),
        //   url: ROUTES.PAYMENT_MODE,
        //   accessKey: "payment_mode",
        // },
        {
          key: "bank",
          icon: <BsBank />,
          label: t("bank"),
          url: ROUTES.BANK,
          accessKey: "Settings",
        },

        // {
        //   key: "security_code_setting",
        //   icon: <MdSecurity />,
        //   label: t("security_code_setting"),
        //   url: ROUTES.SECURITY_CODE_SETTING,
        //   accessKey: "security_code_setting",
        // },
        {
          key: "counter",
          icon: <ScheduleFilled />,
          label: t("counter"),
          url: ROUTES.COUNTER,
          accessKey: "Settings",
        },
        {
          key: "counter_group",
          icon: <ScheduleFilled />,
          label: t("counter_group"),
          url: ROUTES.COUNTER_GROUP,
          accessKey: "Settings",
        },
        {
          key: "user_group",
          icon: <MdGroup />,
          label: t("user_group"),
          url: ROUTES.USER_GROUP,
          accessKey: "Settings",
        },
        {
          key: "group",
          icon: <MdGroups2 />,
          label: t("group"),
          url: ROUTES.GROUP,
          accessKey: "Settings",
        },
        {
          key: "Notification",
          icon: <MdCircleNotifications />,
          label: t("notification"),
          url: ROUTES.NOTIFICATIONS,
          accessKey: "Settings",
        },
      ],
    },
    {
      key: "System Users",
      icon: <BiSolidUser />,
      label: t("system_users"),
      // url: ROUTES.SYSTEM_USERS,
      accessKey: "System Users",
      children: [
        {
          key: "bank enrolment officer",
          icon: <AiTwotoneBank />,
          label: t("bank_enrolment_officer"),
          url: ROUTES.BANK_ENROLMENT_OFFICER,
          accessKey: "System Users",
        },
        {
          key: "bank branch enrolment officer",
          icon: <AiTwotoneBank />,
          label: t("bank_branch_enrolment_officer"),
          url: ROUTES.BANK_BRANCH_ENROLMENT_OFFICER,
          accessKey: "System Users",
        },
        {
          key: "dashboard user",
          icon: <MdOutlineDashboard />,
          label: t("dashboard_user"),
          url: ROUTES.DASHBOARD_USER,
          accessKey: "System Users",
        },
        {
          key: "dashboard admin",
          icon: <MdOutlineDashboard />,
          label: t("dashboard_admin"),
          url: ROUTES.DASHBOARD_ADMIN,
          accessKey: "System Users",
        },
        {
          key: "enrolment officer",
          icon: <BiSolidUserDetail />,
          label: t("enrolment_officer"),
          url: ROUTES.ENROLMENT_OFFICER,
          accessKey: "System Users",
        },
        {
          key: "helpdesk officer",
          icon: <BsPeopleFill />,
          label: t("helpdesk_officer"),
          url: ROUTES.HELPDESK_OFFICER,
          accessKey: "System Users",
        },
        // {
        //   key: "Spl enrolment officer",
        //   icon: <BsPencilFill />,
        //   label: t("spl_enrolment_officer"),
        //   url: ROUTES.SPL_ENROLMENT_OFFICER,
        //   accessKey: "System Users",
        // },
        {
          key: "VIP enrolment officer",
          icon: <BsPersonVideo2 />,
          label: t("vip_enrolment_officer"),
          url: ROUTES.VIP_ENROLMENT_OFFICER,
          accessKey: "System Users",
        },
        {
          key: "MASM user",
          icon: <BiSolidUserDetail />,
          label: t("masm_user"),
          url: ROUTES.MASM_USER,
          accessKey: "System Users",
        },

        {
          key: "Enrolment Action User",
          icon: <BiSolidUserDetail />,
          label: t("enrolment_action_user"),
          url: ROUTES.ENROLMENT_ACTION_USER,
          accessKey: "System Users",
        },
      ],
    },
    {
      key: "Additional Users",
      icon: <AiOutlineUnorderedList />,
      label: t("additional_users"),
      url: ROUTES.ADDITIONAL_USERS,
      accessKey: "Additional Users",
    },
    {
      key: "Enroll",
      icon: <AiOutlineUserAdd />,
      label: t("enroll"),
      url: ROUTES.ENROLL,
      accessKey: "Enroll",
    },
    {
      key: "Receipt",
      icon: <BiReceipt />,
      label: t("receipt"),
      url: ROUTES.RECEIPT,
      accessKey: "Receipt",
    },

    // {
    //   key: "managing provisions",
    //   icon: <AiOutlineUnorderedList />,
    //   label: t("Managing provisions"),
    //   url: ROUTES.MANAGING_PROVISIONS,
    //   accessKey: "Managing Provisions",
    // },

    {
      key: "Spot Booking",
      icon: <AiOutlineUser />,
      label: t("spot_booking"),
      url: ROUTES.SPOT_BOOKING,
      accessKey: "Spot Booking",
    },
    {
      key: "Booking Action",
      icon: <MdOutlinePendingActions />,
      label: t("booking_action"),
      url: ROUTES.BOOKING_ACTION,
      accessKey: "Booking Action",
    },
    {
      key: "Booking Summary",
      icon: <BiSolidBookAlt />,
      label: t("booking_history"),
      url: ROUTES.BOOKING_HISTORY,
      accessKey: "Booking Summary",
    },
    // {
    //   key: "Reports",
    //   icon: <BiSolidReport />,
    //   label: t("reports"),
    //   url: ROUTES.REPORTS,
    //   accessKey: "Reports",
    // },

    {
      key: "Manage Feedback",
      icon: <MdOutlineManageSearch />,
      label: t("manage_feedback"),
      accessKey: "Manage Feedback",
      children: [
        {
          key: "Feedback Manage",
          // icon: <MdOutlineAccessTime />,
          label: t("feedback_manage_label"),
          url: ROUTES.FEEDBACK_MANAGE,
          accessKey: "Manage Feedback",
        },
        {
          key: "User Response",
          // icon: <MdOutlineAccessTime />,
          label: t("user_response_label"),
          url: ROUTES.USER_RESPONSE,
          accessKey: "Manage Feedback",
        },
      ],
    },
    {
      key: "Billing History",
      icon: <MdSpeakerNotes />,
      label: t("billing_history"),
      url: ROUTES.BILLING_HISTORY,
      accessKey: "Billing History",
    },

    {
      key: "General Settings",
      icon: <MdSpeakerNotes />,
      label: t("general_settings"),
      url: ROUTES.EXTRA_ALLOWED_PERCENTAGE,
      accessKey: "General Settings",
    },
    {
      key: "day_wise_transactions",
      icon: <MdOutlineAccessTime />,
      label: t("day_wise_transaction"),
      url: ROUTES.DAY_WISE_TRANSACTIONS,
      accessKey: "day_wise_transactions",
    },
    {
      key: "Feedback",
      icon: <MdOutlineFeedback />,
      label: t("feedback_label"),
      url: ROUTES.FEEDBACK,
      accessKey: "Feedback",
    },
    {
      key: "POS",
      icon: <MdOutlineFeedback />,
      label: t("pos"),
      url: ROUTES.POS,
      accessKey: "POS",
    },
    // {
    //   key: "Send Email",
    //   icon: <BiSend />,
    //   label: t("send_email"),
    //   url: ROUTES.SEND_EMAIL,
    //   accessKey: "Send Email",
    // },
    // {
    //   key: "Access Rights",
    //   icon: <AiFillCrown />,
    //   label: t("access_rights"),
    //   url: ROUTES.ACCESS_RIGHTS,
    //   accessKey: "Access Rights",
    // },
  ];

  useEffect(() => {
    if (is_logged_in === false) {
      navigate(ROUTES.LOGIN);
    }
  }, [is_logged_in]);
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Layout>
      <HeaderBar setOpen={setOpen} />
      <HStack flex={1}>
        <Sidebar menu_list={menu_list} />
        <VStack flex={1}>
          <Content style={{ overflowY: "auto", backgroundColor: "white" }}>
            {children}
          </Content>
        </VStack>
      </HStack>
      <Drawer
        // className="ant-drawer-title ant-drawer-close"
        title="Notifications"
        placement="right"
        onClose={onClose}
        open={open}
        closable
        extra={
          <Space>
            <Button onClick={onClose}>Close</Button>
          </Space>
        }
      >
        <Box
          style={{
            padding: 10,
            alignItems: "center",
          }}
        >
          {/* <NotificationLists /> */}
        </Box>
      </Drawer>
    </Layout>
  );
};
export default UserLayout;
