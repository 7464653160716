import { Form } from "antd";
import React, { useEffect, useState } from "react";
import QuestionFormItem from "./question_form_item";
import QuestionTypeFormItem from "./question_type";
import {
  create_feedback_question__mutation,
  dynamicClear,
  dynamicRequest,
  update_feedback_question__mutation,
  useDynamicSelector,
} from "../../../../../../services/redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FeedbackQuestionSwitchButton from "./question_form_active_switch_form_item";

const AddQuestionForm = (props) => {
  const [form] = Form.useForm();
  const { id, handle_switch } = props;
  const {
    set_edit_modal_visible,
    header_type,
    get_all_feedback_question_query,
    edit_values,
  } = props;
  const {
    status: create_feedback_question_status,
    error: create_feedback_question_error,
  } = useDynamicSelector("create_feedback_question");
  const {
    status: update_feedback_question_status,
    error: update_feedback_question_error,
  } = useDynamicSelector("update_feedback_question");

  const dispatch = useDispatch();
  const [initial_values, set_initial_values] = useState({});
  const [is_checked, set_is_checked] = useState(false);

  useEffect(() => {
    if (edit_values.id) {
      form.setFieldsValue({
        ...edit_values,
        is_active: edit_values?.is_active,
      });
      set_is_checked(edit_values?.is_active);
      set_initial_values({
        ...edit_values,
        is_active: edit_values?.is_active,
      });
    } else {
      // form.resetFields();
    }
  }, [edit_values]);

  useEffect(() => {
    if (create_feedback_question_status === "success") {
      toast.success("Question created successfully");
      set_edit_modal_visible(false);
      get_all_feedback_question_query();
      dispatch(dynamicClear("create_feedback_question"));
    } else if (create_feedback_question_error?.message) {
      toast.error(create_feedback_question_error.message);
      get_all_feedback_question_query();
      dispatch(dynamicClear("create_feedback_question"));
    }
    if (update_feedback_question_status === "success") {
      toast.success("Question updated successfully");
      set_edit_modal_visible(false);
      get_all_feedback_question_query();
      dispatch(dynamicClear("update_feedback_question"));
    } else if (update_feedback_question_error?.message) {
      toast.error(update_feedback_question_error.message);
      get_all_feedback_question_query();
      dispatch(dynamicClear("update_feedback_question"));
    }
  }, [
    create_feedback_question_status,
    create_feedback_question_error,
    update_feedback_question_status,
    update_feedback_question_error,
  ]);

  const create_feedback_question = (values) => {
    let key = [{ key: "create_feedback_question", loading: true }];
    let query = create_feedback_question__mutation;
    let variable = {
      data: {
        question: values.question,
        type: values.type,
        feedback_id: id,
      },
    };

    dispatch(dynamicRequest(key, query, variable));
  };

  const update_feedback_question = (edit_id, values) => {
    let key = [{ key: "update_feedback_question", loading: true }];
    let query = update_feedback_question__mutation;
    let variable = {
      id: edit_id,
      data: {
        question: values.question,
        type: values.type,
        is_active: true,
        // is_active: values.is_active,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const handle_finish = (values) => {
    if (header_type === "add") {
      create_feedback_question(values);
    } else if (header_type === "edit") {
      let edit_id = edit_values?.id;
      update_feedback_question(edit_id, values);
    }
  };

  return (
    <div>
      <Form
        id="question_form"
        onFinish={handle_finish}
        initialValues={initial_values}
        form={form}
      >
        <QuestionFormItem />
        <QuestionTypeFormItem />
        {/* <FeedbackQuestionSwitchButton
          set_is_checked={set_is_checked}
          is_checked={is_checked}
          // handle_switch={handle_switch}
        /> */}
      </Form>
    </div>
  );
};

export default AddQuestionForm;
