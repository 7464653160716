import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ProfileEmailIdFormItem = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name="email"
        label={t("email_id")}
        rules={[
          {
            required: true,
            message: t("email_id_is_required"),
          },
        ]}
      >
        <Input placeholder="Email id"></Input>
      </Form.Item>
    </div>
  );
};

export default ProfileEmailIdFormItem;
