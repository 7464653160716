import { Form, Switch } from "antd";
import React from "react";

const InputSubDistrictIsForeign = (props) => {
  const is_add_district = props.header_type === "add_district";

  return (
    <>
      <Form.Item name="is_foreign" label="Foreign" valuePropName="checked">
        <Switch defaultChecked={false} />
      </Form.Item>
    </>
  );
};

export default InputSubDistrictIsForeign;
