import React from "react";
import InputAdminName from "../form_inputs/input_admin_name";
import InputAdminEmail from "../form_inputs/input_admin_email";
import InputAdminMobile from "../form_inputs/input_admin_mobile";
import { Col, Row } from "antd";
import InputAdminUserName from "../form_inputs/input_admin_username";
import InputSystemUserPassword from "../form_inputs/input_system_user_password";

export const Admin = (props) => {
  return (
    <>
      <Col
        style={{
          padding: "10px",
          borderRadius: "6px",
          width: "100%",
          backgroundColor: "rgba(211, 211, 211, 0.2)",
          marginTop: "10px",
        }}
      >
        <h3 style={{ marginLeft: "12px" }}>Admin</h3>
        <Row gutter={16} justify={"space-between"}>
          <Col span={12}>
            <InputAdminName />
            <InputAdminEmail />
            <InputAdminMobile />
          </Col>
          <Col span={11}>
            <InputAdminUserName header_type={props.header_type} />
            <InputSystemUserPassword header_type={props.header_type} />
          </Col>
        </Row>
      </Col>
    </>
  );
};
