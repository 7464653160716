import React from "react";
import { useDynamicSelector } from "../../../../services/redux";

const table_headers = [
  "Payment Mode",
  "Payment Reference Number",
  "Date",
  "Donor Name/Address",
  "Id Proof/Number",
  "Amount",
];

const DonationTable = () => {
  const { booking_user } = useDynamicSelector("get_booking_receipt");
  return (
    <>
      <style>
        {`
        @media Print{
          .address-column{
            height: auto; 
            display: "flex";
            justify-content: "space-between";
            flex-direction: "column";
          }
          .address{
            margin-bottom:8px;
            margin-left:5px;
            width: auto;
            alignItems: "flex-start";
          }
          .name{
          }
        }
      `}
      </style>
      <table
        style={{
          border: "1px solid #000",
          borderCollapse: "collapse",
          width: "97%",
          margin: "auto",
        }}
      >
        <thead>
          <tr>
            {table_headers.map((header) => (
              <th style={{ border: "1px solid #000" }}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: "1px solid #000", width: "150px" }}></td>
            <td style={{ border: "1px solid #000", width: "150px" }}></td>
            <td style={{ border: "1px solid #000", width: "150px" }}></td>
            <td
              className="address-column"
              style={{
                width: "160px",
                height: "auto",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "1px",
              }}
            >
              <h5 className="name" style={{ height: "20%", marginLeft: "1px" }}>
                {booking_user?.name}
              </h5>
              <h5
                className="address"
                style={{
                  width: "100px",
                  alignItems: "flex-start",
                  height: "80%",
                  marginLeft: "1px",
                }}
              >
                {booking_user?.address}
              </h5>
            </td>
            <td
              style={{
                border: "1px solid #000",
                width: "150px",
                alignItems: "center",
              }}
            >
              <h5 className="name" style={{ height: "20%" }}>
                {booking_user?.id_type}
              </h5>
              <h5
                className="address"
                style={{
                  width: "100px",
                  alignItems: "flex-start",
                  height: "80%",
                }}
              >
                {booking_user?.id_number}
              </h5>
            </td>
            <td style={{ border: "1px solid #000", width: "150px" }}></td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default DonationTable;
