import { Button, Modal } from "antd";
import React from "react";
import FeedbackManageExpandableForm from "../feedback_manage_expandable_form/feedback_manage_expandable_main_form";
import { useDynamicSelector } from "../../../../../../services/redux";
import { IoMdClose } from "react-icons/io";

const FeedbackManageExpandableEditModal = (props) => {
  const {
    header_type,
    set_edit_modal_visible,
    is_edit_model_visible,
    edit_values,
    item,
    options,
    data,
  } = props;
  const { loading: create_feedback_question_option } = useDynamicSelector(
    "create_feedback_question_option"
  );

  const { loading: update_feedback_question_option } = useDynamicSelector(
    "update_feedback_question_option"
  );

  return (
    <div>
      <Modal
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        title={header_type === "edit" ? `Edit Option` : `Add Option`}
        open={is_edit_model_visible}
        destroyOnClose={true}
        onCancel={() => set_edit_modal_visible(false)}
        footer={[
          <Button key="cancel" onClick={() => set_edit_modal_visible(false)}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            htmlType="submit"
            form="expandable_form"
            loading={
              create_feedback_question_option || update_feedback_question_option
            }
          >
            Save
          </Button>,
        ]}
      >
        <FeedbackManageExpandableForm
          header_type={header_type}
          set_edit_modal_visible={set_edit_modal_visible}
          is_edit_model_visible={is_edit_model_visible}
          edit_values={edit_values}
          item={item}
          options={options}
          data={data}
        />
      </Modal>
    </div>
  );
};

export default FeedbackManageExpandableEditModal;
