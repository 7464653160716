import { Col, Table } from "antd";
import React, { useState } from "react";
import {
  dynamicRequest,
  get_masm_mandram_count_query,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";

const MandramWiseCountTable = () => {
  const {
    items: mandram_items,
    loading: mandram_items_loading,
    pagination,
  } = useDynamicSelector("get_masm_user_mandram_count");

  const dispatch = useDispatch();

  const [current_page_limit, set_current_page_limit] = useState(5);
  const [current_page, set_current_page] = useState(1);

  const table_row_class_name = (record, index) => {
    return index % 2 === 0 ? "odd-row" : "even-row";
  };

  const fetch_data = (page_number, page_limit) => {
    let keys = [{ key: "get_masm_user_mandram_count", loading: true }];
    let query = get_masm_mandram_count_query;
    let variable = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(keys, query, variable));
    set_current_page(page_number);
    set_current_page_limit(page_limit);
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "mandram_code",
      align: "center",
      width: "200px",
      height: "100px",
    },
    {
      title: "Name",
      dataIndex: "mandram_name",
      align: "left",
      width: "300px",
      height: "100px",
    },
    {
      title: "Booked",
      align: "left",
      width: "300px",
      height: "100px",
      render: (item) => {
        return item?.count?.booked;
      },
    },
    {
      title: "Arrived",
      align: "left",
      width: "300px",
      height: "100px",
      render: (item) => {
        return item?.count?.arrived;
      },
    },
    {
      title: "Cancelled",
      align: "left",
      width: "300px",
      height: "100px",
      render: (item) => {
        return item?.count?.cancelled;
      },
    },
  ];
  return (
    <Col>
      <Table
        style={{
          width: "98%",
          marginTop: "10px",
          cursor: "pointer",
          overflowY: "scroll",
        }}
        columns={columns}
        dataSource={mandram_items}
        loading={mandram_items_loading}
        rowClassName={(record, index) => table_row_class_name(record, index)}
        pagination={{
          current: current_page,
          pageSize: current_page_limit,
          total: mandram_items?.length,
          showSizeChanger: true,
          onChange: fetch_data,
          onShowSizeChange: (current, size) => {
            fetch_data(current, size);
          },
        }}
      />
    </Col>
  );
};

export default MandramWiseCountTable;
