import gql from "graphql-tag";
export const query_change_password = gql`
  mutation change_password($data: change_password_input) {
    change_password(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
