import { Button, Form, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../services/redux/selector";
import {
  dynamicClear,
  dynamicRequest,
  mutation_create_booking,
  // query_check_order_status,
  query_get_booking_details,
} from "../../../services/redux/slices";
import { useDispatch } from "react-redux";
import BookingConfirmationDetail from "./booking_modal_form_item/booking_confirmation_detail";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import moment from "moment";
import { startCase } from "lodash";

let modalInstance;

const BookingConfirmationModal = (props) => {
  const {
    modal_open,
    set_open_notification,
    set_modal_open,
    booking_details,
    selected_time,
    selected_item,
    mandram_id,
    initial_values,
    form,
    pay_mode,
    ignore_waiting,
    count,
    set_pay_mode,
    set_selected_item,
    set_amount,
    set_selected_time,
    set_value,
    set_get_leader_details,
    set_leader_mobile_number,
    set_selected_id_proof,
    set_leader_details,
    set_is_order_status_cancelled,
    set_mandram_id,
    set_duplicate_booking,
    set_duplicate_booking_modal,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [order_booking_id, set_order_booking_id] = useState();
  const [is_duplicate_booking, set_is_duplicate_booking] = useState(false);
  const item = useDynamicSelector("get_booking_user_details");
  const booking_type = Form.useWatch("booking_type", form);

  console.log("booking_details", booking_details);

  const { confirmed_count, waiting_count } = useDynamicSelector(
    "get_mandram_confirmed_waiting_count_summary"
  );

  // const {
  //   status: order_status,
  //   error: check_order_error,
  //   loading: check_order_loading,
  //   booking_id: check_order_booking_id,
  // } = useDynamicSelector("check_order_status");

  const {
    status: create_booking_status,
    error: create_booking_error,
    loading: create_booking_loading,
    id: create_booking_id,
  } = useDynamicSelector("create_booking");

  const create_booking = () => {
    let key = [{ key: "create_booking", loading: true }];
    let query = mutation_create_booking;
    let data = {
      time_slot_id: selected_time,
      count: count,
      mandram_id: mandram_id,
      payment_mode: pay_mode,
      ignore_waiting: ignore_waiting,
      booking_user_id: item?.id ? item?.id : "",
      booking_type:booking_details?.booking_type,
      booking_user: {
        name: booking_details?.group_leader
          ? booking_details?.group_leader
          : item?.name,
        mobile: booking_details?.mobile
          ? booking_details?.mobile
          : item?.mobile,
        address: booking_details?.address
          ? booking_details?.address
          : item.address,
        id_type: booking_details?.id_proof
          ? booking_details?.id_proof
          : item.id_type,
        id_number: booking_details?.id_number
          ? booking_details?.id_number
          : item.id_number,
      },
    };

    if (item?.id) {
      delete data.booking_user;
    } else {
      delete data.booking_user_id;
    }
    let variables = {
      data: data,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_one_booking = (create_booking_id) => {
    let key = [{ key: "get_booking_detail", loading: true }];
    let query = query_get_booking_details;
    let variables = {
      custom: {
        booking_reference: create_booking_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  // const check_order_status = (create_booking_id) => {
  //   let key = [{ key: "check_order_status", loading: true }];
  //   let query = query_check_order_status;
  //   let variables = {
  //     id: create_booking_id,
  //   };
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  const on_finish = () => {
    if (booking_type === "vip") {
      const modalInstance = Modal.info({
        title: "Payment Process",
        content: (
          <div>
            <p>Your Payment is Processing.....</p>
            <p> Do not close and refresh</p>
            <div class="loading">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ),
        okButtonProps: {
          style: {
            display: "none",
          },
        },
      });
    } else if (booking_type === "normal") {
      create_booking();
    }
  };

  useEffect(() => {
    if (create_booking_status === "booking created") {
      set_pay_mode("");
      set_selected_item("");
      set_value(1);
      set_amount(0);
      set_get_leader_details("");
      set_selected_time("");
      set_is_duplicate_booking(false);
      set_modal_open(false);
      set_open_notification(true);
      get_one_booking(create_booking_id);
      set_leader_mobile_number("");
      set_leader_details(false);
      set_mandram_id("");
      set_selected_id_proof("Aadhaar Card");
      form.resetFields();
      dispatch(dynamicClear("create_booking"));
      dispatch(dynamicClear("get_booking_user_details"));
      dispatch(dynamicClear("get_mandram_confirmed_waiting_count_summary"));
    }

    // else if (create_booking_status === "order created") {
    //   check_order_status(create_booking_id);
    //   modalInstance = Modal.info({
    //     title: "Payment Process",
    //     content: (
    //       <div>
    //         <p>Your Payment is Processing.....</p>
    //       </div>
    //     ),
    //     okButtonProps: {
    //       style: {
    //         display: "none",
    //       },
    //     },
    //   });
    //   set_pay_mode("");
    //   set_selected_item("");
    //   set_value(1);
    //   set_amount(0);
    //   set_get_leader_details("");
    //   set_selected_time("");
    //   set_modal_open(false);
    //   set_mandram_id("");
    //   get_one_booking(create_booking_id);
    //   set_leader_mobile_number("");
    //   set_leader_details(false);
    //   set_selected_id_proof("Aadhaar Card");
    //   form.resetFields();
    //   dispatch(dynamicClear("get_booking_user_details"));
    //   dispatch(dynamicClear("get_mandram_confirmed_waiting_count_summary"));
    // }
    else if (create_booking_error) {
      if (
        create_booking_error?.message ===
        "Cannot Read Properties Of Null Reading Date Slot"
      ) {
        set_is_duplicate_booking(true);
        create_booking();
      } else if (
        create_booking_error?.message === "Already booking exist for this user"
      ) {
        set_pay_mode("");
        set_selected_item("");
        set_value(1);
        set_amount(0);
        set_get_leader_details("");
        set_selected_time("");
        set_modal_open(false);
        get_one_booking(create_booking_id);
        set_leader_mobile_number("");
        set_leader_details(false);
        set_mandram_id("");
        set_selected_id_proof("Aadhaar Card");
        form.resetFields();
        if (is_duplicate_booking) {
          set_open_notification(true);
        } else {
          set_duplicate_booking_modal(true);
        }
        dispatch(dynamicClear("create_booking"));
        dispatch(dynamicClear("get_booking_user_details"));
      } else {
        toast.error(startCase(create_booking_error?.message));
        set_modal_open(false);
        dispatch(dynamicClear("create_booking"));
      }
    }
  }, [create_booking_status, create_booking_id, create_booking_error]);

  // useEffect(() => {
  //   if (order_status === "completed") {
  //     set_pay_mode("");
  //     set_selected_item("");
  //     set_value(1);
  //     set_amount(0);
  //     set_get_leader_details("");
  //     set_selected_time("");
  //     set_leader_mobile_number("");
  //     set_leader_details(false);
  //     set_open_notification(true);
  //     set_selected_id_proof("Aadhaar Card");
  //     get_one_booking(check_order_booking_id);
  //     form.resetFields();
  //     if (modalInstance) {
  //       modalInstance.destroy();
  //       set_open_notification(true);
  //     }
  //     // dispatch(dynamicClear("check_order_status"));
  //     dispatch(dynamicClear("create_booking"));
  //     dispatch(dynamicClear("get_mandram_confirmed_waiting_count_summary"));
  //   }
  //    else if (order_status === "initiated") {
  //     set_modal_open(false);

  //     setTimeout(() => {
  //       check_order_status(create_booking_id);
  //     }, 10000);
  //   } else if (order_status === "cancelled") {
  //     set_is_order_status_cancelled(true);
  //     if (modalInstance) {
  //       modalInstance.destroy();
  //       set_modal_open(false);
  //       toast.error("Payment failed");
  //       get_one_booking(check_order_booking_id);
  //       set_open_notification(true);
  //     }
  //     dispatch(dynamicClear("check_order_status"));
  //     dispatch(dynamicClear("create_booking"));
  //     dispatch(dynamicClear("get_mandram_confirmed_waiting_count_summary"));
  //   } else if (order_status === "failure") {
  //     if (modalInstance) {
  //       modalInstance.destroy();
  //       set_modal_open(false);
  //       toast.error("Payment failed");
  //     }
  //   } else if (check_order_error?.message) {
  //     toast.error(startCase(check_order_error?.message));
  //     dispatch(dynamicClear("check_order_status"));
  //   }
  // }, [order_status, check_order_error, create_booking_id]);

  const formatted_date = selected_item?.date
    ? moment(selected_item?.date).format("DD-MMM-YYYY")
    : "";
  return (
    <div>
      <Modal
        title={t("conformation")}
        open={modal_open}
        onCancel={() => set_modal_open(false)}
        style={{ maxWidth: 600, height: 300 }}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            style={{
              backgroundColor: "#168f92",
              color: "white",
              border: "transparent",
            }}
            onClick={() => set_modal_open(false)}
          >
            {t("no")}
          </Button>,
          <Button
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            htmlType="submit"
            form="add_counter_form"
            onClick={on_finish}
            loading={create_booking_loading}
          >
            {t("yes")}
          </Button>,
        ]}
      >
        <Typography
          style={{
            marginBottom: "15px",
          }}
        >
          {t("details_for_booking")}
        </Typography>

        <BookingConfirmationDetail
          title={t("mandram_id")}
          value={initial_values.code}
        />
        <BookingConfirmationDetail
          title={t("mandram")}
          value={initial_values.name}
        />
        <BookingConfirmationDetail title={t("devotee_s")} value={count} />
        <BookingConfirmationDetail title={t("date")} value={formatted_date} />
      </Modal>
    </div>
  );
};

export default BookingConfirmationModal;
