import { Button, Modal } from "antd";
import React from "react";
import AddQuestionForm from "./add_question_form/add_question_main_form";
import { useDynamicSelector } from "../../../../../services/redux";
import { IoMdClose } from "react-icons/io";

const AddQuestionEditModal = (props) => {
  const {
    set_edit_modal_visible,
    is_edit_model_visible,
    edit_values,
    header_type,
    get_all_feedback_question_query,
    id,
    handle_switch,
  } = props;
  const { loading: create_feedback_question_loading } = useDynamicSelector(
    "create_feedback_question"
  );

  const { loading: update_feedback_question_loading } = useDynamicSelector(
    "update_feedback_question"
  );

  return (
    <div>
      <Modal
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        title={header_type === "edit" ? `Edit Question` : `Add Question`}
        open={is_edit_model_visible}
        destroyOnClose={true}
        onCancel={() => set_edit_modal_visible(false)}
        footer={[
          <Button key="cancel" onClick={() => set_edit_modal_visible(false)}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            htmlType="submit"
            form="question_form"
            loading={
              create_feedback_question_loading ||
              update_feedback_question_loading
            }
          >
            Save
          </Button>,
        ]}
      >
        <AddQuestionForm
          edit_values={edit_values}
          is_edit_model_visible={is_edit_model_visible}
          header_type={header_type}
          set_edit_modal_visible={set_edit_modal_visible}
          get_all_feedback_question_query={get_all_feedback_question_query}
          id={id}
          handle_switch={handle_switch}
        />
      </Modal>
    </div>
  );
};

export default AddQuestionEditModal;
