import React, { Suspense, useEffect, useState } from "react";
import DistrictWiseCollapse from "./district_wise_history";
import { Collapse, Empty, Spin } from "antd";
import BookingHistoryHeader from "./booking_history_header";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  dynamicSet,
  useDynamicSelector,
} from "../../../../services/redux";
import {
  grouped_by_date,
  calculate,
} from "../booking_history_collapse/booking_history";
import { district_details_query } from "../../../../services/redux/slices/graphql/graphql_district";
import { useTranslation } from "react-i18next";

const BookingHistoryList = (props) => {
  const [booking_histories, set_booking_histories] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { key: active_date_key } = useDynamicSelector("date_wise_active_key");
  const { items: districts } = useDynamicSelector("district_list");
  const { items: booking_summary, loading: booking_summary_loading } =
    useDynamicSelector("get_date_district_mandram_wise_summary");

  const get_district_list = () => {
    let key = [{ key: "district_list", loading: true }];
    let query = district_details_query;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (booking_summary?.length > 0) {
      set_booking_histories(grouped_by_date(booking_summary, districts));
    } else {
      set_booking_histories({});
    }
  }, [booking_summary]);

  useEffect(() => {
    get_district_list();
  }, []);

  const handle_change_date_wise_history = (value) => {
    dispatch(dynamicSet("date_wise_active_key", { key: [value] }));
    dispatch(dynamicSet("district_wise_active_key", { key: [] }));
    dispatch(dynamicSet("sub_district_wise_active_key", { key: [] }));
    dispatch(dynamicSet("mandram_wise_active_key", { key: [] }));
  };
  console.log("booking_summary", booking_summary);

  return (
    <Suspense fallback={<Spin />}>
      <div>
        {booking_summary_loading && (
          <div className="booking_history_loading">
            <div
              className="booking_history_loading"
              style={{ flexDirection: "column" }}
            >
              <Spin />
              Please wait...
            </div>
          </div>
        )}
        {!booking_summary && !booking_summary_loading && (
          <div className="booking_history_loading">
            <Empty description={t("no_booking_history")} />
          </div>
        )}
        <Collapse
          accordion={true}
          onChange={handle_change_date_wise_history}
          activeKey={active_date_key ? active_date_key : 1}
        >
          {booking_histories &&
            Object?.keys(booking_histories)?.map((date_wise_history) => {
              return (
                <Collapse.Panel
                  header={
                    <BookingHistoryHeader
                      item={booking_histories?.[date_wise_history]}
                    />
                  }
                  key={date_wise_history}
                  style={{ backgroundColor: "rgb(39, 86, 112)" }}
                >
                  <DistrictWiseCollapse
                    item={booking_histories?.[date_wise_history]}
                    district_wise_history={
                      booking_histories?.[date_wise_history]
                    }
                  />
                </Collapse.Panel>
              );
            })}
        </Collapse>
      </div>
    </Suspense>
  );
};

export default BookingHistoryList;
