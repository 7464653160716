import { gql } from "@apollo/client";

export const query_dashboard_list = gql`
  query {
    get_overall_dashboard {
      today_count
      yesterday_count
      total_count
    }
  }
`;
export const query_sms_balance = gql`
  query get_sms_credit {
    get_sms_credit {
      sms_balance
    }
  }
`;

export const query_season_district_summary = gql`
  query get_season_district_summary {
    get_season_district_summary {
      last_season_enrolled_count
      current_season_enrolled_count
    }
  }
`;
