import { gql } from "@apollo/client";

export const query_get_system_role_list = gql`
  query get_system_role_list {
    get_roles: get_system_role_list {
      items {
        id
        name
      }
    }
  }
`;

export const query_get_role = gql`
  query get_system_role($id: String!) {
    get_system_role(id: $id) {
      id
      name
    }
  }
`;
