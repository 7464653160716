import { Col, Table } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../services/redux";
import { StyleSheet } from "@react-pdf/renderer";
import Loading from "../../system/ui/loading/loading";
import PrintHeader from "../../common/print_header";
import { IS_TABLE_SCROLLABLE } from "../../../../helpers/constants";

const SakthiPeedamReportTable = (props) => {
  const { to_date, from_date } = props;
  const { items: sakthi_peedam_reports, loading } =
    useDynamicSelector("get_sakthi_peedam");

  const styles = StyleSheet.create({
    tableCell: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });

  const total_devotees = sakthi_peedam_reports?.reduce(
    (acc, item) => acc + (item.devotee_count || 0),
    0
  );
  console.log("sakthi_peedam_reports", sakthi_peedam_reports);

  console.log("total_devotees", total_devotees);

  const columns = [
    {
      title: "S.No",
      dataIndex: "districtUser",
      align: "left",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Code",
      dataIndex: "sakthi_peedam_code",
      align: "left",
    },

    {
      title: "Sakthi Peedam",
      dataIndex: "sakthi_peedam_name",
      align: "left",
    },
    {
      title: "Devotees",
      dataIndex: "devotee_count",
      align: "center",
    },
  ];
  return (
    <>
      <div className="print-header" style={{ padding: "15px" }}>
        <PrintHeader title={"SAKTHI PEEDAM REPORT"} />
        <h4
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          SAKTHI PEEDAM REPORT
        </h4>
        <h5
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {from_date} to {to_date}
        </h5>
      </div>
      <Table
        // className="sakthi_peedam_details"
        className="counter-user-wise-report screen-table"
        data-name-table="district-wise-report"
        style={{ width: "96%", margin: "auto" }}
        // ref={componentRef}
        columns={columns}
        dataSource={sakthi_peedam_reports}
        pagination={false}
        loading={loading}
        scroll={{
          x: true,
          y: IS_TABLE_SCROLLABLE ? null : 300,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <text style={{ fontWeight: "bold" }}>TOTAL</text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={1}>
                <text style={styles.tableCell}></text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={2}>
                <text style={styles.tableCell}></text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={3}>
                <text style={styles.tableCell}>
                  <b>{total_devotees}</b>
                </text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />

      <div style={{ padding: "10px" }}>
        <table
          className="table table-bordered print-table"
          style={{ padding: "10px", width: "100%", marginTop: "10px" }}
        >
          <thead style={{ width: "100%" }}>
            <tr>
              {columns.map((column) => (
                <th
                  className="header-style"
                  style={{ width: "100px" }}
                  key={column.key}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {sakthi_peedam_reports?.map((report, index) => (
              <tr key={index}>
                <td className="body-style" style={{ textAlign: "left" }}>
                  {index + 1}
                </td>

                <td className="body-style">{report?.sakthi_peedam_code}</td>

                <td className="body-style">{report?.sakthi_peedam_name}</td>

                <td className="body-style" style={{ textAlign: "right" }}>
                  {report?.devotee_count}
                </td>
              </tr>
            ))}
            <tr>
              <td className="body-style text-left-align">
                <b>Total</b>
              </td>
              <td className="body-style" style={{ textAlign: "right" }}></td>
              <td className="body-style" style={{ textAlign: "right" }}></td>
              <td className="body-style" style={{ textAlign: "right" }}>
                <b>{total_devotees}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <style>
        {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-header {
        display: block !important;
      }

      @media print {
        @page {
          size: A4;
          margin: 1cm;
        }
  
        body::before {
          content: counter(page);
          top: 0;
          right: 1cm;
        }
      }
    }
  `}
      </style>
    </>
  );
};

export default SakthiPeedamReportTable;
