import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  query_pos,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch, useSelector } from "react-redux";

const { Option } = Select;

const UpdateProfilePosFormItem = (props) => {
  const { set_onchange_select } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Assuming useDynamicSelector is a custom hook for Redux selector
  const { items: update_pos_items } = useDynamicSelector(
    "get_unassigned_pos_list"
  );

  console.log("update_pos_items", update_pos_items);
  useEffect(() => {
    update_pos();
  }, []);

  const update_pos = () => {
    let key = [{ key: "get_unassigned_pos_list", loading: true }];
    let query = query_pos;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const custom_filter_option = (input, option) => {
    const input_value = input.toLowerCase();
    const option_label = option.props.children.toLowerCase();
    return option_label.indexOf(input_value) === 0;
  };

  const handle_change = (values) => {
    console.log("values88", values);
    set_onchange_select(values);
  };

  return (
    <div>
      <Form.Item
        name="pos_id"
        label={t("POS")}
        rules={[
          {
            // required: true,
            // message: "",
          },
        ]}
      >
        <Select
          allowClear
          onChange={handle_change}
          placeholder="POS"
          showSearch
          filterOption={custom_filter_option}
        >
          {update_pos_items?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}/{item.terminal_id}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default UpdateProfilePosFormItem;
