import { Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";

const InputNotificationContent = () => {
  return (
    <Form.Item
      name="content"
      label="Content"
      rules={[
        {
          required: true,
          message: "Please enter content",
        },
      ]}
    >
      <TextArea
        style={{ width: "270px" }}
        placeholder="Content"
        allowClear={true}
      />
    </Form.Item>
  );
};

export default InputNotificationContent;
