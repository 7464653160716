import { Form, Input, InputNumber } from "antd";
import React from "react";

const InputSubDistrictExtraAllowedPercentage = () => {
  
  const validateExtraAllowedPercentage = (rule, value, callback) => {
    if (value) {
      const num = parseInt(value, 10);
      if (isNaN(num) || num < 0 || num > 99) {
        callback("Please enter a valid number");
      } else {
        callback();
      }
    }
  };

  return (
    <Form.Item
      name="extra_allowed_percentage"
      label="Extra Allowed Percentage"
      rules={[
        {
          required: true,
          message: "Please enter extra allowed percentage.",
        },
      ]}
    >
      <InputNumber
        type="number"
        allowClear
        style={{ width: "98%" }}
        placeholder="Extra Allowed Percentage"
      />
    </Form.Item>
  );
};

export default InputSubDistrictExtraAllowedPercentage;
