import { Form, Input, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SpotBookingInchargeMobileNo = () => {
  const { t } = useTranslation();
  return (
    <Form.Item label={t("incharge_mobile_no")} name="incharge_mobile">
      <Input
        style={{
          width: "100%",
          border: "none",
        }}
        disabled
      />
    </Form.Item>
  );
};

export default SpotBookingInchargeMobileNo;
