import React from "react";
import { useDynamicSelector } from "../../../services/redux";
import { useTranslation } from "react-i18next";
import { startCase } from "lodash";
import { Table } from "antd";
import { count_format, formatStatus } from "../../../helpers/constants";
import VipBookingMobileHistoryCard from "./vip_booking_mobile_history_card";

const VipBookingUserHistoryTable = () => {
  const { t } = useTranslation();

  const { items: get_booking_list, loading: get_booking_list_loading } =
    useDynamicSelector("get_booking_list");

  let columns = [
    {
      title: t("table:date"),
      dataIndex: "date",
      key: "date",
      fixed: "left",
    },
    {
      title: t("table:booking_no"),
      dataIndex: "booking_reference",
      key: "booking_reference",
    },
    {
      title: t("table:booked"),
      dataIndex: "confirmed_count",
      key: "confirmed_count",
      render: count_format,
    },

    {
      title: t("table:leader_name"),
      dataIndex: ["booking_user", "name"],
      key: "leader_name",
    },
    {
      title: t("table:leader_no"),
      dataIndex: ["booking_user", "mobile"],
      key: "leader_no",
    },
    {
      title: t("table:booked_by"),
      dataIndex: ["booked_by", "name"],
      key: "booked_by",
    },
    {
      title: t("table:payment_mode"),
      dataIndex: ["payment", "payment_mode"],
      key: "payment_mode",
      render: (record) => startCase(record),
    },
    {
      title: t("table:payment_status"),
      dataIndex: "",
      key: "payment_status",
      render: (record) => {
        console.log("payment_statuspayment_statuspayment_status", record);

        return record?.enrolment_list && record?.enrolment_list?.length > 0
          ? formatStatus(record?.enrolment_list?.[0]?.status)
          : "-";
      },
    },
    {
      title: t("table:status"),
      dataIndex: "booking_status",
      key: "status",
      render: formatStatus,
      fixed: "right",
    },
  ];

  return (
    <div style={{ padding: "10px" }}>
      <Table
        columns={columns}
        dataSource={get_booking_list}
        loading={get_booking_list_loading}
        className="vip-booking-table"
        rowClassName={(record, index) =>
          index % 2 === 0 ? "row-even" : "row-odd"
        }
        scroll={{
          x: 1900,
        }}
      />
      <VipBookingMobileHistoryCard booking={get_booking_list}/>
    </div>
  );
};
export default VipBookingUserHistoryTable;
