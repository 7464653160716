import React, { useEffect, useState } from "react";
import { Row } from "antd";
import NotificationTable from "../components/notification/notification_table/notification_table";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../components/notification/notification_table/button_groups";
import {
  mutation_reorder_notification_board,
  query_get_all_notification_board_list,
} from "../../services/redux/slices/graphql/graphql_notification";
import { dynamicClear, dynamicRequest } from "../../services/redux/slices";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../services/redux/selector";
import { toast } from "react-toastify";

const Notifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [notification_modal_visible, set_notification_modal_visible] =
    useState(false);
  const [header_type, set_header_type] = useState("");
  const [enable_reorder, set_enable_reorder] = useState(false);
  const [data_source, set_data_source] = useState([]);

  const { items: notification_items } = useDynamicSelector(
    "get_notification_board_list"
  );

  const {
    status: notification_reorder_success,
    error: notification_reorder_error,
  } = useDynamicSelector("reorder_notification_board");

  useEffect(() => {
    get_all_notifications();
  }, [enable_reorder]);

  const get_all_notifications = () => {
    let keys = [{ key: "get_notification_board_list", loading: true }];
    let query = query_get_all_notification_board_list;
    let variable = {};
    dispatch(dynamicRequest(keys, query, variable));
  };

  const handle_reorder = () => {
    set_enable_reorder(!enable_reorder);
  };

  const add_notification = () => {
    set_header_type("add_notification");
    set_notification_modal_visible(true);
  };

  const cancel_reorder = () => {
    get_all_notifications();
  };

  const save_reorder = () => {
    const modified_array = data_source?.map((item) => ({
      notification_board_id: item.id,
      order: Number(item.order_number),
    }));
    let key = [{ key: "reorder_notification_board", loading: true }];
    let query = mutation_reorder_notification_board;
    let variables = {
      data: {
        notification_board_reorder_list: modified_array,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (notification_reorder_success === "success") {
      toast.success("Notification reordered successfully");
      get_all_notifications();
      dispatch(dynamicClear("reorder_notification_board"));
    } else if (notification_reorder_error) {
      toast.error(notification_reorder_error?.message);
      dispatch(dynamicClear("reorder_notification_board"));
    }
  }, [notification_reorder_success, notification_reorder_error]);

  return (
    <Row style={{ width: "100%" }}>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <CustomPageTitle title={t("notifications")} />
        </Row>

        <Row style={{ margin: "8px" }}>
          {/* <CustomBreadCrumbs pages={"Home/Notifications"} /> */}
        </Row>
      </Row>

      <div
        style={{
          alignItems: "flex-end",
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          margin: "10px",
        }}
      >
        <ButtonGroup
          enable_reorder={enable_reorder}
          cancel_reorder={cancel_reorder}
          save_reorder={save_reorder}
          handle_reorder={handle_reorder}
          add_notification={add_notification}
        />
      </div>

      <Row
        style={{
          margin: "10px",
          width: "100%",
        }}
      >
        <NotificationTable
          data_source={data_source}
          set_data_source={set_data_source}
          save_reorder={save_reorder}
          get_all_notifications={get_all_notifications}
          items={notification_items}
          enable_reorder={enable_reorder}
          set_enable_reorder={set_enable_reorder}
          header_type={header_type}
          set_header_type={set_header_type}
          notification_modal_visible={notification_modal_visible}
          set_notification_modal_visible={set_notification_modal_visible}
        />
      </Row>
    </Row>
  );
};

export default Notifications;
