import { Form, Input } from "antd";
import React from "react";

const InputDistrictUserEmailId = () => {
  const validateEmail = (rule, value, callback) => {
    if (value && !/^\S+@\S+\.\S+$/.test(value)) {
      callback("Please enter a valid email address");
    } else {
      callback();
    }
  };
  return (
    <Form.Item
      name="email"
      label="Email ID"
      rules={[
        {
          required: true,
          message: "Please enter email",
        },
        {
          validator: validateEmail,
        },
      ]}
    >
      <Input
        style={{ width: "270px" }}
        placeholder="Email ID"
        allowClear={true}
      />
    </Form.Item>
  );
};

export default InputDistrictUserEmailId;
