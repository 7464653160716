import { Col, Row, Typography } from "antd";
import React from "react";

const EnrollConfirmationDetailsCard = (props) => {
  const { title, value } = props;
  return (
    <div>
      <Row
        gutter={24}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col span={12}>
          <Typography
            style={{
              display: "flex",
              justifyContent: "flex-start",
              textAlign: "right",
              marginBottom: "3px",
              marginRight: "20px",
              marginLeft: "50%",
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
        </Col>
        <Col span={12}>
          <Typography
            style={{
              display: "flex",
              justifyContent: "flex-start", // Align the text to the left
              marginBottom: "3px",
              marginLeft: "10%",
            }}
          >
            {value}
          </Typography>
        </Col>
      </Row>
    </div>
  );
};

export default EnrollConfirmationDetailsCard;
