import { Button, Modal, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  mutation_cancel_booking,
  mutation_unlock_enrollment_ticket_printing,
} from "../../../../services/redux/slices/graphql/graphql_billing_history";
import TicketNumberListCard from "./ticket_number_list_card";

const ActionModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [select_all, set_select_all] = useState(false);
  const [selected_items, set_selected_items] = useState([]);
  const { Text } = Typography;

  const {
    set_open_unlock_print_modal,
    set_cancel_booking_modal,
    open_unlock_print_modal,
    open_cancel_booking_modal,
    header_type,
    record,
    get_all_enrollment_list,
  } = props;

  const {
    status: cancel_booking_status,
    error: cancel_booking_error,
    loading: cancel_booking_loading,
  } = useDynamicSelector("cancel_booking");

  const {
    status: unlock_enrolment_ticket_printing_status,
    error: unlock_enrolment_ticket_printing_error,
    loading: unlock_enrolment_ticket_printing_loading,
  } = useDynamicSelector("unlock_enrolment_ticket_printing");

  const handle_cancel_booking = (record) => {
    let keys = [{ key: "cancel_booking", loading: true }];
    let query = mutation_cancel_booking;
    let variable = {
      id: record.booking_id,
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const handle_unlock_printing = (record) => {
    let keys = [{ key: "unlock_enrolment_ticket_printing", loading: true }];
    let query = mutation_unlock_enrollment_ticket_printing;
    let variables = {
      data: {
        enrolment_id: record.id,
        ticket_number_list: selected_items,
      },
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const handle_select_all = () => {
    set_select_all(true);
  };

  const handle_unselect_all = () => {
    set_selected_items([]);
    set_select_all(false);
  };

  useEffect(() => {
    if (cancel_booking_status === "success") {
      toast.success("Booking Cancelled");
      dispatch(dynamicClear("cancel_booking"));
      get_all_enrollment_list();
      set_cancel_booking_modal(false);
    } else if (cancel_booking_error?.message) {
      toast.error(cancel_booking_error?.message);
      dispatch(dynamicClear("cancel_booking"));
      set_cancel_booking_modal(false);
    }
    if (unlock_enrolment_ticket_printing_status === "success") {
      toast.success("Printer unlocked successfully");
      dispatch(dynamicClear("unlock_enrolment_ticket_printing"));
      get_all_enrollment_list();
      set_open_unlock_print_modal(false);
    } else if (unlock_enrolment_ticket_printing_error?.message) {
      toast.error(unlock_enrolment_ticket_printing_error?.message);
      dispatch(dynamicClear("unlock_enrolment_ticket_printing"));
      set_open_unlock_print_modal(false);
    }
  }, [
    cancel_booking_status,
    cancel_booking_error,
    unlock_enrolment_ticket_printing_status,
    unlock_enrolment_ticket_printing_error,
  ]);
  const handle_close_modal = () => {
    set_open_unlock_print_modal(false);
    set_cancel_booking_modal(false);
    set_selected_items([]);
    set_select_all(false);
  };
  return (
    <Modal
      width={header_type === "cancel_booking" ? "600px" : "1190px"}
      data-custom-delete-modal={
        header_type === "cancel_booking" ? "custom-delete-modal-header" : ""
      }
      title={
        header_type === "cancel_booking"
          ? "Cancel Booking"
          : header_type === "unlock_printer"
          ? "Unlock Ticket Printing"
          : ""
      }
      open={open_unlock_print_modal || open_cancel_booking_modal}
      destroyOnClose={true}
      onCancel={() => {
        handle_close_modal();
      }}
      footer={[
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent:
              header_type === "unlock_printer" ? "space-between" : "flex-end",
          }}
        >
          {header_type === "unlock_printer" && (
            <div>
              <Button
                style={{ backgroundColor: "MediumSeaGreen", color: "white" }}
                onClick={handle_select_all}
              >
                Select All
              </Button>
              <Button
                style={{ backgroundColor: "#bc1a00", color: "white" }}
                onClick={handle_unselect_all}
              >
                Unselect All
              </Button>
            </div>
          )}
          <div>
            <Button
              key="cancel"
              onClick={() => {
                handle_close_modal();
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              key="confirm"
              type="primary"
              loading={
                cancel_booking_loading ||
                unlock_enrolment_ticket_printing_loading
              }
              onClick={() => {
                if (header_type === "cancel_booking") {
                  handle_cancel_booking(record);
                } else if (header_type === "unlock_printer") {
                  handle_unlock_printing(record);
                }
              }}
            >
              {header_type === "unlock_printer" ? t("unlock") : t("yes")}
            </Button>
          </div>
        </div>,
      ]}
      wrapClassName="custom-delete-modal"
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <Content>
        <Row>
          <Text>
            {header_type === "cancel_booking" ? t("cancel_booking") : ""}
          </Text>
          {header_type === "unlock_printer" ? (
            <div style={{ paddingLeft: "11px" }}>
              <TicketNumberListCard
                selected_items={selected_items}
                set_selected_items={set_selected_items}
                select_all={select_all}
                count={record?.arrived_count}
                handle_unselect_all={handle_close_modal}
              />
            </div>
          ) : (
            ""
          )}
          {header_type === "cancel_booking" && (
            <>
              <Text strong> &nbsp;</Text>
              <Text> &nbsp;?</Text>
            </>
          )}
        </Row>
      </Content>
    </Modal>
  );
};

export default ActionModal;
