import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Checkbox, Select } from "antd";
import moment from "moment";
import _orderBy from "lodash/orderBy";
import {
  dynamicRequest,
  get_report_date_slots,
  useDynamicSelector,
} from "../../../../services/redux";
import { useTranslation } from "react-i18next";

const DistrictWiseDateInput = (props) => {
  const {
    select_all_dates,
    set_selected_state,
    onchange_district_wise_input,
    set_yesterday_date,
    selected_date,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Assuming you have a selectedItems state
  const [selectedItems, setSelectedItems] = useState([]);

  const { items: date_wise_report_items } = useDynamicSelector(
    "date_wise_report_abishekam_date_list"
  );

  const [date, set_date] = useState();

  // const sorted_date_slot_items = _orderBy(
  //   date_wise_report_items,
  //   ["date_string"],
  //   ["asc"]
  // );

  useEffect(() => {
    get_abishekam_date();
  }, []);

  const get_abishekam_date = () => {
    let key = [{ key: "date_wise_report_abishekam_date_list", loading: false }];
    let query = get_report_date_slots;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_change = (values, option) => {
    console.log("date_values", values);
    onchange_district_wise_input();
    setSelectedItems(values);
    set_selected_state(values);

    // const selectedDateString = selected_date;
    // const selectedMoment = moment(selectedDateString, "DD-MMM-YYYY");
    // const yesterdayMoment = selectedMoment.clone().subtract(1, "days");
    // const yesterdayDateString = yesterdayMoment?.format("DD-MMM-YYYY");
    // set_yesterday_date(yesterdayDateString);

    // console.log("yesterdayDateString", yesterdayDateString);

    const selectedMoment = moment(values, "DD-MMM-YYYY");

    // Check if selectedMoment is a valid date
    if (selectedMoment.isValid()) {
      onchange_district_wise_input();
      setSelectedItems(values);
      set_selected_state(values);

      const yesterdayMoment = selectedMoment.clone().subtract(1, "days");
      const yesterdayDateString = yesterdayMoment?.format("DD-MMM-YYYY");
      set_yesterday_date(yesterdayDateString);

      console.log("yesterdayDateString", yesterdayDateString);
    } else {
      // Handle invalid date here
      console.error("Invalid date selected");
      // You might want to clear or set a default value for yesterday_date
      set_yesterday_date("");
    }
  };

  return (
    <div>
      <Form.Item
        name="abishekam_date"
        label={t("date")}
        // style={{ fontWeight: "bold" }}
        rules={[
          {
            required: true,
            message: "Please select date",
          },
        ]}
      >
        <Select
          // mode="multiple" // Assuming you want to select multiple dates
          showSearch
          allowClear
          placeholder="Select Date"
          style={{ fontWeight: "normal" }}
          onChange={handle_change}
          value={selectedItems}
        >
          {date_wise_report_items?.map((option, index) => (
            <Select.Option key={index} value={option.date_string}>
              {option?.date_string}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default DistrictWiseDateInput;
