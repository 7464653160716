import { startCase } from "lodash";
import { useDynamicSelector } from "../../../../services/redux";
import moment from "moment";

const PrintDateWiseReport = (props) => {
  const { item, district, mandram_names } = props;
  console.log("itemitem", item);
  console.log("district", district);
  const {
    items: booking_list,
    loading: booking_list_loading,
    pagination: get_details_pagination,
  } = useDynamicSelector("get_booking_list");
  console.log("booking_list", booking_list?.[0]);
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <p>ஓம் சக்தி</p>
        <p>ஆதி பராசக்தி சித்தர்பீடம்,</p>
        <p>மேல்மருவத்தூர்(2023-2024)</p>
      </div>
      {/* <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Booked</th>
            <th>Cancelled</th>
            <th>Enrolled</th>
            <th>Yet To Enroll</th>
            <th>Excess</th>
            <th>Prepaid</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{item?.date}</td>
            <td> {item?.total_booked}</td>
            <td> {item?.total_cancelled_count}</td>
            <td>{item?.total_enrolled}</td>

            <td>
              {item?.total_yet_to_enroll_count < 0
                ? 0
                : item?.total_yet_to_enroll_count}
            </td>
            <td>
              {" "}
              {item?.total_yet_to_enroll_count < 0
                ? Math.abs(item?.total_yet_to_enroll_count)
                : 0}
            </td>
            <td>{item?.total_prepaid_amount}</td>
          </tr>
        </tbody>
      </table> */}

      {/* <table>
        <thead>
          <tr>
            <th>District Name</th>
            <th>Booked</th>
            <th>Cancelled</th>
            <th>Enrolled</th>
            <th>Yet To Enroll</th>
            <th>Excess</th>
            <th>Prepaid</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{district?.district_name}</td>
            <td> {district?.total_booked}</td>
            <td> {item?.total_cancelled_count}</td>
            <td>{item?.total_enrolled}</td>

            <td>
              {district?.total_yet_to_enroll_count < 0
                ? 0
                : district?.total_yet_to_enroll_count}
            </td>
            <td>
              {district?.total_yet_to_enroll_count < 0
                ? Math.abs(district?.total_yet_to_enroll_count)
                : 0}
            </td>
            <td>{district?.total_prepaid_amount}</td>
          </tr>
        </tbody>
      </table> */}
      <table>
        <thead>
          <tr>
            <th>Mandram Name</th>
            <th>Booked</th>
            <th>Cancelled</th>
            <th>Enrolled</th>
            <th>Yet To Enroll</th>
            <th>Excess</th>
            <th>Prepaid</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{district?.district_name}</td>
            <td> {district?.total_booked}</td>
            <td> {item?.total_cancelled_count}</td>
            <td>{item?.total_enrolled}</td>

            <td>
              {district?.total_yet_to_enroll_count < 0
                ? 0
                : district?.total_yet_to_enroll_count}
            </td>
            <td>
              {district?.total_yet_to_enroll_count < 0
                ? Math.abs(district?.total_yet_to_enroll_count)
                : 0}
            </td>
            <td>{district?.total_prepaid_amount}</td>
          </tr>
        </tbody>
      </table>

      <table className="date-wise-report">
        <thead>
          <tr>
            <th>Booking No</th>
            <th>Booked</th>
            <th>Enrolled</th>
            <th>Yet To Enroll</th>
            <th>Excess</th>
            <th>Booked By</th>
            <th>Booked Date</th>
            <th>Payment Mode</th>
            <th>Payment Status</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {booking_list?.map((item, index) => (
            <tr key={index}>
              <td>{item.booking_reference}</td>
              <td>{item.confirmed_count + item.waiting_count}</td>
              <td>
                {item.enrolment_list?.reduce((acc, booking) => {
                  return acc + booking.arrived_count;
                }, 0) || 0}
              </td>
              <td>
                {(() => {
                  let yet_to_enroll = 0;
                  let total_enrolled = item?.enrolment_list?.reduce(
                    (acc, booking) => {
                      return acc + booking.arrived_count;
                    },
                    0
                  );
                  console.log("total_enrolled", total_enrolled);
                  yet_to_enroll = item?.count - total_enrolled;
                  console.log("yet_to_enroll", yet_to_enroll);
                  if (yet_to_enroll < 0) {
                    yet_to_enroll = 0;
                  }
                  return yet_to_enroll;
                })()}
              </td>
              <td>
                {(() => {
                  const total_enrolled = item?.enrolment_list?.reduce(
                    (acc, booking) => {
                      return acc + booking.arrived_count;
                    },
                    0
                  );
                  const excess = Math.max(item?.count - total_enrolled, 0);

                  if (excess < 0) {
                    return excess * -1;
                  } else {
                    return 0;
                  }
                })()}
              </td>
              <td>{startCase(item?.booked_by?.name)}</td>
              <td>{moment(item).format("DD/MM/YYYY")}</td>
              <td>{startCase(item?.payment?.payment_mode).toUpperCase()}</td>
              <td>{item?.is_prepaid ? "Paid" : "Unpaid"}</td>
              <td>{startCase(item?.booking_status)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PrintDateWiseReport;
