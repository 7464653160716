import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";

const ExcelImport = () => {
  const [excelData, setExcelData] = useState(null);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        defval: "",
      });

      setExcelData(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx, .xls",
  });

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag & drop an Excel file here, or click to select one.</p>
      </div>

      <div style={{ overflowX: "auto" }}>
        {excelData && (
          <div>
            <h2>Excel Data</h2>
            <table style={{ borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  {excelData[0].map((cell, index) => (
                    <th
                      style={{
                        border: "1px solid #f1f1f1",
                        padding: "5px",
                        textAlign: "left",
                      }}
                      key={index}
                    >
                      {cell}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {excelData.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td
                        style={{
                          border: "1px solid #f1f1f1",
                          padding: "5px",
                          textAlign: "left",
                        }}
                        key={cellIndex}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExcelImport;
