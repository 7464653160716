import { Form, Input } from "antd";
import React from "react";

const DdAmountInput = (props) => {
  const { set_dd_amount } = props;

  const on_change = (e) => {
    set_dd_amount(e.target.value);
  };
  return (
    <Form.Item
      name="dd_amount"
      label={
        <span style={{ fontWeight: "bold", fontSize: "16px" }}>DD Amount</span>
      }
      labelCol={{ span: 24 }}
      rules={[
        {
          required: true,
          message: "Please Enter the DD Amount",
        },
      ]}
    >
      <Input onChange={on_change} disabled={true} />
    </Form.Item>
  );
};

export default DdAmountInput;
