import { Button, Card, Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  query_user_group_wise_summary_list,
} from "../../../../services/redux";
import { query_get_group_wise_summary } from "../../../../services/redux/slices/graphql/graphql_counter_group_wise_report";
import UserGroupWiseReportDate from "./inputs/user_group_wise_report_date";
import UserGroupInput from "./inputs/user_group_input";

const UserGroupWiseReportSearch = ({ set_selected_date }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [date, set_date] = useState({});

  const handle_finish = (values) => {
    console.log(values);
    get_counter_group_wise_report_api(values);
  };

  const get_counter_group_wise_report_api = ({
    enrolled_date_slot_id_list,
    user_group_id_list,
  }) => {
    let keys = [{ key: "get_user_group_wise_summary", loading: true }];
    let query = query_user_group_wise_summary_list;
    let variable = {
      filter: {
        enrolled_date_slot_id_list,
        user_group_id_list,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const on_change_values = () => {
    dispatch(dynamicClear("get_user_group_wise_summary"));
  };

  return (
    <div>
      <Form
        id="search"
        form={form}
        onFinish={handle_finish}
        onValuesChange={on_change_values}
      >
        <Card
          style={{
            width: "96%",
            borderRadius: "5px",
            margin: "auto",
            backgroundColor: "#e7e7e7",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div style={{ marginBottom: "-15px" }}>
            <Row gutter={24}>
              <Col span={11}>
                <UserGroupWiseReportDate
                  set_selected_date={set_selected_date}
                />
              </Col>
              <Col span={10}>
                <UserGroupInput />
              </Col>
              <Col span={3}>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{
                      backgroundColor: "#014477",
                      color: "white",
                      borderRadius: "3px",
                    }}
                  >
                    {"Get"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Card>
      </Form>
    </div>
  );
};

export default UserGroupWiseReportSearch;
