import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ProfileNameFormItem = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name="name"
        label={t("name")}
        rules={[
          {
            required: true,
            message: t("name_is_required"),
          },
        ]}
      >
        <Input placeholder="Name"></Input>
      </Form.Item>
    </div>
  );
};

export default ProfileNameFormItem;
