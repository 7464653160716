import { gql } from "@apollo/client";

export const check_enrolment_session_query = gql`
  query check_enrolment_session($data: check_enrolment_session_input) {
    check_enrolment_session(data: $data) {
      status
      session_status
      error {
        status_code
        message
      }
    }
  }
`;
export const create_enrolment_session_mutation = gql`
  mutation create_enrolment_session {
    create_enrolment_session {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const close_enrolment_session_mutation = gql`
  mutation close_enrolment_session {
    close_enrolment_session {
      status
    }
  }
`;
