import { getUUID, showToast } from "@helpers/functions";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";

import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageName from "./page_name";
import {
  create_system_ui_module_component_mutation,
  create_system_ui_module_page,
  get_system_ui_module_list_query,
  update_system_ui_module_component_mutation,
  update_system_ui_module_page,
} from "../../../../services/redux/slices/graphql/graphql_access_rights";

const PageForm = (props) => {
  const {
    close,
    action_type,
    get_id,
    action_item,
    set_loading,
    set_expanded_row_key,
    link_type,
  } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    status: status,
    loading: loading,
    error: error,
  } = useDynamicSelector("create_system_ui_module_component");

  const {
    status: create_status,
    loading: create_loading,
    error: create_error,
  } = useDynamicSelector("create_system_ui_module");
  const {
    status: update_status,
    loading: update_loading,
    error: update_error,
  } = useDynamicSelector("update_system_ui_module");

  const {
    status: update_component_status,
    loading: update_component_loading,
    error: update_component_error,
  } = useDynamicSelector("update_system_ui_module_component");

  useEffect(() => {
    set_loading(
      create_loading || update_loading || loading || update_component_loading
    );
  }, [create_loading, update_loading, loading, update_component_loading]);

  useEffect(() => {
    if (action_type === "add_page" && create_status === "success") {
      showToast({
        type: "success",
        message: "Module created successfully",
      });
      system_ui_module_list();
      close();
      dispatch(dynamicClear("create_system_ui_module"));
    } else if (action_type === "add_page" && create_error) {
      showToast({
        type: "error",
        message: create_error.message,
      });
      system_ui_module_list();
      dispatch(dynamicClear("create_system_ui_module"));
    } else if (action_type === "add_ui_component" && status === "success") {
      showToast({
        type: "success",
        message: "Page Component created successfully",
      });
      system_ui_module_list();
      close();
      set_expanded_row_key(null);
      dispatch(dynamicClear("create_system_ui_module_component"));
    } else if (action_type === "add_ui_component" && error === "error") {
      showToast({
        type: "error",
        message: error.message,
      });
      system_ui_module_list();
      set_expanded_row_key(null);
      dispatch(dynamicClear("create_system_ui_module_component"));
    } else if (action_type === "edit_page" && update_status === "success") {
      showToast({
        type: "success",
        message: "Page updated successfully",
      });
      system_ui_module_list();
      close();
      dispatch(dynamicClear("update_system_ui_module"));
    } else if (action_type === "edit_page" && update_error === "error") {
      showToast({
        type: "error",
        message: update_error.message,
      });
      system_ui_module_list();
      dispatch(dynamicClear("update_system_ui_module"));
    } else if (
      action_type === "edit_page_component" &&
      update_component_status === "success"
    ) {
      showToast({
        type: "success",
        message: "Page component updated successfully",
      });
      system_ui_module_list();
      close();
      set_expanded_row_key(null);
      dispatch(dynamicClear("update_system_ui_module_component"));
    } else if (
      action_type === "edit_page_component" &&
      update_component_error
    ) {
      showToast({
        type: "error",
        message: update_component_error.message,
      });
      system_ui_module_list();
      set_expanded_row_key(null);
      dispatch(dynamicClear("update_system_ui_module_component"));
    }
  }, [
    create_status,
    create_error,
    status,
    error,
    update_status,
    update_component_status,
    update_component_error,
  ]);

  const system_ui_module_list = () => {
    let key = [{ key: "get_system_ui_module_list", loading: true }];
    let query = get_system_ui_module_list_query;
    let variable = {};
    dispatch(dynamicRequest(key, query, variable));
  };
  useEffect(() => {
    system_ui_module_list();
  }, []);

  const create_page = (data) => {
    let key = [{ key: "create_system_ui_module", loading: true }];
    let query = create_system_ui_module_page;
    let variable = { data };
    dispatch(dynamicRequest(key, query, variable));
  };
  const update_page = (data) => {
    let key = [{ key: "update_system_ui_module", loading: true }];
    let query = update_system_ui_module_page;
    let variable = { data, id: get_id };
    dispatch(dynamicRequest(key, query, variable));
  };

  const create_page_component = (data) => {
    let key = [{ key: "create_system_ui_module_component", loading: true }];
    let query = create_system_ui_module_component_mutation;
    let variable = { data };
    dispatch(dynamicRequest(key, query, variable));
  };

  const update_page_component = (data) => {
    let key = [{ key: "update_system_ui_module_component", loading: true }];
    let query = update_system_ui_module_component_mutation;
    let variables = { data, id: get_id };
    dispatch(dynamicRequest(key, query, variables));
  };

  const on_finish = (values) => {
    let uuid = getUUID();
    if (action_type === "add_page") {
      create_page({ ...values, id: uuid });
    } else if (action_type === "add_ui_component") {
      create_page_component({
        ...values,
        id: uuid,
        ui_module_id: get_id,
      });
    } else if (action_type === "edit_page") {
      update_page({
        ...values,
        id: get_id,
      });
    } else if (action_type === "edit_page_component") {
      update_page_component({
        ...values,
        id: get_id,
      });
    }
  };

  useEffect(() => {
    if (action_type === "add_page") {
      form?.resetFields();
      form.setFieldsValue({});
    } else {
      form.setFieldsValue({ name: action_item.name });
    }
  }, [action_item]);

  return (
    <div>
      <Form name="create page" onFinish={on_finish} id="page_form" form={form}>
        <PageName />
      </Form>
    </div>
  );
};
export default PageForm;
