import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import DistrictUserAccess from "../district_user_access";
import UpdateDistrictUserAccessButtons from "../update_district_user_access_buttons";
import MandramFilter from "./mandram_filter";
import { dynamicRequest, mutation_set_accessible_mandram_list_for_sub_district } from "../../../../services/redux";

export const ManagingProvisionModalSubDistrict = (props) => {
  const {
    managing_provision_modal_visible,
    set_managing_provision_modal_visible,
    get_all_mandram_list,
    district_user_id,
    mandram_list,
    set_mandram_list,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handle_update = () => {
    let list = mandram_list?.filter((x) => x.is_active)?.map((item) => item.id);
    set_accessible_mandram_list_for_sub_district(list);
    set_managing_provision_modal_visible(false);
  };

  const set_accessible_mandram_list_for_sub_district = (mandram_id) => {
    let key = [{ key: "set_accessible_mandram_list_for_sub_district", loading: true }];
    let query = mutation_set_accessible_mandram_list_for_sub_district;
    let variables = {
      custom: {
        mandram_id_list: mandram_id,
        sub_district_user_id: district_user_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <Modal
      open={managing_provision_modal_visible}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      width={"60%"}
      title={t("provisions")}
      onCancel={() => set_managing_provision_modal_visible(false)}
      footer={
        <UpdateDistrictUserAccessButtons
          mandram_list={mandram_list}
          handle_update={handle_update}
          set_managing_provision_modal_visible={
            set_managing_provision_modal_visible
          }
          set_mandram_list={set_mandram_list}
        />
      }
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <MandramFilter get_all_mandram_list={get_all_mandram_list} />
      </div>
      <DistrictUserAccess
        get_all_mandram_list={get_all_mandram_list}
        district_user_id={district_user_id}
        set_managing_provision_modal_visible={
          set_managing_provision_modal_visible
        }
        mandram_list={mandram_list}
        set_mandram_list={set_mandram_list}
      />
    </Modal>
  );
};
export default ManagingProvisionModalSubDistrict;
