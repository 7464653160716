import { Button, Modal, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { useDynamicSelector } from "../../../../services/redux";
import BookingConfirmationDetail from "../../booking/booking_modal_form_item/booking_confirmation_detail";

const VipBookingEnrollmentDetailModel = ({
  enrollment_model_open,
  set_enrollment_model_open,
  enrollment_details,
}) => {
  const { sequence_count, booking } = useDynamicSelector(
    "get_enrolment_detail"
  );

  const { t } = useTranslation();
  const on_finish = () => {
    set_enrollment_model_open(false);
  };
  // const on_close = () => {
  //   set_enrollment_model_open(false);
  // };
  return (
    <Modal
      title={t("enrollment_details")}
      open={enrollment_model_open}
      onCancel={() => set_enrollment_model_open(false)}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      footer={[
        <Button
          style={{
            backgroundColor: "#f96451",
            color: "white",
            border: "transparent",
          }}
          htmlType="submit"
          onClick={on_finish}
        >
          {t("close")}
        </Button>,
        // <Button
        //   style={{
        //     backgroundColor: "#168f92",
        //     color: "white",
        //     border: "transparent",
        //   }}
        //   onClick={on_close}
        // >
        //   {t("no")}
        // </Button>,
      ]}
    >
      <BookingConfirmationDetail
        title={t("booking_no")}
        value={enrollment_details?.booking_reference}
      />
      <BookingConfirmationDetail
        title={t("receipt_no")}
        value={enrollment_details?.receipt_no}
      />
      <BookingConfirmationDetail
        title={t("count")}
        value={enrollment_details?.arrived_count}
      />
      <BookingConfirmationDetail
        title={t("amount")}
        value={enrollment_details?.amount}
      />
      <BookingConfirmationDetail
        title={t("payment")}
        value={enrollment_details?.spot_vip_booking_pay_mode}
      />
      <BookingConfirmationDetail
        title={t("payment")}
        value={enrollment_details?.district_code}
      />
    </Modal>
  );
};

export default VipBookingEnrollmentDetailModel;
