import React from "react";
import { Form, Input } from "antd";

const InputSMS = () => {
  const validate_mobile_number = (_, value) => {
    const regex = /^[0-9]{10}$/;
    if (value && !regex.test(value)) {
      return Promise.reject("Please enter a valid number.");
    }
    return Promise.resolve();
  };

  return (
    <Form.Item
      label="Mobile Number"
      name="mobile"
      rules={[
        { required: true, message: "Please enter your mobile number." },
        { validator: validate_mobile_number },
      ]}
    >
      <Input placeholder="Enter your mobile number" />
    </Form.Item>
  );
};

export default InputSMS;
