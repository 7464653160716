import React from "react";
import { Modal, Button } from "antd";
import { Text } from "native-base";

const EnableConfirmationModal = (props) => {
  const { handle_enable_confirm, cancel_enable_modal, is_enable_modal } = props;

  return (
    <Modal
      open={is_enable_modal}
      title="Confirmation"
      onCancel={cancel_enable_modal}
      footer={[
        <Button key="cancel" onClick={cancel_enable_modal}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={() => handle_enable_confirm()}
        >
          Yes
        </Button>,
      ]}
    >
      <Text>Do you really want enable this?</Text>
    </Modal>
  );
};

export default EnableConfirmationModal;
