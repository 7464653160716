import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import FeedbackManageTitleFormItem from "./feedback_manage_title_form_item";
import {
  create_feedback_mutation,
  dynamicClear,
  dynamicRequest,
  update_feedback_mutation,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { showToast } from "../../../../../helpers/functions";

const FeedbackManageMainForm = (props) => {
  const [form] = Form.useForm();
  const {
    set_edit_modal_visible,
    header_type,
    get_feedback_list_query,
    edit_values,
    is_active_record,
  } = props;

  const dispatch = useDispatch();

  const {
    status: create_feedback_status,
    error: create_feedback_error,
    loading: create_feedback_loading,
  } = useDynamicSelector("create_feedback");
  const { status: update_feedback_status, error: update_feedback_error } =
    useDynamicSelector("update_feedback");
  const [initial_values, set_initial_values] = useState({});

  useEffect(() => {
    if (edit_values.id) {
      form.setFieldsValue({
        ...edit_values,
      });
      set_initial_values({ ...edit_values });
    } else {
      form.resetFields();
    }
  }, [edit_values]);
  useEffect(() => {
    if (create_feedback_status === "success") {
      toast.success("Feedback created successfully");

      set_edit_modal_visible(false);

      get_feedback_list_query();
      dispatch(dynamicClear("create_feedback"));
    } else if (create_feedback_error?.message) {
      toast.error(create_feedback_error.message);
      get_feedback_list_query();
      dispatch(dynamicClear("create_feedback"));
    }
    if (update_feedback_status === "success") {
      toast.success("Feedback updated successfully");
      set_edit_modal_visible(false);
      get_feedback_list_query();
      dispatch(dynamicClear("update_feedback"));
    } else if (update_feedback_error?.message) {
      toast.error(update_feedback_error.message);
      get_feedback_list_query();
      dispatch(dynamicClear("update_feedback"));
    }
  }, [
    create_feedback_status,
    update_feedback_status,
    create_feedback_error,
    update_feedback_error,
  ]);
  const create_feedback_mutation_method = (values) => {
    let key = [{ key: "create_feedback", loading: true }];
    let query = create_feedback_mutation;
    let variable = {
      data: {
        title: values.title,
      },
    };

    dispatch(dynamicRequest(key, query, variable));
  };

  const update_feedback = (edit_id, values) => {
    let key = [{ key: "update_feedback", loading: true }];
    let query = update_feedback_mutation;
    let variable = {
      id: edit_id,
      data: {
        title: values.title,
        is_active: !is_active_record?.is_active,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };
  const handle_finish = (values, is_active_record) => {
    if (header_type === "add") {
      create_feedback_mutation_method(values);
    } else if (header_type === "edit") {
      let edit_id = edit_values?.id;
      update_feedback(edit_id, values);
    }
  };

  return (
    <div>
      <Form
        onFinish={handle_finish}
        id="feedback_form"
        initialValues={initial_values}
        form={form}
      >
        <FeedbackManageTitleFormItem />
      </Form>
    </div>
  );
};

export default FeedbackManageMainForm;
