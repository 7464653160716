import { Form, Input, InputNumber } from "antd";
import React, { useTransition } from "react";
import { useTranslation } from "react-i18next";

const SpTicketPriceFormItem = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name="vip_ticket_price"
        label={t("spl_ticket_price")}
        style={{ width: "400px" }}
        rules={[
          {
            required: true,
            message: "VIP ticket price is required",
          },
        ]}
      >
        <InputNumber placeholder={t("vip_ticket_price")} />
      </Form.Item>
    </div>
  );
};

export default SpTicketPriceFormItem;
