import { Form, Input } from "antd";
import React from "react";

const MandramInChargeInput = () => {
  return (
    <Form.Item
      label="Incharge Name"
      name="incharge_name"
      rules={[
        {
          required: true,
          message: "Please enter Incharge Name",
        },
      ]}
    >
      <Input />
    </Form.Item>
  );
};

export default MandramInChargeInput;
