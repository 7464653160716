import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const SpotBookingName = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        label={t("name")}
        name="spot_incharge_name"
        rules={[{ required: true, message: "Please enter the name" }]}
      >
        <Input
          style={{
            width: "100%",
          }}
        />
      </Form.Item>
    </div>
  );
};

export default SpotBookingName;
