import { Form, Input } from "antd";
import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

const TitleFormItem = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name="title"
        label={t("title")}
        style={{ width: "400px" }}
        rules={[
          {
            required: true,
            message: "Title is required",
          },
        ]}
      >
        <Input placeholder={t("title")} />
      </Form.Item>
    </div>
  );
};

export default TitleFormItem;
