import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "../../../../services/redux";
import { Form, Select } from "antd";
import { useEffect } from "react";
import { enrolment_user_list } from "../../../../services/redux/slices/graphql/graphql_user_counter_wise_report";

const UserCounterWiseFilter = () => {
  const dispatch = useDispatch();
  const { items: counter_users } = useDynamicSelector("get_enrolment_user");
  console.log("counter_users", counter_users);
  useEffect(() => {
    query_get_enrolment_users();
  }, []);

  const query_get_enrolment_users = () => {
    let key = [{ key: "get_enrolment_user", loading: true }];
    let query = enrolment_user_list;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const custom_filter_option = (input, option) => {
    const input_value = input.toLowerCase();
    const option_label = option.props.children.toLowerCase();
    return option_label.indexOf(input_value) === 0;
  };

  return (
    <div>
      <Form.Item name="user_id_list" label="User">
        <Select
          mode="multiple"
          placeholder="Select User"
          allowClear
          showSearch
          filterOption={custom_filter_option}
        >
          {counter_users?.map((user, index) => (
            <Select.Option
              key={user.system_user?.id}
              value={user.system_user?.id}
            >
              {user.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default UserCounterWiseFilter;
