import { Button, Form, Input, InputNumber, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  mutation_upsert_extra_allowed_percentage,
  query_get_settings,
} from "../../../services/redux/slices/graphql/graphql_pos";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const BankExtraAllowedPercentage = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [allowed_percentage, set_allowed_percentage] = useState(0);

  console.log("allowed_percentage", allowed_percentage);
  const on_finish = () => {
    upsert_extra_allowed_percentage_bank();
  };
  const { t } = useTranslation();

  const { status: create_percentage_status, error: create_percentage_error } =
    useDynamicSelector("update_settings");
  const { id, bank_branch_extra_allowed_percentage } =
    useDynamicSelector("get_settings");
  console.log(
    "bank_branch_extra_allowed_percentage",
    bank_branch_extra_allowed_percentage,
    id
  );

  const upsert_extra_allowed_percentage_bank = () => {
    const allowed_per = parseInt(allowed_percentage, 0);
    let key = [{ key: "update_settings", loading: true }];
    let query = mutation_upsert_extra_allowed_percentage;
    let variables = {
      data: {
        bank_branch_extra_allowed_percentage: allowed_per,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_setting_percentage = () => {
    let key = [{ key: "get_settings", loading: true }];
    let query = query_get_settings;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const on_change = (value) => {
    set_allowed_percentage(value);
  };

  useEffect(() => {
    get_setting_percentage();
  }, [create_percentage_status]);

  useEffect(() => {
    if (create_percentage_status === "success") {
      toast.success("Updated Successfully");
      form.resetFields("");
    } else {
      toast.error(create_percentage_error);
    }
    dispatch(dynamicClear("update_settings"));
  }, [create_percentage_status, create_percentage_error]);

  return (
    <div>
      <Form onFinish={on_finish} layout="horizontal" form={form}>
        <Row
          style={{
            width: "100%",
          }}
        >
          <Form.Item
            name="allowed_percentage"
            rules={[
              {
                validator: (_, value) => {
                  const numericValue = value
                    ? String(value).replace(/[^\d.]/g, "")
                    : "";

                  if (numericValue === "") {
                    return Promise.reject("Please enter a value.");
                  }

                  const floatValue = parseFloat(numericValue);

                  if (
                    isNaN(floatValue) ||
                    floatValue < 0.1 ||
                    floatValue > 100
                  ) {
                    return Promise.reject("Enter a number between 1 and 100.");
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Row style={{ display: "flex", flexDirection: "row" }}>
              <Typography style={{ width: "40%", fontWeight: "bold" }}>
                {t("bank_branch_enrollment_percentage")}
              </Typography>
              <Typography
                style={{
                  width: "20px",
                  marginTop: "11px",
                  fontWeight: "bold",
                }}
              >
                :
              </Typography>
              <InputNumber
                placeholder="Enter a Allowed Percentage"
                style={{ width: "10%", marginLeft: "45%", marginTop: "-40px" }}
                min={0.1}
                max={100}
                step={0.1}
                onChange={on_change}
              />
            </Row>
          </Form.Item>
        </Row>
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            htmlType="submit"
            style={{
              color: "white",
              border: "#000",
              width: "20%",
              backgroundColor: "#033E3E",
              borderRadius: "6px",
              marginTop: "30px",
              marginRight: "10px",
            }}
          >
            save
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export default BankExtraAllowedPercentage;
