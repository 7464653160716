import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { toUpper, upperCase } from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SwamiImage from "@assets/icons/adhiparashathi_image.png";

const OmshakthiReportPdfRender = (props) => {
  const { om_shakthi_items, om_shakthi_payment_items, to_date, from_date } =
    props;

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "white",
      padding: 20,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    table: {
      display: "table",
      width: "auto",
      marginBottom: "10px",
    },
    tableRow: {
      flexDirection: "row",
    },
    table_first_cell: {
      padding: 4,
      flex: 1,
      textAlign: "left",
      fontSize: "8px",
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 0,
    },
    table_last_cell: {
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "8px",
      borderTopWidth: 1,
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    table_last_index_first_cell: {
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "8px",
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderRightWidth: 1,
    },
    table_last_index_name_last_cell: {
      padding: 4,
      flex: 1,
      textAlign: "left",
      fontSize: "8px",
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderRightWidth: 1,
    },
    table_last_index_cell: {
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "8px",
      borderTopWidth: 1,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      // borderBottomWidth: 1,
    },
    table_last_index_last_cell: {
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "8px",
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    tableCell: {
      // borderTopWidth: 1,
      // borderBottomWidth: 0,
      // borderLeftWidth: 1,
      // borderRightWidth: 0,
      borderWidth: 1,
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "8px",
    },
    tableCellName: {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 0,
      padding: 4,
      flex: 1,
      textAlign: "left",
      fontSize: "8px",
    },
    tableColHeader: {
      // borderRightWidth: 1,
      // borderLeftWidth: 1,
      // borderTopWidth: 1,
      borderWidth: 1,
      backgroundColor: "#f0f0f0",
      padding: 3,
      flex: 1,
      textAlign: "center",
      fontSize: "10px",
    },
    tableColumn: {
      paddingTop: "3px",
      width: "80px",
      borderWidth: 1,
      backgroundColor: "#f0f0f0",
      textAlign: "center",
      fontSize: "10px",
    },
    table_row: {
      width: "80px",
      borderWidth: 1,
      textAlign: "center",
      fontSize: "8px",
      padding: 4,
    },
    tableColHeader_first_header: {
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      backgroundColor: "#f0f0f0",
      padding: 3,
      flex: 1,
      textAlign: "center",
      fontSize: "10px",
    },
    table_header: {
      display: "flex",
      margin: "10px 0px 0px 0px",
      fontWeight: "bold",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "10px",
    },
    table_title: {
      display: "flex",
      justifyContent: "flex-start",
      fontWeight: "600",
      fontSize: "10px",
    },
    header_content: {
      fontWeight: "600",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "10px",
    },
    image: {
      width: "30px",
      height: "40px",
    },
    full_header_content: {
      marginTop: "-30px",
      display: "flex",
      alignContent: "center",
      justifyContent: "space-evenly",
    },
    pageNumber: {
      position: "absolute",
      bottom: 10,
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: 10,
    },
  });

  const { t } = useTranslation();

  const upi_payment_items = om_shakthi_payment_items?.filter(
    (item) => item.payment_mode === "upi"
  );

  const card_payment_items = om_shakthi_payment_items?.filter(
    (item) => item.payment_mode === "card"
  );

  const dd_payment_items = om_shakthi_payment_items?.filter(
    (item) => item.payment_mode === "dd"
  );

  const calculate_upi_column_total = (columnKey) => {
    return upi_payment_items
      ? upi_payment_items.reduce(
          (sum, record) => sum + (record[columnKey] || 0),
          0
        )
      : 0;
  };

  const calculate_card_column_total = (columnKey) => {
    return card_payment_items
      ? card_payment_items.reduce(
          (sum, record) => sum + (record[columnKey] || 0),
          0
        )
      : 0;
  };

  const calculate_dd_column_total = (columnKey) => {
    return dd_payment_items
      ? dd_payment_items.reduce(
          (sum, record) => sum + (record[columnKey] || 0),
          0
        )
      : 0;
  };

  return (
    <Document fileName={`Om-Shakthi-report.pdf`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image style={styles.image} src={SwamiImage}></Image>
          <View style={styles.flexContainer}>
            <View style={styles.full_header_content}>
              <Text style={styles.header_content}>{t("om_shakthi")}</Text>
              <Text style={styles.header_content}>{t("masm")} </Text>
              <Text style={styles.header_content}>
                {t("adhiparashakthi_siddhar_peedam")}
              </Text>
            </View>
          </View>
          <View></View>
          <Text style={styles.table_header}>
            {toUpper("Om Shakthi report")}
          </Text>
          <Text style={styles.table_header}>
            {from_date?.selected_from_date} To {to_date?.selected_to_date}
          </Text>
          <Text style={styles.table_header}></Text>
          <Text style={styles.table_title}>Ticket Summary</Text>
          <Text style={styles.table_header}></Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColumn}>Normal Ticket Devotees</Text>
              <Text style={styles.tableColumn}>
                Normal Ticket Received Amount
              </Text>
              <Text style={styles.tableColHeader}>VIP Ticket Devotees</Text>
              <Text style={styles.tableColHeader}>
                VIP Ticket Received Amount
              </Text>
              <Text style={styles.tableColumn}>Total Devotees</Text>
              <Text style={styles.tableColumn}>Total Amount</Text>
            </View>

            {om_shakthi_items?.map((item, index) => (
              <View
                style={styles.tableRow}
                key={index}
                wrap={index !== 0 ? "wrap" : "nowrap"}
              >
                <Text style={styles.table_row}>
                  {item?.base_total_devotees}
                </Text>
                <Text style={styles.table_row}>{item?.base_total_amount}</Text>
                <Text style={styles.tableCell}>{item?.vip_total_devotees}</Text>
                <Text style={styles.tableCell}>{item.vip_total_amount}</Text>
                <Text style={styles.table_row}>{item?.total_devotees}</Text>
                <Text style={styles.table_row}>{item?.total_amount}</Text>
              </View>
            ))}
          </View>

          <Text style={styles.table_header}></Text>
          <Text style={styles.table_title}>Pay Mode Wise Summary</Text>
          <Text style={styles.table_header}></Text>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>Cash Amount</Text>
              <Text style={styles.tableColHeader}>DD Amount</Text>
              <Text style={styles.tableColHeader}>Card Amount</Text>
              <Text style={styles.tableColHeader}>UPI Amount</Text>
              <Text style={styles.tableColHeader}>Total Amount</Text>
            </View>

            {om_shakthi_items?.map((item, index) => (
              <View
                style={styles.tableRow}
                key={index}
                wrap={index !== 0 ? "wrap" : "nowrap"}
              >
                <Text style={styles.tableCell}>{item?.total_cash_amount}</Text>
                <Text style={styles.tableCell}>{item?.total_dd_amount}</Text>
                <Text style={styles.tableCell}>{item.total_card_amount}</Text>
                <Text style={styles.tableCell}>{item?.total_upi_amount}</Text>
                <Text style={styles.tableCell}>{item?.total_amount}</Text>
              </View>
            ))}
          </View>

          <Text style={styles.table_header}></Text>
          {upi_payment_items?.length && (
            <View>
              <Text style={styles.table_title}>UPI</Text>
              <Text style={styles.table_header}></Text>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <Text style={styles.tableColHeader}>SI.No</Text>
                  <Text style={styles.tableColHeader}>Receipt No</Text>
                  <Text style={styles.tableColHeader}>Reference Number</Text>
                  <Text style={styles.tableColHeader}>Amount</Text>
                </View>

                {upi_payment_items.map((item, index) => (
                  <View
                    style={styles.tableRow}
                    key={index}
                    wrap={index !== 0 ? "wrap" : "nowrap"}
                  >
                    <Text style={styles.tableCell}>{index + 1}</Text>
                    <Text style={styles.tableCell}>{item?.receipt_number}</Text>
                    <Text style={styles.tableCell}>{item?.pos_receipt}</Text>
                    <Text style={styles.tableCell}>{item?.amount}</Text>
                  </View>
                ))}
              </View>

              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>TOTAL</Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}>
                  {calculate_upi_column_total("amount")}
                </Text>
              </View>
            </View>
          )}

          <Text style={styles.table_header}></Text>
          {card_payment_items?.length && (
            <View>
              <Text style={styles.table_title}>Card</Text>
              <Text style={styles.table_header}></Text>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <Text style={styles.tableColHeader}>SI.No</Text>
                  <Text style={styles.tableColHeader}>Receipt No</Text>
                  <Text style={styles.tableColHeader}>Reference Number</Text>
                  <Text style={styles.tableColHeader}>Amount</Text>
                </View>

                {card_payment_items.map((item, index) => (
                  <View
                    style={styles.tableRow}
                    key={index}
                    wrap={index !== 0 ? "wrap" : "nowrap"}
                  >
                    <Text style={styles.tableCell}>{index + 1}</Text>
                    <Text style={styles.tableCell}>{item?.receipt_number}</Text>
                    <Text style={styles.tableCell}>{item?.pos_receipt}</Text>
                    <Text style={styles.tableCell}>{item?.amount}</Text>
                  </View>
                ))}
              </View>

              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>TOTAL</Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}>
                  {calculate_card_column_total("amount")}
                </Text>
              </View>
            </View>
          )}
          <Text style={styles.table_header}></Text>
          {dd_payment_items?.length && (
            <View>
              <Text style={styles.table_title}>UPI</Text>
              <Text style={styles.table_header}></Text>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <Text style={styles.tableColHeader}>SI.No</Text>
                  <Text style={styles.tableColHeader}>Receipt No</Text>
                  <Text style={styles.tableColHeader}>Reference Number</Text>
                  <Text style={styles.tableColHeader}>DD Date</Text>
                  <Text style={styles.tableColHeader}>Amount</Text>
                </View>

                {dd_payment_items.map((item, index) => (
                  <View
                    style={styles.tableRow}
                    key={index}
                    wrap={index !== 0 ? "wrap" : "nowrap"}
                  >
                    <Text style={styles.tableCell}>{index + 1}</Text>
                    <Text style={styles.tableCell}>{item?.receipt_number}</Text>
                    <Text style={styles.tableCell}>{item?.pos_receipt}</Text>
                    <Text style={styles.tableCell}>
                      {moment(item?.dd_datetime).format("DD/MM/YYYY")}
                    </Text>

                    <Text style={styles.tableCell}>{item?.amount}</Text>
                  </View>
                ))}
              </View>

              <View style={styles.tableRow}>
                <Text style={styles.tableCell}>TOTAL</Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}></Text>
                <Text style={styles.tableCell}>
                  {calculate_dd_column_total("amount")}
                </Text>
              </View>
            </View>
          )}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default OmshakthiReportPdfRender;
