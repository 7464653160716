import React from "react";
import { Card, Row } from "antd";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";
import DayWiseReportForm from "../components/system_report/day_wise_report/day_wise_report_form/day_wise_report_main_form";
import { useDynamicSelector } from "../../services/redux";
import LoaderSpinner from "../components/common/custom_spinner";

const DayWiseReport = () => {
  const { t } = useTranslation();
  const { loading: get_day_wise_summary_loading } = useDynamicSelector(
    "get_daywise_summary"
  );
  return (
    <div>
      <Row style={{ marginLeft: "10px" }}>
        <CustomPageTitle title={t("day_wise_report")} />
      </Row>

      <div style={{ marginTop: "20px" }}>
        <DayWiseReportForm />
      </div>
      {get_day_wise_summary_loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <LoaderSpinner />
        </div>
      )}
    </div>
  );
};

export default DayWiseReport;
