import React, { useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "antd";
import CustomButton from "../components/common/custom_button";
import SystemUserSelectRole from "../components/system_users/masm_user_select_role/masm_user_select_role";
import SystemUserTable from "../components/system_users/masm_user_table/masm_user_table";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import { useParams } from "react-router-dom";
import {
  query_get_system_role_list,
  useDynamicSelector,
} from "../../services/redux";

const SystemUsers = () => {
  // const { role_name } = useParams();
  const { t } = useTranslation();
  // const [modal_visible, set_modal_visible] = useState(false);
  // const [modal_title, set_modal_title] = useState("");
  // const [selected_items, set_selected_items] = useState({});

  // const { items: roles_items } = useDynamicSelector("get_roles");

  // const get_all_system_role_list = () => {
  //   let key = [{ key: "get_roles", loading: true }];
  //   let query = query_get_system_role_list;
  //   dispatch(dynamicRequest(key, query));
  // };
  // useEffect(() => {
  //   get_all_system_role_list();
  // }, []);

  // const [role_type, set_role_type] = useState(null);

  // const add_system_user = () => {
  //   set_modal_visible(true);
  //   set_modal_title("add");
  //   set_selected_items("");
  // };

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <CustomPageTitle title={t("system_users_title")} />
        </Row>

        <Row style={{ margin: "8px", marginRight: "23px" }}>
          {/* <CustomBreadCrumbs pages={"Home/MASM User"} /> */}
        </Row>
      </Row>

      <Card
        style={{
          width: "96%",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Row
            style={{
              alignItems: "flex-end",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginBottom: "20px",
              gap: "10px",
            }}
          >
            {/* <SystemUserSelectRole set_role_type={set_role_type} /> */}
            <CustomButton
              button_name={t("add_system_user")}
              onclick_button={add_system_user}
            />
          </Row>
          {/* <SystemUserSelectRole /> */}
          <div>{/* <SystemUserSelectRole /> */}</div>

          <SystemUserTable
            role_type={role_type}
            modal_visible={modal_visible}
            set_modal_visible={set_modal_visible}
            modal_title={modal_title}
            set_selected_items={set_selected_items}
            selected_items={selected_items}
            set_modal_title={set_modal_title}
            set_role_type={set_role_type}
            role_name={role_name}
          />
        </Row>
      </Card>
    </>
  );
};
export default SystemUsers;
