import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Modal, Typography } from "antd";
import Title from "antd/lib/typography/Title";
import CustomPageTitle from "../components/common/custom_page_title";
import SearchEnrollDetailsForm from "../components/enroll/search_enroll_details/search_enroll_details_form";
import PaymentHistoryTable from "../components/enroll/payment_history_for_enroll/payment_history_table";
import EnrollmentListTable from "../components/enroll/enrollment_history/enrollment_list_table";
import ReceiptDetails from "../components/enroll/receipt_details_for_enroll/receipt_details";
import EnrollButtons from "../components/enroll/enroll_buttons";
import {
  check_enrolment_session_query,
  close_enrolment_session_mutation,
  create_enrolment_session_mutation,
  dynamicClear,
  dynamicRequest,
  mutation_enrolment_ticket_print,
  query_check_has_pos_for_enroll,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import {
  get_closure_summary_query,
  get_enroll_booking_list_query,
  set_counter_user_mutation,
} from "../../services/redux/slices/graphql/graphql_enroll_booking";
import io from "socket.io-client";
import {
  retrieveItem,
  send_response_to_printer_service,
  storeItem,
} from "../../helpers/functions";
import toast from "react-hot-toast";
import { IoMdClose } from "react-icons/io";
import { get_current_counter_query } from "../../services/redux/slices/graphql/graphql_counter_group";
import axios from "axios";
import { get_enrolment_detail_query } from "../../services/redux/slices/graphql/graphql_get_enrolment_detail";
import { API_URL } from "../../helpers/constants";
import moment from "moment";

const Enroll = () => {
  const dispatch = useDispatch();
  const receipt_input_ref = useRef(null);
  const [counter_mac_address, set_counter_mac_address] = useState(null);

  const [session_modal_visible, set_session_modal_visible] = useState(false);
  const [is_loading, set_is_loading] = useState(false);
  const [is_print_token_modal_visible, set_is_print_token_modal_visible] =
    useState(false);
  const [can_enroll, set_can_enroll] = useState(false);
  const [enrolled_id, set_enrolled_id] = useState(null);
  const [token_printer_response, set_token_printer_response] = useState("");
  const [socket_details, set_socket_details] = useState();
  const [totalAmount, set_total_amount] = useState(0);
  const [system_reference, set_system_reference] = useState("");

  console.log("token_printer_response_test", token_printer_response);

  let socket;
  const session_id = retrieveItem("session_id");
  const system_user = retrieveItem("system_user");

  const { id: enroll_booking_id, status: enrollment_booking_status } =
    useDynamicSelector("enroll_booking");

  const {
    session_status: check_enrolment_session_status,
    status: check_enrolment_status,
    error: check_enrolment_session_error,
  } = useDynamicSelector("check_enrolment_session");
  const {
    id: create_enrolment_session_id,
    status: create_enrolment_session_status,
    error: create_enrolment_session_error,
  } = useDynamicSelector("create_enrolment_session");

  const {
    enrolment_id: enrollment_payment_check_enrolment_id,
    payment_status: enrollment_payment_check_status,
  } = useDynamicSelector("enrollment_payment_check");

  const { payment_status, enrolment_id: check_order_booking_id } =
    useDynamicSelector("check_order_status_for_spot_booking");

  const {
    status: set_counter_user_status,
    id: set_counter_user_status_id,
    error: set_counter_user_error,
  } = useDynamicSelector("set_counter_user");

  const {
    status: spot_booking_and_enroll_status,
    id: create_spot_booking_and_enroll_id,
  } = useDynamicSelector("create_spot_booking_and_enroll");

  const { name: current_counter_name, id: current_counter_id } =
    useDynamicSelector("get_current_counter");

  // useEffect(() => {
  //   let socket_url = "http://localhost:7876";
  //   socket = io.connect(`${socket_url}`, {
  //     transports: ["websocket"],
  //     query: { session_id },
  //   });
  //   set_socket_details(socket);
  //   console.log("socket", socket);
  //   socket.on("connect", () => {
  //     console.log("Connected to server");
  //   });
  //   // socket.on("mac_address_found", async (mac_address) => {
  //   //   set_counter_mac_address(mac_address);
  //   // });
  //   socket.on("connect_error", (error) => {
  //     console.error("Connection error:", error);
  //   });

  //   socket.on("print_response", (response) => {
  //     console.log("Message from server:", response);
  //     set_token_printer_response(response);
  //   });
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  console.log("token_printer_response1", token_printer_response);
  console.log("is_loading", is_loading);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:7878/my-address");
        set_counter_mac_address(response?.data);
        console.error("response", response);
      } catch (error) {
        console.error("Error fetching MAC address:", error);
      }
    };
    fetchData();
  }, []);

  const TicketsPrinted = async () => {
    let key = [{ key: "set_enrolment_ticket_printed", loading: true }];
    let query = mutation_enrolment_ticket_print;
    let variables = {
      id: enrolled_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (token_printer_response === "printing started") {
      TicketsPrinted();
      console.log("token_printer_response2", token_printer_response);
      set_is_loading(false);
      close_token_printer_modal();
      set_token_printer_response("");
      dispatch(dynamicClear("enroll_booking"));
      dispatch(dynamicClear("enrollment_payment_check"));
      dispatch(dynamicClear("check_order_status_for_spot_booking"));
      dispatch(dynamicClear("create_spot_booking_and_enroll"));

      if (receipt_input_ref.current) {
        receipt_input_ref.current.focus();
      }
    } else if (
      token_printer_response === "tickets already printed" ||
      token_printer_response === "invalid enrolment status" ||
      token_printer_response === "error"
    ) {
      toast.error("Tickets already printed");
      close_token_printer_modal();
      set_token_printer_response("");
      if (receipt_input_ref.current) {
        receipt_input_ref.current.focus();
      }
      dispatch(dynamicClear("enroll_booking"));
      dispatch(dynamicClear("enrollment_payment_check"));
      dispatch(dynamicClear("check_order_status_for_spot_booking"));
      dispatch(dynamicClear("create_spot_booking_and_enroll"));
    }
  }, [token_printer_response, receipt_input_ref]);

  useEffect(() => {
    if (enroll_booking_id && enrollment_booking_status === "enrolled") {
      set_enrolled_id(enroll_booking_id);
      dispatch(dynamicClear("check_enrolment_session"));
    } else if (
      enrollment_payment_check_enrolment_id &&
      enrollment_payment_check_status === "completed"
    ) {
      set_enrolled_id(enrollment_payment_check_enrolment_id);
      dispatch(dynamicClear("check_enrolment_session"));
    } else if (check_order_booking_id && payment_status === "completed") {
      set_enrolled_id(check_order_booking_id);
    } else if (create_spot_booking_and_enroll_id) {
      set_enrolled_id(create_spot_booking_and_enroll_id);
    }
  }, [
    enroll_booking_id,
    enrollment_payment_check_enrolment_id,
    check_order_booking_id,
    create_spot_booking_and_enroll_id,
    payment_status,
  ]);

  useEffect(() => {
    if (set_counter_user_status === "success") {
      check_enrollment_session_status(counter_mac_address);
      dispatch(dynamicClear("set_counter_user"));
    } else if (set_counter_user_error) {
      toast.error(set_counter_user_error?.message);
      dispatch(dynamicClear("set_counter_user"));
    }
  }, [set_counter_user_status, set_counter_user_error]);

  useEffect(() => {
    if (counter_mac_address) {
      set_system_reference(counter_mac_address);
      set_counter_for_user(counter_mac_address);
    }
  }, [counter_mac_address]);

  useEffect(() => {
    if (check_enrolment_session_error) {
      toast.error(check_enrolment_session_error?.message);
      set_can_enroll(false);
      dispatch(dynamicClear("check_enrolment_session"));
    } else if (check_enrolment_session_status === "open") {
      set_can_enroll(true);
      get_enroll_booking_list();
      get_closure_details();
      receipt_input_ref?.current?.focus();
      dispatch(dynamicClear("check_enrolment_session"));
    } else if (check_enrolment_session_status === "closed") {
      set_can_enroll(false);
      open_session_modal();
      dispatch(dynamicClear("check_enrolment_session"));
    }
  }, [check_enrolment_session_status, check_enrolment_session_error]);

  useEffect(() => {
    if (create_enrolment_session_status === "success") {
      set_can_enroll(true);
      if (receipt_input_ref.current) {
        receipt_input_ref.current.focus();
      }
      close_session_modal();
      dispatch(dynamicClear("create_enrolment_session"));
    } else if (create_enrolment_session_error) {
      set_can_enroll(false);
      toast.error(create_enrolment_session_error?.message);
      dispatch(dynamicClear("create_enrolment_session"));
    }
  }, [
    create_enrolment_session_status,
    create_enrolment_session_id,
    create_enrolment_session_error,
    receipt_input_ref,
  ]);

  useEffect(() => {
    if (system_reference) {
      get_current_counter_name();
    }
  }, [system_reference]);

  useEffect(() => {
    if (current_counter_id) {
      get_check_pos_connect();
    }
  }, [current_counter_id]);

  useEffect(() => {
    if (enrollment_booking_status === "enrolled") {
      open_token_printer_modal();
      get_closure_details();
      if (receipt_input_ref.current) {
        receipt_input_ref.current.focus();
      }
      dispatch(dynamicClear("enroll_booking"));
    }
  }, [enrollment_booking_status, receipt_input_ref]);

  const get_enroll_booking_list = () => {
    let key = [{ key: "get_current_session_enrolment_list", loading: true }];
    let query = get_enroll_booking_list_query;
    let variables = null;
    dispatch(dynamicRequest(key, query, variables));
  };

  const open_token_printer_modal = () => {
    set_is_print_token_modal_visible(true);
  };
  const close_token_printer_modal = () => {
    set_is_print_token_modal_visible(false);
  };

  const open_session_modal = () => {
    set_session_modal_visible(true);
  };
  const close_session_modal = () => {
    set_session_modal_visible(false);
  };

  const check_enrollment_session_status = (mac_address) => {
    let keys = [{ key: "check_enrolment_session", loading: true }];
    let query = check_enrolment_session_query;
    let variables = {
      data: {
        system_reference_id: mac_address,
      },
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const set_counter_for_user = (mac_address) => {
    let keys = [{ key: "set_counter_user", loading: true }];
    let query = set_counter_user_mutation;
    let variables = {
      id: mac_address,
    };
    dispatch(dynamicRequest(keys, query, variables));
  };
  const create_enrollment_session_status = () => {
    let keys = [{ key: "create_enrolment_session", loading: true }];
    let query = create_enrolment_session_mutation;
    let variables = null;
    dispatch(dynamicRequest(keys, query, variables));
  };

  const close_enrollment_session_status = () => {
    let keys = [{ key: "close_enrolment_session", loading: true }];
    let query = close_enrolment_session_mutation;
    let variables = null;
    dispatch(dynamicRequest(keys, query, variables));
  };

  const get_current_counter_name = () => {
    let key = [{ key: "get_current_counter", loading: true }];
    let query = get_current_counter_query;
    let variables = {
      data: {
        system_reference_id: system_reference,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_closure_details = () => {
    let key = [{ key: "get_closure_summary", loading: true }];
    let query = get_closure_summary_query;
    dispatch(dynamicRequest(key, query));
  };

  const get_check_pos_connect = () => {
    let key = [{ key: "check_pos_connect", loading: true }];
    let query = query_check_has_pos_for_enroll;
    let variables = {
      data: {
        counter_id: current_counter_id,
      },
    };

    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <Col>
      <CustomPageTitle title="ENROLL" />

      {can_enroll && (
        <>
          <Card
            style={{
              height: "auto",
              overflowY: "scroll",
              margin: "10px",
              width: "97%",
              backgroundColor: "white",
              marginLeft: "20px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.4)",
            }}
          >
            <Title
              level={4}
              style={{
                textAlign: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              Your Counter is {current_counter_name}
            </Title>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Title level={4}>Enrolled By : {system_user}</Title>
              </div>
              <div>
                <EnrollButtons
                  open_token_printer_modal={open_token_printer_modal}
                  get_enroll_booking_list={get_enroll_booking_list}
                  receipt_input_ref={receipt_input_ref}
                  totalAmount={totalAmount}
                  system_user={system_user}
                  get_closure_details={get_closure_details}
                  open_session_modal={open_session_modal}
                  create_enrollment_session_status={
                    create_enrollment_session_status
                  }
                  close_enrollment_session_status={
                    close_enrollment_session_status
                  }
                  set_can_enroll={set_can_enroll}
                />
              </div>
            </div>
            <div style={{ marginTop: "2%" }}>
              <EnrollmentListTable set_total_amount={set_total_amount} />
            </div>
          </Card>
          <Card
            style={{
              overflowY: "scroll",
              margin: "10px",
              width: "97%",
              backgroundColor: "#f7f7f7",
              marginLeft: "20px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.4)",
            }}
          >
            <PaymentHistoryTable totalAmount={totalAmount} />
          </Card>
          <Card
            style={{
              height: "17%",
              margin: "20px",
              width: "97%",
              backgroundColor: "#f7f7f7",
              marginLeft: "20px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.4)",
            }}
          >
            <SearchEnrollDetailsForm
              get_enroll_booking_list={get_enroll_booking_list}
              receipt_input_ref={receipt_input_ref}
              open_token_printer_modal={open_token_printer_modal}
              get_closure_details={get_closure_details}
            />
          </Card>
        </>
      )}
      {!can_enroll && (
        <Card
          style={{
            // height: "70%",
            height: "auto",
            overflowY: "scroll",
            margin: "10px",
            width: "97%",
            backgroundColor: "white",
            marginLeft: "20px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.4)",
          }}
        >
          <Title
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginLeft: "35%",
            }}
          >
            Checking Counter Status...
          </Title>
        </Card>
      )}
      <Modal
        title="Create Session"
        open={session_modal_visible}
        style={{
          width: "100%",
          height: "50%",
        }}
        onCancel={close_session_modal}
        maskClosable={true}
        footer={null}
        closeIcon={<></>}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p>
            Currently, you don't have an active session. Create a new enrollment
            session
          </p>
          <div>
            <Button
              type="primary"
              onClick={() => {
                create_enrollment_session_status();
              }}
            >
              {"Start New Session"}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title={"Print Token"}
        open={is_print_token_modal_visible}
        onCancel={() => {
          // close_token_printer_modal();
        }}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            style={{
              color: "white",
              border: "transparent",
              backgroundColor: "#f96451",
            }}
            onClick={() => {
              close_token_printer_modal();
            }}
          >
            {"No"}
          </Button>,
          <Button
            loading={is_loading}
            style={{
              color: "white",
              border: "transparent",
              backgroundColor: "#168f92",
            }}
            htmlType="submit"
            onClick={() => {
              send_response_to_printer_service(
                enrolled_id,
                session_id,
                set_is_loading,
                TicketsPrinted,
                close_token_printer_modal,
                set_token_printer_response
              );
            }}
          >
            {"Yes"}
          </Button>,
          ,
        ]}
      >
        <Typography>
          {
            "Your information saved successfully..! Do you want to print the receipt ?"
          }
        </Typography>
      </Modal>
    </Col>
  );
};

export default Enroll;
