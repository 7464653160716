import { Button, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { useDynamicSelector } from "../../../services/redux";
import BankForm from "./bank_form";

const BankModal = (props) => {
  const {
    bank_modal_open,
    handle_bank_modal_close,
    bank_modal_title,
    selected_items,
  } = props;
  const { t } = useTranslation();

  const { loading: create_bank_loading } = useDynamicSelector("create_bank");
  const { loading: update_bank_loading } = useDynamicSelector("update_bank");
  return (
    <Modal
      open={bank_modal_open}
      destroyOnClose={true}
      width={"40%"}
      title={bank_modal_title == "add" ? t("add_bank") : t("edit_bank")}
      onCancel={handle_bank_modal_close}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      footer={[
        <Button onClick={handle_bank_modal_close}>{t("cancel")}</Button>,
        <Button
          style={{ backgroundColor: "#1e90ff", color: "white" }}
          htmlType="submit"
          form="add_bank_form"
          loading={create_bank_loading || update_bank_loading}
        >
          {t("save")}
        </Button>,
      ]}
    >
      <BankForm
        selected_items={selected_items}
        bank_modal_title={bank_modal_title}
      />
    </Modal>
  );
};
export default BankModal;
