import { Form, Input } from "antd";
import React from "react";

const CashBalance = () => {
  return (
    <Form.Item label="Balance" name="cash_balance">
      <Input disabled={true} style={{ marginLeft: "15px" }} />
    </Form.Item>
  );
};

export default CashBalance;
