import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { dynamicRequest } from "@services/redux";
import NotificationModal from "../notification_modal/notification_modal";
import DraggableTable from "./draggable_table";
import { useTranslation } from "react-i18next";
import { query_get_all_notification_board_list } from "../../../../services/redux/slices/graphql/graphql_notification";

const NotificationTable = (props) => {
  const { save_reorder } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [current_page_limit, set_current_page_limit] = useState(5);
  const [current_page, set_current_page] = useState(1);

  const fetch_data = (page_number, page_limit) => {
    let keys = [{ key: "get_notification_board_list", loading: true }];
    let query = query_get_all_notification_board_list;
    let variable = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(keys, query, variable));
    set_current_page(page_number);
    set_current_page_limit(page_limit);
  };

  return (
    <>
      <DraggableTable
        {...props}
        fetch_data={fetch_data}
        current_page={current_page}
        current_page_limit={current_page_limit}
      />

      <NotificationModal {...props} />
    </>
  );
};

export default NotificationTable;
