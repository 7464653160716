import { Form, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SlotTimeFormItem = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name="slot_time_in_minutes"
        label={t("slot_time")}
        rules={[
          {
            required: true,
            message: "Slot time is required",
          },
        ]}
        style={{ width: "400px" }}
      >
        <InputNumber placeholder={t("slot_time")} />
      </Form.Item>
    </div>
  );
};

export default SlotTimeFormItem;
