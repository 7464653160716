import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "antd";
import SearchReceiptNo from "./receipt_inputs/input_receipt_no";
import SearchReceiptDate from "./receipt_inputs/input_date";
import CustomButton from "../../common/custom_button";
import { query_get_booking_receipt } from "../../../../services/redux/slices/graphql/graphql_billing_history";
import { useTranslation } from "react-i18next";
import SearchReceiptMobileNumber from "./receipt_inputs/input_mobile_number";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "../../../../services/redux";

const ReceiptForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [receipt_reference_prefix, set_receipt_reference_prefix] = useState("");

  const { loading: loading_booking_detail } = useDynamicSelector(
    "get_booking_receipt"
  );

  const handle_submit = (values) => {
    get_one_receipt(values);
  };

  const get_one_receipt = (values) => {
    let keys = [{ key: "get_booking_receipt", loading: true }];
    let query = query_get_booking_receipt;
    delete values.date;
    let variables = {
      custom: {
        booking_user_mobile: String(values.booking_user_mobile),
        booking_reference: values.booking_reference,
      },
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  useEffect(() => {
    form.setFieldsValue({ booking_reference: receipt_reference_prefix });
  }, [receipt_reference_prefix]);

  return (
    <Form
      style={{ width: "100%" }}
      onFinish={handle_submit}
      form={form}
      id={"get_receipt_form"}
    >
      <Row gutter={16}>
        <Col xs={12} lg={10} md={20}>
          <SearchReceiptDate
            set_receipt_reference_prefix={set_receipt_reference_prefix}
          />
        </Col>
        <Col xs={12} lg={10} md={20} style={{ marginRight: "8px" }}>
          <SearchReceiptNo
            receipt_reference_prefix={receipt_reference_prefix}
            form={form}
          />
        </Col>
        <Col xs={12} lg={10} md={20}>
          <SearchReceiptMobileNumber />
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <CustomButton
            loading={loading_booking_detail}
            form_id={"get_receipt_form"}
            button_name={t("get_receipt")}
            is_submit={true}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default ReceiptForm;
