import React from "react";
import { Form, Switch } from "antd";

const InputSubDistrictIsActive = (props) => {
  return (
    <Form.Item
      name="is_active"
      label="Activate District"
      style={{ marginTop: "20px", marginRight: "10px", width: "500px" }}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
  );
};

export default InputSubDistrictIsActive;
