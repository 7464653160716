import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SystemUserUsernameInput = ({ modal_title }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t("user_name")}
      name="username"
      rules={
        modal_title == "add"
          ? [
              {
                required: true,
                message: "Please enter  username",
              },
            ]
          : []
      }
    >
      <Input placeholder={t("user_name")} disabled={modal_title !== "add"} />
    </Form.Item>
  );
};
export default SystemUserUsernameInput;
