import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const CounterGroupName = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t("name")}
      name="name"
      rules={[
        {
          required: true,
          message: "Please enter counter name",
        },
      ]}
      style={{ marginTop: "22px" }}
    >
      <Input placeholder="Name" />
    </Form.Item>
  );
};

export default CounterGroupName;
