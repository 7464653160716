import { gql } from "@apollo/client";

export const get_feedback_response_query = gql`
  query get_feedback_response_list($pagination: pagination_input) {
    get_feedback_response_list(pagination: $pagination) {
      items {
        datetime
        feedback {
          title
        }
        user {
          name
        }
        question_list {
          feedback_question {
            question
            type
          }
          text
          feedback_question_option {
            option
          }
        }
      }
      error {
        message
      }
    }
  }
`;
