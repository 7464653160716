import { Button, Form, Row } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../services/redux/selector";

const SpotBookingSubmit = ({ title }) => {
  const { loading: create_spot_booking_loading } = useDynamicSelector(
    "create_spot_booking"
  );
  return (
    <Row
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        marginRight: "20px",
      }}
    >
      <Button
        htmlType="submit"
        style={{
          borderRadius: "6px",
          backgroundColor: "#033E3E",
          color: "white",
          border: "transparent",
          width: "15%",
        }}
        loading={create_spot_booking_loading}
      >
        {title}
      </Button>
    </Row>
  );
};

export default SpotBookingSubmit;
