import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import CustomButton from "../components/common/custom_button";
import { useTranslation } from "react-i18next";
import { CustomSearchBox } from "../components/common/custom_search_box";
import { dynamicRequest } from "../../services/redux/slices/dynamic_request";
import { useDispatch } from "react-redux";
import { IoMdAddCircleOutline } from "react-icons/io";
import PosTable from "../components/pos/table/pos_table";
import PosModal from "../components/pos/modal/pos_modal";
import { query_get_all_pos } from "../../services/redux/slices/graphql/graphql_pos";
const { Title } = Typography;

const POS = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [header_type, set_header_type] = useState("");
  const [edit_model_visible, set_edit_modal_visible] = useState(false);

  useEffect(() => {
    get_all_pos();
  }, []);

  const get_all_pos = (search_string) => {
    let keys = [{ key: "get_pos_list", loading: true }];
    let query = query_get_all_pos;
    let variable = {
      filter: {
        search_string,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const add_district = () => {
    set_edit_modal_visible(true);
    set_header_type("add_pos");
  };

  const on_search = (search_string) => {
    get_all_pos(search_string);
  };

  return (
    <Col
      style={{
        margin: "8px auto",
        width: "98%",
        height: "100%",
      }}
    >
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Title level={4}>{t("pos")}</Title>
        {/* <CustomBreadCrumbs pages={"Home/POS"} /> */}
      </Row>

      <Col
        style={{
          height: "auto",
          margin: "auto",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Row
          style={{
            justifyContent: "space-between",
            margin: "5px",
            padding: "0px 12px",
            alignItems: "center",
          }}
        >
          <Title level={5}></Title>

          <Row style={{ justifyContent: "space-between" }}>
            <Row style={{ marginRight: "5px" }}>
              <CustomSearchBox
                placeholder={"Search POS"}
                on_search={on_search}
              />
            </Row>

            <CustomButton
              icon={<IoMdAddCircleOutline />}
              button_name={t("add_pos")}
              onclick_button={add_district}
              set_edit_modal_visible={set_edit_modal_visible}
              set_header_type={set_header_type}
            />
          </Row>
        </Row>

        <Col style={{ display: "flex", margin: "auto" }}>
          <PosTable
            get_all={get_all_pos}
            set_header_type={set_header_type}
            set_edit_modal_visible={set_edit_modal_visible}
          />
        </Col>
      </Col>
      <PosModal
        get_all={get_all_pos}
        header_type={header_type}
        set_edit_modal_visible={set_edit_modal_visible}
        edit_model_visible={edit_model_visible}
      />
    </Col>
  );
};

export default POS;
