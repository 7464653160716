import { Form, InputNumber } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";

const VipBookingCount = ({set_ticket_count,ticket_count,set_total_ticket_amount}) => {
  const { t } = useTranslation();
  const { items: season_date_slot_list_items, loading: loading } =
  useDynamicSelector("get_season_date_slot_list");
  {/* const[ticket_count,set_ticket_count]=useState();
  const[total_ticket_amount,set_total_ticket_amount]=useState(0) */}
  const vip_ticket_price = season_date_slot_list_items?.[0]?.season?.vip_ticket_price;

  const on_change=(val)=>{
    set_ticket_count(val)
  }
useEffect(()=>{
  set_total_ticket_amount(ticket_count*vip_ticket_price)
},[ticket_count])


  return (
    <div style={{width:"49%"}}>
    <Form.Item
      label={t("count")}
      name="count"
      rules={[
        {
          required: true,
          message: "Please enter the count",
        },
      ]}
    
    >
      <InputNumber
        style={{
          width: "100%",
        }}
        onChange={on_change}
      />
    </Form.Item>
    </div>
  );
};

export default VipBookingCount;
