import { Form, Input } from "antd";
import React from "react";

const InputSubDistrictAdminEmail = () => {
  const validateEmail = (rule, value, callback) => {
    if (value && !/^\S+@\S+\.\S+$/.test(value)) {
      callback("Please enter a valid email address");
    } else {
      callback();
    }
  };

  return (
    <>
      <Form.Item
        name={["district_admin", "email"]}
        label="Email"
        rules={[
          {
            required: true,
            message: "Please enter email",
          },
          {
            validator: validateEmail,
          },
        ]}
      >
        <Input placeholder="Email" allowClear={true} />
      </Form.Item>
    </>
  );
};

export default InputSubDistrictAdminEmail;
