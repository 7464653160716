import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "../../../../services/redux";
import { capitalize } from "lodash";
import { Row, Table, Typography } from "antd";
import ActionButton from "../../common/custom_action_button";
import CustomSMSModal from "../../common/custom_sms_modal";
import moment from "moment";

const { Title } = Typography;

const EnrollActionList = (props) => {
  const { loading_booking_detail, selector_name, receipt_no } = props;

  const dispatch = useDispatch();

  const [table_data, set_tableData] = useState([]);
  const [sms_modal_visible, set_sms_modal_visible] = useState(false);

  const {
    id,
    booked_on,
    mandram,
    booking_status,
    booking_reference,
    count,
    booking_user,
    date_slot,
    enrolment_list,
  } = useDynamicSelector("get_enroll_action");
  //   const { booking_reference } = useDynamicSelector("get_enroll_action");
  console.log("sequence_count", enrolment_list);

  const {
    status: message_status,
    loading: message_loading,
    error: sms_error,
  } = useDynamicSelector("send_sms_to_number");

  const handle_print = (event) => {
    event.stopPropagation();
    const url = `/print-receipt/${booking_reference}/${booking_user?.mobile}`;
    const new_tab = window.open(url, "_blank", "width=850, height=900");
    if (new_tab) {
      new_tab.focus();
    }
  };

  const send_sms_api = (mobile, booking_id) => {
    let key = [{ key: "send_sms_to_number", loading: true }];
    let query = send_sms_to_mobile;
    let variables = {
      data: {
        booking_id,
        mobile,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (message_status === "success") {
      toast.success("Message sent!");
      set_sms_modal_visible(false);
      dispatch(dynamicClear("send_sms_to_number"));
    } else if (sms_error?.message) {
      toast.error("Failed to send message");
      dispatch(dynamicClear("send_sms_to_number"));
    }
  }, [message_status, sms_error]);

  const api_data = [
    {
      key: "1",
      header: "Booking Number",
      value: booking_reference,
    },
    {
      key: "2",
      header: "Receipt Number",
      value: `${receipt_no}`,
    },
    {
      key: "3",
      header: "Booked Date",
      value: moment(booked_on).format("DD-MMM-YYYY").toUpperCase(),
    },
    {
      key: "4",
      header: "Abishekam Date",
      value: moment(date_slot?.date).format("DD-MMM-YYYY").toUpperCase(),
    },
    {
      key: "4",
      header: "District",
      value: mandram?.district?.name,
    },
    {
      key: "5",
      header: "VSG",
      value: `${mandram?.name} / ${mandram?.code}`,
    },
    {
      key: "6",
      header: "Group Leader Name",
      value: booking_user?.name,
    },
    {
      key: "7",
      header: "Group Leader Mobile",
      value: booking_user?.mobile,
    },
    {
      key: "8",
      header: "Irumudi Count",
      value: count,
    },
    {
      key: "9",
      header: "Status",
      value: <b>{capitalize(booking_status)}</b>,
    },
  ];

  const handle_share = () => {
    set_sms_modal_visible(true);
  };

  useEffect(() => {
    set_tableData(api_data);
  }, []);

  const columns = [
    {
      dataIndex: "header",
      key: "header",
      title: "Header",
      width: "514px",
    },
    {
      dataIndex: "value",
      key: "value",
      title: "Value",
      width: "514px",
    },
  ];

  return (
    <>
      <Row
        style={{
          backgroundColor: "#eeeaea",
          width: "100%",
          // margin: "8px 0px 5px 0px",
          borderRadius: "10px",
          flexDirection: "column",
        }}
      >
        <Title level={5} style={{ margin: "15px 10px 10px 10px" }}>
          {"Enroll Action Details :"}
        </Title>
        <Table
          style={{
            marginTop: "10px",
            marginBottom: "15px",
            margin: "5px",
            marginRight: "20px",
          }}
          dataSource={table_data}
          columns={columns}
          bordered
          pagination={false}
          loading={loading_booking_detail}
          showHeader={false}
          width="100%"
        />
        <Row
          style={{
            width: "100%",
            alignContent: "flex-end",
            justifyContent: "flex-end",
            alignSelf: "flex-end",
            margin: "8px",
          }}
        >
          <div style={{ marginRight: "15px" }}>
            <ActionButton
              name={"Print"}
              on_click={(e) => handle_print(e)}
              bg_color={"#179093"}
            />
          </div>
          {/* <ActionButton
            name={"Share"}
            on_click={(e) => handle_share(e)}
            bg_color={"#179093"}
          /> */}
        </Row>
      </Row>

      <CustomSMSModal
        loading={message_loading}
        sms_api={send_sms_api}
        modal_visible={sms_modal_visible}
        set_modal_visible={set_sms_modal_visible}
        sms_booking_id={id}
      />
    </>
  );
};

export default EnrollActionList;
