import { Form, Select } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../../services/redux";
import moment from "moment";

const OmSakthiReportFromDate = (props) => {
  const { ordered_date_slots, set_from_date } = props;

  const on_change = (value, option) => {
    const selected_date = {
      id: value,
      selected_from_date: option?.children,
    };
    set_from_date(selected_date);
  };

  return (
    <Form.Item
      name="from_date"
      label="From Date"
      rules={[{ required: true, message: "Please Select from date" }]}
    >
      <Select placeholder="Select From Date" allowClear onChange={on_change}>
        {ordered_date_slots?.map((date_option, index) => (
          <Select.Option key={date_option?.id} value={date_option?.id}>
            {date_option?.date_string}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default OmSakthiReportFromDate;
