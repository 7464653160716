import { Col, Collapse, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubDistrictWiseCollapse from "./sub_district_wise_history";
import {
  dynamicRequest,
  dynamicSet,
  query_get_all_mandrams,
  query_sub_district_wise_summaries,
  useDynamicSelector,
} from "../../../../services/redux";
import { sub_district_list_details_query } from "../../../../services/redux/slices/graphql/graphql_district";
const BookingHistoryHeader = React.lazy(() =>
  import("./booking_history_header")
);

const DistrictWiseCollapse = (props) => {
  const { district_wise_history, item } = props;
  const dispatch = useDispatch();
  const { key: active_district_key } = useDynamicSelector(
    "district_wise_active_key"
  );
  const { mandram_id } = useDynamicSelector("mandram_id");

  const { loading: get_sub_district_wise_history_loading } = useDynamicSelector(
    "get_sub_district_list"
  );

  const get_sub_district_wise_summary = (district_id) => {
    let key = [{ key: "get_sub_district_wise_summary", loading: true }];
    let query = query_sub_district_wise_summaries;
    let variables = {
      filter: {
        date_slot_id: item?.date_slot_id,
        district_id: district_id,
        mandram_id_list: mandram_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_sub_district_list = (district_id) => {
    let key = [{ key: "sub_district_list_history", loading: true }];
    let query = sub_district_list_details_query;
    let variables = {
      filter: {
        district_id: district_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_change_district_wise = (key) => {
    console.log("handle_change_district_wise", key, item);
    if (key) {
      get_sub_district_wise_summary(key);
      get_sub_district_list(key);
    }
    dispatch(dynamicSet("district_wise_active_key", { key: key }));
    dispatch(dynamicSet("sub_district_wise_active_key", { key: [] }));
    dispatch(dynamicSet("mandram_wise_active_key", { key: [] }));
  };

  return (
    <div>
      <Collapse
        accordion={true}
        onChange={handle_change_district_wise}
        activeKey={active_district_key || []}
      >
        {get_sub_district_wise_history_loading && (
          <div className="booking_history_loading">
            <Spin />
          </div>
        )}
        {district_wise_history?.districts &&
          Object?.keys(district_wise_history?.districts)?.map(
            (district_wise) => {
              return (
                <Collapse.Panel
                  header={
                    <BookingHistoryHeader
                      item={district_wise_history?.districts?.[district_wise]}
                    />
                  }
                  key={district_wise}
                  style={{ backgroundColor: "rgb(35, 84, 91)" }}
                >
                  <SubDistrictWiseCollapse date_slot_item={item} />
                </Collapse.Panel>
              );
            }
          )}
      </Collapse>
    </div>
  );
};

export default DistrictWiseCollapse;
