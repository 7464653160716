import { Form, Input } from "antd";
import GroupName from "./group_form_items/group_name";
import {
  dynamicRequest,
  mutation_create_group,
  mutation_update_group,
} from "../../../services/redux/slices";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import GroupTamilName from "./group_form_items/group_tamil_name";
const GroupForm = (props) => {
  const { group_modal_title, prefill_values } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handle_group_submit = (values) => {
    if (group_modal_title === "add") {
      create_group(values);
    } else {
      update_group(values);
    }
  };

  const create_group = (values) => {
    let key = [{ key: "create_district_group", loading: true }];
    let query = mutation_create_group;

    let variables = {
      data: {
        name: values?.name,
        tamil_name: values?.tamil_name,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const update_group = (values) => {
    let key = [{ key: "update_district_group", loading: true }];
    let query = mutation_update_group;
    let variables = {
      id: prefill_values.id,
      data: {
        name: values?.name,
        tamil_name: values?.tamil_name,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (group_modal_title === "edit") {
      form.setFieldsValue({ ...prefill_values });
    } else {
      form.setFieldsValue({ name: "", tamil_name: "" });
    }
  }, [prefill_values]);

  return (
    <Form
      id="add_group_form"
      form={form}
      labelCol={{
        span: 7,
      }}
      onFinish={handle_group_submit}
    >
      <GroupName />
      <GroupTamilName />
    </Form>
  );
};
export default GroupForm;
