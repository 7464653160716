import React from "react";
import { Card, Typography } from "antd";
import { count_format, formatStatus } from "../../../helpers/constants";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const VipBookingMobileHistoryCard = ({ booking }) => {
  const { t } = useTranslation();

  return (
    <Card className="vip-booking-history-card">
      <div className="vip-booking-history-item">
        <Text className="vip-booking-history-title">{"Booking No: "} </Text>
        <Text className="vip-booking-history-text">
          {booking?.booking_reference || "DEC2024001"}
        </Text>
      </div>

      <div className="vip-booking-history-row">
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{"Date: "}</Text>
          <Text className="vip-booking-history-text">
            {booking?.date || "12-11-2024"}
          </Text>
        </div>
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{"booked: "}</Text>
          <Text className="vip-booking-history-text">
            {count_format(booking?.confirmed_count)}
          </Text>
        </div>
      </div>

      <div className="vip-booking-history-row">
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{"Leader Name: "}</Text>
          <Text className="vip-booking-history-text">
            {booking?.booking_user?.name || "Tamil Mani"}
          </Text>
        </div>
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{"Leader No: "}</Text>
          <Text className="vip-booking-history-text">
            {booking?.booking_user?.mobile || "9003581906"}
          </Text>
        </div>
      </div>

      <div className="vip-booking-history-row">
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{"Booked By: "}</Text>
          <Text className="vip-booking-history-text">
            {booking?.booked_by?.name || "User"}
          </Text>
        </div>
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{"Payment Mode: "}</Text>
          <Text className="vip-booking-history-text">
            {startCase(booking?.payment?.payment_mode) || "Online"}
          </Text>
        </div>
      </div>

      <div className="vip-booking-history-row">
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">
            {"payment Status:"}
          </Text>
          <Text className="vip-booking-history-text">
            {booking?.enrolment_list && booking.enrolment_list.length > 0
              ? formatStatus(booking.enrolment_list[0].status)
              : "-"}
          </Text>
        </div>
        <div className="vip-booking-history-item-half">
          <Text className="vip-booking-history-title">{t("status")}</Text>
          <Text className="vip-booking-history-text">
            {formatStatus(booking?.booking_status) || "-"}
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default VipBookingMobileHistoryCard;
