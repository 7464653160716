import React from "react";
import { Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const QuestionTypeFormItem = () => {
  const { t } = useTranslation();

  const custom_filter_option = (input, option) => {
    const input_value = input.toLowerCase();
    const option_label = option.props.children.toLowerCase();
    return option_label.indexOf(input_value) === 0;
  };
  return (
    <div>
      <Form.Item
        name="type"
        label={t("question_type")}
        rules={[
          {
            required: true,
            message: "Question type is required",
          },
        ]}
      >
        <Select
          placeholder="Select Question Type"
          showSearch
          filterOption={custom_filter_option}
        >
          <Option value="text">Text</Option>
          <Option value="select">Select</Option>
        </Select>
      </Form.Item>
    </div>
  );
};

export default QuestionTypeFormItem;
