import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { get_date_slots } from "../../../../services/redux/slices/graphql/graphql_system_reports";
import { useDispatch } from "react-redux";
import _orderBy from "lodash/orderBy";
import {
  dynamicClear,
  dynamicRequest,
  get_date_slots_counter_user_wise,
  useDynamicSelector,
} from "../../../../services/redux";

const CounterUserWiseDate = (props) => {
  const { set_report_date, report_date } = props;
  const dispatch = useDispatch();
  const { items: date_slots } = useDynamicSelector(
    "get_date_slots_counter_user_wise"
  );
  console.log("dsdsdsdsd");
  const custom_filter_option = (input, option) => {
    const inputValue = input.toLowerCase();
    const optionLabel = option.props.children.toLowerCase();
    return optionLabel.indexOf(inputValue) === 0;
  };

  useEffect(() => {
    get_date_slot_list();
  }, []);

  console.log("date_slots", date_slots);
  const get_date_slot_list = () => {
    let key = [{ key: "get_date_slots_counter_user_wise", loading: true }];
    let query = get_date_slots_counter_user_wise;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const on_change = (value, option) => {
    dispatch(dynamicClear("get_counter_user_wise_summary"));
    const selectedDate = {
      id: value,
      date: option?.children,
    };
    set_report_date(selectedDate);
  };

  return (
    <div>
      <Form.Item
        name="date_slot_id_list"
        label="Date"
        rules={[
          {
            required: true,
            message: "Please Select Date",
          },
        ]}
      >
        <Select
          placeholder="Select Date"
          onChange={on_change}
          allowClear
          showSearch
          filterOption={custom_filter_option}
        >
          {date_slots?.map((option, index) => (
            <Select.Option key={option.id} value={option.id}>
              {option.date_string}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};
export default CounterUserWiseDate;
