import { Form, Input } from "antd";
import React from "react";

const ContactNumber = () => {
  return (
    <Form.Item
      name={["mandram_admin", "mobile"]}
      label="Contact Number"
      rules={[
        {
          required: true,
          message: "Please user Contact",
        },
      ]}
    >
      <Input type="number" />
    </Form.Item>
  );
};

export default ContactNumber;
