import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";

const SearchReceiptNo = ({ form }) => {
  const [value, set_value] = useState("");

  const handle_change_receipt_number = (e) => {
    const uppercase_value = e.target.value.toUpperCase();
    set_value(uppercase_value);
  };

  useEffect(() => {
    form.setFieldValue("booking_reference", value);
  }, [value]);

  return (
    <>
      <Form.Item
        name="booking_reference"
        label="Booking No"
        rules={[
          {
            required: true,
            message: "Please enter booking no",
          },
        ]}
      >
        <Input
          value={value}
          placeholder="Booking No"
          allowClear={true}
          onChange={handle_change_receipt_number}
        />
      </Form.Item>
    </>
  );
};

export default SearchReceiptNo;
