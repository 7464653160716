import React from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { startCase } from "lodash";
import { retrieveItem } from "../../../../helpers/functions";

const IdProof = ({ set_selected_id_proof }) => {
  const { t } = useTranslation();
  const role = retrieveItem("role");

  const options = [
    { value: "Aadhaar Card", label: t("aadhaar_card") },
    { value: "Pancard", label: t("pancard") },
    { value: "Driving License", label: t("driving_license") },
    { value: "Bank Passbook", label: t("bank_passbook") },
    { value: "Passport", label: t("passport") },
    { value: "Ration Card", label: t("ration_card") },
    { value: "Student ID", label: t("student_id") },
    { value: "Government ID", label: t("gov_id") },
  ];

  const on_change = (val) => {
    set_selected_id_proof(val);
  };

  return (
    <Form.Item
      label={t("id_proof")}
      name="id_proof"
      // rules={[
      //   {
      //     required: true,
      //     message: t("Please enter ID proof!"),
      //   },
      // ]}
    >
      <Select
        style={{
          width: "100%",
        }}
        placeholder={t("Select")}
        onChange={on_change}
        allowClear
      >
        {options.map((item, index) => (
          <Select.Option key={index} value={item.value}>
            {startCase(item.label)}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default IdProof;
