import { Card, Typography } from "antd";
import React from "react";
import DdPaymentList from "./dd_payment_list";
import { useDynamicSelector } from "../../../../../services/redux";

const DdPaymentCard = () => {
  const {
    items: om_shakthi_payment_items,
    error: om_sakthi_payment_report_error,
  } = useDynamicSelector("get_om_sakthi_payment");

  const ddPaymentItems = om_shakthi_payment_items?.filter(
    (item) => item?.payment_mode === "dd"
  );
  return (
    ddPaymentItems?.length > 0 && (
      <>
        <Typography style={{ fontWeight: "bold" }}>DD</Typography>
        <div>
          <DdPaymentList />
        </div>
      </>
    )
  );
};

export default DdPaymentCard;
