import { Form, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CounterActiveSwitch = (props) => {
  const { is_active } = props;
  const { t } = useTranslation();

  return (
    <Form.Item label={t("is_active")} name="is_active" valuePropName="checked">
      <Switch defaultChecked={false} />
    </Form.Item>
  );
};
export default CounterActiveSwitch;
