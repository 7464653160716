import { Card, Col, Form, Row } from "antd";
import React from "react";
import SakthiPeedamFromDate from "./sakthi_peedam_report_from_date";
import SakthiPeedamToDate from "./sakthi_peedam_report_to_date";
import SakthiPeedamReportMandram from "./sakthi_peedam_report_mandram";
import CustomButton from "../../../common/custom_button";
import { useTranslation } from "react-i18next";
import { dynamicClear } from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const SakthiPeedamReportForm = (props) => {
  const { get_sakthi_peedam_report, set_from_date, set_to_date } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handle_finish = (values) => {
    get_sakthi_peedam_report(values);
  };

  const on_change_values = () => {
    dispatch(dynamicClear("get_sakthi_peedam"));
  };

  return (
    <Form
      id="search"
      onFinish={handle_finish}
      onValuesChange={on_change_values}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 15,
      }}
      form={form}
    >
      <Card
        style={{
          width: "96%",
          borderRadius: "5px",
          backgroundColor: "#e7e7e7",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row gutter={24} style={{ height: "30px" }}>
          <Col span={11}>
            <SakthiPeedamFromDate set_from_date={set_from_date} />
          </Col>
          <Col span={10}>
            <SakthiPeedamToDate set_to_date={set_to_date} />
          </Col>
          <Col span={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Form.Item>
                <CustomButton
                  button_name={t("get")}
                  bg_color={"#014477"}
                  rounded={true}
                  is_submit={true}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={24} style={{ height: "30px", marginTop: "20px" }}>
          <Col span={11}>
            <SakthiPeedamReportMandram />
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default SakthiPeedamReportForm;
