import { Button, Modal, Typography } from "antd";
import React from "react";
import EnrollConfirmationDetailsCard from "./enroll_confirmation_details_card";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../services/redux";

const EnrollDetailsCard = (props) => {
  const {
    open_confirmation,
    set_open_confirmation,
    no_of_devotees,
    mandram_code,
    mandram_name,
    enroll_booking,
  } = props;

  const { loading: enroll_loading } = useDynamicSelector("enroll_booking");

  const { t } = useTranslation();

  return (
    <Modal
      title="Confirmation"
      open={open_confirmation}
      onCancel={() => set_open_confirmation(false)}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      footer={[
        <Button
          style={{
            backgroundColor: "#f96451",
            color: "white",
            border: "transparent",
          }}
          onClick={() => set_open_confirmation(false)}
        >
          {t("no")}
        </Button>,
        <Button
          style={{
            color: "white",
            border: "transparent",
            backgroundColor: "#168f92",
          }}
          onClick={() => enroll_booking()}
          loading={enroll_loading}
        >
          {t("yes")}
        </Button>,
      ]}
    >
      <Typography style={{ marginBottom: "15px" }}>
        Do you want to enroll for the following details ?
      </Typography>
      <EnrollConfirmationDetailsCard title={"Code"} value={mandram_code} />
      <EnrollConfirmationDetailsCard title={"Mandram"} value={mandram_name} />
      <EnrollConfirmationDetailsCard
        title={"Devotees"}
        value={no_of_devotees}
      />
      {/* <EnrollConfirmationDetailsCard title={"Date"} value={formatted_date} /> */}
    </Modal>
  );
};

export default EnrollDetailsCard;
