import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { dynamicRequest, useDynamicSelector } from "../../../../services/redux";
import { useTranslation } from "react-i18next";
import { query_get_all_counter_group } from "../../../../services/redux/slices/graphql/graphql_counter_group";
import { useDispatch } from "react-redux";

const CounterGroupName = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { items: counter_group_list } = useDynamicSelector(
    "get_counter_group_list"
  );

  const custom_filter_option = (input, option) => {
    const inputValue = input.toLowerCase();
    const optionLabel = option.props.children.toLowerCase();
    return optionLabel.indexOf(inputValue) === 0;
  };

  const get_all_counter_group_list = () => {
    let key = [{ key: "get_counter_group_list", loading: true }];
    let query = query_get_all_counter_group;
    dispatch(dynamicRequest(key, query));
  };
  useEffect(() => {
    get_all_counter_group_list();
  }, []);

  return (
    <Form.Item
      name="counter_group_id"
      label={t("counter_group")}
      rules={[
        {
          required: true,
          message: "Please enter counter group",
        },
      ]}
    >
      <Select
        placeholder="Counter Group"
        allowClear
        showSearch
        filterOption={custom_filter_option}
      >
        {counter_group_list?.map((option, index) => (
          <Select.Option key={option.name} value={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default CounterGroupName;
