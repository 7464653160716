import { Button, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import SystemUserForm from "../masm_user_form/masm_user_form";
import { IoMdClose } from "react-icons/io";
import { useDynamicSelector } from "../../../../services/redux";

const SystemUserModal = (props) => {
  const {
    modal_visible,
    set_modal_visible,
    modal_title,
    selected_items,
    role_type,
  } = props;
  const { t } = useTranslation();
  const { loading: create_loading } = useDynamicSelector("create_masm_user");
  const { loading: update_loading } = useDynamicSelector("update_masm_user");
  console.log("selected_items", selected_items);
  return (
    <Modal
      title={modal_title == "add" ? `Add ${role_type}` : `Edit ${role_type}`}
      open={modal_visible}
      destroyOnClose={true}
      onCancel={() => set_modal_visible(false)}
      width={"70%"}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      footer={[
        <Button onClick={() => set_modal_visible(false)}>{t("cancel")}</Button>,
        <Button
          style={{ backgroundColor: "#1e90ff", color: "white" }}
          htmlType="submit"
          form="add_system_user_form"
          loading={create_loading || update_loading}
        >
          {t("save")}
        </Button>,
      ]}
    >
      <SystemUserForm
        selected_items={selected_items}
        modal_title={modal_title}
        role_type={role_type}
      />
    </Modal>
  );
};
export default SystemUserModal;
