import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FeedBackManageTable from "../components/manage_feedback/feedback_manage/feedback_manage_table";
import FeedbackManageEditModal from "../components/manage_feedback/feedback_manage/feedback_manage_edit_modal/feedback_modal_edit_modal";
import FeedbackManageDeleteModal from "../components/manage_feedback/feedback_manage/feedback_manage_delete_modal/feedback_manage_delete_modal";
import AddQuestionComponent from "../components/manage_feedback/feedback_manage/feedback_manage_add_question_table/feedback_manage_add_question_main_component";
import FeedBackExpandableTableComponent from "../components/manage_feedback/feedback_manage/feedback_manage_expandable_table_component/feedback_manage_expandable.table_component";
import CustomPageTitle from "../components/common/custom_page_title";
import CustomButton from "../components/common/custom_button";
import {
  delete_feedback_mutation,
  dynamicClear,
  dynamicRequest,
  get_feedback,
  get_feedback_list,
  update_feedback_action_mutation,
  update_feedback_mutation,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FeedbackManageAddQuestion from "../components/manage_feedback/feedback_manage/feedback_manage_add_question_table/feedback_manage_add_question_main_component";
import { navigate } from "../../helpers/functions";
import { ROUTES } from "../routes/my_routes";
import { useParams } from "react-router-dom";
import FeedbackQuestionActiveModal from "../components/manage_feedback/feedback_manage/feedback_manage_add_question_table/feedback_question_active_modal/feedback_question_active_modal";

const FeedBackManage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [is_edit_model_visible, set_edit_modal_visible] = useState(false);
  const [header_type, set_header_type] = useState("");
  const [edit_values, set_edit_values] = useState({});
  const [delete_id, set_delete_id] = useState("");
  const [is_active_record, set_is_active_record] = useState("");
  const [is_active_modal, set_is_active_modal] = useState(false);
  const [is_delete_modal_visible, set_is_delete_modal_visible] =
    useState(false);
  const [show_add_question, set_show_add_Question] = useState(false);

  const { items: feedback_list_items, loading } =
    useDynamicSelector("get_feedback_list");

  const {} = useDynamicSelector("get_feedback");

  const { status: update_feedback_status, error: update_feedback_error } =
    useDynamicSelector("update_feedback_action");
  const { status: delete_feedback_status, error: delete_feedback_error } =
    useDynamicSelector("delete_feedback");

  useEffect(() => {
    get_feedback_list_query();
    // get_feedback_details();
  }, []);

  useEffect(() => {
    if (delete_feedback_status === "success") {
      toast.success("Feedback deleted");
      set_is_delete_modal_visible(false);
      get_feedback_list_query();
      dispatch(dynamicClear("delete_feedback"));
    } else if (delete_feedback_error?.message) {
      toast.error(delete_feedback_error.message);
      get_feedback_list_query();
      dispatch(dynamicClear("delete_feedback"));
    }
    if (update_feedback_status == "success") {
      toast.success("Status changed");
      get_feedback_list_query_without_loading();
      dispatch(dynamicClear("update_feedback_action"));
    } else if (update_feedback_error?.message) {
      toast.error(update_feedback_error.message);
      get_feedback_list_query_without_loading();
      dispatch(dynamicClear("update_feedback_action"));
    }
  });
  const get_feedback_list_query = () => {
    let key = [{ key: "get_feedback_list", loading: true }];
    let query = get_feedback_list;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const get_feedback_list_query_without_loading = () => {
    let key = [{ key: "get_feedback_list", loading: false }];
    let query = get_feedback_list;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_feedback_details = (value) => {
    let key = [{ key: "get_feedback", loading: true }];
    let query = get_feedback;
    let variables = {
      id: value.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const update_feedback = (edit_id, values, is_active) => {
    let key = [{ key: "update_feedback_action", loading: true }];
    let query = update_feedback_action_mutation;
    let variable = {
      id: is_active_record.id,
      data: {
        title: is_active_record.title,
        is_active: !is_active_record.is_active,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const delete_feedback = (id) => {
    let key = [{ key: "delete_feedback", loading: true }];
    let query = delete_feedback_mutation;
    let variable = {
      id: delete_id?.id,
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const handle_manage = (value) => {
    set_show_add_Question(false);
    navigate(`${ROUTES.FEEDBACK_QUESTION}/${value.id}/${value.title}`);
    get_feedback_details(value);
  };

  const handle_active = (record) => {
    set_is_active_record(record);
    set_is_active_modal(true);
  };

  const handle_add = () => {
    set_edit_values({});
    set_header_type("add");
    set_edit_modal_visible(true);
  };
  const handle_edit = (record) => {
    set_header_type("edit");
    set_edit_values(record);
    set_edit_modal_visible(true);
  };

  const show_delete_model = (record) => {
    set_delete_id(record);
    set_is_delete_modal_visible(true);
  };

  const handle_delete_cancel = () => {
    set_is_delete_modal_visible(false);
  };

  const handle_delete_confirm = () => {
    delete_feedback(delete_id);
    set_is_delete_modal_visible(false);
  };

  const cancel_active_modal = () => {
    set_is_active_modal(false);
  };
  const handle_active_confirm = (values) => {
    update_feedback(values);
    set_is_active_modal(false);
  };

  return (
    <div>
      <CustomPageTitle title={t("manage_feedback_title")} />

      {show_add_question ? (
        <FeedbackManageAddQuestion is_active={is_active} />
      ) : (
        <FeedBackManageTable
          handle_add={handle_add}
          handle_edit={handle_edit}
          show_delete_model={show_delete_model}
          handle_manage={handle_manage}
          feedback_list_items={feedback_list_items}
          handle_active={handle_active}
          loading={loading}
        />
      )}
      <FeedbackQuestionActiveModal
        cancel_active_modal={cancel_active_modal}
        handle_active_confirm={handle_active_confirm}
        is_active_modal={is_active_modal}
      />

      <FeedbackManageEditModal
        header_type={header_type}
        edit_values={edit_values}
        is_edit_model_visible={is_edit_model_visible}
        set_edit_modal_visible={set_edit_modal_visible}
        get_feedback_list_query={get_feedback_list_query}
        is_active_record={is_active_record}
      />
      <FeedbackManageDeleteModal
        is_delete_modal_visible={is_delete_modal_visible}
        handle_delete_confirm={handle_delete_confirm}
        handle_delete_cancel={handle_delete_cancel}
        show_delete_model={show_delete_model}
      />
    </div>
  );
};

export default FeedBackManage;
