import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";
import OhmSakthiReportForm from "../components/system_report/om_sakthi_report/om_sakthi_report_form/om_sakthi_main_form";
import OmSakthiReportDetails from "../components/system_report/om_sakthi_report/om_sakthi_report_details/om_sakthi_report_details";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import {
  om_sakthi_report_date_query,
  om_sakthi_report_query,
  query_om_sakthi_payment,
} from "../../services/redux/slices/graphql/graphql_om_sakthi_report";
import { toast } from "react-toastify";
import OmshakthiReportModal from "../components/system_report/om_sakthi_report/modal/om_shakthi_report_modal";
import LoaderSpinner from "../components/common/custom_spinner";

const OmSakthiReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    items: om_shakthi_items,
    error: om_sakthi_report_error,
    loading: om_shakthi_items_loading,
  } = useDynamicSelector("get_om_sakthi_summary");

  const {
    items: om_shakthi_payment_items,
    error: om_sakthi_payment_report_error,
    loading: om_shakthi_payment_items_loading,
  } = useDynamicSelector("get_om_sakthi_payment");

  console.log("om_shakthi_items", om_shakthi_payment_items);

  const [from_date, set_from_date] = useState("");
  const [to_date, set_to_date] = useState("");
  const [om_sakthi_report_open, set_om_sakthi_report] = useState(false);
  const [selected_dates, set_selected_dates] = useState({});
  const [visible, set_visible] = useState(false);

  const get_dates_for_om_sakthi_report = () => {
    let key = [{ key: "om_sakthi_report_date", loading: true }];
    let query = om_sakthi_report_date_query;
    dispatch(dynamicRequest(key, query));
  };

  const get_om_sakthi_report = (values) => {
    console.log("values", values);
    let key = [{ key: "get_om_sakthi_summary", loading: true }]; // 1,2
    let query = om_sakthi_report_query;
    let variables = {
      filter: {
        from_date_id: values.from_date,
        to_date_id: values.to_date,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_om_sakthi_payment_report = (values) => {
    console.log("values", values);
    let key = [{ key: "get_om_sakthi_payment", loading: true }];
    let query = query_om_sakthi_payment;
    let variables = {
      filter: {
        from_date_id: values.from_date,
        to_date_id: values.to_date,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_dates_for_om_sakthi_report();
  }, []);

  useEffect(() => {
    if (om_sakthi_report_error) {
      toast.error(om_sakthi_report_error?.message);
      set_om_sakthi_report(false);
      set_from_date("");
      set_to_date("");
      dispatch(dynamicClear("get_om_sakthi_summary"));
    }
  }, [om_sakthi_report_error]);

  useEffect(() => {
    dispatch(dynamicClear("get_om_sakthi_payment"));
    dispatch(dynamicClear("get_om_sakthi_summary"));
  }, []);

  return (
    <>
      <Col>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Row style={{ marginLeft: "10px" }}>
            <CustomPageTitle title={t("om_sakthi_report")} />
          </Row>
          <Row style={{ margin: "8px", marginRight: "23px" }}>
            {/* <CustomBreadCrumbs pages={"Home/Om Sakthi Report"} /> */}
          </Row>
        </Row>
        <div style={{ marginTop: "20px" }}>
          <OhmSakthiReportForm
            get_om_sakthi_report={get_om_sakthi_report}
            get_om_sakthi_payment_report={get_om_sakthi_payment_report}
            set_from_date={set_from_date}
            set_to_date={set_to_date}
            set_om_sakthi_report={set_om_sakthi_report}
            set_selected_dates={set_selected_dates}
          />
        </div>
        {(om_shakthi_items_loading || om_shakthi_payment_items_loading) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <LoaderSpinner />
          </div>
        )}
        <div style={{ marginTop: "20px" }}>
          <OmSakthiReportDetails
            from_date={from_date}
            set_visible={set_visible}
            to_date={to_date}
            om_sakthi_report_open={om_sakthi_report_open}
            set_from_date={set_from_date}
          />
        </div>
      </Col>
      <OmshakthiReportModal
        visible={visible}
        set_visible={set_visible}
        selected_dates={selected_dates}
        to_date={to_date}
        from_date={from_date}
      />
    </>
  );
};

export default OmSakthiReport;
