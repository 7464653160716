import { Col, Row, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CustomButton from "../components/common/custom_button";
import { useTranslation } from "react-i18next";
import { CustomSearchBox } from "../components/common/custom_search_box";
import { dynamicRequest } from "../../services/redux/slices/dynamic_request";
import { useDispatch } from "react-redux";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useDynamicSelector } from "../../services/redux";
import CustomPageTitle from "../components/common/custom_page_title";
import SubDistrictTable from "../components/sub_district/sub_district_table/sub_district_table";
import SubDistrictModal from "../components/sub_district/sub_district_modal/sub_district_modal";
import { query_get_all_sub_district } from "../../services/redux/slices/graphql/graphql_sub_district";
const { Title } = Typography;

const SubDistrict = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [header_type, set_header_type] = useState("");
  const [edit_model_visible, set_edit_modal_visible] = useState(false);
  const [search_string, set_search_string] = useState("");
  const [select_district_id, set_select_district_id] = useState("");

  const {
    items: get_sub_district_list,
    pagination: get_sub_district_list_pagination,
  } = useDynamicSelector("get_sub_district_list");

  const add_district = () => {
    set_edit_modal_visible(true);
    set_header_type("add_district");
  };

  const on_search = (value) => {
    set_search_string(value);
  };

  const get_all_district = () => {
    let keys = [{ key: "get_sub_district_list", loading: true }];
    let query = query_get_all_sub_district;
    let variable = {
      pagination: {
        page_number: 1,
        page_limit: 10,
      },
      sort: {
        field: "code",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    get_all_district();
  }, []);
  const handle_select_district = (value) => {
    set_select_district_id(value);
  };

  return (
    <div
      style={{
        margin: "8px auto",
        width: "98%",
        height: "100%",
      }}
    >
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <CustomPageTitle title={t("sub_districts")} />
        </div>
        <p></p>
      </Row>

      <Col
        style={{
          height: "auto",
          margin: "auto",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Row
          style={{
            justifyContent: "space-between",
            margin: "5px",
            padding: "-22px 12px;",
            alignItems: "center",
          }}
        >
          <Title level={5}>
            {t("t_sub_dist")}
            {`(${get_sub_district_list_pagination?.total_count || 0})`}
          </Title>

          <Row style={{ justifyContent: "space-between" }}>
            <Row style={{ marginRight: "5px" }}>
              <Select
                options={get_sub_district_list || []}
                fieldNames={{ label: "name", value: "id" }}
                style={{ width: "200px" }}
                placeholder={t("districts")}
                showSearch
                filterOption={(input, option) =>
                  (option?.name ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={handle_select_district}
              />
            </Row>
            <Row style={{ marginRight: "5px" }}>
              <CustomSearchBox
                placeholder={"Search sub district"}
                on_search={on_search}
              />
            </Row>

            <CustomButton
              icon={<IoMdAddCircleOutline />}
              button_name={t("add_sub_district")}
              onclick_button={add_district}
              set_edit_modal_visible={set_edit_modal_visible}
              set_header_type={set_header_type}
            />
          </Row>
        </Row>

        <SubDistrictTable
          set_header_type={set_header_type}
          set_edit_modal_visible={set_edit_modal_visible}
          search_string={search_string}
          select_district_id={select_district_id}
        />
      </Col>
      <SubDistrictModal
        header_type={header_type}
        set_edit_modal_visible={set_edit_modal_visible}
        edit_model_visible={edit_model_visible}
        get_all_districts={get_all_district}
      />
    </div>
  );
};

export default SubDistrict;
