import { Form, Select } from "antd";

const PosType = () => {
  const { Option } = Select;

  return (
    <Form.Item
      label="Type"
      name="type"
      rules={[
        {
          required: true,
          message: "Please select a POS type",
        },
      ]}
    >
      <Select placeholder="Select POS type">
        <Option value="worldline">Worldline</Option>
      </Select>
    </Form.Item>
  );
};

export default PosType;
