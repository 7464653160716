import { Form } from "antd";
import React, { useEffect } from "react";
import InputDistrictUserName from "../district_user_inputs/input_user_username";
import InputDistrictUserPassword from "../district_user_inputs/input_user_password";
import InputDistrictUserContactNumber from "../district_user_inputs/input_user_contact_number";
import InputDistrictUserEmailId from "../district_user_inputs/input_user_email_id";
import { useDynamicSelector } from "../../../../../services/redux/selector";
import InputName from "../district_user_inputs/input_user_name";
import {
  mutation_create_district_user,
  mutation_create_sub_district_user,
  mutation_update_district_user,
  mutation_update_sub_district_user,
} from "../../../../../services/redux/slices/graphql/graphql_district";
import { toast } from "react-toastify";
import {
  dynamicClear,
  dynamicRequest,
} from "../../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import InputPosSelector from "../../district_form/form_inputs/input_pos_selector";
import { retrieveItem } from "../../../../../helpers/functions";

const DistrictUserForm = (props) => {
  const {
    header_type,
    edit_record,
    set_add_user_modal_visible,
    get_all,
    record_id,
    district_id,
  } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const role = retrieveItem("role");
  console.log("rolesss", role, header_type);
  const {
    status: update_district_user_status,
    error: update_district_user_error,
  } = useDynamicSelector("update_district_user");
  const {
    status: update_sub_district_user_status,
    error: update_sub_district_user_error,
  } = useDynamicSelector("update_sub_district_user");

  const {
    status: create_district_user_status,
    error: create_district_user_error,
  } = useDynamicSelector("create_district_user");
  const {
    status: create_sub_district_user_status,
    error: create_sub_district_user_error,
  } = useDynamicSelector("create_sub_district_user");

  useEffect(() => {
    if (header_type === "edit_user") {
      form.setFieldsValue({
        ...edit_record,
        system_user: {
          username: edit_record?.system_user.username,
          password: edit_record.password,
        },
        pos_id: edit_record?.pos_id,
      });
    } else {
      form.resetFields();
    }
  }, [header_type, edit_record]);

  const update_district_user = (values) => {
    let keys = [{ key: "update_district_user", loading: true }];
    let query = mutation_update_district_user;
    let variable = {
      id: edit_record?.system_user.id,
      data: {
        ...values,
      },
    };

    dispatch(dynamicRequest(keys, query, variable));
  };
  const update_sub_district_user = (values) => {
    let keys = [{ key: "update_sub_district_user", loading: true }];
    let query = mutation_update_sub_district_user;
    let variable = {
      id: edit_record?.system_user.id,
      data: {
        ...values,
      },
    };

    dispatch(dynamicRequest(keys, query, variable));
  };

  const create_district_user = (values) => {
    let keys = [{ key: "create_district_user", loading: true }];
    let query = mutation_create_district_user;
    let variable = {
      data: {
        ...values,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };
  const create_sub_district_user = (values) => {
    let keys = [{ key: "create_sub_district_user", loading: true }];
    let query = mutation_create_sub_district_user;
    let variable = {
      data: {
        ...values,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const on_finish = (values) => {
    if (header_type === "edit_user" && (role === "District Admin"||role==="Super Admin")) {
      update_district_user(values);
    } else if (header_type === "edit_user" && (role === "Sub-district Admin"||role==="Super Admin")) {
      update_sub_district_user(values);
    } else if (header_type === "add_user" && (role === "Sub-district Admin"||role==="Super Admin")) {
      create_sub_district_user(values);
    } else {
      create_district_user(values);
    }
  };

  useEffect(() => {
    if (create_district_user_status === "success") {
      toast.success("District User Created");
      dispatch(dynamicClear("create_district_user"));
      get_all(record_id);
      set_add_user_modal_visible(false);
    } else if (create_district_user_error?.message) {
      toast.error(create_district_user_error?.message);
      dispatch(dynamicClear("create_district_user"));
      set_add_user_modal_visible(false);
    } else if (update_district_user_status === "success") {
      toast.success("District User Updated");
      dispatch(dynamicClear("update_district_user"));
      get_all(record_id);
      set_add_user_modal_visible(false);
    } else if (update_district_user_error?.message) {
      toast.error(update_district_user_error?.message);
      dispatch(dynamicClear("update_district_user"));
      set_add_user_modal_visible(false);
    } else if (create_sub_district_user_status === "success") {
      toast.success("Sub District User Created");
      dispatch(dynamicClear("create_sub_district_user"));
      get_all(record_id);
      set_add_user_modal_visible(false);
    } else if (create_sub_district_user_error?.message) {
      toast.error(create_sub_district_user_error?.message);
      dispatch(dynamicClear("create_sub_district_user"));
      // set_add_user_modal_visible(false);
    } else if (update_sub_district_user_status === "success") {
      toast.success("Sub District User Updated");
      dispatch(dynamicClear("update_sub_district_user"));
      get_all(record_id);
      set_add_user_modal_visible(false);
    } else if (update_sub_district_user_error?.message) {
      toast.error(update_sub_district_user_error?.message);
      dispatch(dynamicClear("update_sub_district_user"));
      // set_add_user_modal_visible(false);
    }
  }, [
    create_district_user_status,
    create_district_user_error,
    update_district_user_status,
    update_district_user_error,
    create_sub_district_user_status,
    create_sub_district_user_error,
    update_sub_district_user_status,
    update_sub_district_user_error,
  ]);

  return (
    <Form
      key={record_id}
      layout="horizontal"
      colon={false}
      labelAlign="left"
      id={`add_edit_district_user_form-${record_id}`}
      onFinish={on_finish}
      form={form}
      style={{ width: "400px" }}
    >
      <InputName />
      <InputDistrictUserEmailId />
      <InputDistrictUserContactNumber />
      <InputDistrictUserName header_type={header_type} />
      <InputDistrictUserPassword header_type={header_type} />
      {role === "District Admin" && (
        <InputPosSelector
          name={"pos_id"}
          type="district_user"
          record_id={
            header_type === "edit_user"
              ? edit_record?.system_user?.id
              : district_id
          }
          header_type={header_type}
        />
      )}
    </Form>
  );
};

export default DistrictUserForm;
