import { Form, Input } from "antd";
import React, { useState } from "react";
const InputSubDistrictAdminPassword = () => {
  return (
    <>
      <Form.Item
        name={["district_admin", "system_user", "password"]}
        label="Password"
        autoComplete="off"
      >
        <Input.Password placeholder="Password" autoComplete="off" />
      </Form.Item>
    </>
  );
};

export default InputSubDistrictAdminPassword;
