import { DatePicker, Form } from "antd";
import moment from "moment";

const ReceiptDateInput = (props) => {
  const { selected_date, handle_date_change, payment_mode } = props;

  const disabled_date = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <Form.Item
      label={<span style={{ fontWeight: "bold", fontSize: "16px" }}>Date</span>}
      name="date"
      labelCol={{ span: 24 }}
      rules={
        payment_mode === "dd"
          ? [
              {
                required: true,
                message: "Please select the date",
              },
            ]
          : []
      }
    >
      <DatePicker
        onChange={handle_date_change}
        value={selected_date}
        disabledDate={disabled_date}
      />
    </Form.Item>
  );
};

export default ReceiptDateInput;
