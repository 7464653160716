import Typography from "antd/lib/typography/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div style={{ overflow: "auto" }}>
      <Typography
        style={{ margin: "20px", fontWeight: "bold", fontSize: "25px" }}
      >
        {t("privacy_policy")}
      </Typography>
      <Typography style={{ margin: "20px" }}>
        This Privacy Policy applies to all of the products, services and
        websites offered byAdhiparasakthi Siddhar Peedam. Sometimes, we may post
        product specific privacy notices or Help Centre materials to explain our
        products in more detail.
      </Typography>
      <Typography style={{ margin: "20px" }}>
        If you have any questions about this Privacy Policy, please feel free to
        contact us through our website or write to us at aspwct@gmail.com
        Information we collect and how we use it for our Adhiparasakthi Siddhar
        Peedam.
      </Typography>
      <Typography style={{ margin: "20px", fontSize: "20px" }}>
        Changes to our Privacy Policy
      </Typography>
      <Typography style={{ margin: "20px" }}>
        Adhiparasakthi Siddhar Peedam reserves the entire right to
        modify/amend/remove this privacy statement anytime and without any
        reason. Nothing contained herein creates or is intended to create a
        contract/agreement between Adhiparasakthi Siddhar Peedam and any user
        visiting the Adhiparasakthi Siddhar Peedam website or providing
        identifying information of any kind.
      </Typography>
      {/* <Typography
        style={{ margin: "20px", fontWeight: "bold", fontSize: "20px" }}
      >
        DND Policy
      </Typography>
      <Typography style={{ margin: "20px" }}>
        If you wish to stop any further sms/email alerts/contacts from our side,
        all you need to do is to send an email:- aspwct@gmail.com with your
        mobile numbers and you will be excluded from the alerts list.
      </Typography>
      <Typography
        style={{ margin: "20px", fontWeight: "bold", fontSize: "20px" }}
      >
        Contact Details
      </Typography>
      <Typography
        style={{ margin: "20px", fontWeight: "bold", fontSize: "15px" }}
      >
        Adhiparasakthi Siddhar Peedam,
      </Typography>
      <Typography style={{ margin: "20px" }}>
        <ul>
          <li>
            <Typography>Melmaruvathur,</Typography>
          </li>
          <li>
            <Typography>Kancheepuram District,</Typography>
          </li>
          <li>
            <Typography>TamilNadu-603319</Typography>
          </li>
          <li>
            <Typography>PH: +91 44 27529096</Typography>
          </li>
          <li>
            <Typography>Email: aspwct@gmail.com</Typography>
          </li>
        </ul>
      </Typography>
      <Typography
        style={{ margin: "20px", fontWeight: "bold", fontSize: "15px" }}
      >
        Public Relation Office
      </Typography>
      <Typography style={{ margin: "20px" }}>
        <ul>
          <li>
            <Typography> PH: +91 44 27529276</Typography>
          </li>
          <li>
            <Typography> Timing: 8:00 am To 8:00 PM</Typography>
          </li>
        </ul>
      </Typography> */}
    </div>
  );
};

export default PrivacyPolicy;
