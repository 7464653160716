import { gql } from "@apollo/client";
export const get_feedback_question_option_list_query = gql`
  query get_feedback_question_option_list(
    $filter: get_feedback_question_option_list_filter_input
    $sort: sort_input
    $pagination: pagination_input
  ) {
    get_feedback_question_option_list(
      filter: $filter
      sort: $sort
      pagination: $pagination
    ) {
      items {
        id
        option
        feedback_question_id
        is_active
      }

      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const create_feedback_question_option_mutation = gql`
  mutation create_feedback_question_option(
    $data: create_feedback_question_option_input!
  ) {
    create_feedback_question_option(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_feedback_question_option_mutation = gql`
  mutation update_feedback_question_option(
    $id: String!
    $data: update_feedback_question_option_input!
  ) {
    update_feedback_question_option(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_feedback_question_option_mutation_for_delete = gql`
  mutation update_feedback_question_option(
    $id: String!
    $data: update_feedback_question_option_input!
  ) {
    update_feedback_question_option_to_delete: update_feedback_question_option(
      id: $id
      data: $data
    ) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_feedback_question_option_mutation = gql`
  mutation delete_feedback_question_option($id: String!) {
    delete_feedback_question_option(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
