import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _orderBy from "lodash/orderBy";
import {
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../../services/redux";
import moment from "moment";

const UserGroupWiseReportDate = ({ set_selected_date }) => {
  const dispatch = useDispatch();
  const { items: date_slots } = useDynamicSelector("get_season_date_slot_list");

  useEffect(() => {
    get_date_slot_list();
  }, []);

  const get_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const onChangeDate = (values, option) => {
    const date_strings = option.map((option) => option.label);

    const date_objects = date_strings.map((dateString) =>
      moment(dateString, "D-MMM-YYYY")
    );

    const least_date = moment.min(date_objects);
    const last_date = moment.max(date_objects);

    set_selected_date({
      dates: values,
      option: {
        least_date: least_date.format("D-MMM-YYYY"),
        last_date: last_date.format("D-MMM-YYYY"),
      },
    });
  };

  return (
    <div>
      <Form.Item
        name="enrolled_date_slot_id_list"
        label="Date"
        rules={[
          {
            required: true,
            message: "Please Select Date",
          },
        ]}
      >
        <Select
          placeholder="Select Date"
          allowClear
          showSearch
          mode="multiple"
          onChange={onChangeDate}
        >
          {date_slots?.map((option, index) => (
            <Select.Option
              key={option.id}
              value={option.id}
              label={option.date_string}
            >
              {option.date_string}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};
export default UserGroupWiseReportDate;
