import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { district_details_query } from "../../../../../services/redux/slices/graphql/graphql_district";
import {
  dynamicRequest,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const BookingReportDistrict = (props) => {
  const { set_district_id } = props;
  const dispatch = useDispatch();
  const { items } = useDynamicSelector("district_list");

  const get_District_for_booking_report = () => {
    let key = [{ key: "district_list", loading: true }];
    let query = district_details_query;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_District_for_booking_report();
  }, []);

  const on_change = (value) => {
    set_district_id(value);
  };

  const custom_filter_option = (input, option) => {
    const input_value = input.toLowerCase();
    const option_label = option.props.children.toLowerCase();
    return option_label.indexOf(input_value) === 0;
  };
  return (
    <Form.Item name="district_name_input" label="District">
      <Select
        placeholder="Select District"
        allowClear
        showSearch
        onChange={on_change}
        filterOption={custom_filter_option}
      >
        {items?.map((option, index) => (
          <Select.Option key={option.id} value={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default BookingReportDistrict;
