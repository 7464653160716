import {
  Card,
  Collapse,
  Button,
  Space,
  Popconfirm,
  Skeleton,
  Pagination,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { Pressable, Text } from "native-base";
import FeedBackExpandableTableComponent from "../feedback_manage_expandable_table_component/feedback_manage_expandable.table_component";
import CustomButton from "../../../common/custom_button";
import { useTranslation } from "react-i18next";
import {
  delete_feedback_question_option_mutation,
  dynamicClear,
  dynamicRequest,
  get_feedback_question_list,
  get_feedback_question_option_list_query,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
const { Panel } = Collapse;

const AddQuestion = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handle_add,
    handle_edit,
    show_delete_model,
    handle_back,
    get_all_feedback_question_query,
  } = props;

  const [is_edit_model_visible, set_edit_modal_visible] = useState(false);
  const [header_type, set_header_type] = useState("");
  const [edit_values, set_edit_values] = useState({});
  const [panel_record, set_panel_record] = useState("");
  const [is_question_record, set_question_record] = useState([]);
  const [current, set_current] = useState(1);
  const [current_limit, set_current_limit] = useState(5);
  const { items: feedback_question_item, loading } = useDynamicSelector(
    "get_feedback_question_list"
  );

  const { items: get_feedback_question_option_list_items } = useDynamicSelector(
    "get_feedback_question_option_list"
  );

  const {
    status: delete_feedback_question_option_status,
    error: delete_feedback_question_option_error,
  } = useDynamicSelector("delete_feedback_question_option");

  useEffect(() => {
    set_question_record(feedback_question_item);
  }, [feedback_question_item]);
  useEffect(() => {
    let keys = [{ key: "get_feedback_question_option_list", loading: true }];
    dispatch(
      dynamicRequest(keys, get_feedback_question_list, {
        page_number: current,
        page_limit: current_limit,
      })
    );
  }, []);

  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_feedback_question_option_list", loading: true }];
    dispatch(
      dynamicRequest(keys, get_feedback_question_list, {
        page_number: page_number,
        page_limit: page_limit,
      })
    );
    set_current_limit(page_limit);
    set_current(page_number);
  };

  // const fetch_data = (page_number, page_limit) => {
  //   let keys = [{ key: "get_feedback_question_list", loading: true }];
  //   let query = get_feedback_question_list;
  //   let variable = {
  //     page_number: page_number,
  //     page_limit: page_limit,
  //   };
  //   dispatch(dynamicRequest(keys, query, variable));
  //   set_current(page_number);
  //   set_current_limit(page_limit);
  // };

  const handle_click = (record) => {
    set_panel_record(record);
  };

  const handle_add_expandable = () => {
    set_edit_values({});
    set_header_type("add");
    set_edit_modal_visible(true);
  };
  const handle_edit_expandable = (record) => {
    set_header_type("edit");
    set_edit_values(record);
    set_edit_modal_visible(true);
  };
  const isExpanded = {};

  const handle_panel_change = (panelKey) => {
    if (isExpanded[panelKey]) {
      isExpanded[panelKey] = false;
    } else {
      isExpanded[panelKey] = true;
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "10px",
        }}
      >
        {/* <CustomButton
          button_name={t("add_question")}
          onclick_button={handle_add}
          
        /> */}
        <Card
          style={{
            width: "95%",
            borderRadius: "10px",
            borderRadius: "10px",
            border: "1px solid #e0e0e0",
            boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
                marginBottom: "20px",
              }}
            >
              <CustomButton
                button_name={t("back")}
                onclick_button={handle_back}
              />
            </div>
            <Button
              style={{
                marginBottom: "20px",
                backgroundColor: "#179093",
                color: "white",
              }}
              onClick={handle_add}
            >
              Add Question
            </Button>
          </div>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin />
            </div>
          ) : (
            <div style={{ alignContent: "center" }}>
              <Collapse onChange={handle_panel_change}>
                {is_question_record?.map((item) => (
                  <Panel
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ color: "black" }}>
                          {item?.question}{" "}
                        </span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Space size="middle">
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                handle_edit(item);
                              }}
                              type="link"
                            >
                              <EditOutlined />
                            </Button>

                            <Button
                              type="link"
                              onClick={(event) => {
                                event.stopPropagation();
                                show_delete_model(item);
                              }}
                            >
                              <DeleteOutlined />
                            </Button>
                          </Space>
                        </div>
                      </div>
                    }
                    key={item.id}
                  >
                    {item.type === "text" ? (
                      ""
                    ) : (
                      <FeedBackExpandableTableComponent
                        handle_add_expandable={handle_add_expandable}
                        handle_edit_expandable={handle_edit_expandable}
                        header_type={header_type}
                        is_edit_model_visible={is_edit_model_visible}
                        set_edit_modal_visible={set_edit_modal_visible}
                        edit_values={edit_values}
                        panel_record={panel_record}
                        data={item}
                        options={item.options}
                        loading={loading}
                        get_all_feedback_question_query={
                          get_all_feedback_question_query
                        }
                      />
                    )}
                  </Panel>
                ))}
              </Collapse>
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Pagination
              total={is_question_record?.total_count}
              showSizeChanger={true}
              current={current}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AddQuestion;
