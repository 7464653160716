import { Button, Space, Table } from "antd";
import React, { useState } from "react";
import moment from "moment"; // Import Moment.js for date calculations
import {
  dynamicRequest,
  get_season_query,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { EditOutlined } from "@ant-design/icons";
import _orderBy from "lodash/orderBy";

const BookingAvailabilityDateslotsTable = (props) => {
  const dispatch = useDispatch();
  const { handle_edit, show_delete_model, id } = props;
  const { date_slots, loading } = useDynamicSelector("get_season");
  const {
    date_slots: active_season_date_slots,
    loading: active_season_date_slots_loading,
  } = useDynamicSelector("active_season_date_slot");
  const sorted_date_slot_items = _orderBy(
    active_season_date_slots,
    ["date"],
    ["asc"]
  );
  const sorted_season_date_slot_items = _orderBy(date_slots, ["date"], ["asc"]);

  const [current, set_current] = useState(1);
  const [current_limit, set_current_limit] = useState(5);

  const fetch_data = (page_number, page_limit) => {
    // let keys = [{ key: "get_season", loading: true }];
    // let query = get_season_query;
    // let variable = {
    //   page_number: page_number,
    //   page_limit: page_limit,
    // };
    // dispatch(dynamicRequest(keys, query, variable));
    set_current(page_number);
    set_current_limit(page_limit);
  };

  const is_past_date = (date) => {
    return moment(date).isBefore(moment(), "day");
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date_string",
      key: "date",
      // render: (text) => {
      //   const formattedDate = moment(text).format("DD-MMM-YYYY");
      //   return formattedDate;
      // },
    },

    {
      title: "Confirmed Quota",
      dataIndex: "confirmed_quota",
      key: "quota",
    },
    {
      title: "Waiting Quota",
      dataIndex: "waiting_quota",
      key: "quota",
    },
    {
      title: "Confirmed Count",
      dataIndex: "confirmed_count",
      key: "confirmed_count",
    },
    {
      title: "Waiting Count",
      dataIndex: "waiting_count",
      key: "waiting_quota",
    },
    {
      title: "Spot booked Count",
      dataIndex: "spot_booking_count",
      key: "spot_booking_count",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      // width: 300,
      render: (text, record, row_index) => (
        <div>
          {is_past_date(record.date) ? (
            <span>Booking Closed</span>
          ) : (
            <Button onClick={() => handle_edit(record)} type="link">
              <EditOutlined />
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={
          id
            ? sorted_season_date_slot_items?.map((item) => ({ ...item }))
            : sorted_date_slot_items?.map((item) => ({ ...item }))
        }
        // dataSource={date_slots?.map((item) => ({ ...item }))}
        loading={loading || active_season_date_slots_loading}
        pagination={{
          current: current,
          pageSize: current_limit,
          total: date_slots?.length,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10"],
          onChange: fetch_data,
          // onShowSizeChange: (current, size) => {
          //   fetch_data(current, size);
          // },
        }}
        // pagination={true}
        // rowSelection={""}
      ></Table>
    </div>
  );
};

export default BookingAvailabilityDateslotsTable;
