import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const IrumudiPortalUsernameFormItem = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name="booking_reference"
        rules={[
          {
            required: true,
            message: t("receipt_no_required"),
          },
          {
            max: 30,
            message: "Receipt no must be at most 30 characters",
          },
        ]}
      >
        <Input
          className="placeholder-black"
          placeholder="Receipt No"
          style={{
            backgroundColor: "#FFDE8B",
            color: "#2e291e",
            border: "1px solid black",
          }}
        />
      </Form.Item>
    </div>
  );
};

export default IrumudiPortalUsernameFormItem;
