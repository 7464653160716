import { Form, Input, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SeasonDateSlotWaitingQuotaFormItem = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name="waiting_quota"
        label={t("waiting_quota")}
        // rules={[
        //   {
        //     required: true,
        //     // message: "Start Date is required",
        //   },
        // ]}
      >
        <InputNumber />
      </Form.Item>
    </div>
  );
};

export default SeasonDateSlotWaitingQuotaFormItem;
