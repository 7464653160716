import React, { useEffect, useState } from "react";
import DashboardCard from "../components/dashboard/dashbord_card";
import { Button, Row } from "antd";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import { Card } from "native-base";

import { useDispatch } from "react-redux";
import { ReloadOutlined } from "@ant-design/icons";
import axios from "axios";
import {
  dynamicRequest,
  query_sms_balance,
  useDynamicSelector,
} from "../../services/redux";

const SmsDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [sms_credit, set_sms_credit] = useState();
  const { sms_balance, loading: sms_balance_loading } =
    useDynamicSelector("get_sms_credit");

  useEffect(() => {
    get_sms_balance();
  }, []);

  useEffect(() => {
    if (sms_balance) {
      set_sms_credit(sms_balance);
    }
  }, [sms_balance]);

  const get_sms_balance = () => {
    let key = [{ key: "get_sms_credit", loading: true }];
    let query = query_sms_balance;
    dispatch(dynamicRequest(key, query));
  };

  return (
    <div>
      <CustomPageTitle title={t("sms_dashboard")} />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: 5,
        }}
      >
        <Button
          style={{
            backgroundColor: "#1e2c55",
            color: "white",
            borderRadius: "5px",
          }}
          loading={sms_balance_loading}
          onClick={() => {
            get_sms_balance();
          }}
        >
          <ReloadOutlined />
          {t("Refresh")}
        </Button>
      </div>

      <Card
        style={{
          marginTop: "10px",
          width: "98%",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            // gap: "40px",
            marginTop: "20px",
          }}
        >
          <DashboardCard
            sub_title="SMS Balance"
            color="#c2d3ff"
            height="170px"
            width="300px"
            count={sms_credit}
          />
        </Row>
      </Card>
    </div>
  );
};

export default SmsDashboard;
