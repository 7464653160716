import { Form, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import moment from "moment";
import {
  IoCheckmarkDoneCircleSharp,
  IoCheckmarkDoneCircleOutline,
} from "react-icons/io5";
import { Pressable } from "native-base";
import {
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../../services/redux";
import { sort_date_slots } from "../../../../../helpers/functions";
const DateWiseReportAbishekam = ({
  selected_options,
  set_selected_options,
  selectAll,
  setSelectAll,
  set_abishekam_date_id,
}) => {
  const { t } = useTranslation();
  const [sorted_items, set_sorted_items] = useState([]);
  const { items: season_date_slot_list_items } = useDynamicSelector(
    "get_season_date_slot_list"
  );

  useEffect(() => {
    set_sorted_items(sort_date_slots(season_date_slot_list_items));
  }, [season_date_slot_list_items]);

  const get_season_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const dispatch = useDispatch();
  const options = season_date_slot_list_items || [];

  // const handle_select_all = (checked) => {
  //   if (checked) {
  //     set_selected_options(sorted_options.map((option) => option.id));
  //   } else {
  //     set_selected_options([]);
  //   }
  //   setSelectAll(checked);
  // };

  // const handle_select_change = (value) => {
  //   set_selected_options(value);
  //   setSelectAll(false);
  // };

  useEffect(() => {
    get_season_date_slot_list();
  }, []);

  const [selectedValues, setSelectedValues] = useState([]);
  const [abishekam_data_options, set_abishekam_data_options] = useState();

  const handle_change = (values) => {
    set_abishekam_date_id(values);
    // console.log("12", values);
  };

  return (
    <Form.Item
      label={t("abishekam_date")}
      name="date_slot_id_list"
      // style={{ width: "400px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Select
          mode="multiple"
          // style={{ width: "100%" }}
          // value={selected_options}
          onChange={handle_change}
          placeholder="Select All"
          allowClear
        >
          {sorted_items?.map((option) => (
            <Option key={option?.id} value={option.id}>
              {moment(option.date).format("DD-MMM-YYYY")}
            </Option>
          ))}
        </Select>
        {/* <Pressable onPress={selected_abishekam_date}>
          {selectAll ? (
            <Tooltip title="Deselect all">
              <IoCheckmarkDoneCircleSharp size={25} />
            </Tooltip>
          ) : (
            <Tooltip title="Select all">
              <IoCheckmarkDoneCircleOutline size={25} />
            </Tooltip>
          )}
        </Pressable> */}
      </div>
    </Form.Item>
  );
};

export default DateWiseReportAbishekam;
