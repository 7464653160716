import { Checkbox, Form, Switch } from "antd";
import React from "react";

const MandramStatus = (props) => {
  const { set_mandram_status } = props;
  const on_change = (values) => {
    set_mandram_status(values);
  };
  return (
    <Form.Item name="mandram_status" label="Mandram Status">
      <Switch onChange={on_change} />
    </Form.Item>
  );
};

export default MandramStatus;
