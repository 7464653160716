import { Form, Input } from "antd";
import React from "react";
import { retrieveItem } from "../../../../../helpers/functions";

const MandramNameInput = (props) => {
  const { header_type } = props;
  const role = retrieveItem("role");
  return (
    <Form.Item
      label=" Name"
      name="name"
      rules={[
        {
          required: true,
          message: "Please enter name",
        },
      ]}
    >
      <Input
        disabled={
          role === "Super Admin" ||
          role === "MASM Admin" ||
          role === "Mandram Renewal Officer" ||
          role === "MASM User" ||
          (role === "District Admin" && header_type === "Add Mandram")
            ? false
            : true
        }
      />
    </Form.Item>
  );
};

export default MandramNameInput;
