import React, { useEffect, useState } from "react";
import CounterModal from "../components/counter/counter_modal";
import { useTranslation } from "react-i18next";
import CustomDeleteModal from "../components/common/custom_delete_modal";
import CustomPageTitle from "../components/common/custom_page_title";
import { Card, Row } from "antd";
import CustomButton from "../components/common/custom_button";
import {
  dynamicRequest,
  mutation_delete_counter,
  mutation_get_unassigned_pos_list,
  mutation_pos_list,
  query_get_all_counter,
  query_get_one_counter,
} from "../../services/redux/slices";
import { useDispatch } from "react-redux";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import CounterList from "../components/counter/counter_table";
import { useDynamicSelector } from "../../services/redux";
import { query_get_all_counter_group } from "../../services/redux/slices/graphql/graphql_counter_group";

const Counter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [counter_modal_open, set_counter_modal_open] = useState(false);
  const [counter_modal_title, set_counter_modal_title] = useState("");
  const [delete_modal_visible, set_delete_modal_visible] = useState(false);
  const [selected_items, set_selected_items] = useState("");
  // const { name, code, is_active } = useDynamicSelector("get_counter");
  const { items: counter_list } = useDynamicSelector("get_counter_list");
  console.log("counter_list", counter_list);

  const add_counter = () => {
    set_counter_modal_title("add");
    set_counter_modal_open(true);
    // set_selected_items("");
  };

  const handle_counter_modal_close = () => {
    set_counter_modal_open(false);
  };

  const handle_delete_counter = (records) => {
    set_delete_modal_visible(true);
    set_selected_items(records);
  };

  const get_one_counter_record = (counter_id) => {
    get_one_counter(counter_id);
  };

  const get_all_counter_list = () => {
    let key = [{ key: "get_counter_list", loading: true }];
    let query = query_get_all_counter;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  // const get_all_pos_list = () => {
  //   let key = [{ key: "get_pos_list", loading: true }];
  //   let query = mutation_pos_list;
  //   dispatch(dynamicRequest(key, query));
  // };

  // const get_all_unassigned_pos_list = () => {
  //   let key = [{ key: "get_unassigned_pos_list", loading: true }];
  //   let query = mutation_get_unassigned_pos_list;
  //   let variables = {
  //     filter: {
  //       counter_id: selected_items.id,
  //     },
  //   };
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  const delete_one_counter = (record) => {
    let key = [{ key: "delete_counter", loading: true }];
    let query = mutation_delete_counter;
    let variables = {
      id: selected_items.id,
    };

    dispatch(dynamicRequest(key, query, variables));
  };

  const get_one_counter = (id) => {
    let key = [{ key: "get_counter", loading: true }];
    let query = query_get_one_counter;
    let variables = {
      id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_all_counter_group_list = () => {
    let key = [{ key: "get_counter_group_list", loading: true }];
    let query = query_get_all_counter_group;

    dispatch(dynamicRequest(key, query));
  };
  useEffect(() => {
    get_all_counter_group_list();
  }, []);

  useEffect(() => {
    get_all_counter_list();
  }, []);

  // useEffect(() => {
  //   get_all_unassigned_pos_list();
  // }, []);

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row style={{ marginLeft: "10px" }}>
          <CustomPageTitle title={t("counter_title")} />
        </Row>

        <Row style={{ margin: "8px", marginRight: "23px" }}>
          {/* <CustomBreadCrumbs pages={"Home/Counter"} /> */}
        </Row>
      </Row>
      <Card
        style={{
          width: "96%",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Row
            style={{
              alignItems: "flex-end",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <CustomButton
              button_name={t("add_counter")}
              onclick_button={add_counter}
            />
          </Row>
          <CounterList
            get_all_counter_list={get_all_counter_list}
            set_counter_modal_open={set_counter_modal_open}
            set_counter_modal_title={set_counter_modal_title}
            handle_delete_counter={handle_delete_counter}
            get_one_counter_record={get_one_counter_record}
            set_selected_items={set_selected_items}
            set_delete_modal_visible={set_delete_modal_visible}
            get_one_counter={get_one_counter}
            // get_all_unassigned_pos_list={get_all_unassigned_pos_list}
          />
        </Row>
        <CounterModal
          counter_modal_open={counter_modal_open}
          handle_counter_modal_close={handle_counter_modal_close}
          counter_modal_title={counter_modal_title}
          set_counter_modal_open={set_counter_modal_open}
          selected_items={selected_items}
        />

        <CustomDeleteModal
          selector_name={"delete_counter"}
          get_all={get_all_counter_list}
          delete_api={delete_one_counter}
          record={selected_items}
          text={t("counter")}
          delete_modal_visible={delete_modal_visible}
          set_delete_modal_visible={set_delete_modal_visible}
        />
      </Card>
    </>
  );
};
export default Counter;
