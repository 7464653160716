import { Card } from "antd";
import React from "react";
import BookingAvailabilityDateslotsTable from "./booking_availability/booking_availability_dateslots_table/booking_availability_dateslots_table";
import CustomButton from "../common/custom_button";
import { useTranslation } from "react-i18next";

const BookingAvailabilityDateslotsCard = (props) => {
  const { t } = useTranslation();
  const { handle_edit, show_delete_model, handle_back, id } = props;
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card
          style={{
            width: "90%",
            marginTop: "10px",
            border: "1px solid #e0e0e0",
            boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
            borderRadius: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            {id ? (
              <CustomButton
                button_name={t("back")}
                onclick_button={handle_back}
              />
            ) : null}
          </div>
          <BookingAvailabilityDateslotsTable
            handle_edit={handle_edit}
            show_delete_model={show_delete_model}
            id={id}
          />
        </Card>
      </div>
    </div>
  );
};

export default BookingAvailabilityDateslotsCard;
