import { Button, Card, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import CustomButton from "../components/common/custom_button";
import {
  dynamicClear,
  dynamicRequest,
  query_counter_wise_summary,
  query_get_counter_list,
  query_get_counter_wise_summary,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import CounterUserWiseReportList from "../components/counter_user_wise_report/counter_user_wise_report_list/counter_user_wise_report_list";
import PrintCounterUserWiseWiseReport from "../components/counter_user_wise_report/counter_user_wise_print/counter_user_wise_print";
import {
  arrange_xlsx_report_data,
  calculate_user_wise_payment_report,
  export_to_xlsx,
} from "../../helpers/functions";
import CounterUserWiseModal from "../components/counter_user_wise_report/counter_user_wise_modal/counter_user_wise_modal";
import { useReactToPrint } from "react-to-print";
import DistrictUserWiseReportForm from "../components/counter_user_wise_report/counter_user_wise_report_form/counter_user_wise_report_form";
import CounterUserWiseReportForm from "../components/counter_user_wise_report/counter_user_wise_report_form/counter_user_wise_report_form";
import LoaderSpinner from "../components/common/custom_spinner";

const CounterUserWiseReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [counter_id, set_counter_id] = useState();

  const [report_date, set_report_date] = useState(null);
  const [selected_counter, set_selected_counter] = useState(null);
  const [total_amount, set_total_amount] = useState("");
  const [data, set_data] = useState();
  const [selected_values, set_selected_values] = useState();
  console.log("report_date", report_date);

  useEffect(() => {
    dispatch(dynamicClear("get_counter_user_wise_summary"));
  }, []);

  const [modal_visible, set_modal_visible] = useState(false);
  const { items: counter_user_wise_items, loading: counter_user_wise_loading } =
    useDynamicSelector("get_counter_user_wise_summary");

  const get_counter_wise_summary_list = () => {
    let key = [{ key: "get_counter_user_wise_summary", loading: true }];
    let query = query_counter_wise_summary;
    let variables = {
      filter: {
        date_slot_id_list: report_date?.id || "",
        counter_id_list: selected_counter,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const get_all_counter_list = () => {
    let key = [{ key: "get_all_counter_list", loading: true }];
    let query = query_get_counter_list;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const on_print = () => {
    handlePrint();
  };

  const arrange_xlsx_counter_user_report_data = (data_items) => {
    const rearranged_data = [];
    let all_enrollment_total = 0;
    let devotees_total = 0;
    let cash_total = 0;
    let cash_enrollment_total = 0;
    let dd_enrollment_total = 0;
    let dd_amt_total = 0;
    let card_enroll_total = 0;
    let card_amt_total = 0;
    let upi_enroll_total = 0;
    let upi_amt_total = 0;
    let grand_total = 0;

    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S No"]: i + 1,
        ["User"]: data_items[i].user_name,
        ["All Enrollment"]: data_items[i]?.total_enrolments || 0,
        ["Devotees"]: data_items[i]?.total_devotees || 0,
        ["Cash Amount"]: data_items[i]?.cash_amount || 0,
        // ["DD Enrollments"]: data_items[i]?.dd_enrolments || 0,
        // ["DD Amount"]: data_items[i]?.dd_amount || 0,
        ["Card Enrollments"]: data_items[i]?.card_enrolments || 0,
        ["Cash Enrollments"]: data_items[i]?.cash_enrolments || 0,
        ["Card Amount"]: data_items[i]?.card_amount || 0,
        ["UPI Enrollments"]: data_items[i]?.upi_enrolments || 0,
        ["UPI Amount"]: data_items[i]?.upi_amount || 0,
        ["Total Amount"]: data_items[i]?.sum || 0,
      };
      rearranged_data.push(row_data);

      all_enrollment_total += parseFloat(data_items[i]?.total_enrolments) || 0;
      devotees_total += parseFloat(data_items[i]?.total_devotees) || 0;
      cash_total += parseFloat(data_items[i]?.cash_amount) || 0;
      dd_enrollment_total += parseFloat(data_items[i]?.dd_enrolments) || 0;
      cash_enrollment_total += parseFloat(data_items[i]?.cash_enrolments) || 0;
      dd_amt_total += parseFloat(data_items[i]?.dd_amount) || 0;
      card_enroll_total += parseFloat(data_items[i]?.card_enrolments) || 0;
      card_amt_total += parseFloat(data_items[i]?.card_amount) || 0;
      upi_enroll_total += parseFloat(data_items[i]?.upi_enrolments) || 0;
      upi_amt_total += parseFloat(data_items[i]?.upi_amount) || 0;
      grand_total += parseFloat(data_items[i]?.sum) || 0;
    }

    rearranged_data.push({
      ["S No"]: "",
      ["User"]: "Grand Total",
      ["All Enrollment"]: all_enrollment_total,
      ["Devotees"]: devotees_total,
      ["Cash Amount"]: cash_total,
      // ["DD Enrollments"]: dd_enrollment_total,
      // ["DD Amount"]: dd_amt_total,
      ["Card Enrollments"]: card_enroll_total,
      ["Card Amount"]: card_amt_total,
      ["UPI Enrollments"]: upi_enroll_total,
      ["UPI Amount"]: upi_amt_total,
      ["Total Amount"]: grand_total,
    });

    console.log("rearranged_data", rearranged_data);
    return rearranged_data;
  };

  const on_xlsx_download = () => {
    let arranged_data = arrange_xlsx_counter_user_report_data(
      counter_user_wise_items
    );
    set_data(arranged_data);
    export_to_xlsx(arranged_data, "counter User wise report");
  };

  useEffect(() => {
    get_all_counter_list();
    // get_counter_wise_summary_list();
  }, []);

  useEffect(() => {
    if (selected_values) {
      get_counter_wise_summary_list();
    }
  }, [selected_values]);

  useEffect(() => {
    set_total_amount(
      calculate_user_wise_payment_report(counter_user_wise_items)
    );
  }, [counter_user_wise_items]);

  // useEffect(() => {
  //   dispatch(dynamicClear("get_counter_wise_summary"));
  // }, []);

  return (
    <>
      <Row style={{ marginLeft: "10px" }}>
        <CustomPageTitle title={t("counter_user_wise_report_title")} />
      </Row>
      <div style={{ marginTop: "20px" }}>
        <CounterUserWiseReportForm
          get_counter_wise_summary_list={get_counter_wise_summary_list}
          set_report_date={set_report_date}
          report_date={report_date}
          selected_counter={selected_counter}
          set_selected_counter={set_selected_counter}
          set_selected_values={set_selected_values}
        />
      </div>

      {counter_user_wise_loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <LoaderSpinner />
        </div>
      )}

      {counter_user_wise_items && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "25px",
          }}
        >
          <Button
            disabled={counter_user_wise_items?.length > 0 ? false : true}
            style={{
              marginRight: "10px",
              backgroundColor: "#202c57",
              color: "white",
              borderRadius: "5px",
            }}
            onClick={on_print}
          >
            Print
          </Button>
          <Button
            disabled={counter_user_wise_items?.length > 0 ? false : true}
            style={{
              backgroundColor: "#005c43",
              color: "white",
              borderRadius: "5px",
            }}
            onClick={on_xlsx_download}
          >
            Download
          </Button>
        </div>
      )}

      <div ref={componentRef}>
        {counter_user_wise_items && (
          <CounterUserWiseReportList
            total_amount={total_amount}
            report_date={report_date}
          />
        )}
      </div>

      <CounterUserWiseModal
        visible={modal_visible}
        set_visible={set_modal_visible}
        total_amount={total_amount}
        report_date={report_date}
      />
    </>
  );
};
export default CounterUserWiseReport;
