import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { toUpper, upperCase } from "lodash";
import { useTranslation } from "react-i18next";
import SwamiImage from "@assets/icons/adhiparashathi_image.png";
import NotoSansTamil_ExtraCondensed from "@assets/fonts/NotoSansTamil_ExtraCondensed.ttf";

Font.register({ family: "TamilFont", src: NotoSansTamil_ExtraCondensed });

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    padding: "20px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    borderWidth: 1,
    padding: 8,
    flex: 1,
    textAlign: "right",
    fontSize: "8px",
  },
  table_cell: {
    borderWidth: 1,
    padding: 8,
    flex: 1,
    textAlign: "left",
    fontSize: "8px",
  },
  tableColHeader: {
    borderWidth: 1,
    backgroundColor: "#f0f0f0",
    padding: 8,
    flex: 1,
    textAlign: "center",
    fontSize: "10px",
  },
  table_header: {
    display: "flex",
    margin: "5px",
    fontWeight: "bold",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "10px",
  },
  header_content: {
    fontWeight: "600",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "10px",
  },

  image: {
    width: "30px",
    height: "40px",
  },
  full_header_content: {
    marginTop: "-40px",
    display: "flex",
    alignContent: "center",
    justifyContent: "space-evenly",
  },
  pageNumber: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
  },

  tamilText: {
    fontFamily: "TamilFont",
    fontSize: "10px",
  },
  bold_letter: {
    fontWeight: "bold",
  },
  text: {
    fontSize: "10px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const GroupWisePdfRender = ({
  group_wise_items,
  title = "group wise report",
  report_date,
}) => {
  const { t } = useTranslation();
  console.log("datecccccc", report_date);
  return (
    <Document fileName={`Group-Wise-Report.pdf`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image style={styles.image} src={SwamiImage}></Image>

          <View style={styles.flexContainer}>
            <View style={styles.full_header_content}>
              <Text style={styles.header_content}>{t("om_shakthi")}</Text>
              <Text style={styles.header_content}>{t("masm")} </Text>
              <Text style={styles.header_content}>
                {t("adhiparashakthi_siddhar_peedam")}
              </Text>
            </View>
          </View>

          <View></View>
          <Text style={styles.table_header}>{toUpper(title)}</Text>
          {report_date && (
            <View>
              <Text style={styles.text}>{report_date}</Text>
            </View>
          )}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={[styles.table_cell, styles.tamilText]}>
                மாவட்ட குழு
              </Text>
              <Text style={[styles.table_cell, styles.tamilText]}>
                நேற்று வரை
              </Text>
              <Text style={[styles.table_cell, styles.tamilText]}>இன்று</Text>
              <Text style={[styles.table_cell, styles.tamilText]}>
                மொத்தம்
              </Text>
            </View>
            {group_wise_items?.map((item, index) => (
              <View
                style={styles.tableRow}
                key={index}
                wrap={index !== 0 ? "wrap" : "nowrap"}
              >
                <Text style={[styles.table_cell, styles.tamilText]}>
                  {item?.district_group_name}
                </Text>
                <Text style={styles.tableCell}>
                  {item?.till_date_devotees || 0}
                </Text>
                <Text style={styles.tableCell}>
                  {item?.selected_date_devotees || 0}
                </Text>
                <Text style={styles.tableCell}>
                  {item?.total_devotees || 0}
                </Text>
              </View>
            ))}
            <View style={styles.tableRow}>
              <Text
                style={[
                  styles.table_cell,
                  styles.tamilText,
                  styles.bold_letter,
                ]}
              >
                மொத்தம்
              </Text>
              <Text style={[styles.tableColHeader, styles.bold_letter]}>
                {group_wise_items
                  ? group_wise_items.reduce(
                      (sum, record) => sum + (record?.till_date_devotees || 0),
                      0
                    )
                  : 0}
              </Text>
              <Text style={[styles.tableColHeader, styles.bold_letter]}>
                {group_wise_items
                  ? group_wise_items.reduce(
                      (sum, record) =>
                        sum + (record?.selected_date_devotees || 0),
                      0
                    )
                  : 0}
              </Text>
              <Text style={[styles.tableColHeader, styles.bold_letter]}>
                {group_wise_items
                  ? group_wise_items.reduce(
                      (sum, record) => sum + (record?.total_devotees || 0),
                      0
                    )
                  : 0}
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default GroupWisePdfRender;
