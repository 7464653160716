import { Button } from "antd";
import React from "react";

const ActionButton = ({ icon, name, bg_color, on_click }) => {
  return (
    <Button
      type="link"
      onClick={on_click}
      style={{
        backgroundColor: bg_color,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "3px",
        color: "white",
      }}
    >
      <span style={{ color: "white", marginTop: "2px" }}>{icon}</span>
      <span style={{ color: "white" }}>{name}</span>
    </Button>
  );
};

export default ActionButton;
