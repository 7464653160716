import { Form, Input } from "antd";
import React from "react";

const EnrolmentDdReference = (props) => {
  const { payment_mode } = props;
  return (
    <Form.Item
      label="DD Reference"
      name="dd_reference"
      rules={
        payment_mode === "dd"
          ? [
              {
                required: true,
                message: "Please Enter the DD Reference",
              },
            ]
          : []
      }
    >
      <Input placeholder="Enter the DD Reference Number" />
    </Form.Item>
  );
};

export default EnrolmentDdReference;
