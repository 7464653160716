import { Row } from "antd";
import React from "react";
import Barcode from "react-barcode";
import { useDynamicSelector } from "../../../../services/redux";

const BarcodeGenerator = () => {
  const { booking_reference } = useDynamicSelector("get_booking_receipt");
  return (
    <Row style={{ width: "270px", margin: "10px" }}>
      <Barcode
        value={booking_reference}
        format="CODE128"
        height={80}
        width={2}
        displayValue={false}
      />
    </Row>
  );
};

export default BarcodeGenerator;
