import React, { useState } from "react";
import { Space, Table } from "antd";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import CustomDeleteModal from "../../common/custom_delete_modal";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  mutation_delete_pos,
  query_get_all_pos,
  query_get_one_pos,
} from "../../../../services/redux/slices/graphql/graphql_pos";

const PosTable = (props) => {
  const { t } = useTranslation();
  const { set_edit_modal_visible, set_header_type, get_all } = props;
  const dispatch = useDispatch();
  const [delete_modal_visible, set_delete_modal_visible] = useState(false);
  const [current_page_limit, set_current_page_limit] = useState(5);
  const [current_page, set_current_page] = useState(1);
  const [delete_record, set_delete_record] = useState({});

  const { loading: get_district_list_loading, items } =
    useDynamicSelector("get_pos_list");

  const fetch_data = (page_number, page_limit) => {
    let keys = [{ key: "get_pos_list", loading: true }];
    let query = query_get_all_pos;
    let variable = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(keys, query, variable));
    set_current_page(page_number);
    set_current_page_limit(page_limit);
  };

  const get_one_district = (id) => {
    let keys = [{ key: "get_pos", loading: true }];
    let query = query_get_one_pos;
    let variable = {
      id,
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const handle_edit = (record) => {
    get_one_district(record.id);
    set_header_type("edit_pos");
    set_edit_modal_visible(true);
  };

  const handle_delete = (record) => {
    set_delete_record(record);
    set_delete_modal_visible(true);
  };

  const delete_pos = () => {
    let keys = [{ key: "delete_pos", loading: true }];
    let query = mutation_delete_pos;
    let variable = {
      id: delete_record.id,
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "400px",
    },
    {
      title: "Terminal",
      dataIndex: "terminal_id",
      filterSearch: true,
      width: "400px",
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "400px",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "400px",
      render: (_, record) => (
        <>
          <Space
            style={{
              width: "50px",
              justifyContent: "space-between",
            }}
          >
            <EditOutlined onClick={() => handle_edit(record)} />
            <DeleteOutlined onClick={() => handle_delete(record)} />
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        style={{
          width: "100%",
          margin: "21px",
          maxHeight: "500px",
          height: "auto",
        }}
        columns={columns}
        dataSource={items}
        loading={get_district_list_loading}
        className="district-table"
        size="large"
        pagination={{
          current: current_page,
          pageSize: current_page_limit,
          total: items?.length,
          showSizeChanger: true,
          onChange: fetch_data,
          onShowSizeChange: (current, size) => {
            fetch_data(current, size);
          },
        }}
      />

      <CustomDeleteModal
        selector_name="delete_pos"
        get_all={get_all}
        delete_api={delete_pos}
        record={delete_record}
        text={t("pos")}
        delete_modal_visible={delete_modal_visible}
        set_delete_modal_visible={set_delete_modal_visible}
      />
    </>
  );
};

export default PosTable;
