import React, { useEffect, useState } from "react";
import DashboardTitle from "../components/dashboard/dashboard_title";
import DashboardCard from "../components/dashboard/dashbord_card";
import { Row, Typography } from "antd";
import RefreshButton from "../components/dashboard/refresh_button";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import { Card } from "native-base";
import {
  dynamicRequest,
  query_dashboard_list,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dashboard_count, set_dashboard_count] = useState({});

  const {
    today_count,
    yesterday_count,
    total_count,
    loading: dashboard_loading,
  } = useDynamicSelector("get_overall_dashboard");

  const get_dashboard_details = () => {
    let key = [{ key: "get_overall_dashboard", loading: true }];
    let query = query_dashboard_list;
    dispatch(dynamicRequest(key, query));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      get_dashboard_details();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    get_dashboard_details();
  }, []);

  useEffect(() => {
    if (today_count || yesterday_count || total_count) {
      set_dashboard_count({
        today: today_count,
        yesterday: yesterday_count,
        total: total_count,
      });
    }
  }, [today_count, yesterday_count, total_count]);

  return (
    <div>
      <CustomPageTitle title={t("overall_dashboard")} />

      <div
        style={{
          marginTop: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography style={{ color: "#909090" }}>
          {t("dashboard_text")}
        </Typography>
      </div>
      <Card
        style={{
          marginTop: "10px",
          width: "98%",
          margin: "auto",
        }}
      >
        <RefreshButton
          get_dashboard={get_dashboard_details}
          dashboard_loading={dashboard_loading}
        />

        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            // gap: "40px",
            marginTop: "20px",
          }}
        >
          <DashboardCard
            sub_title="TILL YESTERDAY"
            color="#c2d3ff"
            height="170px"
            width="300px"
            count={dashboard_count?.yesterday}
          />
          <DashboardCard
            sub_title="TODAY"
            color="#c3ffe8"
            height="170px,"
            width="300px"
            count={dashboard_count?.today}
          />

          <DashboardCard
            sub_title="TOTAL"
            color="#f8eed7"
            height="170px"
            width="300px"
            count={dashboard_count?.total}
          />
        </Row>
      </Card>
    </div>
  );
};

export default Dashboard;
