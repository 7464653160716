import React from "react";
import { useDynamicSelector } from "../../../../services/redux";
import { retrieveItem } from "../../../../helpers/functions";

const PaymentHistoryTable = () => {
  const {
    total_cash_amount,
    total_card_amount,
    total_dd_amount,
    total_upi_amount,
    total_arrived_count,
    total_amount,
    total_prepaid_card_amount,
    total_prepaid_upi_amount,
    total_prepaid_arrived_count,
    total_prepaid_amount,
  } = useDynamicSelector("get_closure_summary");

  const total_devotees = total_arrived_count + total_prepaid_arrived_count;
  return (
    <table
      style={{
        width: "100%",
        border: "0.3px solid gray",
      }}
    >
      <thead>
        <tr style={{ backgroundColor: "#275670", color: "white" }}>
          <td
            style={{
              border: "0.3px solid gray",
              textAlign: "center",
            }}
          >
            Total Amount :
            <text style={{ marginLeft: "2px" }}>
              ₹
              {total_amount
                ? total_amount.toLocaleString("en-IN", { currency: "INR" })
                : "0"}
            </text>
          </td>

          <td
            style={{
              border: "0.3px solid gray",
              textAlign: "center",
            }}
          >
            Devotees paid at temple : {total_arrived_count || "0"}
          </td>
          <td
            style={{
              border: "0.3px solid gray",
              textAlign: "center",
            }}
          >
            Prepaid Devotees : {total_prepaid_arrived_count || "0"}
          </td>
          <td
            style={{
              border: "0.3px solid gray",
              textAlign: "center",
            }}
          >
            Total Devotees : {total_devotees}
          </td>
        </tr>
      </thead>
      <tbody style={{ height: "100px" }}>
        <tr>
          <td
            style={{
              border: "0.3px solid gray",
              textAlign: "center",
              width: "25%",
            }}
          >
            CASH : ₹
            {total_cash_amount
              ? total_cash_amount.toLocaleString("en-IN", { currency: "INR" })
              : "0"}
          </td>

          <td
            style={{
              border: "0.3px solid gray",
              padding: "3px",
              textAlign: "center",
              width: "25%",
            }}
          >
            DD : ₹
            {total_dd_amount
              ? total_dd_amount.toLocaleString("en-IN", { currency: "INR" })
              : "0"}
          </td>
          <td
            style={{
              borderRight: "1px solid gray",
              padding: "3px",
              textAlign: "center",
              width: "25%",
            }}
          >
            UPI : ₹
            {total_upi_amount
              ? total_upi_amount.toLocaleString("en-IN", { currency: "INR" })
              : "0"}
          </td>
          <td
            style={{
              padding: "3px",
              textAlign: "center",
              width: "25%",
            }}
          >
            CARD : ₹
            {total_card_amount
              ? total_card_amount.toLocaleString("en-IN", { currency: "INR" })
              : "0"}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PaymentHistoryTable;
