import React from "react";
import DistrictWiseDateInput from "../district_wise_report_form_inputs/district_wise_date_input";
import { Button, Card, Col, Form, Row, Space } from "antd";
import CustomButton from "../../common/custom_button";
import { useTranslation } from "react-i18next";
import { dynamicClear } from "../../../../services/redux";
import { useDispatch } from "react-redux";

const DistrictWiseReportForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    set_selected_state,
    district_wise_count,
    set_yesterday_date,
    selected_date,
  } = props;
  const view_district_wise_report = (values) => {
    // set_selected_state(values);
    district_wise_count(values);
  };
  const onchange_district_wise_input = () => {
    dispatch(dynamicClear("get_district_wise_count"));
  };
  return (
    <Form
      id="district_wise_count"
      style={{ width: "100%" }}
      onFinish={view_district_wise_report}
    >
      <Card
        style={{
          width: "96%",
          borderRadius: "5px",
          backgroundColor: "#e7e7e7",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <Row gutter={24} style={{ height: "30px" }}>
            <Col span={12}>
              <DistrictWiseDateInput
                set_yesterday_date={set_yesterday_date}
                set_selected_state={set_selected_state}
                onchange_district_wise_input={onchange_district_wise_input}
                selected_date={selected_date}
              />
            </Col>
            <Col
              span={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                style={{ backgroundColor: "#014477", color: "white" }}
                form="district_wise_count"
                htmlType="submit"
              >
                {t("get")}
              </Button>
            </Col>
          </Row>
        </div>
      </Card>
    </Form>
  );
};
export default DistrictWiseReportForm;
