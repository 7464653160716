import { useDispatch } from "react-redux";
import { export_to_xlsx, retrieveItem } from "../../../../helpers/functions";
import { useEffect, useRef, useState } from "react";
import { dynamicClear, useDynamicSelector } from "../../../../services/redux";
import { Button, Row } from "antd";
import CustomPageTitle from "../../common/custom_page_title";
import BookingReportSearch from "../../system_report/booking_report/form/booking_report_search";
import BookingReportList from "../../system_report/booking_report/list/booking_report_list";
import LoaderSpinner from "../../common/custom_spinner";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import DateWiseBookingReportList from "../date_wise_booking_report_table/date_wise_booking_report_list";

const DateWiseBookingInputs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [counter_id, set_counter_id] = useState();
  const role = retrieveItem("role");

  const [report_date, set_report_date] = useState(null);
  const [selected_counter, set_selected_counter] = useState(null);
  const [total_amount, set_total_amount] = useState("");
  const [data, set_data] = useState();
  const [selected_values, set_selected_values] = useState();
  const [from_date, set_from_date] = useState();
  const [to_date, set_to_date] = useState();

  useEffect(() => {
    dispatch(dynamicClear("get_booking_report"));
  }, []);

  const [modal_visible, set_modal_visible] = useState(false);
  const { items: booking_items, loading: booking_items_loading } =
    useDynamicSelector("get_booking_report");
  const componentRef = useRef();

  const on_print = () => {
    role === "MASM Admin"
      ? handlePrint()
      : (() => {
          const print_content = document.getElementById("print-table");

          const cloned_content = print_content.cloneNode(true);

          const print_window = window.open("", "", "width=600,height=600");
          print_window.document.write(
            "<html><head><title></title></head><body>"
          );
          print_window.document.write(cloned_content.innerHTML);
          print_window.document.write("</body></html>");
          print_window.document.close();

          print_window.onload = () => {
            print_window.print();
            print_window.close();
          };
        })();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const arrange_xlsx_report_data = (data_items) => {
    const rearranged_data = [];
    let irumudi_total = 0;
    let arrived_total = 0;
    let not_arrived_total = 0;
    let excess_total = 0;

    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S No"]: i + 1,
        ["Booking No"]: data_items[i]?.booking_reference ?? "-",
        ["Irumudi Count"]:
          data_items[i]?.confirmed_count + data_items[i]?.waiting_count ?? "-",
        ["Mandram(VSG)"]: `${data_items[i]?.mandram_name}/${data_items[i]?.mandram_code}`,
        // ["Abishekam Date"]: data_items[i]?.abishekam_date ?? "-",
        ["Status"]: data_items[i]?.booking_status ?? "-",
        ["Enrolled Count"]: data_items[i]?.enrolled_count ?? "-",
        ["Yet To Enroll Count"]: data_items[i]?.not_enrolled_count ?? "-",
        ["Excess Count"]: data_items[i]?.excess_count ?? "-",
        ["Payment Mode"]: data_items[i]?.payment_mode ?? "-",
        ["Booked By"]: data_items[i]?.booked_by_name ?? "-",
        ["Leader Mobile"]: data_items[i]?.leader_mobile ?? "-",
      };
      rearranged_data.push(row_data);

      irumudi_total +=
        parseFloat(
          data_items[i]?.confirmed_count + data_items[i]?.waiting_count
        ) || 0;
      arrived_total += parseFloat(data_items[i]?.enrolled_count) || 0;
      not_arrived_total += parseFloat(data_items[i]?.not_enrolled_count) || 0;
      excess_total += parseFloat(data_items[i]?.excess_count) || 0;
    }

    rearranged_data.push({
      ["S No"]: "Grand Total",
      ["Irumudi Count"]: irumudi_total,
      ["Enrolled Count"]: arrived_total,
      ["Yet To Enroll Count"]: not_arrived_total,
      ["Excess Count"]: excess_total,
    });

    return rearranged_data;
  };

  // const arrange_masm_admin_xlsx_data = (data_items) => {
  //   const rearranged_data = [];
  //   let enrolled_total = 0;
  //   let not_arrived_total = 0;
  //   let excess_total = 0;

  //   for (let i = 0; i < data_items?.length; i++) {
  //     let row_data = {
  //       ["S No"]: i + 1,
  //       ["Mandram(VSG)"]: `${data_items[i]?.mandram_name}/${data_items[i]?.mandram_code}`,
  //       ["Enrolled Count"]: data_items[i]?.enrolled_count ?? "-",
  //       ["Yet To Enroll Count"]: data_items[i]?.not_enrolled_count ?? "-",
  //       ["Excess Count"]: data_items[i]?.excess_count ?? "-",
  //     };
  //     rearranged_data.push(row_data);

  //     enrolled_total += parseFloat(data_items[i]?.enrolled_count) || 0;
  //     not_arrived_total += parseFloat(data_items[i]?.not_enrolled_count) || 0;
  //     excess_total += parseFloat(data_items[i]?.excess_count) || 0;
  //   }

  //   rearranged_data.push({
  //     ["S No"]: "Grand Total",
  //     ["Enrolled Count"]: enrolled_total,
  //     ["Yey To Enroll Count"]: not_arrived_total,
  //     ["Excess Count"]: excess_total,
  //   });
  //   return rearranged_data;
  // };

  const aggregated_data_masm_admin = booking_items?.reduce(
    (accumulator, current) => {
      const existing_object = accumulator.find(
        (item) => item.mandram_name === current.mandram_name
      );

      if (existing_object) {
        existing_object.enrolled_count += current.enrolled_count;
        existing_object.not_enrolled_count += current.not_enrolled_count;
        existing_object.excess_count += current.excess_count;
      } else {
        accumulator.push({
          mandram_name: current.mandram_name,
          mandram_code: current.mandram_code,
          enrolled_count: current.enrolled_count,
          not_enrolled_count: current.not_enrolled_count,
          excess_count: current.excess_count,
        });
      }

      return accumulator;
    },
    []
  );

  const on_xlsx_download = () => {
    const arranged_data = arrange_xlsx_report_data(booking_items);
    export_to_xlsx(arranged_data, "booking-report");
    set_data(arranged_data);
  };

  return (
    <>
      {/* <Row style={{ marginLeft: "10px" }}>
        <CustomPageTitle title={t("Booking_report_title")} />
      </Row> */}
      <div style={{ marginTop: "20px" }}>
        <BookingReportSearch
          booking_report_list={booking_items}
          set_report_date={set_report_date}
          report_date={report_date}
          selected_counter={selected_counter}
          set_selected_counter={set_selected_counter}
          set_selected_values={set_selected_values}
          set_to_date={set_to_date}
          set_from_date={set_from_date}
        />
      </div>

      {booking_items?.length > 0 ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "25px",
            }}
          >
            <Button
              disabled={booking_items?.length > 0 ? false : true}
              style={{
                marginRight: "10px",
                backgroundColor: "#202c57",
                color: "white",
                borderRadius: "5px",
              }}
              onClick={on_print}
            >
              Print
            </Button>
            <Button
              disabled={booking_items?.length > 0 ? false : true}
              style={{
                backgroundColor: "#005c43",
                color: "white",
                borderRadius: "5px",
              }}
              onClick={on_xlsx_download}
            >
              Download
            </Button>
          </div>
          <div id="print-table" ref={componentRef}>
            {/* <BookingReportList
              total_amount={total_amount}
              to_date={to_date}
              from_date={from_date}
            /> */}

            <DateWiseBookingReportList
              total_amount={total_amount}
              to_date={to_date}
              from_date={from_date}
            />
          </div>
        </>
      ) : null}
      {booking_items_loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <LoaderSpinner />
        </div>
      )}
    </>
  );
};

export default DateWiseBookingInputs;
