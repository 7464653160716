import { Form } from "antd";
import React, { useEffect } from "react";
import InputNotificationName from "../notification_inputs/input_notification_name";
import InputNotificationContent from "../notification_inputs/input_notification_content";
import InputNotificationEnabled from "../notification_inputs/input_notification_enabled";
import { useDispatch } from "react-redux";
import {
  mutation_create_notification_board,
  mutation_update_notification_board,
} from "../../../../services/redux/slices/graphql/graphql_notification";
import {
  dynamicClear,
  dynamicRequest,
} from "../../../../services/redux/slices";
import { useDynamicSelector } from "../../../../services/redux/selector";
import { toast } from "react-toastify";

const NotificationForm = (props) => {
  const {
    header_type,
    notification_modal_visible,
    set_notification_modal_visible,
    get_all_notifications,
  } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    status: create_notification_status,
    loading: create_notification_loading,
    error: create_notification_error,
  } = useDynamicSelector("create_notification_board");

  const {
    status: update_notification_status,
    loading: update_notification_loading,
    error: update_notification_error,
  } = useDynamicSelector("update_notification_board");

  const { id, name, content, is_active } = useDynamicSelector(
    "get_notification_board"
  );

  const on_finish = (values) => {
    if (header_type === "add_notification") {
      add_notification(values);
      return;
    }
    update_notification(values);
  };

  const add_notification = (values) => {
    let keys = [{ key: "create_notification_board", loading: true }];
    let query = mutation_create_notification_board;
    let variable = {
      data: {
        ...values,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const update_notification = (values) => {
    let keys = [{ key: "update_notification_board", loading: true }];
    let query = mutation_update_notification_board;
    let variable = {
      id,
      data: {
        ...values,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    if (create_notification_status === "success") {
      toast.success("Notification created successfully");
      dispatch(dynamicClear("create_notification_board"));
      get_all_notifications();
      set_notification_modal_visible(false);
    } else if (create_notification_error?.message) {
      toast.error(create_notification_error?.message);
      dispatch(dynamicClear("create_notification_board"));
      get_all_notifications();
      set_notification_modal_visible(false);
    } else if (update_notification_status === "success") {
      toast.success("Notification updated successfully");
      dispatch(dynamicClear("update_notification_board"));
      get_all_notifications();
      set_notification_modal_visible(false);
    } else if (update_notification_error?.message) {
      toast.success(update_notification_error?.message);
      dispatch(dynamicClear("update_notification_board"));
      get_all_notifications();
      set_notification_modal_visible(false);
    }
  }, [
    create_notification_status,
    create_notification_error,
    update_notification_status,
    update_notification_error,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      name,
      content,
      is_active,
    });
  }, [id]);

  return (
    <>
      <Form
        id="notification_form"
        layout="horizontal"
        colon={false}
        labelAlign="left"
        onFinish={on_finish}
        form={form}
        autoComplete="off"
        labelCol={{
          span: 10,
        }}
      >
        <InputNotificationName />
        <InputNotificationContent />
        {header_type === "edit_notification" && (
          <>
            <InputNotificationEnabled />
          </>
        )}
      </Form>
    </>
  );
};

export default NotificationForm;
