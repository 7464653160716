import { Form, Input } from "antd";
import React from "react";

const Username = (props) => {
  const { header_type } = props;
  return (
    <Form.Item
      name={["mandram_admin", "system_user", "username"]}
      label="Username"
      rules={
        header_type === "Add Mandram"
          ? [
              {
                required: true,
                message: "Please enter Username",
              },
            ]
          : []
      }
    >
      <Input />
    </Form.Item>
  );
};

export default Username;
