import { Button, Card, Col, Form, Row } from "antd";
import React from "react";
import CustomButton from "../../../common/custom_button";
import GroupWiseReportDate from "../group_wise_report_form_inputs/group_wise_report_date";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const GroupWiseReportForm = (props) => {
  const dispatch = useDispatch();
  const { get_all_group_wise_list, set_report_date, set_yesterday_date } =
    props;
  const { t } = useTranslation();

  const handle_finish = (values) => {
    get_all_group_wise_list(values);
  };

  const on_change_values = () => {
    dispatch(dynamicClear("get_district_group_wise_count"));
  };
  return (
    <Form
      onFinish={handle_finish}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 15,
      }}
      onValuesChange={on_change_values}
    >
      <Card
        style={{
          width: "96%",
          borderRadius: "5px",
          backgroundColor: "#e7e7e7",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row gutter={24} style={{ height: "30px" }}>
          <Col span={11}>
            <GroupWiseReportDate
              set_report_date={set_report_date}
              set_yesterday_date={set_yesterday_date}
            />
          </Col>
          <Col span={10}></Col>
          <Col span={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                htmlType="submit"
                style={{
                  backgroundColor: "#014477",
                  color: "white",
                  borderRadius: "3px",
                }}
              >
                {t("get")}
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default GroupWiseReportForm;
