import { Typography } from "antd";
import React from "react";

const { Title } = Typography;

const CustomText = ({ title, underline, bold, font_size, font_color }) => {
  const { Title } = Typography;

  return (
    <Title
      style={{
        fontSize: font_size ? font_size : "12px",
        margin: "0px",
        textDecoration: underline ? "underline" : null,
        fontWeight: bold ? "700" : "400",
        color: font_color ? font_color : "black",
      }}
      level={5}
    >
      {title}
    </Title>
  );
};

export default CustomText;
