import { Col, Row, Space, Spin, Table } from "antd";
import React, { useEffect } from "react";
import { useDynamicSelector } from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const MandramWiseCount = (props) => {
  const { title } = props;
  const { t } = useTranslation();

  const { items: mandram_count } = useDynamicSelector(
    "get_district_admin_dashboard_mandram_count_summary"
  );

  console.log("itemsssssss", mandram_count);

  const columns = [
    {
      title: t("code"),
      dataIndex: "mandram_code",
      key: "code",
      width: "85px",
      fixed: "left",
    },

    {
      title: t("mandram"),
      dataIndex: "mandram_name",
      key: "mandram_name",
      width: "100px",
      fixed: true,
    },
    {
      title: t("booked_counts"),
      dataIndex: "",
      key: "booked",
      width: "100px",
      fixed: true,
      render: (items) => {
        return items?.count?.booked;
      },
    },
    {
      title: t("enrolled"),
      dataIndex: "",
      key: "arrived",
      fixed: true,
      width: "100px",
      render: (items) => {
        return items?.count?.arrived;
      },
    },
    {
      title: t("not_arrived"),
      dataIndex: "",
      key: "yet_to_enroll",
      fixed: true,
      width: "130px",
      render: (items) => {
        const result = items?.count?.yet_to_enroll - items?.count?.excess;
        return result < 0 ? 0 : result;
      },
    },
  ];

  return (
    <Table
      scroll={{ x: true, y: 400 }}
      data-name-table="district-wise-report"
      columns={columns}
      dataSource={mandram_count}
      pagination={false}
      style={{ height: "100%" }}
    />
  );
};

export default MandramWiseCount;
