import React from "react";
import { useDynamicSelector } from "../../../../services/redux";
import { FaAddressBook, FaHome, FaPhoneAlt, FaUser } from "react-icons/fa";
import { upperCase } from "lodash";

const GroupLeaderDetails = () => {
  const { booking_user } = useDynamicSelector("get_booking_receipt");

  const style = {
    width: "auto",
  };

  return (
    <>
      <style>
        {`
               @media Print{
                .group-leader-details"{
                    margin-top:7px;
                }
                .group-leader-details h5{
                    margin:0px;
                }
               }
            `}
      </style>
      <div
        className="group-leader-details"
        style={{ marginLeft: "1%", marginTop: "5px", display: "flex" }}
      >
        <div style={{ width: "178px" }}>
          <h5 style={style}>Group Leader</h5>
          <h5 style={style}>Mobile Number </h5>
          <h5 style={style}>Address </h5>
          <h5 style={style}>ID </h5>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <h5 style={style}>
            {`: `}
            <FaUser />
            {` ${booking_user?.name ? upperCase(booking_user.name) : "-"}`}
          </h5>
          <h5 style={style}>
            {`: `}
            <FaPhoneAlt />
            {` ${booking_user?.mobile ? booking_user.mobile : "-"}`}
          </h5>
          <h5>
            {`: `}
            <FaHome />
            {` ${
              booking_user?.address ? upperCase(booking_user.address) : "-"
            }`}
          </h5>
          <h5>
            {`: `}
            <FaAddressBook />
            {` ${
              booking_user?.id_number ? upperCase(booking_user.id_number) : "-"
            }`}
          </h5>
        </div>
      </div>
    </>
  );
};

export default GroupLeaderDetails;
