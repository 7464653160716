import React, { useEffect } from "react";
import { Form, Select } from "antd";
import moment from "moment";
import {
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import _orderBy from "lodash/orderBy";

const { Option } = Select;

const SearchReceiptDate = ({ set_receipt_reference_prefix }) => {
  const dispatch = useDispatch();

  const { items: date_slot_items } = useDynamicSelector(
    "get_season_date_slot_list"
  );

  // const sorted_date_slot_items = _orderBy(
  //   date_slot_items,
  //   ["date_string"],
  //   ["asc"]
  // );
  const sorted_date_slot_items = _orderBy(
    date_slot_items,
    [
      (date) => moment(date.date_string).format("YYYY"),
      (date) => moment(date.date_string).format("MM-DD"),
    ],
    ["asc", "asc"]
  );

  const get_season_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {
      // sort: {
      //   field: "date_string",
      //   order: "asc",
      // },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_change = (value) => {
    const formatted = moment(value).format("DD-MMM-YYYY").toUpperCase();
    const prefix = formatted.split("-");
    set_receipt_reference_prefix(`${prefix[0]}${prefix[1]}`);
  };

  useEffect(() => {
    get_season_date_slot_list();
  }, []);

  return (
    <>
      <Form.Item name="date" label="Date">
        <Select
          onChange={handle_change}
          placeholder={"Select Date"}
          allowClear={true}
        >
          {sorted_date_slot_items?.map((date) => (
            <Option key={date.id} value={date.date}>
              {date?.date_string}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default SearchReceiptDate;
