import gql from "graphql-tag";
export const query_address_report = gql`
  query get_address_report_summary($filter: address_report_summary_input) {
    get_address_report_summary(filter: $filter) {
      items {
        leader_name
        address
        mobile
        email
        district_name
        district_code
        mandram_name
        mandram_code
        id_type
        id_number
        amount
      }
    }
  }
`;
