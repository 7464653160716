import { Form, Input, InputNumber } from "antd";
import React from "react";

const MandramInChargeMobile = () => {
  const on_key_press = (e) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };
  return (
    <Form.Item
      label="Incharge Mobile"
      name="incharge_mobile"
      rules={[
        {
          required: true,
          message: "Please enter Mobile",
        },
        // {
        //   pattern: /^[0-9]{10}$/,
        //   message: "Please enter a valid 10-digit mobile number .",
        // },
      ]}
    >
      <Input type="Number" min={0} onKeyPress={on_key_press} />
    </Form.Item>
  );
};

export default MandramInChargeMobile;
