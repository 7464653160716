import React from "react";
import { Modal, Button } from "antd";
import { Text } from "native-base";

const FeedbackQuestionActiveModal = (props) => {
  const { cancel_active_modal, handle_active_confirm, is_active_modal } = props;

  return (
    <Modal
      open={is_active_modal}
      title="Confirmation"
      onCancel={cancel_active_modal}
      footer={[
        <Button key="cancel" onClick={cancel_active_modal}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={() => handle_active_confirm()}
        >
          Yes
        </Button>,
      ]}
    >
      <Text>Do you really want to change the status?</Text>
    </Modal>
  );
};

export default FeedbackQuestionActiveModal;
