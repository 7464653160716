import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  query_get_counter_list,
  useDynamicSelector,
} from "../../../../services/redux";
import { Form, Select } from "antd";
import { useEffect } from "react";

const CounterUserWiseFilter = (props) => {
  const { set_selected_counter, selected_counter } = props;
  const dispatch = useDispatch();
  const { items: counters } = useDynamicSelector("get_all_counter_list");

  useEffect(() => {
    get_counters();
  }, []);

  const get_counters = () => {
    let key = [{ key: "get_all_counter_list", loading: true }];
    let query = query_get_counter_list;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_change = (values) => {
    dispatch(dynamicClear("get_counter_user_wise_summary"));
    set_selected_counter(values);
  };

  const custom_filter_option = (input, option) => {
    const input_value = input.toLowerCase();
    const option_label = option.props.children.toLowerCase();
    return option_label.indexOf(input_value) === 0;
  };
  return (
    <div>
      <Form.Item
        name="counter_id_list"
        label="Counter"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please Select Counter",
        //   },
        // ]}
      >
        <Select
          mode="multiple"
          placeholder="Select Counters"
          allowClear
          showSearch
          onChange={handle_change}
          filterOption={custom_filter_option}
        >
          {counters?.map((counter, index) => (
            <Select.Option key={counter.id} value={counter.id}>
              {counter.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};
export default CounterUserWiseFilter;
