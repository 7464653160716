import { Card, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import Count from "../../booking_history/booking_history_counts";
import DistrictDashboardOverallCounTable from "./district_dashboard_overall_count_table";

const DistrictDashboardLastCurrentYearCount = ({
  booked,
  arrived,
  not_arrived,
  excess,
  cancelled,
}) => {
  const { t } = useTranslation();
  return (
    <Row
      style={{
        width: "100%",
        height: "100px",
        alignItems: "center",
      }}
    >
      <DistrictDashboardOverallCounTable label={"BOOKED"} value={booked} />
      <DistrictDashboardOverallCounTable label={"ENROLLED"} value={arrived} />
      <DistrictDashboardOverallCounTable
        label={"YET TO ENROLL"}
        value={not_arrived}
      />

      {/* <Count
        label={"TOTAL EXCESS"}
        value={excess}
        backgroundColor={"#8281A0"}
      />
      <Count
        label={"TOTAL CANCELLED"}
        value={cancelled}
        backgroundColor={"#808000"}
      /> */}
    </Row>
  );
};

export default DistrictDashboardLastCurrentYearCount;
