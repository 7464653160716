import { Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import MandramWiseCountTable from "../components/mandramwise_count/mandramwise_count_table";
import Title from "antd/lib/typography/Title";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import { useTranslation } from "react-i18next";
import SearchDistrict from "../components/mandram/mandram_search_inputs/search_district";
import {
  dynamicRequest,
  get_masm_mandram_count_query,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import { get_district_details_query } from "../../services/redux/slices/graphql/graphql_district";

const MandramWiseCount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { items: district_items } = useDynamicSelector("get_district_values");

  const [selected_district_id, set_selected_district_id] = useState("");

  const get_district_options = () => {
    let key = [{ key: "get_district_values", loading: true }];
    let query = get_district_details_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_masm_mandram_count = () => {
    let key = [{ key: "get_masm_user_mandram_count", loading: true }];
    let query = get_masm_mandram_count_query;
    let variables = {
      filter: {
        district_id: selected_district_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_masm_mandram_count();
    get_district_options();
  }, [selected_district_id]);

  return (
    <Col style={{ margin: "8px", width: "98%" }}>
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Title level={4}>{t("mandram_wise_count")}</Title>
        {/* <CustomBreadCrumbs pages={"Home/Mandramwise Count"} /> */}
      </Row>
      <Col
        style={{
          height: "auto",
          overflowY: "scroll",
          margin: "10px",
          width: "98%",
          backgroundColor: "white",
        }}
      >
        <Row>
          <Title level={5} style={{ width: "100px" }}>
            {t("district_search")}
          </Title>
          <SearchDistrict
            set_selected_district_id={set_selected_district_id}
            district_items={district_items}
          />
        </Row>
        <MandramWiseCountTable />
      </Col>
    </Col>
  );
};

export default MandramWiseCount;
