import { Space } from "antd";
import React from "react";
import ColorIndicator from "./color_indicator";

const ColorsCatalog = () => {
  return (
    <Space
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        margin: "10px 0px",
      }}
    >
      <ColorIndicator text="Available" bg_color="green" />
      <ColorIndicator text="Fast Filling" bg_color="#ffa401" />
      <ColorIndicator text="Almost Full" bg_color="#e00102" />
    </Space>
  );
};

export default ColorsCatalog;
