import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../../../common/custom_button";
import { Card, Col, Row, Space } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import OmSakthiReportList from "../om_sakthi_report_list/om_sakthi_report_list";
import AmountDetails from "../om_sakthi_report_list/amount_details";
import DdPayments from "../om_sakthi_report_list/dd_payment_list";
import DdPaymentCard from "../om_sakthi_report_list/dd_payment_card";
import { useTranslation } from "react-i18next";
import { arrange_and_export_xlsx_om_sakthi_form_report_data } from "../../../../../helpers/functions";
import {
  dynamicClear,
  useDynamicSelector,
} from "../../../../../services/redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { AiFillPrinter } from "react-icons/ai";
import UPIPaymentList from "../om_sakthi_report_list/upi_payment_list";
import CardPaymentList from "../om_sakthi_report_list/card_payment_list";
import { useReactToPrint } from "react-to-print";

const OmSakthiReportDetails = (props) => {
  const {
    to_date,
    from_date,
    om_sakthi_report_open,
    set_visible,
    set_from_date,
  } = props;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { items: om_sakthi_report } = useDynamicSelector(
    "get_om_sakthi_summary"
  );

  const { items: om_shakthi_payment_items } = useDynamicSelector(
    "get_om_sakthi_payment"
  );

  const [show_print_button, set_show_print_button] = useState(false);

  const on_print = () => {
    // set_visible(true);
    handlePrint();
  };

  const on_xlsx_download = () => {
    console.log("om_sakthi_report", om_sakthi_report);
    arrange_and_export_xlsx_om_sakthi_form_report_data(
      om_sakthi_report,
      om_shakthi_payment_items
    );
  };

  // useEffect(() => {
  //   if (!om_sakthi_report || om_sakthi_report.length === 0) {
  //     set_from_date("");
  //     toast.error("No Data Found");
  //     dispatch(dynamicClear("get_om_sakthi_summary"));
  //   }
  // }, []);
  return (
    <div>
      <style>
        {`
          @media Print {
            @page {
              counter-increment: page;
              content: "Page " counter(page);
            }
          }
        `}
      </style>
      {om_sakthi_report || om_shakthi_payment_items ? (
        <Col>
          <Row
            style={{
              width: "96%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <CustomButton
              disabled={
                om_shakthi_payment_items && om_sakthi_report?.length > 0
                  ? false
                  : true
              }
              button_name={t("print")}
              bg_color={"#1e2c55"}
              id="om_sakthi_report_print"
              rounded={true}
              onclick_button={on_print}
            />
            <CustomButton
              disabled={
                om_shakthi_payment_items && om_sakthi_report?.length > 0
                  ? false
                  : true
              }
              button_name={t("download")}
              bg_color={"#185c37"}
              rounded={true}
              onclick_button={on_xlsx_download}
            />
          </Row>

          <Card
            id="om_sakthi_report_details"
            title={
              <span>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  OmSakthi
                </div>
                {/* <div>
                {from_date?.selected_from_date} To {to_date?.selected_to_date}
              </div> */}
              </span>
            }
            headStyle={{
              backgroundColor: "#275670",
              color: "white",
              fontSize: "15px",
              display: "flex",
              justifyContent: "center",
              height: "20px",
            }}
            style={{
              width: "96%",
              height: "100%",
              margin: "auto",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div style={{ padding: "20px" }} ref={componentRef}>
              <div style={{ paddingBottom: "20px" }}>
                <OmSakthiReportList to_date={to_date} from_date={from_date} />
              </div>
              <div style={{ paddingBottom: "20px" }}>
                <AmountDetails />
              </div>
              <div style={{ paddingBottom: "20px" }}>
                <DdPaymentCard />
              </div>
              <div style={{ paddingBottom: "20px" }}>
                <UPIPaymentList />
              </div>
              <div style={{ paddingBottom: "35px" }}>
                <CardPaymentList />
              </div>
            </div>
          </Card>
        </Col>
      ) : (
        ""
      )}
    </div>
  );
};
export default OmSakthiReportDetails;
