import React from "react";
import { Card, Row, Col, Typography } from "antd";
import { useDynamicSelector } from "../../../../services/redux";
import VipBookingUserNameAddressSection from "../vip_booking_form/vip_booking_user_name_address_section";
import VipBookingUserIdProofSection from "../vip_booking_form/vip_booking_user_id_proof_section";
import VipBookingUserMobileNumberSection from "../vip_booking_form/vip_booking_user_mobile_section";

const VipBookingUserLeaderDetailsCard = ({ form }) => {
  const leader_details = useDynamicSelector("get_booking_user_details");

  return leader_details?.id ? (
    <Card className="leader_details_card">
      <Row gutter={16} style={{ padding: "3px" }}>
        <Col span={12}>
          <Typography className="leader_details_title">Leader Name:</Typography>
          <Typography>{leader_details.name}</Typography>
        </Col>
        <Col span={12}>
          <Typography className="leader_details_title">Mobile:</Typography>
          <Typography>{leader_details.mobile}</Typography>
        </Col>
      </Row>

      <Row gutter={16} style={{ padding: "3px" }}>
        <Col span={12}>
          <Typography className="leader_details_title">ID Proof:</Typography>
          <Typography>{leader_details.id_type}</Typography>
        </Col>
        <Col span={12}>
          <Typography className="leader_details_title">
            ID Proof Number:
          </Typography>
          <Typography>{leader_details.id_number}</Typography>
        </Col>
      </Row>

      <Row gutter={16} style={{ padding: "3px" }}>
        <Col span={12}>
          <Typography className="leader_details_title">Address:</Typography>
          <Typography>{leader_details.address}</Typography>
        </Col>
      </Row>
    </Card>
  ) : (
    <>
      <VipBookingUserMobileNumberSection form={form} />
      <VipBookingUserNameAddressSection />
      <VipBookingUserIdProofSection form={form} />
    </>
  );
};

export default VipBookingUserLeaderDetailsCard;
