import { Col, Form, Row } from "antd";
import React from "react";
import CustomButton from "../../../common/custom_button";
import AccountsReportDate from "./accounts_report_date";

const AccountsReportForm = () => {
  const handle_finish = (values) => {};
  return (
    <div>
      <Form id="search" onFinish={handle_finish}>
        <Row gutter={24}>
          <Col span={8}>
            <AccountsReportDate />
          </Col>
          <Col span={8}>
            <CustomButton
              button_name={"Get"}
              bg_color={"dodgerBlue"}
              rounded={true}
              //   icon={""}
              //   onclick_button={on_search}
            />
          </Col>
          <Col span={8}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CustomButton
                button_name={"Print"}
                bg_color={"#90a800"}
                rounded={true}

                //   icon={""}
                //   onclick_button={on_search}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AccountsReportForm;
