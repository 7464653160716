import { gql } from "@apollo/client";

export const query_counter_user_wise_summary = gql`
  query get_counter_user_wise_summary(
    $filter: counter_user_wise_summary_filter_input
  ) {
    get_counter_user_wise_summary(filter: $filter) {
      items {
        name
        date
        counter_name
        date
        count {
          arrived
        }
        received_amount
        received_cash_amount
        received_upi_amount
        received_card_amount
        received_dd_amount
        arrived_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const query_counter_wise_summary = gql`
query get_counter_user_wise_summary($filter: counter_user_wise_summary_filter_input) {
  get_counter_user_wise_summary(filter: $filter) {
    items {
      counter_name
      sum
      total_enrolments
      total_devotees
      cash_enrolments
      cash_amount
      dd_enrolments
      dd_amount
      card_enrolments
      card_amount
      upi_enrolments
      upi_amount
      user_name
    }
  }
}
`;


export const get_date_slots_counter_user_wise = gql`
query get_season_date_slot_list($sort: sort_input) {
  get_date_slots_counter_user_wise: get_season_date_slot_list(
    sort: $sort
  ) {
    items {
      id
      date_string
      date
    }
  }
}
`