import React from "react";
import FeedbackUserResponseTable from "../components/manage_feedback/manage_feedback_user_response/manage_feedback_user_response_table";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";

const UserResponse = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <CustomPageTitle title={t("user_response_title")} />
      </div>

      <FeedbackUserResponseTable />
    </div>
  );
};

export default UserResponse;
