import { Col, Form, Row } from "antd";
import React from "react";
import BookingActionReceipt from "../booking_action_inputs/booking_action_receipt_no";
import BookingActionGetDetailButton from "../booking_action_inputs/booking_action_get_detail_button";
import BookingActionDate from "../booking_action_inputs/booking_action_date";

const BookingActionForm = (props) => {
  const { mandram_wise_booking_list } = props;
  const on_finish = (values) => {
    mandram_wise_booking_list(values);
  };
  return (
    <div>
      <Form layout="vertical" onFinish={on_finish}>
        {/* <BookingActionBookingNoReceiptNo /> */}
        <Row gutter={24} justify="space-around" align="middle">
          <Col span={8}>
            <BookingActionDate />
          </Col>
          <Col span={8}>
            <BookingActionReceipt />
          </Col>
          <Col span={3}>
            <BookingActionGetDetailButton />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BookingActionForm;
