import { Card, Typography } from "antd";
import React from "react";
import { getAmountFormatWithSymbol } from "../../../../../helpers/functions";
import { useDynamicSelector } from "../../../../../services/redux";

const CardPaymentList = () => {
  const {
    items: om_shakthi_payment_items,
    error: om_sakthi_payment_report_error,
  } = useDynamicSelector("get_om_sakthi_payment");

  const cardPaymentItems = om_shakthi_payment_items?.filter(
    (item) => item.payment_mode === "card"
  );

  const totalAmount = cardPaymentItems?.reduce(
    (acc, item) => acc + (item?.amount || 0),
    0
  );

  return (
    // Wrap the JSX content in parentheses
    cardPaymentItems?.length > 0 && (
      <div style={{ width: "100%" }}>
        <Typography style={{ fontWeight: "bold" }}>CARD</Typography>
        <table className="table table-bordered" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th className="header-style">SL.No</th>
              <th className="header-style">Receipt No</th>
              {/* <th className="header-style">Bank</th> */}
              <th className="header-style">Reference Number</th>
              <th className="header-style">Amount</th>
            </tr>
          </thead>
          <tbody>
            {cardPaymentItems?.map((x, index) => (
              <tr key={index}>
                <td className="body-style">{index + 1}</td>
                <td className="body-style">{x.receipt_number}</td>{" "}
                {/* <td className="body-style">{"-"}</td> */}
                <td className="body-style">{x.pos_receipt}</td>
                <td
                  className="body-style"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {getAmountFormatWithSymbol(x?.amount)}
                </td>
              </tr>
            ))}
            <tr style={{ fontWeight: "bold" }}>
              <td className="body-style">Total</td>
              <td className="body-style"></td>
              <td className="body-style"></td>
              <td
                className="body-style"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {getAmountFormatWithSymbol(totalAmount)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  );
};

export default CardPaymentList;
