import gql from "graphql-tag";

export const payment_mode_report_query=gql`
query{
    get_payment_mode{
      items{
        payment_date
        total_cash_amount
        total_dd_amount
        total_card_amount
        total_upi_amount
        total_amount
      }
    }
  }`;