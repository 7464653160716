import { Form, Input } from "antd";
import React from "react";

const InputDistrictAdminPassword = () => {
  return (
    <>
      <Form.Item
        name={["sub_district_admin", "system_user", "password"]}
        label="Password"
      >
        <Input.Password
          type="password"
          placeholder="Password"
          autoComplete="off"
        />
      </Form.Item>
    </>
  );
};

export default InputDistrictAdminPassword;
