import { Typography } from "antd";
import React from "react";

const PageDelete = (props) => {
  const { delete_name } = props;
  const { Text } = Typography;

  return (
    <div>
      <Text>
        Do you want to delete the page{" "}
        <text style={{ fontWeight: "bold" }}>{delete_name.name}</text>?
      </Text>
    </div>
  );
};
export default PageDelete;
