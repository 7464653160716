import { Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import CounterPaymentReportForm from "../components/system_report/counter_payment_report/counter_payment_report_form/counter_payment_report_form";
import CounterPaymentReportDetails from "../components/system_report/counter_payment_report/counter_payment_report_details/counter_payment_report_details";
import {
  dynamicRequest,
  get_counter_payment_summary,
  get_counter_wise_summary,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import {
  arrange_xlsx_counter_payment_data,
  calculate_counter_payment_report,
  export_to_xlsx,
} from "../../helpers/functions";
import { set } from "lodash";
import CounterPaymentReportModal from "../components/system_report/counter_payment_report/counter_payment_report_modal/counter_payment_report_modal";
import CustomButton from "../components/common/custom_button";
import { useReactToPrint } from "react-to-print";
import LoaderSpinner from "../components/common/custom_spinner";

const CounterPaymentReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected_values, set_selected_values] = useState();
  const [selected_date, set_selected_date] = useState();
  const [calculated_count, set_calculated_count] = useState();
  const [selected_counter_id, set_selected_counter_id] = useState();
  const [total_amount, set_total_amount] = useState();

  const { items: counter_list } = useDynamicSelector("get_all_counter_list");
  const { items: counter_payment_list, loading: counter_payment_list_loading } =
    useDynamicSelector("get_counter_user_wise_summary");
  const { items: date_slots } = useDynamicSelector(
    "counter_user_wise_report_date_slots"
  );

  const matching_counter_and_date = selected_values?.counter?.map(
    (counterId) => {
      const matchingCounter = counter_list?.find(
        (counter) => counter.id === counterId
      );
      return {
        counter_name: matchingCounter?.name,
      };
    }
  );

  const counter = matching_counter_and_date
    ?.map((counterName) => counterName?.counter_name)
    .join(",");

  const get_counter_summary_list = () => {
    let key = [{ key: "get_counter_user_wise_summary", loading: true }];
    let query = get_counter_payment_summary;
    let variables = {
      filter: {
        date_slot_id_list: selected_values?.date || [],
        counter_id_list: selected_values?.counter || [],
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const on_search_counter_payment_details = (values) => {
    set_selected_values(values);
  };

  const on_xlsx_download = () => {
    const arranged_data = arrange_xlsx_counter_payment_data(
      calculated_count,
      counter,
      counter_list,
      selected_date,
      total_amount
    );
    export_to_xlsx(arranged_data, "counter_payment_report");
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const on_print = () => {
    handlePrint();
  };

  useEffect(() => {
    if (selected_values) {
      get_counter_summary_list();
    }
  }, [selected_values]);

  useEffect(() => {
    set_calculated_count(
      calculate_counter_payment_report(counter_payment_list)
    );
  }, [counter_payment_list]);

  return (
    <>
      <Row style={{ marginLeft: "10px" }}>
        <CustomPageTitle title={t("counter_payment_report_title")} />
      </Row>
      <div style={{ marginTop: "20px" }}>
        <CounterPaymentReportForm
          set_selected_date={set_selected_date}
          handle_search={on_search_counter_payment_details}
          set_selected_counter_id={set_selected_counter_id}
          selected_counter_id={selected_counter_id}
        />
      </div>

      {counter_payment_list_loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <LoaderSpinner />
        </div>
      )}

      <div style={{ marginTop: "20px" }}>
        {counter_payment_list?.length > 0 ? (
          <>
            <Row
              style={{
                width: "96%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <CustomButton
                button_name={"Print"}
                bg_color={"#1E2C55"}
                rounded={true}
                //   icon={""}
                onclick_button={on_print}
                disabled={counter_payment_list?.length > 0 ? false : true}
              />
              <CustomButton
                button_name={"Download"}
                bg_color={"#185c37"}
                rounded={true}
                //   icon={""}
                onclick_button={on_xlsx_download}
                disabled={counter_payment_list?.length > 0 ? false : true}
              />
            </Row>
            <div ref={componentRef} style={{ marginTop: "20px" }}>
              <CounterPaymentReportDetails
                count={calculated_count}
                selected_values={selected_values}
                counter={counter}
                selected_date={selected_date}
                total_amount={total_amount}
                // matching_counters={matching_counters}
                // counter_name={counter_name}
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};
export default CounterPaymentReport;
