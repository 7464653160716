import React from "react";
import InputDistrictName from "../form_inputs/input_district_name";
import InputDistrictCode from "../form_inputs/input_district_code";
import InputTamilName from "../form_inputs/input_tamil_name";
import InputGroupName from "../form_inputs/input_district_group";
import { Col, Row } from "antd";
import InputIsVIP from "../form_inputs/input_vip";
import InputExtraAllowedPercentage from "../form_inputs/input_extra_allowed_percentage";
import InputIsForeign from "../form_inputs/input_admin_is_foreign";

export const District = (props) => {
  return (
    <>
      <Col
        style={{
          padding: "10px",
          borderRadius: "6px",
          width: "100%",
          backgroundColor: "rgba(211, 211, 211, 0.2)",
          marginTop: "10px",
        }}
      >
        <h3 style={{ marginLeft: "12px" }}>District</h3>
        <Row gutter={16} justify={"space-between"}>
          <Col span={12}>
            <InputDistrictName />
            <InputDistrictCode />
            <InputExtraAllowedPercentage />
            <InputIsForeign {...props} />
          </Col>
          <Col span={11}>
            <InputTamilName />
            <InputGroupName />
            <InputIsVIP {...props} />
          </Col>
        </Row>
      </Col>
    </>
  );
};
