import { Col, Row } from "antd";
import React from "react";
import { countFormat } from "../../../helpers/constants";
import moment from "moment";

const BookingHeaderList = (props) => {
  const { item } = props;
  return (
    <div>
      <Row>
        <Col span={3}>
          {moment(item?.date, "DD/MM/YYYY").format("DD-MMM-YYYY")}
        </Col>
        <Col span={3}>
          {"Booked: "}
          {item?.total_booked?.toLocaleString("en-IN")}
        </Col>
        <Col span={3}>
          {"Cancelled: "}
          {item?.total_cancelled_count?.toLocaleString("en-IN")}
        </Col>
        <Col span={3}>
          {"Enrolled: "}
          {item?.total_enrolled?.toLocaleString("en-IN")}
        </Col>

        <Col span={4}>
          {"Yet To Enroll: "}
          {/* {item?.total_yet_to_enroll_count < 0
            ? 0
            : item?.total_yet_to_enroll_count?.toLocaleString("en-IN")} */}
          {item?.total_yet_to_enroll_count}
        </Col>
        {/* <Col span={3}>
          {"Arrived: "}
          {item?.total_arrived}
        </Col> */}
        <Col span={3}>
          {"Excess: "}
          {/* {item?.total_yet_to_enroll_count < 0
            ? Math.abs(item?.total_yet_to_enroll_count?.toLocaleString("en-IN"))
            : 0} */}
          {item?.total_excess_count}
        </Col>

        {/* <Col span={3}>
          {"Paid: "}
          {item?.total_received_amount}
        </Col> */}
        <Col span={4}>
          {"Prepaid: "}
          {item?.total_prepaid_amount?.toLocaleString("en-IN")}
        </Col>
      </Row>
    </div>
  );
};

export default BookingHeaderList;
