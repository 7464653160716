import React, { useRef, useState } from "react";
import { useDynamicSelector } from "../../../../services/redux";
import { toUpper, upperCase } from "lodash";
import { Button, Input, Space, Table } from "antd";
import PrintHeader from "../../common/print_header";
import { retrieveItem } from "../../../../helpers/functions";
import { IS_TABLE_SCROLLABLE } from "../../../../helpers/constants";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const DateWiseBookingReportList = (props) => {
  const { from_date, to_date } = props;
  const { items: booking_report_items, loading: counter_group_items_loading } =
    useDynamicSelector("get_booking_report");

  const search_input = useRef(null);
  const [searched_column, set_searched_column] = useState(null);
  const [search_text, set_search_text] = useState({});

  const [irumudi_count, set_total_irumudi_count] = useState(0);

  // useEffect(() => {
  //   if (booking_report_items) {
  //     var total_irumudi =
  //       booking_report_items?.confirmed_count +
  //       booking_report_items?.waiting_count;
  //     set_total_irumudi_count(total_irumudi);
  //   }
  // }, [booking_report_items]);

  var total_irumudi_count = booking_report_items?.reduce((acc, item) => {
    return acc + (item?.confirmed_count + item?.waiting_count || 0);
  }, 0);

  const role = retrieveItem("role");

  const columns_super_admin = [
    {
      title: "Booking Reference",
    },

    {
      title: "Mandram(VSG)",
    },
    {
      title: "Booked Count",
    },

    {
      title: "Enrolled Count",
    },
    {
      title: "Yet To Enroll Count",
    },
    {
      title: "Excess",
    },
    {
      title: "Booked",
    },
    {
      title: "Payment Mode",
    },
    {
      title: "Leader",
    },
    {
      title: "Status",
    },
  ];

  const handle_search = (selectedKeys, confirm, data_index) => {
    confirm();
    set_search_text({ ...search_text, [data_index]: selectedKeys[0] });
  };

  const handle_reset = (clearFilters, data_index) => {
    clearFilters();
    set_search_text({ ...search_text, [data_index]: "" });
  };

  const get_column_search_props = (data_index, custom_title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={search_input}
          placeholder={`Search ${custom_title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handle_search(selectedKeys, confirm, data_index)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handle_reset(clearFilters, data_index)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handle_search(selectedKeys, confirm, data_index)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : "white" }} />
    ),
    onFilter: (value, record) =>
      record[data_index]
        ? record[data_index]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => search_input.current?.select(), 100);
      }
    },
    render: (text) =>
      searched_column === data_index ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[search_text[data_index]]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Booking Reference",
      key: "booking_reference",
      align: "center",
      fixed: "left",
      dataIndex: "booking_reference",
      ...get_column_search_props("booking_reference", "Booking Reference"),
    },
    {
      title: "Code",
      key: "mandram_code",
      // render: (data) => data?.mandram_code,
      dataIndex: "mandram_code",
      ...get_column_search_props("mandram_code", "Code"),
    },

    {
      title: "Mandram(VSG)",
      key: "mandram_name",
      render: (data) => data?.mandram_name,
      dataIndex: "mandram_name",
      ...get_column_search_props("mandram_name", "Mandram(VSG)"),
    },

    // {
    //   title: "Abishekam Date",
    //   key: "abishekam_date",
    //   render: (data) => moment(data?.abishekam_date).format("DD-MM-YYYY"),
    // },
    {
      title: "Booked",
      key: "irumudi_count",
      align: "center",
      render: (data) => {
        let total_irumudi = data?.confirmed_count + data?.waiting_count;
        return total_irumudi;
      },
    },

    {
      title: "Enrolled Count",
      key: "arrived_count",
      align: "center",
      render: (data) => data?.enrolled_count,
    },
    {
      title: "Yet To Enroll Count",
      key: "not_arrived_count",
      align: "center",
      render: (data) => data?.not_enrolled_count,
    },
    {
      title: "Excess",
      key: "excess",
      align: "center",
      render: (data) => data?.excess_count,
    },
    {
      title: "Booked By",
      key: "booked_by_name",
      align: "center",
      dataIndex: "booked_by_name",
      render: (data) => data?.booked_by_name,
      ...get_column_search_props("booked_by_name", "Booked By"),
    },
    {
      title: "Booked Date",
      key: "booked_date",
      align: "center",
      render: (data) => moment(data?.booked_date).format("DD-MM-YYYY"),
    },

    {
      title: "Leader Name",
      key: "leader_name",
      align: "center",
      dataIndex: "leader_name",
      render: (data) => data?.leader_name,
      ...get_column_search_props("leader_name", "Leader Name"),
    },
    {
      title: "Mobile Number",
      key: "leader_mobile",
      align: "center",
      dataIndex: "leader_mobile",
      render: (data) => data?.leader_mobile,
      ...get_column_search_props("leader_mobile", "Mobile Number"),
    },
    {
      title: "Payment Mode",
      key: "payment_mode",
      align: "center",
      dataIndex: "payment_mode",
      render: (data) =>
        data?.payment_mode ? startCase(data?.payment_mode) : "-",
      ...get_column_search_props("payment_mode", "Payment Mode"),
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      fixed: "right",
      render: (data) => upperCase(data?.booking_status),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        margin: "auto",
        width: "98%",
        marginBottom: "20px",
      }}
    >
      <Table
        style={{ width: "99%" }}
        data-name-table="district-wise-report"
        className="screen-table"
        columns={columns}
        loading={counter_group_items_loading}
        dataSource={booking_report_items}
        pagination={false}
        scroll={{
          x: 1920,
          y: 300,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <text style={{ fontWeight: "bold", paddingLeft: "50px" }}>
                  Total
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={3}></Table.Summary.Cell>
              <Table.Summary.Cell index={4} className="aligned-center">
                <text style={{ fontWeight: "bold", marginLeft: "58px" }}>
                  {booking_report_items
                    ? booking_report_items.reduce(
                        (sum, record) =>
                          sum +
                          (record?.confirmed_count + record?.waiting_count ||
                            0),
                        0
                      )
                    : 0}
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={4} className="aligned-center">
                <text style={{ fontWeight: "bold", marginLeft: "58px" }}>
                  {booking_report_items
                    ? booking_report_items.reduce(
                        (sum, record) => sum + (record?.enrolled_count || 0),
                        0
                      )
                    : 0}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} className="aligned-center">
                <text style={{ fontWeight: "bold", marginLeft: "58px" }}>
                  {booking_report_items
                    ? booking_report_items.reduce(
                        (sum, record) =>
                          sum + (record?.not_enrolled_count || 0),
                        0
                      )
                    : 0}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} className="aligned-center">
                <text style={{ fontWeight: "bold", marginLeft: "58px" }}>
                  {booking_report_items
                    ? booking_report_items.reduce(
                        (sum, record) => sum + (record?.excess_count || 0),
                        0
                      )
                    : 0}
                </text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />

      <div style={{ width: "100%", margin: "auto", marginBottom: "20px" }}>
        <div className="print-header">
          <PrintHeader />
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            BOOKING REPORT
          </h4>
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {to_date}
          </h4>
        </div>
        <table
          className="table table-bordered print-table"
          style={{
            width: "100%",
            marginTop: "10px",
            borderCollapse: "collapse",
            marginLeft: "-10px",
          }}
        >
          <thead style={{ width: "100%" }}>
            <tr>
              {columns_super_admin.map((column) => (
                <th
                  className="header-style"
                  style={{
                    width: "100%",
                    border: "1px solid #ddd",
                    textAlign: "center",
                  }}
                  key={column.key}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {booking_report_items?.map((data, index) => (
              <tr key={index}>
                <td
                  className="body-style "
                  style={{ border: "1px solid #ddd" }}
                >
                  {data?.booking_reference}
                </td>

                <td
                  className="body-style "
                  style={{
                    textAlign: "left",
                    border: "1px solid #ddd",
                    width: "90px",
                  }}
                >
                  {`${data?.mandram_name}`}
                  <br></br>
                  {`${data?.mandram_code}`}
                </td>
                <td
                  className="body-style "
                  style={{
                    textAlign: "right",
                    border: "1px solid #ddd",
                  }}
                >
                  {data?.confirmed_count + data?.waiting_count}
                </td>

                <td
                  className="body-style "
                  style={{
                    textAlign: "right",
                    border: "1px solid #ddd",
                  }}
                >
                  {data?.enrolled_count}
                </td>
                <td
                  className="body-style "
                  style={{
                    textAlign: "right",
                    border: "1px solid #ddd",
                  }}
                >
                  {data?.not_enrolled_count}
                </td>
                <td
                  className="body-style "
                  style={{
                    textAlign: "right",
                    border: "1px solid #ddd",
                  }}
                >
                  {data?.excess_count}
                </td>
                <td
                  className="body-style "
                  style={{
                    textAlign: "left",
                    border: "1px solid #ddd",
                  }}
                >
                  {`${data?.booked_by_name}`}
                  <br></br>
                  {`${moment(data?.booked_date).format("DD-MM-YYYY")}`}
                </td>
                <td
                  className="body-style "
                  style={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                  }}
                >
                  {toUpper(data?.payment_mode)}
                </td>
                <td
                  className="body-style "
                  style={{
                    textAlign: "left",
                    border: "1px solid #ddd",
                  }}
                >
                  {`${data?.leader_name}`}
                  <br></br>
                  {`${data?.leader_mobile}`}
                </td>
                <td
                  className="body-style "
                  style={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                  }}
                >
                  {upperCase(data?.booking_status)}
                </td>
              </tr>
            ))}
          </tbody>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                fontWeight: "bold",
              }}
            >
              Total
            </td>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                textAlign: "right",
              }}
            >
              <text style={{ textAlign: "right" }}>{total_irumudi_count}</text>
            </td>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                textAlign: "right",
              }}
            >
              {"-"}
            </td>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                textAlign: "right",
              }}
            >
              {"-"}
            </td>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                textAlign: "right",
              }}
            >
              <text style={{ textAlign: "right" }}>
                {booking_report_items
                  ? booking_report_items.reduce(
                      (sum, record) => sum + (record?.enrolled_count || 0),
                      0
                    )
                  : 0}
              </text>
            </td>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                textAlign: "right",
              }}
            >
              <text style={{ textAlign: "right" }}>
                {booking_report_items
                  ? booking_report_items.reduce(
                      (sum, record) => sum + (record?.not_enrolled_count || 0),
                      0
                    )
                  : 0}
              </text>
            </td>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
                textAlign: "right",
              }}
            >
              <text style={{ textAlign: "right" }}>
                {booking_report_items
                  ? booking_report_items.reduce(
                      (sum, record) => sum + (record?.excess_count || 0),
                      0
                    )
                  : 0}
              </text>
            </td>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
              }}
            ></td>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
              }}
            ></td>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
              }}
            ></td>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
              }}
            ></td>
            <td
              style={{
                fontWeight: "bold",
                border: "1px solid #ddd",
              }}
            ></td>
          </tr>
          {/* </tfoot> */}
        </table>
      </div>
      <style>
        {`
      @media screen {
        .print-table {
          display: none;
        }
        .print-header {
          display: none;
        }
      }
  
      @media print {
        .screen-table {
          display: none !important;
        }
        .print-header {
          display: block !important;
        }
  
        @media print {
          @page {
            size: A4;
            margin: 1cm;
          }
    
          body::before {
            content: counter(page);
            top: 0;
            right: 1cm;
          }
        }
      }
    `}
      </style>
    </div>
  );
};
export default DateWiseBookingReportList;
