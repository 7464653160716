import { count_format } from "@src/helpers/constants";
import { Col, Skeleton, Typography } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../services/redux";

const BookingHistoryCountBox = (props) => {
  const { loading: booking_summary_loading } = useDynamicSelector(
    "get_date_district_mandram_wise_summary"
  );

  return (
    <Col
      style={{
        backgroundColor: props.backgroundColor,
      }}
      className="booking_history_count_column"
    >
      <Typography style={{ textAlign: "center", color: "white" }}>
        {props.label}
      </Typography>
      <Typography style={{ color: "white", fontWeight: "bold" }}>
        {booking_summary_loading && (
          <Skeleton.Button active={true} size={"small"} shape={"round"} />
        )}
        {!booking_summary_loading && count_format(props.value)}
      </Typography>
    </Col>
  );
};

export default BookingHistoryCountBox;
