import React from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import { Dropdown, Menu } from "antd";

const UIModulePages = (props) => {
  const {
    handle_edit,
    handle_add,
    handle_delete,
    setIsHovered,
    key,
    expandedRowKey,
    record,
  } = props;

  const menu = (
    <Menu>
      <Menu.Item key="add" onClick={() => handle_add(record)}>
        <BiAddToQueue style={{ marginRight: "5px", color: "green" }} />
        Add
      </Menu.Item>
      <Menu.Item key="edit" onClick={() => handle_edit(record)}>
        <AiOutlineEdit style={{ marginRight: "5px", color: "blue" }} />
        Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handle_delete(record)}>
        <AiOutlineDelete style={{ marginRight: "5px", color: "red" }} />
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      key={key}
    >
      <Dropdown overlay={menu} trigger={["hover"]}>
        <div style={{ position: "relative" }}>{record?.name}</div>
      </Dropdown>
    </div>
  );
};

export default UIModulePages;
