import CryptoJS from "crypto-js";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import axios from "axios";
import i18n from "i18next";
import { t } from "i18next";
import { message as antdMessage } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import { API_URL, fileQuery } from "./constants";
import moment from "moment";
import { Text } from "native-base";
import { historyRef } from "../views/routes/Router";
import { QueryRequest } from "../services/apollo/api_service";
import { get_enrolment_detail_query } from "../services/redux/slices/graphql/graphql_get_enrolment_detail";

export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const encryptData = (data, salt) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

export const decryptData = (cipher_text, salt) => {
  const bytes = CryptoJS.AES.decrypt(cipher_text, salt);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};

export function storeItem(key, data) {
  const salt = process.env.REACT_APP_SALT || "";
  let value = JSON.stringify(data);
  let encryptedKey = window.btoa(key);
  let encryptedValue = encryptData(value, salt);
  window.localStorage.setItem(encryptedKey, encryptedValue);
  window.dispatchEvent(new Event(`${key}`));
}

export function retrieveItem(key) {
  const salt = process.env.REACT_APP_SALT || "";
  try {
    let encryptedKey = window.btoa(key);
    let value = window.localStorage.getItem(encryptedKey);
    const decryptedValue = decryptData(value, salt);
    return JSON.parse(decryptedValue);
  } catch (e) {
    return null;
  }
}

export function clearLocalStorage() {
  try {
    window.localStorage.clear();
  } catch (e) {
    return;
  }
}

export async function fileUpload(file, storageName) {
  let url = await getURL(storageName, file?.name);

  let final_url = url?.split("?")[0];
  let success = true;
  await axios
    .put(url, file, {
      headers: { "Content-Type": file?.type },
    })
    .then(async (res) => {
      if (res.status.toString() === "200") {
        // return final_url;
      } else {
        success = false;
      }
    });
  if (success) {
    return final_url;
  }

  async function getURL(type, fileName) {
    let { data } = await QueryRequest(fileQuery, { type, fileName });
    return data.getUploadUrl.url;
  }
}

export function useIsRTL() {
  const localeRTLList = ["ar", "he"];
  let locale = i18n.language || "en";
  if (locale && localeRTLList.includes(locale)) {
    return { isRTL: true, alignLeft: "right", alignRight: "left" };
  }
  return { isRTL: false, alignLeft: "left", alignRight: "right" };
}

export const navigate = (path) => {
  historyRef.current.push(path);
};
export const redirect_page = (path) => {
  historyRef.current.push(path);
  // setTimeout(() => {
  //   window.location.reload();
  // }, 200);
};

export const navigateWithProps = (path, props) => {
  historyRef.current.push({
    pathname: path,
    props_state: props,
  });
};

export const navigateBack = () => {
  historyRef.current.goBack();
};

export function showNotification({ type, message, duration = 3 }) {
  switch (type) {
    case "success":
      antdMessage.success(message, duration);
      break;
    case "warn":
      antdMessage.warning(message, duration);
      break;
    case "error":
      antdMessage.error(message, duration);
      break;
    default:
      antdMessage.info(message, duration);
  }
}

export function showToast({ type, message, duration = 5000, id = uuidv4() }) {
  if (id && toast.isActive(id)) {
    return;
  }

  switch (type) {
    case "info":
      toast.info(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    case "success":
      toast.success(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    case "warn":
      toast.warn(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    case "error":
      toast.error(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      break;
    default:
      toast(message, {
        toastId: id,
        position: "top-right",
        autoClose: duration,
        closeOnClick: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
  }
}

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function getAmountFormat(amount) {
  if (amount) {
    return amount
      .toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })
      .replace("₹", "");
  } else {
    return `0.00`;
  }
}

export function getAmountFormatWithSymbol(amount) {
  if (amount) {
    return amount
      .toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        currencyDisplay: "symbol",
      })
      .trim();
  } else {
    return `0.00`;
  }
}

export const formatDate = (date) => {
  if (!date) {
    return "-";
  }
  return moment(date).format("DD/MM/YYYY");
};

export const formatDateTime = (date) => {
  if (!date) {
    return "-";
  }
  return moment(date).format("DD/MM/YYYY hh:mm a");
};

export const hasExceededDate = (checkDate) => {
  const currentDate = moment();
  const exceeded = currentDate.isAfter(moment(checkDate));
  return exceeded;
};

export const get_calendar_cell_color = (item) => {
  if (item?.date_slot.availability_color === "RED") {
    return "#E00202";
  } else if (item?.date_slot.availability_color === "GREEN") {
    return "#006B00";
  } else if (item?.date_slot.availability_color === "YELLOW") {
    return "#FFA400";
  }
};

export const get_calendar_progressbar_color = (
  waiting_quota,
  confirmed_quota,
  booked
) => {
  let total_quota = waiting_quota + confirmed_quota;
  const percentage = (booked / total_quota) * 100;
  return percentage;
};

export const get_progress_bar_filed_color = (color) => {
  if (color === "RED") {
    return "#940505";
  } else if (color === "GREEN") {
    return "#6Ac56A";
  } else {
    return "#905C00";
  }
};

export const get_progress_bar_un_filed_color = (color) => {
  if (color === "RED") {
    return "#FA7171";
  } else if (color === "GREEN") {
    return "#ACFFAC";
  } else {
    return "#B8914B";
  }
};

export const calculate_counts = (irumudi_calendar_items, type) => {
  const today = moment().format("DD-MM-YYYY");
  const yesterday = moment().subtract(1, "day").format("DD-MM-YYYY");
  const tomorrow = moment().add(1, "day").format("DD-MM-YYYY");

  const counts = {
    today: { booked: 0, arrived: 0, not_arrived: 0, excess: 0 },
    yesterday: { booked: 0, arrived: 0, not_arrived: 0, excess: 0 },
    tomorrow: { booked: 0, arrived: 0, not_arrived: 0, excess: 0 },
  };
  irumudi_calendar_items?.forEach((item) => {
    const item_date = moment(item.date).format("DD-MM-YYYY");
    const countType =
      item_date === today
        ? "today"
        : item_date === yesterday
        ? "yesterday"
        : item_date === tomorrow
        ? "tomorrow"
        : null;
    if (countType) {
      counts[countType].booked += item.count.booked || 0;
      counts[countType].arrived += item.count.arrived || 0;
      counts[countType].not_arrived += item.count.yet_to_enroll || 0;
      counts[countType].excess += item.count.excess || 0;
    }
  });
  return counts[type];
};

export const export_to_xlsx = (data, file_name) => {
  const style = {
    alignment: {
      horizontal: "center",
      vertical: "center",
    },
  };

  const ws = XLSX.utils.json_to_sheet(data, {
    header: Object.keys(data[0]),
    styles: { header: [style], cell: [style] },
    autoSize: true,
  });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const blob = new Blob([XLSX.write(wb, { bookType: "xlsx", type: "array" })], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `${file_name}.xlsx`);
};

export const om_sakthi_reports = (data) => {
  const paymentData = [
    // Your payment data here
    ...data,
  ];

  // Function to format date to DD/MM/YYYY
  const formatDate = (dateString) => {
    return moment(new Date(dateString)).format("DD/MM/YYYY");
  };

  // Group payment data by payment mode
  const groupedData = paymentData.reduce((acc, entry) => {
    const { payment_mode, enrollment_datetime, ...details } = entry;
    const formattedDate = formatDate(enrollment_datetime);

    acc[payment_mode] = acc[payment_mode] || [];
    acc[payment_mode].push({ ...details, enrollment_datetime: formattedDate });
    return acc;
  }, {});

  // Create Excel workbook and add sheets
  const workbook = XLSX.utils.book_new();

  for (const paymentMode in groupedData) {
    const sheetData = groupedData[paymentMode];
    const sheet = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, sheet, paymentMode);
  }

  // Save the workbook to a file
  const excelFileName = "om_sakthi_report.xlsx";
  XLSX.writeFile(workbook, excelFileName);
};

export const arrange_xlsx_report_data = (data_items) => {
  const rearranged_data = [];
  for (let i = 0; i < data_items?.length; i++) {
    let row_data = {
      ["S.No"]: i,
      ...data_items[i],
    };
    rearranged_data.push(row_data);
  }
  return rearranged_data;
};

export const arrange_xlsx_counter_payment_data = (
  calculated_count,
  counter,
  counter_list,
  selected_date
) => {
  const rearranged_data = [];

  let row_data_counter = {
    ["S.No"]: 1,
    Title: "Counters",

    Counters: counter
      ? counter
      : counter_list?.map((counter) => counter?.name).join(","),
    Title: "Date",
    Date: selected_date?.length === 0 ? "All Date" : selected_date?.join(","),

    // Date: { selected_date },
    "No of irumudi": calculated_count?.no_of_irumudi,
    Card: calculated_count?.received_card_amount,
    Cash: calculated_count?.received_cash_amount,
    DD: calculated_count?.received_dd_amount,
    UPI: calculated_count?.received_upi_amount,
    Total: calculated_count?.total_amount,
  };
  rearranged_data.push(row_data_counter);

  // let row_data_date = {};
  // rearranged_data.push(row_data_date);

  // let row_data_count = {
  //   ["S.No"]: 3,
  //   Title: "Count",
  //   // Counters: "",
  //   // Date: "",
  //   "No of irumudi": count?.no_of_irumudi,
  //   "Total Amount": count?.received_amount,
  // };
  // rearranged_data.push(row_data_count);

  return rearranged_data;
};

export const arrange_and_export_xlsx_om_sakthi_form_report_data = (
  om_sakthi_report,
  om_shakthi_payment_items,
  ticket_summary,
  payment_mode_wise_summary,
  dd_data,
  card_data,
  upi_data
) => {
  const omSakthiData = om_sakthi_report[0] || {};

  const ticket_summary_data = [];

  let row_data = {
    ["Normal Ticket Devotees"]: omSakthiData.base_total_devotees || 0,
    ["Normal Ticket Received Amount"]: omSakthiData.base_total_amount || 0,
    ["VIP Ticket Devotees"]: omSakthiData.vip_total_devotees || 0,
    ["VIP Ticket Received Amount"]: omSakthiData.vip_total_amount || 0,
    ["Total Devotees"]: omSakthiData.total_devotees || 0,
    ["Total Amount"]: omSakthiData.total_amount || 0,
  };

  ticket_summary_data.push(row_data);
  const payment_mode_wise_summary_data = [];
  row_data = {
    // ["Cash Devotees"]: payment_mode_wise_summary?.normal_ticket_dev || 0,
    ["Cash Amount"]: omSakthiData?.total_cash_amount || 0,
    // ["DD Devotees"]: omSakthiData?.normal_ticket_dev || 0,
    ["DD Amount"]: omSakthiData?.total_dd_amount || 0,
    // ["Card Devotees"]: omSakthiData?.normal_ticket_dev || 0,
    ["Card Amount"]: omSakthiData?.total_upi_amount || 0,
    // ["UPI Devotees"]: omSakthiData?.normal_ticket_dev || 0,
    ["UPI Amount"]: omSakthiData?.total_card_amount || 0,
    // ["Total Devotees"]: omSakthiData?.normal_ticket_dev || 0,
    ["Total Amount"]: omSakthiData?.total_amount || 0,
  };
  payment_mode_wise_summary_data.push(row_data);

  const ddPaymentItems = om_shakthi_payment_items?.filter(
    (item) => item.payment_mode === "dd"
  );
  console.log("ddPaymentItems", ddPaymentItems);

  const dd_total_amount = ddPaymentItems?.reduce(
    (acc, item) => acc + (item.amount || 0),
    0
  );

  const cardPaymentItems = om_shakthi_payment_items?.filter(
    (item) => item.payment_mode === "card"
  );

  var card_total_amount = cardPaymentItems?.reduce(
    (acc, item) => acc + (item.amount || 0),
    0
  );
  console.log("card_total_amount", card_total_amount);

  const upiPaymentItems = om_shakthi_payment_items?.filter(
    (item) => item.payment_mode === "upi"
  );

  const total_upi_amount = upiPaymentItems?.reduce(
    (acc, item) => acc + (item.amount || 0),
    0
  );

  const dd_excel_data = [];
  if (ddPaymentItems?.length) {
    for (let i = 0; i < ddPaymentItems.length; i++) {
      let data = ddPaymentItems[i];
      row_data = {
        ["Sl.No"]: i + 1,
        ["Receipt Number"]: data?.receipt_number || "-",
        ["Reference Number"]: data?.dd_reference || "-",
        ["DD Date"]: moment(data?.dd_datetime).format("DD/MM/YYYY"),
        ["Amount"]: data?.amount || 0,
      };
      dd_excel_data.push(row_data);
    }
    const grandTotalDDRow = {
      ["Sl.No"]: "Total",
      ["Receipt Number"]: "",
      ["Reference Number"]: "",
      ["DD Date"]: "",
      ["Amount"]: dd_total_amount,
    };
    dd_excel_data.push(grandTotalDDRow);
  }

  const card_excel_data = [];
  if (cardPaymentItems?.length) {
    for (let i = 0; i < cardPaymentItems.length; i++) {
      let data = cardPaymentItems[i];
      row_data = {
        ["Sl.No"]: i + 1,
        ["Receipt Number"]: data?.receipt_number || "-",
        ["Reference Number"]: data?.pos_receipt || "-",
        ["Amount"]: data?.amount || 0,
      };
      card_excel_data.push(row_data);
    }
    const grandTotalCardRow = {
      ["Sl.No"]: "Total",
      ["Receipt Number"]: "",
      ["Reference Number"]: "",
      ["Amount"]: `${card_total_amount}`,
    };
    card_excel_data.push(grandTotalCardRow);
  }

  const upi_excel_data = [];
  if (upiPaymentItems?.length) {
    for (let i = 0; i < upiPaymentItems.length; i++) {
      let data = upiPaymentItems[i];
      row_data = {
        ["Sl.No"]: i + 1,
        ["Receipt Number"]: data?.receipt_number || "-",
        ["Reference Number"]: data?.pos_receipt || "-",
        ["Amount"]: data?.amount || 0,
      };
      upi_excel_data.push(row_data);
    }
    const grandTotalUPIRow = {
      ["Sl.No"]: "Total",
      ["Receipt Number"]: "",
      ["Reference Number"]: "",
      ["Amount"]: total_upi_amount,
    };
    upi_excel_data.push(grandTotalUPIRow);
  }
  const style = {
    alignment: {
      horizontal: "center",
      vertical: "center",
    },
  };
  const wb = XLSX.utils.book_new();

  const ts_ws = XLSX.utils.json_to_sheet(ticket_summary_data, {
    header: Object.keys(ticket_summary_data[0]),
    styles: { header: [style], cell: [style] },
    autoSize: true,
  });
  XLSX.utils.book_append_sheet(wb, ts_ws, "Ticket Summary");

  const pms_ws = XLSX.utils.json_to_sheet(payment_mode_wise_summary_data, {
    header: Object.keys(payment_mode_wise_summary_data[0]),
    styles: { header: [style], cell: [style] },
    autoSize: true,
  });
  XLSX.utils.book_append_sheet(wb, pms_ws, "Payment Mode Summary");
  if (dd_excel_data?.length) {
    const dd_ws = XLSX.utils.json_to_sheet(dd_excel_data, {
      header: Object.keys(dd_excel_data[0]),
      styles: { header: [style], cell: [style] },
      autoSize: true,
    });
    XLSX.utils.book_append_sheet(wb, dd_ws, "DD");
  }
  if (card_excel_data?.length) {
    const card_ws = XLSX.utils.json_to_sheet(card_excel_data, {
      header: Object.keys(card_excel_data[0]),
      styles: { header: [style], cell: [style] },
      autoSize: true,
    });
    XLSX.utils.book_append_sheet(wb, card_ws, "Card");
  }
  if (upi_excel_data?.length) {
    const upi_ws = XLSX.utils.json_to_sheet(upi_excel_data, {
      header: Object.keys(upi_excel_data[0]),
      styles: { header: [style], cell: [style] },
      autoSize: true,
    });
    XLSX.utils.book_append_sheet(wb, upi_ws, "UPI");
  }
  const blob = new Blob([XLSX.write(wb, { bookType: "xlsx", type: "array" })], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `om_sakthi_report.xlsx`);
};

export const print_function = (
  print_content_class,
  button_id,
  set_show_print_button
) => {
  set_show_print_button(false);
  const content_to_print = document.querySelector(`.${print_content_class}`);
  const print_button = document.getElementById(`${button_id}`);

  print_button.style.display = "none";
  const new_window = window.open("", "_blank", "width=800, height=900");
  new_window.document.write(content_to_print?.innerHTML);

  new_window.print();

  new_window.addEventListener("unload", () => {
    set_show_print_button(true);
    print_button.style.display = "flex";
    content_to_print.classList.remove("bordered-content");
  });
};

export const format_duration = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remaining_minutes = minutes % 60;

  const formatted_hours = hours % 12 || 12;
  const period = hours < 12 ? "AM" : "PM";

  const formatted_minutes =
    remaining_minutes < 10 ? `0${remaining_minutes}` : remaining_minutes;

  return `${formatted_hours}:${formatted_minutes} ${period}`;
};

export const id_proof_validation = (selected_id_proof, value) => {
  if (!value) {
    return Promise.reject(t("Please enter ID number!"));
  }

  switch (selected_id_proof) {
    case "Aadhaar Card":
      if (!/^\d{12}$/.test(value)) {
        return Promise.reject(t("Invalid Aadhaar Card number!"));
      }
      break;
    case "Pancard":
      if (!/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(value)) {
        return Promise.reject(t("Invalid Pancard number!"));
      }
      break;
    case "Driving License":
      if (!value.match(/^[\w\d]{5,15}$/)) {
        return Promise.reject(t("Invalid Driving License number!"));
      }
      break;
    case "Bank Passbook":
      if (!value.match(/^[\w\d]{5,20}$/)) {
        return Promise.reject(t("Invalid Bank Passbook number!"));
      }
      break;
    case "Passport":
      if (!value.match(/^[A-Z0-9]{7,9}$/)) {
        return Promise.reject(t("Invalid Passport number!"));
      }
      break;
    case "Ration Card":
      if (!value.match(/^[\w\d]{8,15}$/)) {
        return Promise.reject(t("Invalid Ration Card number!"));
      }
      break;
    case "Student ID":
      if (!value.match(/^[\w\d]{6,12}$/)) {
        return Promise.reject(t("Invalid Student ID number!"));
      }
      break;
    case "Government ID":
      if (!value.match(/^[\w\d]{8,20}$/)) {
        return Promise.reject(t("Invalid Government ID number!"));
      }
      break;
    default:
      break;
  }

  return Promise.resolve();
};

export const calculate_counter_payment_report = (counter_payment_items) => {
  console.log("counter_payment_items", counter_payment_items);
  let result = {
    total_amount: 0,
    received_cash_amount: 0,
    received_upi_amount: 0,
    received_card_amount: 0,
    received_dd_amount: 0,
    no_of_irumudi: 0,
  };
  if (counter_payment_items) {
    counter_payment_items.map((counter, index) => {
      result.total_amount += counter.sum;
      result.received_cash_amount += counter.cash_amount;
      result.received_upi_amount += counter.upi_amount;
      result.received_card_amount += counter.card_amount;
      result.received_dd_amount += counter.dd_amount;
      result.no_of_irumudi += counter?.total_devotees;
    });
  }
  return result;
};

export const total_amount_details = (om_sakthi_report_items) => {
  console.log("om_sakthi_report_items", om_sakthi_report_items);

  if (!om_sakthi_report_items || om_sakthi_report_items.length === 0) {
    return {
      total_amount: 0,
      cash_amount: 0,
      dd_amount: 0,
      card_amount: 0,
      upi_amount: 0,
      total_devotees: 0,
    };
  }

  return om_sakthi_report_items.reduce(
    (result, amount_details) => {
      result.total_amount += Number(amount_details.total_amount) || 0;
      result.cash_amount += Number(amount_details.cash_amount) || 0;
      result.dd_amount += Number(amount_details.dd_amount) || 0;
      result.card_amount += Number(amount_details.card_amount) || 0;
      result.upi_amount += Number(amount_details.upi_amount) || 0;
      result.total_devotees += Number(amount_details.total_devotees) || 0;

      return result;
    },
    {
      total_amount: 0,
      cash_amount: 0,
      dd_amount: 0,
      card_amount: 0,
      upi_amount: 0,
      total_devotees: 0,
    }
  );
};

export const calculate_user_wise_payment_report = (counter_user_wise_items) => {
  console.log("counter_user_wise_items", counter_user_wise_items);
  let result = {
    total_enrolment: 0,
    total_devotees: 0,
    total_cash_amount: 0,
    total_dd_enrolments: 0,
    total_dd_amount: 0,
    total_card_enrolments: 0,
    total_card_amount: 0,
    total_upi_enrolments: 0,
    total_upi_amount: 0,
    total_amount: 0,
  };
  if (counter_user_wise_items) {
    counter_user_wise_items.map((counter, index) => {
      result.total_enrolment += counter.total_enrolments;
      result.total_devotees += counter.total_devotees;
      result.total_cash_amount += counter.cash_amount;
      result.total_dd_enrolments += counter.dd_enrolments;
      result.total_dd_amount += counter.dd_amount;
      result.total_card_enrolments += counter?.card_enrolments;
      result.total_card_amount += counter?.card_amount;
      result.total_upi_enrolments += counter?.upi_enrolments;
      result.total_upi_amount += counter?.upi_amount;
      result.total_amount += counter?.sum;
    });
  }
  return result;
};

export const sort_date_slots = (date_slot_items) => {
  const sorted = date_slot_items?.slice().sort((a, b) => {
    const dateA = new Date(a.date_string);
    const dateB = new Date(b.date_string);

    return dateA - dateB;
  });
  return sorted;
};

// export async function initiatePayment({ booking_id, on_success, on_error }) {
//   try {
//     debugger;
//     const [paymentUrl, queryParams] = booking_id.split("?");
//     console.log("Payment URL:", paymentUrl, "Query Params:", queryParams);

//     const response = await axios.get(paymentUrl + "?" + queryParams, null, {
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//       },
//     });
//     console.log("response", response);
//     if (response.status === 200 && response.data) {
//       debugger;
//       on_success(response.data.booking_id || "Unknown booking ID");
//     } else {
//       on_error("Unexpected response format from the server.");
//     }
//   } catch (error) {
//     on_error(error.message || "Payment processing failed.");
//   }
// }

export async function initiatePayment({ booking_id, on_success, on_error }) {
  try {
    // Split the booking_id into payment URL and query parameters

    const [paymentUrl, queryParams] = booking_id.split("?");

    console.log("Payment URL:", paymentUrl, "Query Params:", queryParams);

    // Create a complete URL with the base payment URL and the query parameters

    const fullPaymentUrl = `${paymentUrl}?${queryParams}`;

    // Redirect the user to the ICICI payment page

    window.location.href = fullPaymentUrl; // This will redirect to ICICI payment gateway
  } catch (error) {
    on_error(error.message || "Payment initiation failed.");
  }
}

export const send_response_to_printer_service = async (
  enrolled_id,
  session_id,
  set_is_loading,
  TicketsPrinted,
  close_token_printer_modal,
  set_token_printer_response
) => {
  set_is_loading(true);

  const headers = {
    "content-type": "application/json",
    session_id: session_id,
  };
  const data = {
    query: get_enrolment_detail_query,
    variables: {
      id: enrolled_id,
    },
  };

  let response = await axios.post(API_URL, data, {
    headers,
  });
  if (response?.data?.data?.get_enrolment_detail) {
    let status = response?.data?.data?.get_enrolment_detail?.status;
    let enrolment_position =
      response?.data?.data?.get_enrolment_detail?.enrolment_position;
    let is_tickets_printed =
      response?.data?.data?.get_enrolment_detail?.is_tickets_printed;
    let arrived_count =
      response?.data?.data?.get_enrolment_detail?.arrived_count;
    let reprintable_ticket_list =
      response?.data?.data?.get_enrolment_detail?.reprintable_ticket_list;
    if (status === "paid" || status === "spot_paid" || status === "prepaid") {
      let date = moment(
        response?.data?.data?.get_enrolment_detail?.enrolled_date_slot.date
      )
        .tz("Asia/Kolkata")
        .format("DD-MM-YYYY");
      let is_vip = response?.data?.data?.get_enrolment_detail?.booking.is_vip;
      let security_code =
        response?.data?.data?.get_enrolment_detail?.enrolled_date_slot
          .security_code;
      let mandram_code =
        response?.data?.data?.get_enrolment_detail?.booking?.mandram.code;
      let mandram_name =
        response?.data?.data?.get_enrolment_detail?.booking?.mandram.name;
      let receipt_number =
        response?.data?.data?.get_enrolment_detail?.booking?.booking_reference;
      let leader_mobile =
        response?.data?.data?.get_enrolment_detail?.booking?.booking_user
          ?.mobile;
      let json = {
        arrived_count,
        is_tickets_printed,
        reprintable_ticket_list,
        enrolment_position,
        date,
        security_code,
        mandram_code,
        mandram_name,
        receipt_number,
        leader_mobile,
        is_vip,
      };
      console.log(json, "json");
      try {
        const response = await axios.post(
          "http://localhost:7878/print-tickets-directly",
          json,
          { headers }
        );
        if (response.data === "printing started") {
          await TicketsPrinted();
          close_token_printer_modal();
          console.log("response.data", response.data);
        } else {
          toast.error(response.data);
        }
        set_token_printer_response(response?.data);
      } catch (error) {
        console.error("print-tickets-error:", error);
      }
    }
  }
};
