import React, { useEffect, useMemo } from "react";
import { DatePicker, Row, Space } from "antd";
import moment from "moment";
import CustomButton from "./custom_button";
import {
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../services/redux";
import { useDispatch } from "react-redux";
import _orderBy from "lodash/orderBy";

const { RangePicker } = DatePicker;

const CustomDateSearch = ({ set_selected_date, get_list }) => {
  const dispatch = useDispatch();
  const { items: date_slot_items } = useDynamicSelector(
    "get_season_date_slot_list"
  );

  const filtered_items = useMemo(
    () => _orderBy(date_slot_items, "date", "asc"),
    [date_slot_items]
  );

  const get_season_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const disabled_date = (current) => {
    return (
      current &&
      (current < moment(filtered_items[0]?.date) ||
        current >
          moment(filtered_items[filtered_items?.length - 1]?.date).endOf("day"))
    );
  };

  const handle_date_range_change = (dates, dateStrings) => {
    if (dates && dates.length > 0) {
      set_selected_date({
        from_date: moment(dates[0]).toISOString(),
        to_date: moment(dates[1]).toISOString(),
      });
    } else {
      set_selected_date({});
    }
  };

  const on_search = () => {
    get_list();
  };

  useEffect(() => {
    get_season_date_slot_list();
  }, []);

  useEffect(() => {
    set_selected_date({
      from_date: moment(),
      to_date: moment(filtered_items[filtered_items?.length - 1]?.date).endOf(
        "day"
      ),
    });
  }, []);

  return (
    <Row style={{ width: "100%", marginTop: "20px" }}>
      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <RangePicker
          onChange={handle_date_range_change}
          disabledDate={disabled_date}
          defaultValue={[
            moment(),
            moment(filtered_items[filtered_items?.length - 1]?.date).endOf(
              "day"
            ),
          ]}
        />
        <CustomButton
          button_name={"Get"}
          bg_color={"dodgerBlue"}
          icon={""}
          onclick_button={on_search}
          rounded={true}
        />
      </Space>
    </Row>
  );
};

export default CustomDateSearch;
