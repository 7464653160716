import { gql } from "@apollo/client";

export const mutation_update_booking_payment = gql`
  mutation update_booking_payment_mode(
    $data: update_booking_payment_mode_input
  ) {
    update_booking_payment_mode(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const send_sms_to_mobile_phone = gql`
  mutation send_sms_to_number($data: send_sms_to_number_input) {
    send_sms: send_sms_to_number(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
