export default {
  primary: "#FF005C",
  secondary: "#8F00FF",
  primaryDark: "#2759ff",
  primaryLite: "#637aff99",
  black: "#000",
  white: "white",
  accent: "#112233",
  green: "#60c5a8",
  green2: "#036B2F",
  green3: "#0b9e12",
  light: "#EEEEEE",
  dark: "#333",
  gray: "#CCCCCC",
  red: "#ff2f68",
  lightRed: "#ff4f7e",
  darkRed: "#BF0505",
  purple: "#8f06e4",
  skyBlue: "skyblue",
  yellow: "#f8c907",
  pink: "#ff4c98",
  lightPink: "#f7e4ed",
  gold: "gold",
  orange: "#f85f07",
  line: "#282C35",
  gray: "#CCCCCC",
  darkGray: "#999999",
  dodgerBlue: "dodgerBlue",

  darkOverlayColor: "rgba(0, 0, 0, 0.4)",
  darkOverlayColor2: "rgba(0, 0, 0, 0.8)",
  lightOverlayColor: "rgba(255, 255, 255, 0.6)",
  primaryAlpha: "rgba(99, 122, 255, 0.15)",
  redAlpha: "rgba(255, 84, 84, 0.15)",
  greenAlpha: "rgba(96, 197, 168, 0.15)",
  yellowAlpha: "rgba(248, 201, 7, 0.15)",
  orangeAlpha: "rgba(248, 95, 7, 0.15)",
  purpleAlpha: "rgba(146, 6, 228, 0.15)",

  gradientForm: "#A376F1",

  // bags background colors
  bag1Bg: "#ea7a72",
  bag2Bg: "#c2c5d1",
  bag3Bg: "#82a7c9",
  bag4Bg: "#d49d8f",
  bag5Bg: "#ccd9c6",
  bag6Bg: "#767676",
  bag7Bg: "#d1c8c3",
  bag8Bg: "#dca47f",
  bag9Bg: "#eb849c",
  bag10Bg: "#979dc1",
  bag11Bg: "#c7d3c0",

  // table action colors
  actionEdit: "#179093",
  actionDelete: "#f56356",
};
