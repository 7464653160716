import React from "react";
import { Card, Col, Row, Space, Typography } from "antd";
import { AiOutlineUser } from "react-icons/ai";
import { DASHBOARD_USER } from "../../../helpers/image_constants";
import { height } from "styled-system";

const DashboardCard = (props) => {
  const { sub_title, color, width, height, count } = props;

  return (
    <Row
      style={{
        backgroundColor: color,
        height: height,
        width: width,
        borderRadius: "10px",
        // cursor: "pointer",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        padding: "14px",
      }}
    >
      <Col style={{ width: "136px" }}>
        <Space style={{ marginTop: "80px" }}>
          <img
            src={DASHBOARD_USER}
            style={{
              width: "60px",
              height: "60px",
              display: "flex",
              color: "#1e2c55",
              opacity: "0.3",
            }}
          />
        </Space>
      </Col>
      <Col style={{ width: "136px" }}>
        <Typography
          style={{
            fontSize: "15px",
            fontWeight: "5px",
            color: "#1e2c55",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {sub_title}
        </Typography>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#1e2c55",
            marginTop: "78px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {count?.toLocaleString("en-IN") || 0}
        </Typography>
      </Col>
    </Row>
  );
};

export default DashboardCard;
