import { Button, Modal } from "antd";
import React, { useState } from "react";
import PageForm from "./page_form";
import { IoMdClose } from "react-icons/io";

const PageModal = (props) => {
  const {
    modal_open,
    handle_close,
    handle_submit,
    action_type,
    id,
    action_item,
    set_expanded_row_key,
    link_type,
  } = props;
  const [loading, set_submit_loading] = useState(false);

  return (
    <Modal
      title={
        action_type === "add_page"
          ? "Add Page"
          : action_type === "edit_page"
          ? "Edit Page"
          : action_type === "add_ui_component"
          ? "Add page Component"
          : "Edit page Component"
      }
      open={modal_open}
      onCancel={handle_close}
      footer={
        <>
          <Button onClick={handle_close}>Cancel</Button>
          <Button
            key="confirm"
            type="primary"
            htmlType="submit"
            form="page_form"
            onClick={handle_submit}
            loading={loading}
          >
            {action_type === "add_page"
              ? "Add"
              : action_type === "edit_page"
              ? "Update"
              : action_type === "add_ui_component"
              ? "Add"
              : "Update"}
          </Button>
        </>
      }
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <PageForm
        close={handle_close}
        action_type={action_type}
        get_id={id}
        action_item={action_item}
        set_loading={set_submit_loading}
        set_expanded_row_key={set_expanded_row_key}
        link_type={link_type}
      />
    </Modal>
  );
};
export default PageModal;
