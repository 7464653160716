import { Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  mutation_get_unassigned_pos_list,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";

const PosReferenceId = (props) => {
  const { selected_items } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: pos_list } = useDynamicSelector("get_unassigned_pos_list");

  const custom_filter_option = (input, option) => {
    const inputValue = input.toLowerCase();
    const optionLabel = option.props.children.toLowerCase();
    return optionLabel.indexOf(inputValue) === 0;
  };

  const get_all_unassigned_pos_list = () => {
    let key = [{ key: "get_unassigned_pos_list", loading: true }];
    let query = mutation_get_unassigned_pos_list;
    let variables = {
      filter: {
        counter_id: selected_items.id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_all_unassigned_pos_list();
  }, []);

  return (
    <Form.Item name="pos_id" label={t("pos_reference")}>
      <Select
        placeholder="Select Pos"
        allowClear
        showSearch
        filterOption={custom_filter_option}
      >
        {pos_list?.map((option, index) => (
          <Select.Option key={option.id} value={option.id}>
            {option.name}/{option.terminal_id}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
export default PosReferenceId;
