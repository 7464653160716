import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const GroupLeader = () => {
  const { t } = useTranslation();
  return (
    <Form.Item
      label={t("group_leader_name")}
      name="group_leader"
      rules={[
        {
          required: true,
          message: "Please input your leader!",
        },
      ]}
    >
      <Input
        style={{
          width: "100%",
        }}
        placeholder="Group Leader"
      />
    </Form.Item>
  );
};

export default GroupLeader;
