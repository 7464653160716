import { gql } from "@apollo/client";

export const get_enrolment_detail_query = `
  query get_enrolment_detail($id: String!) {
    get_enrolment_detail(id: $id) {
      arrived_count
      status
      is_tickets_printed
      enrolment_position
      reprintable_ticket_list {
        ticket_number
      }
      enrolled_date_slot {
        date
        security_code
      }
      booking {
        is_vip
        booking_reference
        mandram {
          name
          code
        }
        booking_user {
          mobile
        }
      }
    }
  }
`;
