import { Form, Input } from "antd";
import React from "react";

const BookingUpiPosReceipt = ({ payment_mode }) => {
  //   const on_change = (e) => {
  //   };
  return (
    <Form.Item
      label={
        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
          UPI Receipt No
        </span>
      }
      name="pos_receipt"
      rules={
        payment_mode === "upi"
          ? [
              {
                required: true,
                message: "Please enter Ref number",
              },
            ]
          : []
      }
      labelCol={{ span: 24 }}
    >
      <Input style={{ width: "100%" }} />
    </Form.Item>
  );
};

export default BookingUpiPosReceipt;
