import { gql } from "@apollo/client";

export const send_sms_to_mobile = gql`
  mutation send_sms_to_number($data: send_sms_to_number_input) {
    send_sms_to_number(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
