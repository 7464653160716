import React from "react";
import CustomModal from "../../common/custom_modal";
import PosForm from "../form/pos_form";
import { useDynamicSelector } from "../../../../services/redux";
import { Button, Modal } from "antd";
import { IoMdClose } from "react-icons/io";

const PosModal = (props) => {
  const { header_type, edit_model_visible, set_edit_modal_visible } = props;

  const { loading: create_pos_loading } = useDynamicSelector("create_pos");

  return (
    <Modal
      width={500}
      title={header_type === "edit_pos" ? "Edit POS" : "Add POS"}
      open={edit_model_visible}
      destroyOnClose={true}
      onCancel={() => set_edit_modal_visible(false)}
      footer={[
        <Button key="cancel" onClick={() => set_edit_modal_visible(false)}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          htmlType="submit"
          form="pos_form"
          loading={create_pos_loading}
        >
          Save
        </Button>,
      ]}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <PosForm {...props} />
    </Modal>
  );
};

export default PosModal;
