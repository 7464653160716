import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDeleteModal from "../components/common/custom_delete_modal";
import CustomPageTitle from "../components/common/custom_page_title";
import { Card, Row } from "antd";
import CustomButton from "../components/common/custom_button";
import {
  dynamicRequest,
  mutation_delete_bank,
  query_get_bank_list,
} from "../../services/redux/slices";
import { useDispatch } from "react-redux";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import BankList from "../components/bank_master/bank_list";
import BankModal from "../components/bank_master/bank_modal";

const Bank = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [bank_modal_open, set_bank_modal_open] = useState(false);
  const [bank_modal_title, set_bank_modal_title] = useState("");
  const [selected_items, set_selected_items] = useState("");
  const [delete_modal_visible, set_delete_modal_visible] = useState(false);

  const [current_limit, setCurrentLimit] = useState(10);
  const [current, setCurrent] = useState(1);

  const add_bank = () => {
    set_bank_modal_open(true);
    set_bank_modal_title("add");
    set_selected_items("");
  };
  const handle_delete_bank = (record) => {
    set_delete_modal_visible(true);
    set_selected_items(record);
  };
  const handle_bank_modal_close = () => {
    set_bank_modal_open(false);
  };

  const get_all_bank_list = () => {
    let key = [{ key: "get_bank_list", loading: true }];
    let query = query_get_bank_list;
    let variables = {
      pagination: {
        page_limit: current_limit,
        page_number: current,
      },
    };

    dispatch(dynamicRequest(key, query, variables));
  };

  const delete_one_bank = (record) => {
    let key = [{ key: "delete_bank", loading: true }];
    let query = mutation_delete_bank;
    let variables = {
      id: selected_items.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_all_bank_list();
  }, []);

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row style={{ marginLeft: "10px" }}>
          <CustomPageTitle title={t("bank_title")} />
        </Row>

        <Row style={{ margin: "8px", marginRight: "23px" }}>
          {/* <CustomBreadCrumbs pages={"Home/Bank"} /> */}
        </Row>
      </Row>

      <Card
        style={{
          width: "96%",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Row
            style={{
              // alignItems: "flex-end",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <CustomButton
              button_name={t("add_bank")}
              onclick_button={add_bank}
            />
          </Row>
          <BankList
            get_all_bank_list={get_all_bank_list}
            set_bank_modal_open={set_bank_modal_open}
            set_bank_modal_title={set_bank_modal_title}
            set_selected_items={set_selected_items}
            handle_delete_bank={handle_delete_bank}
          />
        </Row>
        <BankModal
          bank_modal_open={bank_modal_open}
          handle_bank_modal_close={handle_bank_modal_close}
          bank_modal_title={bank_modal_title}
          selected_items={selected_items}
        />

        <CustomDeleteModal
          selector_name={"delete_bank"}
          get_all={get_all_bank_list}
          delete_api={delete_one_bank}
          record={selected_items}
          text={t("bank")}
          delete_modal_visible={delete_modal_visible}
          set_delete_modal_visible={set_delete_modal_visible}
        />
      </Card>
    </>
  );
};
export default Bank;
