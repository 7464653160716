import { Card, Col, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import DashboardTitle from "../components/dashboard/dashboard_title";
import RefreshButton from "../components/dashboard/refresh_button";
import DashboardCard from "../components/dashboard/dashbord_card";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  query_dashboard_list,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import CountDetailCard from "../components/irumudi_calendar/detail_cards/count_detail_card";
import { retrieveItem } from "../../helpers/functions";
import DashboardUserDashboard from "../components/dashboard/user_dashboard";
import UserDashboard from "../components/dashboard/user_dashboard";
import { query_calendar_dashboard } from "../../services/redux/slices/graphql/graphql_irumudi_calendar";

export const TodayDashboard = () => {
  const role = retrieveItem("role");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [today_dashboard_count, set_today_dashboard_count] = useState();
  const { today_count, loading: dashboard_loading } = useDynamicSelector(
    "get_overall_dashboard"
  );

  const get_dashboard_details = () => {
    let key = [{ key: "get_overall_dashboard", loading: true }];
    let query = query_dashboard_list;
    dispatch(dynamicRequest(key, query));
  };

  const get_calendar_data = () => {
    let keys = [{ key: "get_dashboard_calendar", loading: true }];
    let query = query_calendar_dashboard;
    let variable = {};
    dispatch(dynamicRequest(keys, query, variable));
  };

  const handle_refresh = () => {
    get_dashboard_details();
    get_calendar_data();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      get_dashboard_details();
      get_calendar_data();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    get_dashboard_details();
    get_calendar_data();
  }, []);

  useEffect(() => {
    if (today_count) {
      set_today_dashboard_count(today_count);
    }
  }, [today_count]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CustomPageTitle title={t("today_dashboard_title")} />

      <Card
        style={{
          margin: "auto",
          width: "98%",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Typography style={{ color: "#909090" }}>
            {t("dashboard_text")}
          </Typography>
        </div>
        <Row
          style={{
            gap: "40px",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: "40px",
          }}
        >
          <div
            style={{
              width: "73%",
              height: "90%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <DashboardCard
              sub_title="TODAY"
              color="#fae9e4"
              height="170px"
              width="300px"
              count={today_dashboard_count}
            />
          </div>
          <RefreshButton get_dashboard={handle_refresh} />
        </Row>
        <div>
          <UserDashboard />
        </div>
      </Card>
    </div>
  );
};
export default TodayDashboard;
