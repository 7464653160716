import React from "react";
import { useDynamicSelector } from "../../../services/redux";

const VipEnrollmentPaymentTable = () => {
  const {
    total_cash_amount,
    total_card_amount,
    total_dd_amount,
    total_upi_amount,
    total_arrived_count,
    total_amount,
    total_prepaid_card_amount,
    total_prepaid_upi_amount,
    total_prepaid_arrived_count,
    total_prepaid_amount,
  } = useDynamicSelector("get_closure_summary");

  const total_devotees = total_arrived_count + total_prepaid_arrived_count;
  return (
    <table
      style={{
        width: "100%",
        border: "0.3px solid gray",
      }}
    >
      <thead>
        <tr style={{ backgroundColor: "#275670", color: "white" }}>
          <td
            style={{
              border: "0.3px solid gray",
              textAlign: "center",
            }}
          >
            Total Amount
          </td>
          <td
            style={{
              border: "0.3px solid gray",
              textAlign: "center",
            }}
          >
            ₹ {total_amount || "0"}
          </td>
          <td
            style={{
              border: "0.3px solid gray",
              textAlign: "center",
            }}
          >
            Total Devotees
          </td>
          <td
            style={{
              border: "0.3px solid gray",
              textAlign: "center",
            }}
          >
            {total_arrived_count || "0"}
          </td>
        </tr>
      </thead>
      <tbody style={{ height: "100px" }}>
        <tr>
          <td
            style={{
              border: "0.3px solid gray",
              textAlign: "center",
              width: "25%",
            }}
          >
            CASH : ₹ {total_cash_amount || "0"}
          </td>

          <td
            style={{
              border: "0.3px solid gray",
              padding: "3px",
              textAlign: "center",
              width: "25%",
            }}
          >
            DD : ₹ {total_dd_amount || "0"}
          </td>
          <td
            style={{
              borderRight: "1px solid gray",
              padding: "3px",
              textAlign: "center",
              width: "25%",
            }}
          >
            UPI : ₹ {total_upi_amount || "0"}
          </td>
          <td
            style={{
              padding: "3px",
              textAlign: "center",
              width: "25%",
            }}
          >
            CARD : ₹ {total_card_amount || "0"}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default VipEnrollmentPaymentTable;
