import { gql } from "@apollo/client";
export const query_update_profile = gql`
  mutation update_user($data: update_user_input) {
    update_user(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_pos = gql`
  query {
    get_unassigned_pos_list {
      items {
        id
        name
        terminal_id
      }
    }
  }
`;

export const query_user = gql`
  query {
    get_user {
      name
      mobile
      email
      pos_id
    }
  }
`;
