import { gql } from "@apollo/client";

export const get_feedback_question_list = gql`
  query get_feedback_question_list(
    $filter: get_feedback_question_list_filter_input
    $sort: sort_input
    $pagination: pagination_input
  ) {
    get_feedback_question_list(
      filter: $filter
      sort: $sort
      pagination: $pagination
    ) {
      items {
        id
        question
        type
        feedback_id
        is_active
        options {
          id
          option
          is_active
          feedback_question_id
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_feedback_question_list_user = gql`
  query get_feedback_question_list(
    $filter: get_feedback_question_list_filter_input
    $sort: sort_input
    $pagination: pagination_input
  ) {
    get_feedback_question_list_user: get_feedback_question_list(
      filter: $filter
      sort: $sort
      pagination: $pagination
    ) {
      items {
        id
        question
        type
        feedback_id
        is_active
        options {
          id
          option
          is_active
          feedback_question_id
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const create_feedback_question__mutation = gql`
  mutation create_feedback_question($data: create_feedback_question_input!) {
    create_feedback_question(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const update_feedback_question__mutation = gql`
  mutation update_feedback_question(
    $id: String!
    $data: update_feedback_question_input!
  ) {
    update_feedback_question(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const delete_feedback_question__mutation = gql`
  mutation delete_feedback_question($id: String!) {
    delete_feedback_question(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
