import { Table } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../../services/redux";
import { capitalize, toUpper, upperCase } from "lodash";
import moment from "moment";
import PrintHeader from "../../../common/print_header";
import { IS_TABLE_SCROLLABLE } from "../../../../../helpers/constants";

const DailyTransactionReportList = (props) => {
  const { selected_dates } = props;
  const {
    items: daily_transaction_items,
    loading: daily_transaction_items_loading,
  } = useDynamicSelector("get_daily_transaction_summary");

  const grand_total_amount = daily_transaction_items
    ? daily_transaction_items.reduce(
        (acc, transaction) => acc + transaction?.amount,
        0
      )
    : 0;

  const grand_total_devotees = daily_transaction_items
    ? daily_transaction_items.reduce(
        (acc, transaction) => acc + transaction?.devotees,
        0
      )
    : 0;

  const columns = [
    {
      title: "Date",
      align: "center",
      fixed: "left",
      width: 250,
      render: (data) => (
        <div style={{ width: 80 }}>
          {moment(data?.date).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      title: "Receipt No",
      align: "center",
      width: 250,
      render: (data) => data?.receipt_number,
    },
    {
      title: "VSG",
      width: 300,
      align: "center",
      render: (data) => (
        <div style={{ width: 150 }}>
          {`${data?.mandram_code}/${data?.mandram_name}`}
        </div>
      ),
    },
    {
      title: "Booking No",
      align: "center",
      width: 300,
      render: (data) => data?.booking_reference,
    },

    {
      title: "Leader Name",
      width: 350,
      render: (data) => data?.leader_name,
    },
    {
      title: "Leader Mobile No",
      width: 350,
      render: (data) => data?.leader_number,
    },
    {
      title: "Devotees",
      width: 200,
      align: "right",
      render: (data) => data?.devotees,
    },
    // {
    //   title: "Rate",
    //   key: "rate",
    //   align: "center",
    //   render: (data) => data?.rate,
    // },
    {
      title: "Mode Of Payment",
      align: "center",
      width: 350,
      render: (data) => upperCase(data?.payment_mode),
    },
    {
      title: "Amount",
      align: "right",
      width: 250,
      fixed: "right",

      render: (data) => (
        <div style={{ textAlign: "end", width: 80 }}>
          {`₹${data?.amount?.toLocaleString("en-IN")}`}
        </div>
      ),
    },
  ];
  return (
    <>
      <Table
        data-name-table="district-wise-report"
        className="screen-table"
        columns={columns}
        loading={daily_transaction_items_loading}
        dataSource={daily_transaction_items}
        pagination={false}
        style={{ width: "96%", margin: "auto" }}
        scroll={{
          x: true,
          y: IS_TABLE_SCROLLABLE ? 500 : 300,
        }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <text style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                  Total
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell
                index={1}
                className="aligned-cell"
              ></Table.Summary.Cell>
              <Table.Summary.Cell
                index={1}
                className="aligned-cell"
              ></Table.Summary.Cell>
              <Table.Summary.Cell
                index={1}
                className="aligned-cell"
              ></Table.Summary.Cell>

              <Table.Summary.Cell
                index={1}
                className="aligned-cell"
              ></Table.Summary.Cell>

              <Table.Summary.Cell
                index={1}
                className="aligned-cell"
              ></Table.Summary.Cell>
              <Table.Summary.Cell index={2} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  {daily_transaction_items
                    ? daily_transaction_items
                        .reduce(
                          (sum, record) => sum + (record?.devotees || 0),
                          0
                        )
                        .toLocaleString("en-IN")
                    : 0}
                </text>
              </Table.Summary.Cell>

              <Table.Summary.Cell
                index={4}
                className="aligned-cell"
              ></Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <text style={{ fontWeight: "bold" }}>
                  ₹
                  {daily_transaction_items
                    ? daily_transaction_items
                        .reduce((sum, record) => sum + (record?.amount || 0), 0)
                        .toLocaleString("en-IN")
                    : 0}
                </text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />

      <div
        style={{
          margin: "auto",
          width: "100%",
        }}
      >
        <div className="print-header" style={{ padding: "2px" }}>
          <PrintHeader />
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            TRANSACTION REPORT
          </h4>
          <h5
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {`${selected_dates?.to_date}`}
          </h5>
        </div>

        <div style={{ width: "100%" }}>
          <table
            className="table table-bordered print-table"
            style={{ width: "100%", marginTop: "10px" }}
          >
            <thead style={{ width: "100%" }}>
              <tr>
                {columns.map((column) => (
                  <th
                    className="header-style"
                    style={{ width: "100px" }}
                    key={column.key}
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody style={{ width: "100%" }}>
              {daily_transaction_items?.map((report, index) => (
                <tr key={index}>
                  <td className="body-style" style={{ textAlign: "center" }}>
                    {moment(report?.date).format("DD-MM-YYYY")}
                  </td>

                  <td className="body-style " style={{ textAlign: "center" }}>
                    {report?.receipt_number ? report?.receipt_number : "-"}
                  </td>

                  <td className="body-style " style={{ textAlign: "center" }}>
                    {`${report?.mandram_code}/${report?.mandram_name}`}
                  </td>

                  <td className="body-style" style={{ textAlign: "center" }}>
                    {report?.booking_reference
                      ? report?.booking_reference
                      : "-"}
                  </td>
                  <td className="body-style" style={{ textAlign: "left" }}>
                    {`${capitalize(report?.leader_name)}`}
                  </td>

                  <td className="body-style" style={{ textAlign: "right" }}>
                    {report?.leader_number}
                  </td>
                  <td className="body-style" style={{ textAlign: "right" }}>
                    {report?.devotees}
                  </td>
                  <td className="body-style" style={{ textAlign: "center" }}>
                    {`${
                      report?.payment_mode ? toUpper(report?.payment_mode) : "-"
                    }`}
                  </td>
                  <td className="body-style" style={{ textAlign: "right" }}>
                    {report?.amount
                      ? `₹${(report?.amount).toLocaleString("en-IN")}`
                      : ""}
                  </td>
                </tr>
              ))}
              <tr>
                <td className="body-style text-left-align">
                  <b>Total</b>
                </td>
                <td className="body-style "></td>
                <td className="body-style "></td>
                <td className="body-style "></td>
                <td className="body-style " style={{ textAlign: "right" }}>
                  <b>{grand_total_devotees}</b>
                </td>
                <td className="body-style "></td>

                <td className="body-style " style={{ textAlign: "right" }}>
                  <b>{`₹${grand_total_amount.toLocaleString("en-IN")}`}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <style>
          {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-header {
        display: block !important;
      }

      @media print {
        @page {
          size: A4;
          margin: 1cm;
        }
  
        body::before {
          content: counter(page);
          top: 0;
          right: 1cm;
        }

      }
    }
  `}
        </style>
      </div>
    </>
  );
};
export default DailyTransactionReportList;
