import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const CounterCode = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t("code")}
      name="code"
      rules={[
        {
          required: true,
          message: "Please enter counter code",
        },
      ]}
      style={{ marginTop: "22px" }}
    >
      <Input placeholder="Code" />
    </Form.Item>
  );
};
export default CounterCode;
