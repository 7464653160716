import { Col, Form, Input, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const VipBookingUserNameAddressSection = () => {
  const { t } = useTranslation();
  return (
    <Form.Item
      name="address"
      label={t("Address")}
      rules={[{ required: true, message: t("Please enter your address") }]}
    >
      <Input.TextArea placeholder={t("Enter address")} rows={2} />
    </Form.Item>
  );
};

export default VipBookingUserNameAddressSection;
