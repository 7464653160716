import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  query_user_group_list_users,
  useDynamicSelector,
} from "../../../../../services/redux";

const UserGroupInput = (props) => {
  const dispatch = useDispatch();
  const { items: group_users_items } = useDynamicSelector(
    "get_user_group_wise_list"
  );
  console.log("group_users_items", group_users_items);
  useEffect(() => {
    get_date_slot_list();
  }, []);

  const get_date_slot_list = () => {
    let key = [{ key: "get_user_group_wise_list", loading: true }];
    let query = query_user_group_list_users;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const custom_filter_option = (input, option) => {
    const inputValue = input.toLowerCase();
    const optionLabel = option.props.children.toLowerCase();
    return optionLabel.indexOf(inputValue) === 0;
  };

  return (
    <div>
      <Form.Item name="user_group_id_list" label="Group">
        <Select
          mode="multiple"
          placeholder="Select User Group"
          allowClear
          showSearch
          filterOption={custom_filter_option}
        >
          {group_users_items?.map((option, index) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default UserGroupInput;
