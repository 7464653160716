import React, { useEffect, useRef, useState } from "react";
import { Row } from "antd";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";
import { useDispatch } from "react-redux";
import CustomButton from "../components/common/custom_button";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../services/redux";
import { season_date_slots_list } from "../../services/redux/slices/graphql/graphql_daily_transaction_report";
import UserGroupWiseReportList from "../components/system_report/user_group_wise_report/list/user_group_wise_report_list";
// import UserGroupWiseReportModal from "../components/system_report/user_group_wise_report/modal/counter_group_wise_report_modal";
import UserGroupWiseReportSearch from "../components/system_report/user_group_wise_report/user_group_wise_report_search";
import { export_to_xlsx } from "../../helpers/functions";
import UserGrpWiseReportModal from "../components/system_report/user_group_wise_report/modal/user_group_wise_modal";
// import UserGroupWiseReportModal from "../components/system_report/user_group_wise_report/modal/counter_group_wise_report_modal";
import { useReactToPrint } from "react-to-print";
import LoaderSpinner from "../components/common/custom_spinner";

const UserGroupWiseReport = () => {
  const componentRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visible, set_visible] = useState(false);
  const [selected_date, set_selected_date] = useState({});

  const {
    items: group_users_summary_items,
    loading: group_users_summary_items_loading,
  } = useDynamicSelector("get_user_group_wise_summary");

  useEffect(() => {
    dispatch(dynamicClear("get_user_group_wise_summary"));
  }, []);

  const calculateColumnTotal = (columnName) => {
    let total = 0;
    group_users_summary_items?.forEach((item) => {
      total += parseFloat(item[columnName]) || 0;
    });
    return total;
  };

  const arrange_xlsx_report_data = (data_items) => {
    const rearranged_data = [];

    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["Group Name"]: data_items[i]?.user_group_name ?? "-",
        ["Total Enrolments"]: data_items[i]?.total_enrolments ?? "-",
        ["Total Devotees"]: data_items[i]?.total_devotees ?? "-",
        ["Cash Enrolments"]: data_items[i]?.cash_enrolments ?? "-",
        ["Cash Amount"]: data_items[i]?.cash_amount ?? "-",
        ["DD Enrolments"]: data_items[i]?.dd_enrolments ?? "-",
        ["DD Amount"]: data_items[i]?.dd_amount ?? "-",
        ["Card Amount"]: data_items[i]?.card_amount ?? "-",
        ["Card Enrolments"]: data_items[i]?.card_enrolments ?? "-",
        ["UPI Amount"]: data_items[i]?.upi_amount ?? "-",
        ["UPI Enrolments"]: data_items[i]?.upi_enrolments ?? "-",
        ["DD Amount"]: data_items[i]?.dd_amount ?? "-",
        ["DD Enrolments"]: data_items[i]?.dd_enrolments ?? "-",
        ["Total"]: data_items[i]?.sum ?? "-",
      };

      rearranged_data.push(row_data);
    }

    return rearranged_data;
  };

  const on_xlsx_download = () => {
    const arranged_data = arrange_xlsx_report_data(group_users_summary_items);

    // Calculate totals separately
    const totals_row = {
      ["Group Name"]: "Totals",
      ["Total Enrolments"]: calculateColumnTotal("total_enrolments"),
      ["Total Devotees"]: calculateColumnTotal("total_devotees"),
      ["Cash Enrolments"]: calculateColumnTotal("cash_enrolments"),
      ["Cash Amount"]: calculateColumnTotal("cash_amount"),
      ["DD Enrolments"]: calculateColumnTotal("dd_enrolments"),
      ["DD Amount"]: calculateColumnTotal("dd_amount"),
      ["Card Amount"]: calculateColumnTotal("card_amount"),
      ["Card Enrolments"]: calculateColumnTotal("card_enrolments"),
      ["UPI Amount"]: calculateColumnTotal("upi_amount"),
      ["UPI Enrolments"]: calculateColumnTotal("upi_enrolments"),
      ["DD Amount"]: calculateColumnTotal("dd_amount"),
      ["DD Enrolments"]: calculateColumnTotal("dd_enrolments"),
      ["Total"]: calculateColumnTotal("sum"),
    };

    const dataWithTotals = [...arranged_data, totals_row];

    export_to_xlsx(dataWithTotals, "user_group_wise_report");
  };

  const get_season_date_slot_list = () => {
    let keys = [{ key: "get_season_date_slot_list", loading: true }];
    let query = season_date_slots_list;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const on_print = () => {
    // set_visible(true);
    handlePrint();
  };

  useEffect(() => {
    get_season_date_slot_list();
  }, []);

  return (
    <>
      <div>
        <Row style={{ marginLeft: "10px" }}>
          <CustomPageTitle title={t("user_group_wise_report_title")} />
        </Row>
        <div style={{ marginTop: "20px" }}>
          <UserGroupWiseReportSearch set_selected_date={set_selected_date} />
        </div>

        {group_users_summary_items_loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <LoaderSpinner />
          </div>
        )}

        {group_users_summary_items?.length > 0 ? (
          <>
            <Row
              style={{
                width: "96%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <CustomButton
                disabled={group_users_summary_items?.length > 0 ? false : true}
                button_name={"Print"}
                bg_color={"#1e2c55"}
                rounded={true}
                onclick_button={on_print}
              />
              <CustomButton
                disabled={group_users_summary_items?.length > 0 ? false : true}
                button_name={"Download"}
                bg_color={"#185c37"}
                rounded={true}
                onclick_button={on_xlsx_download}
              />
            </Row>
            <div style={{ marginTop: "20px" }} ref={componentRef}>
              <UserGroupWiseReportList selected_date={selected_date} />
            </div>
          </>
        ) : null}
      </div>

      <div style={{ width: "100%" }}></div>

      <UserGrpWiseReportModal visible={visible} set_visible={set_visible} />
    </>
  );
};

export default UserGroupWiseReport;
