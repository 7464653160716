import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  useDynamicSelector,
} from "../../../../../services/redux";
import { query_get_counter_group_list } from "../../../../../services/redux/slices/graphql/graphql_counter_group_wise_report";

const CounterGroupInput = (props) => {
  const dispatch = useDispatch();
  const { items: group_items } = useDynamicSelector("get_counter_group_list");
  console.log("group_items", group_items);
  useEffect(() => {
    get_date_slot_list();
  }, []);

  const get_date_slot_list = () => {
    let key = [{ key: "get_counter_group_list", loading: true }];
    let query = query_get_counter_group_list;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const custom_filter_option = (input, option) => {
    const inputValue = input.toLowerCase();
    const optionLabel = option.props.children.toLowerCase();
    return optionLabel.indexOf(inputValue) === 0;
  };

  return (
    <div>
      <Form.Item name="counter_group_id_list" label="Counter Group">
        <Select
          mode="multiple"
          placeholder="Select Counter Groups"
          allowClear
          showSearch
          filterOption={custom_filter_option}
        >
          {group_items?.map((option, index) => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default CounterGroupInput;
