import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Pagination,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  dynamicRequest,
  query_get_masm_user_list,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import {
  query_get_session_enrolment_list,
  query_session_wise_report,
} from "../../../../services/redux/slices/graphql/graphql_session_wise_report";
import { ROUTES } from "../../../routes/my_routes";
import CustomPageTitle from "../../common/custom_page_title";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { HStack, Link } from "native-base";
import { useParams } from "react-router-dom";
import { startCase } from "lodash";
import { FaPrint } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";

const EnrolmentSessionHistory = (props) => {
  const componentRef = useRef();
  const { list_value, onCancel } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [current_page_limit, set_current_page_limit] = useState(1);
  const [current_page_number, set_current_page_number] = useState(10);
  const { t } = useTranslation();
  const { props_state } = useLocation();
  const { id } = useParams();
  console.log("list_value", list_value);
  const handle_history = (record) => {
    history.push(
      `${ROUTES.PRINT_RECEIPT}/${record?.receipt_no}/${record?.mobile_number}`
    );
  };
  const { items: session_enrolment_list, loading } = useDynamicSelector(
    "get_session_enrolment_list"
  );

  const { items: session_details, loading: session_details_loading } =
    useDynamicSelector("get_enrolment_session_details");

  let column = [
    {
      title: "Receipt Number",
      key: "booking",
      render: (record) => record?.receipt_number,
    },
    {
      title: "Booking Number",
      key: "booking",
      render: (record) => record?.booking_number,
    },
    {
      title: "Total Devotees",
      key: "booking",
      render: (record) => record?.total_devotees,
    },
    {
      title: "Amount",
      key: "booking",
      render: (record) => record?.amount,
    },
    {
      title: "Payment Mode",
      key: "",
      render: (record) => startCase(record?.payment_mode),
    },
    {
      title: "Reference Number",
      key: "",
      render: (record) => record?.dd_reference || "-",
    },
    // {
    //   title: "Payment Status",
    //   key: "",
    //   render: (record) => startCase(record?.status),
    // },
    // {
    //   title: "Booked By",
    //   dataIndex: "",
    //   key: "",
    //   render: (record) => record?.booking_reference
    // },

    // {
    //   title: "Payment Mode",
    //   dataIndex: "",
    //   key: "",
    //   render: (record) => record?.payment?.payment_mode ? startCase(record?.payment?.payment_mode) : "-"
    // },

    // {
    //   title: "Status",
    //   dataIndex: "",
    //   key: "",
    //   fixed: "right",
    //   render: (record) => record?.booking?.status
    // },
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "",
    //   fixed: "right",
    //   render: (record) => (
    //     <>
    //       <Space
    //         style={{
    //           width: "50px",
    //           justifyContent: "space-between",
    //         }}
    //       >
    //         <FaPrint size={20} color="#3b90f5" onClick={() => { handle_history(record) }} />
    //       </Space>
    //     </>
    //   ),
    // }
  ];

  // useEffect(() => {
  //   get_session_wise_history_list();
  // }, [list_value?.id])

  const fetch_data = (page_number, page_limit) => {
    set_current_page_limit(page_limit);
    set_current_page_number(page_number);
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (props.print) {
      handlePrint();
      props.set_print(false);
    }
  }, [props.print]);
  return (
    <div ref={componentRef} style={{ padding: "20px" }}>
      <Table
        columns={column}
        dataSource={session_details}
        loading={session_details_loading}
        pagination={false}
      />
    </div>
  );
};

export default EnrolmentSessionHistory;
