import { Form, Switch } from "antd";
import React from "react";
import { retrieveItem } from "../../../../../helpers/functions";

const MandramActiveStatusInput = (props) => {
  const { set_is_active_status, is_active_status, is_active } = props;
  const role = retrieveItem("role");
  const on_change = (values) => {
    set_is_active_status(values);
  };
  return (
    <Form.Item
      name="is_active"
      label="Is Active"
      valuePropName="checked"
      rules={[
        {
          required: true,
          message: "Please Select",
        },
      ]}
    >
      <Switch
        onChange={on_change}
        defaultChecked={false}
        disabled={
          role === "District Admin" || role === "District User" ? true : false
        }
      />
    </Form.Item>
  );
};

export default MandramActiveStatusInput;
