import React, { useEffect, useRef, useState } from "react";
import { Row } from "antd";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";
import { useDispatch } from "react-redux";
import { export_to_xlsx } from "../../helpers/functions";
import CustomButton from "../components/common/custom_button";
import CounterGroupWiseReportSearch from "../components/system_report/counter_group_wise_report.jsx/counter_group_wise_report_search";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../services/redux";
import { season_date_slots_list } from "../../services/redux/slices/graphql/graphql_daily_transaction_report";
import CounterGroupWiseReportModal from "../components/system_report/counter_group_wise_report.jsx/modal/counter_group_wise_report_modal";
import CounterGroupWiseReportList from "../components/system_report/counter_group_wise_report.jsx/list/counter_group_wise_report";
import { useReactToPrint } from "react-to-print";
import LoaderSpinner from "../components/common/custom_spinner";

const CounterGroupWiseReport = () => {
  const componentRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visible, set_visible] = useState(false);
  const [selected_date, set_selected_date] = useState({});
  console.log("selected_date", selected_date);
  const { items: counter_group_items, loading: counter_group_items_loading } =
    useDynamicSelector("get_counter_group_wise_summary");

  useEffect(() => {
    dispatch(dynamicClear("get_counter_group_wise_summary"));
  }, []);

  const arrange_xlsx_report_data = (data_items) => {
    const rearranged_data = [];
    let grand_total = 0;
    let total_enrolments = 0;
    let total_devotees = 0;
    let cash_enrolments = 0;
    let cash_amount = 0;
    let dd_amount = 0;
    let dd_enrolments = 0;
    let card_enrolments = 0;
    let card_amount = 0;
    let upi_enrolments = 0;
    let upi_amount = 0;

    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["Counter Group Name"]: data_items[i]?.counter_group_name ?? "-",
        ["Total Enrolments"]: data_items[i]?.total_enrolments ?? "-",
        ["Total Devotees"]: data_items[i]?.total_devotees ?? "-",
        ["Cash Enrolments"]: data_items[i]?.cash_enrolments ?? "-",
        ["Cash Amount"]: data_items[i]?.cash_amount ?? "-",
        ["Card Amount"]: data_items[i]?.card_amount ?? "-",
        ["Card Enrolments"]: data_items[i]?.card_enrolments ?? "-",
        ["UPI Amount"]: data_items[i]?.upi_amount ?? "-",
        ["UPI Enrolments"]: data_items[i]?.upi_enrolments ?? "-",
        ["DD Amount"]: data_items[i]?.dd_amount ?? "-",
        ["DD Enrolments"]: data_items[i]?.dd_enrolments ?? "-",
        ["Sum"]: data_items[i]?.sum ?? "-",
      };

      rearranged_data.push(row_data);

      total_enrolments += parseFloat(data_items[i]?.total_enrolments) || 0;
      total_devotees += parseFloat(data_items[i]?.total_devotees) || 0;
      cash_enrolments += parseFloat(data_items[i]?.cash_enrolments) || 0;
      cash_amount += parseFloat(data_items[i]?.cash_amount) || 0;
      dd_amount += parseFloat(data_items[i]?.dd_amount) || 0;
      dd_enrolments += parseFloat(data_items[i]?.dd_enrolments) || 0;
      card_enrolments += parseFloat(data_items[i]?.card_enrolments) || 0;
      card_amount += parseFloat(data_items[i]?.card_amount) || 0;
      upi_enrolments += parseFloat(data_items[i]?.upi_enrolments) || 0;
      upi_amount += parseFloat(data_items[i]?.upi_amount) || 0;
      grand_total += parseFloat(data_items[i]?.sum) || 0;
    }

    // Add Grand Total Row
    rearranged_data.push({
      ["S.No"]: "Grand Total",
      ["Total Enrolments"]: total_enrolments,
      ["Total Devotees"]: total_devotees,
      ["Cash Enrolments"]: cash_enrolments,
      ["Cash Amount"]: cash_amount,
      ["Card Amount"]: card_amount,
      ["Card Enrolments"]: card_enrolments,
      ["UPI Amount"]: upi_amount,
      ["UPI Enrolments"]: upi_enrolments,
      ["DD Enrolments"]: dd_enrolments,
      ["DD Amount"]: dd_amount,
      ["Sum"]: grand_total,
    });

    return rearranged_data;
  };

  console.log("counter_group_items", counter_group_items);

  const get_season_date_slot_list = () => {
    let keys = [{ key: "get_season_date_slot_list", loading: true }];
    let query = season_date_slots_list;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const on_xlsx_download = () => {
    const arranged_data = arrange_xlsx_report_data(counter_group_items);

    if (arranged_data.length > 0) {
      export_to_xlsx(arranged_data, "counter_group_wise_report");
    } else {
      console.error("No data available for Excel download.");
    }
  };

  const handle_print = useReactToPrint({
    content: () => componentRef.current,
  });

  const on_print = () => {
    handle_print();
  };

  useEffect(() => {
    get_season_date_slot_list();
  }, []);

  return (
    <>
      <div>
        <Row style={{ marginLeft: "10px" }}>
          <CustomPageTitle title={t("counter_group_wise_report_title")} />
        </Row>
        <div style={{ marginTop: "20px" }}>
          {/* <CustomReportSearch
            get_selected_dates={get_selected_dates}
            dynamic_query={query_daily_transactions_report}
            dynamic_key={"get_daily_transaction_summary"}
          /> */}
          <CounterGroupWiseReportSearch set_selected_date={set_selected_date} />
        </div>

        {counter_group_items_loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <LoaderSpinner />
          </div>
        )}

        {counter_group_items?.length > 0 ? (
          <>
            <Row
              style={{
                width: "96%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <CustomButton
                id="daily_transaction_print_button"
                disabled={counter_group_items?.length > 0 ? false : true}
                button_name={"Print"}
                bg_color={"#1e2c55"}
                rounded={true}
                onclick_button={on_print}
              />
              <CustomButton
                disabled={counter_group_items?.length > 0 ? false : true}
                button_name={"Download"}
                bg_color={"#185c37"}
                rounded={true}
                onclick_button={on_xlsx_download}
              />
            </Row>
            <div style={{ marginTop: "20px" }} ref={componentRef}>
              <CounterGroupWiseReportList selected_date={selected_date} />
            </div>
          </>
        ) : null}
      </div>

      <div style={{ width: "100%" }}></div>

      <CounterGroupWiseReportModal
        visible={visible}
        set_visible={set_visible}
      />
    </>
  );
};

export default CounterGroupWiseReport;
