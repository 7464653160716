import React, { useEffect } from "react";
import CounterName from "./counter_form_items/counter_name";
import { Col, Form, Row } from "antd";
import {
  dynamicRequest,
  mutation_create_counter,
  mutation_update_counter,
} from "../../../services/redux/slices";
import { useDispatch } from "react-redux";
import CounterActiveSwitch from "./counter_form_items/counter_active_switch";
import CounterCode from "./counter_form_items/counter_code";
import { useDynamicSelector } from "../../../services/redux";
import SystemReferenceId from "./counter_form_items/system_reference_id";
import PrinterReferenceId from "./counter_form_items/printer_reference_id";
import PosReferenceId from "./counter_form_items/pos_reference_id";
import CounterGroupName from "./counter_form_items/counter_group_name";

const CounterForm = (props) => {
  const { counter_modal_title, selected_items } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    name,
    code,
    is_active,
    counter_group_id,
    id,
    system_reference_id,
    printer_reference_id,
    pos_id,
  } = useDynamicSelector("get_counter");

  const handle_counter_submit = (values) => {
    if (counter_modal_title === "add") {
      create_counter(values);
    } else {
      update_counter(values);
    }
  };
  selected_items;
  const create_counter = (values) => {
    let key = [{ key: "create_counter", loading: true }];
    let query = mutation_create_counter;
    let variables = {
      data: {
        name: values?.name,
        code: values?.code,
        counter_group_id: values?.counter_group_id,
        system_reference_id: values?.system_reference_id,
        printer_reference_id: values?.printer_reference_id,
        pos_id: values?.pos_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const update_counter = (values) => {
    let key = [{ key: "update_counter", loading: true }];
    let query = mutation_update_counter;
    let variables = {
      id: selected_items.id,
      data: {
        name: values?.name,
        is_active: values?.is_active,
        code: values?.code,
        counter_group_id: values?.counter_group_id,
        system_reference_id: values?.system_reference_id,
        printer_reference_id: values?.printer_reference_id,
        pos_id: values?.pos_id,
      },
    };

    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (counter_modal_title === "edit") {
      form.setFieldsValue({
        id,
        name,
        code,
        is_active,
        counter_group_id: counter_group_id,
        system_reference_id: system_reference_id,
        printer_reference_id: printer_reference_id,
        pos_id: pos_id,
      });
    } else if (counter_modal_title === "add") {
      form.resetFields();
    }
  }, [
    counter_modal_title,
    id,
    system_reference_id,
    printer_reference_id,
    // pos_id,
    counter_group_id,
  ]);

  return (
    <Form
      id="add_counter_form"
      // initialValues={selected_items}
      form={form}
      labelCol={{
        span: 10,
      }}
      onFinish={handle_counter_submit}
    >
      <Row gutter={24}>
        <Col span={12}>
          <CounterName />
        </Col>
        <Col span={12}>
          <CounterCode />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <CounterGroupName />
        </Col>
        <Col span={12}>
          <SystemReferenceId />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <PosReferenceId selected_items={selected_items} />
        </Col>
        <Col span={12}>{/* <PrinterReferenceId /> */}</Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          {counter_modal_title == "edit" ? (
            <CounterActiveSwitch is_active={is_active} />
          ) : (
            ""
          )}
        </Col>
        <Col span={12}></Col>
      </Row>
    </Form>
  );
};
export default CounterForm;
