import React, { useEffect, useState } from "react";
import FeedBackExpandableTable from "./feedback_manage_expandable_table";
import FeedbackManageExpandableEditModal from "./feedback_modal_expandable_edit_modal/feedback_manage_edit_modal";
import CustomPageTitle from "../../../common/custom_page_title";
import CustomButton from "../../../common/custom_button";
import { useTranslation } from "react-i18next";
import { Row, Space } from "antd";
import FeedBackExpandableDeleteModal from "./feedback_manage_expandable_delete_modal/feedback_expandable_delete_modal";
import {
  delete_feedback_question_option_mutation,
  dynamicClear,
  dynamicRequest,
  get_feedback_question_option_list_query,
  update_feedback_question_option_mutation,
  update_feedback_question_option_mutation_for_delete,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const FeedBackExpandableTableComponent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    header_type,
    handle_edit_expandable,
    handle_add_expandable,
    is_edit_model_visible,
    set_edit_modal_visible,
    edit_values,
    item,
    options,
    data,
    loading: question_loading,
    get_all_feedback_question_query,
  } = props;
  const [delete_id, set_delete_id] = useState("");
  const [delete_active_button, set_active_delete_button] = useState("");

  const [is_delete_modal_visible, set_is_delete_modal_visible] =
    useState(false);

  const { items: get_feedback_question_option_list_items, loading } =
    useDynamicSelector("get_feedback_question_option_list");
  const {
    status: delete_feedback_question_option_status,
    error: delete_feedback_question_option_error,
  } = useDynamicSelector("update_feedback_question_option_to_delete");
  const {
    status: create_feedback_question_option_status,
    error: create_feedback_question_option_error,
  } = useDynamicSelector("create_feedback_question_option");
  const {
    status: update_feedback_question_option_status,
    error: update_feedback_question_option_error,
  } = useDynamicSelector("update_feedback_question_option");

  const update_feedback_option_for_delete = (edit_id, values) => {
    let key = [
      { key: "update_feedback_question_option_to_delete", loading: true },
    ];
    let query = update_feedback_question_option_mutation_for_delete;
    let variable = {
      id: delete_id.id,
      data: {
        option: delete_id.option,
        is_active: !delete_active_button,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  useEffect(() => {
    if (create_feedback_question_option_status === "success") {
      toast.success("Option created");
      set_edit_modal_visible(false);
      get_all_feedback_question_query();
      dispatch(dynamicClear("create_feedback_question_option"));
    } else if (create_feedback_question_option_error?.message) {
      toast.error(create_feedback_question_option_error.message);
      get_all_feedback_question_query();
      dispatch(dynamicClear("create_feedback_question_option"));
    }
    if (update_feedback_question_option_status === "success") {
      toast.success("Option updated");
      set_edit_modal_visible(false);
      get_all_feedback_question_query();
      dispatch(dynamicClear("update_feedback_question_option"));
    } else if (update_feedback_question_option_error?.message) {
      toast.error(update_feedback_question_option_error.message);
      get_all_feedback_question_query();
      dispatch(dynamicClear("update_feedback_question_option"));
    }
  }, [
    create_feedback_question_option_status,
    create_feedback_question_option_error,
    update_feedback_question_option_status,
    update_feedback_question_option_error,
  ]);

  useEffect(() => {
    if (delete_feedback_question_option_status === "success") {
      toast.success("Option deleted successfully");
      set_is_delete_modal_visible(false);
      get_all_feedback_question_query();
      dispatch(dynamicClear("update_feedback_question_option_to_delete"));
    } else if (delete_feedback_question_option_error?.message) {
      toast.error(delete_feedback_question_option_error.message);
      get_all_feedback_question_query();
      dispatch(dynamicClear("update_feedback_question_option_to_delete"));
    }
  }, [
    delete_feedback_question_option_status,
    delete_feedback_question_option_error,
  ]);

  const show_delete_model_expandable = (record) => {
    set_delete_id(record);
    set_active_delete_button(record.is_active);
    set_is_delete_modal_visible(true);
  };

  const handle_delete_cancel_expandable = () => {
    set_is_delete_modal_visible(false);
  };

  const handle_delete_confirm_expandable = (values) => {
    update_feedback_option_for_delete(delete_id);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CustomButton
          button_name={t("add_option")}
          onclick_button={handle_add_expandable}
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        <FeedBackExpandableTable
          handle_edit_expandable={handle_edit_expandable}
          show_delete_model_expandable={show_delete_model_expandable}
          item={item}
          options={options}
          loading={loading || question_loading}
        />
      </div>
      <FeedbackManageExpandableEditModal
        header_type={header_type}
        is_edit_model_visible={is_edit_model_visible}
        set_edit_modal_visible={set_edit_modal_visible}
        edit_values={edit_values}
        options={options}
        data={data}
      />
      <FeedBackExpandableDeleteModal
        handle_delete_cancel_expandable={handle_delete_cancel_expandable}
        handle_delete_confirm_expandable={handle_delete_confirm_expandable}
        is_delete_modal_visible={is_delete_modal_visible}
        handle_edit_expandable={handle_edit_expandable}
      />
    </div>
  );
};

export default FeedBackExpandableTableComponent;
