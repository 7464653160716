import React, { useState } from "react";
import {
  dynamicRequest,
  get_day_wise_report,
  useDynamicSelector,
} from "../../../../../services/redux";
import { Table } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import PrintHeader from "../../../common/print_header";
import { IS_TABLE_SCROLLABLE } from "../../../../../helpers/constants";

const DayWiseReportList = (props) => {
  const { selected_date } = props;
  const dispatch = useDispatch();

  const [current, set_current] = useState(1);
  const [current_limit, set_current_limit] = useState(5);

  const { items: day_wise_report_items, loading: get_day_wise_report_loading } =
    useDynamicSelector("get_daywise_summary");

  const fetch_data = (page_number, page_limit) => {
    let keys = [{ key: "get_daywise_summary", loading: true }];
    let query = get_day_wise_report;
    let variable = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(keys, query, variable));
    set_current(page_number);
    set_current_limit(page_limit);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "400px",

      render: (text) => {
        const formatted_date = moment(text).format("DD/MM/YYYY");
        return <span>{formatted_date}</span>;
      },
    },

    {
      title: "Day",
      dataIndex: "day",
      key: "day",
      align: "center",
      width: "200px",
      render: (text, record) => {
        const day_of_week = moment(record.date).format("dddd");
        return <span>{day_of_week}</span>;
      },
    },
    {
      title: "Irumudi Count",
      dataIndex: "irumudi_count",
      key: "irumudi_count",
      align: "center",
      width: "400px",
    },
  ];

  return (
    <div>
      <Table
        className="screen-table"
        dataSource={day_wise_report_items}
        columns={columns}
        loading={get_day_wise_report_loading}
        data-name-table="district-wise-report"
        pagination={false}
        scroll={{
          x: true,
          y: IS_TABLE_SCROLLABLE ? null : 300,
        }}
      />

      <div style={{ padding: "10px" }}>
        <div className="print-header" style={{ padding: "15px" }}>
          <PrintHeader />
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            DAY WISE REPORT
          </h4>
          <h5
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {selected_date?.date}
          </h5>
        </div>
      </div>

      <table
        className="table table-bordered print-table"
        style={{ width: "99%", marginTop: "10px", marginLeft: "-5px" }}
      >
        <thead style={{ width: "100%" }}>
          <tr>
            {columns.map((column) => (
              <th
                className="header-style"
                style={{ width: "100px" }}
                key={column.key}
              >
                {column.title}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {day_wise_report_items?.map((report, index) => (
            <tr key={index}>
              <td className="body-style">
                {report?.date ? moment(report?.date).format("DD-MM-YYYY") : "-"}
              </td>
              <td className="body-style">
                {report?.date ? moment(report?.date).format("dddd") : "-"}
              </td>
              <td className="body-style">
                {report?.irumudi_count ? report?.irumudi_count : "-"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <style>
        {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-header {
        display: block !important;
      }

      @page {
        size: A4;
        margin: 1cm;
      }

      body::before {
        content: counter(page);
        top: 0;
        right: 1cm;
      }
    }
  `}
      </style>
    </div>
  );
};

export default DayWiseReportList;
