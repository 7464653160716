import React, { useEffect } from "react";
import { Form } from "antd";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  mutation_create_masm_user,
  useDynamicSelector,
} from "../../../../../../services/redux";
import InputPassword from "../../inputs/input_password";
import InputName from "../../inputs/input_name";
import InputUsername from "../../inputs/input_username";
import InputMobile from "../../inputs/input_mobile";
import InputEmail from "../../inputs/input_email";
import InputSelectRole from "../../inputs/input_role";
import { toast } from "react-toastify";
import { get_user_groups_list } from "../../../../../../services/redux/slices/graphql/graphql_users_group";
import { useParams } from "react-router-dom";

const UsersGroupForm = (props) => {
  const {
    header_type,
    selected_items,
    get_all_user_group,
    set_user_group_modal_visible,
  } = props;
  const params = useParams();

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { status: create_user_group_status, error: create_user_group_error } =
    useDynamicSelector("create_masm_user");
  const { status: update_user_group_status, error: update_user_group_error } =
    useDynamicSelector("update_masm_user");

  console.log("create_user_group_status", create_user_group_status);

  const { name } = useDynamicSelector("get_user_group");

  const handle_user_group_submit = (values) => {
    console.log(values);
    if (header_type === "edit") {
      update_user_group(values);
    } else {
      create_user_group(values);
    }
  };

  const get_all_users_group = (values) => {
    let key = [{ key: "get_user_group_user_list", loading: true }];
    let query = get_user_groups_list;
    let variables = {
      filter: {
        group_id: params?.id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const create_user_group = (values) => {
    let key = [{ key: "create_masm_user", loading: true }];
    let query = mutation_create_masm_user;
    let variables = {
      data: {
        group_id: params?.id,
        name: values?.name,
        mobile: values?.mobile,
        email: values?.email,
        type: values?.type,
        system_user: {
          username: values?.username,
          password: values?.password,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const update_user_group = (values) => {
    let key = [{ key: "update_masm_user", loading: true }];
    let query = mutation_update_user_group;
    let variables = {
      id: id,
      data: {
        name: values?.name,
        mobile: values?.mobile,
        email: values?.email,
        type: values?.type,
        system_user: {
          username: values?.username,
          password: values?.password,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (create_user_group_status === "success") {
      toast.success("User  created");
      get_all_users_group();
      set_user_group_modal_visible(false);
      dispatch(dynamicClear("create_masm_user"));
    } else if (create_user_group_error) {
      toast.error("error");
      set_user_group_modal_visible(false);
      dispatch(dynamicClear("create_masm_user"));
    } else if (update_user_group_status === "success") {
      toast.success("User updated");
      get_all_users_group();
      set_user_group_modal_visible(false);
      dispatch(dynamicClear("update_masm_user"));
    } else if (update_user_group_error) {
      toast.error("error");
      set_user_group_modal_visible(false);
      dispatch(dynamicClear("update_masm_user"));
    }
  }, [
    create_user_group_status,
    create_user_group_error,
    update_user_group_status,
    update_user_group_error,
  ]);
  useEffect(() => {
    if (header_type === "edit") {
      form.setFieldsValue({
        name,
      });
    } else if (header_type === "add") {
      form.resetFields();
    }
  }, [header_type, name]);

  useEffect(() => {
    form.setFieldsValue({
      name: "",
    });
  }, []);
  return (
    <div>
      <Form
        id="user_group_form"
        form={form}
        labelCol={{
          span: 8,
        }}
        onFinish={handle_user_group_submit}
      >
        <InputName />
        <InputUsername />
        <InputPassword />
        <InputMobile />
        <InputEmail />
        <InputSelectRole />
      </Form>
    </div>
  );
};

export default UsersGroupForm;
