import React from "react";
import { Col, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import SpotBookingLeaderFetchDetails from "./spot_booking_leader_fetch_details";

const SpotBookingLeaderDetailsFetch = ({ item }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <SpotBookingLeaderFetchDetails
            title={t("group_leader_name")}
            value={item?.name}
          />
        </Col>
        <Col span={12}>
          <SpotBookingLeaderFetchDetails
            title={t("contact_number")}
            value={item?.mobile}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <SpotBookingLeaderFetchDetails
            title={t("id_proof")}
            value={item?.id_type}
          />
        </Col>
        <Col span={12}>
          <SpotBookingLeaderFetchDetails
            title={t("id_number")}
            value={item?.id_number}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <SpotBookingLeaderFetchDetails
            title={t("address")}
            value={item.address}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SpotBookingLeaderDetailsFetch;
