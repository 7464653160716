import React from "react";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";
import { Card, Row } from "antd";
import AccountsReportForm from "../components/system_report/accounts_report/accounts_report_fom/accounts_report_fom";

const AccountsReport = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Row style={{ marginLeft: "10px" }}>
        <CustomPageTitle title={t("accounts_report_title")} />
      </Row>
      <Card
        style={{
          width: "96%",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <AccountsReportForm />
      </Card>
    </div>
  );
};

export default AccountsReport;
