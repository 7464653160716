import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import { useTranslation } from "react-i18next";
import { dynamicRequest } from "../../services/redux/slices/dynamic_request";
import { useDispatch } from "react-redux";
import { query_get_all_district } from "../../services/redux/slices/graphql/graphql_district";
import { useDynamicSelector } from "../../services/redux";
import SendEmailForm from "../components/send_email/send_email_form";
const { Title } = Typography;

const SendEmail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const get_all_districts = () => {
    let keys = [{ key: "get_district_list", loading: true }];
    let query = query_get_all_district;
    let variable = {};
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    get_all_districts();
  }, []);

  return (
    <Col
      style={{
        margin: "8px auto",
        width: "98%",
        height: "100%",
      }}
    >
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <Title level={4}>{t("send_email")}</Title>
        {/* <CustomBreadCrumbs pages={"Home/Send-Email"} /> */}
      </Row>

      <Col
        style={{
          height: "auto",
          margin: "auto",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            justifyContent: "space-between",
            margin: "5px",
            padding: "0px 15px",
            alignItems: "center",
            backgroundColor: "#f6f6f3",
            marginTop: "40px auto",
            color: "white",
            width: "99%",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          }}
        >
          <SendEmailForm />
        </div>
      </Col>
    </Col>
  );
};

export default SendEmail;
