import { Form } from "antd";
import { TextArea } from "native-base";
import React from "react";

const QuestionFormItem = () => {
  return (
    <div>
      <Form.Item
        name="question"
        label="Question"
        rules={[
          {
            required: true,
            message: "Question is required",
          },
        ]}
      >
        <TextArea></TextArea>
      </Form.Item>
    </div>
  );
};

export default QuestionFormItem;
