import { Row } from "antd";
import React from "react";
import BookingDetailsTable from "../booking_details_table/booking_details_table";
import { useDynamicSelector } from "../../../../services/redux";
import CustomNoData from "../../common/custom_no_data";
import { useTranslation } from "react-i18next";

const AvailableReceipts = (props) => {
  const { t } = useTranslation();
  const { booking_reference } = useDynamicSelector("get_booking_receipt");
  return (
    <>
      {booking_reference ? (
        <Row
          style={{
            backgroundColor: "#f0eae7",
            padding: "12px",
            borderRadius: "10px",
            width: "99%",
            margin: "30px auto",
          }}
        >
          <Row style={{ width: "100%" }}>
            <BookingDetailsTable {...props} />
          </Row>
        </Row>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "8%",
          }}
        >
          <CustomNoData description={t("no_receipt")} />
        </div>
      )}
    </>
  );
};

export default AvailableReceipts;
