import React, { useEffect } from "react";
import CounterGroupName from "./counter_group_name";
import { Form } from "antd";
import {
  mutation_create_counter_group,
  mutation_update_counter_group,
} from "../../../../services/redux/slices/graphql/graphql_counter_group";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const CounterGroupForm = (props) => {
  const {
    header_type,
    selected_items,
    get_all_counter_group,
    set_counter_group_modal_visible,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    status: create_counter_group_status,
    error: create_counter_group_error,
  } = useDynamicSelector("create_counter_group");
  const {
    status: update_counter_group_status,
    error: update_counter_group_error,
  } = useDynamicSelector("update_counter_group");

  const { name } = useDynamicSelector("get_counter_group");

  const handle_counter_group_submit = (values) => {
    if (header_type === "add") {
      create_counter_group(values);
    } else {
      update_counter_group(values);
    }
  };

  const create_counter_group = (values) => {
    let key = [{ key: "create_counter_group", loading: true }];
    let query = mutation_create_counter_group;
    let variables = {
      data: {
        name: values?.name,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const update_counter_group = (values) => {
    let key = [{ key: "update_counter_group", loading: true }];
    let query = mutation_update_counter_group;
    let variables = {
      id: selected_items,
      data: {
        name: values?.name,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (create_counter_group_status === "success") {
      toast.success("Counter Group created");
      get_all_counter_group();
      set_counter_group_modal_visible(false);
      dispatch(dynamicClear("create_counter_group"));
    } else if (create_counter_group_error) {
      toast.error("error");
      set_counter_group_modal_visible(false);
      dispatch(dynamicClear("create_counter_group"));
    } else if (update_counter_group_status === "success") {
      toast.success("Counter Group updated");
      get_all_counter_group();
      set_counter_group_modal_visible(false);
      dispatch(dynamicClear("update_counter_group"));
    } else if (update_counter_group_error) {
      toast.error("error");
      set_counter_group_modal_visible(false);
      dispatch(dynamicClear("update_counter_group"));
    }
  }, [
    create_counter_group_status,
    create_counter_group_error,
    update_counter_group_status,
    update_counter_group_error,
  ]);
  useEffect(() => {
    if (header_type === "edit") {
      form.setFieldsValue({
        name,
      });
    } else if (header_type === "add") {
      form.resetFields();
    }
  }, [header_type, name]);

  return (
    <div>
      <Form
        id="add_counter_group_form"
        form={form}
        labelCol={{
          span: 8,
        }}
        onFinish={handle_counter_group_submit}
      >
        <CounterGroupName />
      </Form>
    </div>
  );
};

export default CounterGroupForm;
