import { Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "../../common/custom_action_button";
import CustomModal from "../../common/custom_modal";
import SystemUserModal from "../masm_user_modal/masm_user_modal";
import { useDynamicSelector } from "../../../../services/redux/selector";
import {
  dynamicClear,
  dynamicRequest,
  mutation_delete_masm_user,
  query_get_masm_user_list,
  query_get_system_role_list,
} from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import MasmUserDeleteModal from "../masm_user_delete_modal/masm_user_delete_modal";
import CustomButton from "../../common/custom_button";
import CustomDeleteModal from "../../common/custom_delete_modal";
import { query_get_one_user_group } from "../../../../services/redux/slices/graphql/graphql_user_group";

const SystemUserTable = (props) => {
  const { role_type } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modal_visible, set_modal_visible] = useState(false);
  const [modal_title, set_modal_title] = useState("");
  const [selected_items, set_selected_items] = useState({});
  const [delete_modal_visible, set_delete_modal_visible] = useState(false);
  const [delete_record, set_delete_record] = useState({});
  const [current_page_limit, set_current_page_limit] = useState(5);
  const [current_page, set_current_page] = useState(1);
  console.log("role_type", role_type);
  const { items: system_users, loading: masm_user_loading } =
    useDynamicSelector("get_masm_user_list");
  const { status: create_masm_user_status, error: create_masm_user_error } =
    useDynamicSelector("create_masm_user");
  const { status: update_masm_user_status, error: update_masm_user_error } =
    useDynamicSelector("update_masm_user");

  const handle_system_user_edit = (record) => {
    console.log("recordsdsds", record);
    set_modal_visible(true);
    set_modal_title("Edit");
    set_selected_items({
      id: record.system_user.id,
      name: record.name,
      mobile: record.mobile,
      email: record.email,
      password: record.password,
      username: record.system_user.username,
      type: record.system_user?.roles?.[0]?.name,
      group_id: record?.group?.id,
    });
    console.log("get_one_user_group", record);
    get_one_user_group(record);
  };

  const get_one_user_group = (record) => {
    let key = [{ key: "get_user_group", loading: true }];
    let query = query_get_one_user_group;
    let variables = {
      id: record?.id ? record?.id : "",
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_masm_user_modal = (record) => {
    set_delete_record(record);
    set_delete_modal_visible(true);
  };
  const add_system_user = () => {
    set_modal_visible(true);
    set_modal_title("add");
    set_selected_items("");
  };

  const get_all_masm_user_list = () => {
    let key = [{ key: "get_masm_user_list", loading: true }];
    let query = query_get_masm_user_list;
    let variables = {
      filter: {
        type: role_type,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_all_masm_user_list();
  }, [role_type]);

  const handle_delete = (record) => {
    let key = [{ key: "delete_masm_user", loading: true }];
    let query = mutation_delete_masm_user;
    let variables = {
      id: delete_record.system_user.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  // const handle_delete_masm_user = () => {
  //   handle_delete();
  // };

  const fetch_data = (page_number, page_limit) => {
    let key = [{ key: "get_masm_user_list", loading: true }];
    let query = query_get_masm_user_list;
    let variable = {
      filter: {
        type: role_type,
      },
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(key, query, variable));
    set_current_page(page_number);
    set_current_page_limit(page_limit);
  };

  useEffect(() => {
    if (create_masm_user_status === "success") {
      toast.success(`${role_type} created`);
      dispatch(dynamicClear("create_masm_user"));
      get_all_masm_user_list();
      set_modal_visible(false);
    } else if (create_masm_user_error) {
      toast.error(create_masm_user_error?.message);
      dispatch(dynamicClear("create_masm_user"));
      set_modal_visible(false);
    } else if (update_masm_user_status === "success") {
      toast.success(`${role_type} updated`);
      dispatch(dynamicClear("update_masm_user"));
      get_all_masm_user_list();
      set_modal_visible(false);
    } else if (update_masm_user_error?.message) {
      toast.error(update_masm_user_error.message);
      dispatch(dynamicClear("update_masm_user"));
      set_modal_visible(false);
    }
  }, [
    create_masm_user_status,
    create_masm_user_error,
    update_masm_user_status,
    update_masm_user_error,
    role_type,
  ]);

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: "400px",
    },
    {
      title: t("user_name"),
      dataIndex: "",
      key: "user_name",
      width: "400px",
      render: (items) => {
        return items?.system_user?.username;
      },
    },

    {
      title: t("mobile"),
      dataIndex: "mobile",
      key: "mobile",
      width: "400px",
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      width: "400px",
    },
    {
      title: t("User Group"),
      key: "user_group",
      width: "400px",
      render: (items) => {
        console.log(" itemsxxxxxx", items);
        return items?.group?.name;
      },
    },

    {
      title: <div style={{ textAlign: "center" }}>{t("actions")}</div>,
      dataIndex: "",
      align: "center",
      key: "actions",
      width: "100px",
      render: (_, record) => (
        <Space
          style={{
            width: "50px",
            justifyContent: "space-between",
          }}
        >
          <EditOutlined onClick={() => handle_system_user_edit(record)} />
          <DeleteOutlined onClick={() => handle_masm_user_modal(record)} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Row
          style={{
            alignItems: "flex-end",
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
            gap: "10px",
          }}
        >
          {/* <SystemUserSelectRole set_role_type={set_role_type} /> */}
          <CustomButton
            button_name={
              role_type === "Bank Enrolment Officer"
                ? "Add Bank Enrolment Officer"
                : role_type === "Dashboard User"
                ? "Add Dashboard User"
                : role_type === "Enrolment Officer"
                ? "Add Enrolment Officer"
                : role_type === "Helpdesk Officer"
                ? "Add Helpdesk Officer"
                : role_type === "Spl Enrolment Officer"
                ? "Add Spl Enrolment Officer"
                : role_type === "VIP Enrolment Officer"
                ? "Add VIP Enrolment Officer"
                : role_type === "MASM User"
                ? "Add MASM User"
                : role_type === "Enrolment Action User"
                ? "Add Enrolment Action User"
                : role_type === "Bank Branch Enrolment Officer"
                ? "Add Bank Branch Enrolment Officer"
                : role_type === "Dashboard Admin"
                ? "Add Dashboard Admin"
                : ""
            }
            onclick_button={add_system_user}
          />
        </Row>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Table
            style={{ width: "100%" }}
            size="large"
            columns={columns}
            dataSource={system_users}
            loading={masm_user_loading}
            pagination={{
              current: current_page,
              pageSize: current_page_limit,
              total: system_users?.length,
              showSizeChanger: true,
              onChange: fetch_data,
              onShowSizeChange: (current, size) => {
                get_all_masm_user_list(current, size);
              },
            }}
          />

          <SystemUserModal
            modal_visible={modal_visible}
            set_modal_visible={set_modal_visible}
            modal_title={modal_title}
            selected_items={selected_items}
            role_type={role_type}
          />
          {/* <MasmUserDeleteModal
            delete_modal_visible={delete_modal_visible}
            set_delete_modal_visible={set_delete_modal_visible}
            handle_delete_masm_user={handle_delete_masm_user}
          /> */}

          <CustomDeleteModal
            selector_name={"delete_masm_user"}
            get_all={get_all_masm_user_list}
            delete_api={handle_delete}
            record={delete_record}
            text={role_type}
            delete_modal_visible={delete_modal_visible}
            set_delete_modal_visible={set_delete_modal_visible}
          />
        </div>
      </Row>
    </>
  );
};
export default SystemUserTable;
