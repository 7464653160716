import { Form, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const BookingAvailabilityTicketPrice = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name="ticket_price"
        label={t("ticket_price")}
        rules={[
          {
            required: true,
            message: "Ticket price is required",
          },
        ]}
        style={{ width: "400px" }}
      >
        <InputNumber placeholder={t("ticket_price")} />
      </Form.Item>
    </div>
  );
};

export default BookingAvailabilityTicketPrice;
