import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux";

const VipBookingPortalLoginButton = () => {
  const { t } = useTranslation();
  const { loading } = useDynamicSelector("vip_login");

  return (
    <Button
      loading={loading}
      className="login_button"
      style={{
        backgroundColor: "#fe301d",
        color: "white",
        marginBottom: "10px",
      }}
      htmlType="submit"
    >
      {t("login")}
    </Button>
  );
};

export default VipBookingPortalLoginButton;
