import { Form } from "antd";
import React, { useEffect, useState } from "react";
import {
  dynamicClear,
  dynamicRequest,
} from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../../../services/redux/selector";
import { toast } from "react-toastify";
import InputDistrictAdminName from "../district_sub_admin_inputs/input_sub_district_admin_name";
import InputSubDistrictAdminEmail from "../district_sub_admin_inputs/input_sub_district_admin_email";
import InputDistrictAdminPassword from "../district_sub_admin_inputs/input_sub_district_admin_password";
import InputDistrictAdminMobile from "../district_sub_admin_inputs/input_sub_district_admin_mobile";
import InputDistrictAdminUserName from "../district_sub_admin_inputs/input_sub_district_admin_username";
import { mutation_update_sub_district } from "../../../../services/redux/slices/graphql/graphql_sub_district";

const SubDistrictAdminEditForm = (props) => {
  const dispatch = useDispatch();
  const { record, header_type, set_open, get_all_districts } = props;
  const [form] = Form.useForm();
  const {
    admin,
    password: system_user_password,
    loading: get_admin_user_loading,
  } = useDynamicSelector("get_sub_district_user");
  const [district_initial_values, set_district_initial_values] = useState({});
  console.log("admin", admin);

  useEffect(() => {
    set_district_initial_values(record);
  }, [record]);

  const {
    status: update_district_user_status,
    error: update_district_user_error,
  } = useDynamicSelector("update_sub_district");

  const on_finish = (values) => {
    update_district_user(values);
  };

  const update_district_user = (values) => {
    let keys = [{ key: "update_sub_district", loading: true }];
    let query = mutation_update_sub_district;
    let variable = {
      id: record?.id,
      data: {
        code: district_initial_values.code,
        name: district_initial_values.name,
        tamil_name: district_initial_values.tamil_name,
        extra_allowed_percentage:
          district_initial_values.extra_allowed_percentage,
        // group_id: district_initial_values.group_id,
        is_active: district_initial_values.is_active,
        is_vip: district_initial_values.is_vip,
        sub_district_admin: {
          ...values.sub_district_admin,
          system_user: {
            ...values.sub_district_admin?.system_user,
          },
        },
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    form.setFieldsValue({
      sub_district_admin: {
        ...(record.admin || admin),
        system_user: {
          username:
            record?.admin?.system_user?.username ||
            admin?.system_user?.username,
          password: record?.admin?.password || admin?.password,
        },
      },
    });
  }, [record, admin, system_user_password, form]);

  useEffect(() => {
    if (update_district_user_status === "success") {
      toast.success("Admin updated successfully");
      dispatch(dynamicClear("update_sub_district"));
      get_all_districts();
      set_open(false);
    } else if (update_district_user_error?.message) {
      toast.error(update_district_user_error?.message);
      dispatch(dynamicClear("update_sub_district"));
      // set_open(false);
    }
  }, [update_district_user_error, update_district_user_status]);

  return (
    <Form
      layout="horizontal"
      colon={false}
      labelAlign="left"
      id="district_edit_admin_form"
      onFinish={on_finish}
      form={form}
      autoComplete="off"
    >
      <InputDistrictAdminName />
      <InputDistrictAdminMobile />
      <InputSubDistrictAdminEmail />
      <InputDistrictAdminUserName />
      <InputDistrictAdminPassword />
    </Form>
  );
};

export default SubDistrictAdminEditForm;
