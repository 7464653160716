import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  dynamicRequest,
  query_get_all_district_list,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { FaSearch } from "react-icons/fa";
import { Text } from "native-base";

const MandramFilter = (props) => {
  const { get_all_mandram_list } = props;
  const dispatch = useDispatch();

  const { items: mandram_list } = useDynamicSelector("get_mandrams");

  const handle_input_change = (e) => {
    console.log("e", e.target.value);
    get_all_mandram_list(e.target.value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {/* <Text
        style={{
          width: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //   backgroundColor: "pink",
        }}
      >
        Search
      </Text> */}
      <Input
        placeholder="Search"
        suffix={<FaSearch style={{ marginRight: "8px", color: "#1890ff" }} />}
        onChange={(e) => {
          handle_input_change(e);
        }}
      />
    </div>
  );
};
export default MandramFilter;
