import { gql } from "@apollo/client";

export const query_get_all_notification_board_list = gql`
  query get_notification_board_list {
    get_notification_board_list {
      items {
        id
        name
        content
        order_number
        is_active
      }
    }
  }
`;

export const query_get_one_notification_board = gql`
  query get_notification_board($id: String!) {
    get_notification_board(id: $id) {
      id
      name
      content
      order_number
      is_active
    }
  }
`;

export const mutation_create_notification_board = gql`
  mutation create_notification_board($data: create_notification_board_input!) {
    create_notification_board(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_notification_board = gql`
  mutation update_notification_board(
    $id: String!
    $data: update_notification_board_input!
  ) {
    update_notification_board(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_notification_board = gql`
  mutation delete_notification_board($id: String!) {
    delete_notification_board(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_reorder_notification_board = gql`
  mutation reorder_notification_board(
    $data: notification_board_reorder_list_input
  ) {
    reorder_notification_board(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
