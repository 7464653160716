import React from "react";
import { Col, Row } from "antd";
import { useDynamicSelector } from "../../../../../services/redux";
import InputPosSelector from "../form_inputs/input_pos_selector";

export const PosCard = () => {
  const { id } = useDynamicSelector("get_district");
  return (
    <>
      <Col
        style={{
          padding: "10px",
          borderRadius: "6px",
          width: "100%",
          backgroundColor: "rgba(211, 211, 211, 0.2)",
          marginTop: "10px",
        }}
      >
        <h3 style={{ marginLeft: "12px" }}>POS</h3>
        <Row gutter={16} justify={"space-between"}>
          <Col span={12}>
            <InputPosSelector record_id={id} name={"pos_id_list"} />
          </Col>
        </Row>
      </Col>
    </>
  );
};
