import React, { useEffect, useState } from "react";
import { Button, Col, Row, Typography } from "antd";
import moment from "moment-timezone";

const VipBookingUserTime = ({
  items,
  selected_date,
  set_selected_time,
  selected_time,
}) => {
  const [categorized_time_slots, set_categorized_time_slots] = useState([]);

  useEffect(() => {
    let categorized_items = {
      Morning: [],
      Afternoon: [],
      Evening: [],
    };

    let is_future_date = moment()
      .startOf("day")
      .isBefore(moment(selected_date).startOf("day"));
    let current_minutes = moment().hours() * 60 + moment().minutes();

    items?.forEach((time) => {
      const time_category = categorize_time_slot(
        time.start_time_in_minutes,
        time.end_time_in_minutes
      );
      const is_past_time = is_future_date
        ? false
        : time.end_time_in_minutes < current_minutes;

      categorized_items[time_category].push({
        ...time,
        is_past_time,
      });
    });

    set_categorized_time_slots(categorized_items);
  }, [selected_date, items]);

  const handle_time_click = (time) => {
    set_selected_time(time); // Update the parent component with the selected time
  };

  const categorize_time_slot = (start, end) => {
    const morning_start = 0,
      morning_end = 720,
      afternoon_start = 720,
      afternoon_end = 960;
    if (start >= morning_start && end <= morning_end) return "Morning";
    if (start >= afternoon_start && end <= afternoon_end) return "Afternoon";
    return "Evening";
  };

  const minutes_to_time = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const formattedHours = hours % 12 || 12;
    const period = hours < 12 ? "AM" : "PM";
    return `${formattedHours < 10 ? `0${formattedHours}` : formattedHours}:${
      mins < 10 ? `0${mins}` : mins
    } ${period}`;
  };

  return (
    <div style={{marginBottom:"10px"}}>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBottom: "15px",
        }}
      >
        <Typography style={{ textAlign: "center" }}>
          Select a Time Slot
        </Typography>
      </Row>
      <Row
        style={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {Object.keys(categorized_time_slots).map((category) => (
          <div key={category}>
            <Col span={24}>
              <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                {category}
              </Typography>
            </Col>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {categorized_time_slots[category].map((slot) => (
                <Button
                  key={slot.index}
                  onClick={() =>
                    !slot.is_past_time && handle_time_click(slot.id)
                  }
                  className="vip_booking_time_slots"
                  style={{
                    backgroundColor: slot.is_past_time
                      ? "#dbd9d9"
                      : selected_time === slot.id
                      ? "#EC9501"
                      : "#960808",
                  }}
                  disabled={slot.is_past_time}
                >
                  <Typography style={{ color: "white" }}>
                    {`${minutes_to_time(
                      slot.start_time_in_minutes
                    )} - ${minutes_to_time(slot.end_time_in_minutes)}`}
                  </Typography>
                </Button>
              ))}
            </div>
          </div>
        ))}
      </Row>
    </div>
  );
};

export default VipBookingUserTime;
