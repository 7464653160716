import { Spin } from "antd";
import React from "react";

const Loading = ({marginTop}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop:marginTop
      }}
    >
      <Spin size="default" />
    </div>
  );
};

export default Loading;
