import { Row } from "antd";
import React from "react";
import PageComponent from "../components/access_rights/Page_component/page_component";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";

const AccessRights = () => {
  const { t } = useTranslation();
  return (
    <div style={{ width: "100%" }}>
      <CustomPageTitle title={t("access_rights")} />
      <div style={{ padding: "10px" }}>
        <PageComponent />
      </div>
    </div>
  );
};

export default AccessRights;
