import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { useDynamicSelector } from "../../../../services/redux";
import UserGroupForm from "../user_group_form/user_group_form";

const UserGroupModal = (props) => {
  const {
    user_group_modal_visible,
    set_user_group_modal_visible,
    header_type,
    selected_items,
    get_all_user_group,
  } = props;
  const { t } = useTranslation();

  const { loading: create_user_group_loading } =
    useDynamicSelector("create_user_group");
  const { loading: update_user_group_loading } =
    useDynamicSelector("update_user_group");

  const handle_modal_close = () => {
    set_user_group_modal_visible(false);
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        open={user_group_modal_visible}
        onCancel={handle_modal_close}
        width={"40%"}
        title={header_type === "add" ? "Add User Group" : "Edit User Group"}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button onClick={handle_modal_close}>{t("cancel")}</Button>,
          <Button
            style={{ backgroundColor: "#1e90ff", color: "white" }}
            htmlType="submit"
            form="add_user_group_form"
            loading={create_user_group_loading || update_user_group_loading}
          >
            {t("save")}
          </Button>,
        ]}
      >
        <UserGroupForm
          header_type={header_type}
          selected_items={selected_items}
          get_all_user_group={get_all_user_group}
          set_user_group_modal_visible={set_user_group_modal_visible}
        />
      </Modal>
    </div>
  );
};
export default UserGroupModal;
