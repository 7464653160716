import { Col, Row } from "antd";
import React from "react";
import {} from "../../../helpers/constants";
import { useDynamicSelector } from "../../../services/redux";

const BookingMandramList = (props) => {
  const { item } = props;
  const { items: mandram_items } = useDynamicSelector("get_all_mandrams");

  let mandram_name = mandram_items?.find(
    (mandram) => mandram?.id === item?.mandram_id
  );

  return (
    <div>
      <Row key={item?.id}>
        <Col span={4}>{`${mandram_name?.name} / ${mandram_name?.code}`}</Col>
        <Col span={3}>
          {"Booked: "}
          {item?.count?.booked?.toLocaleString("en-IN")}
        </Col>
        <Col span={3}>
          {"Cancelled: "}
          {item?.count?.cancelled?.toLocaleString("en-IN")}
        </Col>

        <Col span={3}>
          {"Enrolled: "}
          {item?.count?.enrolled?.toLocaleString("en-IN")}
        </Col>

        <Col span={3}>
          {"Yet To Enroll: "}
          {/* {item?.count?.yet_to_enroll < 0
            ? 0
            : item?.count?.yet_to_enroll?.toLocaleString("en-IN")} */}
          {item?.count?.yet_to_enroll}
        </Col>

        <Col span={3}>
          {"Excess: "}
          {/* {item?.count?.yet_to_enroll < 0
            ? Math.abs(item?.count?.yet_to_enroll?.toLocaleString("en-IN"))
            : 0} */}
          {item?.count?.excess}
        </Col>

        <Col span={4}>
          {"Prepaid: "}
          {item?.prepaid_amount?.toLocaleString("en-IN") || 0}
        </Col>
      </Row>
    </div>
  );
};

export default BookingMandramList;
