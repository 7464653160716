import { Button, Modal } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../services/redux/selector";
import AdminEditForm from "../district_table/admin_edit_form";
import { IoMdClose } from "react-icons/io";

const EditAdminModal = (props) => {
  const { open, set_open } = props;

  const { loading: update_district_loading } =
    useDynamicSelector("update_district");

  return (
    <Modal
      title={"Edit Admin"}
      open={open}
      destroyOnClose={true}
      onCancel={() => set_open(false)}
      footer={[
        <Button key="cancel" onClick={() => set_open(false)}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          htmlType="submit"
          form="district_edit_admin_form"
          loading={update_district_loading}
        >
          Save
        </Button>,
      ]}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <AdminEditForm {...props} />
    </Modal>
  );
};

export default EditAdminModal;
