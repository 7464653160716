import { Button, Modal, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";

const BookingIgnoreWaiting = ({
  set_waiting_conformation,
  set_ignore_waiting,
  waiting_conformation,
  set_modal_open,
}) => {
  const { t } = useTranslation();
  const on_finish = (values) => {
    set_waiting_conformation(false);
    set_modal_open(true);
    set_ignore_waiting(true);
  };
  const on_close = () => {
    set_waiting_conformation(false);
  };
  return (
    <div>
      <Modal
        title={t("waiting_con")}
        open={waiting_conformation}
        onCancel={() => set_waiting_conformation(false)}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            htmlType="submit"
            onClick={on_finish}
          >
            {t("yes")}
          </Button>,
          <Button
            style={{
              backgroundColor: "#168f92",
              color: "white",
              border: "transparent",
            }}
            onClick={on_close}
          >
            {t("no")}
          </Button>,
        ]}
      >
        <Typography>{t("waiting_msg")}</Typography>
      </Modal>
    </div>
  );
};

export default BookingIgnoreWaiting;
