import React, { useEffect, useRef, useState } from "react";

import PrintDistrictWiseReport from "../district_wise_report_print/district_wise_report_print_list";
import DistrictWiseReportList from "../district_wise_report_list/district_wise_report_list";
import CustomButton from "../../common/custom_button";
import { Row, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { AiFillPrinter } from "react-icons/ai";
import { FileExcelOutlined } from "@ant-design/icons";
import {
  arrange_xlsx_report_data,
  export_to_xlsx,
} from "../../../../helpers/functions";
import { dynamicClear, useDynamicSelector } from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";

const DistrictWiseReportDetails = (props) => {
  const {
    set_selected_language,
    selected_language,
    selected_date,
    yesterday_date,
  } = props;
  const dispatch = useDispatch();
  const { items: district_wise_count_item } = useDynamicSelector(
    "get_district_wise_count"
  );
  const { t } = useTranslation();

  const language = [
    { index: 1, value: "Tamil" },
    { index: 2, value: "English" },
  ];

  const print_district_wise_report = () => {
    const printWindow = window.open("", "", "width=600,height=600");
    const district_wise_print_html_content = ` <html>
    <html>
    <head>
      <style>
        body {
          font-family: Arial, sans-serif;
        
        }
        h3 {
          text-align: center;
          color: #333;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 20px;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: center;
        }
      </style>
    </head>
    <body>
      ${document.getElementById("district-wise-report").innerHTML}
    </body>
  </html>
    `;
    printWindow.document.write(district_wise_print_html_content);
    printWindow.document.close();

    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 1000);
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const on_print = () => {
    handlePrint();
  };

  const arrange_xlsx_report_data = (data_items) => {
    const rearranged_data = [];
    let grand_total = 0;
    let yesterday_count = 0;
    let today_count = 0;
    let total_count = 0;

    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["District"]: data_items[i]?.district_name ?? "-",
        ["District tamil Name"]: data_items[i]?.district_tamil_name ?? "-",
        ["Yesterday Count"]: data_items[i]?.yesterday_count ?? "-",
        ["Today Count"]: data_items[i]?.today_count ?? "-",
        ["Total Count"]: data_items[i]?.total_count ?? "-",
      };

      rearranged_data.push(row_data);

      yesterday_count += parseFloat(data_items[i]?.yesterday_count) || 0;
      today_count += parseFloat(data_items[i]?.today_count) || 0;
      total_count += parseFloat(data_items[i]?.total_count) || 0;
    }

    // Add Grand Total Row
    rearranged_data.push({
      ["S.No"]: "Grand Total",
      ["Yesterday Count"]: yesterday_count,
      ["Today Count"]: today_count,
      ["Total Count"]: total_count,
      // style: { fontWeight: "bold" },
    });

    return rearranged_data;
  };

  const download_district_wise_report = () => {
    const arranged_data = arrange_xlsx_report_data(district_wise_count_item);
    export_to_xlsx(arranged_data, "district_wise_report_table");
  };

  const handle_select_language = (value) => {
    set_selected_language(value);
    // dispatch(dynamicClear("get_district_wise_count"));
  };
  return (
    <div>
      {district_wise_count_item && (
        <>
          <Row
            style={{
              width: "96%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <Select
              style={{ width: "10%" }}
              allowClear
              onChange={handle_select_language}
              defaultValue={selected_language ? selected_language : "Tamil"}
            >
              {language.map((option) => (
                <Select.Option key={option?.value} value={option?.value}>
                  {option?.value}
                </Select.Option>
              ))}
            </Select>
            <CustomButton
              disabled={district_wise_count_item?.length > 0 ? false : true}
              button_name={t("Print")}
              bg_color={"#014477"}
              rounded={true}
              // icon={<AiFillPrinter />}
              onclick_button={on_print}
            />
            <CustomButton
              disabled={district_wise_count_item?.length > 0 ? false : true}
              button_name={t("download")}
              bg_color={"#185c37"}
              rounded={true}
              // icon={<FileExcelOutlined />}
              onclick_button={download_district_wise_report}
            />
          </Row>

          <div ref={componentRef} style={{ marginTop: "20px" }}>
            <DistrictWiseReportList
              selected_language={selected_language}
              selected_date={selected_date}
              yesterday_date={yesterday_date}
            />
          </div>

          {/* <div style={{ display: "none" }} id="district-wise-report">
        <PrintDistrictWiseReport
          selected_language={selected_language}
          selected_date={selected_date}
        />
      </div> */}
        </>
      )}
    </div>
  );
};
export default DistrictWiseReportDetails;
