import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const MasmUserEmail = ({ modal_title }) => {
  const { t } = useTranslation();

  const validateEmail = (rule, value, callback) => {
    if (value && !/^\S+@\S+\.\S+$/.test(value)) {
      callback("Please enter a valid email address");
    } else {
      callback();
    }
  };

  return (
    <>
      <Form.Item
        name="email"
        label={t("email")}
        rules={
          modal_title == "add"
            ? [
                {
                  required: true,
                  message: "Please enter email",
                },
                {
                  validator: validateEmail,
                },
              ]
            : []
        }
      >
        <Input placeholder={t("email")} allowClear={true} />
      </Form.Item>
    </>
  );
};

export default MasmUserEmail;
