import { Form, Select } from "antd";
import React from "react";

const InputSelectRole = () => {
  const roles = ["VIP Enrolment Officer", "Bank Enrolment Officer"];

  return (
    <Form.Item
      label="Select Role"
      name="type"
      initialValue="VIP Enrolment Officer"
    >
      <Select>
        {roles.map((role) => (
          <Select.Option key={role} value={role}>
            {role}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default InputSelectRole;
