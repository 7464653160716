import React, { useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { Row } from "antd";
import { useTranslation } from "react-i18next";
import UserCounterWiseReportList from "../components/user_counter_wise_report/user_counter_wise_report_list/user_counter_wise_report_list";
import UserCounterReportForm from "../components/user_counter_wise_report/user_counter_wise_report_list/user_counter_wise_report_form/user_counter_wise_report_form";
import { useDynamicSelector } from "../../services/redux";
import LoaderSpinner from "../components/common/custom_spinner";

const UserCounterWiseReport = () => {
  const { t } = useTranslation();
  const [selected_date, set_selected_date] = useState({});
  const { items: user_counter_wise_items, loading: counter_user_wise_loading } =
    useDynamicSelector("get_user_counter_wise_summary");

  return (
    <>
      <Row style={{ marginLeft: "10px" }}>
        <CustomPageTitle title={t("user_counter_wise_report_title")} />
      </Row>
      <UserCounterReportForm set_selected_date={set_selected_date} />

      {counter_user_wise_loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <LoaderSpinner />
        </div>
      )}

      <UserCounterWiseReportList selected_date={selected_date} />
    </>
  );
};

export default UserCounterWiseReport;
