import { Form, Input } from "antd";
import React from "react";

const CashReceived = (props) => {
  const { set_cash_amount } = props;

  const on_change = (e) => {
    const received = e.target.value;
    console.log("received", received);
    set_cash_amount(received);
  };

  return (
    <Form.Item label="Received" name="cash_received">
      <Input onChange={on_change} />
    </Form.Item>
  );
};

export default CashReceived;
