import { Button, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import DeleteMandramModal from "./delete_mandram_modal";
import AddMandramUserModal from "./add_mandram_user/add_mandram_user_modal";
import { useDynamicSelector } from "../../../services/redux/selector";

const MandramUserList = (props) => {
  const { key, record_id, mandram_items, expanded_row_key } = props;
  const [delete_mandram, set_delete_mandram] = useState("");
  const [header_type, set_header_type] = useState("");
  const [mandram_user, set_add_mandram_user] = useState("");

  const { id: mandram_id } = useDynamicSelector("get_mandram");

  const handle_delete_mandram_user = () => {
    set_header_type("Delete Mandram User");
    set_delete_mandram(true);
  };

  const handle_edit_mandram_user = () => {
    set_header_type("Edit Mandram User");
    set_add_mandram_user(true);
  };
  const columns = [
    {
      key: key,
      title: "Username",
      align: "left",
      width: "300px",
      height: "100px",
      render: (items) => {
        return items?.system_user?.username;
      },
    },
    {
      title: "Contact Number",
      align: "center",
      width: "300px",
      height: "100px",
      render: (items) => {
        return items?.mobile;
      },
    },
    {
      title: "Email ID",
      align: "center",
      width: "300px",
      height: "100px",
      render: (items) => {
        const email = items?.email;
        return email ? email : "-";
      },
    },
    {
      title: "Mandram (VSG)",
      align: "left",
      width: "300px",
      height: "100px",
      render: (items) => {
        return items?.name;
      },
    },
    {
      title: "Is Active",
      align: "left",
      width: "300px",
      height: "100px",
      render: (items) => {
        const is_active = items?.is_active;
        return is_active === "true" ? "yes" : "No";
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   align: "left",
    //   width: "300px",
    //   height: "100px",
    //   render: (_, record) => (
    //     <>
    //       <Space>
    //         <Button
    //           style={{
    //             backgroundColor: "#168f92",
    //             color: "white",
    //             borderRadius: "6px",
    //             border: "transparent",
    //           }}
    //           onClick={handle_edit_mandram_user}
    //         >
    //           Edit
    //         </Button>
    //         <Button
    //           style={{
    //             backgroundColor: "#f96451",
    //             color: "white",
    //             borderRadius: "6px",
    //             border: "transparent",
    //           }}
    //           onClick={handle_delete_mandram_user}
    //         >
    //           Delete
    //         </Button>
    //       </Space>
    //     </>
    //   ),
    // },
  ];

  return (
    <Row style={{ width: "100%" }} key={key}>
      <Table
        columns={columns}
        dataSource={mandram_items}
        pagination={false}
        size="large"
        key={key}
        style={{ width: "100%" }}
        rowKey={record_id}
        expandedRowKeys={expanded_row_key}
        // key={expanded_row_key}
      />
      {/* <DeleteMandramModal
        set_delete_mandram={set_delete_mandram}
        delete_mandram={delete_mandram}
        header_type={header_type}
        set_header_type={set_header_type}
      /> */}
      {/* <AddMandramUserModal
        set_add_mandram_user={set_add_mandram_user}
        mandram_user={mandram_user}
        header_type={header_type}
        set_header_type={set_header_type}
      /> */}
    </Row>
  );
};

export default MandramUserList;
