import React from "react";
import { Card, Row } from "antd";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";
import BookingHistoryForm from "../components/booking_history_new/booking_history_form/booking_history_form";
import BookingHistoryList from "../components/booking_history_new/booking_history_collapse/booking_history_list";
import BookingHistoryCount from "../components/booking_history_new/booking_history_count";

const BookingHistory = ({ date }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <div style={{ marginLeft: "18px" }}>
            <CustomPageTitle title={t("booking_history_title")} />
          </div>
        </Row>
      </Row>

      <Card
        style={{
          width: "96%",
          margin: "auto",
          minHeight: 0,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <BookingHistoryForm />
        <BookingHistoryCount />
        <div style={{ marginTop: "30px" }}>
          <BookingHistoryList />
        </div>
      </Card>
    </>
  );
};
export default BookingHistory;
