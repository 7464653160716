import { Form, Input, Select } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../../services/redux";

const EnrolmentDdBank = (props) => {
  const { payment_mode } = props;
  const { items } = useDynamicSelector("get_bank");
  console.log("items", items);

  return (
    <Form.Item
      label="Bank"
      name="bank"
      rules={
        payment_mode === "dd"
          ? [
              {
                required: true,
                message: "Please Select the Bank",
              },
            ]
          : []
      }
    >
      <Select style={{ width: "100%" }} placeholder="Select Bank" allowClear>
        {items?.map((bank) => (
          <Select.Option key={bank.name} value={bank.id}>
            {bank.name.toUpperCase()}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default EnrolmentDdBank;
