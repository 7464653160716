import React, { useEffect, useState } from "react";
import ArrivedCount from "./inputs/arrived_count";
import { Form } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  get_one_bank,
  mutation_update_enrolment,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { startCase } from "lodash";
import PaymentModeInput from "./inputs/payment_mode";
import CashInput from "./inputs/cash_input";
import PosInput from "./inputs/pos_input";
import EnrolmentDdAmount from "./inputs/enrolment_dd_amount";
import EnrolmentDdBank from "./inputs/enrolment_dd_bank";
import EnrolmentDdReference from "./inputs/enrolment_dd_reference";
import EnrolmentDdDateTime from "./inputs/enrolment_dd_date_time";

const UpdateEnrolmentForm = ({
  set_update_modal_visible,
  record,
  get_all_enrollment_list,
}) => {
  const enrolment_payment_mode = record?.payment?.payment_mode;
  const [form] = Form.useForm();
  const [payment_mode, set_payment_mode] = useState(enrolment_payment_mode);
  console.log("payment_mode", payment_mode);
  const [selected_date, set_selected_date] = useState("");
  const [total_amount, set_total_amount] = useState(0);
  const [current_total, set_current_total] = useState();
  const [updated_arrived_count, set_updated_arrived_count] = useState(0);
  const dispatch = useDispatch();
  const { loading, status, error } = useDynamicSelector("update_enrolment");

  const on_finish = (values) => {
    console.log("update_enrol", values);
    update_enrolment(values);
  };

  const update_enrolment = (values) => {
    let keys = [{ key: "update_enrolment", loading: true }];
    let query = mutation_update_enrolment;
    let variable = {
      id: record?.id,
      data: {
        arrived_count: values.arrived_count,
        dd_bank_id: values.bank,
        dd_amount: values.dd_amount,
        dd_reference: values.dd_reference,
        dd_datetime: selected_date,
        pos_receipt: values.pos_reference,
        payment_mode: values.payment_mode,
        amount: total_amount,
        booking_payment: "true",
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const get_bank_list = () => {
    let key = [{ key: "get_bank", loading: true }];
    let query = get_one_bank;
    dispatch(dynamicRequest(key, query));
  };

  useEffect(() => {
    get_bank_list();
  }, []);

  useEffect(() => {
    form.setFieldsValue({ arrived_count: record?.arrived_count });
    form.setFieldsValue({ payment_mode: record?.payment?.payment_mode });
  }, [record]);

  useEffect(() => {
    if (status === "success") {
      toast.success("Enrolment updated");
      dispatch(dynamicClear("update_enrolment"));
      set_update_modal_visible(false);
      get_all_enrollment_list();
      set_selected_date("");
      set_payment_mode("");
    } else if (error?.message) {
      toast.error(startCase(error?.message));
      set_update_modal_visible(false);
      dispatch(dynamicClear("update_enrolment"));
    }
  }, [status, error]);

  useEffect(() => {
    if (updated_arrived_count) {
      const amount = updated_arrived_count * record?.booking?.price;
      set_total_amount(amount);
    } else {
      const current_amount = record?.arrived_count * record?.booking.price;
      set_total_amount(current_amount);
    }
  }, [record, updated_arrived_count]);

  useEffect(() => {
    if (payment_mode === "dd") {
      form.setFieldsValue({ dd_amount: total_amount });
    }
  }, [payment_mode, total_amount]);

  useEffect(() => {
    if (
      payment_mode === "cash" ||
      payment_mode === "card" ||
      payment_mode === "upi"
    ) {
      form.setFieldsValue({ cash: total_amount });
    }
  }, [payment_mode, total_amount]);
  return (
    <Form onFinish={on_finish} form={form} id="update_enrolment_form">
      <ArrivedCount set_updated_arrived_count={set_updated_arrived_count} />
      <PaymentModeInput set_payment_mode={set_payment_mode} />
      {payment_mode === "cash" ? (
        <div>
          <CashInput payment_mode={payment_mode} />
        </div>
      ) : (
        ""
      )}
      {payment_mode === "upi" || payment_mode === "card" ? (
        <div>
          <PosInput payment_mode={payment_mode} />
          <CashInput payment_mode={payment_mode} />
        </div>
      ) : (
        ""
      )}
      {payment_mode === "dd" ? (
        <div>
          <EnrolmentDdAmount payment_mode={payment_mode} />
          <EnrolmentDdBank payment_mode={payment_mode} />
          <EnrolmentDdReference payment_mode={payment_mode} />
          <EnrolmentDdDateTime
            set_selected_date={set_selected_date}
            payment_mode={payment_mode}
          />
        </div>
      ) : (
        ""
      )}
    </Form>
  );
};

export default UpdateEnrolmentForm;
