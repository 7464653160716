import { Form, Switch } from "antd";
import React from "react";

const InputSubDistrictIsVIP = (props) => {
  const is_add_district = props.header_type === "add_district";

  return (
    <>
      <Form.Item
        name="is_vip"
        label="VIP"
        valuePropName="checked"
        // rules={
        //   is_add_district
        //     ? [{ required: true, message: "Please activate VIP" }]
        //     : []
        // }
      >
        <Switch defaultChecked={false} />
      </Form.Item>
    </>
  );
};

export default InputSubDistrictIsVIP;
