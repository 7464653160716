import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ProfileMobileNUmberFormItem = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        inputMode="numeric"
        name="mobile"
        label={t("mobile_number")}
        rules={[
          {
            required: true,
            message: t("mobile_number_is_required"),
          },
          // {
          //   pattern: /^[0-9]{10}$/,
          //   message: "Please enter a valid 10-digit mobile number .",
          // },
        ]}
      >
        <Input placeholder="Mobile Number" />
      </Form.Item>
    </div>
  );
};

export default ProfileMobileNUmberFormItem;
