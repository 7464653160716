import { Form, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SpotBookingMandram = ({
  mandram_items,
  set_mandram_item,
  set_initial_values,
  set_mandram_id,
}) => {
  const { t } = useTranslation();
  const options = mandram_items || [];

  const onchange_mandram = (selected_mandram) => {
    set_mandram_id(selected_mandram?.value);
    const parts = selected_mandram?.key?.split("/");
    const mandram_vsg = parts?.[0];
    const code = parts?.[1];
    const name = parts?.[2];
    const incharge_name = parts?.[3];
    const incharge_mobile = parts?.[4];
    set_initial_values({
      mandram_vsg: mandram_vsg,
      code: code,
      name: name,
      incharge_name: incharge_name,
      incharge_mobile: incharge_mobile,
      mandram_id: selected_mandram?.value,
    });
    set_mandram_item({ code, name, incharge_name, incharge_mobile });
  };

  options.map((a) => a.id);

  return (
    <Form.Item
      label={t("mandram_vsg")}
      name="mandram_vsg"
      rules={[
        {
          required: true,
          message: "Please select mandram",
        },
      ]}
    >
      <Select
        style={{
          width: "100%",
        }}
        placeholder={t("mandram")}
        onChange={(value, option) => onchange_mandram(option)}
        allowClear
        showSearch
        filterOption={(input, option) =>
          option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {options.map((mandram) => (
          <Select.Option
            key={`${mandram?.name} - ${mandram?.code}/${mandram?.code}/${mandram?.name}/${mandram?.incharge_name}/${mandram?.incharge_mobile}`}
            value={mandram.id}
          >
            {`${mandram?.code}/${mandram?.name}`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SpotBookingMandram;
