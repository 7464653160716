import { Card, Row, Typography } from "antd";
import React from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import BankExtraAllowedPercentage from "../components/extra_allowed_percentage/extra_allowed_percentage";
import { useDynamicSelector } from "../../services/redux";

const ExtraAllowedPercentage = () => {
  const { t } = useTranslation();

  const { id, bank_branch_extra_allowed_percentage } =
    useDynamicSelector("get_settings");
  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <div style={{ marginLeft: "18px" }}>
            <CustomPageTitle title={t("general_settings")} />
          </div>
        </Row>

        <Row style={{ margin: "8px", marginRight: "23px" }}>
          {/* <CustomBreadCrumbs pages={"Home/Booking History"} /> */}
        </Row>
      </Row>
      <Card
        style={{
          width: "45%",
          margin: "5px auto",
          minHeight: 0,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Typography
            style={{ fontWeight: "bold", fontSize: "15px", color: "green" }}
          >
            Current Bank Branch Extra Enrollment allowed percentage
          </Typography>
          <Typography
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              fontSize: "15px",
              color: "green",
            }}
          >
            :
          </Typography>
          <Typography
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              fontSize: "15px",
              color: "green",
            }}
          >
            {bank_branch_extra_allowed_percentage}
          </Typography>
        </Row>

        <BankExtraAllowedPercentage />
      </Card>
      {/* <Card
        style={{
          width: "45%",
          margin: "5px auto",
          minHeight: 0,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#E5E8E8 ",
        }}
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Typography style={{ fontWeight: "bold", fontSize: "15px" }}>
            Current Bank Branch Extra Enrollment allowed percentage
          </Typography>
          <Typography
            style={{ fontWeight: "bold", marginLeft: "10px", fontSize: "15px" }}
          >
            :
          </Typography>
          <Typography
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              fontSize: "15px",
            }}
          >
            {bank_branch_extra_allowed_percentage}
          </Typography>
        </Row>
      </Card> */}
    </>
  );
};

export default ExtraAllowedPercentage;
