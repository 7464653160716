import React, { useEffect } from "react";
import { Form, Select } from "antd";
import {
  dynamicRequest,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { query_get_unassigned_pos_list } from "../../../../../services/redux/slices/graphql/graphql_district";

const { Option } = Select;

const InputSubDistrictPosSelector = ({ type, record_id, name, header_type }) => {
  const { items: pos_list } = useDynamicSelector("get_unassigned_pos_list");
  const dispatch = useDispatch();
  const get_all_unassigned_pos = () => {
    let keys = [{ key: "get_unassigned_pos_list", loading: true }];
    let query = query_get_unassigned_pos_list;

    let variable = {
      filter: {
        [header_type === "edit_user" ? "user_id" : "district_id"]: record_id,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    get_all_unassigned_pos();
  }, [record_id]);

  return (
    <Form.Item name={name} label={name === "pos_id" ? "POS " : "POS List"}>
      <Select
        style={{ width: "270px" }}
        mode={type === "district_user" ? "" : "multiple"}
        allowClear={true}
        placeholder="Select POS"
        showSearch={true}
      >
        {pos_list?.map((pos) => (
          <Option key={pos?.id} value={pos?.id}>
            {`${pos?.name} / ${pos?.terminal_id}`}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default InputSubDistrictPosSelector;
