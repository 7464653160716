import { DatePicker, Form } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

const BookingAvailabilityStartDate = () => {
  const { t } = useTranslation();
  const disabledDate = (current) => {
    const today = new Date();
    const currentDate = moment();
    return current && current <= currentDate.startOf("day");
  };

  const currentDate = new Date();

  return (
    <div>
      <Form.Item
        name="start_date"
        label={t("start_date")}
        rules={[
          {
            required: true,
            message: "Start Date is required",
          },
        ]}
        style={{ width: "400px" }}
      >
        <DatePicker
          placeholder={t("start_date")}
          defaultPickerValue={moment()}
          disabledDate={disabledDate}
        />
      </Form.Item>
    </div>
  );
};

export default BookingAvailabilityStartDate;
