import { Pagination, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  query_get_bank_list,
} from "../../../services/redux/slices";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../../services/redux/selector";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const BankList = (props) => {
  const {
    set_bank_modal_title,
    set_bank_modal_open,
    handle_delete_bank,
    set_selected_items,
    get_all_bank_list,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [current_page_limit, set_current_page_limit] = useState(5);
  // const [current_page, set_current_page] = useState(1);

  const [current_limit, setCurrentLimit] = useState(10);
  const [current, setCurrent] = useState(1);

  // const fetch_data = (page_number, page_limit) => {
  //   let key = [{ key: "get_bank_list", loading: true }];
  //   let query = query_get_bank_list;
  //   let variable = {
  //     page_number: page_number,
  //     page_limit: page_limit,
  //   };
  //   dispatch(dynamicRequest(key, query, variable));
  //   set_current_page(page_number);
  //   set_current_page_limit(page_limit);
  // };

  const {
    items: bank_list,
    pagination: bank_pagination,
    loading: bank_loading,
  } = useDynamicSelector("get_bank_list");
  const { status: create_bank_status, error: create_bank_error } =
    useDynamicSelector("create_bank");
  const { status: update_bank_status, error: update_bank_error } =
    useDynamicSelector("update_bank");

  const fetch_data = (page_number, page_limit) => {
    let key = [{ key: "get_bank_list", loading: true }];
    let query = query_get_bank_list;
    let variables = {
      pagination: {
        page_limit: page_limit,
        page_number: page_number,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };

  const handle_edit_bank = (record) => {
    set_bank_modal_open(true);
    set_bank_modal_title("edit");
    set_selected_items(record);
  };

  useEffect(() => {
    if (create_bank_status === "success") {
      toast.success("Bank Created");
      dispatch(dynamicClear("create_bank"));
      get_all_bank_list();
      set_bank_modal_open(false);
    } else if (create_bank_error?.message) {
      toast.error(create_bank_error.message);
      dispatch(dynamicClear("create_bank"));
      set_bank_modal_open(false);
    } else if (update_bank_status === "success") {
      toast.success("Bank Updated");
      dispatch(dynamicClear("update_bank"));
      get_all_bank_list();
      set_bank_modal_open(false);
    } else if (update_bank_error?.message) {
      toast.error(update_bank_error.message);
      dispatch(dynamicClear("update_bank"));
      set_bank_modal_open(false);
    }
  });

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      width: "300px",
      align: "center",

      render: (_, record) => (
        <Space
          style={{
            width: "50px",
            justifyContent: "space-between",
          }}
        >
          <EditOutlined onClick={() => handle_edit_bank(record)} />
          <DeleteOutlined onClick={() => handle_delete_bank(record)} />
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      {/* <Row> */}
      <Table
        style={{ width: "100%" }}
        columns={columns}
        dataSource={bank_list}
        loading={bank_loading}
        pagination={false}
        // pagination={{
        //   current: current_page,
        //   pageSize: current_page_limit,
        //   total: pagination?.total_count,
        //   showSizeChanger: true,
        //   onChange: fetch_data,
        //   onShowSizeChange: (current, size) => {
        //     get_all_bank_list(current, size);
        //   },
        // }}
      />
      {/* <div></div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
          <Pagination
            total={bank_pagination?.total_count}
            showSizeChanger={true}
            current={current}
            hideOnSinglePage={false}
            onChange={fetch_data}
            style={{ marginTop: "10px" }}
          />
      </div>
      {/* </Row> */}
    </div>
  );
};
export default BankList;
