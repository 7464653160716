import { Form, Select } from "antd";
import React, { useEffect } from "react";
import {
  dynamicRequest,
  query_get_all_mandrams,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const BookingReportMandram = (props) => {
  const { district_id, sub_district_id } = props;
  const dispatch = useDispatch();
  const { items: mandram_items } = useDynamicSelector("get_all_mandrams");

  const get_mandram_query = () => {
    let key = [{ key: "get_all_mandrams", loading: true }];
    let query = query_get_all_mandrams;
    let variable = {
      filter: {
        district_id: district_id,
        sub_district_id_list: sub_district_id,
      },
      sort: {
        field: "internal_code",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  useEffect(() => {
    get_mandram_query();
  }, [district_id, sub_district_id]);
  return (
    <Form.Item name="mandram_name_input" label="Mandram">
      <Select
        placeholder="Select Mandram"
        allowClear
        showSearch
        mode="multiple"
        filterOption={(input, option) =>
          option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {mandram_items?.map((option, index) => (
          <Select.Option key={option.id} value={option.id}>
            {`${option?.code}/${option?.name}`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default BookingReportMandram;
