import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Pagination, Row, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { MdOutlineCancel, MdOutlineCheckCircle } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  get_feedback,
  get_feedback_list,
  useDynamicSelector,
} from "../../../../services/redux";

const FeedBackManageTable = (props) => {
  const dispatch = useDispatch();
  const {
    handle_edit,
    handle_add,
    show_delete_model,
    handle_manage,
    handle_active,
    is_active,
    feedback_list_items,
    loading,
  } = props;
  const {} = useDynamicSelector("get_feedback");

  const [current, set_current] = useState(1);
  const [current_limit, set_current_limit] = useState(5);

  // const fetch_data = (page_number, page_limit) => {
  //   let keys = [{ key: "get_feedback", loading: true }];
  //   let query = get_feedback;
  //   let variable = {
  //     page_number: page_number,
  //     page_limit: page_limit,
  //   };
  //   dispatch(dynamicRequest(keys, query, variable));
  //   set_current(page_number);
  //   set_current_limit(page_limit);
  // };

  useEffect(() => {
    let keys = [{ key: "get_feedback", loading: true }];
    dispatch(
      dynamicRequest(keys, get_feedback_list, {
        page_number: current,
        page_limit: current_limit,
      })
    );
  }, []);
  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_feedback", loading: true }];
    dispatch(
      dynamicRequest(keys, get_feedback_list, {
        page_number: page_number,
        page_limit: page_limit,
      })
    );
    set_current_limit(page_limit);
    set_current(page_number);
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "200px",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "200px",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "200px",
      render: (text, record, row_index) => (
        <Row>
          <Space size="middle">
            <Button onClick={() => handle_edit(record)} type="link">
              <EditOutlined />
            </Button>
            <Button onClick={() => handle_manage(record)} type="link">
              <InfoCircleOutlined />
            </Button>
            <Button onClick={() => show_delete_model(record)} type="link">
              <DeleteOutlined />
            </Button>
            <Button
              onClick={() => {
                handle_active(record, !record.is_active);
              }}
              type="link"
            >
              {record.is_active ? (
                <MdOutlineCheckCircle size={18} />
              ) : (
                <MdOutlineCancel size={18} />
              )}
            </Button>
          </Space>
        </Row>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Card
          style={{
            width: "95%",
            borderRadius: "10px",
            borderRadius: "10px",
            border: "1px solid #e0e0e0",
            boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{
                marginBottom: "20px",
                backgroundColor: "#179093",
                color: "white",
              }}
              onClick={handle_add}
            >
              Add Feedback
            </Button>
          </div>
          <Table
            dataSource={feedback_list_items}
            columns={columns}
            loading={loading}
            pagination={false}
            // pagination={{
            //   current: current,
            //   pageSize: current_limit,
            //   total: feedback_list_items?.length,
            //   showSizeChanger: true,
            //   onChange: fetch_data,
            //   onShowSizeChange: (current, size) => {
            //     fetch_data(current, size);
            //   },
            // }}
          ></Table>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Pagination
              total={feedback_list_items?.total_count}
              showSizeChanger={true}
              current={current}
              hideOnSinglePage={false}
              onChange={fetchData}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default FeedBackManageTable;
