import { Form, Input } from "antd";
import React from "react";

const ExpandableOptionFormItem = () => {
  return (
    <div>
      <Form.Item
        name="option"
        label="Option"
        rules={[
          {
            required: true,
            message: "Option is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};

export default ExpandableOptionFormItem;
