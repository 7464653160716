import { Button, Modal, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect } from "react";
import {
  delete_mandram_query,
  dynamicClear,
  dynamicRequest,
} from "../../../services/redux/slices";
import { useDynamicSelector } from "../../../services/redux/selector";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const DeleteMandramModal = (props) => {
  const {
    delete_mandram,
    set_delete_mandram,
    header_type,
    mandram_delete_id,
    get_all_mandram_list,
    mandram_delete_name,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    status: delete_mandram_status,
    loading: delete_mandram_loading,
    error: mandram_delete_error,
  } = useDynamicSelector("delete_mandram");

  const handle_delete_mandram = () => {
    delete_one_mandram();
  };

  const delete_one_mandram = () => {
    let key = [{ key: "delete_mandram", loading: true }];
    let query = delete_mandram_query;
    let variables = {
      id: mandram_delete_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  useEffect(() => {
    if (delete_mandram_status === "success") {
      set_delete_mandram(false);
      toast.success("Mandram Deleted Successfully");
      dispatch(dynamicClear("delete_mandram"));
      get_all_mandram_list();
    } else if (mandram_delete_error) {
      toast.error("Something went wrong");
      dispatch(dynamicClear("delete_mandram"));
    }
  }, [delete_mandram_status, mandram_delete_error]);
  return (
    <Modal
      data-custom-delete-modal="custom-delete-modal-header"
      title={header_type}
      open={delete_mandram}
      onCancel={() => set_delete_mandram(false)}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      footer={[
        <Button
          style={{
            backgroundColor: "#168f92",
            color: "white",
            border: "transparent",
          }}
          onClick={() => set_delete_mandram(false)}
        >
          {t("cancel")}
        </Button>,
        <Button
          style={{
            backgroundColor: "#f96451",
            color: "white",
            border: "transparent",
          }}
          onClick={handle_delete_mandram}
        >
          {t("delete")}
        </Button>,
      ]}
    >
      <Content>
        Are you sure want to delete
        <Text style={{ fontWeight: "bold", marginLeft: "6px" }}>
          {mandram_delete_name}
        </Text>
        <Text> &nbsp;?</Text>
      </Content>
    </Modal>
  );
};

export default DeleteMandramModal;
