import { gql } from "@apollo/client";

export const sakthi_peedam_report_dates_query = gql`
  query get_season_date_slot_list {
    sakthi_peedam_date: get_season_date_slot_list {
      items {
        id
        season_id
        date
        date_string
      }
      error {
        message
        status_code
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;

export const sakthi_peedam_report_query = gql`
  query get_sakthi_peedam($filter: sakthi_peedam_filter_input) {
    get_sakthi_peedam(filter: $filter) {
      items {
        sakthi_peedam_code
        sakthi_peedam_name
        devotee_count
      }
      error {
        message
        status_code
      }
    }
  }
`;
