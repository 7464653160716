import { Button, Col, Row, Typography } from "antd";
import React, { useState } from "react";
import PageModal from "./page_modal";
import PageList from "./page_list";
import SyncEndPoints from "../end_points/sync_end_points";
import EndPointsModal from "../sync_end_points/end_points_modal";
import { useTranslation } from "react-i18next";
import CustomButton from "../../common/custom_button";
import { IoMdAddCircleOutline } from "react-icons/io";

const PageComponent = () => {
  const { t } = useTranslation();
  const { Text } = Typography;

  const [modal_open, set_open_modal] = useState(false);
  const [link_modal_open, set_link_open_modal] = useState(false);

  const [get_id, set_filed_id] = useState("");
  const [action_type, set_action_type] = useState(null);
  const [link_type, set_link_type] = useState(null);

  const [action_item, set_action_item] = useState({});
  const [expanded_row_key, set_expanded_row_key] = useState(null);

  const handle_add = () => {
    set_open_modal(true);
    set_action_type("add_page");
    set_action_item({});
  };
  const handle_close = () => {
    set_open_modal(false);
    set_action_type("");
  };
  const handle_link_close = () => {
    set_link_open_modal(false);
    set_action_type("");
  };

  const handle_submit = (e) => {};
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          padding: "10px 0px",
          justifyContent: "space-between",
        }}
      >
        <p></p>
        <div style={{ display: "flex", marginLeft: "-35px" }}>
          <Col
            span={6}
            style={{
              textAlign: "end",
              marginLeft: "170px",
              marginRight: "20px",
            }}
          >
            <CustomButton
              icon={<IoMdAddCircleOutline />}
              onclick_button={handle_add}
              button_name={t("add_page")}
            />
          </Col>
          <Col span={6} style={{ textAlign: "end" }}>
            <SyncEndPoints />
          </Col>
        </div>
      </div>

      <PageModal
        modal_open={modal_open}
        handle_close={handle_close}
        handle_submit={handle_submit}
        action_type={action_type}
        id={get_id}
        action_item={action_item}
        link_type={link_type}
        set_expanded_row_key={set_expanded_row_key}
      />
      <EndPointsModal
        action_type={action_type}
        link_type={link_type}
        link_modal_open={link_modal_open}
        handle_link_close={handle_link_close}
        id={get_id}
        handle_submit={handle_submit}
        action_item={action_item}
      />
      <PageList
        set_open_modal={set_open_modal}
        set_action_type={set_action_type}
        set_filed_id={set_filed_id}
        set_action_item={set_action_item}
        set_link_open_modal={set_link_open_modal}
        get_id={get_id}
        set_expanded_row_key={set_expanded_row_key}
        expanded_row_key={expanded_row_key}
        set_link_type={set_link_type}
      />
    </div>
  );
};
export default PageComponent;
