import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SystemReferenceId = () => {
  const { t } = useTranslation();
  return (
    <Form.Item label={t("system_reference")} name="system_reference_id">
      <Input placeholder="System Reference" />
    </Form.Item>
  );
};
export default SystemReferenceId;
