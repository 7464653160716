import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Pagination, Space, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";

import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../helpers/functions";
import {
  dynamicRequest,
  dynamicSet,
  get_mandram_list_query,
  useDynamicSelector,
} from "../../../services/redux";
import { countFormat, formatStatus } from "../../../helpers/constants";
import { useTranslation } from "react-i18next";
import { filter } from "lodash";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const MandramRenewalList = () => {
  const dispatch = useDispatch();
  const search_input = useRef(null);
  const [search_text, set_search_text] = useState({});
  const { t } = useTranslation();
  const { id: params_district_id } = useParams();
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const {
    items: mandram_items,
    pagination,
    loading: mandram_loading,
  } = useDynamicSelector("get_mandram_list");

  console.log("pagination", pagination);

  const { status: bulk_update_status, error: bulk_error_status } =
    useDynamicSelector("update_many_mandram_status");

  const { key: activate_key } = useDynamicSelector("activate_key");
  const role = retrieveItem("role");

  const get_column_search_props = (data_index, custom_title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={search_input}
          placeholder={`Search ${custom_title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handle_search(selectedKeys, confirm, data_index)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handle_reset(clearFilters, data_index)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handle_search(selectedKeys, confirm, data_index)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : "white" }} />
    ),
    onFilter: (value, record) =>
      record[data_index]
        ? record[data_index]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => search_input.current?.select(), 100);
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{
          backgroundColor: "#ffc069",
          padding: 0,
        }}
        searchWords={[search_text[data_index]]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });
  const handle_search = (selectedKeys, confirm, data_index) => {
    confirm();
    set_search_text({ ...search_text, [data_index]: selectedKeys[0] });
  };

  const get_mandram_list = (search_string = "", get_sub_district_id) => {
    let key = [{ key: "get_mandram_list", loading: true }];
    let query = get_mandram_list_query;
    let variables = {
      filter: {
        district_id: params_district_id,
      },
      pagination: {
        page_number: page_number,
        page_limit: page_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_mandram_list();
  }, [page_limit, page_number]);

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      align: "center",
      width: "200px",
      height: "100px",
      ...get_column_search_props("code", "Code"),
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      width: "250px",
      height: "100px",
      ...get_column_search_props("name", "Name"),
    },
    {
      title: "District",
      dataIndex: ["district", "name"],
      align: "left",
      width: "300px",
      height: "100px",
      ...get_column_search_props(["district", "name"], "District"),
    },
    {
      title: "Incharge",
      dataIndex: "incharge_name",
      align: "left",
      width: "200px",
      height: "100px",
      ...get_column_search_props("incharge", "Incharge"),
    },
    {
      title: "Incharge Mobile",
      dataIndex: "incharge_mobile",
      align: "center",
      width: "300px",
      height: "100px",
      ...get_column_search_props("incharge_mobile", "Incharge Mobile"),
    },
    {
      title: "Status",
      dataIndex: "activation_status",
      align: "center",
      width: "300px",
      height: "100px",
      render: formatStatus,
    },
  ];
  const on_change_select = (value, selected_rows) => {
    dispatch(dynamicSet("activate_key", { key: value }));
  };

  const fetch_data = (page_number, page_limit) => {
    console.log("page_number", page_number, page_limit);

    set_page_number(page_number);
    set_page_limit(page_limit);
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={mandram_items}
        loading={mandram_loading}
        pagination={false}
        rowKey={"id"}
        rowSelection={{
          type: "checkbox",
          onChange: on_change_select,
          selectedRowKeys: activate_key,
        }}
      />
      <Pagination
        showTotal={(total, range) => `Total ${countFormat(total)} Madrams`}
        total={pagination?.total_count}
        showSizeChanger={true}
        current={page_number}
        hideOnSinglePage={false}
        onChange={fetch_data}
      />
    </div>
  );
};

export default MandramRenewalList;
