import { Card, Typography } from "antd";
import React from "react";
import { navigate } from "@helpers/functions";
import { useTranslation } from "react-i18next";
import DistrictAdministrationCard from "../components/district_administration/district_administration_card";
import { Navigate } from "react-router";
import { ROUTES } from "../routes/my_routes";
import CustomPageTitle from "../components/common/custom_page_title";

const DistrictAdministration = () => {
  const { t } = useTranslation();
  const handle_navigate = () => {
    // navigate(`/areas/${record?.id}/${record.level.id}`);
    navigate(ROUTES.DISTRICT_ADMINISTRATION_TABLE);
  };
  return (
    <div>
      <CustomPageTitle title={t("district_administration_title")} />
      <div>
        <DistrictAdministrationCard handle_navigate={handle_navigate} />
      </div>
    </div>
  );
};

export default DistrictAdministration;
