import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { query_get_sub_district_list } from "../../../../services/redux/slices/graphql/graphql_sub_district";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "../../../../services/redux";

const SubDistrictOptions = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { district_id, set_sub_district_id } = props;
  const { items: sub_district_options } = useDynamicSelector(
    "get_all_sub_district_list"
  );

  useEffect(() => {
    if (district_id) {
      get_sub_districts_list();
    }
  }, [district_id]);

  const get_sub_districts_list = (values) => {
    let keys = [{ key: "get_all_sub_district_list", loading: true }];
    let query = query_get_sub_district_list;
    let variable = {
      filter: {
        district_id: district_id,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };
  const onchange_district = (option) => {
    set_sub_district_id(option?.value);
  };
  return (
    <Form.Item label={t("sub_district")} name="sub_district">
      <Select
        style={{
          width: "100%",
        }}
        placeholder={t("sub_district")}
        onChange={(value, option) => onchange_district(option)}
        allowClear
        showSearch
        // mode="multiple"
        disabled={district_id ? false : true}
        filterOption={(input, option) =>
          option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {sub_district_options?.map((sub_district) => (
          <Select.Option
            key={`${sub_district?.name} - ${sub_district?.code}/${sub_district?.code}/${sub_district?.name}/${sub_district?.incharge_name}/${sub_district?.incharge_mobile}`}
            value={sub_district.id}
          >
            {`${sub_district?.code}/${sub_district?.name}`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SubDistrictOptions;
