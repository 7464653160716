import { gql } from "@apollo/client";

export const query_get_all_user_group = gql`
  query get_user_group_list($pagination: pagination_input, $sort: sort_input) {
    get_user_group_list(pagination: $pagination, sort: $sort) {
      items {
        id
        name
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_create_user_group = gql`
  mutation create_user_group($data: create_user_group_input) {
    create_user_group(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_user_group = gql`
  mutation update_user_group($id: String!, $data: update_user_group_input) {
    update_user_group(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_one_user_group = gql`
  query get_user_group($id: String!) {
    get_user_group(id: $id) {
      id
      name
    }
  }
`;

export const mutation_delete_user_group = gql`
  mutation delete_user_group($id: String!) {
    delete_user_group(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

// export const get_current_counter_query = gql`
//   query get_current_counter($data: get_current_counter_input) {
//     get_current_counter(data: $data) {
//       id
//       name
//       code
//     }
//   }
// `;
