import { Col, Row } from "antd";
import React, { useEffect } from "react";
import SearchReceipt from "../components/receipt/search_receipt";
import AvailableReceipts from "../components/receipt/available_receipts/available_receipts";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";
import { dynamicClear, useDynamicSelector } from "../../services/redux";
import LoaderSpinner from "../components/common/custom_spinner";
import { useDispatch } from "react-redux";

const Receipt = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { loading: loading_booking_detail } = useDynamicSelector(
    "get_booking_receipt"
  );

  useEffect(() => {
    dispatch(dynamicClear("get_booking_receipt"));
  }, []);

  return (
    <Col style={{ margin: "5px", width: "98%" }}>
      <Row
        style={{
          display: "flex",
          margin: "auto",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <CustomPageTitle title={t("RECEIPT")} />
        {/* <CustomBreadcrumbs pages={"Home/District"} /> */}
      </Row>
      <SearchReceipt />

      {loading_booking_detail ? (
        <Row
          style={{
            marginTop: "5%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoaderSpinner
            loading_booking_detail={loading_booking_detail}
            message={t("wait_receipt_fetch")}
          />
        </Row>
      ) : (
        <AvailableReceipts loading_booking_detail={loading_booking_detail} />
      )}
    </Col>
  );
};

export default Receipt;
