import React, { useEffect, useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { Card, Row } from "antd";
import EnrollActionMainForm from "../components/enroll_action/enroll_action_main_form/enroll_action_main_form";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  query_enroll_action,
  useDynamicSelector,
} from "../../services/redux";
import EnrollActionList from "../components/enroll_action/enroll_action_list/enroll_action_list";
import CustomNoData from "../components/common/custom_no_data";
import LoaderSpinner from "../components/common/custom_spinner";
import { useTranslation } from "react-i18next";

const EnrollAction = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [enroll_numbers, set_enroll_number] = useState();
  console.log("enroll_numbers5", enroll_numbers);
  const { booking_reference, enrolment_list, loading } =
    useDynamicSelector("get_enroll_action");
  console.log("booking_reference", booking_reference, enrolment_list);
  const receipt_no = enrolment_list?.map((count) => {
    return count?.sequence_count?.count;
  });
  console.log("receipt_no", receipt_no);

  // useEffect(() => {
  //   // Dispatch the dynamicRequest action in the useEffect
  //   dispatch(
  //     dynamicRequest(
  //       [{ key: "get_enroll_action", loading: true }],
  //       query_enroll_action,
  //       null
  //     )
  //   );
  // }, []);

  const enroll_action = (values) => {
    let key = [{ key: "get_enroll_action", loading: true }];
    let query = query_enroll_action;
    let variables = {
      filter: {
        booking_reference: values.booking_reference,
        receipt_number: values.receipt_number,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    dispatch(dynamicClear("get_enroll_action"));
  }, []);
  return (
    <>
      <div>
        <CustomPageTitle title={t("enroll_action")} />
        <Card
          style={{
            width: "95%",
            borderRadius: "10px",
            border: "1px solid #e0e0e0",
            boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
            marginLeft: "25px",
            backgroundColor: "#f0eae7",
          }}
        >
          <EnrollActionMainForm
            enroll_action={enroll_action}
            set_enroll_number={set_enroll_number}
          />
        </Card>
      </div>
      <Row
        style={{
          width: "95%",
          display: "flex",
          justifyContent: "center",
          marginLeft: "25px",
        }}
      >
        {loading ? (
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "40px" }}
          >
            <LoaderSpinner />
          </div>
        ) : (
          !booking_reference &&
          !receipt_no && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "40px",
              }}
            >
              <CustomNoData />
            </div>
          )
        )}
        <div style={{ marginTop: "20px" }}>
          {!loading && (booking_reference || receipt_no) && (
            <EnrollActionList receipt_no={receipt_no} />
          )}
        </div>
      </Row>
    </>
  );
};

export default EnrollAction;
