import React, { useEffect, useState } from "react";
import { Col, Form, Row, Select } from "antd";
import { useTranslation } from "react-i18next";

import {
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";

const BookingActionDate = ({ form }) => {
  const { t } = useTranslation();
  const [selected_date, set_selected_date] = useState();
  const dispatch = useDispatch();

  const { items: season_date_slot_list_items, loading: loading } =
    useDynamicSelector("get_season_date_slot_list");

  // const sorted_date_slot_items = _orderBy(
  //   season_date_slot_list_items,
  //   ["date_string"],
  //   ["asc"]
  // );

  const get_season_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_change = (value) => {
    set_selected_date(value);
  };

  useEffect(() => {
    get_season_date_slot_list();
  }, []);

  return (
    <Form.Item label={t("abishekam_date")} name="date">
      <Select
        allowClear
        style={{
          width: "100%",
        }}
        placeholder="Please select"
        onChange={handle_change}
      >
        {season_date_slot_list_items?.map((option) => (
          <Select.Option key={option?.id} value={option.id}>
            {option.date_string}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default BookingActionDate;
