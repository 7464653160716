import { Button, Card } from "antd";
import React from "react";
import BookingAvailabilityTable from "./booking_availability_table/booking_availability_table";

const BookingAvailabilityCard = (props) => {
  const {
    handle_Add,
    handle_enable,
    handle_details,
    startDate,
    endDate,
    show_delete_model,
    toggle_enable_button,
    enable_buttons,
    loading,
    show_enable_modal,
  } = props;
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card
          style={{
            width: "95%",
            border: "1px solid #e0e0e0",
            boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
            borderRadius: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{
                marginBottom: "20px",
                backgroundColor: "#209e91",
                color: "white",
              }}
              onClick={handle_Add}
            >
              Add Season
            </Button>
          </div>
          <div>
            <BookingAvailabilityTable
              handle_details={handle_details}
              handle_enable={handle_enable}
              startDate={startDate}
              endDate={endDate}
              show_delete_model={show_delete_model}
              toggle_enable_button={toggle_enable_button}
              enable_buttons={enable_buttons}
              loading={loading}
              show_enable_modal={show_enable_modal}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default BookingAvailabilityCard;
