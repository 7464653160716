import React, { useEffect, useState } from "react";
import BookingAvailabilityDateslotsCard from "../components/booking_availability/booking_availability_dataslots_card";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  active_season_date_slots_query,
  dynamicRequest,
  get_season_query,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import { navigateBack } from "../../helpers/functions";
import SeasonAvailabilityDateSlotsEditModal from "../components/booking_availability/booking_availability/booking_availability_table/booking_availability_modal/season_availability_edit_modal/season_availability_edit_modal";

const SeasonDateSlots = () => {
  const { title, id } = useParams();
  const dispatch = useDispatch();
  const [is_edit_model_visible, set_edit_modal_visible] = useState(false);
  const [header_type, set_header_type] = useState("");
  const [edit_values, set_edit_values] = useState();

  const { t } = useTranslation();
  const {} = useDynamicSelector("get_season");
  const { system_user } = useDynamicSelector("login");
  const { date_slots: active_season_date_slots } = useDynamicSelector(
    "active_season_date_slot"
  );

  useEffect(() => {
    if (id) {
      get_one_season_query();
    } else {
      active_date_slots();
    }
  }, []);

  const get_one_season_query = (values) => {
    let key = [{ key: "get_season", loading: true }];
    let query = get_season_query;
    let variables = {
      id: id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const active_date_slots = (values) => {
    let key = [{ key: "active_season_date_slot", loading: true }];
    let query = active_season_date_slots_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_back = () => {
    navigateBack();
  };
  const handle_edit = (values) => {
    set_edit_modal_visible(true);
    set_header_type("edit");
    set_edit_values(values);
  };
  return (
    <div>
      <div>
        {id ? (
          <CustomPageTitle title={title} />
        ) : (
          <CustomPageTitle title={t("active_date_slot")} />
        )}
      </div>
      <BookingAvailabilityDateslotsCard
        handle_back={handle_back}
        handle_edit={handle_edit}
        id={id}
      />

      <SeasonAvailabilityDateSlotsEditModal
        handle_edit={handle_edit}
        set_edit_modal_visible={set_edit_modal_visible}
        is_edit_model_visible={is_edit_model_visible}
        header_type={header_type}
        edit_values={edit_values}
        get_one_season_query={get_one_season_query}
        active_date_slots={active_date_slots}
      />
    </div>
  );
};

export default SeasonDateSlots;
