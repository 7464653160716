import { Col, Row } from "antd";
import React from "react";
import { countFormat } from "../../../../helpers/constants";

const DistrictHeaderList = (props) => {
  const { district } = props;
  return (
    <div>
      <Row>
        <Col span={3}>{district?.district_name}</Col>
        <Col span={3}>
          {"Booked: "} {district?.total_booked?.toLocaleString("en-IN")}
        </Col>
        <Col span={3}>
          {"Cancelled: "}{" "}
          {district?.total_cancelled_count?.toLocaleString("en-IN")}
        </Col>
        <Col span={3}>
          {"Enrolled: "} {district?.total_enrolled?.toLocaleString("en-IN")}
        </Col>

        <Col span={4}>
          {"Yet To Enroll: "}
          {/* {district?.total_yet_to_enroll_count < 0
            ? 0
            : district?.total_yet_to_enroll_count?.toLocaleString("en-IN")} */}
          {district?.total_yet_to_enroll_count}
        </Col>

        <Col span={3}>
          {"Excess: "}
          {/* {district?.total_yet_to_enroll_count < 0
            ? Math.abs(
                district?.total_yet_to_enroll_count?.toLocaleString("en-IN")
              )
            : 0} */}
          {district?.total_excess_count}
        </Col>

        <Col span={4}>
          {"Prepaid: "}{" "}
          {district?.total_prepaid_amount?.toLocaleString("en-IN")}
        </Col>
      </Row>
    </div>
  );
};

export default DistrictHeaderList;
