import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import _orderBy from "lodash/orderBy";
import {
  dynamicRequest,
  get_date_slots,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useTranslation } from "react-i18next";

const GroupWiseReportDate = (props) => {
  const { set_report_date, set_yesterday_date } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: date_slots } = useDynamicSelector(
    "counter_user_wise_report_date_slots"
  );

  const on_change = (values, option) => {
    console.log("option", option);
    set_report_date({ id: values, label: option?.label });
    console.log("values, option", values, option?.label);
    const selectedDateString = option?.label;
    const selectedMoment = moment(selectedDateString, "DD-MMM-YYYY");
    const yesterdayMoment = selectedMoment.clone().subtract(1, "days");
    const yesterdayDateString = yesterdayMoment?.format("DD-MMM-YYYY");
    set_yesterday_date(yesterdayDateString);
  };

  return (
    <div>
      <Form.Item
        name="date"
        label={t("date")}
        rules={[
          {
            required: true,
            message: "Please select date",
          },
        ]}
      >
        <Select
          placeholder="Select Date"
          onChange={on_change}
          allowClear
          // showSearch
          // filterOption={custom_filter_option}
        >
          {date_slots?.map((option, index) => (
            <Select.Option
              key={option?.id}
              value={option?.id}
              label={option?.date_string}
            >
              {option?.date_string}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};
export default GroupWiseReportDate;
