import { Form, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const DayEndTimeFormItem = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name="day_end_time_in_minutes"
        label={t("day_end_time")}
        rules={[
          {
            required: true,
            message: "Day end time is required",
          },
        ]}
        style={{ width: "400px" }}
      >
        <InputNumber placeholder={t("end_time")} />
      </Form.Item>
    </div>
  );
};

export default DayEndTimeFormItem;
