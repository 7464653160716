import { Button, Modal } from "antd";
import React from "react";
import NotificationForm from "../notification_form/notification_form";
import { useDynamicSelector } from "../../../../services/redux/selector";

const NotificationModal = (props) => {
  const {
    header_type,
    edit_modal_visible,
    set_edit_modal_visible,
    get_all_notifications,
    notification_modal_visible,
    set_notification_modal_visible,
  } = props;

  const { loading: create_notification_loading } = useDynamicSelector(
    "create_notification_board"
  );
  return (
    <Modal
      title={
        header_type === "edit_notification"
          ? "Edit Notification"
          : "Add Notification"
      }
      open={notification_modal_visible}
      destroyOnClose={true}
      onCancel={() => set_notification_modal_visible(false)}
      footer={[
        <Button
          key="cancel"
          onClick={() => set_notification_modal_visible(false)}
        >
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          htmlType="submit"
          form="notification_form"
          loading={create_notification_loading}
        >
          Save
        </Button>,
      ]}
    >
      <NotificationForm {...props} />
    </Modal>
  );
};

export default NotificationModal;
