import { Spin } from "antd";
import React from "react";

const LoaderSpinner = ({ message }) => {
  return (
    <>
      <div>
        <div
          style={{
            margin: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
          <h4 style={{ marginTop: "10px" }}>
            {message ? message : "Please Wait..."}
          </h4>
        </div>
      </div>
    </>
  );
};

export default LoaderSpinner;
