import React, { useEffect, useState } from "react";
import GroupModal from "../components/group/group_modal";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";
import { Card, Row } from "antd";
import CustomButton from "../components/common/custom_button";
import {
  dynamicRequest,
  get_group_list,
  mutation_delete_group,
  query_get_one_group,
} from "../../services/redux/slices";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../services/redux/selector";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import CustomDeleteModal from "../components/common/custom_delete_modal";
import GroupList from "../components/group/group_list";

const Group = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [group_modal_open, set_group_modal_open] = useState(false);
  const [group_modal_title, set_group_modal_title] = useState("");
  const [delete_record, set_delete_record] = useState({});
  const [delete_modal_visible, set_delete_modal_visible] = useState(false);

  const [current_limit, setCurrentLimit] = useState(10);
  const [current, setCurrent] = useState(1);

  // const { pagination } = useDynamicSelector("get_district_group_list");
  const { id, tamil_name, name } = useDynamicSelector("get_district_group");

  const add_group = () => {
    set_group_modal_open(true);
    set_group_modal_title("add");
  };

  const handle_group_modal_close = () => {
    set_group_modal_open(false);
  };
  const get_all_group_list = () => {
    let key = [{ key: "get_district_group_list", loading: true }];
    let query = get_group_list;
    let variables = {
      pagination: {
        page_limit: current_limit,
        page_number: current,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_group_delete = (record) => {
    set_delete_record(record);
    set_delete_modal_visible(true);
  };
  const get_one_group_record = (id) => {
    get_one_group(id);
  };

  const handle_delete = (record) => {
    let key = [{ key: "delete_district_group", loading: true }];
    let query = mutation_delete_group;
    let variables = {
      id: delete_record.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_one_group = (group_id) => {
    let key = [{ key: "get_district_group", loading: true }];
    let query = query_get_one_group;
    let variables = {
      id: group_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_all_group_list();
  }, []);

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row style={{ marginLeft: "10px" }}>
          <CustomPageTitle title={t("group_title")} />
        </Row>

        <Row style={{ margin: "8px", marginRight: "23px" }}>
          {/* <CustomBreadCrumbs pages={"Home/Group"} /> */}
        </Row>
      </Row>
      <Card
        style={{
          width: "96%",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Row
            style={{
              alignItems: "flex-end",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <CustomButton
              button_name={t("add_group")}
              onclick_button={add_group}
            />
          </Row>
          <GroupList
            get_all_group_list={get_all_group_list}
            get_one_group_record={get_one_group_record}
            set_group_modal_open={set_group_modal_open}
            set_group_modal_title={set_group_modal_title}
            handle_group_delete={handle_group_delete}
            set_delete_modal_visible={set_delete_modal_visible}
          />
        </Row>
        <GroupModal
          prefill_values={{ id, tamil_name, name }}
          group_modal_open={group_modal_open}
          handle_group_modal_close={handle_group_modal_close}
          group_modal_title={group_modal_title}
        />

        <CustomDeleteModal
          selector_name={"delete_district_group"}
          get_all={get_all_group_list}
          delete_api={handle_delete}
          record={delete_record}
          text={t("group")}
          delete_modal_visible={delete_modal_visible}
          set_delete_modal_visible={set_delete_modal_visible}
        />
      </Card>
    </>
  );
};
export default Group;
