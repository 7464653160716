import { Form, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const SakthiPeedamReportMandram = (props) => {
  const {} = props;
  const { t } = useTranslation();

  const { items } = useDynamicSelector("get_mandrams");

  // const custom_filter_option = (input, option) => {
  //   const inputValue = input.toLowerCase();
  //   const optionLabel = option.children.toLowerCase();
  //   return optionLabel.indexOf(inputValue) === 0;
  // };

  function filter_ss_codes(input_array) {
    return input_array?.filter((obj) => obj.code.startsWith("SS"));
  }

  const filtered_array = filter_ss_codes(items);

  return (
    <Form.Item name="mandram" label={t("mandram")}>
      <Select
        placeholder="Select Mandram"
        allowClear
        mode="multiple"
        showSearch
        filterOption={(input, option) =>
          option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {filtered_array?.map((district, index) => (
          <Select.Option key={district.name} value={district.id}>
            {`${district?.code}/${district?.name}`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SakthiPeedamReportMandram;
