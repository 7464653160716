import React, { useEffect } from "react";
import { Button, Card, Col, Form, Row } from "antd";
import CounterUserWiseDate from "../counter_user_wise_report_form_input/counter_user_wise_date";
import CounterUserWiseFilter from "../counter_user_wise_report_form_input/counter_user_wise_filter";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const CounterUserWiseReportForm = (props) => {
  const {
    get_counter_wise_summary_list,
    set_report_date,
    report_date,
    set_selected_counter,
    selected_counter,
    set_selected_values,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handle_finish = (values) => {
    console.log("valuesvalues", values);
    get_counter_wise_summary_list();
    set_selected_values(values);
  };

  return (
    <>
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 15,
        }}
        onFinish={handle_finish}
      >
        <Card
          style={{
            width: "96%",
            borderRadius: "5px",
            backgroundColor: "#e7e7e7",
            margin: "auto",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Row gutter={24} style={{ height: "30px" }}>
            <Col span={11}>
              <CounterUserWiseDate
                set_report_date={set_report_date}
                report_date={report_date}
              />
            </Col>
            <Col span={10}>
              <CounterUserWiseFilter
                selected_counter={selected_counter}
                set_selected_counter={set_selected_counter}
              />
            </Col>
            <Col span={3}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  htmlType="submit"
                  style={{
                    backgroundColor: "#014477",
                    color: "white",
                    borderRadius: "3px",
                  }}
                >
                  {t("get")}
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  );
};
export default CounterUserWiseReportForm;
