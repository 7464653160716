import { Form, Input, InputNumber } from "antd";
import React from "react";

const EnrolmentDdAmount = (props) => {
  const { payment_mode } = props;
  return (
    <Form.Item
      label="DD Amount"
      name="dd_amount"
      rules={
        payment_mode === "dd"
          ? [
              {
                required: true,
                message: "Please Enter the DD Amount",
              },
            ]
          : []
      }
    >
      <InputNumber placeholder="Enter the DD Amount" disabled={true} />
    </Form.Item>
  );
};

export default EnrolmentDdAmount;
