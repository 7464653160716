import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const EnrollActionBookingNoFormItem = () => {
  const { t } = useTranslation();
  const handle_change = (values) => {
    console.log("tt", values);
  };
  return (
    <div>
      <Form.Item name="booking_reference" label={t("booking_no_label")}>
        <Input
          style={{ width: "300px" }}
          placeholder="Booking no"
          onChange={handle_change}
        />
      </Form.Item>
    </div>
  );
};

export default EnrollActionBookingNoFormItem;
