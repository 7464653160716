import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Typography } from "antd";
import VipBookingMobileFormItem from "./vip_booking_mobile_form_item";
import VipBookingSendOtpButton from "./vip_booking_send_otp_button";
import VipBookingPortalLoginButton from "./vip_booking_portal_login_button";
import { useTranslation } from "react-i18next";
import { redirect_page, storeItem } from "../../../../helpers/functions";
import { ROUTES } from "../../../routes/my_routes";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
  vip_login_query,
} from "../../../../services/redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const VipBookingPortal = ({ handle_back_click }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [is_otp_status, set_is_otp_status] = useState(false);
  const {
    status: vip_login_status,
    session_id,
    error: vip_login_error,
    system_user,
    page_accesses,
    page_component_accesses,
  } = useDynamicSelector("vip_login");
  const { status: create_otp_status, error: create_otp_error } =
    useDynamicSelector("create_system_otp");

  const handleFormSubmit = (values) => {
    vip_user_login(values);
  };

  useEffect(() => {
    if (create_otp_status === "success") {
      toast.success("OTP send Successfully");
      set_is_otp_status(true);
      dispatch(dynamicClear("create_system_otp"));
    } else if (create_otp_error) {
      toast.error("Something went Wrong");
      dispatch(dynamicClear("create_system_otp"));
    }
    dispatch(dynamicClear("create_system_otp"));
  }, [create_otp_status, create_otp_error]);
console.log("vip_login_error",vip_login_error);

  useEffect(() => {
    if (vip_login_error) {
      const { message } = vip_login_error;
      if (message === "Invalid OTP") {
        toast.error("Please enter a valid OTP");
      dispatch(dynamicClear("vip_login"));

      } else if (message === "invalid_credentials") {
        toast.error("Please enter valid credentials");
      dispatch(dynamicClear("vip_login"));

      }
      dispatch(dynamicClear("vip_login"));
    }

    if (vip_login_status === "success") {
      storeItem("session_id", session_id);
      storeItem("role", system_user?.roles?.[0]?.name);
      storeItem("page_accesses", page_accesses);
      storeItem("system_user", system_user?.name);
      storeItem("page_component_accesses", page_component_accesses);
      redirect_page(ROUTES.VIP_BOOKING_USERS);
      dispatch(dynamicClear("vip_login"));
    }
  }, [vip_login_error, vip_login_status]);

  const vip_user_login = (values) => {
    let key = [{ key: "vip_login", loading: true }];
    let query = vip_login_query;
    let variables = {
      custom: {
        username: values?.booking_user_mobile,
        otp: values?.otp,
        is_vip_user: "true",
        password: "123456",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <div className="login_form_container">
      <Row
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0 0 10px 10px",
        }}
      >
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Typography
            style={{
              width: "100%",
              marginTop: "15px",
              fontWeight: "bold",
              fontSize: "15px",
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            Vip Booking Portal
          </Typography>
        </div>
        <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
          <VipBookingMobileFormItem />

          {is_otp_status === false ? (
            <VipBookingSendOtpButton form={form} />
          ) : (
            <>
              <Form.Item
                name="otp"
                rules={[
                  {
                    required: true,
                    message: t("otp"),
                  },
                ]}
              >
                <Input
                  maxLength={4}
                  placeholder="Enter OTP"
                  className="otp-input"
                   
                />
              </Form.Item>
              <VipBookingPortalLoginButton />
            </>
          )}
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "3px",
          }}
        >
          <Button
            style={{ width: "300px", height: "30px", marginBottom: "10px" }}
            onClick={handle_back_click}
          >
            Back
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default VipBookingPortal;
