import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { toUpper, upperCase } from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";

const DailyTransactionPdfRender = ({
  daily_transaction_items,
  selected_dates,
}) => {
  console.log("selected_dates", selected_dates);
  const calculateColumnTotal = (columnKey) => {
    return daily_transaction_items
      ? daily_transaction_items.reduce(
          (sum, record) => sum + (record[columnKey] || 0),
          0
        )
      : 0;
  };
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "white",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    table: {
      display: "table",
      width: "auto",
      border: "1px solid black",
    },
    tableRow: {
      flexDirection: "row",
    },
    table_first_cell: {
      padding: 4,
      flex: 1,
      textAlign: "left",
      fontSize: "8px",
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 0,
    },
    table_last_cell: {
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "8px",
      borderTopWidth: 1,
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    table_last_index_first_cell: {
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "8px",
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderRightWidth: 1,
    },
    table_last_index_name_last_cell: {
      padding: 4,
      flex: 1,
      textAlign: "left",
      fontSize: "8px",
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderRightWidth: 1,
    },
    table_last_index_cell: {
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "8px",
      borderTopWidth: 1,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      // borderBottomWidth: 1,
    },
    table_last_index_last_cell: {
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "8px",
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    tableCell: {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 0,
      padding: 4,
      flex: 1,
      textAlign: "center",
      fontSize: "8px",
    },
    tableCellName: {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 0,
      padding: 4,
      flex: 1,
      textAlign: "left",
      fontSize: "8px",
    },
    tableColHeader: {
      borderRightWidth: 1,
      borderTopWidth: 1,
      backgroundColor: "#f0f0f0",
      padding: 3,
      flex: 1,
      textAlign: "center",
      fontSize: "10px",
    },
    tableColHeader_first_header: {
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      backgroundColor: "#f0f0f0",
      padding: 3,
      flex: 1,
      textAlign: "center",
      fontSize: "10px",
    },
    table_header: {
      display: "flex",
      margin: "5px",
      fontWeight: "bold",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "10px",
    },
    header_content: {
      fontWeight: "600",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "10px",
    },
    image: {
      width: "40px",
      height: "40px",
    },
    full_header_content: {
      display: "flex",
      alignContent: "center",
      justifyContent: "space-evenly",
    },
    flexContainer: {
      marginTop: "-15px",
    },
    text: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });

  const { t } = useTranslation();

  return (
    <Document fileName={`Daily-transaction-report.pdf`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.flexContainer}>
            <View style={styles.full_header_content}>
              <Text style={styles.header_content}>{t("om_shakthi")}</Text>
              <Text style={styles.header_content}>{t("masm")} </Text>
              <Text style={styles.header_content}>
                {t("adhiparashakthi_siddhar_peedam")}
              </Text>
            </View>
          </View>

          <View></View>
          <Text style={styles.table_header}>
            {toUpper("daily transaction report")}
          </Text>
          <Text style={styles.text}>{selected_dates?.to_date}</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>Date</Text>
              <Text style={styles.tableColHeader}>Booking No</Text>
              <Text style={styles.tableColHeader}>Receipt No</Text>
              <Text style={styles.tableColHeader}>Leader Name</Text>
              <Text style={styles.tableColHeader}>Payment Mode</Text>
              <Text style={styles.tableColHeader}>Devotees</Text>
              <Text style={styles.tableColHeader}>Amount</Text>
            </View>
            {daily_transaction_items.map((item, index) => (
              <View
                style={styles.tableRow}
                key={index}
                wrap={index !== 0 ? "wrap" : "nowrap"}
              >
                <Text
                  style={
                    index === daily_transaction_items?.length - 1
                      ? styles.table_last_index_first_cell
                      : styles.tableCell
                  }
                >
                  {moment(item.date).format("DD-MM-YYYY")}
                </Text>
                <Text
                  style={
                    index === daily_transaction_items?.length - 1
                      ? styles.table_last_index_first_cell
                      : styles.tableCell
                  }
                >
                  {item.booking_reference}
                </Text>
                <Text
                  style={
                    index === daily_transaction_items?.length - 1
                      ? styles.table_last_index_first_cell
                      : styles.tableCell
                  }
                >
                  {item.receipt_number}
                </Text>
                <Text
                  style={
                    index === daily_transaction_items?.length - 1
                      ? styles.table_last_index_name_last_cell
                      : styles.tableCellName
                  }
                >
                  {item.leader_name}
                </Text>

                <Text
                  style={
                    index === daily_transaction_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.tableCell
                  }
                >
                  {upperCase(item?.payment_mode)}
                </Text>
                <Text
                  style={
                    index === daily_transaction_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.tableCell
                  }
                >
                  {item.devotees}
                </Text>
                <Text
                  style={
                    index === daily_transaction_items?.length - 1
                      ? styles.table_last_index_last_cell
                      : styles.tableCell
                  }
                >
                  {item.amount}
                </Text>
              </View>
            ))}

            {/* Total row */}
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader_first_header}>TOTAL</Text>
              <Text style={styles.tableColHeader}></Text>
              <Text style={styles.tableColHeader}></Text>
              <Text style={styles.tableColHeader}></Text>
              <Text style={styles.tableColHeader}></Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("devotees")}
              </Text>
              <Text style={styles.tableColHeader}>
                {calculateColumnTotal("amount")}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DailyTransactionPdfRender;
