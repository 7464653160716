import { Card, Row } from "antd";
import React, { useEffect } from "react";
import SystemUserTable from "../components/system_users/masm_user_table/masm_user_table";
import {
  dynamicRequest,
  query_get_system_role_list,
  useDynamicSelector,
} from "../../services/redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomPageTitle from "../components/common/custom_page_title";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";

const SplEnrolmentOfficer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [role_type, set_role_type] = useState();
  const { items: roles_items } = useDynamicSelector("get_roles");

  const get_all_system_role_list = () => {
    let key = [{ key: "get_roles", loading: true }];
    let query = query_get_system_role_list;
    dispatch(dynamicRequest(key, query));
  };
  useEffect(() => {
    get_all_system_role_list();
  }, []);

  useEffect(() => {
    const system_user_role = roles_items?.find(
      (role) => role.name === "Spl Enrolment Officer"
    );
    if (system_user_role) {
      set_role_type(system_user_role.name);
    }
  }, [roles_items]);
  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <CustomPageTitle title={t("spl_enrolment_officer_title")} />
        </Row>

        <Row style={{ margin: "8px", marginRight: "23px" }}>
          {/* <CustomBreadCrumbs pages={"Home/Spl Enrolment Officer"} /> */}
        </Row>
      </Row>

      <Card
        style={{
          width: "96%",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <SystemUserTable role_type={role_type} />
      </Card>
    </>
  );
};
export default SplEnrolmentOfficer;
