import React from "react";
import { Form, Switch } from "antd";

const CheckboxNotificationEnabled = () => {
  return (
    <Form.Item
      name="is_active"
      label="Enabled"
      valuePropName="checked"
      rules={[
        {
          required: true,
          message: "Please enable notification",
        },
      ]}
    >
      <Switch valuePropName={"checked"} />
    </Form.Item>
  );
};

export default CheckboxNotificationEnabled;
