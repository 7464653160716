import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const InputEmail = () => {
  const { t } = useTranslation();

  const validateEmail = (rule, value, callback) => {
    if (value && !/^\S+@\S+\.\S+$/.test(value)) {
      callback("Please enter a valid email address");
    } else {
      callback();
    }
  };

  return (
    <Form.Item
      style={{ marginTop: "30px" }}
      name="email"
      label={t("email")}
      rules={[
        {
          required: true,
          message: "Please enter email",
        },
        {
          validator: validateEmail,
        },
      ]}
    >
      <Input placeholder={t("email")} allowClear={true} />
    </Form.Item>
  );
};

export default InputEmail;
