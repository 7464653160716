import { Skeleton } from "antd";
import React from "react";

const CustomSkeletonLoading = (props) => {
  const { style } = props;
  return (
    <Skeleton.Button
      active={true}
      size={"default"}
      shape={"default"}
      block={false}
      style={style}
    />
  );
};

export default CustomSkeletonLoading;
