import { Col, Row } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import ReceiptForm from "./receipt_form/receipt_form";

const SearchReceipt = () => {
  return (
    <Col
      style={{
        backgroundColor: "#f0eae7",
        padding: "12px",
        borderRadius: "10px",
        width: "99%",
        margin: "auto",
      }}
    >
      <Row>
        <Title level={5}>Search Receipt</Title>
      </Row>
      <ReceiptForm />
    </Col>
  );
};

export default SearchReceipt;
