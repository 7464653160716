import React from "react";
import MandramRenewalList from "../components/mandram_renewal/mandram_renewal_list";
import MandramRenewalFilterForm from "../components/mandram_renewal/mandram_renewal_filter_form";

const MandramRenewal = () => {
  return (
    <div style={{margin:"10px"}}>
      <MandramRenewalFilterForm />
      <MandramRenewalList />
    </div>
  );
};

export default MandramRenewal;
