import { Form, Input } from "antd";
import React from "react";

const MandramUserName = () => {
  return (
    <Form.Item
      name={["mandram_admin", "name"]}
      label="Mandram User"
      rules={[
        {
          required: true,
          message: "Please enter Mandram (VSG) Name",
        },
      ]}
    >
      <Input />
    </Form.Item>
  );
};

export default MandramUserName;
