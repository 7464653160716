import { Form, Select, Button } from "antd";
import JoditEditor from "jodit-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { dynamicRequest, useDynamicSelector } from "../../../services/redux";
import { startCase } from "lodash";

const SendEmailForm = () => {
  const { items: get_district_list } = useDynamicSelector("get_district_list");
  const { t } = useTranslation();

  const onFinish = (values) => {
    submit_response(values);
  };

  const submit_response = (values) => {
    let keys = [{ key: "get_district_list", loading: true }];
    let query = "query_get_all_district";
    let variable = {
      data: {
        district_id_list: values.district_id_list,
        content: values.content,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  return (
    <Form onFinish={onFinish} style={{ padding: "10px" }}>
      <Form.Item
        label={t("Select District")}
        name="district_list"
        rules={[
          {
            required: true,
            message: t("Please select district"),
          },
        ]}
        className="email-form"
        style={{
          width: "0% !important",
          marginLeft: "-10px",
        }}
      >
        <Select
          allowClear={true}
          mode="multiple"
          style={{
            width: "400px",
          }}
          placeholder={t("Select district")}
          allow_clear
        >
          {get_district_list?.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {startCase(item.name)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={"content"}
        style={{ marginTop: "10px", width: "100%", backgroundColor: "white" }}
      >
        <JoditEditor />
      </Form.Item>

      <Form.Item
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SendEmailForm;
