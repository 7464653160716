import { Form, Input } from "antd";
import React, { useRef } from "react";

const ReferenceNumber = (props) => {
  // const input_ref = useRef();
  const { payment_mode } = props;

  // const on_change = (e) => {
  //   console.log("dd_reference_number", e.target.value);
  //   set_dd_reference_number(e.target.value);
  // };
  return (
    <Form.Item
      label={
        <span style={{ fontWeight: "bold", fontSize: "16px" }}>DD Ref.No</span>
      }
      name="reference_number"
      rules={
        payment_mode === "dd"
          ? [
              {
                required: true,
                message: "Please enter Ref number",
              },
            ]
          : []
      }
      labelCol={{ span: 24 }}
    >
      <Input style={{ width: "100%" }} />
    </Form.Item>
  );
};

export default ReferenceNumber;
