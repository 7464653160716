import { Card, Row } from "antd";
import React, { useEffect, useState } from "react";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  query_get_system_role_list,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import SystemUserTable from "../components/system_users/masm_user_table/masm_user_table";

const EnrolmentActionUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [role_type, set_role_type] = useState();
  const { items: roles_items } = useDynamicSelector("get_roles");
  console.log("role_type", role_type);

  const get_all_system_role_list = () => {
    let key = [{ key: "get_roles", loading: true }];
    let query = query_get_system_role_list;
    dispatch(dynamicRequest(key, query));
  };
  useEffect(() => {
    get_all_system_role_list();
  }, []);

  useEffect(() => {
    const system_role_type = roles_items?.find(
      (role) => role.name === "Enrolment Action User"
    );
    if (system_role_type) {
      set_role_type(system_role_type.name);
    }
  }, [roles_items]);

  return (
    <>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <CustomPageTitle title={t("enrolment_action_user_title")} />
        </Row>

        <Row style={{ margin: "8px", marginRight: "23px" }}>
          {/* <CustomBreadCrumbs pages={"Home/Bank Enrolment Officer"} /> */}
        </Row>
      </Row>

      <Card
        style={{
          width: "96%",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <SystemUserTable role_type={role_type} />
      </Card>
    </>
  );
};

export default EnrolmentActionUser;
