import { Form, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const BankActiveSwitch = ({ initialValue }) => {
  const { t } = useTranslation();
  const [switch_click, set_switch_click] = useState(initialValue);

  const on_change = (values) => {
    set_switch_click(values);
  };
  useEffect(() => {
    set_switch_click(switch_click);
  }, [initialValue]);

  return (
    <Form.Item
      label={t("is_active")}
      name="is_active"
      style={{ marginTop: "22px" }}
    >
      <Switch onChange={on_change} defaultChecked={switch_click} />
    </Form.Item>
  );
};
export default BankActiveSwitch;
