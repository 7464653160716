import { Button, Space, Table } from "antd";
import React, { useState } from "react";
import Feedback from "../../../../pages/feedback";
import {
  dynamicRequest,
  get_feedback_question_option_list_query,
  useDynamicSelector,
} from "../../../../../services/redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

const FeedBackExpandableTable = (props) => {
  const dispatch = useDispatch();
  const {
    handle_edit_expandable,
    show_delete_model_expandable,
    item,
    options,
    loading,
  } = props;

  const {
    items: get_feedback_question_option_list_items,
    loading: get_feedback_question_option_list_loading,
  } = useDynamicSelector("get_feedback_question_option_list");

  const [current, set_current] = useState(1);
  const [current_limit, set_current_limit] = useState(5);

  const fetch_data = (page_number, page_limit) => {
    let keys = [{ key: "get_feedback_question_option_list", loading: true }];
    let query = get_feedback_question_option_list_query;
    let variable = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(keys, query, variable));
    set_current(page_number);
    set_current_limit(page_limit);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "300px",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Option",
      dataIndex: "option",
      key: "option",
      width: "300px",
    },
    {
      title: "Action",
      key: "action",
      width: "300px",
      align: "centre",
      render: (text, record) => (
        <Space>
          <Button
            onClick={() => handle_edit_expandable(record)}
            type="link"
            // style={{ backgroundColor: "#179093", color: "white" }}
          >
            <EditOutlined />
          </Button>
          <Button
            onClick={() => show_delete_model_expandable(record)}
            type="link"
            // style={{ backgroundColor: "#f56356", color: "white" }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={options}
        loading={loading}
        pagination={false}
        // pagination={{
        //   current: current,
        //   pageSize: current_limit,
        //   total: get_feedback_question_option_list_items?.length,
        //   showSizeChanger: true,
        //   onChange: fetch_data,
        //   onShowSizeChange: (current, size) => {
        //     fetch_data(current, size);
        //   },
        // }}
      ></Table>
    </div>
  );
};

export default FeedBackExpandableTable;
