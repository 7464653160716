import { Form, Input } from "antd";
import React from "react";

const PaymentReference = (props) => {
  const { payment_mode } = props;
  return (
    <Form.Item
      name="upi_reference"
      label="Reference No"
      rules={
        payment_mode === "upi" || payment_mode === "card"
          ? [
              {
                required: true,
                message: "Please enter the reference number",
              },
            ]
          : []
      }
    >
      <Input />
    </Form.Item>
  );
};

export default PaymentReference;
