import { Row } from "antd";
import React, { useEffect, useState } from "react";
import ColorsCatalog from "../components/irumudi_calendar/colors_catalog/colors_catalog";
import { useTranslation } from "react-i18next";
import {
  query_calendar_dashboard,
  query_get_date_slot_list,
} from "../../services/redux/slices/graphql/graphql_irumudi_calendar";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  query_current_date_time,
  useDynamicSelector,
} from "../../services/redux";
import moment from "moment";
import Calendar from "../components/irumudi_calendar/calendar/calendar";
import CountDetailCard from "../components/irumudi_calendar/detail_cards/count_detail_card";
import MarqueeContent from "../components/irumudi_calendar/marquee_content/marquee";
import CustomPageTitle from "../components/common/custom_page_title";

const IrumudiCalendar = () => {
  const [irumudi_dates, set_irumudi_dates] = useState({});

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { items: irumudi_calendar_items, loading: irumudi_items_loading } =
    useDynamicSelector("get_dashboard_calendar");

  useEffect(() => {
    get_calendar_data();
    get_date_slot_list();
    current_date_time_query();
  }, []);

  const current_date_time_query = () => {
    let key = [{ key: "get_current_date_time", loading: true }];
    let query = query_current_date_time;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_calendar_data = () => {
    let keys = [{ key: "get_dashboard_calendar", loading: true }];
    let query = query_calendar_dashboard;
    let variable = {};
    dispatch(dynamicRequest(keys, query, variable));
  };

  const get_date_slot_list = () => {
    let keys = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_date_slot_list;
    let variable = {};
    dispatch(dynamicRequest(keys, query, variable));
  };

  const set_calendar_dates = () => {
    if (irumudi_calendar_items && irumudi_calendar_items.length > 0) {
      const startDate = moment(irumudi_calendar_items[0]?.date).format(
        "YYYY-MM-DD"
      );
      const endDate = moment(
        irumudi_calendar_items[irumudi_calendar_items.length - 1]?.date
      ).format("YYYY-MM-DD");

      set_irumudi_dates({
        start_date: startDate,
        end_date: endDate,
      });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      get_calendar_data();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    get_calendar_data();
  }, []);

  useEffect(() => {
    set_calendar_dates();
  }, [irumudi_calendar_items]);

  return (
    <Row style={{ width: "100%" }}>
      <CustomPageTitle title={t("irumudi_calendar")} />

      <MarqueeContent />

      <div
        xs={{ flexDirection: "column" }}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          padding: "10px",
        }}
      >
        <div style={{ width: "70%" }}>
          <ColorsCatalog />
          <Calendar
            start_date={irumudi_dates.start_date}
            end_date={irumudi_dates.end_date}
          />
        </div>
        <div style={{ width: "35%", marginTop: "38px" }}>
          <CountDetailCard
            day_type={"yesterday"}
            bg_color={"#d4380d"}
            title={t("yesterday")}
            items={irumudi_calendar_items}
            loading={irumudi_items_loading}
          />
          <CountDetailCard
            bg_color={"#1c8eff"}
            title={t("today")}
            day_type={"today"}
            items={irumudi_calendar_items}
            loading={irumudi_items_loading}
          />
          <CountDetailCard
            bg_color={"#fc9a12"}
            title={t("tomorrow")}
            day_type={"tomorrow"}
            items={irumudi_calendar_items}
            loading={irumudi_items_loading}
          />
        </div>
      </div>
    </Row>
  );
};

export default IrumudiCalendar;
