import {
  Avatar,
  Button,
  Form,
  Modal,
  Segmented,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { CARD_IMAGE, UPI_IMAGE } from "../../../../helpers/image_constants";
import {
  dynamicClear,
  dynamicRequest,
  mutation_update_booking_payment,
  query_booking_action_list,
  query_check_order_status,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
let modalInstance;

const BookingActionPaymentModal = (props) => {
  const {
    open_payment_details,
    set_open_payment_details,
    booking_id,
    update_payment_id,
    set_update_payment_id,
    has_pos,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [payment_mode, set_payment_mode] = useState("");
  console.log("update_payment_id", update_payment_id);

  const { items: booking_action_list, loading: booking_list_loading } =
    useDynamicSelector("get_action_list");

  const {
    status: order_status,
    error: check_order_error,
    loading: check_order_loading,
    booking_id: check_order_booking_id,
  } = useDynamicSelector("check_order_status");

  const {
    status: update_booking_payment_status,
    id: update_booking_payment_id,
    loading: update_booking_payment_loading,
  } = useDynamicSelector("update_booking_payment_mode");

  const updating_booking_payment_list = (values) => {
    let key = [{ key: "update_booking_payment_mode", loading: true }];
    let query = mutation_update_booking_payment;
    let variables = {
      data: {
        payment_mode: payment_mode,
        booking_id: booking_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_check_order_status = (update_booking_payment_id) => {
    let key = [{ key: "check_order_status", loading: true }];
    let query = query_check_order_status;
    let variables = {
      id: update_booking_payment_id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const mandram_wise_booking_list = (values) => {
    let key = [{ key: "get_action_list", loading: true }];
    let query = query_booking_action_list;
    let variables = {
      filter: {
        date_slot_id: values?.date,
        booking_reference: values?.receipt_no,
      },
    };
    console.log("variables", variables);
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (order_status === "completed") {
      toast.success("Payment Success");
      mandram_wise_booking_list();
      if (modalInstance) {
        modalInstance.destroy();
      }

      dispatch(dynamicClear("check_order_status"));
    } else if (order_status === "initiated") {
      setTimeout(() => {
        handle_check_order_status(update_booking_payment_id);
      }, 10000);
    } else if (order_status === "cancelled") {
      if (modalInstance) {
        modalInstance.destroy();
        toast.error("Payment failed");
      }
      dispatch(dynamicClear("check_order_status"));
    } else if (order_status === "failure") {
      if (modalInstance) {
        modalInstance.destroy();
        toast.error("Payment failed");
      }
    } else if (check_order_error?.message) {
      toast.error(startCase(check_order_error?.message));
      dispatch(dynamicClear("check_order_status"));
    }
  }, [order_status, check_order_error, update_booking_payment_id]);

  const on_finish = (values) => {
    updating_booking_payment_list();
    set_open_payment_details(false);
    set_payment_mode("");
  };
  const on_close = () => {
    set_open_payment_details(false);
    set_payment_mode("");
  };

  const on_change = (value) => {
    set_payment_mode(value);
    console.log("values", value);
  };

  useEffect(() => {
    if (update_booking_payment_status === "order created") {
      handle_check_order_status(update_booking_payment_id);
      modalInstance = Modal.info({
        title: "Payment Process",
        content: (
          <div>
            <p>Your Payment is Processing.....</p>
          </div>
        ),
        okButtonProps: {
          style: {
            display: "none",
          },
        },
      });
    }
  }, [update_booking_payment_status, update_booking_payment_id]);

  return (
    <div>
      <Modal
        destroyOnClose={true}
        title={"Payment"}
        open={open_payment_details}
        onCancel={() => {
          set_open_payment_details(false);
          set_payment_mode("");
        }}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            style={{
              backgroundColor: "#168f92",
              color: "white",
              border: "transparent",
            }}
            onClick={on_close}
          >
            {/* {t("no")} */}
            Cancel
          </Button>,
          <Button
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            htmlType="submit"
            onClick={on_finish}
            loading={update_booking_payment_loading}
          >
            {/* {t("yes")} */}
            Pay
          </Button>,
        ]}
      >
        <Form.Item
          label={t("mode_of_payment")}
          name="payment_mode"
          rules={[
            {
              required: true,
              message: "Please Select Mode of payment",
            },
          ]}
        >
          <Space
            direction="vertical"
            style={{ width: "100%", flexWrap: "wrap", marginLeft: "50px" }}
          >
            <Segmented
              onChange={on_change}
              value={payment_mode}
              options={[
                {
                  label: (
                    <div
                      style={{
                        padding: 4,
                        width: "100px",
                      }}
                    >
                      <Avatar
                        style={{
                          width: "50px",
                          height: "45px",
                          padding: "6px",
                        }}
                        src={CARD_IMAGE}
                      />

                      <h4 style={{ fontWeight: "bold" }}>{t("Card")}</h4>
                    </div>
                  ),
                  value: "card",
                },
                {
                  label: (
                    <div
                      style={{
                        padding: 4,
                        width: "100px",
                      }}
                    >
                      <Avatar
                        style={{
                          width: "70px",
                          height: "45px",
                          padding: "2px",
                        }}
                        src={UPI_IMAGE}
                      />

                      <h4 style={{ fontWeight: "bold" }}>{t("Upi")}</h4>
                    </div>
                  ),
                  value: "upi",
                },
              ]}
            />
          </Space>
        </Form.Item>
      </Modal>
    </div>
  );
};

export default BookingActionPaymentModal;
