import React from "react";
import { Form, Select } from "antd";

const PaymentModeInput = (props) => {
  const { set_payment_mode } = props;
  const paymentModes = ["cash", "dd", "card", "upi"];

  const on_change = (values) => {
    console.log("values", values);
    set_payment_mode(values);
  };

  return (
    <Form.Item label="Payment Mode" name="payment_mode">
      <Select
        style={{ width: "100%" }}
        placeholder="Select Payment Mode"
        allowClear
        onChange={on_change}
      >
        {paymentModes.map((mode) => (
          <Select.Option key={mode} value={mode}>
            {mode.toUpperCase()}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default PaymentModeInput;
