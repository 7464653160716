import { Table } from "antd";
import React, { useState } from "react";
import {
  district_wise_report_query,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IS_TABLE_SCROLLABLE } from "../../../../helpers/constants";
import PrintHeader from "../../common/print_header";

const DistrictWiseReportList = (props) => {
  const { selected_language, selected_date, yesterday_date } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    items: district_wise_count_item,
    loading: district_wise_count_loading,
  } = useDynamicSelector("get_district_wise_count");
  const [current, set_current] = useState(1);
  const [current_limit, set_current_limit] = useState(5);

  const fetch_data = (page_number, page_limit) => {
    let keys = [{ key: "get_district_wise_count", loading: true }];
    let query = district_wise_report_query;
    let variable = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(keys, query, variable));
    set_current(page_number);
    set_current_limit(page_limit);
  };

  const total_yesterday = district_wise_count_item
    ? district_wise_count_item.reduce(
        (acc, item) => acc + item?.yesterday_count,
        0
      )
    : 0;

  const total_today = district_wise_count_item
    ? district_wise_count_item.reduce((acc, item) => acc + item?.today_count, 0)
    : 0;

  const total_overall = district_wise_count_item
    ? district_wise_count_item.reduce((acc, item) => acc + item?.total_count, 0)
    : 0;
  const columns = [
    {
      title: selected_language === "Tamil" ? "மாவட்டங்கள்" : "Districts",
      dataIndex:
        selected_language === "Tamil" ? "district_tamil_name" : "district_name",
      key: "districtUser",
      width: "250px",
    },

    {
      title:
        selected_language === "Tamil"
          ? `நேற்று வரை         
  ${yesterday_date}`
          : `Till Yesterday
 ${yesterday_date}`,

      // selected_language === "Tamil" ? "நேற்று வரை" : "Till Yesterday",
      dataIndex: "yesterday_count",
      key: "yesterday",
      width: "250px",
      align: "right",
    },
    {
      title:
        selected_language === "Tamil"
          ? `இன்று         
      ${selected_date}`
          : `Selected date
     ${selected_date}`,

      dataIndex: "today_count",
      key: "today",
      width: "250px",
      align: "right",
    },
    {
      title: selected_language === "Tamil" ? "மொத்தம்" : "Total",
      dataIndex: "total_count",
      key: "total",
      width: "250px",
      align: "right",
    },
  ];
  const data = [
    {
      districtUser: "kavitha",
      yesterday: "44",
      today: "65",
      total: "109",
    },
  ];
  return (
    <div>
      <Table
        data-name-table="district-wise-report"
        className="counter-user-wise-report screen-table"
        // className="district_wise_report_table"
        style={{ width: "96%", margin: "auto" }}
        columns={columns}
        dataSource={district_wise_count_item}
        loading={district_wise_count_loading}
        pagination={false}
        scroll={{
          x: true,
          y: IS_TABLE_SCROLLABLE ? null : 300,
        }}
        // pagination={{
        //   current: current,
        //   pageSize: current_limit,
        //   total: district_wise_count_item?.length,
        //   showSizeChanger: true,
        //   onChange: fetch_data,
        //   onShowSizeChange: (current, size) => {
        //     fetch_data(current, size);
        //   },
        // }}
        summary={() => (
          <Table.Summary fixed>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <text style={{ fontWeight: "bold", paddingLeft: "50px" }}>
                  {selected_language === "Tamil" ? "மொத்தம்" : "Total"}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  {district_wise_count_item
                    ? district_wise_count_item.reduce(
                        (sum, record) => sum + (record?.yesterday_count || 0),
                        0
                      )
                    : 0}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  {district_wise_count_item
                    ? district_wise_count_item.reduce(
                        (sum, record) => sum + (record?.today_count || 0),
                        0
                      )
                    : 0}
                </text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} className="aligned-cell">
                <text style={{ fontWeight: "bold" }}>
                  {district_wise_count_item
                    ? district_wise_count_item.reduce(
                        (sum, record) => sum + (record.total_count || 0),
                        0
                      )
                    : 0}
                </text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />

      <div>
        <div className="print-header" style={{ padding: "15px" }}>
          <PrintHeader />
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            DISTRICT WISE REPORT
          </h4>
          <p style={{ marginLeft: "300px" }}>{selected_date}</p>
        </div>

        <table
          className="table table-bordered print-table"
          style={{ padding: "10px", width: "100%", marginTop: "10px" }}
        >
          <thead style={{ width: "100%" }}>
            <tr className="header-style">
              <th className="header-style" style={{ width: "20%" }}>
                {selected_language === "Tamil" ? "மாவட்டங்கள்" : "District"}
              </th>
              <th className="header-style" style={{ width: "20%" }}>
                {selected_language === "Tamil"
                  ? `நேற்று வரை         
  ${yesterday_date}`
                  : `Till Yesterday
 ${yesterday_date}`}
              </th>
              <th className="header-style" style={{ width: "20%" }}>
                {selected_language === "Tamil"
                  ? `இன்று         
      ${selected_date}`
                  : `Selected date
     ${selected_date}`}
              </th>
              <th className="header-style" style={{ width: "20%" }}>
                {selected_language === "Tamil" ? "மொத்தம்" : "Total"}
              </th>
            </tr>
          </thead>
          <tbody>
            {district_wise_count_item?.map((item, index) => (
              <tr key={index}>
                <td className="body-style" style={{ textAlign: "left" }}>
                  {selected_language === "Tamil"
                    ? item?.district_tamil_name
                    : item?.district_name}
                </td>
                <td className="body-style">{item?.yesterday_count}</td>
                <td className="body-style">{item?.today_count}</td>
                <td className="body-style">{item?.total_count}</td>
              </tr>
            ))}
            <tr>
              <td className="body-style">
                {selected_language === "Tamil" ? (
                  <text style={{ fontWeight: "bold" }}>மொத்தம்</text>
                ) : (
                  <text style={{ fontWeight: "bold" }}>Total</text>
                )}
              </td>
              <td className="body-style">
                <text style={{ fontWeight: "bold" }}>{total_yesterday}</text>
              </td>
              <td className="body-style">
                <text style={{ fontWeight: "bold" }}>{total_today}</text>
              </td>
              <td className="body-style">
                <text style={{ fontWeight: "bold" }}>{total_overall}</text>
              </td>
            </tr>
          </tbody>
        </table>
        <style>
          {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-table {
        display: block !important;
      }
      .print-header {
        display: block !important;
      }

      @page {
        size: A4;
        margin: 1cm;
      }

      body::before {
        content: counter(page);
        top: 0;
        right: 1cm;
      }
    }
  `}
        </style>
      </div>
    </div>
  );
};
export default DistrictWiseReportList;
