import { gql } from "@apollo/client";

export const query_get_masm_user_list = gql`
  query get_masm_user_list($filter: get_masm_user_filter_input) {
    get_masm_user_list(filter: $filter) {
      items {
        name
        mobile
        email
        password
        group {
          id
          name
        }
        system_user {
          id
          username
          roles {
            name
          }
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_create_masm_user = gql`
  mutation create_masm_user($data: create_masm_user_input) {
    create_masm_user(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_masm_user = gql`
  mutation update_masm_user($id: String!, $data: update_masm_user_input) {
    update_masm_user(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_masm_user = gql`
  mutation delete_masm_user($id: String!) {
    delete_masm_user(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_masm_mandram_count_query = gql`
  query get_masm_user_mandram_count(
    $filter: masm_user_mandram_count_filter_input
  ) {
    get_masm_user_mandram_count(filter: $filter) {
      items {
        mandram_name
        mandram_id
        mandram_code
        count {
          booked
          cancelled
          arrived
          excess
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;
