import { Form, Input } from "antd";
import React from "react";

const InputAdminMobile = () => {
  return (
    <>
      <Form.Item
        name={["district_admin", "mobile"]}
        label="Mobile"
        rules={[
          {
            required: true,
            message: "Please enter mobile number",
          },
          {
            pattern: /^[0-9]{10}$/,
            message: "Please enter a valid mobile number",
          },
        ]}
        allowClear={true}
      >
        <Input type="text" placeholder="Mobile" />
      </Form.Item>
    </>
  );
};

export default InputAdminMobile;
