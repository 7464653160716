import gql from "graphql-tag";

export const get_group_list = gql`
  query get_district_group_list(
    $filter: get_district_group_list_filter_input
    $sort: sort_input
    $pagination: pagination_input
  ) {
    get_district_group_list(
      filter: $filter
      sort: $sort
      pagination: $pagination
    ) {
      items {
        id
        name
        tamil_name
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        message
      }
    }
  }
`;

export const mutation_create_group = gql`
  mutation create_district_group($data: create_district_group_input!) {
    create_district_group(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_group = gql`
  mutation update_district_group(
    $id: String!
    $data: update_district_group_input!
  ) {
    update_district_group(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_one_group = gql`
  query get_district_group($id: String!) {
    get_district_group(id: $id) {
      id
      name
      tamil_name
    }
  }
`;

export const mutation_delete_group = gql`
  mutation delete_district_group($id: String!) {
    delete_district_group(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
