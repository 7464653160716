import React from "react";
import { Form, Input } from "antd";

const PosTerminal = () => {
  return (
    <Form.Item
      label="Terminal Id"
      name="terminal_id"
      rules={[
        {
          required: true,
          message: "Please enter a terminal id",
        },
      ]}
    >
      <Input placeholder="Terminal Id" />
    </Form.Item>
  );
};

export default PosTerminal;
