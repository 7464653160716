import moment from "moment";

export const calculate = (grouped_by_date) => {
  let sum_total_booked = 0;
  let sum_total_enrolled = 0;
  let sum_total_cancelled = 0;
  let sum_total_yet_to_enroll = 0;
  let sum_total_excess = 0;
  let sum_total_prepaid_amount = 0;

  // Iterate through each date in grouped_by_date
  for (const date in grouped_by_date) {
    // Accumulate counts for each date
    sum_total_booked += grouped_by_date[date].total_booked || 0;
    sum_total_enrolled += grouped_by_date[date].total_enrolled || 0;
    sum_total_cancelled += grouped_by_date[date].total_cancelled_count || 0;
    sum_total_yet_to_enroll +=
      grouped_by_date[date].total_yet_to_enroll_count || 0;
    sum_total_excess += grouped_by_date[date].total_excess_count || 0;
    sum_total_prepaid_amount += grouped_by_date[date].total_prepaid_amount || 0;
  }

  return {
    sum_total_booked,
    sum_total_enrolled,
    sum_total_cancelled,
    sum_total_yet_to_enroll,
    sum_total_excess,
    sum_total_prepaid_amount,
  };
};

export const grouped_by_date = (
  booking_summary_list,
  districts
) => {
  let booking_summary_object = booking_summary_list?.reduce((acc, item) => {
    const date = moment(item?.date).format("DD/MM/YYYY");
    if (!acc[date]) {
      acc[date] = {
        date,
        date_slot_id: item?.date_slot_id,
        total_arrived: 0,
        total_booked: 0,
        total_enrolled: 0,
        total_yet_to_enroll_count: 0,
        total_cancelled_count: 0,
        total_excess_count: 0,
        total_received_amount: 0,
        total_prepaid_amount: 0,
        enrolled: 0,
        districts: {},
      };
    }
    acc[date].total_arrived += item?.count?.arrived;
    acc[date].total_booked += item?.count?.booked;
    acc[date].total_yet_to_enroll_count += item?.count?.yet_to_enroll;
    acc[date].total_cancelled_count += item?.count?.cancelled;
    acc[date].total_excess_count += item?.count?.excess;
    acc[date].total_enrolled += item?.count?.enrolled;
    acc[date].total_received_amount += item.received_amount;
    acc[date].total_prepaid_amount += item.prepaid_amount;

    if (item.district_id && districts) {
      if (!acc[date].districts[item.district_id]) {
        acc[date].districts[item.district_id] = {
          date,
          district_id: item.district_id,
          district_name: districts?.find(
            (list) => list?.id === item?.district_id
          )?.name,
          total_arrived: 0,
          total_booked: 0,
          total_enrolled: 0,
          total_yet_to_enroll_count: 0,
          total_cancelled_count: 0,
          total_excess_count: 0,
          total_received_amount: 0,
          total_prepaid_amount: 0,
          mandram_items: {},
        };
      }
      acc[date].districts[item.district_id].total_arrived +=
        item?.count?.arrived;
      acc[date].districts[item.district_id].total_booked += item?.count?.booked;
      acc[date].districts[item.district_id].total_yet_to_enroll_count +=
        item?.count?.yet_to_enroll;
      acc[date].districts[item.district_id].total_cancelled_count +=
        item?.count?.cancelled;
      acc[date].districts[item.district_id].total_excess_count +=
        item?.count?.excess;
      acc[date].districts[item.district_id].total_enrolled +=
        item?.count?.enrolled;
      acc[date].districts[item.district_id].total_received_amount +=
        item?.received_amount;
      acc[date].districts[item.district_id].total_prepaid_amount +=
        item?.prepaid_amount;
    }

    return acc;
  }, {});
  return booking_summary_object;
};
