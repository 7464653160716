import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useCallback, useEffect, useState } from "react";
import { Space, Table } from "antd";
import DraggableRow from "./draggable_row";
import { useTranslation } from "react-i18next";
import CustomDeleteModal from "../../common/custom_delete_modal";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  mutation_delete_notification_board,
  query_get_one_notification_board,
} from "../../../../services/redux/slices/graphql/graphql_notification";
import { dynamicRequest } from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../../../services/redux/selector";

const DraggableTable = (props) => {
  const {
    enable_reorder,
    set_enable_reorder,
    set_header_type,
    set_notification_modal_visible,
    get_all_notifications,
    items,
    save_reorder,
    fetch_data,
    current_page,
    current_page_limit,
    data_source,
    set_data_source,
  } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [filtered_columns, set_filtered_columns] = useState([]);
  const [delete_modal_visible, set_delete_modal_visible] = useState(false);
  const [delete_record, set_delete_record] = useState({});

  const { loading: notification_loading } = useDynamicSelector(
    "get_notification_board_list"
  );

  useEffect(() => {
    const items_with_key = items?.map((item, i) => ({
      ...item,
      key: i.toString(),
    }));
    set_data_source(items_with_key);
  }, [items, enable_reorder]);

  const columns = [
    {
      key: "sort",
      title: "Drag",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Content",
      dataIndex: "content",
      width: "400px",
    },
    {
      title: "Enabled",
      dataIndex: "is_active",
      align: "center",
      render: (value) => {
        return value === true ? "Yes" : "No";
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "center",
      render: (value, record) => (
        <Space
          style={{
            width: "50px",
            justifyContent: "space-between",
          }}
        >
          <EditOutlined onClick={() => handle_edit(record)} />
          <DeleteOutlined onClick={() => handle_delete(record)} />
        </Space>
      ),
    },
  ];

  const handle_edit = (record) => {
    set_header_type("edit_notification");
    get_one_notification_board(record?.id);
    set_notification_modal_visible(true);
  };

  const get_one_notification_board = (id) => {
    let keys = [{ key: "get_notification_board", loading: true }];
    let query = query_get_one_notification_board;
    let variable = {
      id,
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const delete_record_api = () => {
    let keys = [{ key: "delete_notification_board", loading: true }];
    let query = mutation_delete_notification_board;
    let variable = {
      id: delete_record.id,
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const handle_delete = (record) => {
    set_delete_modal_visible(true);
    set_delete_record(record);
  };

  const on_drag_end = useCallback(
    ({ active, over }) => {
      if (active.id !== over?.id) {
        set_data_source((previous) => {
          const activeIndex = previous.findIndex((i) => i.key === active.id);
          const overIndex = previous.findIndex((i) => i.key === over?.id);
          return arrayMove(previous, activeIndex, overIndex);
        });
      }
    },
    [data_source, save_reorder]
  );

  useEffect(() => {
    if (!enable_reorder) {
      let filtered = columns.filter((column) => column.key !== "sort");
      set_filtered_columns(filtered);
    } else set_filtered_columns(columns);
  }, [enable_reorder]);

  const pagination = {
    current: current_page,
    pageSize: current_page_limit,
    total: data_source?.length,
    showSizeChanger: true,
    onChange: fetch_data,
    onShowSizeChange: (current, size) => {
      fetch_data(current, size);
    },
  };

  return (
    <>
      {enable_reorder ? (
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={on_drag_end}
        >
          <SortableContext
            items={data_source ? data_source.map((i) => i.key) : []}
            strategy={verticalListSortingStrategy}
          >
            <Table
              style={{ width: "100%" }}
              components={{
                body: {
                  row: DraggableRow,
                },
              }}
              rowKey="key"
              columns={filtered_columns}
              dataSource={data_source}
              loading={notification_loading}
              pagination={pagination}
            />
          </SortableContext>
        </DndContext>
      ) : (
        <Table
          style={{ width: "100%" }}
          rowKey="key"
          columns={filtered_columns}
          dataSource={data_source}
          loading={notification_loading}
          pagination={pagination}
        />
      )}

      <CustomDeleteModal
        selector_name={"delete_notification_board"}
        get_all={get_all_notifications}
        delete_api={delete_record_api}
        record={delete_record}
        text={t("Notification")}
        delete_modal_visible={delete_modal_visible}
        set_delete_modal_visible={set_delete_modal_visible}
      />
    </>
  );
};

export default DraggableTable;
