import { Card, Collapse, Pagination, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import CustomPageTitle from "../../common/custom_page_title";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  get_feedback_response_query,
  useDynamicSelector,
} from "../../../../services/redux";
import moment from "moment";
const { Panel } = Collapse;

const FeedbackUserResponseTable = () => {
  const dispatch = useDispatch();
  const [is_question_record, set_question_record] = useState([]);
  const [current, set_current] = useState(1);
  const [current_limit, set_current_limit] = useState(5);
  const { items: feedback_response_list_items, loading } = useDynamicSelector(
    "get_feedback_response_list"
  );

  useEffect(() => {
    feedback_response_list();
  }, []);

  const feedback_response_list = () => {
    let key = [{ key: "get_feedback_response_list", loading: true }];
    let query = get_feedback_response_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetch_data = (page_number, page_limit) => {
    let keys = [{ key: "get_feedback_response_list", loading: true }];
    let query = get_feedback_response_query;
    let variable = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(keys, query, variable));
    set_current(page_number);
    set_current_limit(page_limit);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <Card style={{ width: "95%", borderRadius: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "10px",
                marginBottom: "20px",
              }}
            >
              {/* <CustomButton
                button_name={t("back")}
                onclick_button={handle_back}
              /> */}
            </div>
          </div>

          <div style={{ alignContent: "center" }}>
            {loading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Spin />
              </div>
            ) : (
              <Collapse>
                {feedback_response_list_items?.map((item) => (
                  <Panel
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ color: "black" }}>{item.user.name}</span>
                        <span style={{ color: "black" }}>
                          {moment(item.datetime).format("DD /MM /YYYY, h:mm A")}
                        </span>
                      </div>
                    }
                  >
                    {item.question_list.map((question, index) => (
                      <div key={index} style={{ marginBottom: 10 }}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            {`Question ${index + 1}:`}
                          </span>{" "}
                          {question.feedback_question.question}
                        </div>
                        <div>
                          <span style={{ fontWeight: "bold" }}>Answer:</span>{" "}
                          {question.feedback_question_option?.option ||
                            question.text}
                        </div>
                      </div>
                    ))}
                  </Panel>
                ))}
              </Collapse>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Pagination
              current={current}
              pageSize={current_limit}
              total={feedback_response_list_items?.length}
              onChange={fetch_data}
              showSizeChanger={true}
              onShowSizeChange={(current, size) => {
                fetch_data(current, size);
              }}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default FeedbackUserResponseTable;
