import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const BookingActionReceipt = () => {
  const { t } = useTranslation();
  return (
    <Form.Item label={t("booking_no")} name="receipt_no">
      <Input
        allowClear
        style={{
          width: "100%",
        }}
        placeholder="Booking No"
      />
    </Form.Item>
  );
};

export default BookingActionReceipt;
