import React from "react";
import { Modal, Button } from "antd";
import { Text } from "native-base";
import { IoMdClose } from "react-icons/io";

const BookingAvailabilityDeleteModal = (props) => {
  const {
    is_delete_modal_visible,
    handle_delete_cancel,
    handle_delete_confirm,
    show_delete_model,
  } = props;

  return (
    <Modal
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      open={is_delete_modal_visible}
      title="Confirmation"
      onCancel={handle_delete_cancel}
      footer={[
        <Button key="cancel" onClick={handle_delete_cancel}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={() => handle_delete_confirm()}
        >
          Delete
        </Button>,
      ]}
    >
      <Text>Do you really want to delete this?</Text>
    </Modal>
  );
};

export default BookingAvailabilityDeleteModal;
