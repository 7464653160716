import { Collapse, Spin } from "antd";
import React from "react";
import BookingHistoryHeader from "./booking_history_header";
import MandramWiseCollapse from "./mandram_wise_history";
import { useDispatch, useSelector } from "react-redux";
import {
  dynamicRequest,
  dynamicSet,
  query_get_all_mandrams,
  query_get_mandram_wise_summary,
  useDynamicSelector,
} from "../../../../services/redux";

const SubDistrictWiseCollapse = (props) => {
  const { date_slot_item } = props;
  const dispatch = useDispatch();
  const { items: sub_district_list, loading: sub_district_loading } =
    useDynamicSelector("sub_district_list_history");
  const { mandram_id } = useDynamicSelector("mandram_id");
  const { key: active_sub_district_key } = useDynamicSelector(
    "sub_district_wise_active_key"
  );
  const {
    items: get_sub_district_wise_summary,
    loading: get_sub_district_wise_summary_loading,
  } = useDynamicSelector("get_sub_district_wise_summary");
  const mandram_wise_summary = (sub_district_id) => {
    let key = [{ key: "get_mandram_wise_summary", loading: true }];
    let query = query_get_mandram_wise_summary;
    let variables = {
      filter: {
        date_slot_id: date_slot_item?.date_slot_id,
        sub_district_id: sub_district_id,
        mandram_id_list: mandram_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const get_mandram_list = (sub_district_id) => {
    let key = [{ key: "get_all_mandrams", loading: true }];
    let query = query_get_all_mandrams;
    let variables = {
      filter: { sub_district_id: sub_district_id },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handle_change__sub_district_wise = (key) => {
    if (key) {
      mandram_wise_summary(key);
      get_mandram_list(key);
    }
    dispatch(dynamicSet("sub_district_wise_active_key", { key: key }));
    dispatch(dynamicSet("mandram_wise_active_key", { key: [] }));
  };

  return (
    <div>
      <Collapse
        accordion={true}
        onChange={handle_change__sub_district_wise}
        activeKey={active_sub_district_key || []}
      >
        {get_sub_district_wise_summary_loading && sub_district_loading && (
          <div className="booking_history_loading">
            <Spin />
          </div>
        )}
        {get_sub_district_wise_summary?.map((sub_district_wise) => {
          let sub_subject = sub_district_list?.find(
            (list) => list.id === sub_district_wise?.sub_district_id
          );
          let sub_district_wise_item = {
            district_name: sub_subject?.name,
            ...sub_district_wise?.count,
          };

          return (
            <Collapse.Panel
              header={<BookingHistoryHeader item={sub_district_wise_item} />}
              key={sub_district_wise?.sub_district_id || ""}
              style={{ backgroundColor: "#54473F" }}
            >
              <MandramWiseCollapse date_slot_item={date_slot_item} />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default SubDistrictWiseCollapse;
