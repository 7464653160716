import React, { useEffect } from "react";
import CounterGroupName from "./user_group_name";
import { Form } from "antd";

import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  mutation_create_user_group,
  mutation_update_user_group,
} from "../../../../services/redux/slices/graphql/graphql_user_group";

const UserGroupForm = (props) => {
  const {
    header_type,
    selected_items,
    get_all_user_group,
    set_user_group_modal_visible,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { status: create_user_group_status, error: create_user_group_error } =
    useDynamicSelector("create_user_group");
  const { status: update_user_group_status, error: update_user_group_error } =
    useDynamicSelector("update_user_group");

  const { name } = useDynamicSelector("get_user_group");

  const handle_user_group_submit = (values) => {
    if (header_type === "add") {
      create_user_group(values);
    } else {
      update_user_group(values);
    }
  };

  const create_user_group = (values) => {
    let key = [{ key: "create_user_group", loading: true }];
    let query = mutation_create_user_group;
    let variables = {
      data: {
        name: values?.name,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const update_user_group = (values) => {
    let key = [{ key: "update_user_group", loading: true }];
    let query = mutation_update_user_group;
    let variables = {
      id: selected_items,
      data: {
        name: values?.name,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (create_user_group_status === "success") {
      toast.success("User Group created");
      get_all_user_group();
      set_user_group_modal_visible(false);
      dispatch(dynamicClear("create_user_group"));
    } else if (create_user_group_error) {
      toast.error("error");
      set_user_group_modal_visible(false);
      dispatch(dynamicClear("create_user_group"));
    } else if (update_user_group_status === "success") {
      toast.success("User Group updated");
      get_all_user_group();
      set_user_group_modal_visible(false);
      dispatch(dynamicClear("update_user_group"));
    } else if (update_user_group_error) {
      toast.error("error");
      set_user_group_modal_visible(false);
      dispatch(dynamicClear("update_user_group"));
    }
  }, [
    create_user_group_status,
    create_user_group_error,
    update_user_group_status,
    update_user_group_error,
  ]);
  useEffect(() => {
    if (header_type === "edit") {
      form.setFieldsValue({
        name,
      });
    } else if (header_type === "add") {
      form.resetFields();
    }
  }, [header_type, name]);

  useEffect(() => {
    form.setFieldsValue({
      name: "",
    });
  }, []);
  return (
    <div>
      <Form
        id="add_user_group_form"
        form={form}
        labelCol={{
          span: 8,
        }}
        onFinish={handle_user_group_submit}
      >
        <CounterGroupName />
      </Form>
    </div>
  );
};

export default UserGroupForm;
