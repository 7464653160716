import { gql } from "@apollo/client";

export const devotee_payment_report_query=gql`
query get_devotee_payment{
    get_devotee_payment{
      items{
        date
        devotee_count
        devotee_amount
        vip_devotee_count
        vip_devotee_amount
      }
      total_summary{
        total_devotee_count
        total_devotee_amount
        total_vip_devotee_count
        total_vip_devotee_amount
        ticket_price
        vip_ticket_price
      }
      error{
        message
      }
       
    }
  }`;