import { Button, Modal } from "antd";
import CounterForm from "./counter_form";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import {
  dynamicRequest,
  mutation_get_unassigned_pos_list,
  useDynamicSelector,
} from "../../../services/redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const CounterModal = (props) => {
  const {
    counter_modal_open,
    handle_counter_modal_close,
    counter_modal_title,
    selected_items,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading: create_counter_loading } =
    useDynamicSelector("create_counter");
  const { loading: update_counter_loading } =
    useDynamicSelector("update_counter");

  return (
    <Modal
      open={counter_modal_open}
      destroyOnClose={true}
      width={"70%"}
      title={
        counter_modal_title == "add" ? t("add_counter") : t("edit_counter")
      }
      onCancel={handle_counter_modal_close}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      footer={[
        <Button onClick={handle_counter_modal_close}>{t("cancel")}</Button>,
        <Button
          style={{ backgroundColor: "#1e90ff", color: "white" }}
          htmlType="submit"
          form="add_counter_form"
          loading={create_counter_loading || update_counter_loading}
        >
          {t("save")}
        </Button>,
      ]}
    >
      <CounterForm
        counter_modal_title={counter_modal_title}
        selected_items={selected_items}
      />
    </Modal>
  );
};
export default CounterModal;
