import { Empty } from "antd";
import React from "react";

const CustomNoData = ({ description }) => {
  return (
    <div>
      <Empty description={<span>{description}</span>} />
    </div>
  );
};

export default CustomNoData;
