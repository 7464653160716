import { Row } from "antd";
import React, { useEffect, useState } from "react";
import BookingHistoryCountBox from "./booking_history_count_box";
import { useTranslation } from "react-i18next";
import {
  calculate,
  grouped_by_date,
} from "./booking_history_collapse/booking_history";
import { useDynamicSelector } from "../../../services/redux";

const BookingHistoryCount = (props) => {
  const { t } = useTranslation();
  const [booking_history_total, set_booking_history_total] = useState();
  const { items: booking_summary } = useDynamicSelector(
    "get_date_district_mandram_wise_summary"
  );
  const { items: districts } = useDynamicSelector("district_list");

  useEffect(() => {
    if (booking_summary && booking_summary?.length > 0) {
      let group_by = grouped_by_date(booking_summary, districts);
      set_booking_history_total(calculate(group_by));
    }
  }, [booking_summary]);
  console.log("booking_history_total", booking_history_total);

  return (
    <div>
      <Row
        style={{
          width: "100%",
          height: "100px",
          alignItems: "center",
        }}
      >
        <BookingHistoryCountBox
          label={t("total_booked")}
          value={booking_history_total?.sum_total_booked||0}
          backgroundColor={"#E84258"}
        />
        <BookingHistoryCountBox
          label={t("total_enrolled")}
          value={booking_history_total?.sum_total_enrolled}
          backgroundColor={"#FD8060"}
        />
        <BookingHistoryCountBox
          label={t("total_yet_to_enroll")}
          value={booking_history_total?.sum_total_yet_to_enroll}
          backgroundColor={"#FFA500"}
        />

        <BookingHistoryCountBox
          label={t("total_canceled")}
          value={booking_history_total?.sum_total_cancelled}
          backgroundColor={"#8281A0"}
        />
        <BookingHistoryCountBox
          label={t("total_prepaid")}
          value={booking_history_total?.sum_total_prepaid_amount}
          backgroundColor={"#808000"}
        />
      </Row>
    </div>
  );
};

export default BookingHistoryCount;
