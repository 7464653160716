import React, { useEffect, useState } from "react";
import CounterGroupList from "../components/counter_group/counter_group_list/counter_group_list";
import CustomPageTitle from "../components/common/custom_page_title";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import { Card, Row } from "antd";
import { useTranslation } from "react-i18next";
import CustomButton from "../components/common/custom_button";
import CounterGroupModal from "../components/counter_group/counter_group_modal/counter_group_modal";
import {
  mutation_delete_counter_group,
  query_get_all_counter_group,
  query_get_one_counter_group,
} from "../../services/redux/slices/graphql/graphql_counter_group";
import { dynamicRequest } from "../../services/redux";
import { useDispatch } from "react-redux";
import CustomDeleteModal from "../components/common/custom_delete_modal";

const CounterGroup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [counter_group_modal_visible, set_counter_group_modal_visible] =
    useState(false);
  const [delete_modal_visible, set_delete_modal_visible] = useState(false);
  const [selected_items, set_selected_items] = useState("");
  const [header_type, set_header_type] = useState("");
  const [current_limit, set_current_limit] = useState(10);
  const [current, set_current] = useState(1);

  const add_counter_group = () => {
    set_counter_group_modal_visible(true);
    set_header_type("add");
  };

  const get_all_counter_group = () => {
    let key = [{ key: "get_counter_group_list", loading: true }];
    let query = query_get_all_counter_group;
    let variables = {
      pagination: {
        page_limit: current_limit,
        page_number: current,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const delete_one_counter_group = (record) => {
    let key = [{ key: "delete_counter_group", loading: true }];
    let query = mutation_delete_counter_group;
    let variables = {
      id: selected_items.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_all_counter_group();
  }, []);

  return (
    <div>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row style={{ marginLeft: "10px" }}>
          <CustomPageTitle title={t("counter_group_title")} />
        </Row>

        <Row style={{ margin: "8px", marginRight: "23px" }}>
          {/* <CustomBreadCrumbs pages={"Home/Counter Group"} /> */}
        </Row>
      </Row>
      <Card
        style={{
          width: "96%",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Row
            style={{
              alignItems: "flex-end",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <CustomButton
              button_name={t("add_counter_group")}
              onclick_button={add_counter_group}
            />
          </Row>
        </Row>
        <CounterGroupList
          set_header_type={set_header_type}
          set_counter_group_modal_visible={set_counter_group_modal_visible}
          set_selected_items={set_selected_items}
          set_delete_modal_visible={set_delete_modal_visible}
          selected_items={selected_items}
          header_type={header_type}
          get_all_counter_group={get_all_counter_group}
          set_current={set_current}
          set_current_limit={set_current_limit}
          current={current}
        />
        <CounterGroupModal
          counter_group_modal_visible={counter_group_modal_visible}
          set_counter_group_modal_visible={set_counter_group_modal_visible}
          header_type={header_type}
          selected_items={selected_items}
          get_all_counter_group={get_all_counter_group}
        />
        <CustomDeleteModal
          selector_name={"delete_counter_group"}
          get_all={get_all_counter_group}
          delete_api={delete_one_counter_group}
          record={selected_items}
          text={t("counter_group")}
          delete_modal_visible={delete_modal_visible}
          set_delete_modal_visible={set_delete_modal_visible}
        />
      </Card>
    </div>
  );
};
export default CounterGroup;
