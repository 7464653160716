import { Avatar, Form, Modal, Radio, Segmented, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import {
  CARD_IMAGE,
  CARD_IMAGE_G,
  CASH_IMAGE,
  DD_IMAGE,
  UPI_G,
  UPI_IMAGE,
} from "../../../../../helpers/image_constants";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  query_check_has_pos_for_enroll,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const PaymentModeInput = (props) => {
  const { payment_mode, set_payment_mode } = props;

  const { result } = useDynamicSelector("check_pos_connect");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [pos_status, set_pos_status] = useState(false);

  const on_change = (value) => {
    set_payment_mode(value);
  };

  useEffect(() => {
    if (result) {
      set_pos_status(result);
    }
  }, [result]);

  return (
    <Form.Item
      label={
        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
          Mode of Payment
        </span>
      }
      name="mode_of_payment"
      rules={[
        {
          required: true,
          message: "Please select Payment mode",
        },
      ]}
    >
      <Space direction="horizontal">
        <Segmented
          onChange={on_change}
          value={payment_mode}
          style={{ width: "auto" }}
          options={[
            {
              label: (
                <div
                  style={{
                    padding: 4,
                    width: "100px",
                  }}
                >
                  <Avatar
                    style={{
                      width: "50px",
                      height: "40px",
                      padding: "2px",
                    }}
                    src={CASH_IMAGE}
                  />

                  <h4 style={{ fontWeight: "bold" }}>{t("Cash")}</h4>
                </div>
              ),
              value: "cash",
            },
            {
              label: (
                <div
                  style={{
                    padding: 4,
                    width: "100px",
                  }}
                >
                  <Avatar
                    style={{
                      width: "45px",
                      height: "40px",
                      padding: "2px",
                    }}
                    src={DD_IMAGE}
                  />

                  <h4 style={{ fontWeight: "bold" }}>DD</h4>
                </div>
              ),
              value: "dd",
            },
            {
              label: (
                <div
                  style={{
                    padding: 4,
                    width: "100px",
                  }}
                >
                  <Avatar
                    style={{
                      width: "50px",
                      height: "40px",
                      padding: "8px",
                    }}
                    src={CARD_IMAGE}
                  />

                  <h4 style={{ fontWeight: "bold" }}>CARD</h4>
                </div>
              ),
              value: "card",
              // disabled: pos_status === false,
            },
            {
              label: (
                <div
                  style={{
                    padding: 4,
                    width: "100px",
                  }}
                >
                  <Avatar
                    style={{
                      width: "70px",
                      height: "40px",
                      padding: "2px",
                    }}
                    src={UPI_IMAGE}
                  />

                  <h4 style={{ fontWeight: "bold" }}>UPI</h4>
                </div>
              ),
              value: "upi",
              // disabled: pos_status === false,
            },
          ]}
        />
      </Space>
    </Form.Item>
  );
};

export default PaymentModeInput;
