import { Button, Form } from 'antd'
import React from 'react'
import { useDynamicSelector } from '../../../../services/redux';

const VipBookingButton = ({title}) => {

  const {
    loading: create_vip_booking_loading,
  } = useDynamicSelector("create_spot_vip_booking");
  return (
    <div style={{ display:"flex",justifyContent:"flex-end", width: "100%"}}>
    <Button
      htmlType="submit"
      style={{
        borderRadius: "6px",
        backgroundColor: "#033E3E",
        color: "white",
        border: "transparent",
       width:"180px"
      }}
      loading={create_vip_booking_loading}
    >
      {title}
    </Button>
  </div>
  )
}

export default VipBookingButton
