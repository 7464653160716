import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const AddressReportSearchButton = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Button
        key="confirm"
        form="address_wise_search"
        htmlType="submit"
        style={{
          backgroundColor: "#014477",
          color: "white",
          marginLeft: "100px",
        }}
      >
        {t("get_details")}
      </Button>
    </div>
  );
};

export default AddressReportSearchButton;
