import { useDynamicSelector } from "@services/redux";
import { Button, Form, Input } from "antd";
import { t } from "i18next";
import { Box } from "native-base";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  dynamicClear,
  dynamicRequest,
  query_change_password,
} from "../../../../services/redux";

const ChangePassword = (props) => {
  const { set_is_modal_open } = props;
  const dispatch = useDispatch();
  const { action_item } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // const { status: change_password_status, error: change_password_error } =
  //   useDynamicSelector("change_password");

  // useEffect(() => {
  //   console.log("change_password_status:", change_password_status);
  //   console.log("change_password_error:", change_password_error);

  //   if (change_password_status === "success") {
  //     toast.success("Password Changed");
  //     form.resetFields();
  //     dispatch(dynamicClear("change_password"));
  //   } else if (change_password_status === "failure") {
  //     toast.error("Incorrect old password");

  //     dispatch(dynamicClear("change_password"));
  //   }
  // }, [change_password_status, change_password_error]);

  const change_password = (values) => {
    console.log("values77", values);
    let key = [{ key: "change_password", loading: true }];
    let query = query_change_password;
    let variable = {
      data: {
        old_password: values?.old_password,
        new_password: values?.new_password,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };
  const on_finish = (values) => {
    console.log("values1", values);
    change_password(values);
  };

  return (
    <div>
      <Form
        name="operation"
        id="change_password_form"
        form={form}
        onFinish={on_finish}
      >
        <Form.Item
          label={t("old_password")}
          name="old_password"
          placeholder="Old password"
          rules={[
            {
              required: true,
              message: t("current_password_is_required"),
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label={t("new_password")}
          name="new_password"
          placeholder="new password"
          rules={[
            {
              required: true,
              message: t("new_password_is_required"),
            },
          ]}
        >
          <Input.Password type="text" />
        </Form.Item>
        <Form.Item
          label={t("confirm_password")}
          name="confirm_password"
          placeholder="confirm password"
          rules={[
            { required: "true", message: t("confirm_password_required") },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("new_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The passwords do not match."));
              },
            }),
          ]}
        >
          <Input.Password type="text" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePassword;
