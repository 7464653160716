import { gql } from "@apollo/client";

export const mutation_enrolment_ticket_print = gql`
  mutation set_enrolment_ticket_printed($id: String!) {
    set_enrolment_ticket_printed(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
