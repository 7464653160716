import { Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../services/redux/selector";
import {
  dynamicClear,
  dynamicRequest,
  mutation_get_unassigned_pos_list,
  query_get_all_counter,
} from "../../../services/redux/slices";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const CounterList = (props) => {
  const {
    get_all_counter_list,
    set_counter_modal_open,
    set_counter_modal_title,
    handle_delete_counter,
    get_one_counter_record,
    set_selected_items,
    get_one_counter,
    // get_all_unassigned_pos_list,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [current_page_limit, set_current_page_limit] = useState(10);
  const [current_page, set_current_page] = useState(1);
  const {
    items: counter_list,
    pagination,
    loading: counter_loading,
  } = useDynamicSelector("get_counter_list");
  const { status: create_counter_status, error: create_counter_error } =
    useDynamicSelector("create_counter");
  const { status: update_counter_status, error: update_counter_error } =
    useDynamicSelector("update_counter");

  const { items: counter_group_list } = useDynamicSelector(
    "get_counter_group_list"
  );

  const handle_edit_counter = (record) => {
    set_counter_modal_open(true);
    get_one_counter(record.id);
    set_counter_modal_title("edit");
    set_selected_items(record);
    // get_all_unassigned_pos_list();
  };

  const fetch_data = (page_number, page_limit) => {
    let key = [{ key: "get_counter_list", loading: true }];
    let query = query_get_all_counter;
    let variable = {
      page_number: page_number,
      page_limit: page_limit,
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variable));
    set_current_page(page_number);
    set_current_page_limit(page_limit);
  };

  useEffect(() => {
    if (create_counter_status === "success") {
      toast.success("Counter Created");
      get_all_counter_list();
      set_counter_modal_open(false);
      dispatch(dynamicClear("create_counter"));
    } else if (create_counter_error) {
      // toast.error(create_counter_error?.message);
      toast.error("Couldn't Create Counter");
      set_counter_modal_open(false);
      dispatch(dynamicClear("create_counter"));
    } else if (update_counter_status === "success") {
      toast.success("Counter Updated");
      dispatch(dynamicClear("update_counter"));
      get_all_counter_list();
      set_counter_modal_open(false);
    } else if (update_counter_error) {
      // toast.error(update_counter_error?.message);
      toast.error("Couldn't Update Counter");
      set_counter_modal_open(false);
      dispatch(dynamicClear("update_counter"));
    }
  }, [
    create_counter_status,
    create_counter_error,
    update_counter_status,
    update_counter_error,
  ]);

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: "750px",
    },
    {
      title: t("code"),
      dataIndex: "code",
      key: "code",
      width: "750px",
    },
    {
      title: t("counter_group"),
      dataIndex: "counter_group_name",
      key: "counter_group_name",
      width: "750px",

      render: (_, record) => {
        const counterGroup = counter_group_list?.find(
          (group) => group.id === record?.counter_group_id
        );
        return counterGroup ? counterGroup.name : null;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>{t("actions")}</div>,
      dataIndex: "",
      key: "actions",
      width: "300px",
      align: "center",
      render: (_, record) => (
        <Space
          style={{
            width: "50px",
            justifyContent: "space-between",
          }}
        >
          <EditOutlined onClick={() => handle_edit_counter(record)} />
          <DeleteOutlined onClick={() => handle_delete_counter(record)} />
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
    >
      <Table
        // style={{ width: "100%" }}
        columns={columns}
        dataSource={counter_list}
        loading={counter_loading}
        pagination={{
          current: current_page,
          pageSize: current_page_limit,
          total: counter_list?.length,
          showSizeChanger: true,
          onChange: fetch_data,
          onShowSizeChange: (current, size) => {
            get_all_counter_list(current, size);
          },
        }}
      />
    </div>
  );
};
export default CounterList;
