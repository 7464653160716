import React from "react";
import { Modal, Button } from "antd";
import { Text } from "native-base";
import { useDynamicSelector } from "../../../../../../services/redux";
import { IoMdClose } from "react-icons/io";

const FeedBackExpandableDeleteModal = (props) => {
  const {
    is_delete_modal_visible,
    handle_delete_cancel,
    show_delete_model,
    handle_delete_confirm_expandable,
    handle_delete_cancel_expandable,
    handle_edit_expandable,
  } = props;
  const { loading: delete_feedback_question_option_loading } =
    useDynamicSelector("delete_feedback_question_option");

  return (
    <Modal
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      open={is_delete_modal_visible}
      title="Confirmation"
      onCancel={handle_delete_cancel_expandable}
      footer={[
        <Button key="cancel" onClick={handle_delete_cancel_expandable}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={() => handle_delete_confirm_expandable()}
          loading={delete_feedback_question_option_loading}
        >
          Delete
        </Button>,
      ]}
    >
      <Text>Do you really want to delete this?</Text>
    </Modal>
  );
};

export default FeedBackExpandableDeleteModal;
