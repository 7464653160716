import { Row, Typography } from "antd";
import React from "react";
const { Title } = Typography;

const ColorIndicator = ({ bg_color, text }) => {
  return (
    <Row
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        height: "20px",
        marginBottom: "5px",
      }}
    >
      <span
        style={{
          backgroundColor: bg_color,
          height: "20px",
          width: "20px",
          marginRight: "8px",
          borderRadius: "50%",
        }}
      ></span>
      <p className="color-indicator-text" style={{ marginTop: "12px" }}>
        {text}
      </p>
    </Row>
  );
};

export default ColorIndicator;
