import { Col, Space, Table } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../../services/redux";
import moment from "moment";
import PrintHeader from "../../../common/print_header";
import { IS_TABLE_SCROLLABLE } from "../../../../../helpers/constants";

const DevoteePaymentReportList = (props) => {
  const { excel_ref } = props;
  const {
    items: devotee_payment_report_items,

    loading: devotee_details_loading,
  } = useDynamicSelector("get_devotee_payment");

  const base_total_devotees = devotee_payment_report_items
    ? devotee_payment_report_items.reduce(
        (sum, record) => sum + (record.devotee_count || 0),
        0
      )
    : 0;

  const base_total_amount = devotee_payment_report_items
    ? devotee_payment_report_items.reduce(
        (sum, record) => sum + (record.devotee_amount || 0),
        0
      )
    : 0;
  const vip_total_devotees = devotee_payment_report_items
    ? devotee_payment_report_items.reduce(
        (sum, record) => sum + (record.vip_devotee_count || 0),
        0
      )
    : 0;

  const vip_total_amount = devotee_payment_report_items
    ? devotee_payment_report_items.reduce(
        (sum, record) => sum + (record.vip_devotee_amount || 0),
        0
      )
    : 0;

  const total_devotees = devotee_payment_report_items
    ? devotee_payment_report_items.reduce(
        (sum, record) =>
          sum + (record.devotee_count || 0) + (record.vip_devotee_count || 0),
        0
      )
    : 0;

  const total_amount = devotee_payment_report_items
    ? devotee_payment_report_items
        .reduce(
          (sum, record) =>
            sum +
            (record.devotee_amount || 0) +
            (record.vip_devotee_amount || 0),
          0
        )
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })
    : 0;

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      key: "date",
      width: "200px",
      render: (date, record) =>
        record?.date === "Total" ? "TOTAL" : moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "₹ 350",
      children: [
        {
          title: "Total Devotees",
          dataIndex: "devotee_count",
          key: "total_devotee",
          align: "right",
          width: "300px",
        },
        {
          title: "Total Amount",
          dataIndex: "devotee_amount",
          key: "total_amount",
          align: "right",
          width: "300px",
          render: (amount) => amount.toLocaleString("en-IN"),
        },
      ],
    },
    {
      title: "₹ 1000",
      children: [
        {
          title: "Total Devotees",
          dataIndex: "vip_devotee_count",
          key: "total_devotee",
          align: "right",
          width: "300px",
        },
        {
          title: "Total Amount",
          dataIndex: "vip_devotee_amount",
          key: "total_amount",
          align: "right",
          width: "300px",
          render: (amount) => amount.toLocaleString("en-IN"),
        },
      ],
    },
    {
      title: "NET Total Devotees",
      dataIndex: "net_total_devotees",
      align: "right",
      key: "net_total_devotees",
      width: "400px",
      render: (count, record) =>
        record?.devotee_count + record?.vip_devotee_count,
    },
    {
      title: "Net Total Amount",
      dataIndex: "net_total_amount",
      key: "net_total_amount",
      align: "right",
      width: "300px",
      render: (amount, record) =>
        (record?.devotee_amount + record?.vip_devotee_amount).toLocaleString(
          "en-IN"
        ),
    },
  ];

  return (
    <>
      <div className="print-header" style={{ padding: "15px" }}>
        <PrintHeader title={"DEVOTEE PAYMENT REPORT"} />
        <h4
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          DEVOTEE PAYMENT REPORT
        </h4>
      </div>

      <Col style={{ width: "auto", marginTop: "5px" }}>
        <Table
          data-name-table="district-wise-report"
          className="counter-user-wise-report screen-table"
          columns={columns}
          dataSource={devotee_payment_report_items}
          pagination={false}
          loading={devotee_details_loading}
          bordered
          scroll={{
            x: true,
            y: IS_TABLE_SCROLLABLE ? null : 300,
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <text style={{ fontWeight: "bold", paddingLeft: "20px" }}>
                    TOTAL
                  </text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} className="right-aligned-cell">
                  <text style={{ fontWeight: "bold" }}>
                    {base_total_devotees}
                  </text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} className="right-aligned-cell">
                  <text style={{ fontWeight: "bold" }}>
                    ₹{base_total_amount.toLocaleString("en-IN")}
                  </text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} className="right-aligned-cell">
                  <text style={{ fontWeight: "bold" }}>
                    {vip_total_devotees}
                  </text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} className="right-aligned-cell">
                  <text style={{ fontWeight: "bold" }}>
                    ₹{vip_total_amount.toLocaleString("en-IN")}
                  </text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} className="right-aligned-cell">
                  <text style={{ fontWeight: "bold" }}>{total_devotees}</text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} className="right-aligned-cell">
                  <text style={{ fontWeight: "bold" }}>{total_amount}</text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      </Col>

      <div style={{ padding: "10px", width: "100%", marginTop: "10px" }}>
        <table ref={excel_ref} className="table table-bordered print-table">
          <thead>
            <tr className="header-style">
              <th
                className="header-style"
                style={{ width: "15%", textAlign: "center" }}
              >
                Date
              </th>

              <th
                className="header-style"
                rowSpan="2"
                style={{ width: "25%", textAlign: "center" }}
              >
                ₹350
                <th>Total Devotees</th>
                <th>Total Amount</th>
              </th>
              <th
                className="header-style"
                rowSpan="2"
                style={{ width: "25%", textAlign: "center" }}
              >
                ₹1000
                <th>Total Devotees</th>
                <th>Total Amount</th>
              </th>

              <th
                className="header-style"
                style={{ width: "15%", textAlign: "center" }}
              >
                NET Total Devotees
              </th>
              <th
                className="header-style"
                style={{ width: "20%", textAlign: "center" }}
              >
                NET Total Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {devotee_payment_report_items?.map((report, index) => (
              <tr key={index}>
                <td className="body-style" style={{ textAlign: "center" }}>
                  {moment(report?.date).format("DD-MM-YYYY")}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{ borderRight: "1px solid gray", width: "50%" }}
                    >
                      {report?.devotee_count}
                    </span>
                    <span
                      style={{ width: "50%" }}
                    >{`₹${(report?.devotee_amount).toLocaleString(
                      "en-IN"
                    )}`}</span>
                  </div>
                </td>

                <td className="body-style" style={{ textAlign: "right" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{ borderRight: "1px solid gray", width: "50%" }}
                    >
                      {report?.vip_devotee_count}
                    </span>
                    <span
                      style={{ width: "50%" }}
                    >{`₹${(report?.vip_devotee_amount).toLocaleString(
                      "en-IN"
                    )}`}</span>
                  </div>
                </td>

                <td className="body-style" style={{ textAlign: "right" }}>
                  {/* <div style={{ width: "50%" }}> */}
                  {report?.devotee_count + report?.vip_devotee_count}
                  {/* </div> */}
                </td>
                <td className="body-style" style={{ textAlign: "right" }}>
                  {`₹${(
                    report?.devotee_amount + report?.vip_devotee_amount
                  ).toLocaleString("en-IN")}`}
                </td>
              </tr>
            ))}

            <tr>
              <td
                className="body-style"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Total
              </td>
              <td
                className="body-style"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span style={{ borderRight: "1px solid gray", width: "50%" }}>
                    {base_total_devotees}
                  </span>
                  <span
                    style={{ width: "50%" }}
                  >{`₹${base_total_amount.toLocaleString("en-IN")}`}</span>
                </div>
              </td>

              <td
                className="body-style"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span style={{ borderRight: "1px solid gray", width: "50%" }}>
                    {vip_total_devotees}
                  </span>
                  <span
                    style={{ width: "50%" }}
                  >{`₹${vip_total_amount.toLocaleString("en-IN")}`}</span>
                </div>
              </td>

              <td
                className="body-style"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                {total_devotees}
              </td>
              <td
                className="body-style"
                style={{ textAlign: "right", fontWeight: "bold" }}
              >
                {total_amount}
              </td>
            </tr>
          </tbody>
        </table>

        <style>
          {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-table {
        display: block !important;
      }
      .print-header {
        display: block !important;
      }

      @page {
        size: A4;
        margin: 1cm;
      }

      body::before {
        content: counter(page);
        top: 0;
        right: 1cm;
      }
    }
  `}
        </style>
      </div>
    </>
  );
};

export default DevoteePaymentReportList;
