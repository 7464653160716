import { Button, Form } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const DateWiseReportSearch = (props) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Form.Item>
        <Button
          htmlType="submit"
          style={{
            borderRadius: "5px",
            backgroundColor: "#025A80",
            color: "white",
          }}
        >
          {t("get")}
        </Button>
      </Form.Item>
    </div>
  );
};

export default DateWiseReportSearch;
