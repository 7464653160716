import React, { useEffect } from "react";
import { useDynamicSelector } from "../../../../services/redux";
import { retrieveItem } from "../../../../helpers/functions";

const EnrollmentListTable = (props) => {
  const { set_total_amount } = props;
  const role = retrieveItem("role");

  const { items: enrollment_items } = useDynamicSelector(
    "get_current_session_enrolment_list"
  );

  useEffect(() => {
    if (enrollment_items) {
      const calculated_total_amount = enrollment_items.reduce((total, item) => {
        const amount = item?.arrived_count * item?.booking?.price;
        return total + amount;
      }, 0);
      set_total_amount(calculated_total_amount);
    }
  }, [enrollment_items]);

  const table_style = {
    width: "100%",
    position: "sticky",
  };

  const cell_style = {
    textAlign: "center",
    height: "7px",
  };

  const table_container_style = {
    height: "260px",
    overflowY: "auto",
  };

  const format_date = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const map_payment_mode = (payment_mode) => {
    switch (payment_mode) {
      case "card":
        return "CARD";
      case "cash":
        return "CASH";
      case "upi":
        return "UPI";
      case "dd":
        return "DD";
      default:
        return payment_mode;
    }
  };

  return (
    <>
      <div style={table_container_style}>
        <table style={table_style}>
          <thead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#23645b",
              color: "white",
            }}
          >
            <tr>
              <th>Receipt No</th>
              <th>Booking Number</th>
              {/* <th>Abishekam Date</th> */}
              {/* <th>Enroll Date</th> */}
              <th>District Code</th>
              <th>Mandram Code</th>
              <th>Mandram Name</th>
              <th>Devotees</th>
              <th>Mode of Payment</th>
              {/* {role !== "VIP Enrolment Officer" && <th>Payment Status</th>} */}
              <th>Amount</th>
            </tr>
          </thead>
          <tbody style={{ overflowY: "auto", height: "90px" }}>
            {enrollment_items &&
              enrollment_items.map((item, index) => {
                const amount = item?.arrived_count * item?.booking?.price;
                const prepaid = item?.booking?.is_prepaid;
                const payment = map_payment_mode(item?.payment?.payment_mode);
                return (
                  <tr
                    key={item?.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f7f7f7" : "white",
                    }}
                  >
                    <td style={cell_style}>{item?.sequence_count?.count}</td>
                    <td style={cell_style}>
                      {item?.booking?.booking_reference}
                    </td>
                    {/* <td style={cell_style}>
                      {format_date(item?.booking?.date_slot?.date)}
                    </td> */}
                    {/* <td style={cell_style}>{format_date(item?.enrolled_on)}</td> */}
                    <td style={cell_style}>
                      {item?.booking?.mandram?.district?.code}
                    </td>
                    <td style={cell_style}>{item?.booking?.mandram?.code}</td>
                    <td style={cell_style}>{item?.booking?.mandram?.name}</td>
                    <td style={cell_style}>{item?.arrived_count}</td>
                    <td style={cell_style}>
                      {payment
                        ? payment
                        : map_payment_mode(
                            item?.booking?.payment?.payment_mode
                          )}
                    </td>
                    {/* {role !== "VIP Enrolment Officer" && (
                      <td style={cell_style}>
                        {prepaid === true ? "Prepaid" : "Paid at Temple"}
                      </td>
                    )} */}
                    <td style={{ ...cell_style, textAlign: "right" }}>
                      {amount.toLocaleString("en-IN", {
                        // style: "currency",
                        currency: "INR",
                      })}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EnrollmentListTable;
