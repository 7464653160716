import { Button, Form } from "antd";
import React from "react";
import { useDynamicSelector } from "../../../../services/redux/selector";

const BookingButton = ({ title, is_button_show }) => {
  console.log("is_button_show", is_button_show);
  const { loading: create_booking_loading } =
    useDynamicSelector("create_booking");
  return (
    // <Form.Item
    //   style={{
    //     marginTop: "20px",
    //     width: "100%",
    //     // display: "flex",
    //     // justifyContent: "flex-end",
    //   }}
    // >
    <div className="booking-button">
      <Button
        htmlType="submit"
        style={{
          borderRadius: "6px",

          backgroundColor: is_button_show ? "#f1f1f1" : "#033E3E",
          boxShadow: is_button_show
            ? "2px 2px 4px rgba(0, 2, 2, 0.10)"
            : "none",
          color: is_button_show ? "#90908F " : "white",
          border: "#000",
          width: "30%",
          marginLeft: "35%",
        }}
        disabled={is_button_show}
        loading={create_booking_loading}
      >
        {title}
      </Button>
    </div>
    // </Form.Item>
  );
};

export default BookingButton;
