import React, { useEffect } from "react";
import BookingAvailabilityStartDate from "./booking_availability_start_date";
import { Col, Form, Row } from "antd";
import BookingAvailabilityEndDate from "./booking_availability_end_date";
import BookingAvailabilityTicketPrice from "./booking_availability_ticket_price_form_item";
import TitleFormItem from "./booking_availability_title_form_item";
import SpTicketPriceFormItem from "./booking_availability_sp_ticket_perice_form_item";
import DayStartTimeFormItem from "./booking_availability_start_time_form_item";
import DayEndTimeFormItem from "./booking_availability_end_time_form_item";
import SlotTimeFormItem from "./booking_availability_slot_time_form_item";
import {
  create_season_mutation,
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import QuotaFormItem from "./booking_availability_quota_form_item";
import { toast } from "react-toastify";
import WaitingQuotaFormItem from "./season_date_slot_waiting_quota";

const BookingAvailabilityForm = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    header_type,
    set_edit_modal_visible,
    edit_values,
    get_all_season_list,
  } = props;
  const {
    status: create_season_status,
    error: create_season_error,
    loading,
  } = useDynamicSelector("create_season");

  useEffect(() => {
    if (edit_values.id) {
      form.setFieldsValue({
        ...edit_values,
      });
      set_initial_values({ ...edit_values });
    } else {
      form.resetFields();
    }
  }, [edit_values]);
  useEffect(() => {
    if (create_season_status === "success") {
      toast.success("Season created successfully");

      set_edit_modal_visible(false);

      get_all_season_list();
      dispatch(dynamicClear("create_season"));
    } else if (create_season_error?.message) {
      toast.error(create_season_error.message);
      get_all_season_list();
      dispatch(dynamicClear("create_season"));
    }
  }, [create_season_status, create_season_error]);

  const create_season = (values) => {
    let key = [{ key: "create_season", loading: true }];
    let query = create_season_mutation;
    let variable = {
      data: {
        ...values,
      },
    };

    dispatch(dynamicRequest(key, query, variable));
  };

  // const update_feedback = (edit_id, values, is_active) => {
  //   console.log("edit_id44", edit_id);
  //   console.log("valuesup", values);
  //   let key = [{ key: "update_feedback", loading: true }];
  //   let query = update_feedback_mutation;
  //   let variable = {
  //     id: edit_id,
  //     data: {
  //       title: values.title,
  //       is_active: is_active,
  //     },
  //   };
  //   console.log("variableww", variable);
  //   dispatch(dynamicRequest(key, query, variable));
  // };

  const on_finish = (values) => {
    // const { start_date, end_date } = values;
    // const range = calculate_date_range(start_date, end_date);
    // set_date_range(range);
    // Close the modal or perform any other actions
    // set_edit_modal_visible(false);

    if (header_type === "add") {
      create_season(values);
    } else if (header_type === "edit") {
      let edit_id = edit_values?.id;
      update_feedback(edit_id, values, is_active);
    }
  };

  return (
    <div>
      <Form id="booking_form" onFinish={on_finish} style={{ width: "100%" }}>
        <Row gutter={24}>
          <Col span={12}>
            <TitleFormItem />
          </Col>
          <Col span={12}> </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <BookingAvailabilityStartDate />
          </Col>
          <Col span={12}>
            <BookingAvailabilityEndDate />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <DayStartTimeFormItem />{" "}
          </Col>
          <Col span={12}>
            <DayEndTimeFormItem />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <BookingAvailabilityTicketPrice />
          </Col>
          <Col span={12}>
            {" "}
            <SpTicketPriceFormItem />
          </Col>
        </Row>
        <Row gutter={24}>
          {/* <Col span={12}>
            <SlotTimeFormItem />
          </Col> */}
          <Col span={12}>
            <QuotaFormItem />
          </Col>
          <Col span={12}>
            <WaitingQuotaFormItem />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <SlotTimeFormItem />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BookingAvailabilityForm;
