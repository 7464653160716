import { Form, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import { toast } from "react-toastify";
import {
  mutation_create_sub_district,
  mutation_update_sub_district,
} from "../../../../services/redux/slices/graphql/graphql_sub_district";
import InputSubDistrictIsActive from "../sub_district_form/sub_district_form_inputs/input_sub_district_is_active";
import { SubPosCard } from "../sub_district_form/sub_district_form_components/sub_district_pos";
import SubDistrict from "../sub_district_form/sub_district_form_components/sub_district";
import SubDistrictAdmin from "../sub_district_form/sub_district_form_components/sub_district_admin";

const SubDistrictForm = (props) => {
  const { header_type, set_edit_modal_visible, get_all_districts } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    id,
    name,
    tamil_name,
    code,
    group_id,
    is_vip,
    is_foreign,
    extra_allowed_percentage,
    order_number,
    is_active,
    district,
    admin,
    pos,
  } = useDynamicSelector("get_sub_district");

  const pos_id_list = pos?.map((item) => item.id);

  const {
    status: create_sub_district_status,
    error: create_sub_district_error,
  } = useDynamicSelector("create_sub_district");

  const {
    status: update_sub_district_status,
    error: update_sub_district_error,
  } = useDynamicSelector("update_sub_district");

  const {
    status: delete_sub_district_status,
    error: delete_sub_district_error,
  } = useDynamicSelector("delete_sub_district");

  const create_sub_district = (values) => {
    let keys = [{ key: "create_sub_district", loading: true }];
    let query = mutation_create_sub_district;
    let variable = {
      data: {
        ...values,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const update_sub_district = (values) => {
    let keys = [{ key: "update_sub_district", loading: true }];
    let query = mutation_update_sub_district;
    let variable = {
      id,
      data: {
        ...values,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const on_finish = (values) => {
    const update_values = {
      name: values.name,
      code: values.code,
      extra_allowed_percentage: values.extra_allowed_percentage,
      tamil_name: values.tamil_name,
      is_vip: values.is_vip,
      is_foreign: values.is_foreign,
      is_active: values.is_active,
      district_id: values.district_id,
      sub_district_admin: {
        name: values.district_admin?.name,
        email: values.district_admin?.email,
        mobile: values.district_admin?.mobile,
        system_user: {
          password: values.district_admin?.system_user?.password,
          username: values.district_admin?.system_user?.username,
        },
      },
      pos_id_list: values.pos_id_list,
    };
    header_type === "add_district"
      ? create_sub_district(update_values)
      : update_sub_district(update_values);
  };

  useEffect(() => {
    if (create_sub_district_status === "success") {
      toast.success("Sub District created Successfully");
      dispatch(dynamicClear("create_sub_district"));
      get_all_districts();
      set_edit_modal_visible(false);
    } else if (create_sub_district_error?.message) {
      toast.error("Something went wrong");
      dispatch(dynamicClear("create_sub_district"));
      // set_edit_modal_visible(false);
    } else if (update_sub_district_status === "success") {
      toast.success("Sub District updated Successfully");
      dispatch(dynamicClear("update_sub_district"));
      get_all_districts();
      set_edit_modal_visible(false);
    } else if (update_sub_district_error?.message) {
      toast.error("Something went wrong");
      dispatch(dynamicClear("update_sub_district"));
      // set_edit_modal_visible(false);
    }
    if (delete_sub_district_status === "success") {
      toast.success("District deleted Successfully");
      dispatch(dynamicClear("delete_sub_district"));
      get_all_districts();
      set_edit_modal_visible(false);
    } else if (delete_sub_district_error?.message) {
      toast.error("Something went wrong");
      dispatch(dynamicClear("delete_sub_district"));
      set_edit_modal_visible(false);
    }
  }, [
    create_sub_district_status,
    create_sub_district_error,
    update_sub_district_status,
    update_sub_district_error,
    delete_sub_district_status,
    delete_sub_district_error,
  ]);

  useEffect(() => {
    if (header_type === "edit_district") {
      form.setFieldsValue({
        id,
        name,
        tamil_name,
        code,
        group_id,
        is_vip,
        is_foreign,
        extra_allowed_percentage,
        order_number,
        is_active,
        district_id: district?.id,
        district_admin: {
          ...admin,
          system_user: {
            password: admin?.password,
            username: admin?.system_user?.username,
          },
        },
        pos_id_list: pos_id_list,
      });
    } else {
      form.resetFields();
    }
  }, [header_type, id]);
  return (
    <Form
      layout="horizontal"
      colon={false}
      labelAlign="left"
      id="district_form"
      onFinish={on_finish}
      form={form}
      autoComplete="off"
      labelCol={{
        span: 10,
      }}
    >
      <div
        style={{
          gap: "12px",
        }}
      >
        <SubDistrict {...props} />
        <SubDistrictAdmin {...props} />
        <Row style={{ width: "500px" }}>
          {header_type === "edit_district" ? <InputSubDistrictIsActive /> : ""}
        </Row>
        <SubPosCard />
      </div>
    </Form>
  );
};

export default SubDistrictForm;
