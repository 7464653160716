import React from "react";

const OfficeUseOnly = () => {
  return (
    <div>
      <p>For office use only</p>
      <div
        style={{
          width: "115px",
          height: "30px",
          border: "1px solid black",
          marginTop: "-15px",
        }}
      ></div>
    </div>
  );
};

export default OfficeUseOnly;
