import React, { useEffect, useRef, useState } from "react";
import { Row } from "antd";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";
import DailyTransactionReportList from "../components/system_report/daily_transaction_report/daily_transaction_report_list/daily_transaction_report_list";
import {
  query_daily_transactions_report,
  season_date_slots_list,
} from "../../services/redux/slices/graphql/graphql_daily_transaction_report";
import { dynamicRequest, useDynamicSelector } from "../../services/redux";
import { useDispatch } from "react-redux";
import { export_to_xlsx } from "../../helpers/functions";
import CustomReportSearch from "../components/common/custom_report_search";
import CustomButton from "../components/common/custom_button";
import DailyTransactionModal from "../components/system_report/daily_transaction_report/modal/daily_transaction_modal";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { capitalize } from "lodash";
import LoaderSpinner from "../components/common/custom_spinner";

const DailyTransactionReport = () => {
  const { t } = useTranslation();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const [show_print_button, set_show_print_button] = useState(false);
  const [modal_visible, set_modal_visible] = useState(false);
  const [selected_dates, set_selected_dates] = useState({});
  console.log("selected_dates", selected_dates);
  const {
    items: daily_transaction_items,
    loading: daily_transaction_items_loading,
  } = useDynamicSelector("get_daily_transaction_summary");

  const arrange_xlsx_report_data = (data_items) => {
    const rearranged_data = [];
    let totalAmount = 0;
    let totalDevotees = 0;

    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["Date"]: moment(data_items[i]?.date).format("DD-MM-YYYY") ?? "-",
        ["Receipt No"]: data_items[i]?.receipt_number ?? "-",
        ["VSG"]:
          `${data_items[i]?.mandram_code}/${data_items[i]?.mandram_name}` ??
          "-",
        ["Booking No"]: data_items[i]?.booking_reference ?? "-",
        ["Leader Name"]: capitalize(data_items[i]?.leader_name) ?? "-",
        ["Leader Mobile No"]: capitalize(data_items[i]?.leader_number) ?? "-",
        ["Mode of Payment"]: data_items[i]?.payment_mode ?? "-",
        ["Devotees"]: data_items[i]?.devotees ?? "-",
        ["Amount"]: data_items[i]?.amount ?? "-",
      };

      // Update totalAmount and totalDevotees
      totalAmount += parseFloat(data_items[i]?.amount) || 0;
      totalDevotees += parseFloat(data_items[i]?.devotees) || 0;

      rearranged_data.push(row_data);
    }

    // Add the total row
    rearranged_data.push({
      ["S.No"]: "Total",
      ["Date"]: "",
      ["Receipt No"]: "",
      ["VSG"]: "",
      ["Booking No"]: "",
      ["Leader Name"]: "",
      ["Leader Mobile No"]: "",
      ["Mode of Payment"]: "",
      ["Devotees"]: totalDevotees,
      ["Amount"]: totalAmount,
    });

    return rearranged_data;
  };

  const on_xlsx_download = () => {
    const arranged_data = arrange_xlsx_report_data(daily_transaction_items);
    export_to_xlsx(arranged_data, "daily_transaction_report");
  };

  const get_season_date_slot_list = () => {
    let keys = [{ key: "get_season_date_slot_list", loading: true }];
    let query = season_date_slots_list;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const on_print = () => {
    // set_modal_visible(true);
    handlePrint();
  };

  useEffect(() => {
    get_season_date_slot_list();
  }, []);

  return (
    <>
      <div>
        <Row style={{ marginLeft: "10px" }}>
          <CustomPageTitle title={t("day_wise_report_title")} />
        </Row>
        <div style={{ marginTop: "20px" }}>
          <CustomReportSearch
            set_selected_dates={set_selected_dates}
            dynamic_query={query_daily_transactions_report}
            dynamic_key={"get_daily_transaction_summary"}
          />
        </div>
        {daily_transaction_items_loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <LoaderSpinner />
          </div>
        )}
        {daily_transaction_items?.length > 0 ? (
          <>
            <Row
              style={{
                width: "96%",
                margin: "auto",
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <CustomButton
                id="daily_transaction_print_button"
                disabled={daily_transaction_items?.length > 0 ? false : true}
                button_name={"Print"}
                bg_color={"#1e2c55"}
                rounded={true}
                // icon={<AiFillPrinter />}
                //   icon={""}
                onclick_button={on_print}
              />
              <CustomButton
                disabled={daily_transaction_items?.length > 0 ? false : true}
                button_name={"Download"}
                bg_color={"#185c37"}
                rounded={true}
                // icon={<FileExcelOutlined />}
                onclick_button={on_xlsx_download}
              />
            </Row>
            <div style={{ marginTop: "20px" }} ref={componentRef}>
              <DailyTransactionReportList selected_dates={selected_dates} />
            </div>
          </>
        ) : null}
      </div>
      <DailyTransactionModal
        selected_dates={selected_dates}
        visible={modal_visible}
        set_visible={set_modal_visible}
      />
    </>
  );
};

export default DailyTransactionReport;
