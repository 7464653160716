import { Form, Input } from "antd";
import React from "react";

const InputSubDistrictUserName = (props) => {
  const { header_type } = props;
  return (
    <>
      <Form.Item
        name={["system_user", "username"]}
        label="Username"
        rules={[
          {
            required: true,
            message: "Please enter username",
          },
        ]}
      >
        <Input
          style={{ width: "270px" }}
          placeholder="Username"
          allowClear={true}
          disabled={header_type !== "add_user"}
        />
      </Form.Item>
    </>
  );
};

export default InputSubDistrictUserName;
