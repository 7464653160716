import { Form } from "antd";
import React from "react";
import Username from "./add_mandram_form_inputs/username";
import Password from "./add_mandram_form_inputs/password";
import ContactNumber from "./add_mandram_form_inputs/contact_number";
import EmailId from "./add_mandram_form_inputs/email_id";
import UserMandramName from "./add_mandram_form_inputs/user_mandram_name";
import MandramStatus from "./add_mandram_form_inputs/mandram_status";

const AddMandramUserForm = () => {
  const [form] = Form.useForm();
  const on_finish = (values) => {};
  return (
    <Form id="add_mandram_user_form" form={form} onFinish={on_finish}>
      <Username />
      <Password />
      <ContactNumber />
      <EmailId />
      <UserMandramName />
      <MandramStatus />
    </Form>
  );
};

export default AddMandramUserForm;
