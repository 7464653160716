import { Form, Input } from "antd";
import React from "react";

const ReceiptNumberInput = (props) => {
  const { receipt_input_ref } = props;
  return (
    <Form.Item
      label={
        <span style={{ fontWeight: "bold", fontSize: "15px" }}>Receipt No</span>
      }
      name="booking_reference"
      rules={[
        {
          required: true,
          message: "Please Enter the Receipt Number",
        },
      ]}
    >
      <Input
        // style={{ width: "150px", border: "1px solid gray" }}
        ref={receipt_input_ref}
        autoFocus
      />
    </Form.Item>
  );
};

export default ReceiptNumberInput;
