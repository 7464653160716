import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDynamicSelector } from "../../../../../services/redux";
import { startCase, toUpper, upperCase } from "lodash";
import moment from "moment";
import PrintHeader from "../../../common/print_header";
import { retrieveItem } from "../../../../../helpers/functions";
import { IS_TABLE_SCROLLABLE } from "../../../../../helpers/constants";

const BookingReportList = (props) => {
  const { from_date, to_date } = props;
  const { items: booking_report_items, loading: counter_group_items_loading } =
    useDynamicSelector("get_booking_report");

  const [irumudi_count, set_total_irumudi_count] = useState(0);

  // useEffect(() => {
  //   if (booking_report_items) {
  //     var total_irumudi =
  //       booking_report_items?.confirmed_count +
  //       booking_report_items?.waiting_count;
  //     set_total_irumudi_count(total_irumudi);
  //   }
  // }, [booking_report_items]);

  var total_irumudi_count = booking_report_items?.reduce((acc, item) => {
    return acc + (item?.confirmed_count + item?.waiting_count || 0);
  }, 0);

  const role = retrieveItem("role");

  const columns_super_admin = [
    {
      title: "Booking Reference",
    },
    {
      title: "Irumudi Count",
    },
    {
      title: "Mandram(VSG)",
    },
    {
      title: "Status",
    },
    {
      title: "Enrolled Count",
    },
    {
      title: "Yet To Enroll Count",
    },
    {
      title: "Excess",
    },
    {
      title: "Booked",
    },
    {
      title: "Payment Mode",
    },
    {
      title: "Leader",
    },
  ];

  const columns = [
    {
      title: "Booking Reference",
      key: "booking_no",
      align: "center",
      fixed: "left",
      render: (data) => data?.booking_reference,
    },
    {
      title: "Code",
      key: "mandram_code",
      render: (data) => data?.mandram_code,
    },
    {
      title: "Mandram(VSG)",
      key: "mandram_vsg",
      render: (data) => data?.mandram_name,
    },

    // {
    //   title: "Abishekam Date",
    //   key: "abishekam_date",
    //   render: (data) => moment(data?.abishekam_date).format("DD-MM-YYYY"),
    // },

    {
      title: "Booked",
      key: "irumudi_count",
      align: "center",
      render: (data) => {
        let total_irumudi = data?.confirmed_count + data?.waiting_count;
        return total_irumudi;
      },
    },
    {
      title: "Enrolled Count",
      key: "arrived_count",
      align: "center",
      render: (data) => data?.enrolled_count,
    },
    {
      title: "Yet To Enroll Count",
      key: "not_arrived_count",
      align: "center",
      render: (data) => data?.not_enrolled_count,
    },
    {
      title: "Excess",
      key: "excess",
      align: "center",
      render: (data) => data?.excess_count,
    },
    {
      title: "Booked By",
      key: "booked_by",
      align: "center",
      render: (data) => data?.booked_by_name,
    },
    {
      title: "Booked Date",
      key: "booked_date",
      align: "center",
      render: (data) => moment(data?.booked_date).format("DD-MM-YYYY"),
    },

    {
      title: "Leader Name",
      key: "leader_name",
      align: "center",
      render: (data) => data?.leader_name,
    },
    {
      title: "Mobile Number",
      key: "mobile",
      align: "center",
      render: (data) => data?.leader_mobile,
    },
    {
      title: "Payment Mode",
      key: "mode_of_payment",
      align: "center",
      render: (data) =>
        data?.payment_mode ? startCase(data?.payment_mode) : "-",
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      fixed: "right",
      render: (data) => upperCase(data?.booking_status),
    },
  ];

  const aggregated_data = booking_report_items?.reduce(
    (accumulator, current) => {
      const existing_object = accumulator.find(
        (item) => item.mandram_name === current.mandram_name
      );

      if (existing_object) {
        existing_object.enrolled_count += current.enrolled_count;
        existing_object.not_enrolled_count += current.not_enrolled_count;
        existing_object.excess_count += current.excess_count;
      } else {
        accumulator.push({
          mandram_name: current.mandram_name,
          mandram_code: current.mandram_code,
          enrolled_count: current.enrolled_count,
          not_enrolled_count: current.not_enrolled_count,
          excess_count: current.excess_count,
        });
      }

      return accumulator;
    },
    []
  );

  console.log("aggregated_data", aggregated_data);

  const columns_masm_admin = [
    {
      title: "Mandram(VSG)",
      key: "mandram_vsg",
      width: "300px",
      render: (data) => {
        return `${data?.mandram_code}/${data?.mandram_name}`;
      },
    },
    {
      title: "Enrolled Count",
      key: "arrived_count",
      align: "center",
      width: "240px",

      render: (data) => data?.enrolled_count,
    },
    {
      title: "Yet To Enroll Count",
      key: "not_arrived_count",
      align: "center",
      width: "240px",

      render: (data) => data?.not_enrolled_count,
    },
    {
      title: "Excess",
      key: "excess",
      width: "240px",

      align: "center",
      render: (data) => data?.excess_count,
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        margin: "auto",
        width: "98%",
        marginBottom: "20px",
      }}
    >
      {role === "MASM Admin" ? (
        <Table
          style={{ width: "100%" }}
          data-name-table="district-wise-report"
          className="screen-table"
          columns={columns_masm_admin}
          loading={counter_group_items_loading}
          dataSource={aggregated_data}
          pagination={false}
          scroll={{
            x: 1280,
            y: IS_TABLE_SCROLLABLE ? null : 300,
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <text style={{ fontWeight: "bold", paddingLeft: "38px" }}>
                    Total
                  </text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} className="aligned-center">
                  <text style={{ fontWeight: "bold", marginLeft: "105px" }}>
                    {booking_report_items
                      ? booking_report_items.reduce(
                          (sum, record) => sum + (record?.enrolled_count || 0),
                          0
                        )
                      : 0}
                  </text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} className="aligned-center">
                  <text style={{ fontWeight: "bold", marginLeft: "105px" }}>
                    {booking_report_items
                      ? booking_report_items.reduce(
                          (sum, record) =>
                            sum + (record?.not_enrolled_count || 0),
                          0
                        )
                      : 0}
                  </text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} className="aligned-center">
                  <text style={{ fontWeight: "bold", marginLeft: "110px" }}>
                    {booking_report_items
                      ? booking_report_items.reduce(
                          (sum, record) => sum + (record?.excess_count || 0),
                          0
                        )
                      : 0}
                  </text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      ) : (
        <Table
          style={{ width: "99%" }}
          data-name-table="district-wise-report"
          className="screen-table"
          columns={columns}
          loading={counter_group_items_loading}
          dataSource={booking_report_items}
          pagination={false}
          scroll={{
            x: 1920,
            y: 300,
          }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <text style={{ fontWeight: "bold", paddingLeft: "50px" }}>
                    Total
                  </text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3} className="aligned-center">
                  <text style={{ fontWeight: "bold", marginLeft: "58px" }}>
                    {booking_report_items
                      ? booking_report_items.reduce(
                          (sum, record) =>
                            sum +
                            (record?.confirmed_count + record?.waiting_count ||
                              0),
                          0
                        )
                      : 0}
                  </text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} className="aligned-center">
                  <text style={{ fontWeight: "bold", marginLeft: "58px" }}>
                    {booking_report_items
                      ? booking_report_items.reduce(
                          (sum, record) => sum + (record?.enrolled_count || 0),
                          0
                        )
                      : 0}
                  </text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} className="aligned-center">
                  <text style={{ fontWeight: "bold", marginLeft: "58px" }}>
                    {booking_report_items
                      ? booking_report_items.reduce(
                          (sum, record) =>
                            sum + (record?.not_enrolled_count || 0),
                          0
                        )
                      : 0}
                  </text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} className="aligned-center">
                  <text style={{ fontWeight: "bold", marginLeft: "58px" }}>
                    {booking_report_items
                      ? booking_report_items.reduce(
                          (sum, record) => sum + (record?.excess_count || 0),
                          0
                        )
                      : 0}
                  </text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
      )}

      {role === "MASM Admin" ? (
        <>
          <div style={{ width: "100%", margin: "auto", marginBottom: "20px" }}>
            <div className="print-header">
              <PrintHeader />
              <h4
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                BOOKING REPORT
              </h4>
              <h4
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {to_date}
              </h4>
            </div>
            <table
              className="table table-bordered print-table"
              style={{
                width: "100%",
                marginTop: "10px",
                borderCollapse: "collapse",
                // marginLeft: "-20px",
              }}
            >
              <thead style={{ width: "100%" }}>
                <tr>
                  {columns_masm_admin.map((column) => (
                    <th
                      className="header-style"
                      style={{
                        // width: "100%",
                        border: "1px solid #ddd",
                        textAlign: "center",
                      }}
                      key={column.key}
                    >
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {aggregated_data?.map((data, index) => (
                  <tr key={index}>
                    {/* <td
                      className="body-style "
                      style={{
                        textAlign: "right",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.confirmed_count}
                    </td> */}
                    <td
                      className="body-style "
                      style={{
                        textAlign: "left",
                        border: "1px solid #ddd",
                        width: "200px",
                      }}
                    >{`${data?.mandram_name}/${data?.mandram_code}`}</td>

                    <td
                      className="body-style "
                      style={{
                        textAlign: "right",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.enrolled_count}
                    </td>
                    <td
                      className="body-style "
                      style={{
                        textAlign: "right",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.not_enrolled_count}
                    </td>
                    <td
                      className="body-style "
                      style={{
                        textAlign: "right",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.excess_count}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tr>
                <td
                  style={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                  }}
                >
                  Total
                </td>
                {/* <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  <text style={{ textAlign: "right" }}>
                    {total_irumudi_count}
                  </text>
                </td> */}
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {booking_report_items
                    ? booking_report_items.reduce(
                        (sum, record) => sum + (record?.enrolled_count || 0),
                        0
                      )
                    : 0}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {booking_report_items
                    ? booking_report_items.reduce(
                        (sum, record) =>
                          sum + (record?.not_enrolled_count || 0),
                        0
                      )
                    : 0}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {booking_report_items
                    ? booking_report_items.reduce(
                        (sum, record) => sum + (record?.excess_count || 0),
                        0
                      )
                    : 0}
                </td>
              </tr>
            </table>
          </div>
          <style>
            {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-header {
        display: block !important;
      }

      @media print {
        @page {
          size: A4;
          margin: 1cm;
        }
  
        body::before {
          content: counter(page);
          top: 0;
          right: 1cm;
        }
      }
    }
  `}
          </style>
        </>
      ) : (
        <>
          <div style={{ width: "100%", margin: "auto", marginBottom: "20px" }}>
            <div className="print-header">
              <PrintHeader />
              <h4
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                BOOKING REPORT
              </h4>
              <h4
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {to_date}
              </h4>
            </div>
            <table
              className="table table-bordered print-table"
              style={{
                width: "100%",
                marginTop: "10px",
                borderCollapse: "collapse",
                marginLeft: "-10px",
              }}
            >
              <thead style={{ width: "100%" }}>
                <tr>
                  {columns_super_admin.map((column) => (
                    <th
                      className="header-style"
                      style={{
                        width: "100%",
                        border: "1px solid #ddd",
                        textAlign: "center",
                      }}
                      key={column.key}
                    >
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {booking_report_items?.map((data, index) => (
                  <tr key={index}>
                    <td
                      className="body-style "
                      style={{ border: "1px solid #ddd" }}
                    >
                      {data?.booking_reference}
                    </td>
                    <td
                      className="body-style "
                      style={{
                        textAlign: "right",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.confirmed_count + data?.waiting_count}
                    </td>
                    <td
                      className="body-style "
                      style={{
                        textAlign: "left",
                        border: "1px solid #ddd",
                        width: "90px",
                      }}
                    >
                      {`${data?.mandram_name}`}
                      <br></br>
                      {`${data?.mandram_code}`}
                    </td>
                    <td
                      className="body-style "
                      style={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {upperCase(data?.booking_status)}
                    </td>
                    <td
                      className="body-style "
                      style={{
                        textAlign: "right",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.enrolled_count}
                    </td>
                    <td
                      className="body-style "
                      style={{
                        textAlign: "right",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.not_enrolled_count}
                    </td>
                    <td
                      className="body-style "
                      style={{
                        textAlign: "right",
                        border: "1px solid #ddd",
                      }}
                    >
                      {data?.excess_count}
                    </td>
                    <td
                      className="body-style "
                      style={{
                        textAlign: "left",
                        border: "1px solid #ddd",
                      }}
                    >
                      {`${data?.booked_by_name}`}
                      <br></br>
                      {`${moment(data?.booked_date).format("DD-MM-YYYY")}`}
                    </td>
                    <td
                      className="body-style "
                      style={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {toUpper(data?.payment_mode)}
                    </td>
                    <td
                      className="body-style "
                      style={{
                        textAlign: "left",
                        border: "1px solid #ddd",
                      }}
                    >
                      {`${data?.leader_name}`}
                      <br></br>
                      {`${data?.leader_mobile}`}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tr>
                <td
                  style={{
                    border: "1px solid #ddd",
                    fontWeight: "bold",
                  }}
                >
                  Total
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  <text style={{ textAlign: "right" }}>
                    {total_irumudi_count}
                  </text>
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {"-"}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {"-"}
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  <text style={{ textAlign: "right" }}>
                    {booking_report_items
                      ? booking_report_items.reduce(
                          (sum, record) => sum + (record?.enrolled_count || 0),
                          0
                        )
                      : 0}
                  </text>
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  <text style={{ textAlign: "right" }}>
                    {booking_report_items
                      ? booking_report_items.reduce(
                          (sum, record) =>
                            sum + (record?.not_enrolled_count || 0),
                          0
                        )
                      : 0}
                  </text>
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  <text style={{ textAlign: "right" }}>
                    {booking_report_items
                      ? booking_report_items.reduce(
                          (sum, record) => sum + (record?.excess_count || 0),
                          0
                        )
                      : 0}
                  </text>
                </td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                  }}
                ></td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                  }}
                ></td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                  }}
                ></td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                  }}
                ></td>
                <td
                  style={{
                    fontWeight: "bold",
                    border: "1px solid #ddd",
                  }}
                ></td>
              </tr>
              {/* </tfoot> */}
            </table>
          </div>
          <style>
            {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-header {
        display: block !important;
      }

      @media print {
        @page {
          size: A4;
          margin: 1cm;
        }
  
        body::before {
          content: counter(page);
          top: 0;
          right: 1cm;
        }
      }
    }
  `}
          </style>
        </>
      )}
    </div>
  );
};

export default BookingReportList;
