import { Form, Input } from "antd";
import React, { useState } from "react";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Password = (props) => {
  const { header_type } = props;
  const [password_visible, set_password_visible] = useState(false);

  return (
    <Form.Item
      name={["mandram_admin", "system_user", "password"]}
      label="Password"
      rules={
        header_type === "Add Mandram"
          ? [
              {
                required: true,
                message: "Please enter Password",
              },
            ]
          : []
      }
    >
      <Input
        type={password_visible ? "text" : "password"}
        suffix={
          <span
            onClick={() => set_password_visible(!password_visible)}
            style={{ cursor: "pointer" }}
          >
            {password_visible ? <AiFillEye /> : <AiFillEyeInvisible />}
          </span>
        }
      />
    </Form.Item>
  );
};

export default Password;
