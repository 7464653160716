import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { toUpper, upperCase } from "lodash";
import { useTranslation } from "react-i18next";
import SwamiImage from "@assets/icons/adhiparashathi_image.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColumn: {
    flexDirection: "column",
  },
  tableCell: {
    borderWidth: 1,
    padding: 8,
    flex: 1,
    textAlign: "left",
    fontSize: "8px",
  },
  tableColHeader: {
    borderWidth: 1,
    backgroundColor: "#f0f0f0",
    padding: 8,
    flex: 1,
    textAlign: "center",
    fontSize: "10px",
  },
  table_header: {
    display: "flex",
    margin: "5px",
    fontWeight: "bold",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "10px",
  },
  header_content: {
    fontWeight: "600",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "10px",
  },
  image: {
    width: "40px",
    height: "40px",
  },
  full_header_content: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-evenly",
  },
  flexContainer: {
    marginTop: "-15px",
  },
});

const CounterPaymentPdfRender = ({
  title = "Counter Payment Report",
  count,
  counter_list,
  counter,
  // selected_date,
  total_amount,
}) => {
  const { t } = useTranslation();
  return (
    <Document fileName={`Counter-payment-report.pdf`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.flexContainer}>
            <View style={styles.full_header_content}>
              <Text style={styles.header_content}>{t("om_shakthi")}</Text>
              <Text style={styles.header_content}>{t("masm")} </Text>
              <Text style={styles.header_content}>
                {t("adhiparashakthi_siddhar_peedam")}
              </Text>
            </View>
          </View>

          <View></View>
          <Text style={styles.table_header}>{toUpper(title)}</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>
                Counters:
                {counter
                  ? counter
                  : counter_list?.map((counter) => counter.name).join(",")}
              </Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>
                {/* Date: {selected_date?.length === 0 ? "All Date" : selected_date} */}
              </Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>
                No of irumudi: {count?.no_of_irumudi}
              </Text>
              <Text style={styles.tableColHeader}>
                Total Amount: {total_amount}
              </Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>Payment Details</Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>Card</Text>
              <Text style={styles.tableColHeader}>
                {count?.received_card_amount}
              </Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>Cash</Text>
              <Text style={styles.tableColHeader}>
                {count?.received_cash_amount}
              </Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>DD</Text>
              <Text style={styles.tableColHeader}>
                {count?.received_dd_amount}
              </Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>UPI</Text>
              <Text style={styles.tableColHeader}>
                {count?.received_upi_amount}
              </Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>Prepaid</Text>
              <Text style={styles.tableColHeader}>{count?.total_prepaid}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CounterPaymentPdfRender;
