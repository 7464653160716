import React, { useEffect, useState } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Row,
  Col,
  Pagination,
  Switch,
  Typography,
  Form,
  Radio,
  Spin,
  Empty,
} from "antd";
import {
  create_feedback_response_mutation,
  dynamicClear,
  dynamicRequest,
  get_feedback_question_list_user,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// import { TextArea } from "native-base";
import { Input } from "antd";
import CustomButton from "../components/common/custom_button";
import { navigateBack } from "../../helpers/functions";

const { Panel } = Collapse;
const { TextArea } = Input;

const Feedback = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [check_box_values, set_check_box_values] = useState({});
  const [current, set_current] = useState(1);
  const [current_limit, set_current_limit] = useState(5);
  const [is_switch, set_is_switch] = useState(false);
  const [feedback_list, set_feedback_list] = useState([]);
  const [is_option_id, set_is_option_id] = useState("");

  const { items: get_feedback_question_list_user_item, loading } =
    useDynamicSelector("get_feedback_question_list_user");

  const {
    status: create_feedback_response_status,
    error: create_feedback_response_error,
    loading: create_feedback_response_loading,
  } = useDynamicSelector("create_feedback_response");

  useEffect(() => {
    get_all_feedback_question_query_user();
  }, []);

  useEffect(() => {
    if (create_feedback_response_status == "success") {
      toast.success("Feedback submitted");
      form.resetFields();
      dispatch(dynamicClear("create_feedback_response"));
    } else if (create_feedback_response_error?.message) {
      toast.error(create_feedback_response_error.message);
      dispatch(dynamicClear("create_feedback_response"));
    }
  });

  const fetch_data = (page_number, page_limit) => {
    let keys = [{ key: "get_feedback_question_list_user", loading: true }];
    let query = get_feedback_question_list_user;
    let variable = {
      page_number: page_number,
      page_limit: page_limit,
    };
    dispatch(dynamicRequest(keys, query, variable));
    set_current(page_number);
    set_current_limit(page_limit);
  };

  const get_all_feedback_question_query_user = () => {
    let key = [{ key: "get_feedback_question_list_user", loading: true }];
    let query = get_feedback_question_list_user;
    let variables = {
      filter: {
        feedback_id: "8bc0e795-94a5-416b-ba48-41dbdf10fecb",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const create_feedback_response = (feedback_responses) => {
    let key = [{ key: "create_feedback_response", loading: true }];
    let query = create_feedback_response_mutation;
    let variables = {
      data: {
        feedback_id: "8bc0e795-94a5-416b-ba48-41dbdf10fecb",
        feedback_question_response_list: feedback_responses,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const on_finish = (values, val) => {
    const feedback_responses = [];

    let hasError = false; // Flag to track if there is any error

    get_feedback_question_list_user_item?.forEach((question) => {
      const response = {
        feedback_question_id: question.id,
        text: null,
        feedback_question_option_id: is_option_id,
      };

      if (question.type === "select") {
        if (
          !values.feedback_question_response_list ||
          !values.feedback_question_response_list[question.id] ||
          !values.feedback_question_response_list[question.id]
            .feedback_question_option_id
        ) {
          hasError = true;
        } else {
          response.feedback_question_option_id =
            values.feedback_question_response_list[
              question.id
            ]?.feedback_question_option_id;
        }
      } else {
        if (
          !values.feedback_question_response_list ||
          !values.feedback_question_response_list[question.id] ||
          !values.feedback_question_response_list[question.id].typedText
        ) {
          hasError = true;
        } else {
          response.feedback_question_option_id = null;
          response.text =
            values.feedback_question_response_list[question.id].typedText;
        }
      }

      feedback_responses.push(response);
    });

    if (hasError) {
      console.error("Please fill in all required fields");
      return;
    }

    set_feedback_list(feedback_responses);
    create_feedback_response(feedback_responses);
    // form.resetFields();
  };

  const handle_radio_change = (optionId, currentItem) => {
    set_is_option_id(optionId);
  };

  const handle_onchange = (key, value, checked) => {
    set_check_box_values((prevValues) => ({
      ...prevValues,
      [key]: { value, checked },
    }));
  };

  const handle_change = (value, values) => {};
  const handle_submit = () => {
    // form.resetFields();
  };

  const handle_back = () => {
    navigateBack();
  };

  const handle_cancel = () => {
    form.resetFields();
  };

  return (
    <div>
      <CustomPageTitle title={t("feedback_title")} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card
          style={{
            width: "95%",
            borderRadius: "10px",
            border: "1px solid #e0e0e0",
            boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <Button
                onClick={handle_cancel}
                style={{ backgroundColor: "white", color: "black" }}
              >
                Cancel
              </Button>
            </div>
            <Button
              style={{ backgroundColor: "#179093", color: "white" }}
              htmlType="submit"
              key="confirm"
              type="primary"
              form="user_response_form"
              onClick={handle_submit}
              loading={create_feedback_response_loading}
            >
              {t("submit")}
            </Button>
          </div>
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spin />
            </div>
          ) : (
            <Form
              id="user_response_form"
              onFinish={on_finish}
              onValuesChange={handle_change}
              form={form}
            >
              <Collapse>
                {get_feedback_question_list_user_item?.map((item, index) => (
                  <Panel
                    header={
                      <div>
                        <span style={{ color: "black" }}>{item.question}</span>
                      </div>
                    }
                    key={index}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <div style={{ flexDirection: "row", marginTop: "5px" }}>
                          {/* Hidden form item */}
                          <Form.Item
                            hidden
                            name={`${item.question}-${item.id}`}
                          />
                          {/* Radio group for selecting options */}
                          <Form.Item
                            name={[
                              "feedback_question_response_list",
                              item.id,
                              "feedback_question_option_id",
                            ]}
                            // label={item.question}
                            // valuePropName="checked"
                          >
                            <Radio.Group
                              onChange={(e) => handle_radio_change(item.id)}
                            >
                              {item.type === "select"
                                ? item.options.map((option) => (
                                    <Col>
                                      <Radio key={option.id} value={option.id}>
                                        {option.option}
                                      </Radio>
                                    </Col>
                                  ))
                                : null}
                            </Radio.Group>
                          </Form.Item>
                        </div>
                        {item.type !== "select" && (
                          <Form.Item
                            name={[
                              "feedback_question_response_list",
                              item.id,
                              "typedText",
                            ]}
                            // label="Text"
                          >
                            <div
                              style={{
                                width: "200%",
                              }}
                            >
                              <TextArea
                                rows={4}
                                style={{
                                  width: "300%",
                                  marginBottom: "10px",
                                  // backgroundColor: "#fff !important",
                                }}
                              />
                            </div>
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  </Panel>
                ))}
              </Collapse>
            </Form>
          )}

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            {/* <div style={{ marginRight: "10px" }}>
              <CustomButton
                button_name={t("back")}
                onclick_button={handle_back}
              />
            </div> */}
            {/* <div style={{ marginRight: "10px" }}>
              <Button
                onClick={handle_cancel}
                style={{ backgroundColor: "white", color: "black" }}
              >
                Cancel
              </Button>
            </div> */}
            {/* <Button
              style={{ backgroundColor: "#179093", color: "white" }}
              htmlType="submit"
              key="confirm"
              type="primary"
              form="user_response_form"
              onClick={handle_submit}
              loading={create_feedback_response_loading}
            >
              {t("submit")}
            </Button> */}
          </div>
          {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Pagination
              current={current}
              pageSize={current_limit}
              total={get_feedback_question_list_user?.length}
              onChange={fetch_data}
              showSizeChanger={true}
              onShowSizeChange={fetch_data}
            />
          </div> */}
        </Card>
      </div>
    </div>
  );
};

export default Feedback;
