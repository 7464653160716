import { Form, Select } from "antd";
import React, { useEffect } from "react";
import {
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../../services/redux";
import _orderBy from "lodash/orderBy";
import { useDispatch } from "react-redux";

const DailyTransactionReportFromDate = (props) => {
  const dispatch = useDispatch();
  const { items: date_slot_items } = useDynamicSelector(
    "get_season_date_slot_list"
  );

  const date_list = () => {
    let keys = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variable = {
      filter: {
        from_date,
        to_date,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    date_list();
  }, []);

  return (
    <div>
      <Form.Item
        name="from_date"
        label="From Date"
        rules={[{ required: true, message: "Please select From Date" }]}
        allowClear={true}
      >
        <Select placeholder="Select From Date" allowClear={true}>
          {date_slot_items?.map((date, index) => (
            <Select.Option key={index} value={date?.id} label={date?.id}>
              {date.date_string}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default DailyTransactionReportFromDate;
