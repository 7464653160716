import { Form, Input, InputNumber } from "antd";
import React from "react";

const CashInput = (props) => {
  const { payment_mode } = props;

  return (
    <Form.Item
      label="Amount"
      name="cash"
      rules={
        payment_mode === "cash"
          ? [
              {
                type: "number",
                message: "Please enter a valid number",
              },
              //   {
              //     required: true,
              //     message: "Please Enter the Amount",
              //   },
            ]
          : []
      }
    >
      <InputNumber disabled={true} />
    </Form.Item>
  );
};

export default CashInput;
