import { gql } from "@apollo/client";

export const mutation_create_pos = gql`
  mutation create_pos($data: create_pos_input!) {
    create_pos(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_pos = gql`
  mutation update_pos($id: String!, $data: update_pos_input!) {
    update_pos(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_pos = gql`
  mutation delete_pos($id: String!) {
    delete_pos(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_all_pos = gql`
  query get_all_pos(
    $filter: get_pos_list_filter_input
    $pagination: pagination_input
  ) {
    get_pos_list(filter: $filter, pagination: $pagination) {
      items {
        id
        name
        terminal_id
        type
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_one_pos = gql`
  query query_get_one_pos($id: String!) {
    get_pos(id: $id) {
      id
      name
      terminal_id
      type
    }
  }
`;

export const mutation_upsert_extra_allowed_percentage = gql`
  mutation update_settings($data: extra_allowed_percentage_input) {
    update_settings(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_settings = gql`
  query {
    get_settings {
      id
      bank_branch_extra_allowed_percentage
    }
  }
`;
