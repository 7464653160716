import { Button, Input, Space, Table } from "antd";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  query_address_report,
  useDynamicSelector,
} from "../../../../../services/redux";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useDispatch } from "react-redux";
import { IS_TABLE_SCROLLABLE } from "../../../../../helpers/constants";
import PrintHeader from "../../../common/print_header";
import { FaPhoneAlt, FaUser } from "react-icons/fa";
import { BsPersonBadgeFill } from "react-icons/bs";

const AddressReportListItems = (props) => {
  const { district_name, selected_date } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const search_input = useRef(null);
  const [searched_column, set_searched_column] = useState(null);
  const [current, set_current] = useState(1);
  const [current_limit, set_current_limit] = useState(5);
  const [search_text, set_search_text] = useState({});
  const { items: address_list_items, loading: address_list_loading } =
    useDynamicSelector("get_address_report_summary");

  const handle_search = (selectedKeys, confirm, data_index) => {
    console.log("selectedKeys", selectedKeys);
    confirm();
    set_search_text({ ...search_text, [data_index]: selectedKeys[0] });
  };

  const handle_reset = (clearFilters, data_index) => {
    clearFilters();
    set_search_text({ ...search_text, [data_index]: "" });
  };

  const get_column_search_props = (data_index, customTitle) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={search_input}
          placeholder={`Search ${customTitle}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handle_search(selectedKeys, confirm, data_index)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            onClick={() => handle_reset(clearFilters, data_index)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => handle_search(selectedKeys, confirm, data_index)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : "white" }} />
    ),
    onFilter: (value, record) =>
      record[data_index]
        ? record[data_index]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => search_input.current?.select(), 100);
      }
    },
    render: (text, record) => {
      return searched_column === data_index ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[search_text[data_index]]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : data_index === "leader_name" ? (
        record?.leader_name
      ) : data_index === "mobile" ? (
        record?.mobile
      ) : data_index === "district_name" ? (
        record.booking.district_name
      ) : data_index === "amount" ? (
        getAmountFormat(record?.booking.count * record?.booking.price)
      ) : data_index === "district_code" ? (
        record.district_code
      ) : data_index === "enrolled_by_mobile" ? (
        record.mandram_name
      ) : (
        text
      );
    },
  });

  const columns = [
    {
      title: t("leader_column"),
      dataIndex: "",
      width: 170,
      key: "leader_name",
      ...get_column_search_props("leader_name", "Name"),
      render: (data) => {
        return (
          <div>
            <p style={{ marginRight: "2px" }}>
              <FaUser />
              {`${data?.leader_name}`}
            </p>
            <p style={{ marginRight: "2px" }}>
              <FaPhoneAlt />
              {`${data?.mobile}`}
            </p>
            <p style={{ marginRight: "2px" }}>
              <BsPersonBadgeFill />
              {`${data?.id_number}`}
            </p>
          </div>
        );
      },
    },
    {
      title: t("address"),
      key: "",
      width: "190px",
      render: (data) => {
        return (
          <p style={{ width: "40px" }}>{data?.address ? data?.address : "-"}</p>
        );
      },
    },
    {
      title: "District",
      dataIndex: "",
      width: 60,
      key: "district_name",
      ...get_column_search_props("district_name", "District Name"),
      render: (data) => {
        return `${data?.district_name}-${data?.district_code}` || "-";
      },
    },

    {
      title: "Mandram(VSG)",
      dataIndex: "",
      key: "vsg_name",
      width: 70,
      ...get_column_search_props("mandram_name", "Mandram Name"),
      render: (data) => {
        return (
          <p
            style={{ width: "160px" }}
          >{`${data?.mandram_code}/${data?.mandram_name}`}</p>
        );
      },
    },

    {
      title: t("total_payment"),
      key: "total_payment",
      align: "center",
      width: 60,
      render: (record) => {
        const formattedAmount = record?.amount
          ? record.amount.toLocaleString("en-US", {
              style: "currency",
              currency: "INR",
            })
          : "-";

        return formattedAmount;
      },
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div style={{ margin: "10px 0 20px 0", width: "96%" }}>
        <Table
          data-name-table="district-wise-report"
          className="counter-user-wise-report screen-table"
          columns={columns}
          dataSource={address_list_items}
          loading={address_list_loading}
          pagination={false}
          scroll={{
            x: true,
            y: IS_TABLE_SCROLLABLE ? null : 300,
          }}
        ></Table>
      </div>

      <div>
        <div className="print-header" style={{ padding: "10px" }}>
          <PrintHeader />
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            ADDRESS WISE REPORT
          </h4>
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {`${selected_date}`}
          </h4>
        </div>

        <table className="table table-bordered print-table">
          <thead>
            <tr>
              <th className="header-style">Leader</th>
              <th className="header-style address">Address</th>
              <th className="header-style">District </th>
              <th className="header-style">Mandram</th>
              <th className="header-style">Total Payment</th>
            </tr>
          </thead>
          <tbody className="tbody">
            {address_list_items?.map((item, index) => (
              <tr key={index}>
                <td className="body-style">
                  {`${item?.leader_name}`}
                  <br />
                  {`${item?.mobile}`}
                  <br />
                  {`${item?.id_number}`}
                </td>
                <td className="body-style" style={{ alignItems: "flex-start" }}>
                  <p className="address" style={{ width: "100px" }}>
                    {item?.address}
                  </p>
                </td>
                <td className="body-style">{`${item?.district_name}-${item?.district_code}`}</td>
                <td className="body-style">{`${item?.mandram_code} /${item?.mandram_name}`}</td>
                <td className="body-style">
                  {item?.amount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <style>
          {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
        width:97% !important;
      }
      .print-header {
        display: block !important;
      }
     .tbody{
      width:95% !important;
     }

    }
  `}
        </style> */}
        <style>
          {`
          @media screen {
            .print-table {
              display: none;
            }
            .print-header {
              display: none;
            }
          }

          @media print {
            .screen-table {
             
              display: none !important;
              width: 97% !important;
            }
            .print-header {
              display: block !important;
            }
            .tbody {
              width: 95% !important;
            }
            th.ant-table-filter-column {
              display: none !important;
            }
            td.body-style {
              border: 1px solid black !important;
            }
            .address{
              width:140px;
            }
          }
        `}
        </style>
        {/* <style>
          {`
    @media screen {
      .print-table {
        display: none;
      }
      .print-header {
        display: none;
      }
    }

    @media print {
      .screen-table {
        display: none !important;
      }
      .print-header {
        padding:10px;
        display: block !important;
      }

      @media print {
        @page {
          size: A4;
          margin: 1cm;
        }
  
        body::before {
          content: counter(page);
          top: 0;
          right: 1cm;
        }
      }
    }
  `}
        </style> */}
      </div>
    </div>
  );
};

export default AddressReportListItems;
