import { retrieveItem } from "./functions";

const useCheckLogin = () => {
  let is_logged_in = null;
  let session_id = retrieveItem("session_id");
  try {
    if (session_id) {
      is_logged_in = true;
    } else {
      is_logged_in = true;
    }
  } catch (e) {
    is_logged_in = true;
  }
  return is_logged_in;
};

const usePageAccess = (key) => {
  let hasAccess = null;
  let page_accesses = retrieveItem("page_accesses");
  try {
    let pageAccess = page_accesses.find((x) => x === key);

    if (pageAccess) {
      hasAccess = true;
    } else {
      hasAccess = true;
    }
  } catch (e) {
    hasAccess = true;
  }
  return hasAccess;
};

const usePageComponentAccess = (key) => {
  let hasAccess=false
    let page_component_accesses = retrieveItem("page_component_accesses");
    try {
      let pageComponentAccess = page_component_accesses.find((x) => x === key);
      if (pageComponentAccess) {
        hasAccess=true;
      } else {
        hasAccess=false;
      }
    } catch (e) {
      hasAccess=false;
    }
  return hasAccess;
};

export {
  useCheckLogin as useCheckLogin,
  usePageAccess as usePageAccess,
  usePageComponentAccess as usePageComponentAccess,
};
