import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { district_details_query } from "../../../../../services/redux/slices/graphql/graphql_district";
import {
  dynamicRequest,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const AddressReportFormItem = (props) => {
  const {
    set_selected_district,
    selected_mandram,
    set_district_name,
    set_value_change,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: district_list_item } = useDynamicSelector("district_list");
  useEffect(() => {
    get_district_list();
  }, []);
  const get_district_list = () => {
    let key = [{ key: "district_list", loading: true }];
    let query = district_details_query;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handle_change = (values, option) => {
    set_selected_district(values);
    set_district_name(option?.label);
    set_value_change(true);
  };
  const custom_filter_option = (input, option) => {
    const inputValue = input.toLowerCase();
    const optionLabel = option.props.children.toLowerCase();
    return optionLabel.indexOf(inputValue) === 0;
  };

  return (
    <div>
      <Form.Item name="district" label={t("district")}>
        <Select
          showSearch
          allowClear
          filter
          filterOption={custom_filter_option}
          placeholder="Select District"
          onChange={handle_change}
        >
          {district_list_item?.map((option, index) => (
            <Select.Option key={index} value={option.id} label={option.name}>
              {option.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default AddressReportFormItem;
