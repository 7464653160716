import React from "react";
import AddMandramUserForm from "./add_mandram_user_form";
import { Button, Modal } from "antd";

const AddMandramUserModal = (props) => {
  const { set_add_mandram_user, mandram_user, header_type } = props;

  const add_mandram_user = (values) => {};
  return (
    <Modal
      title={header_type}
      open={mandram_user}
      onCancel={() => set_add_mandram_user(false)}
      footer={[
        <Button
          style={{
            backgroundColor: "#168f92",
            color: "white",
            border: "transparent",
          }}
          onClick={() => set_add_mandram_user(false)}
        >
          Cancel
        </Button>,
        <Button
          style={{
            backgroundColor: "#f96451",
            color: "white",
            border: "transparent",
          }}
          onClick={add_mandram_user}
          htmlType="submit"
          form="add_mandram_user_form"
        >
          Save
        </Button>,
      ]}
    >
      <AddMandramUserForm />
    </Modal>
  );
};

export default AddMandramUserModal;
