import React, { useEffect, useState } from "react";
import { Card, Form, Row } from "antd";
import SpotBookingForm from "../components/spot_booking/spot_booking_form/spot_booking_form";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import SpotBookingReceiptModal from "../components/spot_booking/spot_booking_receipt_modal/spot_booking_receipt_modal";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import { useDynamicSelector } from "../../services/redux";
import SpotBookingNotificationModal from "../components/spot_booking/spot_booking_duplicate_modal/spot_booking_duplicate_modal";
import SpotBookingDuplicateModal from "../components/spot_booking/spot_booking_duplicate_modal/spot_booking_duplicate_modal";

const SpotBooking = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [receipt_modal_open, set_receipt_modal_open] = useState(false);
  const [selected_id_proof, set_selected_id_proof] = useState("Aadhaar Card");
  const [mobile, set_mobile] = useState("");

  const [get_leader_mobile_no, set_leader_mobile_number] = useState();
  const [duplicate_booking_modal, set_duplicate_booking_modal] =
    useState(false);

  const { status: create_spot_booking_status } = useDynamicSelector(
    "create_spot_booking"
  );

  const get_mobile_number = (mobile_number) => {
    // set_mobile(mobile_number);
    console.log("get_leader_mobile_no", mobile);
  };

  return (
    <div>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row>
          <CustomPageTitle title={t("spot_booking_title")} />
        </Row>

        <Row style={{ margin: "8px", marginRight: "23px" }}>
          {/* <CustomBreadCrumbs pages={"Home/Spot Booking"} /> */}
        </Row>
      </Row>

      <Card
        style={{
          width: "96%",
          margin: "auto",
          minHeight: 0,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <SpotBookingForm
          set_leader_mobile_number={set_leader_mobile_number}
          get_leader_mobile_no={get_leader_mobile_no}
          get_mobile_number={get_mobile_number}
          set_receipt_modal_open={set_receipt_modal_open}
          form={form}
          selected_id_proof={selected_id_proof}
          set_selected_id_proof={set_selected_id_proof}
          set_duplicate_booking_modal={set_duplicate_booking_modal}
        />

        <SpotBookingReceiptModal
          get_leader_mobile_no={get_leader_mobile_no}
          mobile={mobile}
          receipt_modal_open={receipt_modal_open}
          set_receipt_modal_open={set_receipt_modal_open}
          form={form}
          set_selected_id_proof={set_selected_id_proof}
        />

        <SpotBookingDuplicateModal
          duplicate_booking_modal={duplicate_booking_modal}
          set_duplicate_booking_modal={set_duplicate_booking_modal}
          get_leader_mobile_no={get_leader_mobile_no}
          mobile={mobile}
          set_receipt_modal_open={set_receipt_modal_open}
          form={form}
          set_selected_id_proof={set_selected_id_proof}
        />
      </Card>
    </div>
  );
};

export default SpotBooking;
