import React from "react";
import { Table } from "antd";

const columns = [
  {
    title: "Denomination",
    dataIndex: "denomination",
    render: (text) => <span style={{ fontSize: "14px" }}>{text}</span>,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    render: (text, record) => (
      <span style={{ fontSize: "14px", marginLeft: "5px" }} className="amount">
        {record.key !== "8" ? "________=" : null}
      </span>
    ),
    align: "right",
  },
];

const data = [
  { key: "1", denomination: "\u00A0\u00A0\u00A0\u00A0500X", amount: " " },
  { key: "2", denomination: "\u00A0\u00A0\u00A0\u00A0200X", amount: " " },
  { key: "3", denomination: "\u00A0\u00A0\u00A0\u00A0100X", amount: " " },
  {
    key: "4",
    denomination: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A050X",
    amount: " ",
  },
  {
    key: "5",
    denomination: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A020X",
    amount: " ",
  },
  {
    key: "6",
    denomination: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A010X",
    amount: " ",
  },
];

const Denomination = () => {
  return (
    <>
      <style>
        {`
          @media print {
            .custom-row-denomination {
              margin-top: 50px;
              height:35px;
            }
            .amount {
              padding-top: 10px;
              height: 19px;
            }
          }
        `}
      </style>

      <div
        style={{
          width: "17%",
          margin: "80px 120px 0px 70px",
        }}
      >
        <Table
          width="35%"
          height="auto"
          style={{ margin: "10px 5px 5px 5px" }}
          columns={columns}
          dataSource={data}
          showHeader={false}
          pagination={false}
          rowClassName="custom-row-denomination"
        />
        <h4
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
            marginLeft: "72px",
          }}
        >
          Total =
        </h4>
      </div>
    </>
  );
};

export default Denomination;
