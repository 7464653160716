import React, { useEffect, useRef, useState } from "react";
import { useDynamicSelector } from "../../../services/redux";
import { Button, Spin, Table } from "antd";
import jsPDF from "jspdf";
import { HStack } from "native-base";
import { getAmountFormat, retrieveItem } from "../../../helpers/functions";
import moment from "moment";
import Denomination from "../receipt/PDF/denomination";

const EnrolPrint = (props) => {
  const { current_date_time, onCancel, date, set_pdf_modal } = props;

  const {
    total_cash_amount,
    total_card_amount,
    total_dd_amount,
    total_upi_amount,
    total_arrived_count,
    session_end_time,
    user_name,
    loading,
  } = useDynamicSelector("get_session_wise_report");

  const system_user = retrieveItem("system_user");

  const { name: current_counter_name } = useDynamicSelector(
    "get_current_counter"
  );

  console.log("current_counter_name", current_counter_name);

  const total_amount =
    total_upi_amount + total_dd_amount + total_card_amount + total_cash_amount;

  const data = [
    { key: "1", denomination: "\u00A0\u00A0\u00A0\u00A0500X", amount: " " },
    { key: "2", denomination: "\u00A0\u00A0\u00A0\u00A0200X", amount: " " },
    { key: "3", denomination: "\u00A0\u00A0\u00A0\u00A0100X", amount: " " },
    {
      key: "4",
      denomination: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A050X",
      amount: " ",
    },
    {
      key: "5",
      denomination: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A020X",
      amount: " ",
    },
    {
      key: "6",
      denomination: "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A010X",
      amount: " ",
    },
  ];

  const columns = [
    {
      title: "Denomination",
      dataIndex: "denomination",
      render: (text) => <span style={{ fontSize: "14px" }}>{text}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text, record) => (
        <span
          style={{ fontSize: "14px", marginLeft: "5px" }}
          className="amount"
        >
          {record.key !== "8" ? "_______________=" : null}
        </span>
      ),
      align: "right",
    },
  ];

  const table_style = {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "80%",
  };

  const cell_style = {
    border: "1px solid black",
  };

  const printStyles = `
      @media print {
        table {
          border-collapse: collapse;
          padding: 10px !important;

        }
        td, th {
          padding: 10px !important;
        }
        .denomination{
          display:block !important;
        }
      }
    `;

  const printTable = () => {
    set_pdf_modal(false);
    const printWindow = window.open("", "", "width=600,height=600");
    printWindow.document.write("<html><head><title></title></head><body>");
    printWindow.document.write(
      "<style>@media print { .no-print { display: none; } }</style>"
    );
    printWindow.document.write(reportTemplateRef.current.outerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const reportTemplateRef = useRef(null);

  const generatePDF = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    // Adding the fonts.
    doc.setFont("Arial Unicode MS", "normal");
    doc.text(20, 20, "₹ 100");

    doc.html(reportTemplateRef.current, {
      x: 10,
      y: 10,
      width: 600,
      callback(generatedDoc) {
        onCancel();
        generatedDoc.save("document");
        // printTable();
      },
    });
  };

  return loading ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spin />
    </div>
  ) : (
    <div>
      <div ref={reportTemplateRef}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <text>
            Enrolled By :
            <text style={{ fontWeight: "bold", marginLeft: "10px" }}>
              {user_name}
            </text>
          </text>
        </div>
        <style>{printStyles}</style>
        <table style={table_style} className="print-details">
          <thead>
            <tr style={{ ...cell_style, border: "transparent" }}>
              <th style={{ border: "transparent" }}>
                <text
                  style={{ textDecoration: "underline", marginLeft: "50px" }}
                >
                  Omsakthi
                </text>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={cell_style}>
              <td
                style={{
                  border: "transparent",
                }}
              >
                {/* <text
                  style={{
                    marginLeft: "250px",
                    width: "100%",
                    color: "white",
                  }}
                >
                  {date}
                </text> */}
              </td>
            </tr>
            <tr style={cell_style}>
              <td>
                <text style={{ fontWeight: "bold", marginLeft: "14px" }}>
                  NO.OF.IRUMUDI
                </text>
              </td>
              <td style={{ borderRight: "transparent" }}>
                <text style={{ fontWeight: "bold", marginRight: "14px" }}>
                  AMOUNT
                </text>
              </td>
            </tr>
            <tr style={cell_style}>
              <td>
                <text style={{ marginLeft: "50px", fontWeight: "bold" }}>
                  {total_arrived_count || "-"}
                </text>
              </td>
              <td style={{ borderRight: "transparent", fontWeight: "bold" }}>
                <text>₹ {getAmountFormat(total_amount) || "0"}</text>
              </td>
            </tr>
            <tr style={cell_style}>
              <td style={{ borderRight: "transparent", height: "50px" }}></td>
            </tr>
            <tr style={cell_style}>
              <th style={{ border: "transparent" }}>
                <text
                  style={{ marginLeft: "50px", textDecoration: "underline" }}
                >
                  Amount Details
                </text>
              </th>
            </tr>
            <tr style={cell_style}>
              <td>
                <text style={{ marginLeft: "15px" }}>CASH</text>
              </td>
              <td
                style={{
                  borderRight: "transparent",
                  textAlign: "right",
                  width: "100px",
                }}
              >
                <text>₹ {getAmountFormat(total_cash_amount || 0)}</text>
              </td>
            </tr>
            <tr style={cell_style}>
              <td>
                <text style={{ marginLeft: "15px" }}>DD</text>
              </td>
              <td
                style={{
                  borderRight: "transparent",
                  textAlign: "right",
                  width: "100px",
                }}
              >
                <text>₹ {getAmountFormat(total_dd_amount || 0)}</text>
              </td>
            </tr>
            <tr style={cell_style}>
              <td>
                <text style={{ marginLeft: "15px" }}>CARD</text>
              </td>
              <td
                style={{
                  borderRight: "transparent",
                  textAlign: "right",
                  width: "100px",
                }}
              >
                <text>₹ {getAmountFormat(total_card_amount || 0)}</text>
              </td>
            </tr>
            <tr style={cell_style}>
              <td>
                <text style={{ marginLeft: "15px" }}>UPI</text>
              </td>
              <td
                style={{
                  borderRight: "transparent",
                  textAlign: "right",
                  width: "100px",
                }}
              >
                <text>₹ {getAmountFormat(total_upi_amount || 0)}</text>
              </td>
            </tr>
            <tr style={cell_style}>
              <td>
                <text
                  style={{
                    marginLeft: "14px",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  TOTAL
                </text>
              </td>
              <td
                style={{
                  borderRight: "transparent",
                  fontWeight: "bold",
                  textAlign: "right",
                  width: "100px",
                }}
              >
                <text>₹ {getAmountFormat(total_amount || 0)}</text>
              </td>
            </tr>
            <tr style={cell_style}>
              <td
                style={{
                  borderRight: "transparent",
                  height: "40px",
                  width: "350px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <text style={{ display: "flex", justifyContent: "center" }}>
                  Generated By {system_user} on {date}
                </text>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="denomination" style={{ display: "none" }}>
          {/* <Denomination /> */}
          <>
            <style>
              {`
          @media print {
            .custom-row-denomination {
              margin-top: 50px;
              height:35px;
              width:400px !important;
            }
            .amount {
              padding-top: 10px;
              height: 19px;
            }
            .total{
            }
          }
        `}
            </style>

            <div
              style={{
                width: "17%",
                margin: "80px 120px 0px 70px",
              }}
            >
              <Table
                width="65%"
                height="auto"
                style={{ margin: "10px 5px 5px 5px" }}
                columns={columns}
                dataSource={data}
                showHeader={false}
                pagination={false}
                rowClassName="custom-row-denomination"
              />
              <h4
                className="total"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: "12px",
                  marginLeft: "92px",
                }}
              >
                Total =
              </h4>
            </div>
          </>
        </div>
      </div>
      <HStack justifyContent={"flex-end"} mt={"10px"} space={"20px"}>
        <Button onClick={printTable} type="primary">
          Print
        </Button>
        <Button danger onClick={onCancel}>
          Close
        </Button>
      </HStack>
    </div>
  );
};

export default EnrolPrint;
