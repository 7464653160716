import { ReloadOutlined } from "@ant-design/icons";
import { Button, Row, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../services/redux";

const RefreshButton = (props) => {
  const { get_dashboard } = props;
  const { t } = useTranslation();
  const { loading: dashboard_loading } = useDynamicSelector(
    "get_overall_dashboard"
  );

  return (
    <div>
      <Row
        style={{
          display: "flex",
          margin: "auto",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{
            backgroundColor: "#1e2c55",
            color: "white",
            borderRadius: "5px",
          }}
          loading={dashboard_loading}
          onClick={get_dashboard}
        >
          <ReloadOutlined />
          {t("Refresh")}
        </Button>
      </Row>
    </div>
  );
};
export default RefreshButton;
