import { count_format } from "@src/helpers/constants";
import { Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

const BookingHistoryHeader = (props) => {
  const { t } = useTranslation();
  const { item } = props;
  console.log("itemitem", item);

  return (
    <Row>
      <Col span={3} className="booking_history_header_item">
        {item?.district_name ? item?.district_name : item?.date}
      </Col>
      <Col span={3} className="booking_history_header_item">
        {"Booked: "}
        {count_format(item?.total_booked || item?.booked)}
      </Col>
      <Col span={3} className="booking_history_header_item">
        {"Cancelled: "}
        {count_format(item?.total_cancelled_count || item?.cancelled)}
      </Col>
      <Col span={3} className="booking_history_header_item">
        {"Enrolled: "}
        {count_format(item?.total_enrolled || item?.enrolled)}
      </Col>
      <Col span={4} className="booking_history_header_item">
        {"Yet To Enroll: "}
        {count_format(item?.total_yet_to_enroll_count || item?.yet_to_enroll)}
      </Col>
      <Col span={3} className="booking_history_header_item">
        {"Excess: "}
        {count_format(item?.total_excess_count || item?.excess)}
      </Col>
      <Col span={4} className="booking_history_header_item">
        {"Prepaid: "}
        {count_format(item?.total_prepaid_amount || item?.prepaid || 0)}
      </Col>
    </Row>
  );
};

export default BookingHistoryHeader;
