import { Button, Col, Form, Input, InputNumber, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  query_get_booking_user_details,
  useDynamicSelector,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

const VipBookingUserMobileNumberSection = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contact_number, set_contact_number] = useState();

  const leader_details = useDynamicSelector("get_booking_user_details");
  console.log("contact_number", contact_number);

  const handle_change = (e) => {
    // set_contact_number(e.target.value);
    const input_value = e.target.value;
    const mobile_number_pattern = /^\d{10}$/;

    if (mobile_number_pattern.test(input_value)) {
      set_contact_number(input_value);
    }
  };
  // const handle_get_leader_details = () => {
  //   get_booking_user_details();
  // };

  const get_booking_user_details = () => {
    let key = [{ key: "get_booking_user_details", loading: true }];
    let query = query_get_booking_user_details;
    let variables = {
      custom: {
        booking_user_reference: contact_number,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_booking_user_details();
  }, []);

  // const onclick_click = () => {
  //   form.setFieldsValue({
  //     mobile: "",
  //   });
  //   set_contact_number("");
  //   dispatch(dynamicClear("get_booking_user_details"));
  //   form.resetFields();
  // };

  return (
    <div className="vip_booking_form_item_container">
      <div className="vip_booking_form_item_wrapper">
        <Form.Item
          name="mobile"
          label={t("Mobile Number")}
          rules={[
            { required: true, message: t("Please enter your mobile number") },
          ]}
        >
          <Input
            placeholder={t("Enter mobile number")}
            onChange={handle_change}
            disabled={leader_details?.id ? true : false}
          />
        </Form.Item>
      </div>
      {/* <Col span={12} style={{ display: "flex", alignItems: "center" }}>
        <Button
          style={{ width: "80px", backgroundColor: "#033e3e", color: "#fff" }}
          onClick={
            leader_details?.id ? onclick_click : handle_get_leader_details
          }
        >
          {leader_details?.id ? "Change" : t("Get")}
        </Button>
      </Col> */}

      <div className="vip_booking_form_item_wrapper">
        <Form.Item
          name="leader_name"
          label={t("Leader Name")}
          rules={[
            { required: true, message: t("Please enter the leader's name") },
          ]}
        >
          <Input placeholder={t("Enter leader's name")} />
        </Form.Item>
      </div>
    </div>
  );
};

export default VipBookingUserMobileNumberSection;
