import { Form, Radio } from "antd";
import React from "react";

const VIPForeignBooking = (props) => {
  const { set_booking_type, get_district_list } = props;
  const handle_radio_change = (e) => {
    const type = e.target.value;
    set_booking_type(type);
    get_district_list(type);
  };
  return (
    <div style={{ marginTop: "3px" }}>
      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: "Please select booking type" }]}
      >
        <div style={{ marginLeft: "3px" }}>
          <Radio.Group onChange={handle_radio_change}>
            <Radio value={false}>VIP</Radio>
            <Radio value={true}>Foreign</Radio>
          </Radio.Group>
        </div>
      </Form.Item>
    </div>
  );
};

export default VIPForeignBooking;
