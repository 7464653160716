import { Pagination, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import {
  query_get_all_counter_group,
  query_get_one_counter_group,
} from "../../../../services/redux/slices/graphql/graphql_counter_group";
import { dynamicRequest, useDynamicSelector } from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const CounterGroupList = (props) => {
  const {
    set_counter_group_modal_visible,
    set_header_type,
    header_type,
    set_selected_items,
    set_delete_modal_visible,
    get_all_counter_group,
    selected_items,
    set_current,
    set_current_limit,
    current,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [current_page_limit, set_current_page_limit] = useState(5);
  // const [current_page, set_current_page] = useState(1);
  const {
    items: counter_group_list,
    loading: counter_group_loading,
    pagination: counter_group_pagination,
  } = useDynamicSelector("get_counter_group_list");
  const { id, name } = useDynamicSelector("get_counter_group");

  const get_one_counter_group = (id) => {
    let key = [{ key: "get_counter_group", loading: true }];
    let query = query_get_one_counter_group;
    let variables = {
      id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetch_data = (page_number, page_limit) => {
    let key = [{ key: "get_bank_list", loading: true }];
    let query = query_get_bank_list;
    let variables = {
      pagination: {
        page_limit: page_limit,
        page_number: page_number,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
    set_current_limit(page_limit);
    set_current(page_number);
  };

  // const fetch_data = (page_number, page_limit) => {
  //   let key = [{ key: "get_counter_group_list", loading: true }];
  //   let query = query_get_all_counter_group;
  //   let variable = {
  //     page_number: page_number,
  //     page_limit: page_limit,
  //   };
  //   dispatch(dynamicRequest(key, query, variable));
  //   set_current_page(page_number);
  //   set_current_page_limit(page_limit);
  // };

  const handle_counter_group_edit = (values) => {
    set_counter_group_modal_visible(true);
    set_header_type("edit");
    set_selected_items();
    set_selected_items(values.id);
    get_one_counter_group(values.id);
  };
  const handle_counter_group_delete = (records) => {
    set_delete_modal_visible(true);
    set_selected_items(records);
  };

  const column = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
    },

    {
      title: t("actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      width: "300px",
      align: "center",

      render: (_, record) => (
        <Space
          style={{
            width: "50px",
            justifyContent: "space-between",
          }}
        >
          <EditOutlined
            onClick={() => {
              handle_counter_group_edit(record);
            }}
          />
          <DeleteOutlined
            onClick={() => {
              handle_counter_group_delete(record);
            }}
          />
        </Space>
      ),
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        flexDirection: "column",
      }}
    >
      <Table
        style={{ width: "100%" }}
        columns={column}
        dataSource={counter_group_list}
        loading={counter_group_loading}
        pagination={false}
        // pagination={{
        //   current: current_page,
        //   pageSize: current_page_limit,
        //   total: counter_group_list?.length,
        //   showSizeChanger: true,
        //   onChange: fetch_data,
        //   onShowSizeChange: (current, size) => {
        //     get_all_counter_group(current, size);
        //   },
        // }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Pagination
          total={counter_group_pagination?.total_count}
          showSizeChanger={true}
          current={current}
          hideOnSinglePage={false}
          onChange={fetch_data}
          style={{ marginTop: "10px" }}
        />
      </div>
    </div>
  );
};
export default CounterGroupList;
