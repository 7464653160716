import React, { useEffect } from "react";
import { useDynamicSelector } from "../../../../services/redux";
import DonationTable from "../../receipt/PDF/donation_form_table";
import Denomination from "../../receipt/PDF/denomination";
import EnrolDenomination from "../../receipt/PDF/enrol_denomination";

const EnrolPrintContent = (props) => {
  const { get_closure_details, system_user, current_date_time } = props;

  const {
    total_cash_amount,
    total_card_amount,
    total_dd_amount,
    total_upi_amount,
    total_arrived_count,
    total_amount,
  } = useDynamicSelector("get_closure_summary");

  const { name: current_counter_name } = useDynamicSelector(
    "get_current_counter"
  );

  const table_style = {
    border: "1px solid black",
    border: "collapse",
    width: "100%",
  };

  const cell_style = {
    border: "1px solid black",
  };

  const printStyles = `
      @media print {
        table {
          border-collapse: collapse;
        }
        td, th {
          border: 1px solid black;
          padding: 5px;
        }
      }
    `;

  useEffect(() => {
    get_closure_details();
  }, []);
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <text>
          Counter Name :
          <text style={{ fontWeight: "bold", marginLeft: "10px" }}>
            {current_counter_name}
          </text>
        </text>
        <text>
          Enrolled By :
          <text style={{ fontWeight: "bold", marginLeft: "10px" }}>
            {system_user}
          </text>
        </text>
      </div>
      <style>{printStyles}</style>
      <table style={table_style} className="print-details">
        <thead>
          <tr style={cell_style}>
            <th style={{ border: "transparent" }}>
              <text
                style={{ textDecoration: "underline", marginLeft: "250px" }}
              >
                Omsakthi
              </text>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={cell_style}>
            <td style={{ border: "transparent" }}>
              <text style={{ marginLeft: "250px" }}>
                {new Date().toLocaleDateString()}
              </text>
            </td>
          </tr>
          <tr style={cell_style}>
            <td>
              <text style={{ fontWeight: "bold", marginLeft: "14px" }}>
                NO.OF.IRUMUDI
              </text>
            </td>
            <td style={{ borderRight: "transparent" }}>
              <text style={{ fontWeight: "bold", marginRight: "14px" }}>
                AMOUNT
              </text>
            </td>
          </tr>
          <tr style={cell_style}>
            <td>
              <text style={{ marginLeft: "50px", fontWeight: "bold" }}>
                {total_arrived_count || "-"}
              </text>
            </td>
            <td style={{ borderRight: "transparent", fontWeight: "bold" }}>
              <text>₹ {total_amount || "0"}</text>
            </td>
          </tr>
          <tr style={cell_style}>
            <td style={{ borderRight: "transparent", height: "50px" }}></td>
          </tr>
          <tr style={cell_style}>
            <th style={{ border: "transparent" }}>
              <text
                style={{ marginLeft: "250px", textDecoration: "underline" }}
              >
                Amount Details
              </text>
            </th>
          </tr>
          <tr style={cell_style}>
            <td>
              <text style={{ marginLeft: "15px" }}>CASH</text>
            </td>
            <td
              style={{
                borderRight: "transparent",
                textAlign: "right",
                width: "100px",
              }}
            >
              <text>
                {(total_cash_amount || 0).toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })}
              </text>
            </td>
          </tr>
          <tr style={cell_style}>
            <td>
              <text style={{ marginLeft: "15px" }}>DD</text>
            </td>
            <td
              style={{
                borderRight: "transparent",
                textAlign: "right",
                width: "100px",
              }}
            >
              <text>
                {(total_dd_amount || 0).toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })}
              </text>
            </td>
          </tr>
          <tr style={cell_style}>
            <td>
              <text style={{ marginLeft: "15px" }}>CARD</text>
            </td>
            <td
              style={{
                borderRight: "transparent",
                textAlign: "right",
                width: "100px",
              }}
            >
              <text>
                {(total_card_amount || 0).toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })}
              </text>
            </td>
          </tr>
          <tr style={cell_style}>
            <td>
              <text style={{ marginLeft: "15px" }}>UPI</text>
            </td>
            <td
              style={{
                borderRight: "transparent",
                textAlign: "right",
                width: "100px",
              }}
            >
              <text>
                {(total_upi_amount || 0).toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })}
              </text>
            </td>
          </tr>
          <tr style={cell_style}>
            <td>
              <text
                style={{
                  marginLeft: "14px",
                  fontWeight: "bold",
                  textAlign: "right",
                }}
              >
                TOTAL
              </text>
            </td>
            <td
              style={{
                borderRight: "transparent",
                fontWeight: "bold",
                textAlign: "right",
                width: "100px",
              }}
            >
              <text>
                {(total_amount || 0).toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })}
              </text>
            </td>
          </tr>
          <tr
            style={{
              border: "1px solid black",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <td
              style={{
                borderRight: "transparent",
                height: "40px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <text>
                Generated By {system_user} on {current_date_time}
              </text>
            </td>
          </tr>
        </tbody>
      </table>
      <EnrolDenomination />
    </div>
  );
};

export default EnrolPrintContent;
