import React from "react";
import CustomButton from "../../common/custom_button";
import { MdAddCircleOutline } from "react-icons/md";
import { TbRefresh, TbRefreshOff } from "react-icons/tb";
import colors from "../../../../helpers/colors";
import { useTranslation } from "react-i18next";

const ButtonGroup = (props) => {
  const {
    enable_reorder,
    cancel_reorder,
    save_reorder,
    handle_reorder,
    add_notification,
    enable_save,
  } = props;
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
      {enable_reorder && (
        <React.Fragment>
          <CustomButton
            button_name={t("cancel")}
            onclick_button={cancel_reorder}
            bg_color={colors.actionDelete}
          />
          <CustomButton
            disabled={enable_save}
            button_name={t("save")}
            onclick_button={save_reorder}
            bg_color={colors.green3}
          />
        </React.Fragment>
      )}

      <CustomButton
        icon={enable_reorder ? <TbRefreshOff /> : <TbRefresh />}
        button_name={enable_reorder ? t("disable_reorder") : t("reorder")}
        bg_color={enable_reorder ? "#fc033d" : colors.dodgerBlue}
        onclick_button={handle_reorder}
      />

      {!enable_reorder && (
        <CustomButton
          icon={<MdAddCircleOutline />}
          button_name={t("add")}
          onclick_button={add_notification}
        />
      )}
    </div>
  );
};

export default ButtonGroup;
