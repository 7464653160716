import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { countFormat } from "../../../../../helpers/constants";

const SpotBookingAmount = ({ spot_booking_amount }) => {
  const { t } = useTranslation();
  console.log("spot_booking_amount", spot_booking_amount);
  return (
    <div>
      <Typography style={{ marginBottom: "2%" }}>Amount</Typography>
      <Typography style={{ marginTop: "3%" }}>
        {countFormat(spot_booking_amount || 0)}
      </Typography>
    </div>
  );
};

export default SpotBookingAmount;
