import { DatePicker, Form } from "antd";
import moment from "moment";
import React from "react";

const EnrolmentDdDateTime = (props) => {
  const { set_selected_date, payment_mode } = props;
  const on_change = (date, dateString) => {
    set_selected_date(dateString);
  };

  const disabled_date = (current) => {
    return current && current > moment().endOf("day");
  };
  return (
    <Form.Item
      label="DD Date"
      name="dd_date_time"
      rules={
        payment_mode === "dd"
          ? [
              {
                required: true,
                message: "Please Select the Date",
              },
            ]
          : []
      }
    >
      <DatePicker
        format={"DD/MM/YYYY"}
        onChange={on_change}
        disabledDate={disabled_date}
      />
    </Form.Item>
  );
};

export default EnrolmentDdDateTime;
