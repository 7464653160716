import React from "react";
import LoginHeaderBar from "../components/headerbar/login_header";
import VipBookingUserHistoryTable from "../components/vip_booking_history/vip_booking_user_history_table";
import { Typography } from "antd";

const VipBookingHistory = () => {
  return (
    <>
      <LoginHeaderBar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography.Title level={3}>VIP Booking History</Typography.Title>
      </div>
      <VipBookingUserHistoryTable />
    </>
  );
};

export default VipBookingHistory;
