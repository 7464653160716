import { Form, InputNumber } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";

const IrumudiCount = ({ count, set_count, set_amount, form }) => {
  const { items: season_date_slot_list_items, loading: loading } =
    useDynamicSelector("get_season_date_slot_list");
    const booking_type=Form.useWatch("booking_type",form)

    

  const [ticket_price, set_ticket_price] = useState(0);
  const { t } = useTranslation();

  const on_change = (val) => {
    set_count(val);
  };

  useEffect(() => {
    // Set ticket price based on booking type
    if (booking_type === "vip") {
      set_ticket_price(1000);
    } else {
      set_ticket_price(350);
    }

    // Calculate amount based on count and ticket price
    set_amount(count * ticket_price);
  }, [count, booking_type]);

  useEffect(() => {
    form.resetFields(["irumudi_count"]);
    set_amount(0)
  }, [booking_type, form]);

  return (
    <Form.Item
      label={t("irumudi_count")}
      name="irumudi_count"
      rules={[
        {
          validator: (_, value) => {
            if (value < 0) {
              return Promise.reject("Negative numbers are not allowed!");
            } else if (value === 0) {
              return Promise.reject("Zero is not allowed!");
            } else {
              return Promise.resolve();
            }
          },
          required: true,
        },
      ]}
    >
      <InputNumber
        style={{
          width: "100%",
        }}
        placeholder="Enter the irumudi count"
        onChange={on_change}
      />
    </Form.Item>
  );
};

export default IrumudiCount;
