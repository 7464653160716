import React from "react";
import { Button, Card, Col, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import UserCounterWiseDate from "../../user_counter_wise_report_form_items/user_counter_wise_date";
import UserCounterWiseFilter from "../../user_counter_wise_report_form_items/user_counter_wise_counter_filter";
import {
  dynamicClear,
  dynamicRequest,
  query_counter_user_wise_summary,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { user_counter_wise_report } from "../../../../../services/redux/slices/graphql/graphql_user_counter_wise_report";

const UserCounterReportForm = ({ set_selected_date }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const on_finish = (values) => {
    get_user_counter_wise_report(values);
  };

  const get_user_counter_wise_report = ({
    user_id_list,
    date_slot_id_list,
  }) => {
    let key = [{ key: "get_user_counter_wise_summary", loading: true }];
    let query = user_counter_wise_report;
    let variables = {
      filter: {
        user_id_list: user_id_list,
        date_slot_id_list: date_slot_id_list,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const on_change_values = () => {
    dispatch(dynamicClear("get_user_counter_wise_summary"));
  };

  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 15,
      }}
      onFinish={on_finish}
      onValuesChange={on_change_values}
    >
      <Card
        style={{
          width: "96%",
          borderRadius: "5px",
          backgroundColor: "#e7e7e7",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row gutter={24} style={{ height: "30px" }}>
          <Col span={11}>
            <UserCounterWiseDate set_selected_date={set_selected_date} />
          </Col>
          <Col span={10}>
            <UserCounterWiseFilter set_selected_date={set_selected_date} />
          </Col>
          <Col span={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                htmlType="submit"
                style={{
                  backgroundColor: "#014477",
                  color: "white",
                  borderRadius: "3px",
                }}
              >
                {t("get")}
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
export default UserCounterReportForm;
