import { Button, Modal } from "antd";
import { useDynamicSelector } from "../../../../services/redux";
import { IoMdClose } from "react-icons/io";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import CounterUserWisePdfRender from "../pdf/counter_user_wise_pdf";

const CounterUserWiseModal = (props) => {
  const { visible, set_visible, total_amount, report_date } = props;
  const { items: counter_user_wise_items, loading: counter_user_wise_loading } =
    useDynamicSelector("get_counter_user_wise_summary");

  console.log("counter_user_wise_items", counter_user_wise_items);
  return (
    <Modal
      title={"Counter User Wise Report"}
      open={visible}
      destroyOnClose={true}
      onCancel={() => set_visible(false)}
      footer={[
        <Button key="cancel" onClick={() => set_visible(false)}>
          Cancel
        </Button>,
        <PDFDownloadLink
          style={{ textDecoration: "none", marginLeft: "10px" }}
          document={
            <CounterUserWisePdfRender
              counter_user_wise_items={counter_user_wise_items}
              total_amount={total_amount}
              report_date={report_date}
            />
          }
          fileName={`Counter-user-Wise-Report.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : <Button>Download</Button>
          }
        </PDFDownloadLink>,
      ]}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <PDFViewer width={"450px"} height={"400px"}>
        <CounterUserWisePdfRender
          counter_user_wise_items={counter_user_wise_items}
          total_amount={total_amount}
          report_date={report_date}
        />
      </PDFViewer>
    </Modal>
  );
};

export default CounterUserWiseModal;
