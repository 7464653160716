import { Typography, Space, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const LeaderFetchDetails = ({ title, value }) => {
  const { t } = useTranslation();

  return (
    <div>
    <Row size={20} style={{ marginBottom: "10px" }}>
      <Col style={{ width: "100%"}}>
        <Typography style={{fontWeight:"bold"}}>{title}</Typography>
      </Col>
      <Col style={{ width: "100%" }}>
        <Typography style={{ display: "flex", }}>
          {value}
        </Typography>
      </Col>
    </Row>
  </div>
  );
};

export default LeaderFetchDetails;
