import React, { useEffect } from "react";
import { Form, Row } from "antd";
import {
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PosName from "../inputs/pos_name";
import PosTerminal from "../inputs/pos_terminal";
import PosType from "../inputs/pos_type";
import {
  mutation_create_pos,
  mutation_update_pos,
} from "../../../../services/redux/slices/graphql/graphql_pos";
import { getUUID } from "../../../../helpers/functions";

const PosForm = ({ set_edit_modal_visible, get_all, header_type }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { id, name, terminal_id, type } = useDynamicSelector("get_pos");

  const { status: create_pos_status, error: create_pos_error } =
    useDynamicSelector("create_pos");

  const { status: update_pos_status, error: update_pos_error } =
    useDynamicSelector("update_pos");

  const { status: delete_pos_status, error: delete_pos_error } =
    useDynamicSelector("delete_pos");

  const create_pos = (values) => {
    let keys = [{ key: "create_pos", loading: true }];
    let query = mutation_create_pos;
    let variable = {
      data: {
        ...values,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const update_pos = (values) => {
    let keys = [{ key: "update_pos", loading: true }];
    let query = mutation_update_pos;
    let variable = {
      id,
      data: {
        ...values,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const on_finish = (values) => {
    if (header_type === "add_pos") {
      create_pos(values);
    } else {
      update_pos(values);
    }
  };

  useEffect(() => {
    if (create_pos_status === "success") {
      toast.success("POS created");
      dispatch(dynamicClear("create_pos"));
      get_all();
      set_edit_modal_visible(false);
    } else if (create_pos_error?.message) {
      toast.error(create_pos_error?.message);
      dispatch(dynamicClear("create_pos"));
      set_edit_modal_visible(false);
    } else if (update_pos_status === "success") {
      toast.success("POS updated");
      dispatch(dynamicClear("update_pos"));
      get_all();
      set_edit_modal_visible(false);
    } else if (update_pos_error?.message) {
      toast.error(update_pos_error?.message);
      dispatch(dynamicClear("update_pos"));
      set_edit_modal_visible(false);
    }
    if (delete_pos_status === "success") {
      toast.success("POS deleted");
      dispatch(dynamicClear("delete_pos"));
      get_all();
      set_edit_modal_visible(false);
    } else if (delete_pos_error?.message) {
      toast.error(delete_pos_error?.message);
      dispatch(dynamicClear("delete_pos"));
      set_edit_modal_visible(false);
    }
  }, [
    create_pos_status,
    create_pos_error,
    update_pos_status,
    update_pos_error,
    delete_pos_status,
    delete_pos_error,
  ]);

  useEffect(() => {
    if (header_type === "edit_pos") {
      form.setFieldsValue({
        name,
        type,
        terminal_id,
      });
    } else {
      form.resetFields();
    }
  }, [header_type, id]);

  return (
    <Form
      layout="horizontal"
      colon={false}
      labelAlign="left"
      id="pos_form"
      onFinish={on_finish}
      form={form}
      autoComplete="off"
      labelCol={{
        span: 10,
      }}
    >
      <PosName />
      <PosTerminal />
      <PosType />
    </Form>
  );
};

export default PosForm;
