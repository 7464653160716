import { Row, Space } from "antd";
import React from "react";
import DonationTable from "./donation_form_table";
import { useDynamicSelector } from "../../../../services/redux";
import moment from "moment";

const FormContent = () => {
  const { mandram, date_slot, booking_user } = useDynamicSelector(
    "get_booking_receipt"
  );
  return (
    <div
      style={{
        width: "97%",
        padding: "20px",
        margin: "auto",
      }}
    >
      <div
        style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
      >
        <b>Date:</b>
        <span style={{ borderBottom: "2px dotted black", marginLeft: "5px" }}>
          {moment(date_slot?.date)
            .format("DD-MMM-YYYY")
            .toUpperCase()
            .replace(/ /g, "-")}
        </span>
      </div>
      <h4 style={{ marginTop: "8px" }}>
        <b>From : </b>
      </h4>
      <h5 style={{ marginLeft: "20px" }}>{booking_user?.name},</h5>
      <h5 style={{ marginLeft: "20px" }}>{booking_user?.address}</h5>
      <h4>
        <b>To,</b>
        <h5 style={{ width: "350px", marginLeft: "15px" }}>
          The Chairperson
          <br /> M/ S Melmaruvathur Adhiparasakthi Spiritual Movement
          <br /> GST Road,
          <br /> Melmaruvathur,
          <br /> Cheyyur Taluk,
          <br /> Chengalpattu Dist.,
          <br /> Tamilnadu - 603319 <br />
          PAN NO: AACTM0073D
        </h5>
      </h4>
      <h4>
        <b>Dear Sir / Madam ,</b>
      </h4>
      <h4>
        We I hereby enclosed the details of donation towards the corpus to the
        trust.
        <span>
          <b>The details as follows</b>
        </span>
      </h4>
      <Row style={{ margin: "10px 4px" }}>
        <DonationTable />
      </Row>
      <div>
        <h4>Kindly acknowledge and issue receipt.</h4>
        <h4>Thanking You,</h4>
        <h4>Your Faithfully,</h4>
        <Space
          style={{
            width: "370px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ gap: "10px" }}>
            <h4> Donor Name </h4>
            <h4> Donor Signature </h4>
          </div>
          <div>
            <h4
              style={{ borderBottom: "2px dotted black", textAlign: "center" }}
            >
              {booking_user?.name}
            </h4>
            <h4>.......................................................... </h4>
          </div>
        </Space>
        <h4> Note: Copy of the Adhaar / PAN - Attached herewith</h4>
      </div>
    </div>
  );
};

export default FormContent;
