import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _orderBy from "lodash/orderBy";
import {
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../../services/redux";

const BookingReportToDate = (props) => {
  const { set_to_date } = props;
  const dispatch = useDispatch();
  const { items: date_slots } = useDynamicSelector("get_season_date_slot_list");

  useEffect(() => {
    get_date_slot_list();
  }, []);

  const get_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_change = (values, option) => {
    const selected_date = option?.children;
    set_to_date(selected_date);
  };

  return (
    <div>
      <Form.Item
        name="to_date"
        label="To Date"
        rules={[
          {
            required: true,
            message: "Please Select Date",
          },
        ]}
      >
        <Select
          placeholder="Select Date"
          allowClear
          showSearch
          onChange={handle_change}
        >
          {date_slots?.map((option, index) => (
            <Select.Option key={option.id} value={option.id}>
              {option.date_string}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};
export default BookingReportToDate;
