import React from "react";
import { Form, Input } from "antd";

const InputMobile = () => {
  return (
    <>
      <Form.Item
        name="mobile"
        label="Mobile Number"
        rules={[
          {
            required: true,
            message: "Please enter mobile number",
          },
          {
            pattern: /^[0-9]{10}$/,
            message: "Please enter valid mobile number",
          },
        ]}
        style={{ marginBottom: 0 }}
      >
        <Input type="text" placeholder="Mobile Number" allowClear={true} />
      </Form.Item>
    </>
  );
};

export default InputMobile;
