import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import _orderBy from "lodash/orderBy";
import {
  dynamicClear,
  dynamicRequest,
  get_date_slots,
  useDynamicSelector,
} from "../../../../../services/redux";

const CounterPaymentDate = (props) => {
  const { set_selected_date } = props;
  const dispatch = useDispatch();
  const { items: date_slots } = useDynamicSelector(
    "counter_user_wise_report_date_slots"
  );

  useEffect(() => {
    get_date_slot_list();
  }, []);

  const get_date_slot_list = () => {
    let key = [{ key: "counter_user_wise_report_date_slots", loading: true }];
    let query = get_date_slots;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_change = (value, option) => {
    console.log("option", option);
    const selectedDates = option.map((option) => option?.children);
    set_selected_date(selectedDates);
  };

  return (
    <div>
      <Form.Item
        name="date"
        label="Date"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please Select Date",
        //   },
        // ]}
      >
        <Select
          placeholder="All Date"
          allowClear
          className="counter_report_placeholder"
          mode="multiple"
          // showSearch
          onChange={handle_change}
          // filterOption={custom_filter_option}
        >
          {date_slots?.map((option, index) => (
            <Select.Option key={option.id} value={option.id}>
              {option.date_string}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};
export default CounterPaymentDate;
