import { Collapse, Spin } from "antd";
import React, { useEffect } from "react";
import BookingHistoryHeader from "./booking_history_header";
import { useDispatch, useSelector } from "react-redux";
import MandramWiseBookingList from "./mandram_wise_booking_list";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  query_booking_history_list,
  useDynamicSelector,
} from "../../../../services/redux";

const MandramWiseCollapse = (props) => {
  const { date_slot_item } = props;
  const dispatch = useDispatch();
  const { items: get_all_mandrams, loading: get_all_mandrams_loading } =
    useDynamicSelector("get_all_mandrams");
  const { status: cancel_status } = useDynamicSelector("cancel_booking");
  const {
    items: get_mandram_wise_summary,
    loading: mandram_wise_summary_loading,
  } = useDynamicSelector("get_mandram_wise_summary");
  const { key: active_mandram_key } = useDynamicSelector(
    "mandram_wise_active_key"
  );
  const get_booking_list = (mandram_id) => {
    let key = [{ key: "get_booking_list", loading: true }];
    let query = query_booking_history_list;
    let variables = {
      filter: {
        mandram_id: mandram_id,
        date_slot_id: date_slot_item?.date_slot_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handle_change_mandram_wise = (value) => {
    if (value) {
      get_booking_list(value);
    }
    dispatch(dynamicSet("mandram_wise_active_key", { key: value }));
  };
  useEffect(() => {
    if (cancel_status === "success") {
      get_booking_list(active_mandram_key);
      dispatch(dynamicClear("cancel_booking"));
    }
  }, [cancel_status]);

  return (
    <div>
      <Collapse
        accordion={true}
        onChange={handle_change_mandram_wise}
        activeKey={active_mandram_key || []}
      >
        {mandram_wise_summary_loading && get_all_mandrams_loading && (
          <div className="booking_history_loading">
            <Spin />
          </div>
        )}
        {get_mandram_wise_summary?.map((mandram_wise) => {
          let find_mandram = get_all_mandrams?.find(
            (list) => list?.id === mandram_wise?.mandram_id
          );
          let mandram_wise_item = {
            district_name: find_mandram?.name,
            ...mandram_wise?.count,
          };
          return (
            <Collapse.Panel
              header={<BookingHistoryHeader item={mandram_wise_item} />}
              key={mandram_wise?.mandram_id || ""}
              style={{ backgroundColor: "rgb(52, 73, 94)" }}
            >
              <MandramWiseBookingList />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default MandramWiseCollapse;
