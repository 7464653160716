import { DatePicker, Form, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  useDynamicSelector,
} from "../../../../../services/redux";
import _orderBy from "lodash/orderBy";
import { useDispatch } from "react-redux";

const SakthiPeedamToDate = (props) => {
  const { set_to_date } = props;
  const { t } = useTranslation();

  const { items } = useDynamicSelector("sakthi_peedam_date");
  const ordered_date_slots = _orderBy(items, ["date"], ["asc"]);

  const on_change = (value, option) => {
    const selected_to_date = {
      id: value,
      selected_to_date: option?.children,
    };
    set_to_date(selected_to_date?.selected_to_date);
  };

  return (
    <Form.Item
      name="to_date"
      label={t("to_date")}
      rules={[
        {
          required: true,
          message: "Please select to date",
        },
      ]}
    >
      <Select placeholder="Select To Date" allowClear onChange={on_change}>
        {ordered_date_slots?.map((date_option, index) => (
          <Select.Option key={date_option?.date_string} value={date_option?.id}>
            {date_option?.date_string}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SakthiPeedamToDate;
