import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { toUpper, upperCase } from "lodash";
import { useTranslation } from "react-i18next";
import SwamiImage from "@assets/icons/adhiparashathi_image.png";
import { getAmountFormatWithSymbol } from "../../../../helpers/functions";
import { MASM_LOGO } from "../../../../helpers/image_constants";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "white",
    padding: "20px",
  },
  section: {
    display: "flex",
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    display: "table",
    width: "auto",
    borderCollapse: "collapse",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    borderWidth: 1,
    padding: 8,
    flex: 1,
    textAlign: "left",
    fontSize: "10px",
  },
  tableColHeader: {
    borderWidth: 1,
    backgroundColor: "#f0f0f0",
    padding: 8,
    flex: 1,
    textAlign: "center",
    fontSize: "10px",
  },
  table_header: {
    display: "flex",
    margin: "5px",
    fontWeight: "bold",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "10px",
  },
  header_content: {
    fontWeight: "600",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "10px",
  },
  image: {
    width: "30px",
    height: "40px",
  },
  full_header_content: {
    marginTop: "-20px",
    display: "flex",
    alignContent: "center",
    justifyContent: "space-evenly",
  },
  pageNumber: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
  },
  flexContainer: {
    marginTop: "-15px",
  },
  tableRow: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tableCell: {
    borderWidth: 1,
    padding: 8,
    flex: 1,
    textAlign: "left",
    fontSize: 8,
  },
});

const CounterUserWisePdfRender = ({
  counter_user_wise_items,
  total_amount,
  report_date,
  title = "Counter user wise report",
}) => {
  const { t } = useTranslation();
  const calculateColumnTotal = (columnKey) => {
    return counter_user_wise_items
      ? counter_user_wise_items.reduce(
          (sum, record) => sum + (record[columnKey] || 0),
          0
        )
      : 0;
  };

  return (
    <Document fileName={`counter-user-wise-report.pdf`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Image style={styles.image} src={SwamiImage}></Image>

          <View style={styles.flexContainer}>
            <View style={styles.full_header_content}>
              <Text style={styles.header_content}>{t("om_shakthi")}</Text>
              <Text style={styles.header_content}>{t("masm")} </Text>
              <Text style={styles.header_content}>
                {t("adhiparashakthi_siddhar_peedam")}
              </Text>
              <Text style={styles.header_content}>
                Counter User Wise Report
              </Text>
              <Text style={styles.header_content}>{report_date?.date}</Text>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>User</Text>
              <Text style={styles.tableColHeader}>All Enrolment</Text>
              <Text style={styles.tableColHeader}>Devotees</Text>
              <Text style={styles.tableColHeader}>Cash Amount</Text>
              {/* <Text style={styles.tableColHeader}>DD Enrolment</Text> */}
              {/* <Text style={styles.tableColHeader}>DD Amount</Text> */}
              <Text style={styles.tableColHeader}>Card Enrolment</Text>
              <Text style={styles.tableColHeader}>Card Amount</Text>
              <Text style={styles.tableColHeader}>UPI Enrolment</Text>
              <Text style={styles.tableColHeader}>UPI Amount</Text>
              <Text style={styles.tableColHeader}>Total Amount</Text>
            </View>
            {counter_user_wise_items?.map((item, index) => (
              <View
                style={styles.tableRow}
                key={index}
                wrap={index !== 0 ? "wrap" : "nowrap"}
              >
                <Text style={styles.tableCell}> {item?.user_name}</Text>
                <Text style={styles.tableCell}>{item?.total_enrolments}</Text>
                <Text style={styles.tableCell}>{item?.total_devotees}</Text>
                <Text style={styles.tableCell}>{item.cash_amount}</Text>
                {/* <Text style={styles.tableCell}>{item?.dd_enrolments}</Text> */}
                {/* <Text style={styles.tableCell}>{item?.dd_amount}</Text> */}
                <Text style={styles.tableCell}>{item?.card_enrolments}</Text>
                <Text style={styles.tableCell}>{item?.card_amount}</Text>
                <Text style={styles.tableCell}>{item?.upi_enrolments}</Text>
                <Text style={styles.tableCell}>{item?.upi_amount}</Text>
                <Text style={styles.tableCell}>{item?.sum}</Text>
              </View>
            ))}

            {/* Total row */}
            <View style={styles.tableRow}>
              <Text style={styles.tableCell}>TOTAL</Text>
              <Text style={styles.tableCell}>
                {calculateColumnTotal("total_enrolments")}
              </Text>
              <Text style={styles.tableCell}>
                {calculateColumnTotal("total_devotees")}
              </Text>
              <Text style={styles.tableCell}>
                {calculateColumnTotal("cash_amount")}
              </Text>
              {/* <Text style={styles.tableCell}>
                {calculateColumnTotal("dd_enrolments")}
              </Text> */}
              {/* <Text style={styles.tableCell}>
                {calculateColumnTotal("dd_amount")}
              </Text> */}
              <Text style={styles.tableCell}>
                {calculateColumnTotal("card_enrolments")}
              </Text>
              <Text style={styles.tableCell}>
                {calculateColumnTotal("card_amount")}
              </Text>
              <Text style={styles.tableCell}>
                {calculateColumnTotal("upi_enrolments")}
              </Text>
              <Text style={styles.tableCell}>
                {calculateColumnTotal("upi_amount")}
              </Text>
              <Text style={styles.tableCell}>
                {calculateColumnTotal("sum")}
              </Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default CounterUserWisePdfRender;
