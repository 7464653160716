import { Input, Typography } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';

const VipBookingAmount = ({total_ticket_amount}) => {
    const { t } = useTranslation();
    return (
      <div style={{marginTop:"2px",width:"49%"}}>
        <Typography style={{ marginBottom: "2%" }}>Amount</Typography>
        <Input
          style={{ 
            width: "100%",
          }}
          value={total_ticket_amount||""}
        />
      </div>
    );
}

export default VipBookingAmount
