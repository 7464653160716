import React from "react";
import SwamiImage from "@assets/icons/adhiparashathi_image.png";
import { useTranslation } from "react-i18next";

const PrintHeader = (title) => {
  const { t } = useTranslation();
  return (
    <div>
      <img style={{ height: "40px", width: "30px" }} src={SwamiImage}></img>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "-30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h5>{t("om_shakthi")}</h5>
          <h5>{t("masm")} </h5>
          <h5>{t("adhiparashakthi_siddhar_peedam")}</h5>
          {/* <h4>{title}</h4> */}
        </div>
      </div>
    </div>
  );
};

export default PrintHeader;
