import { Button, Modal, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import {
  dynamicClear,
  dynamicRequest,
  mutation_booking_cancel,
  useDynamicSelector,
} from "../../../../services/redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const BookingHistoryCancelBookingModal = (props) => {
  const { open, handle_close, item } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    loading: cancel_loading,
    status: cancel_status,
    error: cancel_error,
  } = useDynamicSelector("cancel_booking");

  const on_finish = (values) => {
    let key = [{ key: "cancel_booking", loading: true }];
    let query = mutation_booking_cancel;
    let variables = {
      id: item?.id,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    if (cancel_status === "success") {
      handle_close();
      toast.success("Booking cancel successfully.");
    } else if (cancel_error) {
      toast.error(cancel_error?.message);
      dispatch(dynamicClear("cancel_booking"));
    }
  }, [cancel_loading, cancel_error]);

  return (
    <div>
      <Modal
        title={t("cancel_booking_header")}
        open={open}
        onCancel={handle_close}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            htmlType="submit"
            onClick={on_finish}
            loading={cancel_loading}
          >
            {t("yes")}
          </Button>,
          <Button
            style={{
              backgroundColor: "#168f92",
              color: "white",
              border: "transparent",
            }}
            onClick={handle_close}
          >
            {t("no")}
          </Button>,
        ]}
      >
        <Typography>{t("cancel_booking")}</Typography>
      </Modal>
    </div>
  );
};

export default BookingHistoryCancelBookingModal;
