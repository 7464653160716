import React, { useEffect } from "react";
import CustomPageTitle from "../../common/custom_page_title";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import BarcodeGenerator from "./barcode_generator";
import ReceiptTable from "./receipt_table";
import Instructions from "./instructions";
import ContentHeaderbar from "./content_headerbar";
import "./print_media.css";
import OfficeUseOnly from "./office_use_only";
import Denomination from "./denomination";
import FormContent from "./form_content";
import { useParams } from "react-router-dom";
import {
  dynamicRequest,
  query_get_booking_receipt,
  useDynamicSelector,
} from "../../../../services/redux";
import { VERIFICATION_BADGE } from "../../../../helpers/image_constants";
import { useDispatch } from "react-redux";
import GroupLeaderDetails from "./group_leader_details";
import LoaderSpinner from "../../common/custom_spinner";

const PrintContent = () => {
  const params = useParams();
  const {
    confirmed_count,
    count,
    waiting_count,
    is_prepaid,
    loading: receipt_loading,
  } = useDynamicSelector("get_booking_receipt");

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const get_booking_receipt = () => {
    let key = [{ key: "get_booking_receipt", loading: true }];
    let query = query_get_booking_receipt;
    let variables = {
      custom: {
        booking_reference: params.receipt_no,
        booking_user_mobile: params.mobile_number,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_booking_receipt();
  }, [params]);
  return receipt_loading === true ? (
    <LoaderSpinner message={"Please wait..."} />
  ) : (
    <>
      <style>
        {`
    @media Print{
      .border{
          margin: "-4px 13px 4px 13px",
      }
      .group-leader{
        margin-top:5px;
      }
    }
    `}
      </style>
      <div style={{ width: "100%", height: "100%", overflowY: "auto" }}>
        <CustomPageTitle title={t("print_receipt")} />
        <Col
          className="print-content"
          xs={20}
          lg={23}
          md={20}
          style={{
            width: "100%",
            borderRadius: "4px",
            margin: "12px auto",
            height: "auto",
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            overflow: "hidden",
          }}
        >
          <ContentHeaderbar />
          <Row
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              width: "100%",
              justifyContent: "flex-end",
              marginTop: "40px",
            }}
          >
            <BarcodeGenerator />
            <Row
              style={{
                marginRight: "8%",
              }}
            >
              <OfficeUseOnly />
            </Row>
          </Row>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "-85px  auto 0 6px",
              padding: "10px",
            }}
          >
            <div style={{ width: "65%" }}>
              <ReceiptTable />
            </div>

            {is_prepaid ? (
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  marginRight: "50px",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    border: "2px dotted grey",
                    padding: "5px",
                    display: "flex",
                    borderRadius: "5px",
                    alignItems: "center",
                  }}
                >
                  <img style={{ width: "40px" }} src={VERIFICATION_BADGE} />
                  <h2 style={{ margin: "0 5px" }}>
                    <b>PAID </b>
                  </h2>
                </div>
              </div>
            ) : (
              <Denomination />
            )}
          </div>
          <h4
            className="border"
            style={{
              borderBottom: "2px dashed black",
              margin: "0px 13px 4px 13px",
            }}
          ></h4>
          <div className="group-leader" style={{ marginTop: "8px" }}>
            <GroupLeaderDetails />
          </div>
          <Row
            style={{
              justifyContent: "center",
              marginLeft: "-6px",
              marginTop: "10px",
            }}
          >
            <Instructions />
            {confirmed_count + waiting_count > 59 && (
              <div
                style={{ margin: "80px 0px", width: "100%", padding: "2px" }}
              >
                <FormContent />
              </div>
            )}
          </Row>
        </Col>
      </div>
    </>
  );
};

export default PrintContent;
