import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
const RoleItem = (props) => {
  const dispatch = useDispatch();
  const [on_hover, setOnHover] = useState(false);
  const { item, index } = props;

  return (
    <div
      key={index}
      // bg="red.200"
      className="VStack"
      // style={{ minWidth: 200, maxWidth: 600 }}
    >
      <>{item.name}</>
    </div>
  );
};
export default RoleItem;
