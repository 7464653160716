import React from "react";
import { Button, Card, Col, Form, Row } from "antd";
import CounterFilter from "../counter_payment_report_form_items/counter_filter";
import CounterPaymentDate from "../counter_payment_report_form_items/counter_payment_date";
import { useTranslation } from "react-i18next";
import { dynamicClear } from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const CounterPaymentReportForm = (props) => {
  const {
    handle_search,
    set_selected_date,
    set_selected_counter_id,
    selected_counter_id,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const on_change_values = () => {
    dispatch(dynamicClear("get_counter_user_wise_summary"));
  };

  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 15,
      }}
      onFinish={handle_search}
      onValuesChange={on_change_values}
    >
      <Card
        style={{
          width: "96%",
          borderRadius: "5px",
          backgroundColor: "#e7e7e7",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row gutter={24} style={{ height: "30px" }}>
          <Col span={11}>
            <CounterPaymentDate set_selected_date={set_selected_date} />
          </Col>
          <Col span={10}>
            <CounterFilter
              set_selected_counter_id={set_selected_counter_id}
              selected_counter_id={selected_counter_id}
            />
          </Col>
          <Col span={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                htmlType="submit"
                style={{
                  backgroundColor: "#014477",
                  color: "white",
                  borderRadius: "3px",
                }}
              >
                {t("get")}
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};
export default CounterPaymentReportForm;
