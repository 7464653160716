import gql from "graphql-tag";

export const get_date_wise_report = gql`
  query get_date_district_mandram_wise_summary(
    $filter: date_district_mandram_wise_summary_filter_input
  ) {
    get_date_wise_report_summary: get_date_district_mandram_wise_summary(
      filter: $filter
    ) {
      items {
        date
        received_amount
        date_slot_id
        district_id
        date_slot_id
        prepaid_amount
        mandram_id
        count {
          booked
          cancelled
          arrived
          excess
          enrolled
          yet_to_enroll
          prepaid
        }
        status
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const get_district = gql`
  query get_district_list(
    $filter: get_district_list_filter_input
    $sort: sort_input
  ) {
    get_date_wise_district: get_district_list(filter: $filter, sort: $sort) {
      items {
        name
        id
      }
    }
  }
`;

export const get_mandram_wise_report = gql`
  query get_mandram_wise_summary($filter: mandram_wise_summary_filter_input) {
    get_mandram_wise_report_summary: get_mandram_wise_summary(filter: $filter) {
      items {
        booked_count
        date
        arrived_count
        cancelled_count
        excess_count
        received_amount
        enrolled_count
        date_slot_id
        district_id
        mandram_id
        prepaid_amount
        yet_to_enroll_count
        status
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const get_enrolment_query = gql`
  query get_enrolment_list(
    $filter: get_enrolment_list_filter_input
    $pagination: pagination_input
  ) {
    get_enrolment_list(filter: $filter, pagination: $pagination) {
      items {
        id
        booking_id
        counter_id
        enrolled_on
        arrived_count
        is_tickets_printed
        reprintable_ticket_list {
          ticket_number
        }
        status
        booking {
          booking_status
          date_slot_id
          time_slot_id
          price
          count
          confirmed_count
          waiting_count
          booked_on
          booked_by {
            password
            name
          }
          cancelled_count
          mandram_id
          payment {
            payment_mode
            paid_on
          }
          mandram {
            code
          }
          booking_status
          booking_reference
          date_slot {
            date
          }
        }
        counter {
          id
          name
          code
          system_reference_id
          printer_reference_id

          is_active
          counter_group_id
        }
        enrolled_by {
          password
          name
          mobile
          email
        }
        payment {
          amount
          payment_mode
          paid_on
          received_by {
            password
            name
            mobile
            email
          }
        }
        reprintable_ticket_list {
          ticket_number
        }
      }
      error {
        status_code
        message
      }
    }
  }
`;
