import { gql } from "@apollo/client";

export const query_date_wise_report_summary = gql`
  query get_date_wise_summary($filter: date_wise_summary_filter_input) {
    get_date_wise_summary(filter: $filter) {
      items {
        booking_reference
        district_name
        mandram_name
        mandram_code
        booking_status
        payment_mode
        status
        leader_name
        leader_mobile
        booked_by_name
        abishekam_date
        booking_count
        enrolled_count
        not_enrolled_count
        excess_count
      }
    }
  }
`;

export const query_date_wise_report_count = gql`
  query get_date_wise_summary_count(
    $filter: date_wise_summary_count_filter_input
  ) {
    get_date_wise_summary_count(filter: $filter) {
      items {
        abishekam_date
        district_name
        mandram_name
        mandram_code
        mandram_total_count
        mandram_total_enrolled
        mandram_total_not_enrolled
      }
    }
  }
`;
