import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SpotBookingInchargeName = () => {
  const { t } = useTranslation();
  return (
    <Form.Item label={t("incharge_name")} name="incharge_name">
      <Input
        style={{
          width: "100%",
          border: "none",
        }}
        disabled
      />
    </Form.Item>
  );
};

export default SpotBookingInchargeName;
