import React from "react";
import ExcelImport from "../components/excel_upload/excel_import";

const MandramsExcelImport = () => {
  return (
    <div className="App">
      <h1>Excel Import</h1>
      <ExcelImport />
    </div>
  );
};

export default MandramsExcelImport;
