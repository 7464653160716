import { Button, Modal } from "antd";
import React from "react";
import { IoMdClose } from "react-icons/io";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useDynamicSelector } from "../../../../../services/redux";
import DayWisePdfRender from "../day_wise_pdf_render/day_wise_pdf_render";

const DayWiseReportModal = (props) => {
  const { visible, set_visible, selected_dates } = props;

  const { items: day_wise_report_items } = useDynamicSelector(
    "get_daywise_summary"
  );

  return (
    <Modal
      title={"Daily Transaction Report"}
      open={visible}
      width={800}
      destroyOnClose={true}
      onCancel={() => set_visible(false)}
      footer={[
        <Button key="cancel" onClick={() => set_visible(false)}>
          Cancel
        </Button>,
        <PDFDownloadLink
          style={{ textDecoration: "none", marginLeft: "10px" }}
          document={
            <DayWisePdfRender day_wise_report_items={day_wise_report_items} />
          }
          fileName={`Day-Wise-Report.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : <Button>Download</Button>
          }
        </PDFDownloadLink>,
      ]}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PDFViewer width={"770px"} height={"400px"}>
          <DayWisePdfRender day_wise_report_items={day_wise_report_items} />
        </PDFViewer>
      </div>
    </Modal>
  );
};

export default DayWiseReportModal;
