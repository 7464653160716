import { Button, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import _orderBy from "lodash/orderBy";
import {
  dynamicRequest,
  mutation_delete_masm_user,
  useDynamicSelector,
} from "../../../../services/redux";
import CustomDeleteModal from "../../common/custom_delete_modal";
import { useTranslation } from "react-i18next";
import UsersGroupModal from "./modal/user_add_edit_group_modal";
import { get_user_groups_list } from "../../../../services/redux/slices/graphql/graphql_users_group";
import { useParams } from "react-router-dom";

const UsersTable = (props) => {
  const { user_group_modal_visible, set_user_group_modal_visible } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handle_edit, show_delete_model, id } = props;
  const { date_slots, loading } = useDynamicSelector("get_season");
  const [delete_modal_visible, set_delete_modal_visible] = useState(false);
  const [delete_record, set_delete_record] = useState({});
  const [header_type, set_header_type] = useState("");

  const { items: items } = useDynamicSelector("get_user_group_user_list");

  const params = useParams();
  console.log("delete_record", delete_record);

  const get_all_users_group = () => {
    let key = [{ key: "get_user_group_user_list", loading: true }];
    let query = get_user_groups_list;
    let variables = {
      filter: {
        group_id: params?.id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_delete_group_user = (record) => {
    set_delete_record(record);
    set_delete_modal_visible(true);
  };

  const handle_edit_group_user = (record) => {
    set_header_type("edit");
    set_user_group_modal_visible(true);
  };

  const delete_masm_user = () => {
    let keys = [{ key: "delete_masm_user", loading: true }];
    let query = mutation_delete_masm_user;
    let variable = {
      id: delete_record.system_user?.id,
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    get_all_users_group();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record, row_index) => (
        <Space
          style={{
            width: "50px",
            justifyContent: "space-between",
          }}
        >
          <EditOutlined
            style={{ cursor: "pointer" }}
            onClick={() => handle_edit_group_user(record)}
          />
          <DeleteOutlined onClick={() => handle_delete_group_user(record)} />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={items} loading={loading}></Table>

      <UsersGroupModal
        header_type={header_type}
        set_header_type={set_header_type}
        user_group_modal_visible={user_group_modal_visible}
        set_user_group_modal_visible={set_user_group_modal_visible}
      />

      <CustomDeleteModal
        selector_name="delete_masm_user"
        get_all={get_all_users_group}
        delete_api={delete_masm_user}
        record={delete_record}
        text={t("district")}
        delete_modal_visible={delete_modal_visible}
        set_delete_modal_visible={set_delete_modal_visible}
      />
    </div>
  );
};

export default UsersTable;
