import { Card } from "antd";
import React, { useEffect, useState } from "react";
import BillingHistoryCountTable from "../components/billing_history/billing_history_count_table";
import CustomPageTitle from "../components/common/custom_page_title";
import BillingHistoryTable from "../components/billing_history/billing_history_table/billing_history_table";
import { query_get_enrolment_details } from "../../services/redux/slices/graphql/graphql_billing_history";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "../../services/redux";
import { useTranslation } from "react-i18next";
import CustomDateSearch from "../components/common/custom_date_search";

const BillingHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected_date, set_selected_date] = useState({});

  const [current_page_limit, set_current_page_limit] = useState(10);
  const [current_page, set_current_page] = useState(1);

  const get_all_enrollment_list = (field, value) => {
    let keys = [{ key: "get_enrolment_list", loading: true }];
    let query = query_get_enrolment_details;

    let filter = {
      from_date: selected_date?.from_date,
      to_date: selected_date?.to_date,
      search: {
        field: field || "",
        value: value || "",
      },
    };

    let variables = {
      filter,
      pagination: {
        page_number: current_page || 1,
        page_limit: current_page_limit || 10,
      },
    };
    dispatch(dynamicRequest(keys, query, variables));
  };

  useEffect(() => {
    if (selected_date?.from_date !== "" && selected_date?.to_date !== "") {
      // get_all_enrollment_list();
    }
  }, [selected_date]);

  return (
    <div>
      <CustomPageTitle title={"Billing History"} />
      <Card
        style={{
          width: "96%",
          margin: " 10px auto",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
        }}
      >
        {/* <BillingHistoryCountTable selected_date={selected_date} /> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CustomDateSearch
            selected_date={selected_date}
            set_selected_date={set_selected_date}
            get_list={get_all_enrollment_list}
          />
        </div>
        <BillingHistoryTable
          get_all_enrollment_list={get_all_enrollment_list}
          selected_date={selected_date}
          set_current_page_limit={set_current_page_limit}
          set_current_page={set_current_page}
          current_page={current_page}
          current_page_limit={current_page_limit}
        />
      </Card>
    </div>
  );
};

export default BillingHistory;
