import { gql } from "@apollo/client";
export const query_calendar_dashboard = gql`
  query get_dashboard_calendar {
    get_dashboard_calendar {
      items {
        mandram_name
        date
        date_slot {
          id
          date
          availability_color
          date_string
        }
        confirmed_quota
        waiting_quota
        count {
          booked
          cancelled
          arrived
          excess
          yet_to_enroll
          enrolled
        }
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const query_get_date_slot_list = gql`
  query get_season_date_slot_list {
    get_season_date_slot_list {
      items {
        availability_color
        date
      }
    }
  }
`;
