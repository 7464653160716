import { Form, Select, message } from "antd";
import React, { useEffect } from "react";
import {
  dynamicRequest,
  select_bank,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const SelectBank = (props) => {
  const { set_selected_bank, payment_mode } = props;
  const dispatch = useDispatch();
  const { items: bank_items } = useDynamicSelector("bank_list");

  const get_bank_list_details = () => {
    let key = [{ key: "bank_list", loading: true }];
    let query = select_bank;
    dispatch(dynamicRequest(key, query));
  };

  const on_change = (values) => {
    set_selected_bank(values);
  };

  useEffect(() => {
    get_bank_list_details();
  }, []);

  return (
    <Form.Item
      label={<span style={{ fontWeight: "bold", fontSize: "16px" }}>Bank</span>}
      name="bank"
      labelCol={{ span: 24 }}
      rules={
        payment_mode === "dd"
          ? [
              {
                required: true,
                message: "Please select a mode of payment!",
              },
            ]
          : []
      }
    >
      <Select
        style={{ width: "100%", border: "0px solid black" }}
        allowClear
        onChange={on_change}
      >
        {bank_items?.map((option) => (
          <Select.Option key={option.id} value={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SelectBank;
