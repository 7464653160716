import { gql } from "@apollo/client";

export const get_report_date_slots = gql`
  query get_season_date_slot_list($sort: sort_input) {
    date_wise_report_abishekam_date_list: get_season_date_slot_list(
      sort: $sort
    ) {
      items {
        id
        date_string
      }
    }
  }
`;

export const get_district_list = gql`
  query get_district_list($sort: sort_input) {
    date_wise_report_district_list: get_district_list(sort: $sort) {
      items {
        id
        name
      }
    }
  }
`;

export const get_mandram_list = gql`
  query get_mandram_list(
    $filter: get_mandram_list_filter_input
    $sort: sort_input
  ) {
    date_wise_report_mandram_list: get_mandram_list(
      filter: $filter
      sort: $sort
    ) {
      items {
        id
        name
        code
      }
    }
  }
`;
