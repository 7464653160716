import { Button, Card, Col, Form, Row, Space } from "antd";
import React, { useRef, useState } from "react";
import DayWiseReport from "./day_wise_report_select_date_form_item";
import { useTranslation } from "react-i18next";
import DayWiseReportList from "../day_wise_report_list/day_wise_report_list";
import {
  dynamicRequest,
  get_day_wise_report,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import DayWiseReportModal from "../day_wise_report_modal/day_wise_report_modal";
import {
  arrange_xlsx_report_data,
  export_to_xlsx,
} from "../../../../../helpers/functions";
import CustomButton from "../../../common/custom_button";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const DayWiseReportForm = (props) => {
  const componentRef = useRef();

  const dispatch = useDispatch();
  const [show_print_button, set_show_print_button] = useState(false);
  const [modal_visible, set_modal_visible] = useState(false);
  const [selected_date, set_selected_date] = useState({});
  const { items: day_wise_report_items } = useDynamicSelector(
    "get_daywise_summary"
  );

  const { t } = useTranslation();

  const day_wise_report_list = (values) => {
    let key = [{ key: "get_daywise_summary", loading: true }];
    let query = get_day_wise_report;
    let variables = {
      filter: {
        from_date: values?.from_date,
        to_date: values?.to_date,
      },
    };
    console.log("variables9", variables);
    dispatch(dynamicRequest(key, query, variables));
  };
  const handle_finish = (values) => {
    day_wise_report_list(values);
  };

  const handle_print = useReactToPrint({
    content: () => componentRef.current,
  });

  const print_day_wise_report = () => {
    handle_print();
  };

  const arrange_xlsx_day_wise_report_data = (data_items) => {
    const rearranged_data = [];
    console.log("data_items", data_items);

    if (data_items && data_items.length) {
      for (let i = 0; i < data_items.length; i++) {
        let row_data = {
          ["S No"]: i + 1,
          ["Date"]: moment(data_items[i].date).format("DD/MM/YYYY"),
          ["Day"]: moment(data_items[i].date).format("dddd"),
          ["No of irumudi"]: data_items[i].irumudi_count,
        };
        rearranged_data.push(row_data);
      }
    }

    return rearranged_data;
  };

  const on_xlsx_download = () => {
    const arranged_data = arrange_xlsx_day_wise_report_data(
      day_wise_report_items
    );
    export_to_xlsx(arranged_data, "day_wise_report");
  };

  return (
    <>
      <div>
        <Card
          style={{
            width: "96%",
            borderRadius: "5px",
            backgroundColor: "#e7e7e7",
            margin: "auto",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Form id="search" onFinish={handle_finish} style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <DayWiseReport
                set_selected_date={set_selected_date}
                name="from_date"
              />
              <DayWiseReport
                set_selected_date={set_selected_date}
                name="to_date"
              />
              <Button
                style={{
                  backgroundColor: "#014477",
                  color: "white",
                  borderRadius: "5px",
                }}
                htmlType="submit"
                form="search"
              >
                {t("get")}
              </Button>
            </div>
          </Form>
        </Card>
      </div>

      {day_wise_report_items?.length > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "96%",
            marginTop: "10px",
            alignItems: "flex-end",
          }}
        >
          <Space>
            <Button
              style={{
                backgroundColor: "#1e2c55",
                color: "white",
                borderRadius: "5px",
              }}
              onClick={print_day_wise_report}
              disabled={day_wise_report_items?.length > 0 ? false : true}
            >
              {t("print")}
            </Button>
            <CustomButton
              button_name={"Download"}
              bg_color={"#185c37"}
              rounded={true}
              onclick_button={on_xlsx_download}
              disabled={day_wise_report_items?.length > 0 ? false : true}
            />
          </Space>
        </div>
      ) : null}

      {day_wise_report_items?.length > 0 ? (
        <div
          ref={componentRef}
          style={{
            width: "96%",
            marginTop: "30px",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <DayWiseReportList selected_date={selected_date} />
        </div>
      ) : null}

      <DayWiseReportModal
        visible={modal_visible}
        set_visible={set_modal_visible}
      />
    </>
  );
};

export default DayWiseReportForm;
