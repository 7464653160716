import React, { useEffect, useState } from "react";
import * as Icon from "react-icons/fi";
import { Spin } from "antd";
import {
  TbSquareRoundedCheck,
  TbSquareRoundedCheckFilled,
} from "react-icons/tb";

const UiCheckBoxComponent = (props) => {
  const { loading, is_checked, onChange, key } = props;

  const onClick = (e) => {
    if (!loading && onChange) {
      onChange(!is_checked);
    }
  };

  const CheckedStatus = () => {
    return (
      <>
        {!is_checked ? (
          <TbSquareRoundedCheck color="Black" size={20} />
        ) : (
          <TbSquareRoundedCheckFilled color="Black" size={20} />
        )}
      </>
    );
  };
  return (
    <div
      style={{
        // marginLeft: "27px",
        width: "20px",
        height: "20px",
        cursor: loading ? "default" : "pointer",
        borderRadius: loading ? "0" : "20px",
      }}
      onClick={onClick}
      key={key}
    >
      {loading && <Spin size="small" />}
      {!loading && <CheckedStatus />}
    </div>
  );
};
export default UiCheckBoxComponent;
