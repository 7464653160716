import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SpotBookingAddress = () => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  return (
    <div>
      <Form.Item label={t("address")} name="address">
        <TextArea
          rows={4}
          style={{
           width:"100%"
          }}
        />
      </Form.Item>
    </div>
  );
};

export default SpotBookingAddress;
