import { showToast } from "@helpers/functions";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../services/redux";
import {
  introspection_query,
  sync_system_api_modules_mutation,
} from "../../../../services/redux/slices/graphql/graphql_access_rights";
import { Button, Col } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const SyncEndPoints = () => {
  const dispatch = useDispatch();
  const { loading, types } = useDynamicSelector("schema");
  const { loading: sync_loading, status } = useDynamicSelector(
    "sync_system_api_modules"
  );

  useEffect(() => {
    if (types) {
      let keys = [{ key: "sync_system_api_modules", loading: true }];
      let queries = types
        .find((x) => x.name === "Query")
        .fields.map((x) => x.name);
      let mutations = types
        .find((x) => x.name === "Mutation")
        .fields.map((x) => x.name);
      let api_names = [...queries, ...mutations];
      let variables = {
        custom: { api_names },
      };
      dispatch(
        dynamicRequest(keys, sync_system_api_modules_mutation, variables, "M")
      );
    }
  }, [types]);

  useEffect(() => {
    if (status) {
      if (status === "success") {
        showToast({
          type: "success",
          message: `Endpoints synced successfully`,
        });
        dispatch(dynamicClear("sync_system_api_modules"));
      }
    }
  }, [status]);

  const loadEndpoints = () => {
    let keys = [{ key: "sync_system_api_modules", loading: true }];
    let variables = { custom: { api_names: types } };
    dispatch(dynamicRequest(keys, sync_system_api_modules_mutation, variables));
  };

  const handleSyncEndpoints = () => {
    let keys = [{ key: "schema", loading: true }];
    let variables = null;
    dispatch(dynamicRequest(keys, introspection_query, variables));
  };

  return (
    <div>
      <Col span={10}>
        <Button
          onClick={handleSyncEndpoints}
          className="end_points_color"
          loading={loading || sync_loading}
        >
          Sync EndPoints
        </Button>
      </Col>
    </div>
  );
};
export default SyncEndPoints;
