import React from "react";
import { Col, Row, Space } from "antd";
import LeaderFetchDetails from "./booking_leader_fetch_name";
import { useTranslation } from "react-i18next";
import { storeItem } from "../../../../helpers/functions";

const BookingLeaderDetailsFetch = ({ item }) => {
  const { t } = useTranslation();
  storeItem("leader_details", item?.mobile);

  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <LeaderFetchDetails
            title={t("group_leader_name")}
            value={item?.name}
          />
        </Col>
        <Col span={12}>
          <LeaderFetchDetails title={t("mobile")} value={item?.mobile} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <LeaderFetchDetails title={t("id_proof")} value={item?.id_type} />
        </Col>
        <Col span={12}>
          <LeaderFetchDetails title={t("id_number")} value={item?.id_number} />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <LeaderFetchDetails title={t("address")} value={item.address} />
        </Col>
      </Row>
    </div>
  );
};

export default BookingLeaderDetailsFetch;
