import { gql } from "@apollo/client";

export const query_get_all_district_user_list = gql`
  query get_district_user_list($filter: get_district_user_list_filter_input) {
    get_all_district_user: get_district_user_list(filter: $filter) {
      items {
        name
        mobile
        email
        password
        system_user {
          id
          username
          name
          email
          mobile
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_create_district_user = gql`
  mutation create_district_user($data: create_district_user_input) {
    create_district_user(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_all_mandram_list = gql`
  query get_mandram_list(
    $filter: get_mandram_list_filter_input
    $sort: sort_input
  ) {
    get_mandrams: get_mandram_list(filter: $filter, sort: $sort) {
      items {
        id
        name
        code
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_set_accessible_mandram_list = gql`
  mutation set_accessible_mandram_list($custom: set_mandram_list_input) {
    set_accessible_mandram_list(custom: $custom) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_all_district_list = gql`
  query {
    get_district_details: get_district_list {
      items {
        name
        id
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_accessible_mandram_list = gql`
  query get_accessible_mandram_list($id: String!) {
    get_accessible_mandram_list(id: $id) {
      items {
        id
        name
        is_active
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_set_accessible_mandram_list_for_sub_district = gql`
  mutation set_accessible_mandram_list_for_sub_district(
    $custom: set_mandram_list_for_sub_district_input
  ) {
    set_accessible_mandram_list_for_sub_district(custom: $custom) {
      id
      status
      error {
        message
      }
    }
  }
`;
