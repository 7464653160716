import { Form, Input } from "antd";
import React from "react";

const InputSubDistrictAdminUserName = ({ header_type }) => {
  return (
    <>
      <Form.Item
        name={["district_admin", "system_user", "username"]}
        label="Username"
        rules={[
          {
            required: true,
            message: "Please enter username",
          },
        ]}
      >
        <Input
          disabled={header_type === "edit_district" ? true : false}
          placeholder="Username"
          allowClear={true}
          autoComplete="off"
          aria-autocomplete="none"
        />
      </Form.Item>
    </>
  );
};

export default InputSubDistrictAdminUserName;
