import React from "react";
import CustomText from "../../common/custom_text";
import { useTranslation } from "react-i18next";

const Instructions = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: "100%",
        margin: "1px 15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        borderRadius: "8px",
        gap: "4px",
      }}
    >
      <CustomText title={t("instructions")} bold={true} font_size={"17px"} />
      <CustomText
        bold={true}
        title={"சித்தர் பீடத்திற்கு வரும் பக்தர்கள் கடைபிடிக்க வேண்டியவை"}
      />

      <CustomText title={""} />
      <CustomText title={t("parking")} />
      <CustomText
        title={t(
          "தாங்கள் வரும் வாகனங்களை தேசிய நெடுஞ்சாலையில் நிறுத்தக்கூடாது. சித்தர் பீடம் வாகன நிறுத்துமிடங்களில் மட்டுமே நிறுத்த வேண்டும்."
        )}
      />
      <CustomText title={t("group_leader")} />
      <CustomText
        title={
          "மன்றம் பதிவு சரி பார்க்கும் இடத்தில் குரூப் லீடர் தனது ஏதேனும் ஒரு ஐடி ப்ரூப் ஜெராக்ஸ் அவசியம் கொடுக்க வேண்டும்."
        }
      />
      <CustomText title={t("print_out")} />
      <CustomText
        title={
          "இந்த பதிவு சான்றை கட்டாயம் கொண்டு வர வேண்டும் இந்த பதிவு சான்று தொலைந்து போனாலோ அல்லது கிழிந்து போனாலோ அனுமதி அளிக்கப்படாது."
        }
      />
      <CustomText title={t("irumudi_token")} />
      <CustomText
        title={
          "இருமுடி அனுமதிச்சீட்டு வழங்கும் பிரிவு தினசரி காலை 3 மணி முதல் மாலை 6 மணி வரை செயல்படும் இரவு 8 மணிக்கு கருவறை மூடப்படும்."
        }
      />
    </div>
  );
};

export default Instructions;
