import { Space, Typography } from "antd";
import { upperCase } from "lodash";
import React from "react";

const { Title } = Typography;
const CustomPageTitle = ({ title }) => {
  return (
    <Space
      style={{
        width: "100%",
        overflowY: "auto",
        maxHeight: "100vh",
      }}
    >
      <Title
        style={{
          fontSize: "20px",
          fontWeight: "600",
          margin: "10px",
        }}
      >
        {upperCase(title)}
      </Title>
    </Space>
  );
};

export default CustomPageTitle;
