import { Button, Form, Modal } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import InputSMS from "./inputs/sms_input";

const CustomSMSModal = (props) => {
  const [form] = Form.useForm();
  const { modal_visible, set_modal_visible, sms_api, loading, sms_booking_id } =
    props;
  const { t } = useTranslation();

  const handle_send_api = (values) => {
    sms_api(values?.mobile, sms_booking_id);
  };

  const handleClose = () => {
    form.resetFields();
    set_modal_visible(false);
  };
  return (
    <Modal
      title={"Send SMS"}
      open={modal_visible}
      destroyOnClose={true}
      onCancel={handleClose}
      footer={[
        <Button onClick={handleClose}>{t("cancel")}</Button>,
        <Button
          loading={loading}
          style={{ backgroundColor: "dodgerBlue", color: "white" }}
          htmlType="submit"
          form="sms_form"
        >
          {t("send")}
        </Button>,
      ]}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <Form onFinish={handle_send_api} form={form} id="sms_form">
        <InputSMS />
      </Form>
    </Modal>
  );
};
export default CustomSMSModal;
