import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  dynamicClear,
  dynamicRequest,
  query_get_counter_list,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const CounterFilter = (props) => {
  const { set_selected_counter_id, selected_counter_id } = props;
  const dispatch = useDispatch();

  const { items: counter_list } = useDynamicSelector("get_all_counter_list");

  useEffect(() => {
    handle_get_counter_list();
  }, []);

  console.log("counter_list", counter_list);

  const handle_get_counter_list = () => {
    let key = [{ key: "get_all_counter_list", loading: true }];
    let query = query_get_counter_list;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_change = (values) => {
    set_selected_counter_id(values);
    console.log("handle_change", values);
  };

  return (
    <Form.Item name="counter" label="Counter">
      <Select
        className="counter_report_placeholder"
        placeholder="All Counter"
        allowClear
        mode="multiple"
        onChange={handle_change}
        value={selected_counter_id}
      >
        {counter_list?.map((counter, index) => (
          <Select.Option key={counter?.id} value={counter?.id}>
            {counter.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
export default CounterFilter;
