import { Col, Typography } from "antd";
import React from "react";

const ReceiptDetailsCard = (props) => {
  const { title, sub_title, sub_value, symbol, amount, currency } = props;
  return (
    <Col>
      <Typography style={{ fontSize: "15px" }}>{title}</Typography>
      <div>
        <Typography
          style={{
            fontWeight: "bold",
          }}
        >
          <div style={{ display: "flex" }}>
            <div>{symbol}</div>
            <div> {sub_title}</div>
            <div style={{ fontSize: "20px" }}>{currency}</div>
            <div style={{ fontSize: "20px" }}>
              {amount ? amount.toLocaleString("en-IN") : ""}
            </div>
          </div>
          <text style={{ fontWeight: "bold", marginLeft: "3px" }}>
            {sub_value}
          </text>
        </Typography>
      </div>
    </Col>
  );
};

export default ReceiptDetailsCard;
