import { Col, Form, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import SystemUserNameInput from "../masm_user_form_items/masm_user_name_input";
import SystemUserUsernameInput from "../masm_user_form_items/masm_user_username_input";
import SystemUserRole from "../masm_user_form_items/masm_user_role";
import MasmUserEmail from "../masm_user_form_items/masm_user_email";
import MasmUserMobile from "../masm_user_form_items/masm_user_mobile_number";
import {
  dynamicRequest,
  mutation_create_masm_user,
  mutation_update_masm_user,
} from "../../../../services/redux/slices";
import { useDispatch } from "react-redux";
import SystemUserPasswordInput from "../masm_user_form_items/masm_user_password_input";
import SystemUserGroup from "../masm_user_form_items/masm_user_group";

const SystemUserForm = (props) => {
  const { selected_items, modal_title, role_type } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handle_masm_user_submit = (user_details) => {
    console.log("user_details", user_details);
    if (modal_title === "add") {
      create_one_masm_user(user_details);
    } else {
      update_one_masm_user(user_details);
    }
  };

  // useEffect(() => {
  //   if (selected_items?.system_user?.id) {
  //     form.setFieldsValue({
  //       mobile: selected_items.mobile,
  //       name: selected_items.name,
  //       email: selected_items.email,
  //       type: selected_items.type,
  //       system_user: {
  //         username: selected_items.system_user.username,
  //       },
  //     });
  //   } else {
  //     form.setFieldsValue({
  //       mobile: "",
  //     });
  //   }
  // }, [selected_items]);

  const create_one_masm_user = (values) => {
    console.log("valuesddd", values);
    let key = [{ key: "create_masm_user", loading: true }];
    let query = mutation_create_masm_user;
    let variables = {
      data: {
        name: values?.name,
        mobile: values?.mobile,
        email: values?.email,
        type: role_type,
        group_id: values?.group_id,
        system_user: {
          username: values?.username,
          password: values?.password,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const update_one_masm_user = (values) => {
    let key = [{ key: "update_masm_user", loading: true }];
    let query = mutation_update_masm_user;
    let variables = {
      id: selected_items.id,
      data: {
        name: values?.name,
        mobile: values?.mobile,
        email: values?.email,
        group_id: values?.group_id,
        system_user: { username: values?.username, password: values.password },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <div>
      <Form
        id="add_system_user_form"
        form={form}
        labelCol={{
          span: 10,
        }}
        onFinish={handle_masm_user_submit}
        initialValues={selected_items}
      >
        <Row gutter={24}>
          <Col span={12}>
            <SystemUserNameInput />
          </Col>
          <Col span={12}>
            <MasmUserMobile />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <SystemUserUsernameInput modal_title={modal_title} />
          </Col>
          <Col span={12}>
            {/* <SystemUserRole modal_title={modal_title} /> */}
            <SystemUserPasswordInput modal_title={modal_title} />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <MasmUserEmail modal_title={modal_title} />
          </Col>
          <Col span={12}>
            <SystemUserGroup modal_title={modal_title} />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default SystemUserForm;
