import { Button, Modal } from "antd";
import React from "react";
import BookingAvailabilityForm from "../../booking_availability_form/booking_availability_main_form";
import { IoMdClose } from "react-icons/io";

const BookingAvailabilityEditModal = (props) => {
  const {
    header_type,
    edit_values,
    is_edit_model_visible,
    set_edit_modal_visible,
    calculate_date_range,
    set_date_range,
    handle_save_dates,
    get_all_season_list,
  } = props;

  return (
    <div>
      <Modal
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        title={header_type === "edit" ? `Edit Season` : `Add Season`}
        open={is_edit_model_visible}
        destroyOnClose={true}
        onCancel={() => set_edit_modal_visible(false)}
        width={900}
        footer={[
          <Button key="cancel" onClick={() => set_edit_modal_visible(false)}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            htmlType="submit"
            form="booking_form"
            // onClick={saveDates}
          >
            Save
          </Button>,
        ]}
      >
        <BookingAvailabilityForm
          header_type={header_type}
          set_edit_modal_visible={set_edit_modal_visible}
          edit_values={edit_values}
          calculate_date_range={calculate_date_range}
          set_date_range={set_date_range}
          get_all_season_list={get_all_season_list}
        />
      </Modal>
    </div>
  );
};

export default BookingAvailabilityEditModal;
