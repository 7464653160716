import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const IdNumber = ({ selected_id_proof }) => {
  const { t } = useTranslation();

  const id_regex = {
    "Aadhaar Card": /^\d{12}$/,
    Pancard: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
    "Driving License": /^[\w\d]{5,15}$/,
    "Bank Passbook": /^[\w\d]{5,20}$/,
    Passport: /^[A-Z0-9]{7,9}$/,
    "Ration Card": /^[\w\d]{8,15}$/,
    "Student ID": /^[\w\d]{6,12}$/,
    "Government ID": /^[\w\d]{8,20}$/,
  };

  const validate_id_number = (_, value) => {
    const regex = id_regex[selected_id_proof];

    if (!value) {
      return Promise.reject(t("ID number is required!"));
    }

    if (!regex.test(value)) {
      return Promise.reject(t(`Invalid ${selected_id_proof} number!`));
    }

    return Promise.resolve();
  };

  return (
    <Form.Item
      label={t("id_number")}
      name="id_number"
      rules={[
        // {
        //   required: true,
        //   message: t("Please enter ID number!"),
        // },
        {
          validator: validate_id_number,
        },
      ]}
    >
      <Input
        style={{
          width: "100%",
        }}
        placeholder="ID Card Number"
      />
    </Form.Item>
  );
};

export default IdNumber;
