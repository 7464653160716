import { Button, Modal } from "antd";
import React from "react";
import { IoMdClose } from "react-icons/io";
import SeasonAvailabilityDateSlotsMainForm from "../season_availability_date_slots_main_form/season_availability_date_slots_main_form";
import { useDynamicSelector } from "../../../../../../../services/redux";

const SeasonAvailabilityDateSlotsEditModal = (props) => {
  const {
    header_type,
    edit_values,
    is_edit_model_visible,
    set_edit_modal_visible,
    get_one_season_query,
    active_date_slots,
  } = props;
  const {
    loading: update_season_date_slot_loading,
    date_slots: active_season_date_slots,
  } = useDynamicSelector("get_season");

  console.log("active_season_date_slots", active_season_date_slots);
  console.log("header_type", header_type);
  return (
    <div>
      <Modal
        className="date_slots"
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        title={header_type === "edit" ? edit_values.date_string : null}
        open={is_edit_model_visible}
        destroyOnClose={true}
        onCancel={() => set_edit_modal_visible(false)}
        width={900}
        footer={[
          <Button key="cancel" onClick={() => set_edit_modal_visible(false)}>
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            htmlType="submit"
            form="booking_form"
            // onClick={saveDates}
            loading={update_season_date_slot_loading}
          >
            Save
          </Button>,
        ]}
      >
        {/* <BookingAvailabilityForm
          header_type={header_type}
          set_edit_modal_visible={set_edit_modal_visible}
          edit_values={edit_values}
          calculate_date_range={calculate_date_range}
          set_date_range={set_date_range}
          get_all_season_list={get_all_season_list}
        /> */}
        <SeasonAvailabilityDateSlotsMainForm
          header_type={header_type}
          set_edit_modal_visible={set_edit_modal_visible}
          edit_values={edit_values}
          get_one_season_query={get_one_season_query}
          active_date_slots={active_date_slots}
        />
      </Modal>
    </div>
  );
};

export default SeasonAvailabilityDateSlotsEditModal;
