import { Form, Input } from "antd";
import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const InputDistrictUserPassword = ({ header_type }) => {
  const [show_password, set_show_password] = useState(false);

  const toggle_password_visibility = () => {
    set_show_password(!show_password);
  };

  return (
    <>
      <Form.Item
        name={["system_user", "password"]}
        label="Password"
        rules={
          header_type === "add_user"
            ? [{ required: true, message: "Please enter a password" }]
            : []
        }
      >
        <Input
          type={show_password ? "text" : "password"}
          style={{ width: "270px" }}
          placeholder="Password"
          suffix={
            <span onClick={toggle_password_visibility}>
              {show_password ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>
          }
        />
      </Form.Item>
    </>
  );
};

export default InputDistrictUserPassword;
