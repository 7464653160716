import React from "react";
import { Form, Avatar, Space, Segmented } from "antd";
import { useTranslation } from "react-i18next";
import {
  CARD_IMAGE,
  CARD_IMAGE_G,
  CASH_IMAGE,
  DD_IMAGE,
  UPI_G,
  UPI_IMAGE,
} from "../../../../helpers/image_constants";

const VipBookingPaymentMode = ({ set_payment_mode, payment_mode, has_pos }) => {
  const { t } = useTranslation();
  const onChange = (val) => {
    set_payment_mode(val);
  };

  return (
    <Form.Item
      label={t("pay_mode")}
      name="pay_mode"
      rules={[
        {
          required: true,
          message: t("Please choose a payment mode"),
        },
      ]}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Segmented
          onChange={onChange}
          value={payment_mode}
          options={[
            {
              label: (
                <div style={{ padding: 4, width: "20%" }}>
                  <Avatar
                    style={{
                      width: "50px",
                      height: "40px",
                      padding: "2px",
                    }}
                    src={CASH_IMAGE}
                  />
                  <h4 style={{ fontWeight: "bold", marginLeft: "2px" }}>
                    {t("Cash")}
                  </h4>
                </div>
              ),
              value: "cash",
            },
            {
              label: (
                <div
                  style={{
                    padding: 4,
                    width: "20%",
                  }}
                >
                  <Avatar
                    style={{ width: "45px", height: "40px", padding: "2px" }}
                    src={DD_IMAGE}
                  />
                  <h4
                    style={{
                      fontWeight: "bold",
                      marginLeft: "11px",
                    }}
                  >
                    {t("DD")}
                  </h4>
                </div>
              ),
              value: "dd",
            },
            {
              label: (
                <div style={{ padding: 4, width: "20%" }}>
                  <Avatar
                    style={{ width: "50px", height: "40px", padding: "8px" }}
                    // src={!has_pos ? CARD_IMAGE_G : CARD_IMAGE}
                    src={CARD_IMAGE}
                  />

                  <h4 style={{ fontWeight: "bold", marginLeft: "6px" }}>
                    {t("Card")}
                  </h4>
                  {/* {!has_pos ? (
                    <h4
                      style={{
                        fontWeight: "bold",
                        marginLeft: "6px",
                        color: "#90908F",
                      }}
                    >
                      {t("Card")}
                    </h4>
                  ) : (
                    <h4 style={{ fontWeight: "bold", marginLeft: "6px" }}>
                      {t("Card")}
                    </h4>
                  )} */}
                </div>
              ),
              value: "card",
              // disabled: !has_pos,
            },
            {
              label: (
                <div style={{ padding: 4, width: "20%" }}>
                  <Avatar
                    style={{ width: "70px", height: "40px", padding: "2px" }}
                    // src={!has_pos ? UPI_G : UPI_IMAGE}
                    src={UPI_IMAGE}
                  />

                  <h4 style={{ fontWeight: "bold", marginLeft: "15px" }}>
                    {t("Upi")}
                  </h4>
                  {/* {!has_pos ? (
                    <h4
                      style={{
                        fontWeight: "bold",
                        color: "#90908F",
                        marginLeft: "17px",
                      }}
                    >
                      {t("Upi")}
                    </h4>
                  ) : (
                    <h4 style={{ fontWeight: "bold", marginLeft: "15px" }}>
                      {t("Upi")}
                    </h4>
                  )} */}
                </div>
              ),
              value: "upi",
              // disabled: !has_pos,
            },
          ]}
        />
      </Space>
    </Form.Item>
  );
};

export default VipBookingPaymentMode;
