import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";
import {
  query_get_all_user_group,
  query_get_one_user_group,
} from "../../../../services/redux/slices/graphql/graphql_user_group";
import { useDispatch } from "react-redux";
import { dynamicRequest } from "../../../../services/redux";

const SystemUserGroup = (props) => {
  const { modal_title } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { items: get_user_group_list_items } = useDynamicSelector(
    "get_user_group_list"
  );
  const [user_group_id, set_user_group_id] = useState("");

  const get_all_user_group = () => {
    let key = [{ key: "get_user_group_list", loading: true }];
    let query = query_get_all_user_group;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_all_user_group();
  }, []);

  const on_change = (values) => {
    set_user_group_id(values);
  };

  return (
    <Form.Item label={"User Group"} name="group_id">
      <Select placeholder={"User Group"} allowClear onChange={on_change}>
        {get_user_group_list_items?.map((item) => (
          <Select.Option key={item?.id} value={item?.id}>
            {item?.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SystemUserGroup;
