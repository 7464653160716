import { Button, Modal, Typography } from "antd";
import React from "react";
import { IoMdClose } from "react-icons/io";
import LoaderSpinner from "../../common/custom_spinner";
import { useTranslation } from "react-i18next";
import { dynamicClear, useDynamicSelector } from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../../helpers/functions";

const SpotBookingDuplicateModal = ({
  duplicate_booking_modal,
  set_duplicate_booking_modal,
  set_receipt_modal_open,
  form,
  set_selected_id_proof,
  mobile,
  get_leader_mobile_no,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const leader_details = retrieveItem("leader_details");
  const {
    status: create_spot_booking_status,
    error: create_spot_booking_error,
    loading: create_spot_booking_loading,
    id: create_spot_booking_id,
  } = useDynamicSelector("create_spot_booking");

  const items = useDynamicSelector("get_booking_user_details");

  const { booking_reference, loading: booking_detail_loading } =
    useDynamicSelector("get_booking_detail");

  const on_finish = (values) => {
    set_duplicate_booking_modal(false);

    const url = `/print-receipt/${booking_reference}/${get_leader_mobile_no}`;
    const new_tab = window.open(url, "_blank", "width=850, height=900");
    if (new_tab) {
      new_tab.focus();
    }

    dispatch(dynamicClear("get_booking_user_details"));
  };
  const on_close = () => {
    set_duplicate_booking_modal(false);
    form.resetFields();
  };

  const on_cancel = () => {
    set_duplicate_booking_modal(false);
    form.resetFields();
  };

  return (
    <Modal
      //   data-payment-status={is_order_status_cancelled ? "failure" : "success"}
      title={"Duplicate Booking"}
      open={duplicate_booking_modal}
      onCancel={on_cancel}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      footer={[
        booking_reference && (
          <Button
            key="no-button"
            style={{
              backgroundColor: "#168f92",
              color: "white",
              border: "transparent",
            }}
            onClick={on_close}
          >
            {t("no")}
          </Button>
        ),
        booking_reference && (
          <Button
            key="yes-button"
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            htmlType="submit"
            onClick={on_finish}
          >
            {t("yes")}
          </Button>
        ),
      ]}
    >
      <div>
        <Typography style={{ color: "red" }}>{t("Already_booking")}</Typography>
      </div>

      <Typography
        style={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        {booking_reference}
      </Typography>
    </Modal>
  );
};

export default SpotBookingDuplicateModal;
