import { Form, Input, InputNumber } from "antd";
import React, { useState, useTransition } from "react";
import { useTranslation } from "react-i18next";

const MasmUserMobile = () => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        name="mobile"
        label={t("mobile_no")}
        rules={[
          {
            required: true,
            message: "Please enter mobile number",
          },
          {
            pattern: /^[0-9]{10}$/,
            message: "Please enter valid mobile number",
          },
        ]}
      >
        <Input type="text" placeholder={t("mobile_no")} allowClear={true} />
      </Form.Item>
    </>
  );
};

export default MasmUserMobile;
