import { Button, Modal } from "antd";
import React from "react";
import { IoMdClose } from "react-icons/io";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useDynamicSelector } from "../../../../../services/redux";
import GroupWisePdfRender from "../group_wise_pdf_render/group_wise_pdf_render";

const GroupWiseReportModal = (props) => {
  const { visible, set_visible, report_date } = props;
  console.log("report_date", report_date);
  const { items: group_wise_items } = useDynamicSelector(
    "get_district_group_wise_count"
  );

  return (
    <Modal
      title={"Group Wise Report"}
      open={visible}
      width={800}
      destroyOnClose={true}
      onCancel={() => set_visible(false)}
      footer={[
        <Button key="cancel" onClick={() => set_visible(false)}>
          Cancel
        </Button>,
        <PDFDownloadLink
          style={{ textDecoration: "none", marginLeft: "10px" }}
          document={<GroupWisePdfRender group_wise_items={group_wise_items} />}
          fileName={`Group-Wise-Report.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              "Loading document..."
            ) : (
              <Button variant={"feedback_configer_button"}>Download</Button>
            )
          }
        </PDFDownloadLink>,
      ]}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PDFViewer width={"770px"} height={"400px"}>
          <GroupWisePdfRender
            group_wise_items={group_wise_items}
            report_date={report_date.label}
          />
        </PDFViewer>
      </div>
    </Modal>
  );
};

export default GroupWiseReportModal;
