import { Form, Input, InputNumber, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { countFormat } from "../../../../helpers/constants";

const BookingAmount = ({ amount }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography style={{ marginBottom: "2%" }}>Amount</Typography>
      <Typography style={{ marginTop: "3%" }}>
        {countFormat(amount||0)}
      </Typography>
      {/* <Input
        style={{
          width: "100%",
        }}
        value={countFormat(amount)||""}
      /> */}
    </div>
  );
};

export default BookingAmount;
