import React, { useEffect, useState } from "react";
import { Pagination, Row, Space, Table } from "antd";
import { useDispatch } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import CustomDeleteModal from "../../common/custom_delete_modal";
import { useTranslation } from "react-i18next";
import { startCase } from "lodash";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { retrieveItem } from "../../../../helpers/functions";
import { countFormat } from "../../../../helpers/constants";
import { useHistory } from "react-router-dom";
import {
  mutation_delete_sub_district,
  query_get_all_sub_district,
  query_get_one_sub_district,
  query_get_one_sub_district_user,
  query_get_sub_district_users_list,
} from "../../../../services/redux/slices/graphql/graphql_sub_district";
import ExpandableSubDistrictForm from "./sub_district_expandable_form";

const SubDistrictTable = (props) => {
  const { t } = useTranslation();
  const {
    set_edit_modal_visible,
    set_header_type,
    search_string,
    select_district_id,
  } = props;
  const role = retrieveItem("role");
  const dispatch = useDispatch();
  const [add_user_modal_visible, set_add_user_modal_visible] = useState(false);
  const [delete_modal_visible, set_delete_modal_visible] = useState(false);
  const [current_page_limit, set_page_limit] = useState(10);
  const [current_page, set_page_number] = useState(1);
  const [delete_record, set_delete_record] = useState({});
  const [color_class, set_color_class] = useState("");
  const [record_id, set_record_id] = useState("");
  const [active_row_key, set_active_row_key] = useState(null);

  const {
    loading: get_sub_district_list_loading,
    items,
    pagination: district_list_pagination,
  } = useDynamicSelector("get_sub_district_list");
  console.log("items", items);

  const handle_expand = (expanded, record) => {
    console.log("record", record);

    if (expanded) {
      set_active_row_key(record.id);
      set_record_id(record.id);
      get_all_districts_users(record.id);
      get_one_sub_district_user(record);
    } else {
      set_active_row_key(null);
    }
  };
  const get_one_sub_district_user = (record) => {
    let key = [{ key: "get_sub_district_user", loading: true }];
    let query = query_get_one_sub_district_user;
    let variable = {
      id: record.id,
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const get_all_districts_users = (record_id) => {
    let keys = [{ key: "get_sub_district_user_list", loading: true }];
    let query = query_get_sub_district_users_list;
    let variables = {
      filter: {
        sub_district_id: record_id || "",
      },
    };
    dispatch(dynamicRequest(keys, query, variables));
  };
  const get_sub_district_list = () => {
    let keys = [{ key: "get_sub_district_list", loading: true }];
    let query = query_get_all_sub_district;
    let variable = {
      filter: { search_string: search_string, district_id: select_district_id },
      pagination: {
        page_number: current_page || 1,
        page_limit: current_page_limit || 10,
      },
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };
  const fetch_data = (page_number, page_limit) => {
    set_page_number(page_number);
    set_page_limit(page_limit);
  };

  useEffect(() => {
    get_sub_district_list();
  }, [current_page, current_page_limit, search_string, select_district_id]);

  const get_one_district = (id) => {
    let keys = [{ key: "get_sub_district", loading: true }];
    let query = query_get_one_sub_district;
    let variable = {
      id,
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const handle_edit = (record) => {
    get_one_district(record.id);
    set_header_type("edit_district");
    set_edit_modal_visible(true);
  };
  const handle_navigate = (record) => {
    console.log("recordrecord", record);
  };

  const handle_delete = (record) => {
    set_delete_record(record);
    set_delete_modal_visible(true);
  };

  const delete_sub_district = () => {
    let keys = [{ key: "delete_sub_district", loading: true }];
    let query = mutation_delete_sub_district;
    let variable = {
      id: delete_record.id,
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      width: "400px",
      align: "left",
    },
    {
      title: "Name",
      dataIndex: "name",
      filterSearch: true,
      width: "400px",
      render: (name) => {
        return startCase(name);
      },
    },
    {
      title: "Tamil Name",
      dataIndex: "tamil_name",
      filterSearch: true,
      width: "400px",
    },
    {
      title: "Extra Allowed Percentage",
      dataIndex: "extra_allowed_percentage",
      width: "460px",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "400px",
      render: (_, record) => (
        <Space
          style={{
            width: "60px",
            justifyContent: "space-between",
          }}
        >
          <EditOutlined
            onClick={() => handle_edit(record)}
            className="sub_district_icon"
          />
          <DeleteOutlined
            onClick={() => handle_delete(record)}
            className="sub_district_icon"
          />
        </Space>
      ),
    },
  ];

  const table_row_class_name = (record, index) => {
    const class_name = index % 2 === 0 ? "odd-row" : "even-row";
    set_color_class(class_name);
    return class_name;
  };

  return (
    <>
      <Table
        id={"expandable_parent_table"}
        rowKey={"id"}
        columns={columns}
        dataSource={items}
        loading={get_sub_district_list_loading}
        className="district-table"
        size="large"
        pagination={false}
        rowClassName={(record, index) => table_row_class_name(record, index)}
        expandable={{
          expandedRowRender: (record, index) => (
            <ExpandableSubDistrictForm
              color={index % 2 === 0 ? "white" : "#ffeadd"}
              get_one_district={get_one_district}
              get_all_districts={get_sub_district_list}
              get_all_districts_users={get_all_districts_users}
              record={record}
              record_id={record.id}
              items={[record?.admin]}
              add_user_modal_visible={add_user_modal_visible}
              set_add_user_modal_visible={set_add_user_modal_visible}
              expandable_district_index={index}
            />
          ),
          onExpand: (expanded, record) => handle_expand(expanded, record),
          rowExpandable: (record) => true,
          expandedRowKeys: active_row_key ? [active_row_key] : [],
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Pagination
          showTotal={(total, range) =>
            `Total ${countFormat(total)} Sub Districts`
          }
          total={district_list_pagination?.total_count}
          showSizeChanger={true}
          current={current_page}
          hideOnSinglePage={false}
          onChange={fetch_data}
        />
      </div>

      <CustomDeleteModal
        selector_name="delete_sub_district"
        get_all={get_sub_district_list}
        delete_api={delete_sub_district}
        record={delete_record}
        text={t("sub_district")}
        delete_modal_visible={delete_modal_visible}
        set_delete_modal_visible={set_delete_modal_visible}
      />
    </>
  );
};

export default SubDistrictTable;
