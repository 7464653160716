import { Button, Form, Modal, Skeleton } from "antd";
import React, { useState } from "react";
import AddMandramForm from "../add_mandram/add_mandram_form";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux";

const AddMandramModal = (props) => {
  const { set_add_mandram, add_mandram, header_type, get_all_mandram_list } =
    props;

  const { loading: create_mandram_loading } =
    useDynamicSelector("create_mandram");
  const { loading: update_mandram_loading } =
    useDynamicSelector("update_mandram");
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <Modal
      title={header_type}
      open={add_mandram}
      destroyOnClose={true}
      onCancel={() => set_add_mandram(false)}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      form={form}
      width={800}
      footer={[
        <Button
          style={{
            backgroundColor: "#168f92",
            color: "white",
            border: "transparent",
          }}
          onClick={() => set_add_mandram(false)}
        >
          {t("cancel")}
        </Button>,
        <Button
          loading={
            header_type === "Add Mandram"
              ? create_mandram_loading
              : update_mandram_loading
          }
          style={{
            backgroundColor: "#f96451",
            color: "white",
            border: "transparent",
          }}
          htmlType="submit"
          form="add_mandram_form"
        >
          {t("save")}
        </Button>,
      ]}
    >
      <AddMandramForm
        {...props}
        set_add_mandram={set_add_mandram}
        header_type={header_type}
        get_all_mandram_list={get_all_mandram_list}
      />
    </Modal>
  );
};

export default AddMandramModal;
