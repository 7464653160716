import { Form, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { district_details_query } from "../../../../services/redux/slices/graphql/graphql_district";
import { useDynamicSelector } from "../../../../services/redux/selector";

const SpotBookingDistrict = ({
  set_district_id,
  districts,
  get_mandram_list,
}) => {
  const { t } = useTranslation();

  const district_list = districts || [];

  const handle_change = (value) => {
    set_district_id(value);
  };

  return (
    <Form.Item
      label={t("district")}
      name="district"
      rules={[{ required: true, message: "Please select district" }]}
    >
      <Select
        style={{
          width: "100%",
        }}
        placeholder={t("district")}
        onChange={handle_change}
        allowClear
        showSearch
        filterOption={(input, option) =>
          option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
      >
        {district_list.map((district) => (
          <Select.Option key={district.id} value={district.id}>
            {district.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SpotBookingDistrict;
