import { Form, Select } from "antd";
import { startCase } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

const SpotBookingIdProof = ({ set_selected_id_proof }) => {
  const { t } = useTranslation();

  const options = [
    { value: "Aadhaar Card", label: t("aadhaar_card") },
    { value: "Pancard", label: t("pancard") },
    { value: "Driving License", label: t("driving_license") },
    { value: "Bank Passbook", label: t("bank_passbook") },
    { value: "Passport", label: t("passport") },
    { value: "Ration Card", label: t("ration_card") },
    { value: "Student ID", label: t("student_id") },
    { value: "Government ID", label: t("gov_id") },
  ];

  const on_change = (val) => {
    console.log("val", val);
    set_selected_id_proof(val);
  };

  return (
    <Form.Item
      label={t("id_proof")}
      name="id_type"
      rules={
        [
          // {
          //   required: true,
          //   message: "Please select your ID proof!",
          // },
        ]
      }
      placeholder="Select"
    >
      <Select
        style={{
          width: "100%",
        }}
        onChange={on_change}
      >
        {options.map((option) => (
          <Select.Option key={option.id} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default SpotBookingIdProof;
