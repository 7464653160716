import React from "react";

const ToolTipContent = ({ data }) => {
  const style = {
    margin: "5px 0",
    fontSize: "9px",
    display: "flex",
    justifyContent: "space-between",
    width: "90px",
  };

  return (
    <div className="tooltip-content-container">
      <div style={style}>
        <span style={{ color: "dodgerBlue" }}>Net Booked</span>
        <span>{data?.count?.booked || 0}</span>
      </div>
      <div style={style}>
        <span style={{ color: "green" }}>Enrolled</span>
        <span>{data?.count.enrolled || 0}</span>
      </div>
      <div style={style}>
        <span style={{ color: "red" }}>Yet to Enroll</span>
        <span>
          {/* {data?.count?.yet_to_enroll < 0 ? 0 : data?.count?.yet_to_enroll || 0} */}
          {data?.count?.yet_to_enroll}
        </span>
      </div>
      <div style={style}>
        <span style={{ color: "orange" }}>Excess</span>
        <span>{data?.count.excess}</span>
      </div>
    </div>
  );
};

export default ToolTipContent;
