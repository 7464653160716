import { Button, Modal } from "antd";
import React from "react";
import { IoMdClose } from "react-icons/io";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useDynamicSelector } from "../../../../../services/redux";
import CounterGroupWisePDF from "../counter_group_wise_report_pdf/counter_group_wise_pdf";

const CounterGroupWiseReportModal = (props) => {
  const { visible, set_visible } = props;
  const { items: counter_group_items, loading: counter_group_items_loading } =
    useDynamicSelector("get_counter_group_wise_summary");
  console.log("counter_group_items", counter_group_items);
  return (
    <Modal
      title={"Counter Group Wise Report"}
      open={visible}
      width={800}
      destroyOnClose={true}
      onCancel={() => set_visible(false)}
      footer={[
        <Button key="cancel" onClick={() => set_visible(false)}>
          Cancel
        </Button>,
        <PDFDownloadLink
          style={{ textDecoration: "none", marginLeft: "10px" }}
          document={
            <CounterGroupWisePDF counter_group_items={counter_group_items} />
          }
          fileName={`counter_group_wise_report.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : <Button>Download</Button>
          }
        </PDFDownloadLink>,
      ]}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PDFViewer width={"770px"} height={"400px"}>
          <CounterGroupWisePDF counter_group_wise_items={counter_group_items} />
        </PDFViewer>
      </div>
    </Modal>
  );
};

export default CounterGroupWiseReportModal;
