import { Card, Col, Form, Row } from "antd";
import React from "react";
import OmSakthiReportFromDate from "../om_sakthi_report_forn_inputs/om_sakthi_report_from_date";
import CustomButton from "../../../common/custom_button";
import { useTranslation } from "react-i18next";
import _orderBy from "lodash/orderBy";
import OmSakthiReportToDate from "../om_sakthi_report_forn_inputs/om_sakthi_report_to_date";
import {
  dynamicClear,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const OhmSakthiReportForm = (props) => {
  const {
    get_om_sakthi_report,
    get_om_sakthi_payment_report,
    set_from_date,
    set_to_date,
    set_om_sakthi_report,
    set_selected_dates,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { items } = useDynamicSelector("om_sakthi_report_date");
  const ordered_date_slots = _orderBy(items, ["date"], ["asc"]);

  const handle_finish = (values) => {
    get_om_sakthi_report(values);
    get_om_sakthi_payment_report(values);
    set_om_sakthi_report(true);
  };

  const handle_form_change = () => {
    dispatch(dynamicClear("get_om_sakthi_payment"));
    dispatch(dynamicClear("get_om_sakthi_summary"));
  };

  return (
    <Form
      id="search"
      onFinish={handle_finish}
      form={form}
      onValuesChange={handle_form_change}
    >
      <Card
        style={{
          width: "96%",
          borderRadius: "5px",
          backgroundColor: "#e7e7e7",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row gutter={24} style={{ height: "30px" }}>
          <Col span={11}>
            <OmSakthiReportFromDate
              ordered_date_slots={ordered_date_slots}
              set_from_date={set_from_date}
            />
          </Col>
          <Col span={10}>
            <OmSakthiReportToDate
              ordered_date_slots={ordered_date_slots}
              set_to_date={set_to_date}
            />
          </Col>
          <Col span={3}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CustomButton
                button_name={t("get")}
                bg_color={"#014477"}
                rounded={true}
                is_submit={true}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default OhmSakthiReportForm;
