import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CounterGroupForm from "../counter_group_form/counter_group_form";
import { IoMdClose } from "react-icons/io";
import { useDynamicSelector } from "../../../../services/redux";

const CounterGroupModal = (props) => {
  const {
    counter_group_modal_visible,
    set_counter_group_modal_visible,
    header_type,
    selected_items,
    get_all_counter_group,
  } = props;
  const { t } = useTranslation();

  const { loading: create_counter_group_loading } = useDynamicSelector(
    "create_counter_group"
  );
  const { loading: update_counter_group_loading } = useDynamicSelector(
    "update_counter_group"
  );

  const handle_modal_close = () => {
    set_counter_group_modal_visible(false);
  };

  return (
    <div>
      <Modal
        open={counter_group_modal_visible}
        onCancel={handle_modal_close}
        width={"40%"}
        title={
          header_type === "add" ? "Add Counter Group" : "Edit Counter Group"
        }
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            // style={{ backgroundColor: "#168f92", color: "white" }}
            onClick={handle_modal_close}
          >
            {t("cancel")}
          </Button>,
          <Button
            style={{ backgroundColor: "#1e90ff", color: "white" }}
            htmlType="submit"
            form="add_counter_group_form"
            loading={
              create_counter_group_loading || update_counter_group_loading
            }
          >
            {t("save")}
          </Button>,
        ]}
      >
        <CounterGroupForm
          header_type={header_type}
          selected_items={selected_items}
          get_all_counter_group={get_all_counter_group}
          set_counter_group_modal_visible={set_counter_group_modal_visible}
        />
      </Modal>
    </div>
  );
};
export default CounterGroupModal;
