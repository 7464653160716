import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "../../../../../services/redux";
import CheckBoxComponent from "../check_box";
import { system_ui_module_component_role_access } from "../../../../../services/redux/slices/graphql/graphql_access_rights";

const PageComponentRoleCheckBox = (props) => {
  const { page_component, role, key } = props;
  const dispatch = useDispatch();

  const { id, status, loading, error } = useDynamicSelector(
    `set_system_ui_module_component_role_access-${page_component.id}-${role.id}`
  );
  const [has_access, setHasAccess] = useState(false);
  useEffect(() => {
    if (page_component) {
      let page_roles = page_component?.roles?.map((x) => x?.id);
      if (page_roles?.indexOf(role.id) > -1) {
        setHasAccess(true);
      } else {
        setHasAccess(false);
      }
    }
  }, [page_component]);
  useEffect(() => {
    if (status === "success" && id === `${page_component.id}-${role.id}`) {
      setHasAccess(!has_access);
      dispatch(
        dynamicClear(
          `set_system_ui_module_component_role_access-${page_component.id}-${role.id}`
        )
      );
    }
    // else if (error === "error" && id === page_component?.id) {
    //   dispatch(dynamicClear(`set_system_ui_module_component_role_access`));
    // }
  }, [status]);

  const on_change_page_access = (value) => {
    if (!loading) {
      let key = [
        {
          key: "set_system_ui_module_component_role_access",
          loading: true,
          variant: `${page_component.id}-${role.id}`,
        },
      ];
      let query = system_ui_module_component_role_access;
      let variable = {
        custom: {
          id: page_component.id,
          role_id: role.id,
          has_access: value,
        },
      };
      dispatch(dynamicRequest(key, query, variable));
    }
  };

  return (
    <CheckBoxComponent
      key={key}
      is_checked={has_access}
      loading={loading}
      onChange={on_change_page_access}
    />
  );
};
export default PageComponentRoleCheckBox;
