import gql from "graphql-tag";

export const query_get_all_counter = gql`
query get_counter_list($filter:get_counter_list_filter_input,$sort:sort_input) {
  get_counter_list(filter:$filter,sort:$sort) {
    items {
      id
      name
      code
      is_active
      counter_group_id
      system_reference_id
      printer_reference_id
      pos_id
    }
    pagination {
      total_count
      page_number
      page_limit
    }
    error {
      status_code
      message
    }
  }
}
`;

export const mutation_create_counter = gql`
  mutation create_counter($data: create_counter_input!) {
    create_counter(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_one_counter = gql`
  query get_counter($id: String!) {
    get_counter(id: $id) {
      name
      code
      is_active
      counter_group_id
      system_reference_id
      printer_reference_id
      pos_id
    }
  }
`;

export const mutation_update_counter = gql`
  mutation update_counter($id: String!, $data: update_counter_input!) {
    update_counter(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_counter = gql`
  mutation delete_counter($id: String!) {
    delete_counter(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_pos_list = gql`
  query {
    get_pos_list {
      items {
        id
        name
        terminal_id
      }
    }
  }
`;

export const mutation_get_unassigned_pos_list = gql`
  query get_unassigned_pos_list($filter: get_unassigned_pos_list_filter_input) {
    get_unassigned_pos_list(filter: $filter) {
      items {
        id
        name
        terminal_id
      }
    }
  }
`;
