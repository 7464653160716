import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SystemUserNameInput = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={t("name")}
      name="name"
      rules={[
        {
          required: true,
          message: "Please enter name",
        },
      ]}
    >
      <Input placeholder={t("name")} />
    </Form.Item>
  );
};
export default SystemUserNameInput;
