import { Row } from "antd";
import React, { useEffect, useState } from "react";

const TicketNumberListCard = (props) => {
  const { count, select_all, selected_items, set_selected_items,handle_unselect_all } = props;

  const [mouse_down_item, set_mouse_down_item] = useState(null);
  const [is_mouse_down, set_is_mouse_down] = useState(false);
  const items = Array.from({ length: count }, (_, index) => index + 1);

  useEffect(() => {
    if (select_all) {
      set_selected_items(items);
    } else {
      set_selected_items([]);
    }
  }, [select_all]);

  const handle_item_click = (item) => {
    if (selected_items.includes(item)) {
      set_selected_items(
        selected_items.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      set_selected_items([...selected_items, item]);
    }
  };

  let mouse_down_timeout = null;
  const handle_item_mouse_down = (item) => {
    clearTimeout(mouse_down_timeout);
    mouse_down_timeout = setTimeout(() => {
      let _selected_items = [...selected_items, item];
      _selected_items = [...new Set(_selected_items)];
      set_selected_items(_selected_items);
      set_mouse_down_item(item);
      set_is_mouse_down(true);
    }, 600);
  };

  const handle_item_mouse_up = () => {
    clearTimeout(mouse_down_timeout);
    set_mouse_down_item(null);
    set_is_mouse_down(false);
  };

  const handle_item_mouse_enter = (item) => {
    if (is_mouse_down) {
      const start_item = mouse_down_item;
      const current_item = mouse_down_item;

      const selected_range = Array.from(
        { length: Math.abs(item - start_item) + 1 },
        (_, i) => start_item + (item > start_item ? i : -i)
      );
      let _selected_items = [...selected_items, ...selected_range];
      _selected_items = [...new Set(_selected_items)];
      set_selected_items(_selected_items);
    }
  };

  return (
    <Row onMouseUp={() => handle_item_mouse_up()}>
      {items.map((item, index) => (
        <div
          style={{
            backgroundColor: selected_items.includes(item)
              ? "dodgerblue"
              : "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "40px",
            height: "40px",
            margin: "8px",
            cursor: "pointer",
            userSelect: "none",
            borderRadius: "3px",
            color: selected_items.includes(item) ? "white" : "black",
            boxShadow: selected_items.includes(item)
              ? "0px 2px 8px 0px rgba(173, 216, 230, 0.9)"
              : "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
          key={index}
          onClick={() => handle_item_click(item)}
          onMouseDown={() => handle_item_mouse_down(item)}
          onMouseEnter={() => handle_item_mouse_enter(item)}
          onMouseUp={() => handle_item_mouse_up()}
        >
          {item}
        </div>
      ))}
    </Row>
  );
};

export default TicketNumberListCard;
