import React, { useEffect, useState } from "react";
import { Radio, Form } from "antd";

const BookingTypeNormalVip = ({ form }) => {
  return (
    <Form.Item
      label="Booking Type"
      name="booking_type"
      rules={[{ required: true, message: "Please select a booking type" }]}
    >
      <Radio.Group
        defaultValue={"normal"}
      >
        <Radio value="normal">Normal Booking</Radio>
        <Radio value="vip">VIP Booking</Radio>
      </Radio.Group>
    </Form.Item>
  );
};

export default BookingTypeNormalVip;
