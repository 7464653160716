import React from "react";
import DateWiseBookingInputs from "../components/date_wise_booking_report/date_wise_booking_report_form/date_wise_booking_inputs";
import CustomPageTitle from "../components/common/custom_page_title";
import { Row } from "antd";

const DateWiseBookingReport = () => {
  return (
    <div>
      <Row style={{ marginLeft: "10px" }}>
        <CustomPageTitle title={"Date Wise Booking Report"} />
      </Row>
      <DateWiseBookingInputs />
    </div>
  );
};

export default DateWiseBookingReport;
