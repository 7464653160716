import { Button, Modal } from "antd";
import React from "react";
import UpdateEnrolmentForm from "../form/update_enrolment_form";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux";
import { IoMdClose } from "react-icons/io";

const UpdateEnrollmentModal = (props) => {
  const {
    update_modal_visible,
    set_update_modal_visible,
    record,
    get_all_enrollment_list,
  } = props;
  const { loading: update_enrolment_loading } =
    useDynamicSelector("update_enrolment");
  const { t } = useTranslation();
  return (
    <Modal
      title={update_modal_visible === true ? "Update Enrollment" : ""}
      open={update_modal_visible}
      destroyOnClose={true}
      onCancel={() => set_update_modal_visible(false)}
      footer={[
        <Button onClick={() => set_update_modal_visible(false)}>
          {t("cancel")}
        </Button>,
        <Button
          loading={update_enrolment_loading}
          style={{ backgroundColor: "dodgerBlue", color: "white" }}
          htmlType="submit"
          form="update_enrolment_form"
        >
          {t("save")}
        </Button>,
      ]}
      width={"500px"}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <UpdateEnrolmentForm {...props} />
    </Modal>
  );
};

export default UpdateEnrollmentModal;
