import { Form, Select } from "antd";
import {
  dynamicClear,
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import _orderBy from "lodash/orderBy";

const DayWiseReport = (props) => {
  const { set_selected_date, name } = props;
  const dispatch = useDispatch();
  const { items: season_date_slot_list_items } = useDynamicSelector(
    "get_season_date_slot_list"
  );
  console.log("season_date_slot_list_items1153", season_date_slot_list_items);

  // const sorted_date_slot_items = _orderBy(
  //   season_date_slot_list_items,
  //   ["date"],
  //   ["asc"]
  // );

  useEffect(() => {
    get_season_date_slot_list();
    dispatch(dynamicClear("get_daywise_summary"));
  }, []);

  const get_season_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const on_change = (value) => {
    dispatch(dynamicClear("get_daywise_summary"));
    set_selected_date({ date: value });
    console.log("Selected date:", value);
  };

  return (
    <div>
      <Form.Item
        name={name}
        label={name === "to_date" ? "To Date" : "From Date"}
        rules={[
          {
            required: "true",
            message: "Please Select Date",
          },
        ]}
      >
        <Select
          placeholder="Select Date"
          allowClear
          onChange={on_change}
          style={{ width: "200px" }}
        >
          {season_date_slot_list_items?.map((option, index) => (
            <Select.Option key={index} value={option.date_string}>
              {option.date_string}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default DayWiseReport;
