import { gql } from "@apollo/client";

export const create_feedback_response_mutation = gql`
  mutation create_feedback_response($data: feedback_response_input) {
    create_feedback_response(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
