import React from "react";
import { Form, Input } from "antd";

const PosName = () => {
  return (
    <Form.Item
      label="Name"
      name="name"
      rules={[
        {
          required: true,
          message: "Please enter the POS name",
        },
      ]}
    >
      <Input placeholder="Name" />
    </Form.Item>
  );
};

export default PosName;
