import { Form } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import BankActiveSwitch from "./bank_form_items/bank_active_switch";
import {
  dynamicRequest,
  mutation_create_bank,
  mutation_update_bank,
} from "../../../services/redux";
import BankName from "./bank_form_items/bank_name";

const BankForm = (props) => {
  const { selected_items, bank_modal_title } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handle_bank_submit = (values) => {
    if (bank_modal_title === "add") {
      create_one_bank(values);
    } else {
      update_one_bank(values);
      delete values.is_active;
    }
  };

  const create_one_bank = (values) => {
    let key = [{ key: "create_bank", loading: true }];
    let query = mutation_create_bank;
    let variables = {
      data: {
        name: values?.name,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const update_one_bank = (values) => {
    let key = [{ key: "update_bank", loading: true }];
    let query = mutation_update_bank;
    let variables = {
      id: selected_items.id,
      data: {
        name: values?.name,
        is_active: values?.is_active,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <Form
      id="add_bank_form"
      form={form}
      initialValues={selected_items}
      labelCol={{
        span: 12,
      }}
      wrapperCol={{
        span: 12,
      }}
      onFinish={handle_bank_submit}
    >
      <BankName />
      {bank_modal_title == "edit" ? (
        <BankActiveSwitch initialValue={selected_items.is_active} />
      ) : (
        ""
      )}
    </Form>
  );
};
export default BankForm;
