import { Button, Modal, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { IoMdClose } from "react-icons/io";
import { useDynamicSelector } from "../../../../services/redux";

const BookingActionCancelBookingModal = (props) => {
  const {
    open_notification,
    set_open_notification,
    set_modal_open,
    mandram_booking_cancel,
    cancel_booking_details,
  } = props;
  const { t } = useTranslation();

  const on_finish = (values) => {
    set_open_notification(false);
    set_modal_open(false);
    mandram_booking_cancel(cancel_booking_details?.id);
  };
  const on_close = () => {
    set_open_notification(false);
  };
  return (
    <div>
      <Modal
        title={t("cancel_booking_header")}
        open={open_notification}
        onCancel={() => set_open_notification(false)}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            htmlType="submit"
            onClick={on_finish}
          >
            {t("yes")}
          </Button>,
          <Button
            style={{
              backgroundColor: "#168f92",
              color: "white",
              border: "transparent",
            }}
            onClick={on_close}
          >
            {t("no")}
          </Button>,
        ]}
      >
        <Typography>{t("cancel_booking")}</Typography>
      </Modal>
    </div>
  );
};

export default BookingActionCancelBookingModal;
