import { Row, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import React from "react";

const CustomBreadCrumbs = ({ pages }) => {
  const breadcrumb_items = pages?.split("/");
  return (
    <Row>
      <Breadcrumb>
        {breadcrumb_items.map((item, index) => (
          <Breadcrumb.Item key={index}>
            <Link to={item === breadcrumb_items[0] ? "/" : `/${item}`}>
              {item}
            </Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </Row>
  );
};

export default CustomBreadCrumbs;
