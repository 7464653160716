import { Button, Pagination, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  dynamicRequest,
  get_season_list_query,
  useDynamicSelector,
} from "../../../../../services/redux";
import moment from "moment";
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { MdOutlineCheck, MdOutlineCommentsDisabled } from "react-icons/md";

import { MdOutlineComments } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  getAmountFormat,
  getAmountFormatWithSymbol,
} from "../../../../../helpers/functions";

const BookingAvailabilityTable = (props) => {
  const dispatch = useDispatch();
  const { handle_details, show_delete_model, loading, show_enable_modal } =
    props;

  const { items: get_season_list_items } =
    useDynamicSelector("get_season_list");

  const [current, set_current] = useState(1);
  const [current_limit, set_current_limit] = useState(10);
  useEffect(() => {
    let keys = [{ key: "get_season_list", loading: true }];
    dispatch(
      dynamicRequest(keys, get_season_list_query, {
        page_number: current,
        page_limit: current_limit,
      })
    );
  }, []);

  // const fetch_data = (page_number, page_limit) => {
  //   let keys = [{ key: "get_season_list", loading: true }];
  //   let query = get_season_list_query;
  //   let variable = {
  //     page_number: page_number,
  //     page_limit: page_limit,
  //   };
  //   dispatch(dynamicRequest(keys, query, variable));
  //   set_current(page_number);
  //   set_current_limit(page_limit);
  // };
  // useEffect(() => {
  //   let keys = [{ key: "verification", loading: true }];
  //   dispatch(
  //     dynamicRequest(keys, get_verifications_query, {
  //       page_number: current,
  //       page_limit: current_limit,
  //     })
  //   );
  // }, []);
  const fetchData = (page_number, page_limit) => {
    let keys = [{ key: "get_season_list", loading: true }];
    dispatch(
      dynamicRequest(keys, get_season_list_query, {
        page_number: page_number,
        page_limit: page_limit,
      })
    );
    set_current_limit(page_limit);
    set_current(page_number);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "",
      key: "title",
      align: "center",
      width: 150,
      render: (season) => {
        return season?.title;
      },
    },

    {
      title: "Date Range",
      key: "date_range",
      align: "center",
      width: 250,
      render: (text, record) => {
        const startDate = moment(record.start_date).format("DD-MMM-YYYY");
        const endDate = moment(record.end_date).format("DD-MMM-YYYY");

        return `${startDate} - ${endDate}`;
      },
    },
    {
      title: "Time",
      key: "time",
      align: "center",
      width: 200,
      render: (text, record) => {
        const startTime = moment()
          .startOf("day")
          .add(record.day_start_time_in_minutes, "minutes")
          .format("hh:mm a");

        const endTime = moment()
          .startOf("day")
          .add(record.day_end_time_in_minutes, "minutes")
          .format("hh:mm a");

        return `${startTime} - ${endTime}`;
      },
    },
    {
      title: "Ticket Price",
      dataIndex: "",
      key: "ticket_price",
      align: "center",
      render: (record) => {
        return getAmountFormatWithSymbol(record?.ticket_price);
      },
    },
    {
      title: "VIP Ticket Price",
      dataIndex: "",
      key: "spl_ticket_price",
      align: "center",
      render: (record) => {
        return getAmountFormatWithSymbol(record?.vip_ticket_price);
      },
    },

    // ...

    // {
    //   title: "Quota",
    //   dataIndex: "date_slots",
    //   key: "quota",
    //   align: "center",
    //   render: (text, record) => {
    //     // Assuming record "date_slots" is an array
    //     const firstQuota = record?.date_slots?.[0]?.quota;
    //     return <Typography>{firstQuota}</Typography>;
    //   },
    // },

    {
      title: "Action",
      key: "action",
      align: "center",
      width: 300,
      render: (text, record, row_index) => (
        <div>
          <Button onClick={() => handle_details(record)} type="link">
            <InfoCircleOutlined />
          </Button>

          <Button
            onClick={() => {
              show_enable_modal(record, !record.is_active);
            }}
            type="link"
          >
            {record.is_active ? (
              <MdOutlineCheck />
            ) : (
              <MdOutlineCommentsDisabled />
            )}
          </Button>
          <Button onClick={() => show_delete_model(record)} type="link">
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ overflowX: "auto" }}>
      <Table
        loading={loading}
        columns={columns}
        dataSource={get_season_list_items}
        pagination={false}
        // pagination={{
        //   current: current,
        //   pageSize: current_limit,
        //   total: get_season_list_items?.length,
        //   showSizeChanger: true,
        //   onChange: fetch_data,
        //   onShowSizeChange: (current, size) => {
        //     fetch_data(current, size);
        //   },
        // }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Pagination
          total={get_season_list_items?.total_count}
          showSizeChanger={true}
          current={current}
          hideOnSinglePage={false}
          onChange={fetchData}
        />
      </div>
    </div>
  );
};

export default BookingAvailabilityTable;
