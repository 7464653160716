import { Col, Image, Row, Typography } from "antd";
import React from "react";
import SwamiImage from "@assets/icons/adhiparashathi_image.png";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux";
const { Title } = Typography;

const ReceiptImageContent = () => {
  const { booking_reference } = useDynamicSelector("get_booking_receipt");

  const { t } = useTranslation();
  return (
    <>
      <Col
        style={{
          height: "auto",
          width: "170px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row
          style={{
            alignItems: "center",
            width: "200px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Image
            style={{ margin: "auto" }}
            width={50}
            src={SwamiImage}
            preview={false}
          />
          <Title level={5} className="booking_no">
            {t("booking_no")}
            {booking_reference}
          </Title>
        </Row>
      </Col>
    </>
  );
};

export default ReceiptImageContent;
