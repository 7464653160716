import { Card, Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomButton from "./custom_button";
import { dynamicClear, dynamicRequest } from "../../../services/redux";
import DailyTransactionReportFromDate from "../system_report/daily_transaction_report/daily_transaction_report_form_inputs/daily_transaction_report_from_date";
import DailyTransactionReportToDate from "../system_report/daily_transaction_report/daily_transaction_report_form_inputs/daily_transaction_report_to_date";

const CustomReportSearch = (props) => {
  const { dynamic_query, dynamic_key, get_selected_dates, set_selected_dates } =
    props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [date, set_date] = useState({});

  useEffect(() => {
    if (!date?.from_date && !date.to_date) {
      get_report_api({ from_date: "", to_date: "" });
    }
    // get_selected_dates(date);
  }, [date]);

  const handle_finish = (values) => {
    get_report_api(values);
  };

  const get_report_api = ({ from_date, to_date }) => {
    let keys = [{ key: dynamic_key, loading: true }];
    let query = dynamic_query;
    let variable = {
      filter: {
        from_date,
        to_date,
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  // useEffect(() => {
  //   form.setFieldValue("to_date", "");
  // }, [date.from_date]);
  const on_values_change = () => {
    dispatch(dynamicClear("get_daily_transaction_summary"));
  };
  return (
    <div>
      <Form
        id="search"
        form={form}
        onFinish={handle_finish}
        onValuesChange={on_values_change}
      >
        <Card
          style={{
            width: "96%",
            borderRadius: "5px",
            margin: "auto",
            backgroundColor: "#e7e7e7",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div style={{ marginBottom: "-15px" }}>
            <Row gutter={24}>
              <Col span={11}>
                <DailyTransactionReportFromDate
                  set_date={set_date}
                  set_selected_dates={set_selected_dates}
                />
              </Col>
              <Col span={10}>
                <DailyTransactionReportToDate
                  set_selected_dates={set_selected_dates}
                  set_date={set_date}
                  from_date={date?.from_date}
                />
              </Col>
              <Col span={3}>
                <CustomButton
                  button_name={"Get"}
                  bg_color={"#014477"}
                  rounded={true}
                  is_submit={true}
                />
              </Col>
            </Row>
          </div>
        </Card>
      </Form>
    </div>
  );
};

export default CustomReportSearch;
