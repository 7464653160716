import React, { useEffect, useState } from "react";
import { dynamicRequest, useDynamicSelector } from "../../../../services/redux";
import { useDispatch } from "react-redux";
import { query_get_all_notification_board_list } from "../../../../services/redux/slices/graphql/graphql_notification";

const MarqueeContent = () => {
  const dispatch = useDispatch();
  const [notification_content, set_notification_content] = useState("");

  const { items: notification_items } = useDynamicSelector(
    "get_notification_board_list"
  );

  const get_all_notifications = () => {
    let keys = [{ key: "get_notification_board_list", loading: true }];
    let query = query_get_all_notification_board_list;
    let variable = {};
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    get_all_notifications();
  }, []);

  useEffect(() => {
    if (notification_items) {
      const content = notification_items
        .map((notification) => notification.content)
        .join(",  ");
      set_notification_content(content);
    }
  }, [notification_items]);

  const handle_mouse_enter = () => {
    const marquee = document.getElementById("marquee");
    if (marquee) {
      marquee.stop();
    }
  };

  const handle_mouse_leave = () => {
    const marquee = document.getElementById("marquee");
    if (marquee) {
      marquee.start();
    }
  };

  return (
    <div
      className="marquee-text"
      style={{
        backgroundColor: "#3383af",
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        height: "35px",
        overflow: "hidden",
      }}
    >
      <marquee id="marquee" style={{ width: "100%" }}>
        <h4
          style={{ color: "white", marginTop: "3px" }}
          onMouseEnter={handle_mouse_enter}
          onMouseLeave={handle_mouse_leave}
        >
          {notification_content}
        </h4>
      </marquee>
    </div>
  );
};

export default MarqueeContent;
