import { Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import BookingAvailabilityCard from "../components/booking_availability/booking_availability/booking_availability_card";
import BookingAvailabilityDateslotsCard from "../components/booking_availability/booking_availability_dataslots_card";
import BookingAvailabilityEditModal from "../components/booking_availability/booking_availability/booking_availability_table/booking_availability_modal/booking_availability_edit_modal";
import BookingAvailabilityDeleteModal from "../components/booking_availability/booking_availability/booking_availability_delete_modal/booking_availability_delete_modal";
import moment from "moment";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import {
  change_season_active_status_button,
  delete_season_mutation,
  dynamicClear,
  dynamicRequest,
  get_season_list_query,
  get_season_query,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { navigate } from "../../helpers/functions";
import { ROUTES } from "../routes/my_routes";
import EnableConfirmationModal from "../components/booking_availability/booking_availability/booking_availability_table/season_availability_enable_confirmation_modal";

const Season = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [is_edit_model_visible, set_edit_modal_visible] = useState(false);
  const [header_type, set_header_type] = useState("");
  const [edit_values, set_edit_values] = useState({});
  const [delete_id, set_delete_id] = useState("");
  const [is_delete_modal_visible, set_is_delete_modal_visible] =
    useState(false);
  const [is_enable_modal, set_enable_modal] = useState(false);
  const [enable_record, set_enable_record] = useState("");

  const { items: get_season_list_items, loading } =
    useDynamicSelector("get_season_list");

  // const {} = useDynamicSelector("get_season");
  const { status: delete_season_status, error: delete_season_error } =
    useDynamicSelector("delete_season");

  const {
    status: change_season_active_button_status,
    error: change_season_active_button_error,
  } = useDynamicSelector("change_season_active_status");

  useEffect(() => {
    get_all_season_list();
    // get_one_season_query();
  }, []);

  useEffect(() => {
    if (delete_season_status === "success") {
      toast.success("Season deleted successfully");
      set_is_delete_modal_visible(false);
      get_all_season_list();
      dispatch(dynamicClear("delete_season"));
    } else if (delete_season_error?.message) {
      toast.error(delete_season_error.message);
      get_all_season_list();
      dispatch(dynamicClear("delete_season"));
    }
    if (change_season_active_button_status == "success") {
      toast.success("Status changed successfully");
      get_all_season_list_without_loading();
      dispatch(dynamicClear("change_season_active_status"));
    } else if (change_season_active_button_status?.message) {
      toast.error(change_season_active_button_status.message);
      get_all_season_list_without_loading();
      dispatch(dynamicClear("delete_season"));
    }
  });

  const get_all_season_list = () => {
    let key = [{ key: "get_season_list", loading: true }];
    let query = get_season_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };
  const get_all_season_list_without_loading = () => {
    let key = [{ key: "get_season_list", loading: false }];
    let query = get_season_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  // const get_one_season_query = (values) => {
  //   let key = [{ key: "get_season", loading: true }];
  //   let query = get_season_query;
  //   let variables = {
  //     id: values?.id,
  //   };
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  const delete_season = (id) => {
    let key = [{ key: "delete_season", loading: true }];
    let query = delete_season_mutation;
    let variable = {
      id: delete_id?.id,
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const change_season_active_status = (values, enable_buttons) => {
    let key = [{ key: "change_season_active_status", loading: true }];
    let query = change_season_active_status_button;
    let variables = {
      id: enable_record?.id,
      custom: {
        is_active: !enable_record?.is_active,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_Add = () => {
    set_edit_values({});
    set_header_type("add");
    set_edit_modal_visible(true);
  };
  const handle_edit = (record) => {
    set_header_type("edit");
    // set_edit_values(record);
    set_edit_modal_visible(true);
  };

  const show_delete_model = (record) => {
    set_delete_id(record);
    set_is_delete_modal_visible(true);
  };

  const handle_delete_cancel = () => {
    set_is_delete_modal_visible(false);
    toggle_enable_button;
  };

  const handle_delete_confirm = (record) => {
    delete_season(delete_id);
    // get_area_list();
    set_is_delete_modal_visible(false);
  };
  const handle_enable = (values, enable_buttons) => {
    change_season_active_status(values, enable_buttons);
  };

  const show_enable_modal = (record) => {
    set_enable_record(record);
    set_enable_modal(true);
  };
  const cancel_enable_modal = () => {
    set_enable_modal(false);
  };
  const handle_enable_confirm = (values) => {
    change_season_active_status(values);
    set_enable_modal(false);
    // get_all_season_list();
  };
  const handle_details = (values) => {
    navigate(
      `${ROUTES.BOOKING_AVAILABILITY_DATE_SLOTS}/${values?.id}/${values.title}`
    );
    // get_one_season_query(values);
  };

  const toggle_enable_button = (index) => {
    // const updated_active_buttons = [...enable_buttons];
    // updated_active_buttons[index] = !updated_active_buttons[index];
    // set_enable_buttons(updated_active_buttons);
  };
  const [enable_buttons, set_enable_buttons] = useState();

  return (
    <div>
      <CustomPageTitle title={t("season_title")} />
      <div
        style={{
          height: "400px",
          overflowY: "auto",
          marginTop: "10px",
        }}
      >
        <BookingAvailabilityCard
          handle_Add={handle_Add}
          handle_details={handle_details}
          handle_enable={handle_enable}
          show_delete_model={show_delete_model}
          toggle_enable_button={toggle_enable_button}
          enable_buttons={enable_buttons}
          loading={loading}
          show_enable_modal={show_enable_modal}
        />
      </div>
      <EnableConfirmationModal
        is_enable_modal={is_enable_modal}
        cancel_enable_modal={cancel_enable_modal}
        handle_enable_confirm={handle_enable_confirm}
      />

      <BookingAvailabilityEditModal
        header_type={header_type}
        edit_values={edit_values}
        is_edit_model_visible={is_edit_model_visible}
        set_edit_modal_visible={set_edit_modal_visible}
        get_all_season_list={get_all_season_list}
      />
      <BookingAvailabilityDeleteModal
        is_delete_modal_visible={is_delete_modal_visible}
        handle_delete_confirm={handle_delete_confirm}
        handle_delete_cancel={handle_delete_cancel}
        show_delete_model={show_delete_model}
      />
    </div>
  );
};

export default Season;
