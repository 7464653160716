import { Form, Input } from "antd";
import React from "react";

const BookingPosReceipt = ({ payment_mode, set_pos_receipt_no }) => {
  const on_change = (e) => {
    const val = e.target.value;
    set_pos_receipt_no(val);
  };

  return (
    <Form.Item
      label={
        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
          Card Receipt No
        </span>
      }
      name="pos_receipt"
      rules={
        payment_mode === "card"
          ? [
              {
                required: true,
                message: "Please enter Ref number",
              },
            ]
          : []
      }
      labelCol={{ span: 24 }}
    >
      <Input style={{ width: "100%" }} onChange={on_change} />
    </Form.Item>
  );
};

export default BookingPosReceipt;
