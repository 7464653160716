import { Form, Input, InputNumber } from "antd";
import Password from "antd/lib/input/Password";
import { Box } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";

const IrumudiPortalPasswordFormItem = (props) => {
  const { set_check_mobile_number } = props;
  const { t } = useTranslation();
  const handle_change = (value) => {
    set_check_mobile_number(value);
  };

  return (
    <div>
      <Form.Item
        name="booking_user_mobile"
        rules={[
          {
            required: true,
            message: t("mobile_number"),
          },
          // {
          //   pattern: /^[0-9]{10}$/,
          //   message: "Please enter a valid 10-digit mobile number .",
          // },
        ]}
      >
        <Input
          className="placeholder-black"
          type="tel"
          inputMode="numeric"
          placeholder={t("mobile_number_label")}
          style={{
            backgroundColor: "#FFDE8B",
            color: "#2e291e",
            border: "1px solid black",
          }}
          onChange={(e) => handle_change(e.target.value)}
        />
      </Form.Item>
    </div>
  );
};

export default IrumudiPortalPasswordFormItem;
