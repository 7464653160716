import { Button, Modal } from "antd";
import { Box } from "native-base";
import React, { useState } from "react";
import EndPointForm from "./end_points_form";
import { IoMdClose } from "react-icons/io";

const EndPointsModal = (props) => {
  const {
    link_type,
    link_modal_open,
    handle_link_close,
    id,
    handle_submit,
    action_item,
    action_type,
  } = props;
  const [loading, set_loading] = useState(false);

  return (
    <Box>
      <Modal
        width={500}
        open={link_modal_open}
        onCancel={handle_link_close}
        title={
          link_type === "link_component"
            ? "Link Endpoint To Page Component"
            : ""
        }
        footer={
          <>
            <Button onClick={handle_link_close}>Cancel</Button>
            <Button
              form="link_form"
              htmlType="submit"
              onClick={handle_submit}
              loading={loading}
            >
              Link
            </Button>
          </>
        }
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
      >
        <EndPointForm
          get_id={id}
          link_type={link_type}
          set_loading={set_loading}
          action_item={action_item}
          close={handle_link_close}
          action_type={action_type}
        />
      </Modal>
    </Box>
  );
};
export default EndPointsModal;
