import React from "react";
import { Modal, Button } from "antd";
import { Text } from "native-base";
import { useDynamicSelector } from "../../../../../services/redux";
import { IoMdClose } from "react-icons/io";

const FeedbackManageDeleteModal = (props) => {
  const {
    is_delete_modal_visible,
    handle_delete_cancel,
    handle_delete_confirm,
    show_delete_model,
  } = props;
  const { loading: delete_feedback_question_loading } = useDynamicSelector(
    "delete_feedback_question"
  );

  return (
    <Modal
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      open={is_delete_modal_visible}
      title="Confirmation"
      onCancel={handle_delete_cancel}
      footer={[
        <Button key="cancel" onClick={handle_delete_cancel}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          onClick={() => handle_delete_confirm()}
          loading={delete_feedback_question_loading}
        >
          Delete
        </Button>,
      ]}
    >
      <Text>Do you really want to delete this?</Text>
    </Modal>
  );
};

export default FeedbackManageDeleteModal;
