import { Form } from "antd";
import React, { useEffect, useState } from "react";
import ExpandableOptionFormItem from "./expandable_option_form_item";
import {
  create_feedback_question_option_mutation,
  dynamicClear,
  dynamicRequest,
  update_feedback_question_option_mutation,
  useDynamicSelector,
} from "../../../../../../services/redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FeedbackQuestionOptionSwitchButton from "./expandable_switch_button_form_item";

const FeedbackManageExpandableForm = (props) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const {
    header_type,
    set_edit_modal_visible,
    edit_values,
    item,
    options,
    data,
  } = props;
  const [initial_values, set_initial_values] = useState({});
  const [is_checked, set_is_checked] = useState(false);

  useEffect(() => {
    if (edit_values.id) {
      form.setFieldsValue({
        ...edit_values,
      });
      set_initial_values({ ...edit_values });
    } else {
      form.resetFields();
    }
  }, [edit_values]);

  const create_feedback_option = (values) => {
    let key = [{ key: "create_feedback_question_option", loading: true }];
    let query = create_feedback_question_option_mutation;
    let variable = {
      data: {
        option: values?.option,
        feedback_question_id: data?.id,
      },
    };
    1;

    dispatch(dynamicRequest(key, query, variable));
  };

  const update_feedback_option = (edit_id, values) => {
    let key = [{ key: "update_feedback_question_option", loading: true }];
    let query = update_feedback_question_option_mutation;
    let variable = {
      id: edit_id,
      data: {
        option: values.option,
        is_active: true,
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const handle_finish = (values) => {
    if (header_type === "add") {
      create_feedback_option(values);
    } else if (header_type === "edit") {
      let edit_id = edit_values?.id;
      update_feedback_option(edit_id, values);
    }
  };
  return (
    <div>
      <Form
        id="expandable_form"
        onFinish={handle_finish}
        initialValues={initial_values}
        form={form}
      >
        <ExpandableOptionFormItem />
        {/* {header_type !== "edit" && (
          <FeedbackQuestionOptionSwitchButton
            set_is_checked={set_is_checked}
            is_checked={is_checked}
            header_type={header_type}
          />
        )} */}
      </Form>
    </div>
  );
};

export default FeedbackManageExpandableForm;
