import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";

import _orderBy from "lodash/orderBy";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../../services/redux";
import { sort_date_slots } from "../../../../../helpers/functions";

const AddressReportToDate = (props) => {
  const { set_selected_date } = props;
  const dispatch = useDispatch();
  const [sorted_items, set_sorted_items] = useState([]);

  const { items: date_slot_items } = useDynamicSelector(
    "get_season_date_slot_list"
  );

  const date_list = () => {
    let keys = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variable = {
      filter: {},
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  const handle_change = (value, option) => {
    set_selected_date(option?.children);
  };

  useEffect(() => {
    const sorted = sort_date_slots(date_slot_items);
    set_sorted_items(sorted);
  }, [date_slot_items]);

  useEffect(() => {
    date_list();
  }, []);

  return (
    <div>
      <Form.Item
        name="to_date"
        label="To Date"
        rules={[{ required: true, message: "Please select To Date" }]}
        allowClear={true}
      >
        <Select
          placeholder="Select From Date"
          allowClear={true}
          onChange={handle_change}
        >
          {sorted_items?.map((date, index) => (
            <Select.Option key={index} value={date?.id} label={date?.id}>
              {date.date_string}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default AddressReportToDate;
