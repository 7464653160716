import { Table } from "antd";
import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { useDynamicSelector } from "../../../../services/redux";

const SubDistrictAdminCard = ({ items, set_open, set_header_type, record }) => {
  const { admin, loading: get_admin_user_loading } = useDynamicSelector(
    "get_sub_district_user"
  );
  const columns = [
    {
      title: "Name",
      key: "name",
      width: "400px",
      render: (data) => {
        return data?.name;
      },
    },
    {
      title: "Username",
      key: "username",
      width: "400px",
      render: (data) => {
        return data?.system_user?.username;
      },
    },
    {
      title: "Contact Number",
      dataIndex: "mobile",
      key: "mobile",
      width: "400px",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "400px",
      align: "left",
      render: (text, record) => <div style={{ textAlign: "left" }}>{text}</div>,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "400px",
      render: (text, record) => (
        <span>
          <EditOutlined onClick={() => handle_edit(record)} />
        </span>
      ),
    },
  ];

  const handle_edit = (record) => {
    set_header_type("edit_admin");
    set_open(true);
  };
  return (
    <Table
      columns={columns}
      loading={get_admin_user_loading}
      style={{ width: "100%", backgroundColor: "red !important" }}
      dataSource={admin ? [admin] : []}
      pagination={false}
      className="admin-card-table"
    />
  );
};

export default SubDistrictAdminCard;
