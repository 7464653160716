import { Box, Text, VStack } from "native-base";
import React from "react";
import IrumudiPortalForm from "./irumudi_portal_form/irumudi_portal_main_form";
import { Col, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";

const IrumudiPortal = ({handle_back_click}) => {
  const { t } = useTranslation();
  return (
    <div className="login_form_container">
      <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
        <Typography
          style={{
            width: "100%",
            color: "",
            marginTop: "15px",
            fontWeight: "bold",
            fontSize: "15px",
            display: "flex",
            justifyContent: "center",
            padding:"10px"
          }}
        >
          {t("irumudi_receipt_title")}
        </Typography>
      </div>
      <Row
        style={{
          width: "100%",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0 0 10px 10px",
        }}
      >
        <IrumudiPortalForm handle_back_click={handle_back_click} />
      </Row>
    </div>
  );
};

export default IrumudiPortal;
