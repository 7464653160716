import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CountDetailCard from "../irumudi_calendar/detail_cards/count_detail_card";
import { dynamicRequest, useDynamicSelector } from "../../../services/redux";
import { useTranslation } from "react-i18next";
import { query_calendar_dashboard } from "../../../services/redux/slices/graphql/graphql_irumudi_calendar";
import { useDispatch } from "react-redux";
import RefreshButton from "./refresh_button";
import { retrieveItem } from "../../../helpers/functions";

const UserDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: irumudi_calendar_items, loading: irumudi_items_loading } =
    useDynamicSelector("get_dashboard_calendar");
  const role = retrieveItem("role");
  console.log(role, "fdfdf");
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const get_calendar_data = () => {
    let keys = [{ key: "get_dashboard_calendar", loading: true }];
    let query = query_calendar_dashboard;
    let variable = {};
    dispatch(dynamicRequest(keys, query, variable));
  };

  useEffect(() => {
    get_calendar_data();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      get_calendar_data();
    }, 300000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const on_refresh = () => {
    get_calendar_data();
  };
  return (
    <Row gutter={24} style={{ height: "100%" }}>
      {role === "Dashboard User" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "70%",
            margin: "auto",
          }}
        >
          <Typography style={{ color: "#909090" }}>
            {t("dashboard_text")}
          </Typography>
          <div style={{ marginLeft: "20px" }}>
            <RefreshButton
              dashboard_loading={irumudi_items_loading}
              get_dashboard={on_refresh}
            />
          </div>
        </div>
      )}

      {isMobile ? (
        <>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <CountDetailCard
              day_type={"yesterday"}
              bg_color={"#d4380d"}
              title={t("yesterday")}
              items={irumudi_calendar_items}
              loading={irumudi_items_loading}
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CountDetailCard
              bg_color={"#1c8eff"}
              title={t("today")}
              day_type={"today"}
              items={irumudi_calendar_items}
              loading={irumudi_items_loading}
            />
          </Col>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <CountDetailCard
              bg_color={"#fc9a12"}
              title={t("tomorrow")}
              day_type={"tomorrow"}
              items={irumudi_calendar_items}
              loading={irumudi_items_loading}
            />
          </Col>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "95%" }}>
                <CountDetailCard
                  day_type={"yesterday"}
                  bg_color={"#d4380d"}
                  title={t("yesterday")}
                  items={irumudi_calendar_items}
                  loading={irumudi_items_loading}
                />
              </div>
            </Col>
            <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "95%" }}>
                <CountDetailCard
                  bg_color={"#1c8eff"}
                  title={t("today")}
                  day_type={"today"}
                  items={irumudi_calendar_items}
                  loading={irumudi_items_loading}
                />
              </div>
            </Col>

            <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "95%" }}>
                <CountDetailCard
                  bg_color={"#fc9a12"}
                  title={t("tomorrow")}
                  day_type={"tomorrow"}
                  items={irumudi_calendar_items}
                  loading={irumudi_items_loading}
                />
              </div>
            </Col>
          </div>
        </>
      )}
    </Row>
  );
};

export default UserDashboard;
