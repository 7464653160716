import { gql } from "@apollo/client";

export const mutation_create_spot_booking = gql`
  mutation create_spot_booking($data: create_spot_booking_input) {
    create_spot_booking(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
