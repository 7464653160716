import React, { useEffect } from "react";
import { clearLocalStorage, navigate } from "../../helpers/functions";

const Logout = () => {
  useEffect(() => {
    clearLocalStorage();
    navigate("/login");
  }, []);
  return <div flex={1}></div>;
};
export default Logout;
