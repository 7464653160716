import React from "react";

import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import InputSubDistrictName from "../sub_district_form_inputs/input_sub_district_name";
import InputSubDistrictDistrictCode from "../sub_district_form_inputs/input_sub_district_code";
import InputSubDistrictExtraAllowedPercentage from "../sub_district_form_inputs/input_sub_district_extra_allowed_percentage";
import InputSubDistrictIsForeign from "../sub_district_form_inputs/input_sub_district_admin_is_foreign";
import InputSubDistrictTamilName from "../sub_district_form_inputs/input_sub_district_tamil_name";
import InputSubDistrictGroupName from "../sub_district_form_inputs/input_sub_district_group";
import InputSubDistrictIsVIP from "../sub_district_form_inputs/input_sub_district_vip";
import InputDistrictForm from "../sub_district_form_inputs/input_district_form";
const SubDistrict = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Col
        style={{
          padding: "10px",
          borderRadius: "6px",
          width: "100%",
          backgroundColor: "rgba(211, 211, 211, 0.2)",
          marginTop: "10px",
        }}
      >
        <h3 style={{ marginLeft: "12px" }}>{t("sub_district")}</h3>
        <Row gutter={16} justify={"space-between"}>
          <Col span={12}>
            <InputSubDistrictName />
            <InputSubDistrictDistrictCode />
            <InputDistrictForm />
            <InputSubDistrictExtraAllowedPercentage />
          </Col>
          <Col span={11}>
            <InputSubDistrictTamilName />
            {/* <InputSubDistrictGroupName /> */}
            <InputSubDistrictIsForeign {...props} />
            <InputSubDistrictIsVIP {...props} />
          </Col>
        </Row>
      </Col>
    </>
  );
};
export default SubDistrict;
