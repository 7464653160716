import { Form, Input } from "antd";
import React from "react";

const EmailId = () => {
  return (
    <Form.Item
      name={["mandram_admin", "email"]}
      label="Email Id"
      rules={[
        {
          type: "email",
          message: "Email address must contain '@'",
        },
        // {
        //   validator: (rule, value) => {
        //     if (value && !value.includes("@")) {
        //       return Promise.reject("Email address must contain '@'");
        //     }
        //     return Promise.resolve();
        //   },
        // },
      ]}
    >
      <Input />
    </Form.Item>
  );
};

export default EmailId;
