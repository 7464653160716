import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";
import SakthiPeedamReportForm from "../components/system_report/sakthi_peedam_report/sakthi_peedam_report_form/shakthi_peedam_report_form";
import CustomBreadCrumbs from "../components/common/custom_breadcrumbs";
import SakthiPeedamReportDetails from "../components/system_report/sakthi_peedam_report/sakthi_peedam_report_details/sakthi_peedam_report_details";
import { useDispatch } from "react-redux";
import {
  sakthi_peedam_report_dates_query,
  sakthi_peedam_report_query,
} from "../../services/redux/slices/graphql/graphql_sakthi_peedam_report";
import {
  dynamicClear,
  dynamicRequest,
  mandram_list_query,
  query_get_all_mandram_list,
  useDynamicSelector,
} from "../../services/redux";
import SakthiPeedamReportTable from "../components/system_report/sakthi_peedam_report/sakthi_peedam_report_table";
import { toast } from "react-toastify";
import SakthiPeedamReportModal from "../components/system_report/sakthi_peedam_report/sakthi_peedam_report_modal/sakthi_peedam_report_modal";
import CustomButton from "../components/common/custom_button";
import { useReactToPrint } from "react-to-print";
import { export_to_xlsx } from "../../helpers/functions";
import LoaderSpinner from "../components/common/custom_spinner";

const SakthiPeedamReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [modal_visible, set_modal_visible] = useState(false);
  const [from_date, set_from_date] = useState();
  const [to_date, set_to_date] = useState();

  const {
    items: sakthi_peedam_reports,
    loading: sakthi_peedam_reports_loading,
  } = useDynamicSelector("get_sakthi_peedam");

  const total_devotees = sakthi_peedam_reports?.reduce(
    (acc, item) => acc + (item.devotee_count || 0),
    0
  );

  const get_dates_for_sakthi_peedam_report = () => {
    let key = [{ key: "sakthi_peedam_date", loading: true }];
    let query = sakthi_peedam_report_dates_query;
    dispatch(dynamicRequest(key, query));
  };

  const get_mandrams_for_sakthi_peedam_report = () => {
    let key = [{ key: "get_mandrams", loading: true }];
    let query = query_get_all_mandram_list;
    let variable = {
      sort: {
        field: "internal_code",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variable));
  };

  const get_sakthi_peedam_report = (values) => {
    let key = [{ key: "get_sakthi_peedam", loading: true }];
    let query = sakthi_peedam_report_query;
    let variables = {
      filter: {
        from_date: values.from_date,
        to_date: values.to_date,
        mandram_id: values.mandram,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const arrange_xlsx_day_wise_report_data = (data_items) => {
    const rearranged_data = [];
    let devotees_total = 0;

    if (data_items && data_items.length) {
      for (let i = 0; i < data_items.length; i++) {
        let row_data = {
          ["S No"]: i + 1,
          ["Code"]: data_items[i]?.sakthi_peedam_code || "-",
          ["Sakthi Peedam"]: data_items[i]?.sakthi_peedam_name || "-",
          ["Devotees"]: data_items[i]?.devotee_count || "-",
        };
        rearranged_data.push(row_data);
        devotees_total += parseFloat(data_items[i]?.devotee_count) || 0;
      }
    }

    rearranged_data.push({
      ["S No"]: "Total",
      ["Devotees"]: devotees_total,
    });

    return rearranged_data;
  };

  const on_xlsx_download = () => {
    const arranged_data = arrange_xlsx_day_wise_report_data(
      sakthi_peedam_reports
    );
    export_to_xlsx(arranged_data, "sakthi_peedam_details");
  };

  // const on_print = () => {
  //   set_modal_visible(true);
  // };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const on_print = () => {
    handlePrint();
  };

  useEffect(() => {
    get_dates_for_sakthi_peedam_report();
    // dispatch(dynamicClear("get_sakthi_peedam"));
  }, []);
  useEffect(() => {
    get_mandrams_for_sakthi_peedam_report();
  }, []);

  return (
    <Col>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Row style={{ marginLeft: "10px" }}>
          <CustomPageTitle title={t("sakthi_peedam_reports")} />
        </Row>

        <Row style={{ margin: "8px", marginRight: "23px" }}></Row>
      </Row>
      <div style={{ marginTop: "20px" }}>
        <SakthiPeedamReportForm
          set_from_date={set_from_date}
          set_to_date={set_to_date}
          get_sakthi_peedam_report={get_sakthi_peedam_report}
        />
      </div>

      {sakthi_peedam_reports_loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <LoaderSpinner />
        </div>
      )}

      <div style={{ marginTop: "20px" }}>
        {sakthi_peedam_reports?.length > 0 ? (
          <Row
            style={{
              width: "96%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <CustomButton
              button_name={t("print")}
              bg_color={"#1e2c55"}
              rounded={true}
              // icon={<AiFillPrinter />}
              onclick_button={on_print}
              id="sakthi_peedam_print_button"
              disabled={sakthi_peedam_reports?.length > 0 ? false : true}
            />
            <CustomButton
              button_name={t("download")}
              bg_color={"#185c37"}
              rounded={true}
              // icon={<FileExcelOutlined />}
              onclick_button={on_xlsx_download}
              disabled={sakthi_peedam_reports?.length > 0 ? false : true}
            />
          </Row>
        ) : null}
      </div>

      {sakthi_peedam_reports?.length > 0 ? (
        <div ref={componentRef} style={{ marginTop: "20px" }}>
          <SakthiPeedamReportTable to_date={to_date} from_date={from_date} />
        </div>
      ) : null}
    </Col>
  );
};

export default SakthiPeedamReport;
