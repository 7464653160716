import React, { useState } from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const BookingPasswordFormItem = () => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  return (
    <div>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: t("password_required"),
          },
          {
            max: 30,
            message: "Password must be at most 30 characters",
          },
        ]}
      >
        <Input
          type={passwordVisible ? "text" : "password"}
          placeholder="Password"
          className="password-input"
          style={{
            border: "1px solid black",
            backgroundColor: "#ffde8b",
          }}
          suffix={
            <span
              style={{
                position: "absolute",
                top: "50%",
                right: "8px",
                transform: "translateY(-50%)",
                zIndex: 100,
              }}
              onClick={togglePasswordVisibility}
            >
              {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            </span>
          }
        />
      </Form.Item>
    </div>
  );
};

export default BookingPasswordFormItem;
