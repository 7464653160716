import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Form, Select } from "antd";
import { retrieveItem } from "../../../../helpers/functions";
import { dynamicRequest, useDynamicSelector } from "../../../../services/redux";
import { query_get_sub_district_filter } from "../../../../services/redux/slices/graphql/graphql_district";
import { useTranslation } from "react-i18next";
import Title from "antd/lib/typography/Title";

const SubDistrictFilter = ({
  set_sub_district_id,
  header_type,
  get_district_id,
}) => {
  const dispatch = useDispatch();
  const role = retrieveItem("role");
  const district_name = retrieveItem("district_name");
  const district_id = retrieveItem("district_id");
  const { t } = useTranslation();
  const { items: sub_district_items } = useDynamicSelector(
    "get_sub_district_filter"
  );

  const handleSelectDistrict = (value) => {
    set_sub_district_id(value);
  };

  const filterOption = (input, option) =>
    option.props.children.toLowerCase().startsWith(input.toLowerCase());

  const getSubDistrictDetails = () => {
    let key = [{ key: "get_sub_district_filter", loading: true }];
    let query = query_get_sub_district_filter;
    let variables = {
      data: {
        filter: {
          district_id: get_district_id,
        },
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    getSubDistrictDetails();
  }, [get_district_id]);

  const isAdmin = role === "Super Admin" || role === "MASM Admin";
  const isEditable = !(header_type === "Edit Mandram");

  return (
    <div style={{ display: "flex" }}>
      <Title level={5} style={{ width: "100px" }}>
        {t("sub_district")}  :
      </Title >
      <Select
      style={{width:"200px"}}
        allowClear
        showSearch={isAdmin}
        onChange={handleSelectDistrict}
        filterOption={isAdmin ? filterOption : undefined}
        defaultValue={isAdmin ? undefined : district_id}
        disabled={!isAdmin && !isEditable}
      >
        {isAdmin ? (
          sub_district_items?.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))
        ) : (
          <Select.Option key={district_id} value={district_id}>
            {district_name}
          </Select.Option>
        )}
      </Select>
    </div>
  );
};

export default SubDistrictFilter;
