import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

const NoOfDevotees = (props) => {
  const {
    set_no_of_devotees,
    no_of_devotees,
    set_total_amount,
    price,
    arrived_count,
    allowed_devotee,
    set_visible,
    excess_devotee_count,
  } = props;

  console.log("excess_devotee_count", excess_devotee_count);

  useEffect(() => {
    if (no_of_devotees) {
      const sum = parseInt(no_of_devotees, 10) + arrived_count;
      if (sum > allowed_devotee) {
        toast.error("Devotees count Exceed");
        set_visible(false);
        return;
      }
      if (sum < allowed_devotee) {
        set_visible(true);
        return;
      }
      if (excess_devotee_count === 0) {
        if (sum >= 1) {
          set_visible(true);
        } else {
          toast.error("Devotees count Exceed");
          set_visible(false);
        }
      }
      return;
    }
  }, [no_of_devotees, arrived_count, allowed_devotee, excess_devotee_count]);

  const on_change = (e) => {
    const value = e.target.value;
    set_no_of_devotees(value);
    const total = price * value;
    set_total_amount(total);
  };

  const on_key_press = (e) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };
  return (
    <Form.Item
      label={
        <span style={{ fontWeight: "bold", fontSize: "16px" }}>
          Arrived Devotees Count
        </span>
      }
      name="no_of_devotees"
      rules={[
        {
          required: true,
          message: "Please enter the number of devotees!",
        },
      ]}
      labelCol={{ span: 24 }}
    >
      <Input
        style={{ width: "80%", border: "1px solid #b0b0b0" }}
        type="Number"
        autoFocus
        onChange={on_change}
        onKeyPress={on_key_press}
        min={1}
      />
    </Form.Item>
  );
};

export default NoOfDevotees;
