import React from "react";
import { useDynamicSelector } from "../../../../services/redux";
import { Col, Skeleton, Typography } from "antd";

const DistrictDashboardOverallCounTable = ({ label, value, hide_border }) => {
  const { loading: booking_summary_loading } = useDynamicSelector(
    "get_date_district_mandram_wise_summary"
  );

  return (
    <Col
      style={{
        width: "33%",
        height: "100%",
        fontSize: "20px",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRight: hide_border ? "0px" : "1px solid white",
        backgroundColor: "#025A80",
      }}
    >
      <Typography style={{ textAlign: "center", color: "white" }}>
        {label}
      </Typography>
      <Typography style={{ color: "white", fontWeight: "bold" }}>
        {booking_summary_loading && (
          <Skeleton.Button active={true} size={"small"} shape={"rounded"} />
        )}
        {!booking_summary_loading && value}
      </Typography>
    </Col>
  );
};

export default DistrictDashboardOverallCounTable;
