import { Button, Row, Select, Spin, Typography } from "antd";
import React, { useEffect, useState, useRef } from "react";
import CustomButton from "../../../common/custom_button";
import GroupWiseReportList from "../group_wise_report_list/group_wise_report_list";
import { AiFillPrinter } from "react-icons/ai";
import { FileExcelOutlined } from "@ant-design/icons";
import PrintGroupWiseReport from "../print_group_wise_report/print_group_wise_report";
import GroupWiseReportModal from "../group_wise_report_modal/group_wise_report_modal";
import { useDynamicSelector } from "../../../../../services/redux";
import {
  arrange_xlsx_report_data,
  export_to_xlsx,
} from "../../../../../helpers/functions";
import { useReactToPrint } from "react-to-print";

const GroupWiseReportDetails = (props) => {
  const { report_date, get_all_group_wise_list, yesterday_date } = props;
  const [visible, set_visible] = useState(false);
  const [loading, set_loading] = useState(false);
  const [selected_language, set_selected_language] = useState("Tamil");

  const { items: group_wise_report_list } = useDynamicSelector(
    "get_district_group_wise_count"
  );

  useEffect(() => {
    if (selected_language) {
      get_all_group_wise_list();
    }
  }, [selected_language]);

  const language = [
    { index: 1, value: "Tamil" },
    { index: 2, value: "English" },
  ];

  const handle_select_language = (value) => {
    set_selected_language(value);
  };
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const on_print = () => {
    handlePrint();
  };

  // const print_group_wise_report = async () => {
  //   //   const printWindow = window.open("", "", "width=600,height=600");
  //   //   const district_group_wise_print_html_content = ` <html>
  //   //   <html>
  //   //   <head>
  //   //     <style>
  //   //       body {
  //   //         font-family: TamilFont, sans-serif;
  //   //         margin: 20px;
  //   //       }
  //   //       h3 {
  //   //         text-align: center;
  //   //         color: #333;
  //   //       }
  //   //       table {
  //   //         width: 100%;
  //   //         border-collapse: collapse;
  //   //         margin-top: 20px;
  //   //       }
  //   //       th, td {
  //   //         border: 1px solid #ddd;
  //   //         padding: 8px;
  //   //         text-align: center;
  //   //       }
  //   //     </style>
  //   //   </head>
  //   //   <body>
  //   //     ${document.getElementById("group-wise-report screen-table").innerHTML}
  //   //   </body>
  //   // </html>
  //   //   `;
  //   //   printWindow.document.write(district_group_wise_print_html_content);
  //   //   printWindow.document.close();

  //   //   setTimeout(() => {
  //   //     printWindow.print();
  //   //     printWindow.close();
  //   //   }, 1000);
  //   try {
  //     set_loading(true);
  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //   } finally {
  //     set_loading(false);
  //   }
  //   handlePrint();
  // };

  const arrange_xlsx_report_data = (data_items) => {
    const rearranged_data = [];
    let grand_total = 0;
    let till_date_devotees = 0;
    let selected_date_devotees = 0;
    let total_devotees = 0;

    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["District Group Name"]: data_items[i]?.district_group_name ?? "-",
        ["District Group tamil Name"]:
          data_items[i]?.district_group_tamil_name ?? "-",
        ["Yesterday Count"]: data_items[i]?.till_date_devotees ?? "-",
        ["Today Count"]: data_items[i]?.selected_date_devotees ?? "-",
        ["Total Count"]: data_items[i]?.total_devotees ?? "-",
      };

      rearranged_data.push(row_data);

      till_date_devotees += parseFloat(data_items[i]?.till_date_devotees) || 0;
      selected_date_devotees +=
        parseFloat(data_items[i]?.selected_date_devotees) || 0;
      total_devotees += parseFloat(data_items[i]?.total_devotees) || 0;
    }

    // Add Grand Total Row
    rearranged_data.push({
      ["S.No"]: "Grand Total",
      ["Yesterday Count"]: till_date_devotees,
      ["Today Count"]: selected_date_devotees,
      ["Total Count"]: total_devotees,
    });

    return rearranged_data;
  };

  const on_xlsx_download = () => {
    const arranged_data = arrange_xlsx_report_data(group_wise_report_list);
    export_to_xlsx(arranged_data, "group-wise-report");
  };

  return (
    <>
      {group_wise_report_list?.length ? (
        <div>
          <Row
            style={{
              width: "96%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <text>Language</text>
            <Select
              style={{ width: "10%" }}
              allowClear
              onChange={handle_select_language}
              defaultValue={selected_language ? selected_language : "Tamil"}
            >
              {language.map((option) => (
                <Select.Option key={option?.value} value={option?.value}>
                  {option?.value}
                </Select.Option>
              ))}
            </Select>
            <CustomButton
              button_name={"Print"}
              bg_color={"#1e2c55"}
              rounded={true}
              loading={loading}
              onclick_button={on_print}
              disabled={group_wise_report_list?.length > 0 ? false : true}
            />
            <CustomButton
              button_name={"Download"}
              bg_color={"#185c37"}
              rounded={true}
              onclick_button={on_xlsx_download}
              disabled={group_wise_report_list?.length > 0 ? false : true}
            />
          </Row>

          {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography>ஓம் சக்தி</Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography>ஆதி பராசக்தி சித்தர்பீடம்,</Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography>மேல்மருவத்தூர்(2023-2024)</Typography>
      </div> */}
          <div style={{ marginTop: "20px" }} ref={componentRef}>
            <GroupWiseReportList
              report_date={report_date}
              selected_language={selected_language}
              yesterday_date={yesterday_date}
            />
          </div>

          <GroupWiseReportModal
            visible={visible}
            set_visible={set_visible}
            report_date={report_date}
          />
        </div>
      ) : null}
    </>
  );
};

export default GroupWiseReportDetails;
