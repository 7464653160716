import { Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useTranslation } from "react-i18next";

const Address = () => {
  const { t } = useTranslation();
  return (
    <Form.Item label={t("address")} name="address">
      <TextArea
        rows={4}
        style={{
          width: "100%",
        }}
        placeholder="Full Postal Address"
      />
    </Form.Item>
  );
};

export default Address;
