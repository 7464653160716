import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomPageTitle from "../components/common/custom_page_title";
import { Card, Col, Row } from "antd";
import CustomButton from "../components/common/custom_button";
import DevoteePaymentReportList from "../components/system_report/devotee_payment_report/devotee_payment_report_list/devotee_payment_report_list";
import { dynamicRequest, useDynamicSelector } from "../../services/redux";
import { devotee_payment_report_query } from "../../services/redux/slices/graphql/graphql_devotee_payment_report";
import { useDispatch } from "react-redux";
import { export_to_xlsx, print_function } from "../../helpers/functions";
import { AiFillPrinter } from "react-icons/ai";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import * as XLSX from "xlsx";

const DevoteePaymentReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const excelTableRef = useRef(null);
  const [show_print_button, set_show_print_button] = useState(false);
  const { items: devotee_payment_report_items } = useDynamicSelector(
    "get_devotee_payment"
  );

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const on_print = () => {
    handlePrint();
  };

  // const handleDownload = () => {
  //   if (!devotee_payment_report_items) return;

  //   const ws = XLSX.utils.table_to_sheet(excelTableRef.current);
  //   // const ws = XLSX.utils.table_to_sheet(componentRef.current);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "DevoteePaymentReport");
  //   XLSX.writeFile(wb, "DevoteePaymentReport.xlsx");
  // };

  const base_total_devotees = devotee_payment_report_items
    ? devotee_payment_report_items.reduce(
        (sum, record) => sum + (record.devotee_count || 0),
        0
      )
    : 0;

  const base_total_amount = devotee_payment_report_items
    ? devotee_payment_report_items.reduce(
        (sum, record) => sum + (record.devotee_amount || 0),
        0
      )
    : 0;
  const vip_total_devotees = devotee_payment_report_items
    ? devotee_payment_report_items.reduce(
        (sum, record) => sum + (record.vip_devotee_count || 0),
        0
      )
    : 0;

  const vip_total_amount = devotee_payment_report_items
    ? devotee_payment_report_items.reduce(
        (sum, record) => sum + (record.vip_devotee_amount || 0),
        0
      )
    : 0;

  const total_devotees = devotee_payment_report_items
    ? devotee_payment_report_items.reduce(
        (sum, record) =>
          sum + (record.devotee_count || 0) + (record.vip_devotee_count || 0),
        0
      )
    : 0;

  const total_amount = devotee_payment_report_items
    ? devotee_payment_report_items
        .reduce(
          (sum, record) =>
            sum +
            (record.devotee_amount || 0) +
            (record.vip_devotee_amount || 0),
          0
        )
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })
    : 0;

  const arrange_xlsx_devotees_payment_report_data = (data_items) => {
    const rearranged_data = [];

    if (data_items && data_items.length) {
      for (let i = 0; i < data_items.length; i++) {
        let row_data = {
          ["S No"]: i + 1,
          ["Date"]: moment(data_items[i]?.date).format("DD-MM-YYY") || "-",
          ["Total Devotees/ ₹350"]: data_items[i]?.devotee_count || "-",
          ["Total Amount/ ₹350"]: data_items[i]?.devotee_amount || "-",

          ["Total Devotees/ ₹1000"]: data_items[i]?.vip_devotee_count || "-",

          ["Total Amount/ ₹1000"]: data_items[i]?.vip_devotee_amount || "-",
          ["NET Total Devotees"]:
            data_items[i]?.devotee_count + data_items[i]?.vip_devotee_count ||
            "-",
          ["Net Total Amount"]:
            data_items[i]?.devotee_amount + data_items[i]?.vip_devotee_amount ||
            "-",
        };
        rearranged_data.push(row_data);
      }
    }

    rearranged_data.push({
      ["Date"]: "Total",
      ["Total Devotees/ ₹350"]: base_total_devotees,
      ["Total Amount/ ₹350"]: base_total_amount,
      ["Total Devotees/ ₹1000"]: vip_total_devotees,
      ["Total Amount/ ₹1000"]: vip_total_amount,
      ["NET Total Devotees"]: total_devotees,
      ["Net Total Amount"]: total_amount,
    });

    return rearranged_data;
  };

  const on_xlsx_download = () => {
    const arranged_data = arrange_xlsx_devotees_payment_report_data(
      devotee_payment_report_items
    );
    export_to_xlsx(arranged_data, "payment_mode_details");
  };

  const get_devotee_payment_report = () => {
    let key = [{ key: "get_devotee_payment", loading: true }];
    let query = devotee_payment_report_query;
    dispatch(dynamicRequest(key, query));
  };

  useEffect(() => {
    get_devotee_payment_report();
  }, []);
  return (
    <Col>
      <Row style={{ marginLeft: "10px" }}>
        <CustomPageTitle title={t("devotee_payment_mode_report_title")} />
      </Row>
      <Card
        style={{
          width: "96%",
          margin: "auto",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            button_name={"Print"}
            id="devotee_payment_print"
            bg_color={"#1e2c55"}
            rounded={true}
            onclick_button={on_print}
            disabled={devotee_payment_report_items?.length > 0 ? false : true}
          />
          <CustomButton
            button_name={t("download")}
            bg_color={"#185c37"}
            rounded={true}
            onclick_button={on_xlsx_download}
            disabled={devotee_payment_report_items?.length > 0 ? false : true}
          />
        </div>
        <div ref={componentRef}>
          <DevoteePaymentReportList
            excel_ref={excelTableRef}
            className="table table-bordered print-table"
          />
        </div>
      </Card>
    </Col>
  );
};

export default DevoteePaymentReport;
