import { gql } from "@apollo/client";

export const season_date_slots_list = gql`
  query get_season_date_slot_list($sort: sort_input) {
    get_season_date_slot_list(sort: $sort) {
      items {
        id
        date
        date_string
      }
    }
  }
`;

export const query_daily_transactions_report = gql`
  query get_daily_transaction_summary(
    $filter: daily_transaction_summary_input
  ) {
    get_daily_transaction_summary(filter: $filter) {
      items {
        date
        mandram_name
        mandram_code
        devotees
        amount
        receipt_number
        booking_reference
        payment_mode
        receipt_number
        leader_name
        leader_number
      }
    }
  }
`;
