import { Form, Radio, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { district_details_query } from "../../../../services/redux/slices/graphql/graphql_district";
import { dynamicRequest, useDynamicSelector } from "../../../../services/redux";
import { useDispatch } from "react-redux";

const { Option } = Select;

const VipBookingUserTypeDistrict = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: districts } = useDynamicSelector("district_list");
  console.log("districts", districts);

  const handle_type_change = (e) => {
    get_district_list(e.target.value);
  };

  const get_district_list = (booking_type) => {
    let key = [{ key: "district_list", loading: true }];
    let query = district_details_query;
    let variables = {
      filter: {
        is_foreign: booking_type === "vip" ? false : true,
      },
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <div className="vip_booking_form_item_container">
      <div className="vip_booking_form_item_wrapper">
        <Form.Item
          name="booking_type"
          label={t("Booking Type")}
          rules={[
            { required: true, message: t("Please select a booking type") },
          ]}
        >
          <Radio.Group onChange={handle_type_change}>
            <Radio value="vip">{t("VIP")}</Radio>
            <Radio value="foreign">{t("Foreign")}</Radio>
          </Radio.Group>
        </Form.Item>
      </div>

      <div className="vip_booking_form_item_wrapper">
        <Form.Item
          name="district"
          label={t("District")}
          rules={[
            { required: true, message: t("Please select your district") },
          ]}
        >
          <Select placeholder={t("Select district")}>
            {districts?.map((district) => (
              <Select.Option key={district?.id} value={district?.id}>
                {district?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </div>
  );
};

export default VipBookingUserTypeDistrict;
