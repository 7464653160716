import { Button, Form, Spin } from "antd";
import React, { useEffect, useState } from "react";
import ProfileNameFormItem from "./update_profile_name_form_item";
import ProfileEmailIdFormItem from "./update_profile_email_id_form_item";
import ProfileMobileNUmberFormItem from "./update_profile_mobile_number_form_item";
import UpdateProfileButton from "./update_profile_button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  query_update_profile,
  query_user,
  useDynamicSelector,
} from "../../../../services/redux";
import { toast } from "react-toastify";
import { retrieveItem, storeItem } from "../../../../helpers/functions";
import UpdateProfilePosFormItem from "./update_profile_pos_form_item";

const UpdateProfileMainForm = () => {
  const role = retrieveItem("role");
  console.log("rolerole", role);
  const { system_user } = useDynamicSelector("login");
  console.log("system_user", system_user);

  const storedSystemUser = retrieveItem("system_user");
  const [is_pos_select, set_pos_id] = useState();
  const [onchange_select, set_onchange_select] = useState();
  console.log("onchange_select", onchange_select);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [initial_values, set_initial_values] = useState({});

  const { t } = useTranslation();
  const system_user_mail = retrieveItem("system_user_mail_id");
  console.log("system_user_mail", system_user_mail);
  const {
    status: update_profile_status,
    error: update_profile_error,
    loading,
  } = useDynamicSelector("update_user");
  const {
    name,
    mobile,
    email,
    pos_id,
    loading: get_user_loading,
  } = useDynamicSelector("get_user");
  console.log("pos_id666", pos_id);
  useEffect(() => {
    update_user();
  }, []);
  useEffect(() => {
    if (update_profile_status === "success") {
      toast.success("Profile Updated");
      storeItem("system_user", storedSystemUser);

      dispatch(dynamicClear("update_user"));
    } else if (update_profile_status === "failure") {
      toast.error("Something went wrong");

      dispatch(dynamicClear("update_user"));
    } else if (update_profile_error?.message) {
      toast.error(update_profile_error.message);

      dispatch(dynamicClear("update_user"));
    }
  }, [update_profile_status, update_profile_error, storedSystemUser]);

  const update_user = (values) => {
    console.log("valuesvalues", values);
    let key = [{ key: "get_user", loading: true }];
    let query = query_user;
    let variables = {};
    console.log("variablesvariables", variables);
    dispatch(dynamicRequest(key, query, variables));
  };

  const update_profile = (values) => {
    console.log("valuesvalues", values);
    let key = [{ key: "update_user", loading: true }];
    let query = query_update_profile;
    let variables = {
      data: {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        pos_id: onchange_select,
      },
    };
    console.log("variablesvariables", variables);
    dispatch(dynamicRequest(key, query, variables));
  };

  const on_finish = (values) => {
    console.log("values", values);
    update_profile(values);
  };

  const handle_cancel = () => {
    form.resetFields();
  };
  const handle_click = (values) => {
    console.log("valuesvalues555", values);
  };

  useEffect(() => {
    if (name) {
      form.setFieldsValue({
        // ...storedSystemUser,
        name,
        mobile,
        email,
        pos_id,
      });
      // set_pos_id(pos_id);
      set_initial_values({ name });
    } else {
      form.resetFields();
    }
  }, [name, pos_id]);

  return (
    <>
      {get_user_loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin></Spin>
        </div>
      ) : (
        <Form form={form} id="update_profile_form" onFinish={on_finish}>
          <ProfileNameFormItem />
          <ProfileEmailIdFormItem />
          <ProfileMobileNUmberFormItem />
          {role === "District Admin" && (
            <UpdateProfilePosFormItem
              set_onchange_select={set_onchange_select}
            />
          )}

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              htmlType="submit"
              form="update_profile_form"
              style={{ backgroundColor: "#209e91", color: "white" }}
              onClick={handle_click}
              loading={loading}
            >
              {t("update_profile")}
            </Button>
          </div>
        </Form>
      )}
    </>
  );
};

export default UpdateProfileMainForm;
