import React from "react";
import FeedbackManageAddQuestion from "../components/manage_feedback/feedback_manage/feedback_manage_add_question_table/feedback_manage_add_question_main_component";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Typography } from "antd";

const FeedbackManagePage = () => {
  const { id, title } = useParams();
  const { t } = useTranslation();

  const handle_switch = (record) => {
    // console.log("values65", record);
  };

  return (
    <div>
      <Typography
        style={{
          fontSize: "20px",
          fontWeight: "600",
          margin: "10px",
        }}
      >
        {`FEEDBACK MANAGE / ${t(title)}`}{" "}
      </Typography>

      <FeedbackManageAddQuestion id={id} handle_switch={handle_switch} />
    </div>
  );
};

export default FeedbackManagePage;
