import React from "react";

const Weekdays = ({ day, ind }) => {
  return (
    <div key={ind} style={{ width: "14%", textAlign: "center" }}>
      <h5
        className="weekdays"
        style={{
          fontWeight: "bold",
          margin: "5px",
          color:
            day === "Sun" ? "#a80a00" : day === "Sat" ? "#a80a00" : "black",
        }}
      >
        {day === "Mon"
          ? "Monday"
          : day === "Tue"
          ? "Tuesday"
          : day === "Wed"
          ? "Wednesday"
          : day === "Thu"
          ? "Thursday"
          : day === "Fri"
          ? "Friday"
          : day === "Sat"
          ? "Saturday"
          : day === "Sun"
          ? "Sunday"
          : ""}
      </h5>
    </div>
  );
};

export default Weekdays;
