import { Button, Modal } from "antd";
import React from "react";
import DistrictForm from "../district_form/district_form";
import { useDynamicSelector } from "../../../../services/redux/selector";
import { IoMdClose } from "react-icons/io";

const DistrictModal = (props) => {
  const {
    header_type,
    edit_model_visible,
    set_edit_modal_visible,
    get_all_districts,
  } = props;

  const { loading: create_district_loading } =
    useDynamicSelector("create_district");

  return (
    <Modal
      width={1000}
      title={header_type === "edit_district" ? "Edit District" : "Add District"}
      open={edit_model_visible}
      destroyOnClose={true}
      onCancel={() => set_edit_modal_visible(false)}
      footer={[
        <Button key="cancel" onClick={() => set_edit_modal_visible(false)}>
          Cancel
        </Button>,
        <Button
          key="confirm"
          type="primary"
          htmlType="submit"
          form="district_form"
          loading={create_district_loading}
        >
          Save
        </Button>,
      ]}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <DistrictForm {...props} />
    </Modal>
  );
};

export default DistrictModal;
