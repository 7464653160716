import React, { useEffect } from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import { Card, Spin } from "antd";
import UpdateProfileMainForm from "../components/update_profile/update_profile_form/update_profile_main_form";
import {
  dynamicRequest,
  query_user,
  useDynamicSelector,
} from "../../services/redux";
import { useDispatch } from "react-redux";

const UpdateProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { loading: get_user_loading } = useDynamicSelector("get_user");
  // useEffect(() => {
  //   update_user();
  // }, []);
  // const update_user = (values) => {
  //   console.log("valuesvalues", values);
  //   let key = [{ key: "get_user", loading: true }];
  //   let query = query_user;
  //   let variables = {};
  //   console.log("variablesvariables", variables);
  //   dispatch(dynamicRequest(key, query, variables));
  // };
  return (
    <div>
      <CustomPageTitle title={t("profile_title")} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card
          style={{
            borderRadius: "10px",
            border: "1px solid #e0e0e0",
            boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
            backgroundColor: "#e7e7e7",
            width: "60%",
          }}
        >
          <UpdateProfileMainForm />
        </Card>
      </div>
    </div>
  );
};

export default UpdateProfile;
