import { Button, Modal, Typography } from "antd";
import React from "react";
import { IoMdClose } from "react-icons/io";
import {
  dynamicRequest,
  query_get_season_date_slot_list,
  useDynamicSelector,
} from "../../../../services/redux";
import LoaderSpinner from "../../common/custom_spinner";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { retrieveItem } from "../../../../helpers/functions";

const BookingDuplicateModal = (props) => {
  const {
    duplicate_booking_modal,
    set_duplicate_booking_modal,
    set_open_notification,
    set_modal_open,
    count,
    new_user_mobile_no,
    is_order_status_cancelled,
    form,
    set_get_leader_details,
    set_amount,
    set_pay_mode,
    set_leader_details,
  } = props;

  const dispatch = useDispatch();
  const leader_details = retrieveItem("leader_details");

  const { booking_reference, loading: booking_loading } =
    useDynamicSelector("get_booking_detail");
  const { items: season_date_slot_list_items, loading: loading } =
    useDynamicSelector("get_season_date_slot_list");

  const get_season_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const { t } = useTranslation();

  const on_finish = (values) => {
    set_duplicate_booking_modal(false);
    set_modal_open(false);
    form.resetFields();
    set_get_leader_details("");
    set_amount(0);
    set_pay_mode("");
    set_leader_details(false);
    const url = `/print-receipt/${booking_reference}/${
      new_user_mobile_no || leader_details
    }`;
    const new_tab = window.open(url, "_blank", "width=850, height=900");
    if (new_tab) {
      new_tab.focus();
    }
    get_season_date_slot_list();
  };

  const on_close = () => {
    set_duplicate_booking_modal(false);
    set_modal_open(false);
    form.resetFields();
    set_get_leader_details("");
    set_amount(0);
    set_pay_mode("");
    set_leader_details(false);
  };

  const on_cancel = () => {
    set_duplicate_booking_modal(false);

    get_season_date_slot_list();
  };

  return (
    <Modal
      //   data-payment-status={is_order_status_cancelled ? "failure" : "success"}
      data-payment-status="failure"
      title={"Duplicate Booking"}
      open={duplicate_booking_modal}
      onCancel={on_cancel}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      footer={[
        booking_reference && (
          <Button
            key="no-button"
            style={{
              backgroundColor: "#168f92",
              color: "white",
              border: "transparent",
            }}
            onClick={on_close}
          >
            {t("no")}
          </Button>
        ),
        booking_reference && (
          <Button
            key="yes-button"
            style={{
              backgroundColor: "#f96451",
              color: "white",
              border: "transparent",
            }}
            htmlType="submit"
            onClick={on_finish}
          >
            {t("yes")}
          </Button>
        ),
      ]}
    >
      <div>
        {booking_loading === false ? (
          <Typography style={{ color: "red" }}>
            {t("Already_booking")}
          </Typography>
        ) : (
          <LoaderSpinner message={"Please Wait..."} />
        )}
      </div>

      <Typography
        style={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        {booking_reference}
      </Typography>
    </Modal>
  );
};

export default BookingDuplicateModal;
