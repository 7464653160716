import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  get_group_list,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";

const { Option } = Select;

const InputGroupName = () => {
  const dispatch = useDispatch();

  const { items: group_items } = useDynamicSelector("get_district_group_list");
  useEffect(() => {
    get_all_group();
  }, []);

  const get_all_group = () => {
    let keys = [{ key: "get_district_group_list", loading: true }];
    let query = get_group_list;
    let variable = {
      sort: {
        order: "asc",
        field: "name",
      },
    };
    dispatch(dynamicRequest(keys, query, variable));
  };

  return (
    <>
      <Form.Item
        name="group_id"
        label="Group"
        rules={[
          {
            required: true,
            message: "Please select group",
          },
        ]}
      >
        <Select placeholder="Select Group" allowClear>
          {group_items?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default InputGroupName;
