import { Button, Modal } from "antd";
import React from "react";
import { IoMdClose } from "react-icons/io";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import DailyTransactionPdfRender from "../daily_transaction_pdf/daily_transaction_pdf_render";
import { useDynamicSelector } from "../../../../../services/redux";

const DailyTransactionModal = (props) => {
  const { visible, set_visible, selected_dates } = props;
  const {
    items: daily_transaction_items,
    loading: daily_transaction_items_loading,
  } = useDynamicSelector("get_daily_transaction_summary");

  return (
    <Modal
      title={"Daily Transaction Report"}
      open={visible}
      width={800}
      destroyOnClose={true}
      onCancel={() => set_visible(false)}
      footer={[
        <Button key="cancel" onClick={() => set_visible(false)}>
          Cancel
        </Button>,
        <PDFDownloadLink
          style={{ textDecoration: "none", marginLeft: "10px" }}
          document={
            <DailyTransactionPdfRender
              daily_transaction_items={daily_transaction_items}
            />
          }
          fileName={`Daily-Transaction-Report(${selected_dates?.from_date_label} to ${selected_dates?.to_date_label}).pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : <Button>Download</Button>
          }
        </PDFDownloadLink>,
      ]}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PDFViewer width={"770px"} height={"400px"}>
          <DailyTransactionPdfRender
            daily_transaction_items={daily_transaction_items}
            selected_dates={selected_dates}
          />
        </PDFViewer>
      </div>
    </Modal>
  );
};

export default DailyTransactionModal;
