import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { toUpper, upperCase } from "lodash";
import { useTranslation } from "react-i18next";
import moment from "moment";

const DayWisePdfRender = (props) => {
  const { day_wise_report_items } = props;
  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "white",
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    table: {
      display: "table",
      width: "auto",
      borderCollapse: "collapse",
    },
    tableRow: {
      flexDirection: "row",
    },
    tableCell: {
      borderWidth: 1,
      padding: 8,
      flex: 1,
      textAlign: "left",
      fontSize: "8px",
    },
    tableColHeader: {
      borderWidth: 1,
      backgroundColor: "#f0f0f0",
      padding: 8,
      flex: 1,
      textAlign: "center",
      fontSize: "10px",
    },
    table_header: {
      display: "flex",
      margin: "5px",
      fontWeight: "bold",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "10px",
    },
    header_content: {
      fontWeight: "600",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "10px",
    },
    image: {
      width: "40px",
      height: "40px",
    },
    full_header_content: {
      display: "flex",
      alignContent: "center",
      justifyContent: "space-evenly",
    },
    flexContainer: {
      marginTop: "-15px",
    },
  });

  const { t } = useTranslation();

  return (
    <Document fileName={`Day-Wise-report.pdf`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.flexContainer}>
            <View style={styles.full_header_content}>
              <Text style={styles.header_content}>{t("om_shakthi")}</Text>
              <Text style={styles.header_content}>{t("masm")} </Text>
              <Text style={styles.header_content}>
                {t("adhiparashakthi_siddhar_peedam")}
              </Text>
            </View>
          </View>

          <View></View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>Date</Text>
              <Text style={styles.tableColHeader}>No of irumudi</Text>
            </View>
            {day_wise_report_items?.map((item, index) => (
              <View
                style={styles.tableRow}
                key={index}
                wrap={index !== 0 ? "wrap" : "nowrap"}
              >
                <Text style={styles.tableCell}>
                  {moment(item?.date).format("DD/MM/YYYY")}
                </Text>
                <Text style={styles.tableCell}> {item?.irumudi_count}</Text>
              </View>
            ))}
            {/* Total row */}
            {/* <View style={styles.tableRow}>
              <Text style={styles.tableCell}>TOTAL</Text>
              <Text style={styles.tableCell}>
                {total_amount?.total_enrolment}
              </Text>
              <Text style={styles.tableCell}>
                {total_amount?.total_devotees}
              </Text>
              <Text style={styles.tableCell}>
                {total_amount?.total_cash_amount}
              </Text>
              <Text style={styles.tableCell}>
                {total_amount?.total_dd_enrolments}
              </Text>
              <Text style={styles.tableCell}>
                {total_amount?.total_dd_amount}
              </Text>
              <Text style={styles.tableCell}>
                {total_amount?.total_card_enrolments}
              </Text>
              <Text style={styles.tableCell}>
                {total_amount?.total_card_amount}
              </Text>
              <Text style={styles.tableCell}>
                {total_amount?.total_upi_enrolments}
              </Text>
              <Text style={styles.tableCell}>
                {total_amount?.total_upi_amount}
              </Text>
              <Text style={styles.tableCell}>{total_amount?.total_amount}</Text>
            </View> */}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default DayWisePdfRender;
