import { gql } from "@apollo/client";

export const query_get_all_counter_payment_list = gql`
  query ($filter: om_sakthi_summary_input) {
    get_om_sakthi_summary(filter: $filter) {
      items {
        total_cash_amount
        total_dd_amount
        total_card_amount
        total_upi_amount
        total_devotees
        total_amount
      }
    }
  }
`;
