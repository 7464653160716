import { Pagination, Row, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDynamicSelector } from "../../../services/redux/selector";
import {
  dynamicClear,
  dynamicRequest,
  get_group_list,
} from "../../../services/redux/slices";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const GroupList = (props) => {
  const {
    get_all_group_list,
    set_group_modal_open,
    set_group_modal_title,
    handle_group_delete,
    get_one_group_record,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [current_limit, setCurrentLimit] = useState(10);
  const [current, setCurrent] = useState(1);
  const {
    items: group_list,
    loading: group_list_loading,
    pagination: group_pagination,
  } = useDynamicSelector("get_district_group_list");
  const { status: create_group_status, error: create_group_error } =
    useDynamicSelector("create_district_group");
  const { status: update_group_status, error: update_group_error } =
    useDynamicSelector("update_district_group");

  const handle_group_edit = (record) => {
    get_one_group_record(record.id);
    set_group_modal_open(true);
    set_group_modal_title("edit");
  };

  const fetch_data = (page_number, page_limit) => {
    console.log("fetch_data", page_number, page_limit);
    let key = [{ key: "get_district_group_list", loading: true }];
    let query = get_group_list;
    let variables = {
      pagination: {
        page_limit: page_limit,
        page_number: page_number,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
    setCurrentLimit(page_limit);
    setCurrent(page_number);
  };

  useEffect(() => {
    if (create_group_status === "success") {
      toast.success("group created");
      get_all_group_list();
      set_group_modal_open(false);
      dispatch(dynamicClear("create_district_group"));
    } else if (create_group_error) {
      toast.error("Couldn't create Group");
      // toast.error(create_group_error?.message);
      set_group_modal_open(false);
      dispatch(dynamicClear("create_district_group"));
    } else if (update_group_status === "success") {
      toast.success("group updated");
      get_all_group_list();
      set_group_modal_open(false);
      dispatch(dynamicClear("update_district_group"));
    } else if (update_group_error) {
      // toast.error(update_group_error?.message);
      toast.error("Couldn't Update Group");
      set_group_modal_open(false);
      dispatch(dynamicClear("update_district_group"));
    }
  }, [
    create_group_status,
    create_group_error,
    update_group_status,
    update_group_error,
  ]);

  const columns = [
    {
      title: t("name"),
      key: "name",
      width: "750px",
      render: (items) => {
        return items?.name;
      },
    },
    {
      title: t("tamil_name"),
      key: "tamil_name",
      width: "750px",
      render: (items) => {
        return items?.tamil_name;
      },
    },
    {
      title: t("actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      width: "300px",
      align: "center",
      render: (_, record) => (
        <Space
          style={{
            width: "50px",
            justifyContent: "space-between",
          }}
        >
          <EditOutlined
            onClick={() => {
              handle_group_edit(record);
            }}
          />

          <DeleteOutlined
            onClick={() => {
              handle_group_delete(record);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Table
        style={{ width: "100%" }}
        size="large"
        columns={columns}
        dataSource={group_list}
        loading={group_list_loading}
        pagination={false}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Pagination
          total={group_pagination?.total_count}
          showSizeChanger={true}
          current={current}
          hideOnSinglePage={false}
          onChange={fetch_data}
          style={{ marginTop: "10px" }}
        />
      </div>
    </div>
  );
};
export default GroupList;
