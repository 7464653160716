import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const InputName = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      label={"Name"}
      name="name"
      rules={[
        {
          required: true,
          message: "Please enter name",
        },
      ]}
      style={{ marginTop: "22px" }}
    >
      <Input placeholder="Name" />
    </Form.Item>
  );
};

export default InputName;
