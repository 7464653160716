import React, { useEffect } from "react";
import { Card, Typography } from "antd";
import VipBookingFormUser from "../components/vip_booking_users/vip_booking_form/vip_booking_user_form";
import Scrollbars from "react-custom-scrollbars-2";
import LoginHeaderBar from "../components/headerbar/login_header";
import BackgroundLogo from "../../assets/icons/background_image.png";

import { Box } from "native-base";
import { dynamicClear, useDynamicSelector } from "../../services/redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const VipBookingUsers = () => {
  const dispatch = useDispatch();

  return (
    <Box
      bgImage={`url(${BackgroundLogo})`}
      style={{ height: "100vh", backgroundSize: "cover" }}
    >
      <Scrollbars>
        <LoginHeaderBar />
        <div
          style={{
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Typography.Title level={3}>VIP Booking User Form</Typography.Title>
        </div>

        <Card className="vip_booking_user_form_card">
          <VipBookingFormUser />
        </Card>
      </Scrollbars>
    </Box>
  );
};

export default VipBookingUsers;
