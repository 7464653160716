import { Button, Modal, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDynamicSelector } from "../../../../services/redux/selector";
import { IoMdClose } from "react-icons/io";
import {
  navigate,
  navigateWithProps,
  retrieveItem,
  storeItem,
} from "../../../../helpers/functions";
import { ROUTES } from "../../../routes/my_routes";
import {
  dynamicRequest,
  query_get_booking_receipt,
  query_get_season_date_slot_list,
} from "../../../../services/redux";
import { useDispatch } from "react-redux";
import LoaderSpinner from "../../common/custom_spinner";

const BookingNotificationModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { booking_reference, loading: booking_loading } =
    useDynamicSelector("get_booking_detail");
  const {
    open_notification,
    set_open_notification,
    set_modal_open,
    count,
    new_user_mobile_no,
    is_order_status_cancelled,
    form,
    set_get_leader_details,
    set_amount,
    set_pay_mode,
    set_leader_details,
  } = props;
  const { items: season_date_slot_list_items, loading: loading } =
    useDynamicSelector("get_season_date_slot_list");
  const leader_details = retrieveItem("leader_details");

  // const get_booking_receipt = () => {
  //   let key = [{ key: "get_booking_receipt", loading: true }];
  //   let query = query_get_booking_receipt;
  //   let booking_user_mobile;

  //   if (new_user_mobile_no !== undefined) {
  //     booking_user_mobile = new_user_mobile_no;
  //   } else if (leader_details !== undefined) {
  //     booking_user_mobile = leader_details;
  //   } else {
  //     booking_user_mobile = undefined;
  //   }

  //   let variables = {
  //     custom: {
  //       booking_reference: booking_reference,
  //       booking_user_mobile: booking_user_mobile,
  //     },
  //   };

  //   dispatch(dynamicRequest(key, query, variables));
  // };
  const get_season_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {
      sort: {
        field: "date",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const on_finish = (values) => {
    set_open_notification(false);
    set_modal_open(false);
    form.resetFields();
    set_get_leader_details("");
    set_amount(0);
    set_pay_mode("");
    set_leader_details(false);

    const url = `/print-receipt/${booking_reference}/${
      new_user_mobile_no || leader_details
    }`;
    const new_tab = window.open(url, "_blank", "width=850, height=900");
    if (new_tab) {
      new_tab.focus();
    }

    get_season_date_slot_list();
  };
  const on_close = () => {
    set_open_notification(false);
    set_modal_open(false);
    form.resetFields();
    set_get_leader_details("");
    set_amount(0);
    set_pay_mode("");
    set_leader_details(false);
    get_season_date_slot_list();
  };

  const on_cancel = () => {
    set_open_notification(false);
    get_season_date_slot_list();
  };
  return (
    <div>
      <Modal
        data-payment-status={is_order_status_cancelled ? "failure" : "success"}
        title={is_order_status_cancelled ? t("failure") : t("success")}
        open={open_notification}
        onCancel={on_cancel}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          booking_reference && (
            <Button
              key="no-button"
              style={{
                backgroundColor: "#168f92",
                color: "white",
                border: "transparent",
              }}
              onClick={on_close}
            >
              {t("no")}
            </Button>
          ),
          booking_reference && (
            <Button
              key="yes-button"
              style={{
                backgroundColor: "#f96451",
                color: "white",
                border: "transparent",
              }}
              htmlType="submit"
              onClick={on_finish}
            >
              {t("yes")}
            </Button>
          ),
        ]}
      >
        {is_order_status_cancelled ? (
          <div>
            <Typography
              style={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
              }}
            >
              {t("booking_success")}
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                marginBottom: "3px",
              }}
            >
              {t("payment_failed_notification")}
            </Typography>
            {booking_loading === false ? (
              <Typography>{t("success_notification")}</Typography>
            ) : (
              <LoaderSpinner message={"Please Wait..."} />
            )}
          </div>
        ) : booking_loading === false ? (
          <Typography>{t("success_notification")}</Typography>
        ) : (
          <LoaderSpinner message={"Please Wait..."} />
        )}

        <Typography
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          {booking_reference}
        </Typography>
      </Modal>
    </div>
  );
};

export default BookingNotificationModal;
