import { Col, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomText from "../../common/custom_text";

const { Title } = Typography;

const ContentHeader = () => {
  const { t } = useTranslation();
  return (
    <Col
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "10px auto",
        textAlign: "center",
      }}
    >
      <CustomText title={t("om_shakthi")} bold={true} font_size={"16px"} />
      <CustomText title={t("masm")} bold={true} />
      <CustomText title={t("adhiparashakthi_siddhar_peedam")} />
      <CustomText
        underline={true}
        title={t("irumudi_online_booking_acknowledgement")}
      />
    </Col>
  );
};

export default ContentHeader;
