import { Form, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  query_get_mandram_list,
  useDynamicSelector,
} from "../../../../../services/redux";
import { useDispatch } from "react-redux";

const AddressReportMandramFormItem = (props) => {
  const { selected_district, set_selected_mandram, value_change } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { items: mandram_list_items } = useDynamicSelector(
    "get_mandram_details"
  );

  useEffect(() => {
    if (selected_district) {
      get_mandram_list();
    }
  }, [selected_district]);

  const get_mandram_list = () => {
    let key = [{ key: "get_mandram_details", loading: true }];
    let query = query_get_mandram_list;
    let variables = {
      filter: { district_id: selected_district },
      sort: {
        field: "internal_code",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };
  const handle_change = (values) => {
    set_selected_mandram(values);
  };
  // const custom_filter_option = (input, option) => {
  //   const inputValue = input.toLowerCase();
  //   const optionLabel = option.props.children.toLowerCase();
  //   return optionLabel.indexOf(inputValue) === 0;
  // };
  return (
    <div>
      <Form.Item name="mandram" label={t("mandram")}>
        <Select
          allowClear
          showSearch
          filterOption={(input, option) =>
            option?.props?.children
              ?.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onChange={handle_change}
          mode="multiple"
          placeholder="Select Mandram"
        >
          {mandram_list_items?.map((option, index) => (
            <Select.Option key={index} value={option.id}>
              {`${option?.code}/${option?.name}`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};

export default AddressReportMandramFormItem;
