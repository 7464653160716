import { Col, Form, Input, Modal, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import VipBookingMobile from "../vip_booking_inputs/vip_booking_mobile";
import VipBookingName from "../vip_booking_inputs/vip_booking_name";
import VipBookingCount from "../vip_booking_inputs/vip_booking_count";
import { useDynamicSelector } from "../../../../services/redux/selector";
import VipBookingDistrict from "../vip_booking_inputs/vip_booking_district";
import { district_details_query } from "../../../../services/redux/slices/graphql/graphql_district";
import { useDispatch } from "react-redux";
import {
  dynamicClear,
  dynamicRequest,
  mutation_create_spot_foreign_booking,
  mutation_create_spot_vip_booking,
  query_check_has_pos,
  query_get_enrolment_detail,
  // query_check_order_status,
  query_get_season_date_slot_list,
} from "../../../../services/redux/slices";
import VipBookingPaymentMode from "../vip_booking_inputs/vip_booking_payment_mode";
import VipBookingButton from "../vip_booking_inputs/vip_booking_button";
import VipBookingIdType from "../vip_booking_inputs/vip_booking_id_type";
import VipBookingIdNumber from "../vip_booking_inputs/vip_booking_id_number";
import { toast } from "react-toastify";
import VipBookingAmount from "../vip_booking_inputs/vip_booking_amount";
import DdAmountInput from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/dd_amount_input";
import SelectBank from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/select_bank";
import ReceiptDateInput from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/receipt_date_input";
import ReferenceNumber from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/reference_number";
import moment from "moment";
import { width } from "styled-system";
import { startCase } from "lodash";
import VIPLeaderDetails from "../vip_booking_inputs/vip_leader_details";
import { retrieveItem } from "../../../../helpers/functions";
import CashInput from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/cash_received";
import CashReceived from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/cash_received";
import CashBalance from "../../enroll/receipt_details_for_enroll/receipt_details_inputs/cash_balance";
import VIPForeignBooking from "../vip_booking_inputs/vip_foreign_booking";
import BookingPosReceipt from "../../booking/booking_form/booking_pos_receipt";
import BookingUpiPosReceipt from "../../booking/booking_form/booking_upi_pos_receipt";
import SpotBookingSubDistrict from "../../spot_booking/spot_booking_form/spot_booking_sub_district";
let modalInstance;

const VipBookingForm = (props) => {
  const {
    open_token_printer_modal,
    has_pos,
    leader_details,
    set_leader_details,
    set_get_leader_details,
    get_leader_details,
    booking_details,
    set_booking_details,
    set_enrollment_details,
  } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [payment_mode, set_payment_mode] = useState("");
  const [district_id, set_district_id] = useState();
  const [ticket_count, set_ticket_count] = useState();
  const [total_ticket_amount, set_total_ticket_amount] = useState(0);
  const [dd_amount, set_dd_amount] = useState();
  const [selected_bank, set_selected_bank] = useState("");
  const [selected_date, set_selected_date] = useState("");
  const [dd_reference_number, set_dd_reference_number] = useState("");
  const [selected_id_proof, set_selected_id_proof] = useState("Aadhaar Card");
  const [cash_amount, set_cash_amount] = useState(0);
  const [balance_amount, set_balance_amount] = useState(0);
  const [booking_mode, set_booking_type] = useState(false);
  const [pos_receipt_no, set_pos_receipt_no] = useState("");

  const {
    status: create_vip_booking_status,
    error: create_vip_booking_error,
    loading: create_vip_booking_loading,
    id: spot_vip_booking_id,
    booking_reference,
    receipt_no,
    amount,
    payment_mode: spot_vip_booking_pay_mode,
    arrived_count,
    district_code,
  } = useDynamicSelector("create_spot_vip_booking");

  const {
    status: create_spot_foreign_booking_status,
    error: create_spot_foreign_booking_error,
    loading: create_spot_foreign_booking_loading,
    id: spot_spot_foreign_booking_id,
    booking_reference: foreign_booking_reference,
    receipt_no: foreign_receipt_no,
    amount: foreign_amount,
    payment_mode: foreign_spot_vip_booking_pay_mode,
    arrived_count: foreign_arrived_count,
    district_code: foreign_district_code,
  } = useDynamicSelector("create_spot_foreign_booking");

  const { items: districts } = useDynamicSelector("district_list");
  const item = useDynamicSelector("get_booking_user_details");

  // const {
  //   status: order_status,
  //   error: check_order_error,
  //   loading: check_order_loading,
  // } = useDynamicSelector("check_order_status");

  const get_season_date_slot_list = () => {
    let key = [{ key: "get_season_date_slot_list", loading: true }];
    let query = query_get_season_date_slot_list;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  // const check_order_status = (booking_id) => {
  //   let key = [{ key: "check_order_status", loading: true }];
  //   let query = query_check_order_status;
  //   let variables = {
  //     id: booking_id,
  //   };
  //   dispatch(dynamicRequest(key, query, variables));
  // };

  const spot_vip_booking = (records) => {
    let key = [{ key: "create_spot_vip_booking", loading: true }];
    let query = mutation_create_spot_vip_booking;
    let data = {
      payment_mode: payment_mode,
      count: records?.count,
      district_id: district_id,
      booking_user_district_id: district_id,
      pos_receipt: records?.pos_receipt,
      dd_reference: records?.reference_number,
      dd_datetime: selected_date,
      dd_bank_id: selected_bank,
      dd_amount: Number(total_ticket_amount),
      booking_user_id: item?.id ? item?.id : "",

      booking_user: {
        name: booking_details?.group_leader
          ? booking_details?.group_leader
          : item?.name,
        mobile: item?.mobile ? item?.mobile : records?.contact_number,
        address: booking_details?.address
          ? booking_details?.address
          : item.address,
        id_type: booking_details?.id_proof
          ? booking_details?.id_proof
          : item.id_type,
        id_number: booking_details?.id_number
          ? booking_details?.id_number
          : item.id_number,
      },
    };

    if (payment_mode !== "dd") {
      delete data.dd_reference;
      delete data.dd_datetime;
      delete data.dd_amount;
      delete data.dd_bank_id;
    } else if (payment_mode !== "card") {
      delete data.pos_receipt;
    } else if (payment_mode !== "upi") {
      delete data.pos_receipt;
    }

    if (item?.id) {
      delete data?.booking_user;
    } else {
      delete data?.booking_user_id;
    }
    let variables = {
      data: data,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const spot_foreign_booking = (records) => {
    console.log("records", records);
    let key = [{ key: "create_spot_foreign_booking", loading: true }];
    let query = mutation_create_spot_foreign_booking;
    let data = {
      payment_mode: payment_mode,
      count: records?.count,
      district_id: district_id,
      pos_receipt: records?.pos_receipt,
      dd_reference: records?.reference_number,
      dd_datetime: selected_date,
      dd_bank_id: selected_bank,
      dd_amount: Number(total_ticket_amount),
      booking_user_id: item?.id ? item?.id : "",
      booking_user: {
        name: booking_details?.group_leader
          ? booking_details?.group_leader
          : item?.name,
        mobile: item?.mobile ? item?.mobile : records?.contact_number,
        address: booking_details?.address
          ? booking_details?.address
          : item.address,
        id_type: booking_details?.id_proof
          ? booking_details?.id_proof
          : item.id_type,
        id_number: booking_details?.id_number
          ? booking_details?.id_number
          : item.id_number,
      },
    };
    if (item?.id) {
      delete data.booking_user;
    } else {
      delete data.booking_user_id;
    }

    if (payment_mode !== "dd") {
      delete data.dd_reference;
      delete data.dd_datetime;
      delete data.dd_amount;
      delete data.dd_bank_id;
    } else if (payment_mode !== "card") {
      delete data.pos_receipt;
    } else if (payment_mode !== "upi") {
      delete data.pos_receipt;
    }

    let variables = {
      data: data,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_district_list = (booking_type) => {
    let key = [{ key: "district_list", loading: true }];
    let query = district_details_query;
    let variables = {
      filter: {
        is_foreign: booking_type,
      },
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_district_list();
  }, []);

  const on_finish = (records) => {
    if (booking_mode === true) {
      spot_foreign_booking(records);
    } else {
      spot_vip_booking(records);
    }
  };
  const handle_date_change = (date, dateString) => {
    const current_date = date;
    set_selected_date(current_date);
  };

  // const get_enrolment_details = () => {
  //   let keys = [{ key: "get_enrolment_detail", loading: true }];
  //   let query = query_get_enrolment_detail;
  //   let variables = {
  //     id: spot_vip_booking_id,
  //   };
  //   dispatch(dynamicRequest(keys, query, variables));
  // };

  useEffect(() => {
    if (
      create_vip_booking_status === "booking enrolled" ||
      create_spot_foreign_booking_status === "booking enrolled"
    ) {
      // get_enrolment_details();
      set_enrollment_details({
        booking_reference: booking_reference || foreign_booking_reference,
        receipt_no: receipt_no || foreign_receipt_no,
        amount: amount || foreign_amount,
        arrived_count: arrived_count || foreign_arrived_count,
        spot_vip_booking_pay_mode:
          spot_vip_booking_pay_mode || foreign_spot_vip_booking_pay_mode,
        district_code: district_code || foreign_district_code,
      });
      open_token_printer_modal();
      set_total_ticket_amount(0);
      form.resetFields("");
      set_leader_details(false);
      set_get_leader_details("");
      form.setFieldsValue({ cash_balance: 0 });
      form.setFieldsValue({
        id_type: "Aadhaar Card",
      });
      set_payment_mode("");
      set_selected_id_proof("Aadhaar Card");
      dispatch(dynamicClear("create_spot_vip_booking"));
      dispatch(dynamicClear("create_spot_foreign_booking"));
      dispatch(dynamicClear("get_booking_user_details"));
      dispatch(dynamicClear("get_enrolment_detail"));
    } else if (
      create_vip_booking_status === "booking created & paid" ||
      create_spot_foreign_booking_status === "booking created & paid"
    ) {
      open_token_printer_modal();
      set_total_ticket_amount(0);
      form.resetFields("");
      set_leader_details(false);
      set_get_leader_details("");
      dispatch(dynamicClear("create_spot_vip_booking"));
      dispatch(dynamicClear("create_spot_foreign_booking"));
      dispatch(dynamicClear("get_booking_user_details"));
    }
    //  else if (
    //   create_vip_booking_status === "order created" ||
    //   create_spot_foreign_booking_status === "order created"
    // ) {
    //   if (booking_mode === true) {
    //     check_order_status(spot_spot_foreign_booking_id);
    //   }

    //   else {
    //     check_order_status(spot_vip_booking_id);
    //   }
    //   modalInstance = Modal.info({
    //     title: "Payment Process",
    //     content: (
    //       <div>
    //         <p>Your Payment is Processing.....</p>
    //       </div>
    //     ),
    //     okButtonProps: {
    //       style: {
    //         display: "none",
    //       },
    //     },
    //   });
    // }
    else if (create_vip_booking_error?.message) {
      if (create_vip_booking_error.message === "duplicate booking user") {
        toast.error("Duplicate booking user");
        set_leader_details(false);
      } else {
        toast.error(startCase(create_vip_booking_error.message));
      }
      dispatch(dynamicClear("create_spot_vip_booking"));
    } else if (create_spot_foreign_booking_error?.message) {
      if (
        create_spot_foreign_booking_error?.message === "duplicate booking user"
      ) {
        toast.error("Duplicate booking user");
        set_leader_details(false);
      } else {
        toast.error(startCase(create_spot_foreign_booking_error?.message));
      }
      dispatch(dynamicClear("create_spot_foreign_booking"));
    }
  }, [
    create_vip_booking_status,
    spot_vip_booking_id,
    create_spot_foreign_booking_status,
    create_spot_foreign_booking_error,
    create_vip_booking_error,
    receipt_no,
    amount,
    arrived_count,
    spot_vip_booking_pay_mode,
    district_code,
    booking_reference,
    foreign_booking_reference,
    foreign_receipt_no,
    foreign_amount,
    foreign_spot_vip_booking_pay_mode,
    foreign_arrived_count,
    foreign_district_code,
  ]);

  // useEffect(() => {
  //   if (order_status === "completed") {
  //     set_total_ticket_amount(0);
  //     open_token_printer_modal();
  //     set_payment_mode("");
  //     set_selected_id_proof("Aadhaar Card");
  //     if (modalInstance) {
  //       modalInstance.destroy();
  //       form.resetFields("");
  //       form.setFieldsValue({
  //         id_type: "Aadhaar Card",
  //       });
  //       set_leader_details(false);
  //     }
  //     dispatch(dynamicClear("check_order_status"));
  //     dispatch(dynamicClear("create_spot_vip_booking"));
  //   } else if (order_status === "initiated") {
  //     setTimeout(() => {
  //       check_order_status(spot_vip_booking_id);
  //     }, 10000);
  //   } else if (order_status === "cancelled") {
  //     if (modalInstance) {
  //       modalInstance.destroy();
  //       toast.error("Payment Cancelled");
  //       set_leader_details(false);
  //       dispatch(dynamicClear("check_order_status"));
  //       dispatch(dynamicClear("create_spot_vip_booking"));
  //     }
  //   } else if (check_order_error?.message) {
  //     toast.error(startCase(check_order_error.message));
  //     dispatch(dynamicClear("check_order_status"));
  //   }
  // }, [order_status, check_order_error, spot_vip_booking_id]);

  useEffect(() => {
    if (payment_mode === "dd") {
      form.setFieldsValue({ dd_amount: total_ticket_amount });
    }
  }, [payment_mode, total_ticket_amount]);

  useEffect(() => {
    if (payment_mode === "cash") {
      const bal_amount = cash_amount - total_ticket_amount;
      set_balance_amount(bal_amount);
      if (bal_amount < 0) {
        set_balance_amount(0);
        form.setFieldsValue({ cash_balance: 0 });
      } else {
        set_balance_amount(bal_amount);
        form.setFieldsValue({ cash_balance: bal_amount || 0 });
      }
    }
  }, [payment_mode, total_ticket_amount, cash_amount]);

  useEffect(() => {
    // get_district_list();
    get_season_date_slot_list();
  }, []);

  const handle_change_values = (val, value) => {
    set_booking_details(value);
  };

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <Form
        layout="vertical"
        onFinish={on_finish}
        form={form}
        onValuesChange={handle_change_values}
      >
        <Row gutter={16}>
          <Col span={12}>
            <VIPLeaderDetails
              leader_details={leader_details}
              set_leader_details={set_leader_details}
              get_leader_details={get_leader_details}
              set_get_leader_details={set_get_leader_details}
              set_selected_id_proof={set_selected_id_proof}
              selected_id_proof={selected_id_proof}
              set_booking_details={set_booking_details}
              form={form}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <VIPForeignBooking
              set_booking_type={set_booking_type}
              get_district_list={get_district_list}
            />
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={6}>
            <VipBookingDistrict
              set_district_id={set_district_id}
              districts={districts}
            />
          </Col>
          {/* <Col span={6}>
            <SpotBookingSubDistrict district_id={district_id} />
          </Col> */}
          {/* <Col span={12}>
            <VipBookingIdType set_selected_id_proof={set_selected_id_proof} />
          </Col> */}
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <VipBookingCount
                set_total_ticket_amount={set_total_ticket_amount}
                set_ticket_count={set_ticket_count}
                ticket_count={ticket_count}
              />
              <VipBookingAmount total_ticket_amount={total_ticket_amount} />
            </Row>
          </Col>
          {/* <Col span={12}>
            <VipBookingIdNumber selected_id_proof={selected_id_proof} />
          </Col> */}
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <VipBookingPaymentMode
              set_payment_mode={set_payment_mode}
              payment_mode={payment_mode}
              has_pos={has_pos}
            />
          </Col>
          <Col span={12}></Col>
        </Row>
        {payment_mode === "card" ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              marginLeft: "17%",
            }}
          >
            <BookingPosReceipt
              payment_mode={payment_mode}
              set_pos_receipt_no={set_pos_receipt_no}
            />
          </div>
        ) : (
          ""
        )}

        {payment_mode === "upi" ? (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              marginLeft: "28%",
            }}
          >
            <BookingUpiPosReceipt
              payment_mode={payment_mode}
              set_pos_receipt_no={set_pos_receipt_no}
            />
          </div>
        ) : (
          ""
        )}

        <div>
          <div style={{ display: "flex" }}>
            {payment_mode === "dd" ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "20%" }}>
                  <DdAmountInput set_dd_amount={set_dd_amount} />
                </div>
                <div style={{ width: "20%" }}>
                  <SelectBank
                    set_selected_bank={set_selected_bank}
                    payment_mode={payment_mode}
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <ReceiptDateInput
                    set_selected_date={set_selected_date}
                    selected_date={selected_date}
                    handle_date_change={handle_date_change}
                    payment_mode={payment_mode}
                  />
                </div>
                <div style={{ width: "20%" }}>
                  <ReferenceNumber
                    set_dd_reference_number={set_dd_reference_number}
                    payment_mode={payment_mode}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {payment_mode === "cash" ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <CashReceived set_cash_amount={set_cash_amount} />
                <CashBalance />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <VipBookingButton title={"Book"} />
      </Form>
    </div>
  );
};

export default VipBookingForm;
