import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const EnrollActionReceiptNoFormItem = () => {
  const { t } = useTranslation();
  const handle_change = (e) => {
    console.log("rte", e);
  };
  return (
    <div>
      <Form.Item name="receipt_number" label={t("receipt_no_label")}>
        <Input
          style={{ width: "300px" }}
          placeholder="Receipt no"
          onChange={handle_change}
        />
      </Form.Item>
    </div>
  );
};

export default EnrollActionReceiptNoFormItem;
