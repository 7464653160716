import React from "react";
import IrumudiAndBookingPortalForm from "./booking_portal_form/booking_portal_form";
import { Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
const BookingPortal = ({ handle_back_click }) => {
  const { t } = useTranslation();
  return (
    <div className="login_form_container">
      <Row
        style={{
          // width: "100%",
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "0 0 10px 10px",
        }}
      >
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Typography
            style={{
              width: "100%",
              color: "",
              marginTop: "15px",
              fontWeight: "bold",
              fontSize: "15px",
              display: "flex",
              justifyContent: "center",
             marginBottom:"10px"
            }}
          >
            Booking Portal
          </Typography>
        </div>
        <IrumudiAndBookingPortalForm handle_back_click={handle_back_click} />
      </Row>
    </div>
  );
};

export default BookingPortal;
