import React from "react";
import { Form, InputNumber } from "antd";

const SearchReceiptMobileNumber = () => {
  return (
    <>
      <Form.Item
        name="booking_user_mobile"
        label="Mobile Number"
        rules={[
          {
            required: true,
            message: "Please enter mobile number",
          },
          {
            pattern: /^[0-9]{10}$/,
            message: "Please enter valid mobile number",
          },
        ]}
        style={{ marginBottom: 0 }}
      >
        <InputNumber
          type="text"
          placeholder="Mobile Number"
          allowClear={true}
          className="no-spinners"
        />
      </Form.Item>
    </>
  );
};

export default SearchReceiptMobileNumber;
