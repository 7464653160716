import { Button, Form, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import AddMandramForm from "../add_mandram/add_mandram_form";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";
import {
  dynamicClear,
  dynamicRequest,
  mutation_update_mandram_status,
  update_mandram_query,
  useDynamicSelector,
} from "../../../../services/redux";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const ApprovalAndRejected = (props) => {
  const { open, handle_close, action_item, get_all_mandram_list } = props;
  const {
    loading: update_mandram_status_loading,
    status: update_mandram_status,
    error: update_mandram_error,
  } = useDynamicSelector("update_mandram_status");
  const dispatch = useDispatch();
  const [remarks, set_remarks] = useState("");

  const { t } = useTranslation();
  console.log("action_item", action_item);
  const handle_change_remarks = (e) => {
    set_remarks(e?.target?.value);
  };
  const handle_update_mandram = () => {
    let keys = [{ key: "update_mandram_status", loading: true }];
    dispatch(
      dynamicRequest(keys, mutation_update_mandram_status, {
        id: action_item.id,
        activation_status:
          action_item?.activation_status === "not_activated"
            ? "initiated"
            : "not_activated",
        remarks: remarks,
      })
    );
  };

  useEffect(() => {
    if (update_mandram_status === "success") {
      toast.success("Mandram updated successfully.");
      dispatch(dynamicClear("update_mandram_status"));
      get_all_mandram_list();
      handle_close()
    }
  }, [update_mandram_status]);
  return (
    <Modal
      title={
        action_item?.activation_status === "not_activated"
          ? "Activated Conformation"
          : "Not Activated Conformation"
      }
      open={open}
      destroyOnClose={true}
      onCancel={handle_close}
      closeIcon={
        <span style={{ marginTop: "2px" }}>
          <IoMdClose
            style={{
              color: "white",
              width: "28px",
              margin: "15px 0px 0px 0px",
            }}
          />
        </span>
      }
      width={800}
      footer={[
        <Button
          style={{
            backgroundColor: "#168f92",
            color: "white",
            border: "transparent",
          }}
          onClick={handle_close}
        >
          {t("cancel")}
        </Button>,
        <Button
          loading={update_mandram_status_loading}
          style={{
            backgroundColor: "#f96451",
            color: "white",
            border: "transparent",
          }}
          htmlType="submit"
          form="add_mandram_form"
          onClick={handle_update_mandram}
        >
          {t("ok")}
        </Button>,
      ]}
    >
      {"Are you sure you want to "}
      {action_item?.activation_status === "not_activated"
        ? "initiated "
        : "Not Activated Conformation "}
      <b>{`${action_item?.name} ?`}</b>
      <TextArea
        style={{ marginTop: "10px" }}
        placeholder="Remarks"
        onChange={handle_change_remarks}
      />
    </Modal>
  );
};

export default ApprovalAndRejected;
