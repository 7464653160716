import { Button, Modal, Space } from "antd";
import React from "react";
import GroupForm from "./group_form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useDynamicSelector } from "../../../services/redux/selector";
import { IoMdClose } from "react-icons/io";

const GroupModal = (props) => {
  const {
    group_modal_open,
    handle_group_modal_close,
    group_modal_title,
    prefill_values,
  } = props;
  const { t } = useTranslation();

  const { loading: create_group_loading } = useDynamicSelector(
    "create_district_group"
  );
  const { loading: update_group_loading } = useDynamicSelector(
    "update_district_group"
  );

  return (
    <Space>
      <Modal
        open={group_modal_open}
        width={"40%"}
        closeIcon={
          <span style={{ marginTop: "2px" }}>
            <IoMdClose
              style={{
                color: "white",
                width: "28px",
                margin: "15px 0px 0px 0px",
              }}
            />
          </span>
        }
        footer={[
          <Button onClick={handle_group_modal_close}>{t("cancel")}</Button>,
          <Button
            style={{ backgroundColor: "#1e90ff", color: "white" }}
            htmlType="submit"
            form="add_group_form"
            loading={create_group_loading || update_group_loading}
          >
            {t("save")}
          </Button>,
        ]}
        destroyOnClose={true}
        title={group_modal_title == "add" ? t("add_group") : t("edit_group")}
        onCancel={handle_group_modal_close}
      >
        <GroupForm
          prefill_values={prefill_values}
          group_modal_title={group_modal_title}
        />
      </Modal>
    </Space>
  );
};
export default GroupModal;
