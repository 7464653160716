import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useDynamicSelector } from "../../../../services/redux";

const BookingAbishekamDate = ({
  season_date_slot_list_items,
  set_selected_item,
  set_selected_time,
  form,
}) => {
  const { t } = useTranslation();
  const [options, set_options] = useState([]);
  const { datetime } = useDynamicSelector("get_current_date_time");

  useEffect(() => {
    let yesterday = moment(datetime).subtract(1, "days").format();
    const _options =
      season_date_slot_list_items?.filter(
        (c_date) =>
          moment(c_date?.date).tz("Asia/Kolkata").format() >= yesterday
      ) || [];
    set_options(_options);
  }, [datetime, season_date_slot_list_items]);

  return (
    <Form.Item
      label={t("abishekam_date")}
      name="date"
      rules={[
        {
          required: true,
          message: "Please Select Date",
        },
      ]}
    >
      <Select
        style={{
          width: "100%",
        }}
        placeholder="Please select"
        onChange={(e, value) => {
          set_selected_item({
            id: e,
            date: value?.children,
            date_string: value?.children,
          });
          set_selected_time("");
        }}
        autoFocus
        allowClear
      >
        {options?.map((option) => (
          <Select.Option key={option?.id} value={option?.id}>
            {option?.date_string}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default BookingAbishekamDate;
