import { Card } from "antd";
import React, { useState } from "react";
import CustomButton from "../common/custom_button";
import { useTranslation } from "react-i18next";
import UsersTable from "./users/table";
import { navigateBack } from "../../../helpers/functions";

const UsersGroupListTable = (props) => {
  const { handle_edit, show_delete_model, id } = props;
  const { t } = useTranslation();
  const [user_group_modal_visible, set_user_group_modal_visible] =
    useState(false);

  const handle_back = () => {
    navigateBack();
  };

  const handle_add_user = () => {
    set_user_group_modal_visible(true);
  };

  return (
    <div style={{ width: "90%", margin: "auto" }}>
      <div
        style={{
          margin: "10px",
          width: "90%",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CustomButton button_name={t("back")} onclick_button={handle_back} />
          <CustomButton
            button_name={"Add User"}
            onclick_button={handle_add_user}
          />
        </div>
      </div>
      <UsersTable
        user_group_modal_visible={user_group_modal_visible}
        set_user_group_modal_visible={set_user_group_modal_visible}
        handle_edit={handle_edit}
        show_delete_model={show_delete_model}
        id={id}
      />
    </div>
  );
};

export default UsersGroupListTable;
