import { gql } from "@apollo/client";

export const query_get_all_counter_group = gql`
  query get_counter_group_list(
    $pagination: pagination_input
    $sort: sort_input
    $filter: get_counter_group_list_filter_input
  ) {
    get_counter_group_list(
      pagination: $pagination
      sort: $sort
      filter: $filter
    ) {
      items {
        id
        name
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_create_counter_group = gql`
  mutation create_counter_group($data: create_counter_group_input) {
    create_counter_group(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_counter_group = gql`
  mutation update_counter_group(
    $id: String!
    $data: update_counter_group_input
  ) {
    update_counter_group(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_one_counter_group = gql`
  query get_counter_group($id: String!) {
    get_counter_group(id: $id) {
      id
      name
    }
  }
`;

export const mutation_delete_counter_group = gql`
  mutation delete_counter_group($id: String!) {
    delete_counter_group(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_current_counter_query=gql`
query get_current_counter($data:get_current_counter_input){
  get_current_counter(data:$data){
    id
    name
    code
  }
}`;
