import { gql } from "@apollo/client";

export const query_get_booking_details = gql`
  query get_booking_detail($custom: get_booking_detail_input) {
    get_booking_detail(custom: $custom) {
      date_slot_id
      time_slot_id
      price
      count
      confirmed_count
      waiting_count
      cancelled_count
      mandram_id
      booking_reference
      booking_user_id
      enrolment_status
      is_spot_booking
      booked_on
      mandram {
        id
        name
        code
        incharge_name
        incharge_mobile
      }
      date_slot {
        id
        season_id
        date
        start_time_in_minutes
        end_time_in_minutes
        confirmed_quota
        waiting_quota
        id
      }
    }
  }
`;

export const booking_details_query = gql`
  query get_booking_detail($custom: get_booking_detail_input) {
    booking_details: get_booking_detail(custom: $custom) {
      id
      date_slot_id
      time_slot_id
      price
      count
      enrolment_status
      enrolment_list {
        arrived_count
      }
      confirmed_count
      waiting_count
      cancelled_count
      mandram_id
      booking_reference
      booking_user_id
      booking_status
      enrolment_status
      is_spot_booking
      booked_on
      booked_by {
        name
      }
      is_prepaid
      payment {
        amount
        payment_mode
        paid_on
      }
      date_slot {
        id
        date
        date_string
      }
      mandram {
        id
        name
        code
        district_id
        incharge_name
        incharge_mobile
        district {
          name
          extra_allowed_percentage
        }
      }
      time_slot {
        start_time_in_minutes
        end_time_in_minutes
      }
      booking_user {
        id
        id_number
        id_type
        name
        mobile
        email
        address
      }
      error {
        message
      }
    }
  }
`;

export const query_get_date_district_mandram_wise_summary = gql`
  query get_date_district_mandram_wise_summary(
    $filter: date_district_mandram_wise_summary_filter_input
  ) {
    get_date_district_mandram_wise_summary(filter: $filter) {
      items {
        count {
          booked
          cancelled
          arrived
          excess
          enrolled
          yet_to_enroll
        }
        date
        date_slot_id
        received_amount
        prepaid_amount
        date_slot_id
        district_id
        mandram_id
        status
        error {
          message
        }
      }
    }
  }
`;

export const query_get_mandram_wise_summary = gql`
  query get_mandram_wise_summary($filter: mandram_wise_summary_filter_input) {
    get_mandram_wise_summary(filter: $filter) {
      district_id
      items {
        count {
          booked
          cancelled
          arrived
          excess
          enrolled
          yet_to_enroll
        }
        date
        received_amount
        date_slot_id
        mandram_id
        prepaid_amount
        error {
          status_code
          message
        }
      }
    }
  }
`;

export const query_booking_history_list = gql`
  query get_booking_list(
    $filter: get_booking_list_filter_input
    $pagination: pagination_input
  ) {
    get_booking_list(filter: $filter, pagination: $pagination) {
      items {
        id
        count
        price
        date_slot_id
        is_spot_booking

        is_prepaid
        time_slot_id
        confirmed_count
        waiting_count
        cancelled_count
        booking_reference
        booking_status
        status
        date_slot {
          date
          id
        }
        booking_user {
          name
          mobile
        }
        time_slot {
          id
          date_slot_id
        }
        enrolment_status
        booked_on
        mandram {
          name
          code
          incharge_name
          incharge_mobile
        }
        booked_by {
          name
        }
        payment {
          amount
          paid_on
          payment_mode

          received_by {
            name
          }
          payment_mode
        }
        status

        enrolment_list {
          booking_id
          counter_id
          enrolled_on
          arrived_count
          status

          enrolled_by {
            name
            mobile
            email
          }
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;

export const query_booking_action_list = gql`
  query get_booking_list(
    $filter: get_booking_list_filter_input
    $pagination: pagination_input
  ) {
    get_action_list: get_booking_list(
      filter: $filter
      pagination: $pagination
    ) {
      items {
        id
        count
        price
        date_slot_id
        is_spot_booking
        is_prepaid
        time_slot_id
        confirmed_count
        waiting_count
        cancelled_count
        booking_reference
        booking_status
        status
        date_slot {
          date
          id
        }
        booking_user {
          name
          mobile
        }
        time_slot {
          id
          date_slot_id
        }
        enrolment_status
        enrolment_list {
          enrolled_on
          sequence_count {
            count
          }
        }
        booked_on
        mandram {
          name
          code
          incharge_name
          incharge_mobile
        }
        booked_by {
          name
        }
        payment {
          amount
          paid_on
          payment_mode

          received_by {
            name
          }
          payment_mode
        }
        status

        enrolment_list {
          booking_id
          counter_id
          enrolled_on
          arrived_count
          status

          enrolled_by {
            name
            mobile
            email
          }
        }
      }
    }
  }
`;

export const mutation_booking_cancel = gql`
  mutation cancel_booking($id: String!) {
    cancel_booking(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_action_cancel = gql`
  mutation cancel_booking($id: String!) {
    cancel_booking_action: cancel_booking(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_booking_summary_dashboard = gql`
  query get_booking_summary_dashboard(
    $filter: booking_summary_dashboard_filter_input
  ) {
    get_booking_summary_dashboard(filter: $filter) {
      booked_count
      arrived_count
      waiting_count
      cancelled_count
      paid_amount
    }
  }
`;

export const get_booking_history_query=gql`
query get_booking_report($filter:booking_report_filter_input){
  get_booking_report(filter:$filter){
    items{
      booking_reference
      mandram_name
      booked_by_name
      mandram_code
      booking_status
      payment_mode
      leader_name
      leader_mobile
      abishekam_date
      booked_date
      confirmed_count
      waiting_count
      booking_count
      cancelled_count
      enrolled_count
      not_enrolled_count
      excess_count
    }
  }
}`;

export const query_sub_district_wise_summaries=gql`
query get_sub_district_wise_summary($filter:sub_district_wise_summary_filter_input){
  get_sub_district_wise_summary(filter:$filter){
    items{
      date
      count{
        booked
        cancelled
        arrived
        excess
        enrolled
        yet_to_enroll
        prepaid
      }
      received_amount
      status
      sub_district_id
      confirmed_quota
      waiting_quota
      prepaid_amount
      received_amount
    }
  }
}`