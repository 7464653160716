import { gql } from "@apollo/client";

export const district_wise_report_query = gql`
  query get_district_wise_count($filter: district_wise_count_filter_input) {
    get_district_wise_count(filter: $filter) {
      items {
        district_name
        district_tamil_name
        today_count
        yesterday_count
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
