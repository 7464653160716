import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const FeedbackManageTitleFormItem = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name="title"
        label={t("title")}
        rules={[
          {
            required: true,
            message: "Title is required",
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
    </div>
  );
};

export default FeedbackManageTitleFormItem;
