import { Button, Card, Col, Form, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
const XLSX = require("xlsx");
import {
  dynamicClear,
  dynamicRequest,
  query_date_wise_report_count,
  query_date_wise_report_summary,
  query_get_date_district_mandram_wise_summary,
  query_get_mandram_list,
  useDynamicSelector,
} from "../../services/redux";
import { district_details_query } from "../../services/redux/slices/graphql/graphql_district";
import BookingHistoryAbishekam from "../components/booking_history/booking_history_form/booking_history_abishekam";
import BookingHistoryDistrict from "../components/booking_history/booking_history_form/booking_history_district";
import BookingHistoryMandram from "../components/booking_history/booking_history_form/booking_history_mandram";
import BookingHistorySubmit from "../components/booking_history/booking_history_form/booking_history_submit";
import { useDispatch } from "react-redux";
import DateWiseReportCollapse from "../components/reports_page/date_wisie_report/date_wise_report_collapse/date_wise_report_collapse_table";
import DateWiseReportSearch from "../components/reports_page/date_wisie_report/report_search_button_form_item";
import PrintDateWiseReport from "../components/reports_page/date_wisie_report/date_wise_report_print";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import DateWiseReportMainForm from "../components/reports_page/date_wisie_report/date_wise_report_main_form/date_wise_report_main_form";
import LoaderSpinner from "../components/common/custom_spinner";

const ReportMainForm = (props) => {
  const {
    set_mandram_id,
    initial_values,
    set_initial_values,
    set_booking_detail,
    mandram_id,
    role,
    table_open,
    booking_detail,
  } = props;
  const componentRef = useRef();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { set_table_open } = props;
  const [mandram_item, set_mandram_item] = useState([]);
  const [selected_options, set_selected_options] = useState([]);
  const [district_id, set_district_id] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [selected_date, set_selected_date] = useState();
  const [selected_last_least_date, set_selected_last_least_date] = useState({});
  const [selected_items, set_selected_items] = useState();
  const [abishekam_date_id, set_abishekam_date_id] = useState();
  console.log("abishekam_date_id", abishekam_date_id);

  const [print, set_print] = useState(false);
  const { items: districts } = useDynamicSelector("district_list");
  const { items: mandram_items } = useDynamicSelector("get_mandram_details");

  const { items: booking_summary, loading: booking_summary_loading } =
    useDynamicSelector("get_date_district_mandram_wise_summary");

  const { items: date_wise_count_list, loading: get_date_wise_count_loading } =
    useDynamicSelector("get_date_wise_summary_count");

  const {
    items: date_wise_summary_list,
    loading: get_date_wise_summary_loading,
  } = useDynamicSelector("get_date_wise_summary");

  const { items: booking_mandram_summary } = useDynamicSelector(
    "get_mandram_wise_summary"
  );
  const get_date_district_mandram_wise_summary = () => {
    let key = [
      { key: "get_date_district_mandram_wise_summary", loading: true },
    ];
    let query = query_get_date_district_mandram_wise_summary;
    // let district_id_lists = [district_id];
    let variables = {
      filter: {
        district_id_list: selected_items?.district
          ? [selected_items?.district]
          : [],
        mandram_id_list: selected_items?.mandram_vsg || [],
        date_slot_id_list: abishekam_date_id || [],
      },
    };
    set_table_open(true);
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_district_list = () => {
    let key = [{ key: "district_list", loading: true }];
    let query = district_details_query;
    let variables = {
      sort: {
        field: "name",
        order: "asc",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_mandram_list = () => {
    let key = [{ key: "get_mandram_details", loading: true }];
    let query = query_get_mandram_list;
    let variables = {
      filter: {
        district_id: district_id,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_date_wise_summary_print = async () => {
    let key = [{ key: "get_date_wise_summary_count", loading: true }];
    let query = query_date_wise_report_count;
    let filter = {};
    if (selected_items) {
      filter = {
        district_id: selected_items?.district ? [selected_items?.district] : [],
        mandram_id: selected_items?.mandram_vsg || "",
        date_slot_id_list: abishekam_date_id,
      };
    }

    let variables = {
      filter,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_date_wise_count_print = async () => {
    let key = [{ key: "get_date_wise_summary", loading: true }];
    let query = query_date_wise_report_summary;
    let filter = {};
    if (selected_items) {
      filter = {
        district_id: selected_items?.district ? [selected_items?.district] : [],
        mandram_id: selected_items?.mandram_vsg || "",
        date_slot_id_list: abishekam_date_id,
      };
    }

    let variables = {
      filter,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_district_list();
  }, []);
  // useEffect(() => {
  //   if (!booking_summary?.length > 0) {
  //     form.resetFields();
  //     set_selected_options([]);
  //     setSelectAll(false);
  //   }
  // }, [booking_summary]);
  useEffect(() => {
    if (district_id) {
      get_mandram_list();
    }
  }, [district_id]);

  useEffect(() => {
    if (initial_values.code) {
      form.setFieldsValue({
        ...initial_values,
      });
    } else {
      form.resetFields();
    }
  }, [initial_values]);

  useEffect(() => {
    if (
      print &&
      !get_date_wise_summary_loading &&
      !get_date_wise_count_loading
    ) {
      handlePrint();
    }
  }, [get_date_wise_summary_loading, get_date_wise_count_loading]);

  const [show_print_button, set_show_print_button] = useState(false);

  // const on_print = () => {
  //   print_function(
  //     "devotee_payment_details",
  //     "devotee_payment_print",
  //     set_show_print_button
  //   );
  // };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // const print_date_wise_report = async () => {
  //   // set_print(true);
  //   try {
  //     //print API call
  //     await get_date_wise_summary_print();
  //     await get_date_wise_count_print();
  //     handlePrint();
  //     // set_print(false);
  //   } catch (error) {
  //     //
  //   }
  // };

  const print_date_wise_report = () => {
    handlePrint();
  };

  const on_change_values = () => {
    dispatch(dynamicClear("get_date_district_mandram_wise_summary"));
    dispatch(dynamicClear("get_mandram_details"));
    dispatch(dynamicClear("get_mandram_wise_summary"));
  };

  const arrange_xlsx_date_wise_report_data = (
    date_wise_summary_list,
    date_wise_count_list
  ) => {
    console.log("date_wise_count_list", date_wise_count_list);
    const rearranged_date_wise_data = [];
    if (date_wise_summary_list && Array.isArray(date_wise_summary_list)) {
      for (let i = 0; i < date_wise_summary_list.length; i++) {
        let data = date_wise_summary_list[i];
        console.log("data", data);
        let row_data = {
          ["S No"]: i + 1,
          ["Date"]: moment(data?.abishekam_date).format("DD-MM-YYYY"),
          ["District"]: data?.district_name || "",
          ["Mandram"]: data?.mandram_name || "",
          ["Booked"]: data?.mandram_total_count || 0,
          ["Enrolled"]: data?.mandram_total_enrolled || 0,
          ["Not Arrived"]: data?.mandram_total_not_enrolled || 0,
          // ["Total Amount"]: data?.sum || 0,
        };
        rearranged_date_wise_data.push(row_data);
      }
    }
    // let grand_total = {
    //   ["S No"]: "Grand Total",
    //   ["Date"]: "",
    //   ["District"]: "",
    //   ["Mandram"]: "",
    //   ["Booked"]: 0,
    //   ["Enrolled"]: 0,
    //   ["Not Arrived"]: 0,
    //   // ["Total Amount"]: 0,
    // };
    // rearranged_date_wise_data.push(grand_total);

    const rearranged_date_wise_count = [];
    if (date_wise_count_list && Array.isArray(date_wise_count_list)) {
      for (let i = 0; i < date_wise_count_list.length; i++) {
        let data = date_wise_count_list[i];
        console.log("it", data);
        let row_data = {
          ["S No"]: i + 1,
          ["Booking No"]: data?.booking_reference || "",
          ["Irumudi Count"]: data?.booking_count || 0,
          ["Mandram"]: `${data?.mandram_name}/${data?.mandram_code}` || "",
          ["Abishekam Date"]:
            moment(data?.abishekam_date).format("DD-MM-YYYY") || "",
          ["Status"]: data?.status || "",
          ["Arrived"]: data?.enrolled_count || 0,
          ["Not Arrived"]: data?.not_enrolled_count || 0,
          ["Booked"]: `${data?.booked_by_name}/${data?.booking_status}` || "",
          ["Payment Mode"]: data?.payment_mode || "",
          ["Payment Status"]: data?.status || "",
          ["Leader"]: `${data?.leader_name}/${data?.leader_mobile}` || "",
          // ["Total Amount"]: data_items[i]?.sum || 0,
        };
        rearranged_date_wise_count.push(row_data);
      }
    }
    // let grand_total_count = {
    //   // ["S No"]: "Grand Total",
    //   // ["Booking No"]: "",
    //   // ["Irumudi Count"]: 0,
    //   // ["Mandram"]: 0,
    //   // ["Abishekam Date"]: 0,
    //   // ["Status"]: 0,
    //   // ["Arrived"]: 0,
    //   // ["Not Arrived"]: 0,
    //   // ["Excess"]: 0,
    //   // ["Booked"]: 0,
    //   // ["Payment Mode"]: 0,
    //   // ["Payment Status"]: 0,
    //   // ["Leader"]: 0,
    //   // // ["Total Amount"]: 0,
    // };
    // rearranged_date_wise_count.push(grand_total_count);

    const style = {
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
    };

    const wb = XLSX.utils.book_new();

    const ts_ws = XLSX.utils.json_to_sheet(rearranged_date_wise_data, {
      header: Object.keys(rearranged_date_wise_data[0]),
      styles: { header: [style], cell: [style] },
      autoSize: true,
    });
    XLSX.utils.book_append_sheet(wb, ts_ws, "Date Wise Count");

    const count_ws = XLSX.utils.json_to_sheet(rearranged_date_wise_count, {
      header: Object.keys(rearranged_date_wise_count[0]),
      styles: { header: [style], cell: [style] },
      autoSize: true,
    });
    XLSX.utils.book_append_sheet(wb, count_ws, "Date Wise Summary");

    const blob = new Blob(
      [XLSX.write(wb, { bookType: "xlsx", type: "array" })],
      {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }
    );
    saveAs(blob, `date_wise_report.xlsx`);
  };

  const on_XLSX_download = () => {
    arrange_xlsx_date_wise_report_data(
      date_wise_count_list,
      date_wise_summary_list
    );
  };

  return (
    <>
      <div style={{ marginTop: "20px", width: "100%" }}>
        {/* <Card
          style={{
            width: "96%",
            margin: "auto",
            borderRadius: "10px",
            border: "1px solid #e0e0e0",
            // minHeight: 0,
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
            boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.1)",
            backgroundColor: "#e7e7e7",
          }}
        > */}
        {/* <Form
            name="basic"
            onFinish={handle_finish}
            style={{ width: "100%" }}
            layout="vertical"
            form={form}
            onValuesChange={on_change_values}
          >
            <div className="booking-history-form">
              <BookingHistoryAbishekam
                selected_options={selected_options}
                set_selected_options={set_selected_options}
                form={form}
                setSelectAll={setSelectAll}
                selectAll={selectAll}
                set_selected_date={set_selected_date}
                set_selected_last_least_date={set_selected_last_least_date}
              />

              <BookingHistoryDistrict
                set_district_id={set_district_id}
                districts={districts}
              />

              <BookingHistoryMandram
                mandram_items={mandram_items}
                set_mandram_item={set_mandram_item}
                set_initial_values={set_initial_values}
                set_mandram_id={set_mandram_id}
              />
              <DateWiseReportSearch />
            </div>
          </Form> */}
        {/* </Card> */}
      </div>
      <DateWiseReportMainForm
        set_selected_items={set_selected_items}
        set_abishekam_date_id={set_abishekam_date_id}
        get_date_district_mandram_wise_summary={
          get_date_district_mandram_wise_summary
        }
        get_date_wise_count_print={get_date_wise_count_print}
        get_date_wise_summary_print={get_date_wise_summary_print}
      />

      {(date_wise_summary_list || date_wise_count_list || booking_summary) && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              marginRight: "10px",
            }}
          >
            <Button
              disabled={
                get_date_wise_summary_loading || get_date_wise_count_loading
                  ? true
                  : false
              }
              // loading={
              //   get_date_wise_summary_loading || get_date_wise_count_loading
              // }
              onClick={print_date_wise_report}
              // id="date_wise_report_print"
              style={{
                backgroundColor: "#1e2c55",
                marginRight: "10px",
                color: "white",
              }}
            >
              {t("print")}
            </Button>
            <Button
              disabled={
                get_date_wise_summary_loading || get_date_wise_count_loading
                  ? true
                  : false
              }
              style={{
                backgroundColor: "#185c37",
                marginRight: "20px",
                color: "white",
              }}
              onClick={on_XLSX_download}
            >
              {t("download")}
            </Button>
          </div>
          {booking_summary_loading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <LoaderSpinner />
            </div>
          )}
          {/* {booking_detail && ( */}
          <div
            ref={componentRef}
            style={{
              width: "96%",
              justifyContent: "center",
              // display: "flex",
              marginLeft: "20px",
              display: "block",
            }}
          >
            <DateWiseReportCollapse
              table_open={table_open}
              role={role}
              get_date_district_mandram_wise_summary={
                get_date_district_mandram_wise_summary
              }
              mandram_item={mandram_item}
              selected_last_least_date={selected_last_least_date}
            />
          </div>
        </>
      )}

      {/* )} */}
    </>
  );
};

export default ReportMainForm;
