import { Button } from "antd";
import { Box } from "native-base";
import React from "react";
import { useDynamicSelector } from "../../../../../services/redux";

const IrumudiGetReceiptButton = () => {
  const { loading: get_booking_receipt_loading } = useDynamicSelector(
    "get_booking_receipt"
  );
  return (
    <div>
      <Button
        className="login_button"
        style={{
          backgroundColor: "#FE301D",
          color: "white",
          marginBottom: "10px",
        }}
        htmlType="submit"
        form="receipt_form"
        loading={get_booking_receipt_loading}
      >
        Get Receipt
      </Button>
    </div>
  );
};

export default IrumudiGetReceiptButton;
