import { DatePicker, Form, Select } from "antd";
import moment from "moment";
import React from "react";

const OmSakthiReportToDate = (props) => {
  const { ordered_date_slots, set_to_date } = props;

  const on_change = (value, option) => {
    console.log("option", option);
    const selected_date = {
      id: value,
      selected_to_date: option?.children,
    };
    set_to_date(selected_date);
  };
  return (
    <Form.Item
      name="to_date"
      label="To Date"
      rules={[{ required: true, message: "Please select to date" }]}
    >
      <Select placeholder="Select To Date" allowClear onChange={on_change}>
        {ordered_date_slots?.map((date_option, index) => (
          <Select.Option key={date_option?.id} value={date_option?.id}>
            {date_option.date_string}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default OmSakthiReportToDate;
