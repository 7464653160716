import { Form, Input } from "antd";
import React from "react";

const InputDistrictAdminUserName = () => {
  return (
    <>
      <Form.Item
        name={["sub_district_admin", "system_user", "username"]}
        label="Username"
        rules={[
          {
            required: true,
            message: "Please enter username",
          },
        ]}
      >
        <Input
          placeholder="Username"
          allowClear={true}
          autoComplete="off"
          aria-autocomplete="none"
        />
      </Form.Item>
    </>
  );
};

export default InputDistrictAdminUserName;
