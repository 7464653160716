import React from "react";
import CustomPageTitle from "../components/common/custom_page_title";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";

const TermsOfServices = () => {
  const { t } = useTranslation();
  return (
    <div style={{ overflow: "auto" }}>
      <Typography
        style={{ fontWeight: "bold", fontSize: "25px", margin: "20px" }}
      >
        {t("terms_of_service")}
      </Typography>
      <Typography
        style={{ fontWeight: "bold", fontSize: "20px", marginLeft: "20px" }}
      >
        {t("online_payments")}
      </Typography>
      <Typography style={{ margin: "20px" }}>
        This online payment system is provided by Adhiparasakthi Siddhar Peedam.
        Adhiparasakthi Siddhar Peedam may update these terms from time to time
        and any changes will be effective immediately on being set out here.
        Please ensure you are aware of the current terms. The country of
        domicile for Adhiparasakthi Siddhar Peedam is India.
      </Typography>
      <Typography
        style={{
          fontWeight: "bold",
          fontSize: "20px",
          // marginLeft: "15px",
          margin: "20px",
        }}
      >
        {t("terms_and_Conditions")}
      </Typography>
      <Typography style={{ margin: "20px" }}>
        Please read these terms carefully before using the online payment
        facility. Using the online payment facility on this website indicates
        that you accept these terms. If you do not accept these terms do not use
        this facility.
      </Typography>
      <Typography style={{ margin: "20px", fontSize: "20px" }}>
        All payments are subject to the following conditions:-
      </Typography>
      <Typography style={{ margin: "20px" }}>
        The description of services of fee making are specific to your need,
        when you log in with your unique password. Normally payment is required
        in advance (i.e. before you commence your activity).
      </Typography>
      <Typography style={{ margin: "20px" }}>
        All rates quoted are in Indian Rupees. The Adhiparasakthi Siddhar Peedam
        reserves the right to change the rate at any time.
      </Typography>
      <Typography style={{ margin: "40px" }}>
        Your payment will normally reach the Adhiparasakthi Siddhar Peedam
        account to which you are making a payment within two working days. We
        cannot accept liability for a payment not reaching the correct
        Adhiparasakthi Siddhar Peedam account due to you quoting an incorrect
        account number or incorrect personal details. Neither can we accept
        liability if payment is refused or declined by the credit/debit card
        supplier for any reason. If the card supplier declines payment the
        Adhiparasakthi Siddhar Peedam is under no obligation to bring this fact
        to your attention. You should check with your bank/credit/debit card
        supplier that payment has been deducted from your account. In no event
        will the Adhiparasakthi Siddhar Peedam be liable for any damages
        whatsoever arising out of the use, inability to use, or the results of
        use of this site, any websites linked to this site, or the materials or
        information contained at any or all such sites, whether based on
        warranty, contract, tort or any other legal theory and whether or not
        advised of the possibility of such damages.
      </Typography>
      <Typography
        style={{ fontWeight: "bold", fontSize: "20px", margin: "20px" }}
      >
        Cancellations and Refund Policy
      </Typography>
      <Typography style={{ margin: "20px" }}>
        Any request for cancellations of orders once duly placed on the site,
        shall not be entertained under any circumstances. Refunds, if
        applicable, at the discretion of the Management, will only be made to
        the debit/credit card used for the original transaction. For the
        avoidance of doubt nothing in this Policy shall require the
        Adhiparasakthi Siddhar Peedam to refund the Price (or part thereof)
        unless such Price (or part thereof) have previously been paid.
      </Typography>
      <Typography
        style={{ margin: "20px", fontWeight: "bold", fontSize: "20px" }}
      >
        DND Policy
      </Typography>
      <Typography style={{ margin: "20px" }}>
        If you wish to stop any further sms/email alerts/contacts from our side,
        all you need to do is to send an email:- aspwct@gmail.com with your
        mobile numbers and you will be excluded from the alerts list.
      </Typography>
      <Typography
        style={{ margin: "20px", fontWeight: "bold", fontSize: "20px" }}
      >
        Contact Details
      </Typography>
      <Typography
        style={{ margin: "20px", fontWeight: "bold", fontSize: "15px" }}
      >
        Adhiparasakthi Siddhar Peedam,
      </Typography>
      <Typography style={{ margin: "20px" }}>
        <ul>
          <li>
            <Typography>Melmaruvathur,</Typography>
          </li>
          <li>
            <Typography>Kancheepuram District,</Typography>
          </li>
          <li>
            <Typography>TamilNadu-603319</Typography>
          </li>
          <li>
            <Typography>PH: +91 44 27529096</Typography>
          </li>
          <li>
            <Typography>Email: aspwct@gmail.com</Typography>
          </li>
        </ul>
      </Typography>
      <Typography
        style={{ margin: "20px", fontWeight: "bold", fontSize: "15px" }}
      >
        Public Relation Office
      </Typography>
      <Typography style={{ margin: "20px" }}>
        <ul>
          <li>
            <Typography> PH: +91 44 27529276</Typography>
          </li>
          <li>
            <Typography> Timing: 8:00 am To 8:00 PM</Typography>
          </li>
        </ul>
      </Typography>
    </div>
  );
};

export default TermsOfServices;
