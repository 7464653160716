import { gql } from "@apollo/client";
export const get_day_wise_report = gql`
  query get_daywise_summary($filter: daywise_summary_input) {
    get_daywise_summary(filter: $filter) {
      items {
        date
        irumudi_count
      }
    }
  }
`;
