import { gql } from "@apollo/client";

export const create_enroll_booking_query = gql`
  mutation enroll_booking($data: enroll_booking_input) {
    enroll_booking(data: $data) {
      id
      arrived_count
      status
      error {
        message
        status_code
      }
    }
  }
`;

export const get_enroll_booking_list_query = gql`
  query get_current_session_enrolment_list {
    get_current_session_enrolment_list {
      pagination {
        total_count
        page_number
        page_limit
      }
      items {
        id
        booking_id
        counter_id
        enrolled_on
        id
        arrived_count
        sequence_count {
          count
        }
        booking {
          id
          price
          count
          confirmed_count
          is_prepaid
          date_slot {
            id
            date
          }
          booking_reference
          mandram {
            id
            name
            code
            district {
              id
              name
              code
            }
          }
          payment {
            payment_mode
            amount
          }
        }
        counter {
          id
          name
          is_active
        }
        enrolled_by {
          name
          mobile
          email
        }
        payment {
          amount
          payment_mode
          paid_on
        }
        is_tickets_printed
        reprintable_ticket_list {
          ticket_number
        }
      }

      error {
        message
      }
    }
  }
`;

export const enroll_payment_query = gql`
  mutation pay_for_booking($data: booking_payment_input) {
    enroll_payment: pay_for_booking(data: $data) {
      id
      status
      error {
        message
      }
    }
  }
`;

export const enrollment_check_order_status_query = gql`
  query check_order_status($id: String!) {
    enrollment_payment_check: check_order_status(id: $id) {
      order_id
      booking_id
      enrolment_id
      payment_status: status
      error {
        status_code
        message
      }
    }
  }
`;

export const spot_booking_and_enroll_query = gql`
  mutation create_spot_booking_and_enroll($data: create_spot_booking_input) {
    create_spot_booking_and_enroll(data: $data) {
      id
      status
      error {
        message
        status_code
      }
    }
  }
`;

export const check_order_status_for_spot_booking_query = gql`
  query check_order_status($id: String!) {
    check_order_status_for_spot_booking: check_order_status(id: $id) {
      order_id
      booking_id
      enrolment_id
      payment_status: status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_closure_summary_query = gql`
  query get_closure_summary {
    get_closure_summary {
      total_cash_amount
      total_card_amount
      total_dd_amount
      total_upi_amount
      total_arrived_count
      total_prepaid_arrived_count
      total_amount
    }
  }
`;

export const set_counter_user_mutation = gql`
  mutation set_counter_user($id: String!) {
    set_counter_user(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
export const unset_counter_user_mutation = gql`
  mutation unset_counter_user($id: String!) {
    unset_counter_user(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
